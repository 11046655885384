import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./styles"
import { Row, Container, Col } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import sort_ascending from "../../images/sort_ascending.png";
import sort_default from "../../images/sort_default.png";
import sort_descending from "../../images/sort_descending.png";
import { useNavigate, Link } from "react-router-dom";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle"
import { LoadingSpinner } from "../LoadingSpinner/index";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import * as FileSaver from 'file-saver';
import View from "../../images/view.svg";
import * as XLSX from 'xlsx';



const DashboardDistrictWise = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    const [distributionWise, setDistributionWise] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);


    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(50);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey, setSearchKey] = useState('n');
    const [searchKeyTable, setSearchKeyTable] = useState('');

    const[seasonData,setSeasonData]=useState([]);
    const[season,setSeason]=useState('')

    const [seedId,setSeedId]=useState('');
    const [seedName,setSeedName]=useState('');
    const[key,setKey]=useState('d');
    const[varietyId,setVarietyId]=useState('');
    const[varietyName,setVarietyName]=useState('');
    const[districtName,setDistrictName]=useState('');
    const[districtId,setDistrictId]=useState('');
    const[seedData,setSeedData]=useState([]);
    const[varietyData,setVarietyData]=useState([]);
    const [totaltransferQuantity, setTotalTransferQuantity] = useState([]);
    const [totalreceivedQuntity, setTotalReceivedQuantity] = useState(0);
    const [totalQuantity, setTotalQuantity] = useState([]);
    const [totalAllotQuantity, setTotalAllotQuantity] = useState(0);
    const [totalpurchaseDemandQuantity, setTotalpurchaseQuantity] = useState(0);
    const [totaldisrtributedQuantity, setTotaldisrtributedQuantity] = useState(0);
    const [totalfarmersBenifited, setTotalfarmersBenifited] = useState(0);
    const [totalForecastsQuantity, setTotalForecastQuantity] = useState(0);
    const [totalaoApproved, setTotalaoApproved] = useState(0);
    const [currentSort, setcurrentSort] = useState('default');
    const [sort_value, setcurrentSortValue] = useState();

/*     const [districtData,setDistrictData]=useState([]); */

const onSortChange = (sort_value) => {
    setcurrentSortValue(sort_value);
    if (currentSort === 'down'){
        console.log(sort_value);
        if(sort_value == 'Allotment'){
        tableData.sort((a, b) => a.Allotment - b.Allotment)
        }
       else if(sort_value == 'purchaseDemandQuantity'){
            tableData.sort((a, b) => a.purchaseDemandQuantity - b.purchaseDemandQuantity)
            }
            else if(sort_value == 'farmersBenifited' ){
                tableData.sort((a, b) => a.farmersBenifited - b.farmersBenifited)
                }
                else if(sort_value == 'quantity'){
                    tableData.sort((a, b) => a.quantity - b.quantity)
                    }
                    else if(sort_value == 'disrtributedQuantity'){
                        tableData.sort((a, b) => a.disrtributedQuantity - b.disrtributedQuantity)
                        }
                        else if(sort_value == 'aoApproved'){
                            tableData.sort((a, b) => a.aoApproved - b.aoApproved)
                            }
                            else if(sort_value == 'forcastQuantity'){
                                tableData.sort((a, b) => a.forcastQuantity - b.forcastQuantity)
                                }
                                else if(sort_value == 'receivedQuntity'){
                                    tableData.sort((a, b) => a.receivedQuntity - b.receivedQuntity)
                                    }
                                    else if(sort_value == 'transferQuantity'){
                                        tableData.sort((a, b) => a.transferQuantity - b.transferQuantity)
                                        }
        setcurrentSort('up');
        setTableData(tableData);
        console.log('tableData down',tableData);
    }
    else if (currentSort === 'up'){
        let offset = 0;
        console.log(orgtableData);
        var slice = orgtableData.slice(offset, offset + perPage);
        setPageCount(Math.ceil(orgtableData.length / perPage));
        setTableData(slice);
        setOffSet(offset);
        setCurrentPage(0);
        setcurrentSort('default');
    }
    else if (currentSort === 'default') {
        if(sort_value == 'Allotment'){
            tableData.sort((a, b) => b.Allotment - a.Allotment)
            }
           else if(sort_value == 'purchaseDemandQuantity'){
                tableData.sort((a, b) => b.purchaseDemandQuantity - a.purchaseDemandQuantity)
                }
                else if(sort_value == 'farmersBenifited' ){
                    tableData.sort((a, b) => b.farmersBenifited - a.farmersBenifited)
                    }
                    else if(sort_value == 'quantity'){
                        tableData.sort((a, b) => b.quantity - a.quantity)
                        }
                        else if(sort_value == 'disrtributedQuantity'){
                            tableData.sort((a, b) => b.disrtributedQuantity - a.disrtributedQuantity)
                            }
                            else if(sort_value == 'aoApproved'){
                                tableData.sort((a, b) => b.aoApproved - a.aoApproved)
                                }
                                else if(sort_value == 'forcastQuantity'){
                                    tableData.sort((a, b) => b.forcastQuantity - a.forcastQuantity)
                                    }
                                    else if(sort_value == 'receivedQuntity'){
                                        tableData.sort((a, b) => b.receivedQuntity - a.receivedQuntity)
                                        }
                                        else if(sort_value == 'transferQuantity'){
                                            tableData.sort((a, b) => b.transferQuantity - a.transferQuantity)
                                            }
        setTableData(tableData);
        setcurrentSort('down');
        console.log('tableData up',tableData);
    };
};

    const handleInput=async(e)=>{
        const {name,value}=e.target;
        if(name=="seedId"){
            setSeedId(value);
            const data=seedData.filter((item)=>item.id==value);
            console.log(data,value);
            setVarietyId('');
                if(value != ''){
                    const varieties = await API_Auth.getVarietyBySeed(data[0]?.CropName);
                        setVarietyData(varieties);
                   }
                   else{
                    setVarietyData('');
                   }
            setIsLoading(true);
            const result=await API_Auth.getDashboardDistrictAllSearch('',season,value);
            console.log("districtdistributionsearchData",result);
            setIsLoading(false);
            let result_final = [];
            for(let i=0; i<result.length; i++){
               if(result[i]?.DistrictName?.toLowerCase()  != 'demo'&&result[i]?.DistrictName != ''&&result[i]?.DistrictName){
                result_final.push(result[i]);
               }
            }
            handleDistrictDistributionSearch(result_final);    

              }
        if(name=="varietyId"){
            setVarietyId(value);
            setIsLoading(true)
            const result=await API_Auth.getDashboardDistrictAllSearch(value,season,seedId);
            console.log("districtdistributionsearchData",result);
            setIsLoading(false);
            let result_final = [];
            for(let i=0; i<result.length; i++){
                if(result[i].DistrictName?.toLowerCase()  != 'demo'&&result[i]?.DistrictName != ''&&result[i]?.DistrictName){
                 result_final.push(result[i]);
                }
             }
             handleDistrictDistributionSearch(result_final);
             }
        if(name=="season"){
            setSeason(value);
            setIsLoading(true)
            const result=await API_Auth.getDashboardDistrictAllSearch(varietyId,value,seedId);
            console.log("districtdistributionsearchData",result);
            setIsLoading(false);
            let result_final =[];
            for(let i=0; i<result.length; i++){
                if(result[i]?.DistrictName?.toLowerCase()  != 'demo'&&result[i]?.DistrictName != ''&&result[i]?.DistrictName){
                 result_final.push(result[i]);
                }
             }
             handleDistrictDistributionSearch(result_final);
        }
    }
    const handleDistrictDistributionSearch=async(districtdistributionsearchData)=>{
        let totalAllotQuantity = 0;
        let totaltransferQuantity = 0;
        let totalReceiverQuantity = 0;
        let totalpurchaseQuantity = 0;
        let totalfarmersbenefited = 0;
        let totaldistributedquantity = 0;
        let totalQuantity = 0;
        let totalForecastQuantity = 0;
        let totalaoApprovedQuantity = 0;
        for(let i=0; i<districtdistributionsearchData.length; i++){
            if(districtdistributionsearchData[i]?.Allotment){
             totalAllotQuantity = totalAllotQuantity+parseInt(districtdistributionsearchData[i]?.Allotment);
            }
             if(districtdistributionsearchData[i]?.transferQuantity){
             totaltransferQuantity = totaltransferQuantity+parseInt(districtdistributionsearchData[i]?.transferQuantity);
          }
          if(districtdistributionsearchData[i]?.receivedQuntity){
             totalReceiverQuantity = totalReceiverQuantity+parseInt(districtdistributionsearchData[i]?.receivedQuntity);
          }
          if(districtdistributionsearchData[i]?.forcastQuantity){
            totalForecastQuantity = totalForecastQuantity+parseInt(districtdistributionsearchData[i]?.forcastQuantity);
         }
         if(districtdistributionsearchData[i]?.aoApproved){
            totalaoApprovedQuantity = totalaoApprovedQuantity+parseInt(districtdistributionsearchData[i]?.aoApproved);
         }
          if(districtdistributionsearchData[i]?.purchaseDemandQuantity){
             totalpurchaseQuantity = totalpurchaseQuantity+parseInt(districtdistributionsearchData[i]?.purchaseDemandQuantity);
          }
          if(districtdistributionsearchData[i]?.farmersBenifited){
             totalfarmersbenefited = totalfarmersbenefited+parseInt(districtdistributionsearchData[i]?.farmersBenifited);
          }
          if(districtdistributionsearchData[i]?.quantity){
             totalQuantity = totalQuantity+parseInt(districtdistributionsearchData[i]?.quantity);
          }
          if(districtdistributionsearchData[i]?.disrtributedQuantity){
             totaldistributedquantity = totaldistributedquantity+parseInt(districtdistributionsearchData[i]?.disrtributedQuantity);
          }
         }
         setTotalAllotQuantity(totalAllotQuantity);
         setTotalQuantity(totalQuantity); 
         setTotalReceivedQuantity(totalReceiverQuantity); 
         setTotalTransferQuantity(totaltransferQuantity); 
         setTotaldisrtributedQuantity(totaldistributedquantity); 
         setTotalpurchaseQuantity(totalpurchaseQuantity);
         setTotalfarmersBenifited(totalfarmersbenefited); 
         setTotalForecastQuantity(totalForecastQuantity); 
         setTotalaoApproved(totalaoApprovedQuantity); 
         setDistributionWise(districtdistributionsearchData);
         setSearchData(districtdistributionsearchData);
         setIsLoading(false);
        //seting pagenation data
        let offset = 0;
        var slice = districtdistributionsearchData.slice(offset, offset + perPage);
        setPageCount(Math.ceil(districtdistributionsearchData.length / perPage));
        setOrgTableData(districtdistributionsearchData);
        if (currentSort === 'up'){
            console.log(sort_value);
            if(sort_value == 'Allotment'){
                slice.sort((a, b) => a.Allotment - b.Allotment)
            }
           else if(sort_value == 'purchaseDemandQuantity'){
            slice.sort((a, b) => a.purchaseDemandQuantity - b.purchaseDemandQuantity)
                }
                else if(sort_value == 'farmersBenifited' ){
                    slice.sort((a, b) => a.farmersBenifited - b.farmersBenifited)
                    }
                    else if(sort_value == 'quantity'){
                        slice.sort((a, b) => a.quantity - b.quantity)
                        }
                        else if(sort_value == 'disrtributedQuantity'){
                            slice.sort((a, b) => a.disrtributedQuantity - b.disrtributedQuantity)
                            }
                            else if(sort_value == 'aoApproved'){
                                slice.sort((a, b) => a.aoApproved - b.aoApproved)
                                }
                                else if(sort_value == 'forcastQuantity'){
                                    slice.sort((a, b) => a.forcastQuantity - b.forcastQuantity)
                                    }
                                    else if(sort_value == 'receivedQuntity'){
                                        slice.sort((a, b) => a.receivedQuntity - b.receivedQuntity)
                                        }
                                        else if(sort_value == 'transferQuantity'){
                                            slice.sort((a, b) => a.transferQuantity - b.transferQuantity)
                                            }
            setTableData(slice);
            setOffSet(offset);
            setCurrentPage(0);
        }
        else if (currentSort === 'default'){
            let offset = 0;
            var slice = districtdistributionsearchData.slice(offset, offset + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setTableData(slice);
            setOrgTableData(districtdistributionsearchData);
            setOffSet(offset);
            setCurrentPage(0);
        }
        else if (currentSort === 'down') {
            if(sort_value == 'Allotment'){
                slice.sort((a, b) => b.Allotment - a.Allotment)
                }
               else if(sort_value == 'purchaseDemandQuantity'){
                slice.sort((a, b) => b.purchaseDemandQuantity - a.purchaseDemandQuantity)
                    }
                    else if(sort_value == 'farmersBenifited' ){
                        slice.sort((a, b) => b.farmersBenifited - a.farmersBenifited)
                        }
                        else if(sort_value == 'quantity'){
                            slice.sort((a, b) => b.quantity - a.quantity)
                            }
                            else if(sort_value == 'disrtributedQuantity'){
                                slice.sort((a, b) => b.disrtributedQuantity - a.disrtributedQuantity)
                                }
                                else if(sort_value == 'aoApproved'){
                                    slice.sort((a, b) => b.aoApproved - a.aoApproved)
                                    }
                                    else if(sort_value == 'forcastQuantity'){
                                        slice.sort((a, b) => b.forcastQuantity - a.forcastQuantity)
                                        }
                                        else if(sort_value == 'receivedQuntity'){
                                            slice.sort((a, b) => b.receivedQuntity - a.receivedQuntity)
                                            }
                                            else if(sort_value == 'transferQuantity'){
                                                slice.sort((a, b) => b.transferQuantity - a.transferQuantity)
                                                }
            setTableData(slice);
            setOffSet(offset);
            setCurrentPage(0);
        };

    }

    const exportToCSV=(csvData)=>{

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const fileName = 'District Wise Dashboard'
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }
    const handleSearch = (e) => {
        let filterValue = e.target.value;
        setCurrentPage(0);
        if (filterValue == '') {
            let offsetsearch = 0;
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setTableData(slice);
            setSearchData(orgtableData);
            setOffSet(offsetsearch);
            setCurrentPage(0);
            setSearchKey('n');
        }
        else {
            let offsetsearch = 0;
            const filteredData = orgtableData.filter(item => {
                return (item ?.Allotment ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                    item ?.DistrictName ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                        item ?.disrtributedQuantity ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                            item ?.purchaseDemandQuantity ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                                item ?.farmersBenifited ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                                        item ?.UserName ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                                            item ?.receivedQuntity ?.toLowerCase().includes(filterValue.toLowerCase()))
            });

            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            setOffSet(offsetsearch);
            setSearchKeyTable(filteredData);
            setTableData(slice);
            setSearchData(filteredData);
        }
    }
    const handleDistributor=async()=>{
        setSeedId('');
        setDistrictId('');
        setVarietyId('');
        setSeason('');
        setIsLoading(true)
        const result=await API_Auth.getDashboardDistrictAllSearch('','','');
        setIsLoading(false);
        console.log("districtdistributionsearchData",result);
        let result_final =[];
        for(let i=0; i<result.length; i++){
            if(result[i]?.DistrictName?.toLowerCase()  != 'demo'&&result[i]?.DistrictName  != ''&&result[i]?.DistrictName){
             result_final.push(result[i]);
            }
         }
         handleDistrictDistributionSearch(result_final);
    }
    useEffect(() => {
        setIsLoading(true);
        const fetchDistributionWise = async () => {
            let result = await API_Auth.getDashboardDistrictAllSearch('','','');
            let seed_result =await API_Auth.getSeeds();
            const seasonsresult=await API_Auth.getSeasons();
            setSeasonData(seasonsresult);
            setSeedData(seed_result)
            console.log("seed_result",seed_result)
            console.log("result", result)
            setDistributionWise(result);
            setSearchData(result);
            setIsLoading(false);
            let result_final = [];
            for(let i=0; i<result.length; i++){
               if(result[i].DistrictName?.toLowerCase()  != 'demo'){
                if(result[i].DistrictName?.toLowerCase() != null&&result[i]?.DistrictName  != ''){
                result_final.push(result[i]);
                }
               }
            }
            setSearchData(result_final);
            console.log(result_final)
            //seting pagenation data
            var slice = result_final.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result_final.length / perPage));
            setOrgTableData(result_final);
            setTableData(slice);
            let totalAllotQuantity = 0;
            let totaltransferQuantity = 0;
            let totalReceiverQuantity = 0;
            let totalpurchaseQuantity = 0;
            let totalfarmersbenefited = 0;
            let totaldistributedquantity = 0;
            let totalQuantity = 0;
            let totalForecastQuantity = 0;
            let totalaoApprovedQuantity = 0;

            for(let i=0; i<result_final.length; i++){
                if(result_final[i]?.Allotment){
                 totalAllotQuantity = totalAllotQuantity+parseInt(result_final[i]?.Allotment);
                }
                 if(result_final[i]?.transferQuantity){
                 totaltransferQuantity = totaltransferQuantity+parseInt(result_final[i]?.transferQuantity);
              }
              if(result_final[i]?.receivedQuntity){
                 totalReceiverQuantity = totalReceiverQuantity+parseInt(result_final[i]?.receivedQuntity);
              }
              if(result_final[i]?.forcastQuantity){
                totalForecastQuantity = totalForecastQuantity+parseInt(result_final[i]?.forcastQuantity);
             }
             if(result_final[i]?.aoApproved){
                totalaoApprovedQuantity = totalaoApprovedQuantity+parseInt(result_final[i]?.aoApproved);
             }
              if(result_final[i]?.purchaseDemandQuantity){
                 totalpurchaseQuantity = totalpurchaseQuantity+parseInt(result_final[i]?.purchaseDemandQuantity);
              }
              if(result_final[i]?.farmersBenifited){
                 totalfarmersbenefited = totalfarmersbenefited+parseInt(result_final[i]?.farmersBenifited);
              }
              if(result_final[i]?.quantity){
                 totalQuantity = totalQuantity+parseInt(result_final[i]?.quantity);
              }
              if(result_final[i]?.disrtributedQuantity){
                 totaldistributedquantity = totaldistributedquantity+parseInt(result_final[i]?.disrtributedQuantity);
              }
             }
             setTotalAllotQuantity(totalAllotQuantity);
             setTotalQuantity(totalQuantity); 
             setTotalReceivedQuantity(totalReceiverQuantity); 
             setTotalTransferQuantity(totaltransferQuantity); 
             setTotaldisrtributedQuantity(totaldistributedquantity); 
             setTotalpurchaseQuantity(totalpurchaseQuantity);
             setTotalfarmersBenifited(totalfarmersbenefited); 
             setTotalForecastQuantity(totalForecastQuantity); 
             setTotalaoApproved(totalaoApprovedQuantity); 
        };
        // call the function
        fetchDistributionWise()
            // make sure to catch any error
            .catch(console.error);
    }, []);
    const handlerowsperPage = (e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(searchKeyTable);
        }
        else {
            const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(orgtableData);
        }
    }
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }
    const loadMoreData = (offsetdata) => {
        const data = orgtableData;
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setOffSet(offsetdata);
            setSearchData(searchKeyTable);
        } else {
            const slice = data.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(data.length / perPage))
            setOffSet(offsetdata);
            setTableData(slice);
            setSearchData(data);
        }
    };
    const handleViewForecastDetails = (obj) => {
    navigate("/forecast-dao/" + obj.district+"/ao");
    };
    const formatter = new Intl.NumberFormat('en-IN')

    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}

            <Container>
                <Row>
                    <Col style={{marginTop:24}}>
                        <Title>District Wise</Title>
                    </Col>
                    </Row>
                    <Row>
                   
                    <Col lg={2}>
                    <label>Season</label>

                        <div className="row_div">
                            <div className="input_div" >
                                <select value={season} onChange={handleInput} name="season"
                                    style={{ margin: 0, background: 'transparent' }}
                                >

                                    <option value="">All</option>
                                    {seasonData?.length != 0 && <>
                                        {seasonData?.map((item, index) => {
                                            return (
                                                <option value={item.CombYearSeason}>{item.CombYearSeason}</option>
                                            )
                                        })}
                                    </>}

                                </select>
                            </div>
                        </div>
                    </Col>
                    <Col lg={2}>
                    <label>Component</label>

                        <div className="row_div">
                        <div className="input_div" >
                              
                            <select
                                name="seedId"
                                value={seedId}
                                onChange={handleInput}
                                style={{ margin: 0, background: 'transparent' }}>
                            <option value="">All</option>
                            {seedData.length!=0 &&
                                 <>
                                {seedData.map((item,index)=>{
                                    return (
                                        <option value={item.id}>{item.CropName}</option>
                                    )
                                })}
                            </>}
                              
                            </select>
                           
                            </div>

                        </div>
                    </Col>
                    <Col lg={2}>
                    <label>Type</label>

                        <div className="row_div">
                        <div className="input_div" >
                              
                            <select
                                name="varietyId"
                                value={varietyId}
                                onChange={handleInput}
                                style={{ margin: 0, background: 'transparent' }}
                                
                            >
                            <option value=""> All</option>

                            {varietyData.length!=0 &&
                            <>
                                {varietyData.map((item,index)=>{
                                    return (
                                        <option value={item.id}>{item.Variety}</option>
                                    )
                                })}
                            </>}
                              
                            </select>
                           
                            </div>

                        </div>
                    </Col>
                  {/*   <Col lg={2} style={{marginTop:20}}>
                    <button className="add_button" onClick={() => handleDistributor()}>
                                <p className="pcat_title">Districts</p></button>
                    </Col> */}
                    <Col lg={3} style={{marginTop:25}}>
                    <button className="add_button" onClick={() => handleDistributor()}>
                            <p className="pcat_title">Clear All Filters</p></button>
                  </Col>
                  <Col lg={3} style={{marginTop:25}}>
                        <button className="export_button" onClick={(e) => exportToCSV(orgtableData)}>
                            <p className="pcat_title">Export Excel</p>
                        </button>
                    </Col>
                  {/*   <Col lg={3} style={{marginTop:20}}>
                        <div className="row_div">
                            <div className="input_div" >
                                <input type="type" style={{ margin: 0, background: 'transparent' }}
                                    name="filterValue"
                                    //    value={filterValue}
                                    onChange={(e) => handleSearch(e)}
                                    placeholder="Search" />
                                <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                            </div>

                        </div>
                    </Col>
                  */}   </Row>
            </Container>
            <Container>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
                        <Table className="tablelist" responsive>
                            <thead>
                                <tr className="tablelhdr_img">
                                    <th>District Name </th>
                                    <th>Forecasted
                                    {currentSort=='up' && <img  onClick={()=>onSortChange('forcastQuantity')} src={sort_default} />}
                                    {currentSort=='default' && <img  onClick={()=>onSortChange('forcastQuantity')} src={sort_descending } />}
                                    {currentSort=='down' && <img  onClick={()=>onSortChange('forcastQuantity')} src={sort_ascending} />}
                                    </th>
                                    <th>AO Approved
                                    {currentSort=='up' && <img  onClick={()=>onSortChange('aoApproved')} src={sort_default} />}
                                    {currentSort=='default' && <img  onClick={()=>onSortChange('aoApproved')} src={sort_descending } />}
                                    {currentSort=='down' && <img  onClick={()=>onSortChange('aoApproved')} src={sort_ascending} />}
                                    </th>
                                    <th>Allotted
                                    {currentSort=='up' && <img  onClick={()=>onSortChange('Allotment')} src={sort_default} />}
                                    {currentSort=='default' && <img  onClick={()=>onSortChange('Allotment')} src={sort_descending} />}
                                    {currentSort=='down' && <img  onClick={()=>onSortChange('Allotment')} src={sort_ascending} />}
                                    </th>
                                    <th>Purchased
                                    {currentSort=='up' && <img  onClick={()=>onSortChange('purchaseDemandQuantity')} src={sort_default} />}
                                    {currentSort=='default' && <img  onClick={()=>onSortChange('purchaseDemandQuantity')} src={sort_descending } />}
                                    {currentSort=='down' && <img  onClick={()=>onSortChange('purchaseDemandQuantity')} src={sort_ascending} />}
                                    </th>
                                    <th>Received
                                    {currentSort=='up' && <img  onClick={()=>onSortChange('receivedQuntity')} src={sort_default} />}
                                    {currentSort=='default' && <img  onClick={()=>onSortChange('receivedQuntity')} src={sort_descending } />}
                                    {currentSort=='down' && <img  onClick={()=>onSortChange('receivedQuntity')} src={sort_ascending} />}
                                    </th>
                                    <th>Distributed
                                    {currentSort=='up' && <img  onClick={()=>onSortChange('disrtributedQuantity')} src={sort_default} />}
                                    {currentSort=='default' && <img  onClick={()=>onSortChange('disrtributedQuantity')} src={sort_descending } />}
                                    {currentSort=='down' && <img  onClick={()=>onSortChange('disrtributedQuantity')} src={sort_ascending} />}
                                    </th>
                                    <th style={{ textAlign: 'right' }}>Farmers Benefited
                                    {currentSort=='up' && <img  onClick={()=>onSortChange('farmersBenifited')} src={sort_default} />}
                                    {currentSort=='default' && <img  onClick={()=>onSortChange('farmersBenifited')} src={sort_descending } />}
                                    {currentSort=='down' && <img  onClick={()=>onSortChange('farmersBenifited')} src={sort_ascending} />}</th>
{/*                                     <th style={{ textAlign: 'right' }}>Action</th> */}
                                </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {tableData.length != 0 && <tbody>
                                
                                {tableData.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index} className="tablelhdr_img">
                                                {/*<td>{obj.id}</td> */}
                                                <td>{obj.DistrictName}</td>
                                                <td>{formatter.format(obj.forcastQuantity)}</td>
                                                <td>{formatter.format(obj.aoApproved)}</td>
                                                <td>{formatter.format(obj.Allotment)}</td>
                                                <td>{formatter.format(obj.purchaseDemandQuantity)}</td>
                                                <td>{formatter.format(obj.receivedQuntity)}</td>
                                                <td>{formatter.format(obj.disrtributedQuantity)}</td>
                                                <td>{formatter.format(obj.farmersBenifited)}</td>
                                                {/* <td style={{ textAlign: 'center' }}>
                                                    <div className="row_table_edit" onClick={() => handleViewForecastDetails(obj, "view")}>
                                                        <img src={View} />
                                                        <p>VIEW</p>
                                                    </div>
                                                </td> */}
                                            </tr>
                                        </>
                                    );
                                })}
                                <tr>
                                                {/*                                                 <td>{obj.id}</td> */}
                                                <td className="ptotal_title">Total:</td>
                                                <td className="ptotal_title">{formatter.format(totalForecastsQuantity)}</td>
                                                <td className="ptotal_title">{formatter.format(totalaoApproved)}</td>
                                                <td className="ptotal_title">{formatter.format(totalAllotQuantity)}</td>
                                                <td className="ptotal_title">{formatter.format(totalpurchaseDemandQuantity)}</td>
                                                <td className="ptotal_title">{formatter.format(totalreceivedQuntity)}</td>
                                                <td className="ptotal_title">{formatter.format(totaldisrtributedQuantity)}</td>
                                                <td className="ptotal_title">{formatter.format(totalfarmersBenifited)}</td>
                                            </tr>
                            </tbody>
                            }
                        </Table>
                        {tableData.length != 0 &&
                            <div>
                                <div className="pagination_row_div">
                                    <p className="pagination_title">Showing items per page:</p>
                                    <div className="pagination_input_div" >
                                        <select className="pagination_select"
                                            style={{ margin: 0, background: 'transparent' }}
                                            name="pagination_value"
                                            value={perPage}
                                            onChange={handlerowsperPage} >
                                            <option value={20}>20</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="pagination_row_right_div">
                                    <p className="pagination_total">Showing {offset + 1} to {searchData.length < offset + perPage &&
                                        <span>{searchData.length}</span>}
                                        {searchData.length > offset + perPage &&
                                            <span>{offset + perPage} </span>} of {searchData.length} items</p>
                                    <ReactPaginate
                                        previousLabel={<ArrowBackIosIcon className="pagination_prev_btn" color="#2438a7" />}
                                        nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7" />}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={currentPage} />
                                </div>
                            </div>
                        }                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </Wrapper>
    )
}
export default DashboardDistrictWise