import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./DistributionSeeds.styles"
import { Row, Container, Col } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import Edit from "../../images/edit.png";
import plus from "../../images/white_plus.png"
import { useNavigate, Link } from "react-router-dom";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle"
import { LoadingSpinner } from "../LoadingSpinner/index";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Delete from "../../images/delete.png"


const DistributionSeeds = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    const [distributionSeeds, setDistributionSeeds] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);


    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(50);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey, setSearchKey] = useState('n');
    const [searchKeyTable, setSearchKeyTable] = useState('');
    const[seasonData,setSeasonData]=useState([]);
    const[season,setSeason]=useState('')

    const handleSearch = (e) => {
        let filterValue = e.target.value;
        setCurrentPage(0);
        if (filterValue == '') {
            let offsetsearch = 0;
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setTableData(slice);
            setSearchData(orgtableData);
            setOffSet(offsetsearch);
            setCurrentPage(0);
            setSearchKey('n');
        }
        else {
            let offsetsearch = 0;
            const filteredData = orgtableData.filter(item => {
                return (item ?.SeedName ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                    item ?.VarietyName ?.toLowerCase().includes(filterValue.toLowerCase()))
            });

            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            setOffSet(offsetsearch);
            setSearchKeyTable(filteredData);
            setTableData(slice);
            setSearchData(filteredData);
        }
    }

    const handleInput=async(e)=>{
        const {name,value}=e.target;
        if(name=="season"){
            setSeason(value);
            setIsLoading(true)
            const user_id = localStorage.getItem("UserID");
            let resultdata = await API_Auth.getSeedDistributor(user_id,value);
            let result=resultdata.data
            setDistributionSeeds(result);
            setSearchData(result);
            setIsLoading(false);
            //seting pagenation data
            let offset = 0;
            var slice = result.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result.length / perPage));
            setOrgTableData(result);
            setTableData(slice);
            setOffSet(offset);
            setCurrentPage(0); 
        }
    }

    
    useEffect(() => {
        setIsLoading(true);

        const fetchAsset = async () => {
            let id=localStorage.getItem("UserID")
            const seasonsresult=await API_Auth.getSeasons();
            setSeasonData(seasonsresult);
            let resultdata=await API_Auth.getSeedDistributor(id,'')
            let result=resultdata.data
            //let result = await API_Auth.getVariety();
            //let id=localStorage.getItem("UserID")
            //let result = await API_Auth.getStockRegister(id);
            setDistributionSeeds(result);
            setSearchData(result);
            setIsLoading(false);
            //seting pagenation data
            var slice = result.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result.length / perPage));
            setOrgTableData(result);
            setTableData(slice);
        };
        // call the function
        fetchAsset()
            // make sure to catch any error
            .catch(console.error);
    }, []);
    const handlerowsperPage = (e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(searchKeyTable);
        }
        else {
            const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(orgtableData);
        }
    }
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        const data = orgtableData;
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setOffSet(offsetdata);
            setSearchData(searchKeyTable);
        } else {
            const slice = data.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(data.length / perPage))
            setOffSet(offsetdata);
            setTableData(slice);
            setSearchData(data);
        }
    }
    const handleDistribute=(obj)=>{
        navigate("/distribute-seed-adhar", { state: { distributordataSeed: obj} })
    }
    const formatter = new Intl.NumberFormat('en-IN');

    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}

            <Container>
                <Row>
                    <Col lg={4} style={{ paddingLeft: 0 ,marginTop:25}}>
                        <Title>Distribute Seeds</Title>
                    </Col>
                    <Col lg={4} style={{ paddingLeft: 0, }}>
                    <label>Season</label>

                        <div className="row_div">
                            <div className="input_div" >
                                <select value={season} onChange={handleInput} name="season"
                                    style={{ margin: 0, background: 'transparent' }}
                                >

                                    <option value="">All</option>
                                    {seasonData.length != 0 && <>
                                        {seasonData.map((item, index) => {
                                            return (
                                                <option value={item.CombYearSeason}>{item.CombYearSeason}</option>
                                            )
                                        })}
                                    </>}

                                </select>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} style={{ paddingRight: 0,marginTop:25 }}>
                        <div className="row_div" style={{ paddingRight: 0 }}>
                            {/*                             <p className="title">Filter By:</p> */}
                            <div className="input_div" >
                                <input type="type" style={{ margin: 0, background: 'transparent' }}
                                    name="filterValue"
                                    //    value={filterValue}
                                    onChange={(e) => handleSearch(e)}
                                    placeholder="Search" />
                                <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                            </div>

                        </div>
                    </Col>
                   
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
                        <Table className="tablelist" responsive>
                            <thead>
                                <tr>
{/*                                     <th>Id</th> */}
                                    <th>Component</th>
                                    <th>Type</th>
                                    <th>Season</th>
                                    <th>Quantity</th>
                                    <th>UOM</th>
                                    <th style={{ textAlign: 'right' }}>Action</th>
                                </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {tableData.length != 0 && <tbody>
                                {tableData.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
{/*                                                 <td>{obj.id}</td> */}
                                                <td>{obj.SeedName}</td>
                                                <td>{obj.VarietyName}</td>
                                                <td>{obj.year}-{obj.season}</td>
                                                <td>{formatter.format(obj.quantity)}</td>
                                                <td>{obj.Uom}</td>  
                                                <td style={{ textAlign: 'right' }}>
                                                 <div className="row_table_edit" style={{background: '#c1edc1'}} onClick={() => handleDistribute(obj)}>
                                                        <p>Distribute</p>
                                                    </div> 
                                                   
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                            }
                        </Table>
                        {tableData.length != 0 &&
                            <div>
                                <div className="pagination_row_div">
                                    <p className="pagination_title">Showing items per page:</p>
                                    <div className="pagination_input_div" >
                                        <select className="pagination_select"
                                            style={{ margin: 0, background: 'transparent' }}
                                            name="pagination_value"
                                            value={perPage}
                                            onChange={handlerowsperPage} >
                                            <option value={20}>20</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="pagination_row_right_div">
                                    <p className="pagination_total">Showing {offset + 1} to {searchData.length < offset + perPage &&
                                        <span>{searchData.length}</span>}
                                        {searchData.length > offset + perPage &&
                                            <span>{offset + perPage} </span>} of {searchData.length} items</p>
                                    <ReactPaginate
                                        previousLabel={<ArrowBackIosIcon className="pagination_prev_btn" color="#2438a7" />}
                                        nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7" />}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={currentPage} />
                                </div>
                            </div>
                        }</Col>
                </Row>
            </Container>
            <ToastContainer />
        </Wrapper>
    )
}
export default DistributionSeeds