import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./styles"
import { Row, Container, Col } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import { useNavigate, useParams } from "react-router-dom";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle"
import { LoadingSpinner } from "../LoadingSpinner/index";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Modal from "react-bootstrap/Modal";


const TestRequests = () => {
    const {
        isExpanded,
    } = useContext(UserContext);

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);

    let { PurchaseID, type } = useParams();


    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey, setSearchKey] = useState('n');
    const [searchKeyTable, setSearchKeyTable] = useState('');
    const[userRole,setUserRole]=useState('');
    const[testModal,showtestModal]=useState('');
    const [test_suppliers, setTestSuppliers] = useState([]);
    const [test_supplierId, setTestSupplier] = useState('');
    const [supplierErr, setSupplierErr] = useState('');
    const [testing_coments, setTestcmts] = useState('');
    const [commentsErr, setcommentsErr] = useState('');
    const [testing_det, setTestingDet] = useState('');
    const handleSearch = (e) => {
        let filterValue = e.target.value;
        setCurrentPage(0);
        if (filterValue == '') {
            let offsetsearch = 0;
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setTableData(slice);
            setSearchData(orgtableData);
            setOffSet(offsetsearch);
            setCurrentPage(0);
            setSearchKey('n');
        }
        else {
            let offsetsearch = 0;
            const filteredData = orgtableData.filter(obj => {
                return (obj ?.SeedName ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                            obj ?.VerietyID ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                                obj ?.DemandQuantity ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                                            obj ?.transitQuantity ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                                                obj ?.receiveQuantity ?.toLowerCase().includes(filterValue.toLowerCase()))
            });

            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            setOffSet(offsetsearch);
            setSearchKeyTable(filteredData);
            setTableData(slice);
            setSearchData(filteredData);
        }
    }
    useEffect(() => {
        setIsLoading(true);
        const user_role = localStorage.getItem("loggedRole");
        const userid = localStorage.getItem("UserID");
        console.log("userid", userid)
        setUserRole(user_role);

        const fetchForecast = async () => {

            let suppliersdata = await API_Auth.getSuppliers('testing');
            suppliersdata.sort((a, b) => a.UserName.localeCompare(b.UserName));
            console.log("suppliersdata", suppliersdata)
            setTestSuppliers(suppliersdata);

            const result = await API_Auth.getRequestTestingAPI('SHOWTESTAPPROVER','', '', '', '','',
            userid,'','', '', '', '', '', '','', '');  
            console.log("result", result)
            setIsLoading(false);
            //seting pagenation data
            var slice = result.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result.length / perPage));
            setOrgTableData(result);
            setTableData(slice);
        };
        // call the function
        fetchForecast()
            // make sure to catch any error
            .catch(console.error);
    }, []);
    const handlerowsperPage = (e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(searchKeyTable);
        }
        else {
            const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(orgtableData);
        }
    }
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        const data = orgtableData;
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setOffSet(offsetdata);
            setSearchData(searchKeyTable);
        } else {
            const slice = data.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(data.length / perPage))
            setOffSet(offsetdata);
            setTableData(slice);
            setSearchData(data);
        }
    }
    const handleView = (obj) => {
        console.log(obj);
        setcommentsErr("");
        setSupplierErr("");
        showtestModal(true);
        setTestingDet(obj);
    }
    const handleClose = () => {
        showtestModal(false);
    }
    const handlego_toTesting = async() => {
        showtestModal(false);
        console.log(testing_det);
        console.log(test_supplierId,testing_coments);
        if(testing_coments == ''){
            setcommentsErr("please send any comments");
        }
        else  if(test_supplierId == ''){
            setSupplierErr("please select testing agency");
        }
        else if(testing_coments != ''){
            console.log(testing_det);
             const result = await API_Auth.getRequestTestingAPI('STATUSCHANGEBYAPPROVER','', '', 
             '', '','', '',test_supplierId,'SendToTest', '', '', testing_coments, '', '', testing_det.id, '');  
               let res = result[0];
                  if (res.Flag == "Success") {
                   setIsLoading(false);
                   toast.success("Request testing successfully");
                 } else {
                   setIsLoading(false);
                   toast.error("Something went wrong please try again");
                 }
                 setInterval(() => {
                   navigate(0);
                 }, 2000);  
        }
    }
    

    const handleInput = (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
if(name== 'supplier'){
    setTestSupplier(value);
}
if(name== 'testing_coments'){
    setTestcmts(value);
}
    };
    const formatter = new Intl.NumberFormat('en-IN')
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}
                        <Container style={{ marginTop: 42 }}>
                <Row>
                    <Col lg={4} style={{ paddingLeft: 0 }}>
                   <Title> TEST REQUESTS</Title> 
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
                    <Table className="tablelist" responsive>
                            <thead>
                                <tr className="table_wrap">
                                <th>Season</th>
                                <th>Component</th>
                                <th>Type</th>
                             <th>Sender Comment</th>
                             <th>Status</th>      
                             <th>Date</th> 
                             <th>DAO Comment</th>
                             <th>Testing Agency</th>      
                             <th>Testing Comment</th> 
                             <th>TestingDate</th> 
                             <th>SeedReport</th>
                             <th>ReportFile</th>
                             <th style={{ textAlign: "center" }}>Action</th> 
                             </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {tableData.length != 0 && <tbody>
                                {tableData.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index} className="table_wrap">
                                            <td>{obj.season}-{obj.year}</td>
                                             <td>{obj.seedName}</td>
                                             <td>{obj.verietyName}</td>
                                             <td>{obj.senderComment}</td>
                                            <td className="pcat_title">{obj.status}</td>
                                             <td>{obj.insertDate}</td>
                                             <td>{obj.daoComment}</td>
                                             <td>{obj.testingAgencyName}</td>
                                             <td>{obj.reportComment}</td>
                                            
                                             <td>{obj.reportDate}</td>
                                             <td className="pcat_title">{obj.seedReport}</td>
                                             <td>{obj.reportFile}</td>
                                             {obj.status == 'Initiated'&&
                                             <td style={{ textAlign: "center" }}>
                                             <div className="row_table_edit " onClick={() => handleView(obj)} 
                                             style={{ marginRight: 10,background: 'antiquewhite',width: 95}}>
                                                        <p>Send To Test</p>
                                                    </div>
                                              </td>
                                                   }
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                            }
                        </Table>
                        <div>
                        <div className="pagination_row_div">
                            <p className="pagination_title">Showing items per page:</p>
                            <div className="pagination_input_div" >
                                <select className="pagination_select"
                                 style={{ margin: 0, background: 'transparent' }}
                                    name="pagination_value"
                                    value={perPage}
                                    onChange={handlerowsperPage} >
                                    <option value={4}>4</option>
                                    <option value={8}>8</option>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                </select>
                                </div>
                        </div>
                        <div className="pagination_row_right_div">
                        <p className="pagination_total">Showing {offset+1} to {searchData.length<offset+perPage&&
                        <span>{searchData.length}</span> }
                        {searchData.length>offset+perPage&&
                        <span>{offset+perPage }</span>} of {searchData.length} items</p>
                         <ReactPaginate
                            previousLabel={<ArrowBackIosIcon  className="pagination_prev_btn" color="#2438a7"/>}
                            nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7"/>}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"} 
                            forcePage={currentPage} />
                    </div>
                    </div>
                    </Col>
                </Row>
            </Container>
            <Modal
        show={testModal}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Comments</Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <div className="inputdiv">
                            <label>Select Testing Agency</label>
                            <br />
                            <select
                                name="supplier"
                                value={test_supplierId}
                                onChange={handleInput}
                                style={{ width: "85%" }}>
                                <option value=''>Select Testing Agency</option>
                                {test_suppliers.length != 0 && <>
                                    {test_suppliers.map((item, index) => {
                                        return (
                                            <option value={item.id}>{item.UserName}</option>
                                        )
                                    })}
                                </>}
                            </select>
                            {supplierErr != '' && <p className="alert-message">{supplierErr}</p>}

                        </div>
        <div>
                            <label >Comments</label>
                            <br />
                            <div className="input_div" >
                                 <textarea
                                        value={testing_coments}
                                        onChange={handleInput}
                                        style={{ width: "85%",height: 100 }}
                                        name="testing_coments" placeholder="Type Something"
                                        rows={5}
                                        cols={5}
                                    />
                            </div>
                             {commentsErr != '' && <p className="alert-message">{commentsErr}</p>}
                        </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
                        <div className="row_div">
                            <button className="add_button" onClick={() => handlego_toTesting()} style={{
                                padding: 12,
                                height: 45,
                                margin: 10
                            }}>
                                <p className="pcat_title">Submit Testing</p></button>
                        </div>
                    </Modal.Footer>
      </Modal>
            <ToastContainer />
        </Wrapper>
    )
}
export default TestRequests