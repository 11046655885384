import styled from "styled-components";

export const Wrapper = styled.div`
background: #fff;
margin-top: 48px;
.pseudo_border {
  position: relative;
  display: inline-block;
  font-weight: 600;
  line-height: 1.2;
  font-size: 48px;
}
.margin_top_btm{
  margin-bottom: 3rem!important;
  margin-top: 3rem!important;
}
.margin_top{
    margin-top: 3rem!important; 
}
.pseudo_border:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  margin: 10px auto;
  width: 90px;
  height: 3px;
  background: #247158;
}
.text_center{
  text-align: center!important;
}
.contact_box {
    padding: 2rem;
}
.h4_styles{
    margin-bottom: 1.5rem!important;
    font-family: 'Poppins', sans-serif;
}
.d_flex_row{
    flex-direction: row!important;
    display: flex!important;
}
.align_self_start{
    align-self: flex-start!important;
    justify-content: flex-start!important;
    h5{
        margin-bottom: 0.25rem!important;
    }
    p{
        margin-bottom: 0!important;
    }  
}
.link_body{
    color: #007bff;
    text-decoration: none;
    background-color: transparent;    
}
`