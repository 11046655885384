import styled from "styled-components";

export const Wrapper = styled.div`
  
  overflow: hidden;
  background-color:#247158;
  padding: 12px 10px;
  height: 90px;
  position: relative;
  width: 100%;
  display: flex;
  overflow: 'auto',
  whiteSpace: 'nowrap'
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
 .link_bg{
  li {
    float: left;
  }
  
  li a {
    display: block;
    color: white;
    text-align: center;
    padding: 16px;
    text-decoration: none;
    font-size:17px;
    letter-spacing:0.5px
  }
  
  li a:hover {
    color:#fd7e14
  }
}
  .link_bg{
    a{
    color: white;
    text-align: center;
    padding: 16px;
    text-decoration: none;
    font-size:17px;
    letter-spacing:0.5px
    :hover {
      color:#fd7e14
    }
  }
  }

.helpline_cont{
  margin: 24px;
h6{
  color: white;
  font-size: 16px;
  font-weight: 700;
}
}

  .sidebar-headers{
    display: contents;
  }
  .logout{
    top: 10px;
    left: 5px;
    display:flex;
    flex-direction:row;
    color:white;
    text-decoration:none;
    margin-top:16px;
    background:#fd7e14;
    height:40px;
    width:85px;
    border-radius:6px;
    border-color:#fd7e14;
    p{
      margin-left: 12px;
      margin-top: 1px;
      font-weight: 600;
      font-size: 18px;
  
    }

    h6{
      margin-left:10px;
      font-size:20px;
    }

  }
  

  svg {
    color:var(--lightblue);
    margin-left:16px;
    font-size:28px;
    margin-top:5px;
  }
  

  .bg-blue{background:#3e3bad;}
  .header_row{
    display:flex;
    flex-direction:row;
    position:absolute;
    right:0;
    margin-right:30px;
  }
  .header_icons {
    background:var(--lightblue);
    height:25px;
    width:25px;
    margin-right:10px;
    border-radius:4px;
    padding:1px;
    img {
      width:10px;
      height:10px;
      margin:5px 10px 10px 5px;
    }
  }
  .header-rights{
    position: absolute; 
    right: 25px;
    display:flex;
    flex-direction:row;
      .select-module{
        font-family: Roboto;
        width: 200px;
        height: 40px;
        padding: 7px;
        margin-right: 13px;
        border-radius: 8px;
      }

      .section-two{
        display:flex;
        .icon{
          height: 33px;
          margin: 6px;  
        }
        .profile{
          top: 10px;
          left: 5px;
          display:flex;
          flex-direction:row;
          .name{
            font-weight:700;
            font-size: 20px;
            color:white;
            margin-top:8px;
  
          }
          h6{
            margin-left:10px;
            font-size:25px;
          }
         
        
        .logout{
          top: 10px;
          left: 5px;
          display:flex;
          flex-direction:row;
          color:#efb9be;
          margin-right:20px;
          h6{
            margin-left:10px;
            font-size:20px;
          }
  
        }
      }
    
  }


  .header-right-admin{
    left: 70%;
  }
  .header-right-nonadmin{
    left: 53%;
  }
  .header-right{
    position: relative;    
    display:flex;
    .icon{
      width: 35px;
      cursor:pointer;
      margin: 0 10px;
      height: 35px;
    }
    .section-two{
      display:flex;
      .icon{
        height: 33px;
        margin: 6px;  
      }
      .profile{
        top: 10px;
        left: 5px;
        display:flex;
        flex-direction:row;
        .name{
          font-weight:700;
          font-size: 20px;
          color:white;

        }
        h6{
          margin-left:10px;
          font-size:25px;
        }
       
      }
      .logout{
        top: 10px;
        left: 5px;
        display:flex;
        flex-direction:row;
        color:#efb9be;
        margin-right:20px;
        h6{
          margin-left:10px;
          font-size:20px;
        }

      }
     
    div{
      top: 7px;
      line-height: 1px;
      position: relative;
      .name{
        color:white;
        font-size:14px;
      }
      .logout{
        font-weight:700;
        font-size: 20px;
        color:#B2B2B2;
        curson:pointer;
        text-decoration: none;
      }
    }
  }
  .header_icons_user {
    background:#B2B2B2;
    height:35px;
    width:35px;
    margin-right:10px;
    border-radius:4px;
    padding:1px;
    img {
      width:20px;
      height:20px;
      margin:5px 10px 10px 5px;
    }
  }
  .select-module{
    font-family: Roboto;
    width: 200px;
    height: 40px;
    padding: 7px;
    margin-right: 13px;
    border-radius: 8px;
  }
  
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: var(--maxWidth);
  padding: 6px 0;
  margin: 0 auto;
  background:#FFFFFF;
  height:70px;
  color: var(--white);
  a {
    color: var(--white);
    text-decoration: none;
  }
`;

export const LCLogoImg = styled.img`
width: 68px;
height:68px;
margin-left: 12px;  @media screen and (max-width: 500px) {
    width: 40px;
  }
`;



 