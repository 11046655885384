import styled from "styled-components";

export const Wrapper = styled.div`


.tablelist {
  margin-top:15px;
  margin-right:5px;;
}
 
thead{
  color:black;
  background:#c0d3ef;
  border:none !important;
  border-radius:10px;
}
td{
  color:black;
  font-weight:400;
  font-size:17px;
  height: 42px;
  /* padding: 0px 14px;
  vertical-align: middle; */
  font-family:Roboto;
  text-transform: capitalize;
}
th{
  font-size:16px;
  height:42px;
  padding:0px 14px;
  vertical-align: middle;
  font-weight:500;
  font-family:Roboto;

}
.table-head-content {
  padding: 13px 13px 0 13px;
  background: white;
  border-bottom: 1px solid #E2E2E2;   
}  
.td_quntity_variety{
  border: 1px solid #084298;
  color: black;
  font-weight: 400;
  font-size: 14px;
  height: 30px;
  padding: 5px;
  font-family: Roboto;
  text-transform: capitalize;
  text-align: center;
}
.row_div {
  display:flex;
  flex-direction:row;
  padding: 8px 0px;
}
.input_div {
  width: 100%;
  color:var(--black);
  font-family: 'ARIAL';
  height:45px;
  border-radius:6px;
  margin:5px 0px;
  background:white;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  :focus {
    border: 0px solid #DFDFDF !important; 
  }
  input,select{
    border: 2px solid #DFDFDF;
     :focus {
       outline: none !important;
       border-color:none; 
       border: 0px solid #DFDFDF !important; 
     }
    }
}
.title{
  font-family:Roboto;
  font-size:16px;
  font-weight:600;
  margin-left:10px;
  margin-bottom:0 !important;
  margin-top:15px;

}

.adddiv {
  background:white;
  width: 80%;
  margin-top:15px;
  margin-left:20px;
  border-radius:5px;
  padding: 10px 0px;
  
  
  
}
.rowdiv {
  margin:10px;

}
.add_button {
  display:flex;
  flex-direction:row;
  background:#6EDC8F;
  color:black;
  border:#4EACFB;
  padding:10px;
  height:45px;
  margin:15px 10px 5px;
  text-align:center;
  justify-content:center;
  align-item:center;
  box-shadow: 0px 2px 2px #00000030;
border-radius: 6px;
opacity: 1;  
}
.tablehead{
  border: 1px solid #084298;
    text-align: center;
    width: 320px;
    height: 30px;
    padding: 8px;
}
.div_tablehead{
  border: 1px solid #084298;
  text-align: center;
  font-size: 14px;
  height: 30px;
  padding: 0px !important;
}
.table_div{
  text-align: -webkit-center;
  padding: 0px;
}
.div_table_head{
  th{
    padding: 0px 70px;
    border: 1px solid #084298;
    height:30px;
}
td{
  border-right-width: 1px;
    border-bottom-width: 1px;
}
}
.add_cat_button {
  display:flex;
  flex-direction:row;
  background:#000044;
  color:white;
  border:#4EACFB;
  width:85%;
  text-align:center;
  justify-content:center;
  align-item:center;
  box-shadow: 0px 2px 2px #00000030;
  border-radius: 6px;
  opacity: 1;    border:#4EACFB;
  padding:10px;
  height:40px;
  width:85%;
  margin: 50px auto;
  font-weight:bold;
  margin-bottom: 24px;

}

.alert-message{
  color: red;
  margin-left: 15px;
}

`