import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./ForecastAODetails.styles"
import { Row, Container, Col } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import Edit from "../../images/edit.png";
import plus from "../../images/white_plus.png"
import { useNavigate, useParams } from "react-router-dom";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle"
import { LoadingSpinner } from "../LoadingSpinner/index";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


const ForecastAODetails = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    const [foreCastData, setForeCastData] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);

    let { forecastID } = useParams();


    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(50);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey, setSearchKey] = useState('n');
    const [searchKeyTable, setSearchKeyTable] = useState('');


    const [inputFields, setInputFields] = useState([{
        SeedName: '',
        VarietyName: '',
        Quantity: '',
        approvedQuantity: '',
        suppliers: [],
    }])

    useEffect(() => {
        setIsLoading(true);

        const fetchForecast = async () => {
            let result = await API_Auth.getForeCastDetailsBYID(forecastID);
            console.log("result", result)
            setForeCastData(result.forcastData[0]);
            setSearchData(result.SeedData);
            var seeddata = result.SeedData;
            seeddata.forEach(v => {
                v.suppliers = [];

            });
            for (var i = 0; i < seeddata.length; i++) {

                seeddata[i].approvedQuantity = seeddata[i].Quantity
            }
            setIsLoading(false);
            console.log("seedata", seeddata)
            setInputFields(seeddata);

            //sesetSearchDatating pagenation data
        };
        // call the function
        fetchForecast()
            // make sure to catch any error
            .catch(console.error);
    }, []);
    const handleChange = (index, evnt) => {
        const { name, value } = evnt.target;
        console.log(name,value,index)
        const list = [...inputFields];
        if (name == "approvedQuantity") {

            list[index][name] = value;

        }

        setInputFields(list);
    }
    const handleSubmit=()=>{
        console.log(inputFields);

    }
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}

            <Container>
                <Row>
                    <Col lg={4} style={{ paddingLeft: 0 }}>
                        <Title>ForeCast Details</Title>
                    </Col>
                </Row>
            </Container>
            <Container>
                <div className="row_div">
                    <Row>
                        <Row>
                            <Col lg={3}>
                                <p className="pcat_title">Name:</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{foreCastData.Name}</p>
                            </Col>

                            <Col lg={3}>
                                <p className="pcat_title">Season :</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{foreCastData.Season}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <p className="pcat_title">Status :</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{foreCastData.Status}</p>
                            </Col>
                            {/* </Row>
                         <Row> */}
                            <Col lg={3}>
                                <p className="pcat_title">year :</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{foreCastData.year}</p>
                            </Col>
                        </Row>
                    </Row>
                </div>
            </Container>
            <Container>
                <Row>
                    <Col lg={4} style={{ paddingLeft: 0 }}>
                        <Title>Seed Details</Title>
                    </Col>

                </Row>
            </Container>
            <Container>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
                        <Table className="tablelist" responsive>
                            <thead>
                                <tr>
                                    <th>SeedName </th>
                                    <th>Type Name</th>
                                    <th>Quantity</th>
                                    <th>Approved Quantity</th>
                                    <th>Suppliers</th>


                                </tr>
                            </thead>
                            {inputFields.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {inputFields.length != 0 && <tbody>
                                {inputFields.map((data, index) => {
                                    const { SeedName, Quantity, VarietyName, approvedQuantity, suppliers
                                    } = data;
                                    return (
                                        <>
                                            <tr key={index}>
                                                <td >{SeedName}</td>
                                                <td >{VarietyName}</td>
                                                <td >{Quantity}</td>
                                                <td>
                                                    <div>
                                                        <input type="text" style={{width:"50%"}}
                                                            name="approvedQuantity"
                                                            
                                                            value={approvedQuantity}
                                                            onChange={(evnt) => handleChange(index, evnt)}  />
                                                    </div>
                                                </td>
                                                <td></td>

                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                            }
                        </Table>
                        <Row>
                <Col lg={3} className="send_button" 
/*                 disable={disbleIssueButton}
 */                 onClick={() => handleSubmit()}>
                        <p>Submit</p>
                    </Col>
                    </Row>
                    </Col>
                   
                </Row>
             
            </Container>
            <ToastContainer />
        </Wrapper>
    )
}
export default ForecastAODetails