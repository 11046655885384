import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Wrapper } from "./DistributorDetails.styles"
import { LoadingSpinner } from "../LoadingSpinner/index";
import { Title } from "../../GlobalStyle";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import Delete from "../../images/delete.png"
import { height } from "@mui/system";
import Modal from 'react-bootstrap/Modal';

const DistributorDetails = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [cropId, setCropId] = useState(location.state ?.distributordata == undefined ? '' : location.state ?.distributordata.cropID);
    const [cropName, setCropName] = useState(location.state ?.distributordata == undefined ? '' : location.state ?.distributordata.SeedName);
    const [varietyId, setVarietyId] = useState(location.state ?.distributordata == undefined ? '' : location.state ?.distributordata.verityID);
    const [varietyName, setVarietyName] = useState(location.state ?.distributordata == undefined ? '' : location.state ?.distributordata.VarietyName);
    const [allotedQty, setAllotedQty] = useState(location.state ?.distributordata == undefined ? '' : location.state ?.distributordata.AllotedQuantity);
    const [disableSubmit, setDisableSubmit] = useState(false);



    const [showSubmitModal, setShowSubmitModal] = useState(false);


    const [allotedQtyErr, setAllotedQtyErr] = useState('');


    const { distributorID } = useParams();

    const [year, setYear] = useState(location.state ?.year == undefined ? '' : location.state ?.year);
    const [yearErr, setYearErr] = useState('');
    const [season, setSeason] = useState(location.state ?.season == undefined ? '' : location.state ?.season);
    const [seasonErr, setSeasonErr] = useState('');
    const [distributorDataByID, setDistributorDataByID] = useState([]);
    const [indextotal, setIndex] = useState('')

    const [allotedSeedQty, setAllotedSeedQty] = useState('')
    useEffect(() => {
        setIsLoading(true);

        const fetchDistributors = async () => {

            let distributor_result = await API_Auth.getDistributorsByID(distributorID);

            console.log("distributor_result", distributor_result)

            setIsLoading(false);

            setDistributorDataByID(distributor_result);

        };
        // call the function
        fetchDistributors()
            // make sure to catch any error
            .catch(console.error);
    }, []);
    const handleInput = (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        if (name === "year") setYear(value);
        if (name == "season") setSeason(value);
        if (name == "allotedQty") setAllotedQty(value)
    }

    const handleBackClick = () => {
        navigate(-1);;

    }
    const handleChange = (index, e) => {
        setIndex(index)
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;

        const list = [...distributorDataByID];

        if (name === "AllotedQuantity") {
            list[index][name] = value
        }
        console.log(list);
        setDistributorDataByID(list);
    }
    const handleClose = () => {
        setShowSubmitModal(false);
    };
    const handleFinalSubmitt = async () => {
        setShowSubmitModal(false);
        let daoID = localStorage.getItem('UserID')

        console.log(distributorID, allotedSeedQty.cropID, allotedSeedQty.verityID, allotedSeedQty.AllotedQuantity, daoID, year, season);
        if(allotedSeedQty.AllotedQuantity==""){
            setAllotedQtyErr("This Field is required");
        }else{
            setAllotedQtyErr('');
        

        setIsLoading(true);
        setDisableSubmit(true)

        let data = await API_Auth.getInsertAllotment(distributorID, allotedSeedQty.cropID, allotedSeedQty.verityID, allotedSeedQty.AllotedQuantity, daoID, year, season);

        if (data[0].Flag == "Success") {
            setIsLoading(false);

            toast.success('Seed insert allotment successfully ');
            setTimeout(() => {
                navigate("/distributor-allotment");
            }, 2000)

        } else {
            setIsLoading(false);
            setDisableSubmit(false);
            toast.error('Something went wrong');
        }
    }
    }
    const handleSubmit = async (obj, index) => {
        setShowSubmitModal(true);
        setAllotedSeedQty(obj);
        setIndex(index)
    }
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}
            <Container>
                <Row>
                    <Col lg={2}>
                        <div className="back_button" onClick={() => handleBackClick()}>
                            <IoIosArrowBack size={18} />
                            <h6>Back</h6>
                        </div>
                    </Col>

                </Row>
                <Title>Distributor Detail Data</Title>
            </Container>
            <Container>

                <Row style={{ marginTop: 20 }}>
                    <Col lg={3}><label> Seed</label></Col>
                    <Col lg={3}><label> Type</label></Col>
                    <Col lg={3}><label>Allotted Quantity </label></Col>
                    <Col lg={3}><label>Action </label></Col>


                </Row>
                {distributorDataByID.length == 0 && <Container>
                                <Row >
                                    <Col >
                                        <p className="no_Data_table" style={{textAlign:'center',margin:14,fontWeight:'bold'}}>No Data Found</p>
                                    </Col>
                                </Row>
                            </Container>
                            }
                {distributorDataByID.map((data, index) => {
                    const { SeedName, VarietyName, AllotedQuantity } = data;
                    return (
                        <Row key={index}>
                            <Col lg={3}>
                                <div className="inputdiv">
                                    <div className="row_div_search">
                                        <div className="input_div" >
                                            <input type="type" style={{ margin: 0, background: 'transparent' }}
                                                value={SeedName}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col lg={3}>
                                <div className="inputdiv">
                                    <div className="row_div_search">
                                        <div className="input_div" >
                                            <input type="type" style={{ margin: 0, background: 'transparent' }}
                                                value={VarietyName}
                                                disabled />

                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="inputdiv">
                                    <input
                                        name="AllotedQuantity"
                                        value={AllotedQuantity}
                                        onChange={(evnt) => handleChange(index, evnt)} />

                                    {indextotal == index && <>

                                        {allotedQtyErr != '' && <p className="alert-message">{allotedQtyErr}</p>}
                                    </>}

                                </div>
                            </Col>
                            <Col lg={3}>
                                <div style={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: 6,
                                    background: "#ECECEC",
                                    color: "#000044",
                                    display: "flex",
                                    flexDirection: "row",
                                    borderRadius: 10,
                                    cursor: "pointer",
                                    height: 45,
                                    width: 120,
                                    margin: 23
                                }}
                                    onClick={() => handleSubmit(data, index)}>
                                    <p style={{ marginTop: 10, fontWeight: '500' }}>Submit</p>
                                </div>

                            </Col>
                        </Row>
                    )
                })}
                {/* <Row>
                    <Col lg={6}>
                        <div className="inputdiv">
                            <label> Select Year</label>
                            <br />
                            <select
                                name="year"
                                value={year}
                                onChange={handleInput}
                                style={{ width: "93%" }}>
                                <option value=''>Select Year</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                            </select>
                            {yearErr != '' && <p className="alert-message">{yearErr}</p>}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="inputdiv">
                            <label>Select Season</label>
                            <br />
                            <select
                                name="season"
                                value={season}
                                onChange={handleInput}
                            >
                                <option value=''>Select Season</option>
                                <option value="Rabi">Rabi</option>
                                <option value="Winter">Winter</option>
                                <option value="Spring">Spring</option>
                            </select>
                            {seasonErr != '' && <p className="alert-message">{seasonErr}</p>}

                        </div>
                    </Col>

                    <Col lg={6}>
                        <div className="inputdiv">
                            <label>Seed Name</label>
                            <br />
                            <input type="text"
                                value={cropName}
                                disabled
                            />


                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="inputdiv">
                            <label>Type Name</label>
                            <br />
                            <input type="text"
                                value={varietyName}
                                disabled
                            />


                        </div>

                    </Col>
                    <Col lg={6}>
                        <div className="inputdiv">
                            <label>Allotted Quantity</label>
                            <br />
                            <input type="text"
                                value={allotedQty}
                                name="allotedQty"
                                onChange={handleInput}

                            />

                            {allotedQtyErr != '' && <p className="alert-message">{allotedQtyErr}</p>}

                        </div>
                    </Col>

                </Row> */}
                {/* <Row>
                    <Col lg={3}>

                        <button className="add_button" disabled={disableSubmit} onClick={() => handleSubmit()} style={{ marginLeft: 30 }}>
                            <p className="pcat_title"> SUBMIT</p></button>
                    </Col>
                </Row> */}
            </Container>
            <Modal aria-labelledby="example-modal-sizes-title-sm" size="sm" show={showSubmitModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">Allot Seed</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure  to allot seed ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        NO
          </Button>
                    <Button variant="primary"
                        onClick={handleFinalSubmitt}>
                        YES
          </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer />

        </Wrapper>
    )
}
export default DistributorDetails
