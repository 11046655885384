import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Container, Row, Col } from "react-bootstrap";
import { Wrapper } from "./DispatchSupplier.styles"
import { LoadingSpinner } from "../LoadingSpinner/index";
import { Title } from "../../GlobalStyle";
import { useNavigate, useParams } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import Delete from "../../images/delete.png"
import { height } from "@mui/system";
import Table from "react-bootstrap/Table";
import { base_url } from "../../config"


const DispatchSupplier = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    let { purchaseID, supplierID } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [year, setYear] = useState('');
    const [season, setSeason] = useState('');
    const navigate = useNavigate();


    const [disableSubmit, setDisableSubmit] = useState(false);
    const [itemErr, setItemErr] = useState('');







    const [inputFields, setInputFields] = useState([{
        cropName: '',
        cropId: '',
        quantity: '',
        varietyname: '',
        varietyid: '',
        seedid: '',
        varietyid: '',
        transitQuantity: ''
    }]);


    const [truckNo, setTruckNo] = useState('');
    const [truckNoErr, setTruckNoErr] = useState('');

    const [truckDetail, setTruckDetail] = useState('');
    const [truckDetailErr, setTruckDetailErr] = useState('');

    const [driverName, setDriverName] = useState('');
    const [driverNameErr, setDriverNameErr] = useState('');

    const [driverName1, setDriverName1] = useState('');
    const [driverName1Err, setDriverName1Err] = useState('');

    const [driverContactNo, setDriverContactNo] = useState('');
    const [driverContactErr, setDriverContactErr] = useState('');

    const [driverContactNo2, setDriverContactNo2] = useState('');
    const [driverContactNo2Err, setDriverContactNo2Err] = useState('');

    const [quantityIntruck, setQuantityIntruck] = useState('');
    const [quantityIntruckErr, setQuantityIntruckErr] = useState('');

    const [truck_file, setTruck_file] = useState('');
    const [truck_fileErr, setTruck_fileErr] = useState('');
    

    const [transportArray, setTransportArray] = useState([]);
    const [seedDemandDetail, setSeedDemandDetail] = useState([]);
    const [status, setStatus] = useState('');
    const [totalDispatchQuantity, setTotalDispatchQuantity] = useState(0);
    const [truckFile, setTruckFile] = useState('');
    const [userPicShow, setuserPicShow] = useState('');

    useEffect(() => {
        const fetchSeeds = async () => {

            const data = await API_Auth.getDispacthByPurchaseId(purchaseID);
            let details=data.demandDetails
            let totaltransitQuantity = 0; 
            console.log(details); 
            if(details.length!=0){
                for(var i=0;i<details.length;i++){
                        details[i].transitQuantity=parseInt(details[i].DemandQuantity)-parseInt(details[i].receiveTotalQuantity)
                        details[i]['remainingquantity']=parseInt(details[i].DemandQuantity)-parseInt(details[i].receiveTotalQuantity)
                        totaltransitQuantity = totaltransitQuantity+parseInt(details[i]['remainingquantity']);
                }
            }
        setTotalDispatchQuantity(totaltransitQuantity);
        console.log(totaltransitQuantity,totalDispatchQuantity);
            details.forEach(v => {
                v.issueqtyerror = true;
                v.issueqtyErr = '';
                v.issuecolor = '';
            });

            console.log(details);

            console.log(data.purchaseStatus[0].Status);

            setSeedDemandDetail(details);
            setYear(data.demandDetails[0].Year);
            setSeason(data.demandDetails[0].Season);
            setStatus(data.purchaseStatus[0].Status)
        };
        // call the function
        fetchSeeds()
            // make sure to catch any error
            .catch(console.error);
    }, [])

    const handleChange = (index, event) => {
        const { name, value } = event.target;
        const list = [...seedDemandDetail];
        if (name === "transitQuantity") {
            list[index]['issueqtyerror'] = true;
            list[index]['issueqtyErr'] = "";
            list[index][name] = value; 
            if (parseInt(list[index].DemandQuantity )<parseInt(value)) {
                
                console.log("Hi")
                list[index]['issueqtyerror'] = false;
                list[index]['issueqtyErr'] = "Quantity Exceeds"
                list[index]["issuecolor"] = "y"
                list[index][name] = value
            }else{
                console.log("Hello");
                list[index]['issueqtyerror'] = true;
                list[index]['issueqtyErr'] = "";
                list[index][name] = value; 
            }
        }
        console.log(list);
        let totaltransitQuantity = 0;     
        for(let i=0; i<list.length; i++){
            if(list[i]?.transitQuantity){
                totaltransitQuantity = totaltransitQuantity+parseInt(list[i]?.transitQuantity);
            }
        }
        setTotalDispatchQuantity(totaltransitQuantity);
        console.log(totaltransitQuantity,totalDispatchQuantity);
        setSeedDemandDetail(list);
    }

    const handleBackClick = () => {
        navigate(-1);;

    }
    const handleInput = (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;

       // if (name == "truckDetail") setTruckDetail(value);
        if (name == "truckNo") setTruckNo(value);
        if (name == "driverName") setDriverName(value);
        if (name == "driverName1") setDriverName1(value);
        if (name == "driverContactNo")setDriverContactNo(value);
        if (name == "driverContactNo2") setDriverContactNo2(value);
/*         if (name == "quantityIntruck") setQuantityIntruck(value); */
        if (name == "truck_file"){
            setTruck_file(value);
        } 
    }
    const handleFileupload = async () => {
        var formData = new FormData();
        var imagefile = document.querySelector('#file-upload');
        formData.append("userPic", imagefile.files[0]);
        console.log(imagefile.files[0]);
        console.log(formData);
        let upload_res = await API_Auth.uploadImage(formData);
        if (upload_res.data == 'File is uploaded') {
            var file_name = '';
            for (var key of formData.entries()) {
                file_name = key[1].name;
            }
            console.log(upload_res);
            console.log(file_name);
            setTruckFile(file_name);
            setTruck_file(file_name);
            setuserPicShow(base_url + "/images?filename=" + file_name);
        }
    }
    const handleDeleteRow = (data, index) => {
        var setValue = inputFields;
        const dataRemoved = setValue.filter((el, elindex) => {
            return el.id !== data.id;
        });

        setInputFields([...dataRemoved])
    }
    const handleCreateData = async () => {
        let error = false;
        if (seedDemandDetail.length != 0) {
            for (var i = 0; i < seedDemandDetail.length; i++) {
                if (seedDemandDetail[i].issueqtyerror == false) {
                    error = true;
                    setItemErr("Please Enter Qunatity")
                } else {
                    setItemErr("")
                    error = false
                }
            }
        }
        console.log("transportArray", transportArray)
        if (transportArray.length != 0) {
            let totaltransportQuantity = 0;
            setItemErr(""); 
           /*  for(let i=0; i<transportArray.length; i++){
                if(transportArray[i]?.quantityIntruck){
                    totaltransportQuantity = totaltransportQuantity+parseInt(transportArray[i]?.quantityIntruck);
                }
            } 
            console.log(totalDispatchQuantity,totaltransportQuantity);
            if(totaltransportQuantity>totalDispatchQuantity){
                setItemErr("Quantity exceeds transport quantity is more then dispatch quantiy");
                error = true;
            }
            else{
                setItemErr("");
            } */
        } else {
            error = true;
            setItemErr("Please Add Details Transport")
        }



        console.log("error", error)
        if (!error) {
            var croparray = [];
            var tranarray = [];
            for (var i = 0; i < seedDemandDetail.length; i++) {
                let var_cropdata = seedDemandDetail[i]["CropID"] + "_" + seedDemandDetail[i]["VerietyID"] + "_" + seedDemandDetail[i]["transitQuantity"];
                croparray.push(var_cropdata);

            }
            for (var i = 0; i < transportArray.length; i++) {

                // truckdetail_truckNo_drivername-drivername1_driverContactNo-driverContactNo2_quantityIntruck
                //kiran_1234_Kavya-Sri_9704412897-9704412897_300
                //"abc"_123_"sri-kavya"_"97044128985-9704412898"_200

                /*  let var_transportdata =transportArray[i]["truckDetail"] + "_" + transportArray[i]["truckNo"] + "_" + " " + transportArray[i]["driverName"] + "-" + transportArray[i]["driverName1"] + " " + "_"
                     + " " + transportArray[i]["driverContactNo"] + "-" + transportArray[i]["driverContactNo2"] + " "
                     + "_" + transportArray[i]["quantityIntruck"]; */


                let var_transportdata = "'" + "abc" + "'"+"_" +"'" + transportArray[i]["truckNo"] + " '" +"_" +
                "'" +transportArray[i]["driverName"]+ transportArray[i]["driverName1"] +"'"+"_" +"'" +
                transportArray[i]["driverContactNo"]+ transportArray[i]["driverContactNo2"] +"'" +"_" +
                transportArray[i]["quantityIntruck"]
                tranarray.push(var_transportdata);

            }
            let cropID = croparray.toString();
            console.log("croparraytext", cropID)
            let transportDetail = tranarray.toString();
            console.log("croparraytext", transportDetail)

            setIsLoading(true);
            let userId = localStorage.getItem("UserID");
            let status = "inprocess";
            let supplystatus = "transport"
            setDisableSubmit(true);

            let datares = {
                year: year,
                season: season,
                cropID: cropID,
                supplierID: supplierID,
                transportDetail: transportDetail,
                purchaseID: purchaseID,
                status: status,
                supplystatus: supplystatus
            }
            console.log(datares);
            
            //let dispatch_by_Supplier 
            let dispatch_by_Supplier = await API_Auth.getDispatchBySupplier(year, season, cropID, supplierID, transportDetail, purchaseID, status, supplystatus);
            if (dispatch_by_Supplier[0].Flag == "Success") {
                setIsLoading(false);

                toast.success('Dispatch Crops successfully ');
                setTimeout(() => {
                    navigate("/demand-request-supplier");
                }, 2000)
            } else {
                setDisableSubmit(false);
                toast.error('Something went wrong');
            }
        } else {
            setDisableSubmit(false);
        }
    }
    const addTransportField = () => {
        let error = false;
        //setTruckDetailErr('');
        setTruckNoErr('');
        setDriverNameErr('');
        setDriverName1Err('');
        setDriverContactErr('');
        setDriverContactNo2Err('');
        setQuantityIntruckErr('');
        setTruck_fileErr('');
        setQuantityIntruck(totalDispatchQuantity)
      /*   if (truckDetail == "") {
            setTruckDetailErr("This field is required");
            error = true
        } */
        if (truckNo == "") {
            setTruckNoErr("This field is required");
            error = true
        }
        if (driverName == "") {
            setDriverNameErr("This field is required");
            error = true
        }
      /*   if (driverName1 == "") {
            setDriverName1Err("This field is required");
            error = true
        } */
        if (driverContactNo == "") {
            setDriverContactErr("This field is required");
            error = true
        }
        else if(driverContactNo != ''){
            var pattern = new RegExp(/^[0-9\b]+$/);
            if (!pattern.test(driverContactNo)) {
                setDriverContactErr('Please enter only number.'); 
                error = true                   
            }else if(driverContactNo.length != 10){
                setDriverContactErr('Please enter valid phone number.'); 
                error = true  
            } else{
                setDriverContactErr(''); 
            }    
        }

        
       /*  if (driverContactNo2 == "") {
            setDriverContactNo2Err("This field is required");
            error = true
        } */
/*         if (quantityIntruck == "") {
            setQuantityIntruckErr("This field is required");
            error = true
        } */
         if (truck_file == "") {
            setTruck_fileErr("This field is required");
            error = true
        } 
        else{
            setTruck_fileErr(" ");  
        }
        
        if (!error) {
            let data = {
                truckDetail: "abc",
                truckNo: truckNo,
                driverName: driverName,
                driverName1: driverName1,
                driverContactNo: driverContactNo,
                driverContactNo2: driverContactNo2,
                quantityIntruck: totalDispatchQuantity,
               // truck_file:truck_file
            }
            let array = transportArray;

            console.log("array");
            console.log(data);

            array.push(data);
           // setTruckDetail('');
            setTruckNo('');
            setDriverName('');
            setDriverName1('');
            setDriverContactNo('');
            setDriverContactNo2('');
            setQuantityIntruck('');
            setTruck_file('');
            setTransportArray(array);
            console.log(transportArray,array);
        }
    }
    const formatter = new Intl.NumberFormat('en-IN')
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}
            <Container>
                <Row>
                    <Col lg={2}>
                        <div className="back_button" onClick={() => handleBackClick()}>
                            <IoIosArrowBack size={18} />
                            <h6>Back</h6>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Title>Dispatch Supplier</Title>
                    </Col>
                </Row>
                <hr />
            </Container>
            <Container>
                <Row>
                    <Col lg={6}>
                        <div className="inputdiv">
                            <label>  Year</label>
                            <br />
                            <div className="inputdiv">
                                <div className="row_div_search">
                                    <div className="input_div" >
                                        <input type="type" style={{ margin: 0}}
                                            name="year"
                                            value={year}
                                            disabled
                                            placeholder="year" />
                                    </div>

                                </div>

                            </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="inputdiv">
                            <label> Season </label>
                            <br />
                            <div className="inputdiv">
                                <div className="row_div_search">
                                    <div className="input_div" >
                                        <input type="type" style={{ margin: 0}}
                                            name="season"
                                            value={season}
                                            disabled
                                            placeholder="season" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                <hr />
                <Row>
                    <Col lg={3}><label>Select Component</label></Col>
                    <Col lg={3}><label>Select Component Type</label></Col>
                    <Col lg={2}><label>Demand Quantity</label></Col>
                    <Col lg={2}><label>Remaining Quantity</label></Col>
                    <Col lg={2}><label>Dispatch Quantity (in kgs)</label></Col>

                </Row>
                <div>

                    {seedDemandDetail.map((data, index) => {
                        const { CropID, VerietyID, transitQuantity,SeedName,VarietyName,DemandQuantity,remainingquantity,issueqtyerror,issueqtyErr,issuecolor } = data;
                        return (
                            <Row key={index}>
                                <Col lg={3}>
                                    <div className="inputdiv">
                                        <div className="row_div_search">
                                            <div className="input_div" >
                                                <input type="type" style={{ margin: 0}}
                                                    value={SeedName}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                <Col lg={3}>
                                    <div className="inputdiv">
                                        <div className="row_div_search">
                                            <div className="input_div" >
                                                <input type="type" style={{ margin: 0}}
                                                    value={VarietyName}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={2}>
                                    <div className="inputdiv">
                                        <input
                                            name="DemandQuantity"
                                            disabled
                                            value={DemandQuantity}
                                             />

                                    </div>
                                </Col>
                                <Col lg={2}>
                                    <div className="inputdiv">
                                        <input
                                            name="remaining"
                                            disabled
                                            value={remainingquantity}
                                             />

                                    </div>
                                </Col>
                                <Col lg={2}>
                                    <div className="inputdiv">
                                        <input
                                            name="transitQuantity"
                                            disabled={(status == "open" ||status == "PartialComplete" || status=="inprocess")? false : true}
                                            value={transitQuantity}
                                            type='number'
                                            style={{ background: '#ddffdb' }}
                                            onChange={(evnt) => handleChange(index, evnt)} />

                                             {issueqtyerror == false && issuecolor == "r" && <p className="alert-message">{issueqtyErr}</p>}
                                                        {issueqtyerror == false && issuecolor == "y" && <p style={{
                                                            color: '#DF8D0F', textAlign: 'center', fontWeight: '700'
                                                        }}>{issueqtyErr}</p>}
                                                       {/*  {issueqtyerror == false && issuecolor == "b" && <p
                                                            style={{
                                                                color: 'blue', textAlign: 'center', fontWeight: '700'
                                                            }}>{issueqtyErr}</p>} */}
                                    </div>
                                </Col>
                            </Row>
                        )
                    })}
                   {/* <div><p className="ptotal_title" style={{background:'none'}}>
                    Total Dispatch Quantity:{totalDispatchQuantity}</p></div> */}
                    {itemErr != '' && <p className="alert-message">{itemErr}</p>}

                </div>

                <Row>
                    <Title style={{ marginLeft: 10 }}>Transport Details</Title>
                </Row>
                {transportArray.length != 0 && 
                <Row>
                <Col lg={12} sm={12} md={12} className="table_head">
                        <Table className="tablelist" responsive>
                            <thead>
                                <tr>
                                    <th>Truck No </th>
                                    <th>Driver Name</th>
                                    <th>Driver Contact Number</th>
                                    <th>Quantity In truck</th>
                                </tr>
                            </thead>
                            {transportArray.length != 0 && <tbody>
                                {transportArray.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                                <td>{obj.truckNo}</td>
                                                <td>{obj.driverName}</td>
                                                <td>{obj.driverContactNo}</td>
                                                <td>{formatter.format(obj.quantityIntruck)}</td>
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                            }
                        </Table>
                        </Col>
                        </Row>
                        }
                <Row>
                   {/*  <Col lg={4}>
                        <div className="inputdiv">
                            <label>Truck Details</label>
                            <input
                                name="truckDetail"
                                value={truckDetail}
                                onChange={(event) => handleInput(event)} />
                            {truckDetailErr != '' && <p className="alert-message">{truckDetailErr}</p>}

                        </div>
                    </Col> */}

                    <Col lg={2}>
                        <div className="inputdiv">
                            <label>Truck No</label>
                            <input
                                name="truckNo"
                                style={{ background: '#ddffdb' }}
                                value={truckNo}
                                onChange={(event) => handleInput(event)} />
                            {truckNoErr != '' && <p className="alert-message">{truckNoErr}</p>}

                        </div>
                    </Col>
                    <Col lg={2}>
                        <div className="inputdiv">
                            <label>Driver Name</label>
                            <input
                                name="driverName"
                                style={{ background: '#ddffdb' }}
                                value={driverName}
                                onChange={(event) => handleInput(event)} />
                            {driverNameErr != '' && <p className="alert-message">{driverNameErr}</p>}

                        </div>
                    </Col>
                    <Col lg={3}>
                        <div className="inputdiv">
                            <label>Driver Contact Number</label>
                            <input
                                maxLength={10}
                                name="driverContactNo"
                                style={{ background: '#ddffdb' }}
                                value={driverContactNo}
                                onChange={(event) => handleInput(event)} />
                            {driverContactErr != '' && <p className="alert-message">{driverContactErr}</p>}

                        </div>
                    </Col>
                    <Col lg={2}>
                        <div className="inputdiv">
                            <label>Quantity In truck</label>
                            <input
                              style={{ background: '#ddffdb' }}
                                name="quantityIntruck"
                                type='number'
                                disabled
                                value={totalDispatchQuantity}
                                onChange={(event) => handleInput(event)} />
                            {quantityIntruckErr != '' && <p className="alert-message">{quantityIntruckErr}</p>}
                        </div>
                    </Col>
                    <Col lg={3}>
                        <div className="inputdiv_file">
                            <label>Truck File</label>
{/*     {truck_file != '' &&
        <label className="custom-file-uploaded" >
            <div>
                <p>{truck_file} </p>
            </div>
        </label>} */}
{/*         {truck_file == '' &&
        <> */}
                            <input
                                id="file-upload"
                                name="truck_file"
                                style={{ background: '#ddffdb' }}
                                type='file'
                                onChange={() => handleFileupload()}
                                //onChange={(event) => handleInput(event)} 
                                />
                            {truck_fileErr != '' && <p className="alert-message">{truck_fileErr}</p>}
{/*               </>
                } */}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4}>

                        <div className="row_table_div " onClick={() => addTransportField()} style={{ width: 175, margin: 15, fontWeight: 'bold', float: 'left' }}>
                            <AiOutlinePlus size={20} style={{ margin: 10 }} />
                            <p>ADD TRANSPORT</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={3}>
                        <button className="add_button" disabled={disableSubmit} onClick={() => handleCreateData()}>
                            <p className="pcat_title">DISPATCH</p></button>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </Wrapper>
    )
}
export default DispatchSupplier