import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./styles";
import { Row, Container, Col, Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle";
import { LoadingSpinner } from "../LoadingSpinner/index";
import ReactPaginate from "react-paginate";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import View from "../../images/view.svg";

const ListFarmerApplication = () => {
  const { isExpanded } = useContext(UserContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [offset, setOffSet] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [orgtableData, setOrgTableData] = useState([]);
  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [searchKey, setSearchKey] = useState("n");
  const [searchKeyTable, setSearchKeyTable] = useState("");
  const [showAlertModal, setShowAlertModal] = useState(false);

  const location = useLocation();
  console.log("location",location);
  const handleViewDetails = (obj) => {
    navigate("/farmer-application-view", { state: { famerdetails: obj } });
  };
  const handleClose = () => {
    setShowAlertModal(false);
  };
  const handleSearch = (e) => {
    let filterValue = e.target.value;
    setCurrentPage(0);
    if (filterValue == "") {
      let offsetsearch = 0;
      var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
      setPageCount(Math.ceil(orgtableData.length / perPage));
      setOrgTableData(orgtableData);
      setTableData(slice);
      setSearchData(orgtableData);
      setOffSet(offsetsearch);
      setCurrentPage(0);
      setSearchKey("n");
    } else {
      let offsetsearch = 0;
      const filteredData = orgtableData.filter((item) => {
        return (
          item?.PurchaseID?.toLowerCase().includes(filterValue.toLowerCase()) ||
          item?.SupplierName?.toLowerCase().includes(
            filterValue.toLowerCase()
          ) ||
          item?.SeedName?.toLowerCase().includes(filterValue.toLowerCase()) ||
          item?.Demanddate?.toLowerCase().includes(filterValue.toLowerCase()) ||
          moment(item?.Status)
            .format("DD-MM-YYYY")
            .includes(filterValue.toLowerCase())
        );
      });

      const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
      setPageCount(Math.ceil(filteredData.length / perPage));
      setSearchKey("y");
      setOffSet(offsetsearch);
      setSearchKeyTable(filteredData);
      setTableData(slice);
      setSearchData(filteredData);
    }
  };
 
  useEffect(() => {
    setIsLoading(true);
    const user_id = localStorage.getItem("UserID");
    const fetchForecast = async () => {
      const result = await API_Auth.getFamerApplicationsByAdhar(
        location?.state?.adharNumber
      );
      console.log("result", result);
      //seting pagenation data
      var slice = result.slice(offset, offset + perPage);
      setPageCount(Math.ceil(result.length / perPage));
      setOrgTableData(result);
      setTableData(slice);
      setIsLoading(false);
      setSearchData(slice);
    };
    // call the function
    fetchForecast()
      // make sure to catch any error
      .catch(console.error);
  }, []);
  const handleAddFarmerApplication = () => {
    navigate("/farmer-application", {
      state: { adharNumber: location?.state?.adharNumber },
    });
  };
  const handlerowsperPage = (e) => {
    let showperpage = e.currentTarget.value;
    setPerPage(parseInt(showperpage));
    setCurrentPage(0);
    if (searchKey == "y") {
      const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
      setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value));
      setTableData(slice);
      setOffSet(0);
      setSearchData(searchKeyTable);
    } else {
      const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
      setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value));
      setTableData(slice);
      setOffSet(0);
      setSearchData(orgtableData);
    }
  };
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offsetdata = selectedPage * perPage;
    setCurrentPage(selectedPage);
    setOffSet(offsetdata);
    loadMoreData(offsetdata);
  };

  const loadMoreData = (offsetdata) => {
    const data = orgtableData;
    if (searchKey == "y") {
      const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
      setPageCount(Math.ceil(searchKeyTable.length / perPage));
      setTableData(slice);
      setOffSet(offsetdata);
      setSearchData(searchKeyTable);
    } else {
      const slice = data.slice(offsetdata, offsetdata + perPage);
      setPageCount(Math.ceil(data.length / perPage));
      setOffSet(offsetdata);
      setTableData(slice);
      setSearchData(data);
    }
  };
  const formatter = new Intl.NumberFormat("en-IN");

  return (
    <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
      {isLoading && <LoadingSpinner />}

      <Container>
        <Row>
          <Col lg={3} style={{ paddingLeft: 0, marginTop: 25 }}>
            <Title>Farmer Applications</Title>
          </Col>

          <Col lg={4} style={{ paddingRight: 0, marginTop: 25 }}></Col>
          <Col lg={3} style={{ paddingRight: 0, marginTop: 25 }}>
            <div>
              <button
                className="add_button"
                onClick={() => handleAddFarmerApplication()}
              >
                <p className="pcat_title">CREATE NEW APPLICTION</p>
              </button>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col lg={12} sm={12} md={12} className="table_head">
            <Table className="tablelist" responsive>
              <thead>
                <tr>
                  <th>Application No</th>
                  <th>Farmer Name </th>
                  <th>Farmer Age</th>
                  <th>Area</th>
                  <th>Mobile Number</th>
                  <th>Activity Name</th>
                  <th>Scheme Name</th>
                  <th>Application Status DHO</th>
                  <th>Reject Reason DHO</th>
                  <th>Application Status AO</th>
                  <th>Reject Reason AO</th>
                  <th style={{ textAlign: "right" }}>Action</th>
                </tr>
              </thead>
              {tableData.length == 0 && (
                <tbody>
                  <tr>
                    <td colSpan="12">
                      <p className="no_Data_table">No Data Found</p>
                    </td>
                  </tr>
                </tbody>
              )}
              {tableData.length != 0 && (
                <tbody>
                  {tableData.map((obj, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td>{obj.ApllicationNo}</td>
                          <td>{obj.farmerName}</td>
                          <td>{obj.Age}</td>
                          <td>{obj.Area}</td>
                          <td>{obj.farmerCno}</td>
                          <td>{obj.activityName}</td>
                          <td>{obj.scheameName}</td>
                          <td>{obj.ApplicationStatusDho}</td>
                          <td>{obj.RejectReasonDHO}</td>
                          <td>{obj.ApplicationStatusAO}</td>
                          <td>{obj.RejectReasonAO}</td>
                          <td style={{ textAlign: "right" }}>
                            <div
                              className="row_table_edit"
                              style={{ background: "antiquewhite" }}
                              onClick={() =>
                                handleViewDetails(obj)
                              }
                            >
                              <img src={View} />
                              <p>VIEW</p>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              )}
            </Table>
            {tableData.length != 0 && (
              <div>
                <div className="pagination_row_div">
                  <p className="pagination_title">Showing items per page:</p>
                  <div className="pagination_input_div">
                    <select
                      className="pagination_select"
                      style={{ margin: 0, background: "transparent" }}
                      name="pagination_value"
                      value={perPage}
                      onChange={handlerowsperPage}
                    >
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                  </div>
                </div>
                <div className="pagination_row_right_div">
                  <p className="pagination_total">
                    Showing {offset + 1} to{" "}
                    {searchData.length < offset + perPage && (
                      <span>{searchData.length}</span>
                    )}
                    {searchData.length > offset + perPage && (
                      <span>{offset + perPage} </span>
                    )}{" "}
                    of {searchData.length} items
                  </p>
                  <ReactPaginate
                    previousLabel={
                      <ArrowBackIosIcon
                        className="pagination_prev_btn"
                        color="#2438a7"
                      />
                    }
                    nextLabel={
                      <ArrowForwardIosIcon
                        className="pagination_next_btn"
                        color="#2438a7"
                      />
                    }
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    forcePage={currentPage}
                  />
                </div>
              </div>
            )}{" "}
          </Col>
        </Row>
      </Container>
      <Modal
        aria-labelledby="example-modal-sizes-title-sm"
        size="sm"
        show={showAlertModal}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>You do not have any alloted quantity</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </Wrapper>
  );
};
export default ListFarmerApplication;
