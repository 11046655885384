import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./DashboardSeedWise.styles"
import { Row, Container, Col } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import Edit from "../../images/edit.png";
import plus from "../../images/white_plus.png"
import { useNavigate, Link } from "react-router-dom";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle"
import { LoadingSpinner } from "../LoadingSpinner/index";
import { AiOutlineSearch } from "react-icons/ai";

import * as FileSaver from 'file-saver';

import * as XLSX from 'xlsx';


const DashboardSeedWise = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    const [distributionWise, setDistributionWise] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);


    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey, setSearchKey] = useState('n');
    const [searchKeyTable, setSearchKeyTable] = useState('');

    const [seedId, setSeedId] = useState('');
    const [seedName, setSeedName] = useState('');
    const [key, setKey] = useState('d');
    const [varietyId, setVarietyId] = useState('');
    const [varietyName, setVarietyName] = useState('');
    const [districtName, setDistrictName] = useState('');
    const [districtId, setDistrictId] = useState('');
    const [seedData, setSeedData] = useState([]);
    const [varietyData, setVarietyData] = useState([]);

    const [seasonData, setSeasonData] = useState([]);

    const [season, setSeason] = useState('')

    const [districtData, setDistrictData] = useState([]);

    const [varieties, setVarieties] = useState([])
    const [dashbaordSeedWisedata, setDashbaordSeedWiseData] = useState([])
    const[exceldata,setExceldata]=useState([])


    const colorGen = () => {
        var generateColor = Math.floor(Math.random() * 256);
        return generateColor;
    }

    const exportToCSV=(csvData)=>{

        
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        const fileExtension = '.xlsx';
        const fileName = 'Seed Wise Dashboard'

        const ws = XLSX.utils.json_to_sheet(csvData);

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        const data = new Blob([excelBuffer], { type: fileType });

        FileSaver.saveAs(data, fileName + fileExtension);


    }

  


    const handleInput = async (e) => {
        const { name, value } = e.target;
        if (name == "season") {
            setSeason(value);
            console.log(value);
            const data = seedData.filter((item) => item.id == seedId);
            setIsLoading(true);
            if(value!=''){
            const result = await API_Auth.getDashboardSeedSeasonWise(data[0].CropName,value);
            console.log("resultseedata", result);
            var totalarray = []
            setIsLoading(false);
            var varietiesarray = []
            var letters = '0123456789ABCDEF';
            var color = '#';
            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    console.log("inkey", key)
                    let data = {
                        vname: key,
                        color: 'rgb(' + colorGen() + ',' + colorGen() + ',' + colorGen() + ')'

                    }
                    varietiesarray.push(data)
                }
            }
            console.log("varietiesarray", varietiesarray)
            setVarieties(varietiesarray)


            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    let data = {
                        vdata: result[key]

                    }
                    totalarray.push(data)
                }

            }
            var totalarrayexcel=[]

            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    console.log("result[key]",result[key].length)

                    console.log(key);
                    for(var i=0;i<result[key].length; i++){
                    let data = {
                        District:result[key][i][0].DistrictName,
                        Variety: key,
                        Quantity:result[key][i][0].Quantity,
                        Alloted:result[key][i][0].AllocatedQuantity,
                        Purchased:result[key][i][0].purchaseDemandQuantity,
                        Distributed:result[key][i][0].v_disrtributedQuantity,
                        Farmers:result[key][i][0].farmersBenifited



                    }
                
                    totalarrayexcel.push(data) 
                }
                }

            }
            console.log("totalarrayexcel", totalarrayexcel)
            setExceldata(totalarrayexcel)

            setDashbaordSeedWiseData(totalarray);
        }
        else{
            const result = await API_Auth.getDashboardSeedWise(data[0].CropName);
            console.log("resultseedata", result);
            var totalarray = []
            setIsLoading(false);
            var varietiesarray = []
            var letters = '0123456789ABCDEF';
            var color = '#';
            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    console.log("inkey", key)
                    let data = {
                        vname: key,
                        color: 'rgb(' + colorGen() + ',' + colorGen() + ',' + colorGen() + ')'

                    }
                    varietiesarray.push(data)
                }
            }
            console.log("varietiesarray", varietiesarray)
            setVarieties(varietiesarray)


            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    let data = {
                        vdata: result[key]

                    }
                    totalarray.push(data)
                }

            }
            var totalarrayexcel=[]

            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    console.log("result[key]",result[key].length)

                    console.log(key);
                    for(var i=0;i<result[key].length; i++){
                    let data = {
                        District:result[key][i][0].DistrictName,
                        Variety: key,
                        Quantity:result[key][i][0].Quantity,
                        Alloted:result[key][i][0].AllocatedQuantity,
                        Purchased:result[key][i][0].purchaseDemandQuantity,
                        Distributed:result[key][i][0].v_disrtributedQuantity,
                        Farmers:result[key][i][0].farmersBenifited



                    }
                
                    totalarrayexcel.push(data) 
                }
                }

            }
            console.log("totalarrayexcel", totalarrayexcel)
            setExceldata(totalarrayexcel)

            setDashbaordSeedWiseData(totalarray);
        }
        }
        if (name == "seedId") {
            setSeedId(value);
            const data = seedData.filter((item) => item.id == value);
            setIsLoading(true);
            if(season==''){
            const result = await API_Auth.getDashboardSeedWise(data[0].CropName);
            console.log("resultseedata", result);
            var totalarray = []
            setIsLoading(false);
            var varietiesarray = []
            var letters = '0123456789ABCDEF';
            var color = '#';
            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    console.log("inkey", key)
                    let data = {
                        vname: key,
                        color: 'rgb(' + colorGen() + ',' + colorGen() + ',' + colorGen() + ')'

                    }
                    varietiesarray.push(data)
                }
            }
            console.log("varietiesarray", varietiesarray)
            setVarieties(varietiesarray)


            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    let data = {
                        vdata: result[key]

                    }
                    totalarray.push(data)
                }

            }
            var totalarrayexcel=[]

            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    console.log("result[key]",result[key].length)

                    console.log(key);
                    for(var i=0;i<result[key].length; i++){
                    let data = {
                        District:result[key][i][0].DistrictName,
                        Variety: key,
                        Quantity:result[key][i][0].Quantity,
                        Alloted:result[key][i][0].AllocatedQuantity,
                        Purchased:result[key][i][0].purchaseDemandQuantity,
                        Distributed:result[key][i][0].v_disrtributedQuantity,
                        Farmers:result[key][i][0].farmersBenifited



                    }
                
                    totalarrayexcel.push(data) 
                }
                }

            }
            console.log("totalarrayexcel", totalarrayexcel)
            setExceldata(totalarrayexcel)

            setDashbaordSeedWiseData(totalarray);
        }
    else{
        const result = await API_Auth.getDashboardSeedSeasonWise(data[0].CropName,season);
        console.log("resultseedata", result);
        var totalarray = []
        setIsLoading(false);
        var varietiesarray = []
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var key in result) {
            if (result.hasOwnProperty(key)) {
                console.log("inkey", key)
                let data = {
                    vname: key,
                    color: 'rgb(' + colorGen() + ',' + colorGen() + ',' + colorGen() + ')'

                }
                varietiesarray.push(data)
            }
        }
        console.log("varietiesarray", varietiesarray)
        setVarieties(varietiesarray)


        for (var key in result) {
            if (result.hasOwnProperty(key)) {
                let data = {
                    vdata: result[key]

                }
                totalarray.push(data)
            }

        }
        var totalarrayexcel=[]

        for (var key in result) {
            if (result.hasOwnProperty(key)) {
                console.log("result[key]",result[key].length)

                console.log(key);
                for(var i=0;i<result[key].length; i++){
                let data = {
                    District:result[key][i][0].DistrictName,
                    Variety: key,
                    Quantity:result[key][i][0].Quantity,
                    Alloted:result[key][i][0].AllocatedQuantity,
                    Purchased:result[key][i][0].purchaseDemandQuantity,
                    Distributed:result[key][i][0].v_disrtributedQuantity,
                    Farmers:result[key][i][0].farmersBenifited



                }
            
                totalarrayexcel.push(data) 
            }
            }

        }
        console.log("totalarrayexcel", totalarrayexcel)
        setExceldata(totalarrayexcel)

        setDashbaordSeedWiseData(totalarray);          }
        }

    }

    const handleSearch = (e) => {
        let filterValue = e.target.value;
        setCurrentPage(0);
        if (filterValue == '') {
            let offsetsearch = 0;
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setTableData(slice);
            setSearchData(orgtableData);
            setOffSet(offsetsearch);
            setCurrentPage(0);
            setSearchKey('n');
        }
        else {
            let offsetsearch = 0;
            const filteredData = orgtableData.filter(item => {
                return (item ?.districtname ?.toLowerCase().includes(filterValue.toLowerCase()))
            });

            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            setOffSet(offsetsearch);
            setSearchKeyTable(filteredData);
            setTableData(slice);
            setSearchData(filteredData);
        }
    }
    useEffect(() => {
        setIsLoading(true);

        const fetchSeedWise = async () => {
            let resultdata = await API_Auth.getSeeds();
            setSeedId(resultdata[0].id);
            setSeedData(resultdata)
            const seasonresult = await API_Auth.getSeasons();
            setSeasonData(seasonresult)

            const result = await API_Auth.getDashboardSeedWise(resultdata[0].CropName);
            console.log("resultseedata", result);
            var totalarrayexcel=[]

            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    console.log("result[key]",result[key].length)

                    console.log(key);
                    for(var i=0;i<result[key].length; i++){
                    let data = {
                        District:result[key][i][0].DistrictName,
                        Variety: key,
                        Quantity:result[key][i][0].Quantity,
                        Alloted:result[key][i][0].AllocatedQuantity,
                        Purchased:result[key][i][0].purchaseDemandQuantity,
                        Distributed:result[key][i][0].v_disrtributedQuantity,
                        Farmers:result[key][i][0].farmersBenifited,
                        ReceivedQuantity:result[key][i][0].ReceivedQuantity



                    }
                
                    totalarrayexcel.push(data) 
                }
                }

            }
            console.log("totalarrayexcel", totalarrayexcel)
            setExceldata(totalarrayexcel)

            const datadistrict = await API_Auth.getDistricts();

            console.log("datadistrict", datadistrict);

            

            let datad=datadistrict.slice(0, -2);
            console.log("sliceddata",datad)

            var myArray = [], X = 24;
            for (var i = 1; i <= X; i++) {
                myArray.push(i)
            }
            console.log("myArray", myArray);
            setDistrictData(datad)


            var totalarray = []
            setIsLoading(false);
            var varietiesarray = []
            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    console.log("key", key);
                    let data = {
                        vname: key,
                        color: 'rgb(' + colorGen() + ',' + colorGen() + ',' + colorGen() + ')'


                    }
                    varietiesarray.push(data)
                }
            }
            console.log("varietiesarray", varietiesarray)
            setVarieties(varietiesarray);
            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    let data = {
                        vdata: result[key]

                    }
                    totalarray.push(data)
                }

            }
            console.log("totalArray", totalarray);



            setDashbaordSeedWiseData(totalarray);



        };
        
        // call the function
        fetchSeedWise()
            // make sure to catch any error
            .catch(console.error);
    }, []);
    const handlerowsperPage = (e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(searchKeyTable);
        }
        else {
            const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(orgtableData);
        }
    }
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        const data = orgtableData;
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setOffSet(offsetdata);
            setSearchData(searchKeyTable);
        } else {
            const slice = data.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(data.length / perPage))
            setOffSet(offsetdata);
            setTableData(slice);
            setSearchData(data);
        }
    }
    const formatter = new Intl.NumberFormat('en-IN')

    const handleMethod = () => {
        return (
            /*  for (let i = 0; i < 24; i++) {
 
                 for(let j=0;j<totalarray.length;j++){
                   //  console.log("i,j",i,j);
  
                 console.log("AllocatedQuantity ",totalarray[j].vdata[i][0].AllocatedQuantity);
                 console.log("farmersBenifited ",totalarray[j].vdata[i][0].farmersBenifited);
                 console.log("purchaseDemandQuantity ",totalarray[j].vdata[i][0].purchaseDemandQuantity);
                 console.log("v_disrtributedQuantity ",totalarray[j].vdata[i][0].v_disrtributedQuantity);
              
  
  
             } 
             console.log("=========New Row ========");
          }; */

            <>

                {districtData.map((item, districtindex) => {
                    return (
                        <tr>
                            <td style={{
                                borderLeft: "2px solid #084298",
                                background:'#dee9ed'
                            }}>{item.v_districtName}</td>
                            {dashbaordSeedWisedata.map((data, itemindex) => {
                                return (
                                    <>
                                      <td style={{
                                            borderLeft: "2px solid #084298"
                                        }}>{data.vdata[districtindex] != undefined ? formatter.format(data.vdata[districtindex][0].AllocatedQuantity) : ''}</td>
                                        
                                        <td>{data.vdata[districtindex] != undefined ? formatter.format(data.vdata[districtindex][0].purchaseDemandQuantity) : ''}</td>
                                        <td>{data.vdata[districtindex] != undefined ? formatter.format(data.vdata[districtindex][0].ReceivedQuantity) : ''}</td>
                                        <td>{data.vdata[districtindex] != undefined ? formatter.format(data.vdata[districtindex][0].Quantity) : ''}</td>
                                        <td>{data.vdata[districtindex] != undefined ? formatter.format(data.vdata[districtindex][0].v_disrtributedQuantity) : ''}</td>

                                        <td style={{
                                            borderRight: "2px solid #084298"
                                        }}>{data.vdata[districtindex] != undefined ? formatter.format(data.vdata[districtindex][0].farmersBenifited) : ''}</td>

                                    </>
                                )
                            })}
                        </tr>
                    )
                })}
            </>
        )
    }

    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}

            <Container>
                <Row>
                    <Col lg={3} style={{ paddingLeft: 0 ,marginTop:25}}>
                        <Title>Component Wise</Title>
                    </Col>
                    <Col lg={1} style={{ paddingLeft: 0 ,marginTop:25}}>
                        <p style={{ marginTop: 23, fontWeight: 'bold' }}> Filter By</p>
                    </Col>


                    <Col lg={3}>
                    <label>Season</label>

                        <div className="row_div">
                            <div className="input_div" >
                                <select value={season} onChange={handleInput} name="season"
                                    style={{ margin: 0, background: 'transparent' }}>
                                    <option value="">All</option>
                                    {seasonData.length != 0 && <>
                                        {seasonData.map((item, index) => {
                                            return (
                                                <option value={item.CombYearSeason}>{item.CombYearSeason}</option>
                                            )
                                        })}
                                    </>}

                                </select>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3}>
                    <label>Component</label>

                        <div className="row_div">
                            <div className="input_div" >

                                <select
                                    name="seedId"
                                    value={seedId}
                                    onChange={handleInput}
                                    style={{ margin: 0, background: 'transparent' }}

                                >

                                    {seedData.length != 0 &&
                                        <>
                                            {seedData.map((item, index) => {
                                                return (
                                                    <option value={item.id}>{item.CropName}</option>
                                                )
                                            })}
                                        </>}

                                </select>

                            </div>

                        </div>
                    </Col>
                    <Col lg={2} style={{marginTop:25}}>
                        <button
                            className="export_button"
                            onClick={(e) => exportToCSV(exceldata)}>
                            <p className="pcat_title">Export Excel</p>
                        </button>

                    </Col>
                </Row>
            </Container>

            <Container>

            </Container>
            <Container>
                <Table bordered>
                    <thead>
                        <tr>
                            <th style={{
                              background:"#f5f3f3",
                              border:"1px solid rgb(245, 243, 243)"
                            }}> </th>
                            {varieties.length != 0 &&
                                <>
                                    {varieties.map((data, index) => {
                                        return (
                                            <>
                                                <th colspan="6" style={{ textAlign: 'center', border: "2px solid #084298" }}>{data.vname}</th>


                                            </>
                                        )

                                    })}
                                </>}
                        </tr>
                        <tr>
                            <th style={{
                                borderLeft: "2px solid #084298",
                                borderTop: "2px solid #084298",

                                textAlign:'center'
                            }}>District</th>
                            {varieties.length != 0 &&
                                <>
                                    {varieties.map((data, index) => {
                                        return (
                                            <>
                                                <th style={{
                                                    borderLeft: "2px solid #084298"
                                                }}>Allotted </th>
                                                <th >Purchased</th>
                                                <th>Received</th>
                                                <th>Stock</th>
                                                <th>Distributed</th>
                                                <th style={{
                                                    borderRight: "2px solid #084298"
                                                }}>Farmers</th>

                                            </>
                                        )

                                    })}
                                </>}
                        </tr>


                    </thead>

                    <tbody>
                        {handleMethod()}
                    </tbody>



                </Table>
            </Container >


            {/*   <Container>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
                       {tableData.length != 0 &&
                            <div>
                                <div className="pagination_row_div">
                                    <p className="pagination_title">Showing items per page:</p>
                                    <div className="pagination_input_div" >
                                        <select className="pagination_select"
                                            style={{ margin: 0, background: 'transparent' }}
                                            name="pagination_value"
                                            value={perPage}
                                            onChange={handlerowsperPage} >
                                            <option value={4}>4</option>
                                            <option value={8}>8</option>
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="pagination_row_right_div">
                                    <p className="pagination_total">Showing {offset + 1} to {searchData.length < offset + perPage &&
                                        <span>{searchData.length}</span>}
                                        {searchData.length > offset + perPage &&
                                            <span>{offset + perPage} </span>} of {searchData.length} items</p>
                                    <ReactPaginate
                                        previousLabel={<ArrowBackIosIcon className="pagination_prev_btn" color="#2438a7" />}
                                        nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7" />}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={currentPage} />
                                </div>
                            </div>
                        }                    </Col>
                </Row>
            </Container>
        */}     <ToastContainer />
        </Wrapper >
    )
}
export default DashboardSeedWise