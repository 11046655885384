import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./DistrictWiseForecast.styles"
import { Row, Container, Col } from "react-bootstrap"
import API_Auth from "../../API_Auth";
import { LoadingSpinner } from "../LoadingSpinner/index";
import { AiOutlineSearch } from "react-icons/ai";
import { Title } from "../../GlobalStyle";
import Edit from "../../images/edit.png";
import Delete from "../../images/delete.png"
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Table from "react-bootstrap/Table";







const DistrictWiseForecast = () => {
    const {
        isExpanded,
    } = useContext(UserContext);

    const [isLoading, setIsLoading] = useState(false)
    const [roles, setRoles] = useState([]);

    const [formButtonValue, setFormButtonValue] = useState('ADD');
    const [searchData, setSearchData] = useState([]);
    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [seeds, setSeeds] = useState([]);
    const [seedID, setSeedID] = useState('');
    const [districts, setDistricts] = useState([]);
    const [districtForecastData, setDistrictForecastData] = useState('')

    const [totalArrayDistricts, setTotalArrayDistricts] = useState([]);
    const [seedName, setSeedName] = useState('');




    useEffect(() => {
        setIsLoading(true);
        const fetchSeeds = async () => {
            let result = await API_Auth.getSeeds();
            let districtsdata = await API_Auth.getDistricts();
            console.log("districtsdata", districtsdata)
            setDistricts(districtsdata)
            setSeeds(result)
            setSeedID(result[0].id);
            setSeedName(result[0].CropName)
            setIsLoading(false);
            let districtwiseresult = await API_Auth.getForcastProcessDistrictWise(result[0].id);
            setDistrictForecastData(districtwiseresult)

            let totalarray = []

            for (var key in districtwiseresult) {
                if (districtwiseresult.hasOwnProperty(key)) {
                    //console.log(key + " -> " + districtwiseresult[key]);
                    let data = {
                        districtname: key,
                        districtdata: districtwiseresult[key]

                    }



                    totalarray.push(data)
                }

            }
            const filteredData = totalarray.filter((item, index) => {
                return index != 0
            })
            console.log(filteredData)
            setTotalArrayDistricts(filteredData)



        };
        fetchSeeds()
            // make sure to catch any error
            .catch(console.error);
    }, []);

    const handleInput = async (e) => {
        const { name, value } = e.target
        console.log(value)
        setIsLoading(true)
        const seeddata = seeds.filter(item => {
            return item.id == value
        })
        console.log("seedata", seeddata)
        let districtwiseresult = await API_Auth.getForcastProcessDistrictWise(value);

        let totalarray = []

        setSeedID(value);
        setIsLoading(false);
        setSeedName(seeddata[0].CropName)

        for (var key in districtwiseresult) {
            if (districtwiseresult.hasOwnProperty(key)) {
                //console.log(key + " -> " + districtwiseresult[key]);
                let data = {
                    districtname: key,
                    districtdata: districtwiseresult[key]

                }
                totalarray.push(data)
            }

        }
        //console.log(totalarray);
        const filteredData = totalarray.filter((item, index) => {
            return index != 0
        })
        console.log(filteredData)
        setTotalArrayDistricts(filteredData)

    }
    const formatter = new Intl.NumberFormat('en-IN');

    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}
            <Container>
                <Row>
                    <Col lg={4} style={{ paddingLeft: 0 ,marginTop:24}}>
                        <Title>District Wise Forecast</Title>
                    </Col>
                    <Col lg={4} style={{ paddingLeft: 0 }}>
                    </Col>
                    <Col lg={4} style={{ paddingRight: 0 }}>
                        <div className="row_div" style={{ paddingRight: 0 }}>
                            <div className="input_div" >
                                <select style={{ margin: 0, background: 'transparent' }}
                                    onChange={(e) => handleInput(e)}
                                    placeholder="Search">
                                    <>
                                        {seeds.length != 0 && <>
                                            {seeds.map((item, index) => {
                                                return (
                                             <option value={item.id}>{item.CropName}</option>
                                                )
                                            })}
                                        </>}
                                    </>
                                </select>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">

                        <Table striped bordered responsive className="tablelist">
                            <thead>

                                <tr style={{borderWidth: 0}}>
                                    <th className="tablehead">
                                        District</th>
                                   <th className="tablehead">Component</th>
                                  {/* <th className="tablehead"></th>  */}
                                  <th style={{background:"#fff",borderWidth: 0}}> </th>
                                   {/* <th style={{textAlign: 'right'}}>Type Type&Quantity</th>  */}
                               {/*        <th>Type Type</th>  */}
                                   { /* {districts.length != 0 &&
                                        <>
                                            {districts.map((data, index) => {
                                                return (
                                                    <th colspan="2">{data.v_districtName}</th>
                                                )

                                            })}
                                        </>}
 */
                                        }
                                </tr>

                            </thead>
                            {totalArrayDistricts.map((item, index) => {
                                return (
                                    <tr style={{borderWidth: 1}}> 
                                        <th className="div_tablehead" colSpan={1}>{item.districtname}</th>
                                        <td className="div_tablehead" colSpan={1}>{seedName}</td>
                                        <td className="div_tablehead" colSpan={1}>
                                        {item.districtdata.length != 0 &&
                                       <>
                                       <div className="table_div">
                                      <thead className="div_table_head">  
                                      <th style={{borderRight: "1px solid #084298"}}>Type</th>   
                                     <th>Quantity</th>
                                    </thead> 
                               {item.districtdata.map((data, itemindex) => {
                                return (
                                    <>
                                    <tr>
                                    <td className="td_quntity_variety">{ data.VerityName}</td>
                                        <td className="td_quntity_variety">{ formatter.format(data. Quantity)}</td>
                                        </tr>
                                    </>
                                )
                            })}
                            </div>
                            </>
                            }
                            </td>
                             </tr>
                                )
                            })}
                        </Table>
                    </Col>


                </Row>

            </Container>
            <ToastContainer />

        </Wrapper>
    )
}
export default DistrictWiseForecast