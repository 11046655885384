import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./styles"
import { Row, Container, Col } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import API_Auth from "../../API_Auth";
import { LoadingSpinner } from "../LoadingSpinner/index";
import { AiOutlineSearch } from "react-icons/ai";
import { Title } from "../../GlobalStyle";
import Edit from "../../images/edit.png";
import Delete from "../../images/delete.png"
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Activities = () => {
    const {
        isExpanded,
    } = useContext(UserContext);

    const [isLoading, setIsLoading] = useState(false)

    const [formTitle, setFormTitle] = useState('ADD ACTIVITY');
    const [formButtonValue, setFormButtonValue] = useState('ADD');
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey, setSearchKey] = useState('n');
    const [searchKeyTable, setSearchKeyTable] = useState('');

    const [addActivity, setaddActivity] = useState(false);
    const [activityName, setactivityName] = useState('');
    const [activityId, setactivityId] = useState('');
    const [activityNameErr, setactivityNameErr] = useState('');
    const[activities,setActivities]=useState([])


    useEffect(() => {
        setIsLoading(true);
        const fetchActivities = async () => {
            let result = await API_Auth.getActivities();
            result.sort((a, b) => a.HortiActivity.localeCompare(b.HortiActivity))

            setActivities(result);
            setIsLoading(false);
            var slice = result.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result.length / perPage));
            setOrgTableData(result);
            setTableData(slice);
            setSearchData(result);
        };
        fetchActivities()
            // make sure to catch any error
            .catch(console.error);
    }, []);

    const handleSearch = (e) => {
        let filterValue = e.target.value;
        setCurrentPage(0);
        if (filterValue == '') {
            let offsetsearch = 0;
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setCurrentPage(0);
            setOffSet(offsetsearch);
            setTableData(slice);
            setSearchData(orgtableData);
            setSearchKey('n');
        }
        else {
            let offsetsearch = 0;
            const filteredData = orgtableData.filter(item => {
                return item ?.HortiActivity ?.toLowerCase().includes(filterValue.toLowerCase())
            });
            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            setOffSet(offsetsearch);
            setSearchKeyTable(filteredData);
            setTableData(slice);
            setSearchData(filteredData);
            // setTableData(filteredData);
        }
    }
    const handleActivity = () => {
        setaddActivity(true)
        setactivityName('');
        setactivityNameErr('')
        setFormButtonValue('ADD');

    }
    const handleEditActivity = (obj) => {
        setaddActivity(true)
        // setFormTitle('Edit Role');
        setFormButtonValue('UPDATE');
        setactivityNameErr('');
        setactivityName(obj.HortiActivity);
        setactivityId(obj.id);

    }
    const handleClose = () => {
        setaddActivity(false)
    }

    const handleactivityName = (e) => {
        setactivityName(e.currentTarget.value)
    }

    const handleDeleteActivity = (obj) => {
        if (window.confirm('Are you sure you want to delete the Activity ?')) {
            // Save it!
            const fetchDeleteActivity = async () => {
                setIsLoading(true);
                setDisableSubmit(true)

                let result = await API_Auth.deleteActivity(obj.id);
                let res = result[0];
                if (res.Flag == 'Success') {
                    setIsLoading(false);

                    toast.success('Activity Deleted successfully');
                    setDisableSubmit(false)

                    const fetchAsset = async () => {
                        let result = await API_Auth.getActivities();

                        setActivities(result);
                        setIsLoading(false);
                        var slice = result.slice(offset, offset + perPage);
                        setPageCount(Math.ceil(result.length / perPage));
                        setOrgTableData(result);
                        setTableData(slice);
                        setSearchData(result);
                    };
                    // call the function
                    fetchAsset()
                        // make sure to catch any error
                        .catch(console.error);

                } else {
                    toast.error('Something went wrong please try again');
                }

            };
            // call the function
            fetchDeleteActivity()
                // make sure to catch any error
                .catch(console.error);
        } else {
            // Do nothing!
        }


    }

    const handlerowsperPage = (e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        if(searchKey=="y"){
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value));
            setTableData(slice);  
            setOffSet(0);  
            setSearchData(searchKeyTable);
        }
        else{
        const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
        setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value));
        setTableData(slice);
        setOffSet(0);
        setSearchData(orgtableData);
        }
    }
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        const data = orgtableData;
        if(searchKey=="y"){
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setOffSet(offsetdata);
            setSearchData(searchKeyTable);
        }else{
        const slice = data.slice(offsetdata, offsetdata + perPage);
        setPageCount(Math.ceil(data.length / perPage));
        setTableData(slice);
        setOffSet(offsetdata);
        setSearchData(data);
        }

    }

    const handleaddActivity = async () => {
        setDisableSubmit(true);
        let error = false;
        if (activityName == '') {
            setactivityNameErr('this field is required.');
            error = true;
        }
        else {
            setactivityNameErr('');
        }
        if (!error) {
            setIsLoading(true);

            if (formButtonValue == 'ADD') {
                setDisableSubmit(true)

                let result = await API_Auth.addActivity(activityName);
                let res = result[0];
                setDisableSubmit(false)

                if (res.Flag == 'Success') {
                    setaddActivity(false);
                    setIsLoading(false);

                    toast.success('Activity added successfully');
                    setInterval(() => {
                        window.location.reload();
                    }, 2000);

                } else {
                    setIsLoading(false);
                    setDisableSubmit(false);
                    toast.error('Something went wrong please try again');
                }
            } else if (formButtonValue == 'UPDATE') {
                setDisableSubmit(true)

                let result = await API_Auth.updateActivity(activityName, activityId);
                setDisableSubmit(false)

                let res = result[0];
                if (res.Flag == 'Success') {
                    setIsLoading(false);

                    setaddActivity(false);
                    toast.success('Activity updated successfully');
                    setInterval(() => {
                        window.location.reload();
                    }, 2000);

                } else {
                    setIsLoading(false);
                    setDisableSubmit(false);
                    toast.error('Something went wrong please try again');
                }
            }
        } else {
            setDisableSubmit(false);
        }
    }
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}
            <Container style={{ marginTop: 42 }}>
                <Row>
                    <Col lg={4} style={{ paddingLeft: 0 }}>
                        <Title>Activities</Title>
                    </Col>
                    <Col lg={5} style={{ paddingRight: 0 }}>
                        <div className="row_div">
                            <div className="input_div" >
                                <input type="type" style={{ margin: 0, background: 'transparent' }}
                                    name="filterValue"
                                    onChange={(e) => handleSearch(e)}
                                    placeholder="Search" />
                                <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                            </div>

                        </div>
                    </Col>
                    <Col>
                        <button className="add_button" onClick={() => handleActivity()}>
                            <p className="pcat_title"> {formTitle}</p></button>
                    </Col>

                </Row>
                <Row>

                    <Col lg={12} sm={6} md={6} className="table_head">
                        <Table className="tablelist" responsive>
                            <thead>
                                <tr>
                                    <th>Activity Name</th>
                                    <th style={{ textAlign: 'right' }}>Action</th>
                                </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr>
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {tableData.length != 0 && <tbody>
                                {tableData.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                                <td>{obj.HortiActivity}</td>
                                                <td style={{ textAlign: 'right' }}>
                                                    <div className="row_table_edit " onClick={() => handleEditActivity(obj)}>
                                                        <img src={Edit} />
                                                        <p>EDIT</p>
                                                    </div>
                                                    <div className="row_table_edit " onClick={() => handleDeleteActivity(obj)} style={{ marginRight: 10 }}>
                                                        <img src={Delete} />
                                                        <p>DELETE</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                            }

                        </Table>
                        <div>
                        <div className="pagination_row_div">
                            <p className="pagination_title">Showing items per page:</p>
                            <div className="pagination_input_div" >
                                <select className="pagination_select"
                                 style={{ margin: 0, background: 'transparent' }}
                                    name="pagination_value"
                                    value={perPage}
                                    onChange={handlerowsperPage} >
                                    <option value={4}>4</option>
                                    <option value={8}>8</option>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                </select>
                                </div>
                        </div>
                        <div className="pagination_row_right_div">
                        <p className="pagination_total">Showing {offset+1} to {searchData.length<offset+perPage&&
                        <span>{searchData.length}</span> }
                        {searchData.length>offset+perPage&&
                        <span>{offset+perPage }</span>} of {searchData.length} items</p>
                         <ReactPaginate
                            previousLabel={<ArrowBackIosIcon  className="pagination_prev_btn" color="#2438a7"/>}
                            nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7"/>}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"} 
                            forcePage={currentPage} />
                    </div>
                    </div>
                    
                    </Col>
                </Row>
                <Modal show={addActivity} onHide={handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    dialogclassname="modal-content">
                    <Modal.Header closeButton>
                        <Modal.Title> Activity</Modal.Title>
                    </Modal.Header>

                    <Modal.Body >
                        <div>
                            <label >Activity Name</label>
                            <br />
                            <div className="input_div" >
                                <input placeholder='Enter Activity Name'
                                    value={activityName}
                                    onChange={handleactivityName}
                                    style={{ width: "85%" }}
                                />
                            </div>
                            {activityNameErr != '' && <p className="alert-message">{activityNameErr}</p>}
                        </div>

                    </Modal.Body>
                    <Modal.Footer className="modal-footer">
                        <div className="row_div">
                            <button className="add_button"
                            disabled={disableSubmit} onClick={() => handleaddActivity()} style={{
                                padding: 12,
                                height: 45,
                                margin: 10
                            }}>
                                <p className="pcat_title">{formButtonValue}</p></button>
                        </div>
                    </Modal.Footer>
                </Modal>
                </Container>
            <ToastContainer />

        </Wrapper>
    )
}
export default Activities