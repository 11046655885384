import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Wrapper, } from "./styles";
import { UserContext } from "../../context"
import { LoadingSpinner } from "../LoadingSpinner/index";
import { Row, Col,Container } from "react-bootstrap";
import BadalImage from "../../images/badal.png"
import NishaImage from "../../images/nisha.png"
import SiddiqiImage from "../../images/siddiqi.png";
import CopyRight from "../../elements/CopyRights";
import Card from 'react-bootstrap/Card';
// DONE
function Iframe(props) {
    return (<div dangerouslySetInnerHTML={ {__html:  props.iframe?props.iframe:""}} />);
  }
const ContactUs = () => {
    const navigate = useNavigate();
    const { setSessionIsLoggedIn, setSessionLoggedRole, setUserEmail, setSessionLoggedUser, setLoggedUserName } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [passwordType, setPasswordType] = useState("password");

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password");
    }
    const iframe = '<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14647.24921343381!2d85.3139053!3d23.3950065!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6505e23b9879d126!2sKrishi%20Bhawan!5e0!3m2!1sen!2sin!4v1651830308368!5m2!1sen!2sin" width="100%" height="500" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'; 

    return (
        <Wrapper>
            {isLoading && <LoadingSpinner />}
            <Container>
                <Row>
                <Col lg={12} className="text_center margin_top" >
                <h1 className="pseudo_border">Contact us</h1>
                </Col>
                </Row>
                    
                <Row className="margin_top_btm">
                <Col  lg={1}>
                </Col>
                <Col  lg={10}>
                 <Card>
                 <Row>
                <Col lg={6}> 
                <div className="map map-full rounded-top rounded-lg-start">
                <Iframe iframe={iframe} />
                  </div>       
                </Col>
                 <Col  lg={6}>
                 <div className="contact_box">
					  <h4 className="h4_styles">Directorate of Agriculture, Government of Jharkhand</h4>

                    <div className="d_flex_row">
                      <div className="align_self_start">
                        <h5>Address</h5>
                        <address>Department of Agriculture, Animal Husbandry & Cooperative<br/>1st Floor, Krishi Bhawan, Kanke, Ranchi, Jharkhand -834006</address>
                      </div>
                    </div>
                     <div className="d_flex_row">
                      <div>
                        <h5>Phone</h5>
                        <p>2490929, <br/> 2490578</p>
                      </div>
                    </div>
                    <div className="d_flex_row">
                      <div>
                        <h5>E-mail</h5>
                        <p><a href="#" className="link_body">jhagriculture@gmail.com</a></p>
                      </div>
                    </div> 
                    </div>
                </Col>
                </Row>
                </Card>
                </Col>
                <Col  lg={1}>
                </Col>
            </Row>
            </Container>
            <CopyRight/>
        </Wrapper>
    );
};

export default ContactUs;
