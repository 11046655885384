import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./AddVarietyDetail.styles"
import { Title } from "../../GlobalStyle.js"
import API_Auth from "../../API_Auth";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingSpinner } from "../LoadingSpinner/index";
import { Container, Row, Col } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";

const AddVarietyDetail = () => {
    let { varietyID } = useParams();
    const navigate = useNavigate();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const {
        isExpanded,
    } = useContext(UserContext);

    const [formTitle, setFormTitle] = useState('Add New Type');

    const [isLoading, setIsLoading] = useState(false);

    const [cropName, setCropName] = useState('');
    const [cropName_API, setCropName_API] = useState('');
    const [cropNameErr, setCropNameErr] = useState('');

    const [variety, setVariety] = useState('');
    const [varietyErr, setVarietyErr] = useState('');

    const [perAcreLimit, setPerAcreLimit] = useState('');
    const [perAcreLimiterr, setPerAcreLimitErr] = useState('');

    const [ratePerKg, setRatePerKg] = useState('');
    const [ratePerKgErr, setRatePerKgErr] = useState('');

    const [season, setSeason] = useState('');
    const [seasonErr, setSeasonErr] = useState('');

    const [uom, setUOM] = useState('');
    const [uomErr, setuomErr] = useState('');

    const [seasonData, setSeasonData] = useState([]);

    const [uomData, setuomData] = useState([]);

    const [formButtonValue, setFormButtonValue] = useState('ADD TYPE');
    const [seedTypes, setSeedTypes] = useState([]);
    const [seedType, setSeedType] = useState('');
    const [seedTypeErr, setSeedTypeErr] = useState('');
    const[seedData,setSeedData]=useState([]);

    const [seedText, setSeedText] = useState('a');
    const [seedsSuggestion, setSeedsSuggestion] = useState([]);

    const [seedErr, setSeedErr] = useState('');


    const handleBackClick = () => {
        navigate("/variety");
        navigate(0);

    }

    useEffect(() => {
        const fetchVariety = async () => {
            const seedType = await API_Auth.getSeedType();
            const seeds=await API_Auth.getSeeds();
            console.log("seeds",seeds)
            setSeedData(seeds);
            console.log("seed_type", seedType);
            setSeedTypes(seedType);
            if (varietyID != 'add') {
                let res = await API_Auth.getVarietyById(varietyID);
                let seed_det = res[0];
                console.log("seed_det", seed_det);

                setCropName(res.length != 0 ? seed_det.CropName : '');
                setCropName_API(res.length != 0 ? seed_det.CropName : '');
                setVariety(res.length != 0 ? seed_det.Variety : '');
                setPerAcreLimit(res.length != 0 ? seed_det.PerAcreLimit : '');
                setRatePerKg(res.length != 0 ? seed_det.RatePerKg : '');
                setSeason(res.length != 0 ? seed_det.Season : '');
                setUOM(res.length != 0 ? seed_det.Uom : '');
                setSeedType(res.length != 0 ? seed_det.verityType : '');
                setFormTitle('Edit Type');
                setFormButtonValue('UPDATE TYPE');
            }
            const result = await API_Auth.getSeasons();
            console.log(result);
            setSeasonData(result)

            const result_uom = await API_Auth.getUOMBase_url('SELECTOUM','','');
            console.log(result_uom);
            setuomData(result_uom)

            
        };
        // call the function
        fetchVariety()
            // make sure to catch any error
            .catch(console.error);
    }, []);

    let error = false;
    const handleAddVariety = async () => {
        console.log(cropName);
        if (cropName_API == '') {
            setCropNameErr('This field is required, please select in search list');
            error = true;
        }
        else {
            setCropNameErr('  ');
        }
        if (variety == '') {
            setVarietyErr('This field is required');
            error = true;
        }
        else {
            setVarietyErr('  ');
        }
       /*  if (seedType == '') {
            setSeedTypeErr('This field is required');
            error = true;
        }
        else {
            setSeedTypeErr('  ');
        }
        if (perAcreLimit == '') {
            setPerAcreLimitErr('This field is required');
            error = true;
        }
        else {
            setPerAcreLimitErr('  ');
        } */
       /*  if (season == '') {
            setSeasonErr('This field is required');
            error = true;
        }
        else {
            setSeasonErr('  ');
        } */
        if (uom == '') {
            setuomErr('This field is required');
            error = true;
        }
        else {
            setuomErr('  ');
        } 
        
        if (!error) {
           // setIsLoading(true)
            setDisableSubmit(true);
            if (varietyID == 'add') {
                 let result = await API_Auth.addVariety(cropName_API, variety, 'Horticulture', 1, season,uom);
                let res = result[0];
                if (res.Flag == 'Success') {
                    setIsLoading(false);

                    toast.success('Type added successfully');
                    setTimeout(() => {
                        navigate("/variety");
                    }, 2000);

                } else {
                    setIsLoading(false);
                    setDisableSubmit(false);
                    toast.error('Something went wrong please try again');
                } 
            } else {
                let result = await API_Auth.updateVariety(cropName_API, variety, 'Horticulture', 1, season, varietyID,uom);
                let res = result[0];
                if (res.Flag == 'Success') {
                    setIsLoading(false);

                    toast.success('Type updated successfully');
                    setTimeout(() => {
                        navigate("/variety");
                    }, 2000);
                } else {
                    setIsLoading(false);
                    setDisableSubmit(false);
                    toast.error('Something went wrong please try again');
                }
            }
        }


    }
    const handleSeedChange=(item)=>{
        console.log("item",item)
        setCropName_API(item.CropName);
        setCropName(item.CropName);
        setSeedType(item.SeedType)
        setSeedText('a');

    }
    const handleInput = async (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        if (name === "cropName") {
            if (value.length == 0) {
                setSeedsSuggestion(seedData);
                console.log(cropName);
                setCropName(value);
            } else {
                console.log(cropName);
                const suggestdta = seedData.filter(item => {
                    return item ?.CropName ?.toLowerCase().includes(value.toLowerCase())                    
                });
                setCropName(value);
                setSeedText('f')
                if (suggestdta.length == 0) {
                    setSeedErr('Seed not found');
                } else {
                    setSeedErr('');
                }
                setSeedsSuggestion(suggestdta);
            }
        }
        if (name === "variety") setVariety(value);
        if (name === "perAcreLimit") setPerAcreLimit(value);
        if (name === "ratePerKg") setRatePerKg(value);
        if (name === "season") setSeason(value);
        if (name === "uom") setUOM(value);
        if (name == "seedType") setSeedType(value);
    }
    const renderItemSuggestions = () => {
        if (seedsSuggestion.length == 0) {
            return null;
        }
        return (
            <ul className={seedsSuggestion.length != 0 && seedText == 'f' ? "ul_search" : "ul_search_length"} >
                {seedsSuggestion.length != 0 && seedText == 'f' &&
                    <>

                        {seedsSuggestion.map((item, index) => {
                            return (
                                <li className="li_search" onClick={() => handleSeedChange(item)}>{item.CropName}</li>
                            )
                        })}
                    </>
                }
            </ul>
        )
    }
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            <Container>
                <Row className="row_title">
                    <Col lg={4}><p>{formTitle}</p></Col>
                    <Col lg={6}></Col>
                    <Col lg={2}>
                        <div className="back_button" onClick={() => handleBackClick()}>
                            <IoIosArrowBack size={18} />
                            <h6>Back</h6>
                        </div>
                    </Col>
                </Row>
            </Container>
            {isLoading && <LoadingSpinner />}

            <hr />
            <Container>
                <Row>
                    <Col lg={6} md={6} sm={6}>
                    <div className="inputdiv">

                        <label>Component Name</label>
                            <br />
                                        <div className="row_div_search">
                                            <div className="input_div" >
                                                <input type="type" style={{ margin: 0, background: 'transparent',width: "75%"  }}
                                                    name="cropName"
                                                    value={cropName}
                                                    onChange={handleInput}
                                                    placeholder="Component" />
                                                <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                                            </div>

                                        </div>
                                        <>
                                                {renderItemSuggestions()}
                                            </>
                       {/*  <div className="inputdiv">
                            <label>Component Name</label>
                            <br />
                             <input type="text"
                                name="cropName"
                                value={cropName}
                                placeholder="Enter Component Name"
                                onChange={handleInput}
                                style={{ width: "75%" }} /> 
                                                                   
                                        <div className="row_div_search">
                                            <div className="input_div" >
                                                <input type="type" style={{ margin: 0, background: 'transparent' }}
                                                    name="cropName"
                                                    value={cropName}
                                                    onChange={handleInput}
                                                    placeholder="Component Name" />
                                                <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                                            </div>

                                        </div>            
                                                                                <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
 */}
                            {cropNameErr != '' && <p className="alert-message">{cropNameErr}</p>}
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={6}>
                        <div className="inputdiv">
                            <label>Type</label>
                            <br />
                            <input type="text"
                                name="variety"
                                value={variety}
                                placeholder="Enter type"
                                onChange={handleInput}
                                style={{ width: "75%" }} />
                            {varietyErr != '' && <p className="alert-message">{varietyErr}</p>}
                        </div>
                    </Col>
                {/*     <Col lg={6} md={6} sm={6}>
                        <div className="inputdiv">
                            <label>Per Acre Limit</label>
                            <br />
                            <input type="number"
                                name="perAcreLimit"
                                value={perAcreLimit}
                                placeholder="Enter perAcreLimiter"
                                onChange={handleInput}
                                style={{ width: "75%" }} />
                            {perAcreLimiterr != '' && <p className="alert-message">{perAcreLimiterr}</p>}
                        </div>
                    </Col> */}
                 {/*    <Col lg={6} md={6} sm={6}>
                       {/*  <div className="inputdiv">
                            <label>Rate Per Kg</label>
                            <br />
                            <input type="text"
                                name="ratePerKg"
                                value={ratePerKg}
                                placeholder="Enter Rate per kg"
                                onChange={handleInput}
                                style={{ width: "75%" }} />
                            {ratePerKgErr != '' && <p className="alert-message">{ratePerKgErr}</p>}
                        </div> 
                        <div className="inputdiv">
                        <label>Component Type</label>
                            <br />
                             <select value={seedType} name="seedType" onChange={handleInput}
                            style={{ width: "75%" }} >
                                    <option value=''>Select Seed Type</option>

                                    {seedTypes.length != 0 &&
                                        <>
                                            {seedTypes.map((item, index) => {
                                                return (
                                                    <option value={item.SeedType}>{item.SeedType}</option>
                                                )
                                            })}
                                        </>}
                                </select> 
                                {seedTypeErr != '' && <p className="alert-message">{seedTypeErr}</p>}
                            </div>
                    </Col> */}
                    <Col lg={6} md={6} sm={6}>
                        <div className="inputdiv">
                            <label>UOM</label>
                            <br />
                            <select value={uom} onChange={handleInput} name="uom" style={{ width: "75%" }} >
                            <option value="">Select UOM</option>
                            {uomData.length!=0 &&<>
                            {uomData.map((item,index)=>{
                             return(<option value={item.UOM}>{item.UOM}</option>
                                    )
                                })}
                            </>}
                                
                                </select>
                        {uomErr != '' && <p className="alert-message">{uomErr}</p>}
                        </div>
                    </Col>
                    {/* <Col lg={6} md={6} sm={6}>
                        <div className="inputdiv">
                            <label>Season</label>
                            <br />
                

                            <select value={season} onChange={handleInput} name="season"
                                style={{ width: "75%" }} >

                            <option value="">Select Season</option>
                            {seasonData.length!=0 &&<>
                                {seasonData.map((item,index)=>{
                                    return(
                                        <option value={item.CombYearSeason}>{item.CombYearSeason}</option>
                                    )
                                })}
                            </>}
                                
                                </select>
                        {seasonErr != '' && <p className="alert-message">{seasonErr}</p>}
                        </div>
                    </Col> */}
                </Row>
            <Row>
                <Col lg={5}>
                    <div className="row_div">
                        <button className="add_button" disabled={disableSubmit} onClick={() => handleAddVariety()}
                            style={{
                                margin: 12,
                                width: "92%"
                            }}
                        >
                            <p className="pcat_title">{formButtonValue}</p></button>
                    </div>
                </Col>
            </Row>
            </Container>
        <ToastContainer />

        </Wrapper >
    )

}
export default AddVarietyDetail