import React, { Component } from 'react';
import { Outlet } from "react-router-dom";
import Header1 from './Header1';


function Layout1({props}){
    return(
        <>
        <Header1 />
        <Outlet/>
        </>
    )
}

export default Layout1;