import React, { useState, useEffect, useContext } from "react";
// Styles
import {
    Wrapper,
    LCLogoImg
} from "./Header.styles";
// Routing
import { Link, useNavigate } from "react-router-dom";
// Context
import { PropTypes } from "prop-types";
import { UserContext } from "../../context";
import { Row, Container, Col } from "react-bootstrap"
import Modal from 'react-bootstrap/Modal';
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import { FaRegEye, FaEyeSlash } from "react-icons/fa"
import PhoneIcon from '@mui/icons-material/Phone';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

const Header = ({ callback, showImage }) => {
    let isloggedin, user, logged_role, user_email, logged_user;
    const {
        isExpanded,
        setIsExpanded,
        sessionLoggedRole, sessionIsLoggedIn, UserEmail,
        setSessionIsLoggedIn, loggedUserName, setLoggedUserName, setSessionLoggedRole,
        setUserEmail, setSessionLoggedUser, sessionLoggedUser, title, setTitle
    } = useContext(UserContext);
    const [userRole, setUserRole] = useState('');
    const [changePassword, setchangePassword] = useState(false);
    const [userName, setUserName] = useState('');
    const [departmentId, setDepartmentId] = useState('');
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordErr, setPasswordErr] = useState('');
    const [passwordType, setPasswordType] = useState("password");

    console.log("loggedUserName", loggedUserName)

    isloggedin = sessionIsLoggedIn;
    user = loggedUserName;
    user_email = UserEmail;
    logged_role = sessionLoggedRole;

    const isloggedin_ls = localStorage.getItem("isLogged");
    const user_name_ls = localStorage.getItem("loggedUserName");
    const logged_role_ls = localStorage.getItem("loggedRole");
    const User_email = localStorage.getItem("UserEmail");
    if (!isloggedin || !logged_role) {
        isloggedin = isloggedin_ls;
        user = user_name_ls;
        user_email = User_email
        logged_role = logged_role_ls;
    }

    const navigate = useNavigate();
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }


    useEffect(() => {
        isloggedin = sessionIsLoggedIn;
        user = loggedUserName;
        user_email = UserEmail;
        logged_role = sessionLoggedRole;
        const user_role = localStorage.getItem("loggedRole");
        setUserRole(user_role)
        // if token and user missing in context
        if (!sessionIsLoggedIn && !loggedUserName && !UserEmail) {

            // set for future context,
            setSessionIsLoggedIn(isloggedin_ls);
            setLoggedUserName(user_name_ls);
            setUserEmail(User_email);
            setSessionLoggedRole(logged_role_ls);
            isloggedin = isloggedin_ls;
            user = user_name_ls;
            user_email = User_email
            logged_role = logged_role_ls;

            if (isloggedin_ls != 'yes' && isloggedin_ls !== 'Admin') {
                // redirect to login page
                navigate("/login");
            }
        }
    }, []);
    const handleToggler = () => {
        if (isExpanded) {
            setIsExpanded(false);
            return;
        }
        setIsExpanded(true);
    };

    const handlegotoAbout = (e) => {
        navigate("/aboutus");
    }
    const logOut = (e) => {
        localStorage.clear();
        navigate("/login");
    }
    const handleClose = () => {
        setchangePassword(false);
        setPasswordErr('');
    }

    const handleUserProfile = async () => {
        var id = localStorage.getItem("UserID");
        setchangePassword(true);
        setPasswordErr('');
        let res = await API_Auth.getUserById(id);
        let user_det = res[0];
        console.log("res", res[0]);
        setUserName(res.length != 0 ? user_det.UserName : '');
        setPassword(res.length != 0 ? user_det.Password : '')
        // navigate("/user-profile/" +id);
    };
    const handleuserPassword = (e) => {
        setPassword(e.currentTarget.value);
    };
    const handleupdateuser = async () => {
        setDisableSubmit(true);
        let error = false;
        if (password == '') {
            setPasswordErr('this field is required.');
            error = true;
        }
        else {
            setPasswordErr('');
        }
        if (!error) {
            setIsLoading(true);
            var id = localStorage.getItem("UserID");
            let result = await API_Auth.updateUserPassword(id, password);
            console.log(result);
            if (result.errorMessage == "") {
                setIsLoading(false);
                setchangePassword(false);
                toast.success('Password updated successfully');
                /*  setInterval(() => {
                     window.location.reload();
                 }, 2000); */
            } else {
                setIsLoading(false);
                setDisableSubmit(false);
                toast.error('Something went wrong please try again');
            }
        } else {
            setDisableSubmit(false);
        }
    }

    return (
        <>
        {/* <Wrapper> */}
           {/*  <div className="sidebar-header">
                <p className="store_name">Jharkhand Seed</p>
            </div>
            <div className="header-right">
                <div className="section-two" style={{marginTop:6}}>
            <div className="helpline_cont" style={{ marginRight: 0,marginTop: 10}}>
             <PhoneIcon  style={{ marginTop: -2,color:"#ffff"}}/>
             </div>
             <div className="helpline_cont" style={{marginLeft: 0, marginRight: 0,marginTop: 10}}>
          <h6 className="help_line_text">TOLL FREE -</h6>
      </div>
      <div className="helpline_cont" style={{ marginLeft: 0,marginTop: 10}}>
      <h6 className="help_line_text" style={{ cursor: 'default' }}>1800-123-1136</h6></div> 
      <div className='profile'>
               <h6 className='name' onClick={() => handleUserProfile()}>{loggedUserName}</h6></div>
                    <div className='logout' onClick={(e) => logOut(e)}>
                        <h6 style={{ color: 'lightgray' }}>|</h6>
                        <h6 style={{ cursor: 'pointer' }}>Logout</h6>
                    </div>
                </div>
            </div> */}
            <Navbar style={{background: '#000044',overflow: 'auto',whiteSpace: 'nowrap'}}  fixed="top" expand={true}>
        <Navbar.Brand style={{ fontWeight:600,fontFamily:'Roboto',fontSize:24,color:"#ffff",marginLeft: 14}}>Jharkhand Agriculture Allied Blockchain Application</Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end" style={{marginRight: 38}} id="navbarScroll">
        <Nav navbarScroll>
          <Navbar.Text>
          <PhoneIcon  style={{ marginTop: -2,color:"#ffff"}}/>
          </Navbar.Text>
          <Navbar.Text style={{ fontWeight:700,fontFamily:'Roboto',fontSize:16,color:"#ffff"}}>TOLL FREE -</Navbar.Text>
          <Navbar.Text style={{ fontWeight:700,fontFamily:'Roboto',fontSize:16,color:"#ffff",marginLeft:12}}>1800-123-1136</Navbar.Text>
          <Navbar.Text onClick={() => handleUserProfile()}
          style={{ fontWeight:700,fontFamily:'Roboto',fontSize:18,color:"#ffff",cursor:'pointer',marginLeft:30,borderBottom: '2px solid'}}>
          {loggedUserName}
          </Navbar.Text>
          <Navbar.Text style={{ color:"#ffff",marginLeft:8}}>|</Navbar.Text>
          <Navbar.Text onClick={(e) => logOut(e)}
          style={{ fontWeight:700,fontFamily:'Roboto',fontSize:18,color:"#6EDC8F",cursor:'pointer',marginLeft:8}}>
          Logout</Navbar.Text>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
            <Modal show={changePassword} onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                dialogclassname="modal-content">
                <Modal.Header closeButton>
                    <Modal.Title> Change Password</Modal.Title>
                </Modal.Header>

                <Modal.Body >
                    <div>
                        <label >User Name</label>
                        <br />
                        <div className="input_div" >
                            <input placeholder='Enter Department'
                                value={userName}
                                disabled={true}
                                //onChange={handleuserPassword}
                                style={{ width: "85%" }}
                            />
                        </div>
                    </div>
                    <div>
                        <label >Password</label>
                        <br />
                        <div className="row_div">
                            <div className="inputdiv_modal" >
                                <input placeholder='Enter Password'
                                    type={passwordType}
                                    value={password}
                                    onChange={handleuserPassword}
                                    style={{ margin: 0, background: 'transparent', fontSize: 18 }} />


                                <div onClick={togglePassword}>
                                    {passwordType === "password" ? <FaRegEye style={{ marginRight: 10, marginTop: 10 }} size={22} /> : <FaEyeSlash style={{ marginRight: 10, marginTop: 10 }} size={22} />}
                                </div>
                            </div>
                        </div>

                        {passwordErr != '' && <p className="alert-message">{passwordErr}</p>}
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <div className="row_div">
                        <button className="add_button" onClick={() => handleupdateuser()} style={{
                            padding: 12,
                            height: 45,
                            margin: 10
                        }}>
                            {/*                                 <img src={plus} style={{ margin: 5 }} /> */}
                            <p className="pcat_title">{"Update"}</p></button>
                    </div>
                </Modal.Footer>
            </Modal>
{/* </Wrapper>  */}
                  </>
    );
};
Header.propTypes = {
    callback: PropTypes.func,
};

export default Header;
