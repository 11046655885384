import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Wrapper, } from "./styles";
import { UserContext } from "../../context"
import { LoadingSpinner } from "../LoadingSpinner/index";
import { Row, Col, Container } from "react-bootstrap";
import SettleMint_logo from "../../images/SettleMint_logo.svg";
import API_Auth from "../../API_Auth";
import playApp from "../../images/play-app.png";
import HorticultureSplash_Screen from "../../images/HorticultureSplash_Screen.jpg";

import GooglePlay from "../../images/play-btn.png"
import Carousel from 'react-bootstrap/Carousel';
import banner1 from "../../images/horti_slide1.png";
import banner2 from "../../images/horti_slide2.png";
import banner3 from "../../images/horti_slide4.png";
/* import banner4 from "../../images/banner4.svg"; */
import CopyRight from "../../elements/CopyRights";
/* import Gallery from "react-photo-gallery";
import ReactImageVideoLightbox from "react-image-video-lightbox";
 */


import photoImage1 from "../../images/photo1.jpeg"
import photoImage2 from "../../images/photo2.jpeg"
import photoImage3 from "../../images/photo3.jpeg"
import photoImage4 from "../../images/photo4.jpeg"
import photoImage5 from "../../images/photo5.jpeg"
import modalmage1 from "../../images/modalimage1.jpeg"
import  modalmage2 from "../../images/modalimage2.jpeg"
import  modalmage3 from "../../images/modalimage3.jpeg"

import  modalmage4 from "../../images/modalimage4.jpeg"



// DONE


const data = [
  {
    index: 0,
    url: modalmage1,
    src: photoImage1,
    type: "photo"
  },
  {
    index: 1,
    url:modalmage2,
    src: photoImage2,
    type: "photo"
  },
  {
    index: 2,
    url: "https://www.youtube.com/embed/1a_t0Ubpc5A",
    src: photoImage3,
    type: "video"
  },
  {
    index: 3,
    src: photoImage4,

    url: modalmage3,
    type: "photo"
  },
  {
    index: 3,
    src: photoImage5,

    url: modalmage4,
    type: "photo"
  }
];

const HomeScreen = () => {
  const navigate = useNavigate();
  const { setSessionIsLoggedIn, setSessionLoggedRole, setUserEmail, setSessionLoggedUser, setLoggedUserName } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setopen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [passwordType, setPasswordType] = useState("password");

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password");
    }
  

  
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const openlightbox = (index) => {
    console.log(index);
    setCurrentIndex(index);
    setopen(true);
  };
  return (
    <Wrapper>
      {isLoading && <LoadingSpinner />}
      <Carousel activeIndex={index} onSelect={handleSelect}>
       {/* <Carousel.Item interval={2000}>
          <img
            className="d-block w-100"
            src={banner1}
            alt="First slide"
          />
        </Carousel.Item>*/}
        <Carousel.Item interval={2000}>
          <img
            className="d-block w-100"
            src={banner2}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item interval={2000}>
          <img
            className="d-block w-100"
            src={banner3}
            alt="Third slide"
          />
        </Carousel.Item>
       {/*  <Carousel.Item interval={2000}>
          <img
            className="d-block w-100"
            src={banner4}
            alt="Third slide"
          />
        </Carousel.Item> */}
      </Carousel>
      <Container>
        <Row className="play-sec">
          <Col lg={12} md={12} sm={12} className="text_center" >
            <h1 className="pseudo_border">External Links</h1>
          </Col>
        </Row>
        <Row className="external_links">
          <Col lg={12} md={12} sm={12} className="text_center">
            <a href="https://agri.jharkhand.gov.in" target="_blank" >  Department of Agriculture, Animal Husbandry & Co-operative </a>
          </Col>
          <Col lg={4} className="text_center">
            <a href="http://horticulturejharkhand.org" target="_blank" >  Directorate Agriculture Allied </a>

          </Col>
          <Col lg={4} className="text_center">
            <a href="http://jharkhandfisheries.org" target="_blank" >  Directorate Fisheries</a>
          </Col>
          <Col lg={4} className="text_center">
            <a href="https://mmkay.jharkhand.gov.in/" target="_blank" >Mukhyamantri Krishi Ashirwad Yojana </a>
          </Col>
          <Col lg={4} className="text_center">
            <a href="https://jkrmy.jharkhand.gov.in/Default?lb=en" target="_blank" >Jharkhand Krishi Rin Mafi Yojna </a>
          </Col>
          <Col lg={4} className="text_center">
            <a href="http://www.sameti.org/" target="_blank" >  SAMETI </a>
          </Col>
          <Col lg={4} className="text_center">
            <a href="http://nhmjharkhand.org.in/" target="_blank" >  National Agriculture Allied Mission, Jharkhand </a>
          </Col>
        </Row>
      </Container>
     {/*  <Row  className="play-sec">
        <Col lg={12} md={12} sm={12} className="text_center" >
          <h1 className="pseudo_border">Photos & Videos Gallery</h1>
        </Col>
      </Row>
      <Row className="play-sec">
      

        <Col lg={6} md={12}>
          <div><img src={photoImage1} alt="image" className="galleryImage1"  onClick={() => openlightbox(0)}/></div>
          <div><img src={photoImage3} alt="image" className="gallery-Image2" onClick={() => openlightbox(1)} /></div>
        </Col>
        <Col lg={6} md={12}>
          <div><img src={photoImage2} alt="image" className="gallery-Image3"  onClick={() => openlightbox(2)}/></div>
          <div>
            <Row>
              <Col style={{padding:0}} lg={6} md={12}>
                <div><img src={photoImage4} alt="image" className="gallery-Image4" onClick={() => openlightbox(3)} /></div>
              </Col>
              <Col style={{padding:0}} lg={6} md={12}>
                <div><img src={photoImage5} alt="image" className="gallery-Image4"  onClick={() => openlightbox(4)}/></div>
              </Col>
            </Row>
          </div>
{/* {open && (
  <ReactImageVideoLightbox
    data={data}
    startIndex={currentIndex}
    showResourceCount={true}
    onCloseCallback={() => setopen(false)}
    onNavigationCallback={(currentIndex) =>
      console.log(`Current index: ${currentIndex}`)
    }
  />
)}
    </Col>

      </Row> */}
      <Container>
        <Row className="play-sec">
          <Col lg={8} style={{ background: 'none' }} className="form-sec">
            <h1>Android Application  For Agriculture Allied Management</h1>
            <p>Download App</p>
            <h2>Available for <span className="theme-color">Farmers</span> and <span className="theme-color">Distributors.</span> </h2>
            <a href="https://play.google.com/store/apps/details?id=com.settlemintindia.seedtracejharkhand">
              <img src={GooglePlay} /></a>
            <a href="https://jharkhandseed.com/mobile/app-debug.apk" target="_blank" title="Download APK"
              alt="Download APK"><button type="submit" className="btn btn-primary btn-customized" style={{ marginLeft: 4 }}>
                Download APK</button> </a>
          </Col>
         {/*  <Col lg={4}>
            <img className="img-fluid" src={HorticultureSplash_Screen} />
          </Col> */}
        </Row>
      </Container>
      <CopyRight />
    </Wrapper>
  );
};

export default HomeScreen;
