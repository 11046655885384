import styled from "styled-components";

export const Wrapper = styled.div`
.tablelist {
  margin-top:15px;
  margin-right:5px;;
}
 
thead{
  color:black;
  background:#c0d3ef;
  border:1px solid #c0d3ef;
  border-radius:10px;
}
td{
  color:black;
  font-weight:400;
  font-size:14px;
  height:30px;
  padding:5px;
  font-family:Roboto;
  text-transform: capitalize;
  text-align:center;
}
th{
  font-size:14px;
  font-weight:500;
  font-family:Roboto;

}
.export_button {
  display:flex;
  flex-direction:row;
  background:#ea9ca1;

  color:black;
  border:#4EACFB;
  padding:10px;
  height:45px;
  margin: 10px 0px;
  width:100%;
  text-align:center;
  justify-content:center;
  align-item:center;
  box-shadow: 0px 2px 2px #00000030;
border-radius: 6px;
opacity: 1;
}

.table-head-content {
  padding: 13px 13px 0 13px;
  background: white;
  border-bottom: 1px solid #E2E2E2;   
}  
.row_div {
  display:flex;
  flex-direction:row;
  padding: 8px;
}
.input_div {
  width: 70%;
  color:var(--black);
  font-family: 'ARIAL';
  height:45px;
  border-radius:6px;
  margin:5px;
  background:white;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  :focus {
    border: 0px solid #DFDFDF !important; 
  }
  input,select{
    border: 2px solid #DFDFDF;
    :focus {
       outline: none !important;
       border-color:none;  
     }
    }
   
}
.title{
  font-family:Roboto;
  font-size:16px;
  font-weight:600;
  margin-left:10px;
  margin-bottom:0 !important;
  margin-top:15px;

}

.adddiv {
  background:white;
  height:95%;
  width:80%;
  margin-top:15px;
  margin-left:20px;
  border-radius:5px;
  
  input,select{
    width:85%;
    height: 40px;
    border: 1px solid #00001F24;
    border-radius: 6px;
    margin: 15px ;
    padding: 10px; 
    :focus {
      outline: none !important;
      border-color:none;  
    }
   }
  
}
.add_cat_button {
  background:#000044;
  color:white;
  border:#4EACFB;
  padding:10px;
  height:40px;
  width:85%;
  margin:100px 25px 0px;
  font-weight:bold;
  border-radius:6px;
}


`