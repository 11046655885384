import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./styles"
import { Row, Container, Col } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import { useNavigate, useParams } from "react-router-dom";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle"
import { LoadingSpinner } from "../LoadingSpinner/index";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Modal from "react-bootstrap/Modal";
import { base_url } from "../../config"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const TestAgencyRequests = () => {
    const {
        isExpanded,
    } = useContext(UserContext);

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);

    let { PurchaseID, type } = useParams();


    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey, setSearchKey] = useState('n');
    const [searchKeyTable, setSearchKeyTable] = useState('');
    const[userRole,setUserRole]=useState('');
    const[testModal,showtestModal]=useState('');
    const [seedReport, setseedReport] = useState('');
    const [seedReportErr, setseedReportErrr] = useState('');
    const [reportComments, setreportComment] = useState('');
    const [commentsErr, setcommentsErr] = useState('');
    const [testing_det, setTestingDet] = useState('');
    
    const[report_file,setreport_file]=useState(false);
    const[report_fileErr,setreport_fileErr]=useState(false);
    const[report_fileShow,setreportFileShow]=useState(false);
    const [shownDrawerIndex, setshownDrawerIndex] = useState(null);
    const [expand_det, setExpandDetails] = useState('');
    
    const handleSearch = (e) => {
        let filterValue = e.target.value;
        setCurrentPage(0);
        if (filterValue == '') {
            let offsetsearch = 0;
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setTableData(slice);
            setSearchData(orgtableData);
            setOffSet(offsetsearch);
            setCurrentPage(0);
            setSearchKey('n');
        }
        else {
            let offsetsearch = 0;
            const filteredData = orgtableData.filter(obj => {
                return (obj ?.SeedName ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                            obj ?.VerietyID ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                                obj ?.DemandQuantity ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                                            obj ?.transitQuantity ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                                                obj ?.receiveQuantity ?.toLowerCase().includes(filterValue.toLowerCase()))
            });

            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            setOffSet(offsetsearch);
            setSearchKeyTable(filteredData);
            setTableData(slice);
            setSearchData(filteredData);
        }
    }
     const handleFileupload = async () => {
        var formData = new FormData();
        var imagefile = document.querySelector('#file-upload');
       // formData.append("report_file", imagefile.files[0]);
        console.log(imagefile.files[0].name);
        setreport_file(imagefile.files[0].name);
       // console.log(formData);
       /*  let upload_res = await API_Auth.uploadImage(formData);
        if (upload_res.data == 'File is uploaded') {
            var file_name = '';
            for (var key of formData.entries()) {
                file_name = key[1].name;
            }
            console.log(upload_res);
            console.log(file_name);
           // setreport_file(file_name);
            setreportFileShow(base_url + "/images?filename=" + file_name);
        } */
    } 
    useEffect(() => {
        setIsLoading(true);
        const user_role = localStorage.getItem("loggedRole");
        const userid = localStorage.getItem("UserID");
        console.log("userid", userid)
        setUserRole(user_role);

        const fetchForecast = async () => {

            const result = await API_Auth.getRequestTestingAPI('SHOWTESTTESTINGAGENCY','', '', '', '','',
            '',userid,'', '', '', '', '', '','', '');  
            console.log("result", result)
            setIsLoading(false);
            //seting pagenation data
            var slice = result.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result.length / perPage));
            setOrgTableData(result);
            setTableData(slice);
        };
        // call the function
        fetchForecast()
            // make sure to catch any error
            .catch(console.error);
    }, []);

    const showDrawyer = async (e, index,obj) => {
        console.log(index, shownDrawerIndex);
        if (shownDrawerIndex == index) {
            let all_testings = [...orgtableData];
            setExpandDetails(obj);
            console.log("show", all_testings);
            setshownDrawerIndex(null);
           }
        else if (shownDrawerIndex) {
            let all_testings = [...orgtableData];
        }
        else {
            setExpandDetails(obj);
            setshownDrawerIndex(index);
        }
    }

    const handlerowsperPage = (e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(searchKeyTable);
        }
        else {
            const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(orgtableData);
        }
    }
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        const data = orgtableData;
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setOffSet(offsetdata);
            setSearchData(searchKeyTable);
        } else {
            const slice = data.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(data.length / perPage))
            setOffSet(offsetdata);
            setTableData(slice);
            setSearchData(data);
        }
    }
    const handleView = (obj) => {
        console.log(obj);
        setcommentsErr("");
        setseedReportErrr("");
        showtestModal(true);
        setTestingDet(obj);
    }
    const handleClose = () => {
        showtestModal(false);
    }
    const handlego_toTesting = async() => {
        showtestModal(false);
        console.log(testing_det);
        console.log(seedReport,reportComments);
        if(reportComments == ''){
            setcommentsErr("please send any comments");
        }
        else  if(seedReport == ''){
            setseedReportErrr("please select testing agency");
        }
        else if(reportComments != ''){
        console.log(testing_det,'report_file',report_file);
        const result = await API_Auth.getRequestTestingAPI('STATUSCHANGEBYTESINGAGENCY','', '', 
             '', '','', '','','Complete', report_file, reportComments, '', '', seedReport, testing_det.id, '');  
               let res = result[0];
                  if (res.Flag == "Success") {
                   setIsLoading(false);
                   toast.success("Request testing successfully");
                 } else {
                   setIsLoading(false);
                   toast.error("Something went wrong please try again");
                 }
                 setInterval(() => {
                   navigate(0);
                 }, 2000);   
        }
    }
    

    const handleInput = (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
if(name== 'seedReport'){
    setseedReport(value);
}
if(name== 'reportComments'){
    setreportComment(value);
}
if (name === "report_file") {
    console.log("value", value);
   // setreport_file(value);
};
    };
    const formatter = new Intl.NumberFormat('en-IN')
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}
                        <Container>
                <Row>
                    <Col lg={4} style={{ paddingLeft: 0 }}>
                   <Title> TEST REQUESTS</Title> 
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
                    <Table className="tablelist" responsive>
                            <thead>
                                <tr className="table_wrap">
                                <th>Season</th>
                                <th>Component</th>
                                <th>Type</th>
                                <th>TestingDate</th> 
                            {/*  <th>Sender Comment</th> */}
                             <th>Status</th>      
                            {/*  <th>Date</th>  */}
                             <th>DAO Name</th>      
                             {/* <th>DAO Comment</th>
                             <th>Testing Comment</th>  */}
                             <th>SeedReport</th>
                           {/*   <th>ReportFile</th> */}
                             <th style={{ textAlign: "center" }}>Action</th> 
                             <th></th>
                             </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {tableData.length != 0 && <tbody>
                                {tableData.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index} className="table_wrap">
                                            <td>{obj.season}-{obj.year}</td>
                                             <td>{obj.seedName}</td>
                                             <td>{obj.verietyName}</td>
                                             <td>{obj.reportDate}</td>
                                            {/*  <td>{obj.senderComment}</td> */}
                                            <td className="pcat_title">{obj.status}</td>
                                           {/*   <td>{obj.insertDate}</td> */}
                                             <td>{obj.daoName}</td>
                                             {/* <td>{obj.daoComment}</td> 
                                             <td>{obj.reportComment}</td>*/}
                                             <td className="pcat_title" style={{color:obj?.seedReport?.toLowerCase()=='pass'?'green':'red'}}>{obj.seedReport}</td>
                                            {/*  <td>{obj.reportFile}</td> */}
                                            
                                             {obj.status == "SendToTest"&&
                                             <td style={{ textAlign: "center" }}>
                                             <div className="row_table_edit " onClick={() => handleView(obj)} 
                                             style={{ marginRight: 10,background: 'antiquewhite',width: 95}}>
                                                        <p>Submit Test</p>
                                                    </div>
                                              </td>
                                                   }
                                                   {obj.status != "SendToTest"&&
                                             <td style={{ textAlign: "center" }}>
                                              </td>
                                                   }
                                                    <td class="pointer" onClick={(e) => showDrawyer(e, index,obj)} >
                                             Expand <ExpandMoreIcon size="24" style={{ marginLeft: 10, cursor: "pointer" }} />
                                                </td>
                                            </tr>
                                            { shownDrawerIndex == index && 
                                            <>
                                            <tr style={{borderColor:'white'}}>
                                            <td colspan="12" style={{height:220}}>
                                            <Row>
                            <Col lg={6}>                                                 
                           <label >Distributor's Comments</label>
                            <br />
                            <div className="input_div" >
                                 <textarea
                                        value={expand_det?.senderComment}
                                        disabled
                                        onChange={handleInput}
                                        style={{ width: "85%",height: 100 }}
                                        name="reportComments" placeholder=""
                                        rows={5}
                                        cols={5}
                                    />
                            </div>
                            </Col>
                            <Col lg={6}>                                                 
                             <label >DAO's Comments</label>
                            <br />
                            <div className="input_div" >
                                 <textarea
                                        value={expand_det?.daoComment}
                                        disabled
                                        onChange={handleInput}
                                        style={{ width: "85%",height: 100 }}
                                        name="reportComments" placeholder=""
                                        rows={5}
                                        cols={5}
                                    />
                            </div>
                            </Col>
                            </Row>
                            </td>
                            </tr>
                            <tr>
                          <td colspan="12" style={{height:220}}> 
                          <Row>
                            {expand_det?.seedReport != '' &&
                            <Col lg={6}>                                                 
                            <label >Test Result:{expand_det?.seedReport}</label>
                            <br/>
                            <label >Test Report - <a href={'https://dev.jharkhandseed.com:3022/jseed/images?filename='+expand_det?.reportFile} download target="_blank" rel="noopener noreferrer">
                            Download Test Report</a></label>
                            </Col> 
                                }
                            <Col lg={6}>                                                 
                             <label >Test Agency Comments</label>
                            <br />
                            <div className="input_div" >
                                 <textarea
                                        value={expand_det?.reportComment}
                                        disabled
                                        onChange={handleInput}
                                        style={{ width: "85%",height: 100 }}
                                        name="reportComments" placeholder=""
                                        rows={5}
                                        cols={5}
                                    />
                            </div>
                            </Col> 
                            </Row>
                            </td>
                            </tr>
                            </>
                                            }
                                        </>
                                    );
                                })}
                            </tbody>
                            }
                        </Table>
                        <div>
                        <div className="pagination_row_div">
                            <p className="pagination_title">Showing items per page:</p>
                            <div className="pagination_input_div" >
                                <select className="pagination_select"
                                 style={{ margin: 0, background: 'transparent' }}
                                    name="pagination_value"
                                    value={perPage}
                                    onChange={handlerowsperPage} >
                                    <option value={4}>4</option>
                                    <option value={8}>8</option>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                </select>
                                </div>
                        </div>
                        <div className="pagination_row_right_div">
                        <p className="pagination_total">Showing {offset+1} to {searchData.length<offset+perPage&&
                        <span>{searchData.length}</span> }
                        {searchData.length>offset+perPage&&
                        <span>{offset+perPage }</span>} of {searchData.length} items</p>
                         <ReactPaginate
                            previousLabel={<ArrowBackIosIcon  className="pagination_prev_btn" color="#2438a7"/>}
                            nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7"/>}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"} 
                            forcePage={currentPage} />
                    </div>
                    </div>
                    </Col>
                </Row>
            </Container>
            <Modal
        show={testModal}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Comments</Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <div className="inputdiv">
                            <label>Select  Report Result</label>
                            <br />
                            <select
                                name="seedReport"
                                value={seedReport}
                                onChange={handleInput}
                                style={{ width: "85%" }}>
                                <option value={''}>Select Report Result</option>
                               <option value={'pass'}>Pass</option>
                               <option value={'fail'}>Fail</option>
                            </select>
                            {seedReportErr != '' && <p className="alert-message">{seedReportErr}</p>}
                        </div>
                        <div className="inputdiv_file" style={{marginTop: 12}}>
                            <label style={{marginLeft: 16}}>Report File</label>
                            <br />

{/*     {report_file != '' &&
        <label className="custom-file-uploaded" >
            <div>
                <p>{report_file} </p>
            </div>
        </label>} */}
        {/* {report_file == '' && *
        <>
        <input id="file-upload" onChange={handleInput} 
          name="report_file" type="file" style={{marginTop: 12,marginLeft: 16}} />
         {report_fileErr != '' && <p className="alert-message">{report_fileErr}</p>}
    </> */}
                <>
        
               <input id="file-upload" style={{marginTop: 12,marginLeft: 16}}
               onChange={() => handleFileupload()} name="report_file" type="file" />
                      {report_fileErr != '' && <p className="alert-message">{report_fileErr}</p>}
        </> 
              {/*   } */}
                        </div>
                            <div>
                            <label >Comments</label>
                            <br />
                            <div className="input_div" >
                                 <textarea
                                        value={reportComments}
                                        onChange={handleInput}
                                        style={{ width: "85%",height: 100 }}
                                        name="reportComments" placeholder="Type Something"
                                        rows={5}
                                        cols={5}
                                    />
                            </div>
                             {commentsErr != '' && <p className="alert-message">{commentsErr}</p>}
                        </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
                        <div className="row_div">
                            <button className="add_button" onClick={() => handlego_toTesting()} style={{
                                padding: 12,
                                height: 45,
                                margin: 10
                            }}>
                                <p className="pcat_title">Submit Testing</p></button>
                        </div>
                    </Modal.Footer>
      </Modal>
            <ToastContainer />
        </Wrapper>
    )
}
export default TestAgencyRequests