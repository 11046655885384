import styled from "styled-components";

export const Wrapper = styled.div`
.tablelist {
  margin-top:15px;
  margin-right:5px;;
}
 
.row_type{
  border:1px solid #6EDC8F;
  margin:15px 1px;
  height:40px;
  border-radius:6px;
  opacity:1
}
.button_asset_type {
  background:#6EDC8F;
  color:black;
  border-radius:6px;
  margin:-1px;
  padding:10px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
  font-weight:400;
  font-size:14px;
  cursor:pointer;

  }
  .button_asset_type_fixed {
  
    border-radius:6px;
    margin:-1px;
    padding:10px;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    font-weight:500;
    font-size:14px;
    cursor:pointer;

    }

thead{
  color:black;
  background:#c0d3ef;
  border:1px solid #c0d3ef;
  border-radius:10px;
}
td{
  color:black;
  font-weight:400;
  font-size:14px;
  height:30px;
  padding:0px 15px;
  vertical-align: middle;
  font-family:Roboto;
  text-transform: capitalize;
}
th{
  font-size:16px;
  height:35px;
  padding:0px 14px;
  vertical-align: middle;
  font-weight:500;
  font-family:Roboto;

}
.table-head-content {
  padding: 13px 13px 0 13px;
  background: white;
  border-bottom: 1px solid #E2E2E2;   
}  
.row_div {
  display:flex;
  flex-direction:row;
  padding: 8px;
}
.input_div {
  width: 100%;
  color:var(--black);
  font-family: 'ARIAL';
  height:45px;
  border-radius:6px;
  margin:5px;
  background:white;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  :focus {
    border: 0px solid #DFDFDF !important; 
  }
  input,select{
         border: 2px solid #DFDFDF;
     :focus {
       outline: none !important;
       border-color:none;  
     }
    }
   
}
.title{
  font-family:Roboto;
  font-size:16px;
  font-weight:600;
  margin-left:10px;
  margin-bottom:0 !important;
  margin-top:15px;

}

.adddiv {
  background:white;
  height:95%;
  width:80%;
  margin-top:15px;
  margin-left:20px;
  border-radius:5px;
  
  input,select{
    width:85%;
    height: 40px;
    border: 1px solid #00001F24;
    border-radius: 6px;
    margin: 15px ;
    padding: 10px; 
    :focus {
      outline: none !important;
      border-color:none;  
    }
   }
  
}
.add_cat_button {
  background:#000044;
  color:white;
  border:#4EACFB;
  padding:10px;
  height:40px;
  width:85%;
  margin:100px 25px 0px;
  font-weight:bold;
  border-radius:6px;
}


`