import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Container, Row, Col } from "react-bootstrap";
import { Wrapper } from "./styles";
import { LoadingSpinner } from "../LoadingSpinner/index";
import { Title } from "../../GlobalStyle";
import { useNavigate, useLocation } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import Delete from "../../images/delete.png";
import OtpTimer from "otp-timer";
import Table from "react-bootstrap/Table";
import moment from "moment";
import Modal from "react-bootstrap/Modal";

const FarmerCheckLogin = () => {
  const { isExpanded } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [disableSubmit, setDisableSubmit] = useState(false);

  const [adharErr, setAdharErr] = useState("");
  const [adhar, setAdhar] = useState("");
  const [finalErr, setFinalErr] = useState("");
  const [farmerData, setFarmerData] = useState("");
  const [show, setShow] = useState(false);
  const [seedHistorData, setseedHistorData] = useState("");
  const [distributionShow, setShowDistrivtion] = useState(false);
  const [adharAPi, setAdharAPI] = useState("");
  const [mobile, setmobile] = useState("");
  const [mobileErr, setmobileErr] = useState("");
  const location = useLocation();
  const [finalmobieErr, setfinalmobieErr] = useState(false);
  const[tableData,setTableData]=useState([]);
  const[feedbackModal,setFeedBackModal]=useState(false)
  const [feedbackErr, setfeedbackErr] = useState("");
  const [feedback, setfeedback] = useState("");
  const [feedback_det, setFeedBackDet] = useState();
  
  const handleInput = e => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;
    
    if (name === "feedback") {setfeedback(value)}
    if (name === "adhar") {
      console.log(value, value.length);
      if (value == "") {
        setAdhar("");
      } else {
        let cc = value.split("-").join("");

        cc = cc.match(new RegExp(".{1,4}$|.{1,4}", "g")).join("-");
        console.log(cc);
        setAdhar(cc);
      }
    }
    if (name === "mobile") {
      console.log(value, value.length);
        setmobile(value);
    }
  };
  const handleShowPurchasehistory =async () => {
    let error = false;
    if (mobile == "") {
      error = true;
      setmobileErr("Please Enter Mobile No");
    }
    else if (mobile.length<10) {
      error = true;
      setmobileErr("Please Enter Valid Mobile No");
    } 
    else {
      error = false;
      setmobileErr("");
    }
    if (!error) {
      setIsLoading(true);
      //setDisableSubmit(true);
      setfinalmobieErr(" ");
     let result = await API_Auth.getFarmerDetailWithCropSeason(adharAPi,mobile,'DISTRIBUTIONSEEDHISTORYAADHARMOBILENO');
      console.log(result);
      setIsLoading(false);
       if (result.seedDistribution.length != 0) {
        setseedHistorData(result.seedDistribution);
        setShowDistrivtion(true);
      } else {
        setShowDistrivtion(false);
        setfinalmobieErr("Mobile number incorrect");
      } 
    }
  };

  const handleVerifyAdhar = async () => {
    let error = false;
    let a = adhar.split("-").join("");
    console.log(a);
    setAdharAPI(a);
    if (adhar == "") {
      error = true;
      setAdharErr("Please Enter Aadhaar No");
    }
    else if (adhar.length<14) {
      error = true;
      setAdharErr("Please Enter Valid Aadhaar No");
      setFinalErr(" ");
    } 
    else {
      error = false;
      setAdharErr("");
    }
    if (!error) {
      setIsLoading(true);
      //setDisableSubmit(true);
      setFinalErr(" ");
      let result = await API_Auth.getFarmerDetail(a,"GETFARMERFRONTPAGE");
      console.log(result);
      setIsLoading(false);
      if (result.data.length != 0) {
        setFarmerData(result.data[0]);
        setTableData(result.data);
        const list = [...result.data];
           console.log(list);
           setShow(true);
      } else {
        setShow(false);
        setFinalErr("Farmer Not exists");
      }
    }
  };
  const handleupdateFeedback = async (obj) => {
    setFeedBackModal(true);
    setFeedBackDet(obj);
  };
  const handleaddFeedback = async () => {
    console.log(feedback_det.id,adharAPi,feedback);
   setIsLoading(true);
   let result = await API_Auth.getFarmerDetailWithCropSeason('',mobile,
    'UPDATEFEEDBACKBYFARMER',feedback_det.id,feedback,adharAPi);
   console.log(result);
    setIsLoading(false);
    setFeedBackModal(false);

/*     if (result.data.length != 0) {
      setFeedBackModal(false);
    } else {

    }  */
  };
  const handleClose = () => {
    setFeedBackModal(false);
  };
  return (
    <Wrapper>
    {isLoading && <LoadingSpinner />}
    <Container>
        <Row>
          <Col lg={4}></Col>
          <Col lg={4} style={{textAlign:'center'}}>
            <div className="inputdiv" style={{marginLeft:0}}>
              <label style={{marginLeft:-10}}> Enter Aadhaar No</label>
              <br />
              <input
                name="adhar"
                data-type="adhaar-number"
                value={adhar}
                maxLength={14}
                placeholder="AAAA-BBBB-CCCC"
                id="adharnumber"
                onChange={handleInput}
                style={{ width: "50%" ,marginLeft:0}}
              ></input>
              {adharErr != "" && <p className="alert-message" >{adharErr}</p>}
            </div>
          </Col>
        </Row>
        {finalErr != "" &&
        <Row>
          <Col lg={4}></Col>
          <Col lg={4}style={{textAlign:'center'}}>
            {finalErr != "" && <p className="alert-message" style={{marginLeft:-24}}>{finalErr}</p>}
          </Col>
        </Row>
           }
        <Row>
          <Col lg={5}></Col>
          <Col lg={2}style={{paddingLeft:0,marginLeft:-8}}>
            <div>
            <button
              className="add_button"
              disabled={disableSubmit}
              onClick={() => handleVerifyAdhar()}
              style={{ marginLeft: 0, width: "100%", height: 40 }}
            >
              <p style={{ marginTop: -8, fontWeight: "500", fontSize: 16 }}>
                {" "}
                Check Registration
              </p>
            </button>
            </div>
          </Col>
        </Row>
        {show == true && (
          <>
            <Row>
              <Col lg={12} sm={6} md={6} className="table_head">
                <Table className="tablelist" responsive>
                  <thead>
                    <tr>
                      <th> Name</th>
                      <th> Father Name</th>
                      <th> Aadhaar No</th>
                      <th> Gender</th>
                      <th> KYC</th>
                      <th> Category</th>
                      <th> District</th>
                      <th> Village</th>
                    </tr>
                  </thead>
                  {tableData.length == 0 && (
                    <tbody>
                      <tr>
                        <td colSpan="12">
                          <p className="no_Data_table">No Data Found</p>
                        </td>
                      </tr>
                    </tbody>
                  )}
                  {tableData.length != 0 && (
                    <tbody>
                      {tableData.map((obj, index) => {
                        return (
                          <>
                            <tr key={index}>
                              {/*<td>{obj.id}</td> */}
                              <td>{obj.name}</td>
                              <td>{obj.father_name}</td>
                              <td>{obj.aadhar_no}</td>
                              <td>{obj.Gender}</td>
                       <td>{obj.KYC}</td>
                       <td>{obj.CastCetegory}</td>
                       <td>{obj.district}</td>
                       <td>{obj.village}</td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  )}
                </Table>
              </Col>
            </Row>
         <Row>
          <Col lg={4}></Col>
          <Col lg={4} style={{textAlign:'center'}}>
            <div className="inputdiv" style={{marginLeft:0}}>
              <label style={{marginLeft:-10}}> Enter Mobile No</label>
              <br />
              <input
                name="mobile"
                value={mobile}
                maxLength={10}
                placeholder="Enter mobile no"
                onChange={handleInput}
                style={{ width: "50%", marginLeft:0}}
              ></input>
              {mobileErr != "" && <p className="alert-message">{mobileErr}</p>}
            </div>
          </Col>
        </Row>
      
        {finalmobieErr != "" &&
          <Row>
          <Col lg={4}></Col>
          <Col lg={4}style={{textAlign:'center'}}>
            {finalmobieErr != "" && <p className="alert-message"style={{marginLeft:-24}}>{finalmobieErr}</p>}
          </Col>
        </Row>
          }
        <Row>
          <Col lg={5}></Col>
          <Col lg={3} style={{paddingLeft:0,marginLeft:-8}}>
            <button
              className="add_button"
              disabled={disableSubmit}
              onClick={() => handleShowPurchasehistory()}
              style={{ marginLeft: 0, width: "67%", height: 40 }}
            >
              <p style={{ marginTop: -8, fontWeight: "500", fontSize: 16 }}>
                {" "}
                Show Seed Receipts
              </p>
            </button>
          </Col>
        </Row>
        </>
        )}
                {distributionShow == true && (
                  <>
                   <Row>
              <Col lg={12} sm={6} md={6} className="table_head">
                <Table className="tablelist" responsive>
                  <thead>
                    <tr>
                    <th> Distributor Name</th>
                      <th> Distributed Quantity</th>
                      <th>Distribution Date</th>
                      <th>Feedback/Complaint</th>
                    </tr>
                  </thead>
                  {seedHistorData.length == 0 && (
                    <tbody>
                      <tr>
                        <td colSpan="12">
                          <p className="no_Data_table">No Data Found</p>
                        </td>
                      </tr>
                    </tbody>
                  )}
                  {seedHistorData.length != 0 && (
                    <tbody>
                      {seedHistorData.map((obj, index) => {
                        return (
                          <>
                            <tr key={index}>
                       <td>{obj.distributorName}</td>
                              <td>{obj.distributedQuantity}</td>
                              <td>{moment.utc(obj.distributionDate).format("DD MMM YYYY")}</td>
                              <td><button
              className="add_button"
              disabled={disableSubmit}
              onClick={() => handleupdateFeedback(obj)}
              style={{ marginLeft: 0, width: "80%", height: 40 }}
            >
              <p style={{ marginTop: -8, fontWeight: "500", fontSize: 16 }}>
               Send Feedback
              </p>
            </button></td>

                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  )}
                </Table>
              </Col>
            </Row>
                  </>
                )}
          <Modal
        show={feedbackModal}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Feedback</Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <div>
                            <label >Feedback/Complaint</label>
                            <br />
                            <div className="input_div" >
                                {/* <input placeholder='Enter Feedback'
                                    value={feedback}
                                    name='feedback'
                                    onChange={handleInput}
                                    style={{ width: "85%" }}
                                /> */}
                                 <textarea
                                        value={feedback}
                                        onChange={handleInput}
                                        style={{ width: "85%",height: 100 }}
                                        name="feedback" placeholder="Type Something"
                                        rows={5}
                                        cols={5}
                                    />
                            </div>
                            {feedbackErr != '' && <p className="alert-message">{feedbackErr}</p>}
                        </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
                        <div className="row_div">
                            <button className="add_button" onClick={() => handleaddFeedback()} style={{
                                padding: 12,
                                height: 45,
                                margin: 10
                            }}>
                                <p className="pcat_title">Submit Feedback</p></button>
                        </div>
                    </Modal.Footer>
      </Modal>
      </Container>
      <ToastContainer />
    </Wrapper>
  );
};
export default FarmerCheckLogin;
