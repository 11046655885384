
import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "../Variety/Variety.styles";
import { Row, Container, Col } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import API_Auth from "../../API_Auth";
import { LoadingSpinner } from "../LoadingSpinner/index";
import { AiOutlineSearch } from "react-icons/ai";
import { Title } from "../../GlobalStyle";
import Edit from "../../images/edit.png";
import Delete from "../../images/delete.png"
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { specialChars } from "@testing-library/user-event";


const Season = () => {
    const {
        isExpanded,
    } = useContext(UserContext);

    const [isLoading, setIsLoading] = useState(false)
    const [season, setSeason] = useState([]);

    const [formTitle, setFormTitle] = useState('ADD SEASON');
    const [formButtonValue, setFormButtonValue] = useState('ADD');
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey, setSearchKey] = useState('n');
    const [searchKeyTable, setSearchKeyTable] = useState('');

    const [addSeason, setaddSeason] = useState(false);
    const [cropName, setCropName] = useState('');
    const [cropNameErr, setcropNameErr] = useState('')

    const [seedTypes, setSeedTypes] = useState([]);
    const [seedType, setSeedType] = useState('');
    const [seedTypeErr, setSeedTypeErr] = useState('');
    const [seasonErr, setSeasonErr] = useState('');
    const [seedId, setSeedId] = useState('');
    const [year,setYear]=useState();
    const[yearErr,setYearErr]=useState('');
    const[seasonName,setSeasonName]=useState('');
    const[seasonNameErr,setSeasonNameErr]=useState('');



    useEffect(() => {
        setIsLoading(true);
        const fetchSeasons = async () => {

            const result = await API_Auth.getSeasons();
            console.log("Result",result)
           

            setSeason(result);
            setIsLoading(false);
            var slice = result.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result.length / perPage));
            setOrgTableData(result);
            setTableData(slice);
            setSearchData(result);
        };
        fetchSeasons()
            // make sure to catch any error
            .catch(console.error);
    }, []);

    const handleSearch = (e) => {
        let filterValue = e.target.value;
        setCurrentPage(0);
        if (filterValue == '') {
            let offsetsearch = 0;
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setCurrentPage(0);
            setOffSet(offsetsearch);
            setTableData(slice);
            setSearchData(orgtableData);
            setSearchKey('n');
        }
        else {
            console.log("filterValue", filterValue)
            let offsetsearch = 0;
           /*  const filteredData = orgtableData.filter(item => {
                return item ?.CropName ?.toLowerCase().includes(filterValue.toLowerCase())
            }); */
            const filteredData = orgtableData.filter(item => {
                return (item ?.Year ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                    item ?.Season ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                    item ?.CombYearSeason ?.toLowerCase().includes(filterValue.toLowerCase()))
            });
            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            console.log("slice", slice)
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            setOffSet(offsetsearch);
            setSearchKeyTable(filteredData);
            setTableData(slice);
            setSearchData(filteredData);
            // setTableData(filteredData);
        }
    }
    const handleAddSeason = () => {
        setaddSeason(true)
        setSeasonName('');
        setYear('');
        setYearErr('');
        setSeasonNameErr('');
        setFormButtonValue('ADD');

    }
  
    const handleClose = () => {
        setaddSeason(false);
        setYearErr('');
        setSeasonNameErr('');
    }

    const handleInput = (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        if (name === "year") setYear(value);
        if (name == "seasonName") setSeasonName(value);
    }

    const handleDeleteSeason = (obj) => {
        console.log(obj)
        if (window.confirm('Are you sure you want to delete the Season ?')) {
            // Save it!
            const fetchDeleteSeason= async () => {
                setIsLoading(true);

                let result = await API_Auth.deleteSeason(obj.id);
                let res = result[0];
                if (res.Flag == 'Success') {
                    setIsLoading(false);

                    toast.success('Season Deleted successfully');
                    const fetchAsset = async () => {
                        let result = await API_Auth.getSeasons();

                        setSeason(result);
                        setIsLoading(false);
                        var slice = result.slice(offset, offset + perPage);
                        setPageCount(Math.ceil(result.length / perPage));
                        setOrgTableData(result);
                        setTableData(slice);
                        setSearchData(result);
                    };
                    // call the function
                    fetchAsset()
                        // make sure to catch any error
                        .catch(console.error);

                } else {
                    toast.error('Something went wrong please try again');
                }

            };
            // call the function
            fetchDeleteSeason()
                // make sure to catch any error
                .catch(console.error);
        } else {
            // Do nothing!
        }


    }

    const handlerowsperPage = (e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value));
            setTableData(slice);
            setOffSet(0);
            setSearchData(searchKeyTable);
        }
        else {
            const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value));
            setTableData(slice);
            setOffSet(0);
            setSearchData(orgtableData);
        }
    }
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        const data = orgtableData;
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setOffSet(offsetdata);
            setSearchData(searchKeyTable);
        } else {
            const slice = data.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(data.length / perPage));
            setTableData(slice);
            setOffSet(offsetdata);
            setSearchData(data);
        }

    }

    const handleSeasonAdd = async () => {
        setDisableSubmit(true);
        let error = false;
        let specialchar = '-';
        if (year == '') {
            setYearErr('this field is required.');
            error = true;
            setDisableSubmit(false);
        }
        else {
            setYearErr('');
        }
        if (seasonName == '') {
            setSeasonNameErr('this field is required.');
            error = true;
            setDisableSubmit(false);
        }
        else if(seasonName.includes(specialchar)){
            setSeasonNameErr('Replace "-" sign with "~"');
            error = true;
            setDisableSubmit(false);
        }
        else {
            setSeasonNameErr('');
        }
       
        if (!error) {
            setIsLoading(true);

            if (formButtonValue == 'ADD') {
                let result = await API_Auth.addSeason(year,seasonName);
                let res = result[0];
                console.log(res,result?.code);
                if (res?.Flag == 'Success') {
                    setaddSeason(false);
                    setIsLoading(false);

                    toast.success('Season added successfully');
                    setInterval(() => {
                        window.location.reload();
                    }, 2000);
                } 
                else if(result?.code == "ER_DUP_ENTRY"){
                    setIsLoading(false);
                    toast.error('Duplicate Entry');
                    setInterval(() => {
                        setDisableSubmit(false);
                    }, 6000);
                }
                else {
                    setIsLoading(false);
                    setDisableSubmit(false);
                    toast.error('Something went wrong please try again');
                }
            } else if (formButtonValue == 'UPDATE') {

                /* let result = await API_Auth.updateSeed(cropName, seedType, season, seedId);
                let res = result[0];
                if (res.Flag == 'Success') {
                    setIsLoading(false);

                    setaddSeason(false);
                    toast.success('Seed updated successfully');
                    setInterval(() => {
                        window.location.reload();
                    }, 2000);

                } else {
                    setIsLoading(false);
                    setDisableSubmit(false);
                    toast.error('Something went wrong please try again');
                } */
            }
        } else {
            setDisableSubmit(false);
        }
    }
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}
            <Container style={{ marginTop: 42 }}>
                <Row>
                    <Col lg={4} style={{ paddingLeft: 0 }}>
                        <Title>Seasons</Title>
                    </Col>
                    <Col lg={5} style={{ paddingRight: 0 }}>
                        <div className="row_div">
                            <div className="input_div" >
                                <input type="type" style={{ margin: 0, background: 'transparent' }}
                                    name="filterValue"
                                    onChange={(e) => handleSearch(e)}
                                    placeholder="Search" />
                                <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                            </div>

                        </div>
                    </Col>
                    <Col style={{ paddingRight: 0 }}>
                        <button className="add_button" onClick={() => handleAddSeason()}>
                            <p className="pcat_title"> {formTitle}</p></button>
                    </Col>

                </Row>
                <Row>

                    <Col lg={12} sm={6} md={6} className="table_head">
                        <Table className="tablelist" responsive>
                            <thead>
                                <tr>
                                    <th>Season</th>
                                    <th>Year</th>

                                    <th>Comb Year Season</th>

                                    <th style={{ textAlign: 'right' }}>Action</th>
                                </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr>
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {tableData.length != 0 && <tbody>
                                {tableData.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                                <td>{obj.Season}</td>
                                                <td>{obj.Year}</td>
                                                <td>{obj.CombYearSeason}</td>


                                                <td style={{ textAlign: 'right' }}>
                                                   {/*  <div className="row_table_edit " onClick={() => handleEditSeed(obj)}>
                                                        <img src={Edit} />
                                                        <p>EDIT</p>
                                                    </div> */}
                                                    <div className="row_table_edit " onClick={() => handleDeleteSeason(obj)} style={{ marginRight: 10 }}>
                                                        <img src={Delete} />
                                                        <p>DELETE</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                            }

                        </Table>
                        <div>
                            <div className="pagination_row_div">
                                <p className="pagination_title">Showing items per page:</p>
                                <div className="pagination_input_div" >
                                    <select className="pagination_select"
                                        style={{ margin: 0, background: 'transparent' }}
                                        name="pagination_value"
                                        value={perPage}
                                        onChange={handlerowsperPage} >
                                        <option value={4}>4</option>
                                        <option value={8}>8</option>
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                    </select>
                                </div>
                            </div>
                            <div className="pagination_row_right_div">
                                <p className="pagination_total">Showing {offset + 1} to {searchData.length < offset + perPage &&
                                    <span>{searchData.length}</span>}
                                    {searchData.length > offset + perPage &&
                                        <span>{offset + perPage}</span>} of {searchData.length} items</p>
                                <ReactPaginate
                                    previousLabel={<ArrowBackIosIcon className="pagination_prev_btn" color="#2438a7" />}
                                    nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7" />}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                    forcePage={currentPage} />
                            </div>
                        </div>

                    </Col>
                </Row>
                <Modal show={addSeason} onHide={handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    dialogclassname="modal-content">
                    <Modal.Header closeButton>
                        <Modal.Title> Season</Modal.Title>
                    </Modal.Header>

                    <Modal.Body >
                        <div>
                            <label >Year</label>
                            <br />
                            <div className="input_div" >
                            <select value={year} name="year"
                                    onChange={handleInput}
                                    style={{ width: "85%" }}
                                >
                                    <option value=''>Select Year</option>
                                    <option value='2022'>2022</option>
                                    <option value='2023'>2023</option>
                                    <option value='2024'>2024</option>



</select>
                              
                            </div>
                            {yearErr != '' && <p className="alert-message">{yearErr}</p>}
                        </div>
                        <div>
                            <label >Season Name</label>
                            <br />
                            <div className="input_div" >
                                <input value={seasonName} name="seasonName"
                                    onChange={handleInput}
                                    style={{ width: "85%" }}
                                >

                                </input>
                                {seasonNameErr != '' && <p className="alert-message">{seasonNameErr}</p>}


                            </div>
                          
                        </div>

                    </Modal.Body>
                    <Modal.Footer className="modal-footer">
                        <div className="row_div">
                            <button className="add_button" onClick={() => handleSeasonAdd()} style={{
                                padding: 12,
                                height: 45,
                                margin: 10
                            }}>
                                {/*                                 <img src={plus} style={{ margin: 5 }} /> */}
                                <p className="pcat_title">{formButtonValue}</p></button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </Container>
            <ToastContainer />

        </Wrapper>
    )
}
export default Season