import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Container, Row, Col } from "react-bootstrap";
import { Wrapper } from "./styles";
import { LoadingSpinner } from "../LoadingSpinner/index";
import { useNavigate, useLocation } from "react-router-dom";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import { base_url } from "../../config";

const FarmerRegistration = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [disableSubmit, setDisableSubmit] = useState(false);

  const [mobile, setmobile] = useState("");
  const [mobileErr, setmobileErr] = useState("");
  const [finalmobieErr, setfinalmobieErr] = useState(false);

  const [showAdharForm, setShowAdharForm] = useState(true);
  const [showMobile, setShowMobile] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpErr, setOtpErr] = useState("");
  const [addressForm, setAddressForm] = useState(false);

  const [FfirstName, setFfirstName] = useState("");
  const [FfirstNameErr, setFfirstNameErr] = useState("");
  const [FLastName, setFLastName] = useState("");
  const [FLastNameErr, setFLastNameErr] = useState("");
  const [FFatherName, setFFatherName] = useState("");
  const [FFatherNameErr, setFFatherNameErr] = useState();
  const [FGender, setFGender] = useState("");
  const [FGenderErr, setFGenderErr] = useState("");
  const [FCaste, setFCaste] = useState("");
  const [FCasteErr, setFCasteErr] = useState("");

  const [farmerCNo, setfarmerCNo] = useState("");
  const [farmerCNoErr, setfarmerCNoErr] = useState("");
  const [farmerAadharNo, setfarmerAadharNo] = useState("");
  const [farmerAadharNoErr, setfarmerAadharNoErr] = useState("");
  const [FVillage, setFVillage] = useState("");
  const [FVillageErr, setFVillageErr] = useState("");
  const [FPanchayat, setFPanchayat] = useState("");
  const [FPanchayatErr, setFPanchayatErr] = useState("");

  const [FBlock, setFBlock] = useState("");
  const [FBlockErr, setFBlockErr] = useState("");
  const [FArea, setFArea] = useState("");
  const [FAreaErr, setFAreaErr] = useState("");
  const [castList, setCastList] = useState([]);

  const [FDistrict, setFDistrict] = useState("");
  const [FDistrictErr, setFDistrictErr] = useState("");
  const [districts, setDistricts] = useState([]);
  const [FarmerMsg, setFarmerMsg] = useState("");
  const [FarmerBlocks, setFarmerBlocks] = useState([]);

  const [showFarmerApplication, setShowFarmerApplication] = useState(false);

  useEffect(() => {
    const user_role = localStorage.removeItem("loggedRole");
    console.log("user_role",user_role)
    const fetchData = async () => {
      const districts = await API_Auth.getDistricts();
      setDistricts(districts);
      let castList = await API_Auth.addFarmerDetail("GETCAST");
      console.log(castList.errordata);
      setCastList(castList.errordata);

     
    };
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  const handleVerifyAdharNumber = async () => {
    if (farmerAadharNo === "") {
      setfarmerAadharNoErr("Please Enter Aadhaar Number");
    } else if (farmerAadharNo.length < 12) {
      setfarmerAadharNoErr("Please Enter Valid Aadhaar No");
    } else {
      setIsLoading(true);
      setDisableSubmit(true);
      let result = await API_Auth.getCheckAdharNumber(farmerAadharNo);

      setIsLoading(false);
      setDisableSubmit(false)


      if (result[0].errorMessage === "failure") {
        setfarmerAadharNoErr(result[0].message);

        localStorage.setItem("FarmerKey", "yes");
        navigate("/list-farmer-applications", {
          state: { adharNumber: farmerAadharNo },
        });
      } else {
        setShowMobile(true);
        setShowAdharForm(false);
        setfarmerAadharNoErr("");
        setShowFarmerApplication(false);
      }
    }
  };

  const handleInput = async (e) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;

    if (name === "mobile") {
      setmobile(value);
    }
    if (name === "otp") {
      setOtp(value);
    }
    if (name == "FfirstName") {
      setFfirstName(value);
    }
    if (name == "FLastName") {
      setFLastName(value);
    }
    if (name == "FFatherName") {
      setFFatherName(value);
    }
    if (name == "FGender") {
      setFGender(value);
    }
    if (name == "FCaste") {
      setFCaste(value);
    }

    if (name == "farmerCNo") {
      setfarmerCNo(value);
    }
    if (name == "farmerAadharNo") {
      setfarmerAadharNo(value);
    }
    if (name == "FDistrict") {
      console.log(value);

      const result = await API_Auth.getBlockByDistrictId(value);
      console.log("result", result);
      setFarmerBlocks(result);
      setFDistrict(value);
      setFBlock("");
    }

    if (name == "FVillage") {
      setFVillage(value);
    }
    if (name == "FPanchayat") {
      setFPanchayat(value);
    }
    if (name == "FBlock") {
      setFBlock(value);
    }
    if (name == "FArea") {
      setFArea(value);
    }

    
  };

 
  const handleVerifyMobileNumber = async () => {
    let error = false;
    if (mobile === "") {
      error = true;
      setmobileErr("Please Enter Mobile No");
    } else if (mobile.length < 10) {
      error = true;
      setmobileErr("Please Enter Valid Mobile No");
    } else {
      error = false;
      setmobileErr("");
    }
    if (!error) {
      setIsLoading(true);
      setDisableSubmit(true);
      setfinalmobieErr(" ");
      let result = await API_Auth.getFarmerRegisterOTP(mobile);
      console.log(result);
      setIsLoading(false);
      setShowMobile(false);
      setShowOtp(true);
      setDisableSubmit(false);


      if (result.type === "success") {
        setShowMobile(false);
        setShowOtp(true);
      } else {
        setmobileErr("Something Went Wrong");
      }
    }
  };
  const handleVerifyOtp = async () => {
    let error = false;
    if (otp == "") {
      error = true;
      setOtpErr("Please Enter OTP");
    } else if (mobile.length < 5) {
      error = true;
      setOtpErr("Please Enter Valid OTP");
    } else {
      error = false;
      setOtpErr("");
    }
    if (!error) {
      setIsLoading(true);
      setDisableSubmit(true)
      //setDisableSubmit(true);
      setfinalmobieErr(" ");
      let result = await API_Auth.getFarmerRegisterVerifyOTP(mobile, otp);
      console.log(result);
      setIsLoading(false);
      setDisableSubmit(false)

      if (result.type == "error") {
        setOtpErr("Please Enter Valid OTP");
      } else {
        setShowOtp(false);
        setAddressForm(true);
        setShowMobile(false);
      }
    }
  };

  const handleFarmerRegister = async () => {
    if (FfirstName === "") {
      setFfirstNameErr("Please Enter First Name");
    } else if (FLastName === "") {
      setFLastNameErr("Please Enter Last Name");
      setFfirstNameErr("");
    } else if (FFatherName === "") {
      setFFatherNameErr("Please Enter Father Name");
      setFLastNameErr("");
    } else if (FGender === "") {
      setFGenderErr("Please Select Gender");
      setFFatherNameErr("");
    } else if (FCaste === "") {
      setFCasteErr("Please Select Caste");
      setFGenderErr("");
      setFFatherNameErr("");
    } else if (farmerAadharNo === "") {
      setfarmerAadharNoErr("Please Enter Aadhaar Number");
      setFCasteErr("");
    } else if (farmerAadharNo.length < 12) {
      setfarmerAadharNoErr("Please Enter Valid Aadhaar No");
      setFCasteErr("");
    } else if (FDistrict === "") {
      setFDistrictErr("Please Select District");
      setfarmerAadharNoErr("");
      setFCasteErr("");
      setFFatherNameErr("");
    } else if (FBlock === "") {
      setFBlockErr("Please Select Block");
      setFDistrictErr("");
    } else if (FPanchayat === "") {
      setFPanchayatErr("Please Enter Panchayat");
      setFBlockErr("");
    } else if (FVillage === "") {
      setFVillageErr("Please Enter Village");
      setFPanchayatErr("");
    } else if (FArea === "") {
      setFAreaErr("Please Enter Area");
      setFVillageErr("");
    } else {
      setIsLoading(true);
      let Farmerid = "";
      //setDisableSubmit(true);
      let result = await API_Auth.getFarmerNewRegistration(
        FfirstName,
        FLastName,
        FFatherName,
        FGender,
        FCaste,
        mobile,
        farmerAadharNo,
        FVillage,
        FPanchayat,
        FBlock,
        FArea,
        FDistrict,
        Farmerid
      );

      console.log(result);
      setIsLoading(false);

      if (result[0].errorMessage === "failure") {

        setFarmerMsg(result[0].message);
      } else {
        toast.success("Farmer  successfully Registered");
        setShowAdharForm(true);
        setAddressForm(false);
        setmobile("");
        setFfirstName("");
        setFLastName("");
        setFFatherName("");
        setFGender("");
        setFCaste("");
        setfarmerAadharNo("");
        setFVillage("");
        setFPanchayat("");
        setFBlock("");
        setFArea("");
        setFDistrict("");
        setOtp("");
        setFarmerMsg("");
        setFfirstNameErr();
        setFLastNameErr("");
        setFFatherNameErr("");
        setFGenderErr();
        setFCasteErr("");
        setFDistrictErr("");
        setfarmerAadharNoErr("");
        setFBlockErr("");
        setFPanchayatErr("");
        setFVillageErr("");
        setFAreaErr("");
      }
    }
  };
  return (
    <Wrapper>
      {isLoading && <LoadingSpinner />}

      <Container>
        <div
          className="inputdiv"
          style={{
            marginLeft: 0,
            textAlign: "center",
            marginTop: 20,
            fontSize: 24,
          }}
        >
          <label style={{ marginTop: 30, marginBottom: 20 }}>
            {showFarmerApplication === false
              ? "Farmer Registration"
              : "Farmer Application"}
          </label>
        </div>

       
        {showAdharForm == true && (
          <>
            <Row>
              <Col lg={4}></Col>
              <Col lg={6}>
                <div className="inputdiv">
                  <label>Aadhaar Number</label>
                  <br />
                  <input
                    name="farmerAadharNo"
                    // data-type="adhaar-number"
                    value={farmerAadharNo}
                    maxLength={12}
                    placeholder="Aadhaar Number"
                    id="farmerAadharNo"
                    onChange={handleInput}
                    style={{ width: "60%" }}
                  ></input>
                  {farmerAadharNoErr != "" && (
                    <p className="alert-message">{farmerAadharNoErr}</p>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={5}></Col>
              <Col lg={2} style={{ paddingLeft: 0, marginLeft: -8 }}>
                <div>
                  <button
                    className="add_button"
                    disabled={disableSubmit}
                    onClick={() => handleVerifyAdharNumber()}
                    style={{ marginLeft: 0, width: "100%", height: 40 }}
                  >
                    <p
                      style={{ marginTop: -8, fontWeight: "500", fontSize: 16 }}
                    >
                      {" "}
                      Submit
                    </p>
                  </button>
                </div>
              </Col>
            </Row>
          </>
        )}
        {showMobile === true && (
          <>
            <Row>
              <Col lg={4}></Col>
              <Col lg={6}>
                <div className="inputdiv">
                  <label>Mobile Number</label>
                  <br />
                  <input
                    name="mobile"
                    // data-type="adhaar-number"
                    value={mobile}
                    maxLength={10}
                    placeholder="Mobile Number"
                    id="mobileNumber"
                    onChange={handleInput}
                    style={{ width: "50%" }}
                  ></input>
                  {mobileErr != "" && (
                    <p className="alert-message">{mobileErr}</p>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={5}></Col>
              <Col lg={2} style={{ paddingLeft: 0, marginLeft: -8 }}>
                <div>
                  <button
                    className="add_button"
                    disabled={disableSubmit}
                    onClick={() => handleVerifyMobileNumber()}
                    style={{ marginLeft: 0, width: "100%", height: 40 }}
                  >
                    <p
                      style={{ marginTop: -8, fontWeight: "500", fontSize: 16 }}
                    >
                      {" "}
                      Submit
                    </p>
                  </button>
                </div>
              </Col>
            </Row>
          </>
        )}
        {showOtp === true && (
          <>
            <Row>
              <Col lg={4}></Col>
              <Col lg={6}>
                <div className="inputdiv">
                  <label>Enter OTP</label>
                  <br />
                  <input
                    name="otp"
                    // data-type="adhaar-number"
                    value={otp}
                    maxLength={4}
                    placeholder="OTP"
                    id="otp"
                    onChange={handleInput}
                    style={{ width: "50%" }}
                  ></input>
                  {otpErr != "" && <p className="alert-message">{otpErr}</p>}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={5}></Col>
              <Col lg={2} style={{ paddingLeft: 0, marginLeft: -8 }}>
                <div>
                  <button
                    className="add_button"
                    disabled={disableSubmit}
                    onClick={() => handleVerifyOtp()}
                    style={{ marginLeft: 0, width: "100%", height: 40 }}
                  >
                    <p
                      style={{ marginTop: -8, fontWeight: "500", fontSize: 16 }}
                    >
                      {" "}
                      Submit
                    </p>
                  </button>
                </div>
              </Col>
            </Row>
          </>
        )}

        {addressForm === true && (
          <>
            <Row>
              <Col lg={2}></Col>
              <Col lg={9}>
                <Row>
                  <Col>
                    <div className="inputdiv">
                      <label>First Name</label>
                      <br />
                      <input
                        name="FfirstName"
                        // data-type="adhaar-number"
                        value={FfirstName}
                        placeholder="First Name"
                        id="FfirstName"
                        onChange={handleInput}
                        style={{ width: "80%" }}
                      ></input>
                      {FfirstNameErr != "" && (
                        <p className="alert-message">{FfirstNameErr}</p>
                      )}
                    </div>
                  </Col>
                  <Col>
                    <div className="inputdiv">
                      <label>Last Name</label>
                      <br />
                      <input
                        name="FLastName"
                        // data-type="adhaar-number"
                        value={FLastName}
                        placeholder="Last Name"
                        id="FLastName"
                        onChange={handleInput}
                        style={{ width: "80%" }}
                      ></input>
                      {FLastNameErr != "" && (
                        <p className="alert-message">{FLastNameErr}</p>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="inputdiv">
                      <label>Father Name</label>
                      <br />
                      <input
                        name="FFatherName"
                        // data-type="adhaar-number"
                        value={FFatherName}
                        placeholder="Father Name"
                        id="FFatherName"
                        onChange={handleInput}
                        style={{ width: "80%" }}
                      ></input>
                      {FFatherNameErr != "" && (
                        <p className="alert-message">{FFatherNameErr}</p>
                      )}
                    </div>
                  </Col>
                  <Col>
                    <div className="inputdiv">
                      <label>Gender</label>
                      <br />
                      {/* <input
                        name="FGender"
                        // data-type="adhaar-number"
                        value={FGender}
                        placeholder="Gender Name"
                        id="FGender"
                        onChange={handleInput}
                        style={{ width: "80%" }}
                      ></input> */}
                      <select
                        onChange={handleInput}
                        name="FGender"
                        value={FGender}
                        style={{ width: "80%" }}
                      >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                      {FGenderErr != "" && (
                        <p className="alert-message">{FGenderErr}</p>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="inputdiv">
                      <label>Caste</label>
                      <br />
                      <select
                        onChange={handleInput}
                        value={FCaste}
                        name="FCaste"
                        style={{ width: "80%" }}
                      >
                        <option value="">Select Caste</option>
                        {castList?.length != 0 && (
                          <>
                            {castList?.map((item, index) => {
                              return (
                                <option value={item.castCategoryName}>
                                  {item.castCategoryName}
                                </option>
                              );
                            })}
                          </>
                        )}
                      </select>
                      {FCasteErr != "" && (
                        <p className="alert-message">{FCasteErr}</p>
                      )}
                    </div>
                  </Col>
                  <Col>
                    <div className="inputdiv">
                      <label>Mobile Number</label>
                      <br />
                      <input
                        name="farmerCNo"
                        // data-type="adhaar-number"
                        value={mobile}
                        disabled
                        placeholder="Mobile Number"
                        id="farmerCNo"
                        onChange={handleInput}
                        style={{ width: "80%" }}
                      ></input>
                      {farmerCNoErr != "" && (
                        <p className="alert-message">{farmerCNoErr}</p>
                      )}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="inputdiv">
                      <label>Aadhaar Number</label>
                      <br />
                      <input
                        name="farmerAadharNo"
                        // data-type="adhaar-number"
                        value={farmerAadharNo}
                        maxLength={12}
                        disabled
                        placeholder="Aadhaar Number"
                        id="farmerAadharNo"
                        onChange={handleInput}
                        style={{ width: "80%" }}
                      ></input>
                      {farmerAadharNoErr != "" && (
                        <p className="alert-message">{farmerAadharNoErr}</p>
                      )}
                    </div>
                  </Col>
                  <Col>
                    {/*  <div className="inputdiv">
                      <label>Village</label>
                      <br />
                      <input
                        name="FVillage"
                        // data-type="adhaar-number"
                        value={FVillage}
                        placeholder="Village"
                        id="FVillage"
                        onChange={handleInput}
                        style={{ width: "80%" }}
                      ></input>
                      {FVillageErr != "" && (
                        <p className="alert-message">{FVillageErr}</p>
                      )}
                    </div>
                  </Col> */}
                    <div className="inputdiv">
                      <label>District</label>
                      <br />
                      <select
                        onChange={handleInput}
                        value={FDistrict}
                        name="FDistrict"
                        style={{ width: "80%" }}
                      >
                        <option value="">Select District</option>
                        {districts.length != 0 && (
                          <>
                            {districts.map((item, index) => {
                              return (
                                <option value={item.id}>
                                  {item.v_districtName}
                                </option>
                              );
                            })}
                          </>
                        )}
                      </select>
                      {FDistrictErr != "" && (
                        <p className="alert-message">{FDistrictErr}</p>
                      )}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="inputdiv">
                      <label>Block</label>
                      <br />
                      <select
                        onChange={handleInput}
                        value={FBlock}
                        name="FBlock"
                        style={{ width: "80%" }}
                      >
                        <option value="">Select Block</option>
                        {FarmerBlocks.length != 0 && (
                          <>
                            {FarmerBlocks.map((item, index) => {
                              return (
                                <option value={item.id}>
                                  {item.v_blockName}
                                </option>
                              );
                            })}
                          </>
                        )}
                      </select>
                      {FBlockErr != "" && (
                        <p className="alert-message">{FBlockErr}</p>
                      )}
                      {/*  <input
                        name="FBlock"
                        // data-type="adhaar-number"
                        value={FBlock}
                        placeholder="Block"
                        id="FBlock"
                        onChange={handleInput}
                        style={{ width: "80%" }}
                      ></input>
                      {FBlockErr != "" && (
                        <p className="alert-message">{FBlockErr}</p>
                      )} */}
                    </div>
                  </Col>
                  <Col>
                    <div className="inputdiv">
                      <label>Panchayath</label>
                      <br />
                      <input
                        name="FPanchayat"
                        // data-type="adhaar-number"
                        value={FPanchayat}
                        placeholder="Panchayat"
                        id="FPanchayat"
                        onChange={handleInput}
                        style={{ width: "80%" }}
                      ></input>
                      {FPanchayatErr != "" && (
                        <p className="alert-message">{FPanchayatErr}</p>
                      )}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="inputdiv">
                      <label>Village</label>
                      <br />
                      <input
                        name="FVillage"
                        // data-type="adhaar-number"
                        value={FVillage}
                        placeholder="Village"
                        id="FVillage"
                        onChange={handleInput}
                        style={{ width: "80%" }}
                      ></input>
                      {FVillageErr != "" && (
                        <p className="alert-message">{FVillageErr}</p>
                      )}
                    </div>
                  </Col>
                  <Col>
                    <div className="inputdiv">
                      <label>Area</label>
                      <br />
                      <input
                        name="FArea"
                        // data-type="adhaar-number"
                        value={FArea}
                        placeholder="Area"
                        id="FArea"
                        onChange={handleInput}
                        style={{ width: "80%" }}
                      ></input>
                      {FAreaErr != "" && (
                        <p className="alert-message">{FAreaErr}</p>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            {FarmerMsg != "" && (
              <Row>
                <Col lg={4}></Col>
                <Col lg={4} style={{ textAlign: "center" }}>
                  {FarmerMsg != "" && (
                    <p className="alert-message" style={{ marginLeft: -24 }}>
                      {FarmerMsg}
                    </p>
                  )}
                </Col>
              </Row>
            )}
            <Row>
              <Col lg={5}></Col>
              <Col lg={2} style={{ paddingLeft: 0, marginLeft: -8 }}>
                <div>
                  <button
                    className="add_button"
                    disabled={disableSubmit}
                    onClick={() => handleFarmerRegister()}
                    style={{ marginLeft: 0, width: "100%", height: 40 }}
                  >
                    <p
                      style={{ marginTop: -8, fontWeight: "500", fontSize: 16 }}
                    >
                      {" "}
                      Submit
                    </p>
                  </button>
                </div>
              </Col>
            </Row>
          </>
        )}
      </Container>
      <ToastContainer />
    </Wrapper>
  );
};
export default FarmerRegistration;
