import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./styles"
import { Row, Container, Col } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import { useNavigate, useParams } from "react-router-dom";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle"
import { LoadingSpinner } from "../LoadingSpinner/index";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const SeedTesting = () => {
    const {
        isExpanded,
    } = useContext(UserContext);

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);

    let { PurchaseID, type } = useParams();


    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey, setSearchKey] = useState('n');
    const [searchKeyTable, setSearchKeyTable] = useState('');
    const[userRole,setUserRole]=useState('');


    const handleSearch = (e) => {
        let filterValue = e.target.value;
        setCurrentPage(0);
        if (filterValue == '') {
            let offsetsearch = 0;
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setTableData(slice);
            setSearchData(orgtableData);
            setOffSet(offsetsearch);
            setCurrentPage(0);
            setSearchKey('n');
        }
        else {
            let offsetsearch = 0;
            const filteredData = orgtableData.filter(obj => {
                return (obj ?.SeedName ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                            obj ?.VerietyID ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                                obj ?.DemandQuantity ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                                            obj ?.transitQuantity ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                                                obj ?.receiveQuantity ?.toLowerCase().includes(filterValue.toLowerCase()))
            });

            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            setOffSet(offsetsearch);
            setSearchKeyTable(filteredData);
            setTableData(slice);
            setSearchData(filteredData);
        }
    }
    useEffect(() => {
        setIsLoading(true);
        const user_role = localStorage.getItem("loggedRole");
        const userid = localStorage.getItem("UserID");
        setUserRole(user_role);
        const fetchForecast = async () => {
            const result = await API_Auth.getRequestTestingAPI('SHOWTESTSENDER','', '', '', '',userid,
            '','','', '', '', '', '', '','', '');  
            console.log("result", result)
            setIsLoading(false);
            //seting pagenation data
            var slice = result.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result.length / perPage));
            setOrgTableData(result);
            setTableData(slice);
        };
        // call the function
        fetchForecast()
            // make sure to catch any error
            .catch(console.error);
    }, []);
    const handlerowsperPage = (e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(searchKeyTable);
        }
        else {
            const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(orgtableData);
        }
    }
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        const data = orgtableData;
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setOffSet(offsetdata);
            setSearchData(searchKeyTable);
        } else {
            const slice = data.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(data.length / perPage))
            setOffSet(offsetdata);
            setTableData(slice);
            setSearchData(data);
        }
    }
    const handleView = (obj) => {
        console.log(obj);
       navigate("/dispatch-individual/"+obj.dispatchID +"/" +"seed_testing");
    }

    const handleInput = (index, e, obj, inde) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;

    };
    const formatter = new Intl.NumberFormat('en-IN')
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}
                        <Container style={{ marginTop: 42 }}>
                <Row>
                    <Col lg={4} style={{ paddingLeft: 0 }}>
                    {userRole.toLowerCase()== 'distributor'&& <Title>SEED TESTING</Title> }
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
                    <Table className="tablelist" responsive>
                            <thead>
                                <tr className="table_wrap">
                                <th>DispatchID</th>
                                <th>Season</th>
                                <th>Component</th>
                                <th>Type</th>
                             <th>Sender Comment</th>
                             <th>Status</th>      
                             <th>DAO Name </th> 
                             <th>Date</th> 
                             <th>DAO Comment</th>
                             <th>Testing Agency</th>      
                             <th>Testing Comment</th> 
                             <th>TestingDate</th> 
                             <th>SeedReport</th>
                             <th>ReportFile</th>
                             <th style={{ textAlign: "center" }}>Action</th> 
                             </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {tableData.length != 0 && <tbody>
                                {tableData.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index} className="table_wrap">
                                            <td>{obj.dispatchID}</td>
                                            <td>{obj.season}-{obj.year}</td>
                                             <td>{obj.seedName}</td>
                                             <td>{obj.verietyName}</td>
                                            
                                             <td>{obj.senderComment}</td>
                                            <td className="pcat_title">{obj.status}</td>
                                             <td>{obj.daoName}</td>
                                             <td>{obj.insertDate}</td>
                                             <td>{obj.daoComment}</td>
                                             <td>{obj.testingAgencyName}</td>
                                             <td>{obj.reportComment}</td>
                                            
                                             <td>{obj.reportDate}</td>
                                             <td className="pcat_title">{obj.seedReport}</td>
                                             <td>{obj.reportFile}</td>
                                             <td style={{ textAlign: "center" }}>
                                             <div className="row_table_edit " onClick={() => handleView(obj)} style={{ marginRight: 10,background: 'antiquewhite' }}>
                                                        <p>View</p>
                                                    </div>
                                              </td>
                                            </tr>

                                        </>
                                    );
                                })}
                            </tbody>
                            }
                        </Table>
                        <div>
                        <div className="pagination_row_div">
                            <p className="pagination_title">Showing items per page:</p>
                            <div className="pagination_input_div" >
                                <select className="pagination_select"
                                 style={{ margin: 0, background: 'transparent' }}
                                    name="pagination_value"
                                    value={perPage}
                                    onChange={handlerowsperPage} >
                                    <option value={4}>4</option>
                                    <option value={8}>8</option>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                </select>
                                </div>
                        </div>
                        <div className="pagination_row_right_div">
                        <p className="pagination_total">Showing {offset+1} to {searchData.length<offset+perPage&&
                        <span>{searchData.length}</span> }
                        {searchData.length>offset+perPage&&
                        <span>{offset+perPage }</span>} of {searchData.length} items</p>
                         <ReactPaginate
                            previousLabel={<ArrowBackIosIcon  className="pagination_prev_btn" color="#2438a7"/>}
                            nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7"/>}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"} 
                            forcePage={currentPage} />
                    </div>
                    </div>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </Wrapper>
    )
}
export default SeedTesting