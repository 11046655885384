import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./styles"
import { Row, Container, Col } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import { useNavigate, Link } from "react-router-dom";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle"
import { LoadingSpinner } from "../LoadingSpinner/index";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import sort_ascending from "../../images/sort_ascending.png";
import sort_default from "../../images/sort_default.png";
import sort_descending from "../../images/sort_descending.png";

const DistrictWiseFarmerRegistrations = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    const [result_org, set_Result] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);


    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(50);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey, setSearchKey] = useState('n');
    const [searchKeyTable, setSearchKeyTable] = useState('');
    const [districtId, setDistrictId] = useState('');
    const [districtData, setDistrictData] = useState([]);
    const[role,setRole]=useState('');
    const [totalDistributedQuantity, setTotalDistributedQuantity] = useState([]);
    const [totalmemberCount, setTotalmemberCount] = useState([]);
    const [totalfarmerBenifited, setTotalfarmerBenifited] = useState([]);
    const[seasonData,setSeasonData]=useState([]);
    const[season,setSeason]=useState('')
    const [currentSort, setcurrentSort] = useState('default');
    const handleInput = async (e) => {
        const { name, value } = e.target;
        console.log(name,value);
        if(name=="season"){
            setSeason(value);
            setIsLoading(true);
            const result=await API_Auth.getDistrictWiseFarmers(districtId,value);
            console.log("districtdistributionsearchData",result);
            setIsLoading(false);
            let totalfarmerBenifited = 0;
            let totaldisrtributedQuantity = 0;
            let totafarmerRegistered = 0;
            for(let i=0; i<result.length; i++){
                if(result[i]?.farmerBenifited){
                    totalfarmerBenifited = totalfarmerBenifited+parseInt(result[i]?.farmerBenifited);
                }
                 if(result[i]?.totaldistributedQuantity){
                    totaldisrtributedQuantity = totaldisrtributedQuantity+parseInt(result[i]?.totaldistributedQuantity);
              }
              if(result[i]?.memberCount){
                totafarmerRegistered = totafarmerRegistered+parseInt(result[i]?.memberCount);
              }
            }
            setTotalmemberCount(totafarmerRegistered);
            setTotalDistributedQuantity(totaldisrtributedQuantity);
            setTotalfarmerBenifited(totalfarmerBenifited);
           //set pagination
           let offset = 0;
           var slice = result.slice(offset, offset + perPage);
           setPageCount(Math.ceil(result.length / perPage));
           setOrgTableData(result);
           console.log(result);
           set_Result(result);
           setTableData(slice);
           setOffSet(offset);
           setCurrentPage(0);
        }
        if (name == "districtId") {
            setDistrictId(value);
            setIsLoading(true);
            const result=await API_Auth.getDistrictWiseFarmers(value,'');
            console.log("districtdistributionsearchData",result);
            setIsLoading(false);
            let totalfarmerBenifited = 0;
            let totaldisrtributedQuantity = 0;
            let totafarmerRegistered = 0;
            for(let i=0; i<result.length; i++){
                if(result[i]?.farmerBenifited){
                    totalfarmerBenifited = totalfarmerBenifited+parseInt(result[i]?.farmerBenifited);
                }
                 if(result[i]?.totaldistributedQuantity){
                    totaldisrtributedQuantity = totaldisrtributedQuantity+parseInt(result[i]?.totaldistributedQuantity);
              }
              if(result[i]?.memberCount){
                totafarmerRegistered = totafarmerRegistered+parseInt(result[i]?.memberCount);
              }
            }
            setTotalmemberCount(totafarmerRegistered);
            setTotalDistributedQuantity(totaldisrtributedQuantity);
            setTotalfarmerBenifited(totalfarmerBenifited);
           //set pagination
           setOrgTableData(result);
           console.log(result);
           set_Result(result);

           let offset = 0;

           if (currentSort === 'up'){
            result.sort((a, b) => a.memberCount - b.memberCount)
            var slice = result.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result.length / perPage));
            setOffSet(offset);
            setTableData(slice);
            setCurrentPage(0); 
        }
        else if (currentSort === 'default'){
            let offset = 0;
            console.log(orgtableData);
            var slice = result.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result.length / perPage));
            setTableData(slice);
            setOffSet(offset);
            setCurrentPage(0);
        }
        else if (currentSort === 'down') {
            result.sort((a, b) => b.memberCount - a.memberCount)
            var slice = result.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result.length / perPage));
            setOffSet(offset);
            setTableData(slice);
            setCurrentPage(0);
        };
        }
               }
    const handleSearch = (e) => {
        let filterValue = e.target.value;
        setCurrentPage(0);
        if (filterValue == '') {
            let offsetsearch = 0;
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setTableData(slice);
            setSearchData(orgtableData);
            setOffSet(offsetsearch);
            setCurrentPage(0);
            setSearchKey('n');
        }
        else {
            let offsetsearch = 0;
            const filteredData = orgtableData.filter(item => {
                return (item ?.blockName ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                item ?.memberCount?.toString().toLowerCase().includes(filterValue.toLowerCase()))
            });

            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            setOffSet(offsetsearch);
            setSearchKeyTable(filteredData);
            setTableData(slice);
            setSearchData(filteredData);
            setOrgTableData(filteredData);

        }
    }
        useEffect(() => {
        setIsLoading(true);
        
        const DistrictID = localStorage.getItem("DistrictID");
        console.log("DistrictID",DistrictID)
        setDistrictId(DistrictID);
        const role=localStorage.getItem('loggedRole')
        console.log("role",role)
        setRole(role)
        let districtId = ''
        if(role.toLocaleLowerCase()=="ao"||role.toLocaleLowerCase()=="ao_mis"){
           setDistrictId(1);
           districtId= 1;
            }
            else{
                setDistrictId(DistrictID); 
                districtId= DistrictID;
 
            }
            
        const fetchDistributionWise = async () => {
            const seasonsresult=await API_Auth.getSeasons();
            setSeasonData(seasonsresult);
            let result = await API_Auth.getDistrictWiseFarmers(districtId,'');
            console.log(result);
            let totalfarmerBenifited = 0;
            let totaldisrtributedQuantity = 0;
            let totafarmerRegistered = 0;
            for(let i=0; i<result.length; i++){
                if(result[i]?.farmerBenifited){
                    totalfarmerBenifited = totalfarmerBenifited+parseInt(result[i]?.farmerBenifited);
                }
                 if(result[i]?.totaldistributedQuantity){
                    totaldisrtributedQuantity = totaldisrtributedQuantity+parseInt(result[i]?.totaldistributedQuantity);
              }
              if(result[i]?.memberCount){
                totafarmerRegistered = totafarmerRegistered+parseInt(result[i]?.memberCount);
              }
            }
            setTotalmemberCount(totafarmerRegistered);
            setTotalDistributedQuantity(totaldisrtributedQuantity);
            setTotalfarmerBenifited(totalfarmerBenifited);

            let districts_result = await API_Auth.getDistricts();
            console.log("districts_result", districts_result)
            setDistrictData(districts_result);
            console.log("result", result);
            setSearchData(result);
            setIsLoading(false);
            //seting pagenation data
            var slice = result.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result.length / perPage));
            setOrgTableData(result);
            console.log(result);
            set_Result(result);
            setTableData(slice);
        };
        // call the function
        fetchDistributionWise()
            // make sure to catch any error
            .catch(console.error);
    }, []);
    const onSortChange = async(sort_value) => {
        if (currentSort === 'down'){
            console.log(sort_value);
            if(sort_value == 'memberCount'){
            orgtableData.sort((a, b) => a.memberCount - b.memberCount)
            }
            setcurrentSort('up');
            var slice = orgtableData.slice(offset, offset + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setTableData(slice);
            setOffSet(offset);
            setCurrentPage(0);
            console.log('tableData down',tableData);
        }
        else if (currentSort === 'up'){
          /*   let offset = 0;
            console.log(result_org);
            var slice = result_org.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result_org.length / perPage));
            setTableData(slice);
            setOffSet(offset);
            setCurrentPage(0);
            setcurrentSort('default'); */

            const result=await API_Auth.getDistrictWiseFarmers(districtId,'');
            console.log("districtdistributionsearchData",result);
            setIsLoading(false);
            let totalfarmerBenifited = 0;
            let totaldisrtributedQuantity = 0;
            let totafarmerRegistered = 0;
            for(let i=0; i<result.length; i++){
                if(result[i]?.farmerBenifited){
                    totalfarmerBenifited = totalfarmerBenifited+parseInt(result[i]?.farmerBenifited);
                }
                 if(result[i]?.totaldistributedQuantity){
                    totaldisrtributedQuantity = totaldisrtributedQuantity+parseInt(result[i]?.totaldistributedQuantity);
              }
              if(result[i]?.memberCount){
                totafarmerRegistered = totafarmerRegistered+parseInt(result[i]?.memberCount);
              }
            }
            setTotalmemberCount(totafarmerRegistered);
            setTotalDistributedQuantity(totaldisrtributedQuantity);
            setTotalfarmerBenifited(totalfarmerBenifited);
           //set pagination
           setOrgTableData(result);
           console.log(result);
           set_Result(result);

           let offset = 0;
           var slice = result.slice(offset, offset + perPage);
           setPageCount(Math.ceil(result.length / perPage));
           setTableData(slice);
           setOffSet(offset);
           setCurrentPage(0);
           setcurrentSort('default');

                }
        else if (currentSort === 'default') {
            if(sort_value == 'memberCount'){
                orgtableData.sort((a, b) => b.memberCount - a.memberCount)
                }
                var slice = orgtableData.slice(offset, offset + perPage);
                setPageCount(Math.ceil(orgtableData.length / perPage));
                setTableData(slice);
                setOffSet(offset);
                setCurrentPage(0);
                setcurrentSort('down');
            console.log('tableData up',tableData);
        };
    };
    const handlerowsperPage = (e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(searchKeyTable);
        }
        else {
            const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(orgtableData);
        }
    }
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        const data = orgtableData;
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setOffSet(offsetdata);
            setSearchData(searchKeyTable);
        } else {
            const slice = data.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(data.length / perPage))
            setOffSet(offsetdata);
            setTableData(slice);
            setSearchData(data);
        }
    }
    const formatter = new Intl.NumberFormat('en-IN')

    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}

            <Container>
                <Row>
                    <Col lg={3} style={{ paddingLeft: 0,marginTop:25 }}>
                        <Title>Block Level Data</Title>
                    </Col>
                    {role.toLocaleLowerCase()=="dao" &&  <Col lg={5}style={{ paddingRight: 0}}>
                        </Col>}
                  {/*   <Col lg={4} style={{ paddingRight: 0,marginTop:25  }}>
                        <div className="row_div">
                            <div className="input_div" >
                                <input type="type" style={{ margin: 0, background: 'transparent' }}
                                    name="filterValue"
                                    onChange={(e) => handleSearch(e)}
                                    placeholder="Search" />
                                <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                            </div>

                        </div>
                    </Col> */}
{/*                      <Col lg={4} style={{ paddingLeft: 0, }}>
                    <label>Season</label>

                        <div className="row_div">
                            <div className="input_div" >
                                <select value={season} onChange={handleInput} name="season"
                                    style={{ margin: 0, background: 'transparent' }}
                                >

                                    <option value="">All</option>
                                    {seasonData.length != 0 && <>
                                        {seasonData.map((item, index) => {
                                            return (
                                                <option value={item.CombYearSeason}>{item.CombYearSeason}</option>
                                            )
                                        })}
                                    </>}

                                </select>
                            </div>
                        </div>
                    </Col> */}
                {role.toLocaleLowerCase()=="ao" &&  
                 <>
                <Col lg={1}style={{ paddingRight: 0}}>
                </Col><Col lg={4}style={{ paddingRight: 0}}>
                    <label>District</label>
                        <div className="row_div" style={{ paddingRight: 0}}>
                            <div className="input_div" >
                                <select
                                    name="districtId"
                                    value={districtId}
                                    onChange={handleInput}
                                    style={{ margin: 0, background: 'transparent' }}>
{/*                                     <option value="">All</option> */}
                                    {districtData.length != 0 &&
                                        <>
                                            {districtData.map((item, index) => {
                                                return (
                                                    <option value={item.id}>{item.v_districtName}</option>
                                                )
                                            })}
                                        </>}
                                </select>
                            </div>
                        </div>
                    </Col> 
                    </>}
                    {role.toLocaleLowerCase()=="ao_mis" &&  
                 <>
                <Col lg={1}style={{ paddingRight: 0}}>
                </Col><Col lg={4}style={{ paddingRight: 0}}>
                    <label>District</label>
                        <div className="row_div" style={{ paddingRight: 0}}>
                            <div className="input_div" >
                                <select
                                    name="districtId"
                                    value={districtId}
                                    onChange={handleInput}
                                    style={{ margin: 0, background: 'transparent' }}>
{/*                                     <option value="">All</option> */}
                                    {districtData.length != 0 &&
                                        <>
                                            {districtData.map((item, index) => {
                                                return (
                                                    <option value={item.id}>{item.v_districtName}</option>
                                                )
                                            })}
                                        </>}
                                </select>
                            </div>
                        </div>
                    </Col> 
                    </>}

                    </Row>
            </Container>
            <Container style={{marginTop:6}}>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
                        <Table className="tablelist" responsive>
                            <thead>
                            <tr className="tablelhdr_img">
                                  <th>Block Name</th>
                                    <th colSpan={3} style={{textAlign:'center'}}>Farmers Registered
                                    {currentSort=='up' && <img  onClick={()=>onSortChange('memberCount')} src={sort_default} />}
                                    {currentSort=='default' && <img  onClick={()=>onSortChange('memberCount')} src={sort_descending} />}
                                    {currentSort=='down' && <img  onClick={()=>onSortChange('memberCount')} src={sort_ascending} />}
                                    </th>
                                  {/*   <th>Farmers Benefited</th>
                                    <th>Total Distributed</th> */}
                                </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {tableData.length != 0 &&
                             <tbody>
                                {tableData.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                                <td>{obj.blockName}</td>
                                                <td colSpan={3} style={{textAlign:'center'}}>{formatter.format(obj.memberCount)}</td>
                                               {/*  <td>{formatter.format(obj.farmerBenifited)}</td>
                                                <td>{formatter.format(obj.totaldistributedQuantity)}</td> */}
                                            </tr>
                                        </>
                                    );
                                })} 
                                <tr>
                                                {/*                                                 <td>{obj.id}</td> */}
                                                <td className="ptotal_title">Total:</td>
                                                <td colSpan={2} style={{textAlign:'center'}} className="ptotal_title">{formatter.format(totalmemberCount)}</td>
                                               {/*  <td className="ptotal_title">{formatter.format(totalfarmerBenifited)}</td>
                                                <td className="ptotal_title">{formatter.format(totalDistributedQuantity)}</td> */}
                                            </tr>                                           
                            </tbody>
                            }
                        </Table>
                        {tableData.length != 0 &&
                            <div>
                                <div className="pagination_row_div">
                                    <p className="pagination_title">Showing items per page:</p>
                                    <div className="pagination_input_div" >
                                        <select className="pagination_select"
                                            style={{ margin: 0, background: 'transparent' }}
                                            name="pagination_value"
                                            value={perPage}
                                            onChange={handlerowsperPage} >
{/*                                             <option value={4}>4</option> */}
                                            <option value={20}>20</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="pagination_row_right_div">
                                    <p className="pagination_total">Showing {offset + 1} to {searchData.length < offset + perPage &&
                                        <span>{searchData.length}</span>}
                                        {searchData.length > offset + perPage &&
                                            <span>{offset + perPage} </span>} of {searchData.length} items</p>
                                    <ReactPaginate
                                        previousLabel={<ArrowBackIosIcon className="pagination_prev_btn" color="#2438a7" />}
                                        nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7" />}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={currentPage} />
                                </div>
                            </div>
                        }                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </Wrapper>
    )
}
export default DistrictWiseFarmerRegistrations