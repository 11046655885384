import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Container, Row, Col,Table } from "react-bootstrap";
import { Wrapper } from "./DistribureSeedAdhar.styles";
import { LoadingSpinner } from "../LoadingSpinner/index";
import { Title } from "../../GlobalStyle";
import { useNavigate, useLocation } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import Delete from "../../images/delete.png";
import OtpTimer from "otp-timer";
import Seed from "../Seed";

const DistribureSeedAdhar = () => {
  const { isExpanded } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [disableSubmit, setDisableSubmit] = useState(false);

  const [adharErr, setAdharErr] = useState("");
  const [adhar, setAdhar] = useState("");
  const [finalErr, setFinalErr] = useState("");
  const [farmerData, setFarmerData] = useState("");
  const [seedHistorData, setseedHistorData] = useState("");
  const [show, setShow] = useState(false);
  const [qunatity, setQunatity] = useState("");
  const [qunatityErr, setqunatityErr] = useState("");
  const [username, setUserName] = useState("");
  const [userErr, setUserErr] = useState("");
  const [otpShow, setOtpShow] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpErr, setOtpErr] = useState("");
  const location = useLocation();
  const [adharshow, setAdharShow] = useState(false);

  const [cropId, setCropId] = useState(
    location.state?.distributordataSeed == undefined
      ? ""
      : location.state?.distributordataSeed.cropID
  );
  const [cropName, setCropName] = useState(
    location.state?.distributordataSeed == undefined
      ? ""
      : location.state?.distributordataSeed.SeedName
  );
  const [varietyId, setVarietyId] = useState(
    location.state?.distributordataSeed == undefined
      ? ""
      : location.state?.distributordataSeed.verityID
  );
  const [varietyName, setVarietyName] = useState(
    location.state?.distributordataSeed == undefined
      ? ""
      : location.state?.distributordataSeed.VarietyName
  );
  const [qunatitySeed, setQunatitySeed] = useState(
    location.state?.distributordataSeed == undefined
      ? ""
      : location.state?.distributordataSeed.quantity
  );

  const [year, setYear] = useState(
    location.state?.distributordataSeed == undefined
      ? ""
      : location.state?.distributordataSeed.year
  );
  const [crop, setCrop] = useState(
    location.state?.distributordataSeed == undefined
      ? ""
      : location.state?.distributordataSeed.SeedName
  );
  const [variety, setVariety] = useState(
    location.state?.distributordataSeed == undefined
      ? ""
      : location.state?.distributordataSeed.VarietyName
  );
  const [farmersSeedQuota, setfarmersSeedQuota] = useState();
  const [season, setSeason] = useState(
    location.state?.distributordataSeed == undefined
      ? ""
      : location.state?.distributordataSeed.season
  );
  const [aqty, setAqty] = useState(
    location.state?.distributordataSeed == undefined
      ? ""
      : location.state?.distributordataSeed.quantity
  );


  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");

  const handleBackClick = () => {
    navigate(-1);
  };

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(60);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function(position) {
      setLat(position.coords.latitude);
      setLong(position.coords.longitude);
    });
    if (otpShow) {
      let myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(myInterval);
            setAdharShow(true);
            setOtpShow(false);
            console.log("otpadhar", "otpadhar");
          } else {
            setMinutes(minutes - 1);
            setSeconds(59);
          }
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  }, [seconds, otpShow]);
  /*   const handleAddPurchase = () => {
          navigate("/purchase-initiate");
      } */
  const handleInput = e => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;
    if (name === "adhar") {
      console.log(value, value.length);
      if (value == "") {
        setAdhar('');
      } else {
        let cc = value.split("-").join("");
        cc = cc.match(new RegExp(".{1,4}$|.{1,4}", "g")).join("-");
        console.log(cc);
        setAdhar(cc);
      }
    }
    if (name == "qunatity") {
      console.log(qunatitySeed);

      if (parseInt(qunatitySeed) < parseInt(value)) {
        setqunatityErr("Quantity Exceeds");
        console.log(value);
      }
      else if (parseInt(farmersSeedQuota) < parseInt(value)) {
        setqunatityErr("Quantity Exceeds");
        console.log(value);
      }
      else if(value==''){
        setqunatityErr("Please Enter Quantity");
      }
      else{
        setqunatityErr("");
      }
    setQunatity(value);
    }
    if (name == "otp") setOtp(value);
  };

  const handleVerifyAdhar = async () => {
    let error = false;
    let a = adhar.split("-").join("");
    console.log(a);
    console.log(adhar.length);
    if (adhar == "") {
      error = true;
      setAdharErr("Please Enter Aadhaar No");
      setFinalErr(" ");
    } 
    else if (adhar.length<14) {
      error = true;
      setAdharErr("Please Enter Valid Aadhaar No");
      setFinalErr(" ");
    } 
    else  {
      error = false;
      setAdharErr("");
    }
    if (!error) {
      setIsLoading(true);
      //setDisableSubmit(true);
      let result = await API_Auth.getFarmerDetailWithCropSeason(a,'','DISTRIBUTIONSEEDHISTORYAADHARMOBILENO','','','',varietyId,cropId,season,year);
      console.log(result);
      setIsLoading(false);
      if (result.data.length != 0) {
        setFarmerData(result.data);
        setseedHistorData(result.seedDistribution);
        setfarmersSeedQuota(result.data[0].remainingQuantity)
        var districtid = localStorage.getItem("DistrictID");
        console.log(districtid)
        if (districtid.toString() == result.data[0].district) {
          setFinalErr("");
          setShow(true);
          setUserName(result.data[0].name);
        } else {
          setFinalErr("Districts are not same");
          setShow(false);
        }
      } else {
        setShow(false);

        setFinalErr("Aadhaar Number Not exists");
      }
    }
  };
  const handleVerifyOTP = async () => {
    let error = false;
    if (qunatity == "") {
      error = true;
      setqunatityErr("This Field is required");
    }
    console.log(typeof qunatitySeed, typeof qunatity);
    if (parseInt(qunatitySeed) < parseInt(qunatity)) {
      setqunatityErr("Quantity Exceeds");
      error = true;
    }
    else if (parseInt(farmersSeedQuota) < parseInt(qunatity)) {
      setqunatityErr("Quantity Exceeds");
      error = true;
    }
    if (!error) {
      setqunatityErr("");
      setUserErr("");
      setOtpShow(true);
      let phone = "91" + farmerData[0].mobile_no;
      const result = await API_Auth.getVerifyOTP(phone, qunatity, cropName);
      console.log(result);
    } else {
      setOtpShow(false);
    }
  };
  const handleSubmit = async () => {
    let error = false;
    /* if (otp == "") {
            error = true
            setOtpErr("This Field is required")
        } */
    /*   if(otp.toString()!="1234"){
                  setOtpErr("Otp does not matches")
  
              }else{ */
    if (otpShow) {
      if (otp == "") {
        toast.error("Please Enter Otp");
        error = true;
      } else {
        error = false;
      }
    }
    if (adharshow) {
      if (adhar == "") {
        toast.error("Please Enter Aadhaar No");
        error = true;
      } else {
        error = false;
      }
    }
    if (!error) {
      console.log(otpShow, adharshow);
      if (otpShow) {
        var otpf = parseInt(otp);
        var adharf = "";
      }
      if (adharshow) {
        var otpf = "";
        let a = adhar.split("-").join("");
        console.log(a);
        var adharf = a;
      }
      console.log(otpf, adharf);

      setOtpErr("");
      setIsLoading(true);
      //let gps="10.0,15.0";
      let gps = lat + "," + long;
      let apkVersion = "NA";
      let id = localStorage.getItem("UserID");
      let phone = "91" + farmerData[0].mobile_no;
      let a = adhar.split("-").join("");
      console.log(a);

      const result = await API_Auth.getInsertDistribution(
        phone,
        a,
        "DISTRIBUTIONSEED",
        qunatity,
        username,
        cropId,
        varietyId,
        gps,
        apkVersion,
        id,
        year,
        season,
        otpf,
        adharf
      );
      console.log(result);
      if (result.errordata[0].message == "success") {
        setIsLoading(false);

        toast.success("Seed Distribution Successful");
        setTimeout(() => {
          navigate("/distributor-seed-history");
        }, 2000);
      } else {
        setIsLoading(false);
        //setDisableSubmit(false);
        toast.error("Something went wrong please try again");
      }
    }
  };
  const formatter = new Intl.NumberFormat('en-IN')
  return (
    <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
      {isLoading && <LoadingSpinner />}
      <div></div>
      <Container>
        <Row>
          <Col lg={2}>
            <div className="back_button" onClick={() => handleBackClick()}>
              <IoIosArrowBack size={18} />
              <h6>Back</h6>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Title>Aadhaar Verification</Title>
          </Col>
        </Row>
        <hr />
      </Container>
      <Container>
        <Row>
          <Col lg={3}></Col>
          <Col lg={4}>
            <div className="inputdiv">
              <label> Enter Aadhaar No</label>
              <br />
              <input
                name="adhar"
                data-type="adhaar-number"
                value={adhar}
                maxLength={14}
                placeholder="AAAA-BBBB-CCCC"
                id="adharnumber"
                onChange={handleInput}
                style={{ width: "93%" }}
              ></input>
              {adharErr != "" && <p className="alert-message">{adharErr}</p>}
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={4}></Col>
          <Col lg={3}>
            {finalErr != "" && <p className="alert-message">{finalErr}</p>}
          </Col>
        </Row>
        <Row>
          <Col lg={4}></Col>
          <Col lg={3}>
            <button
              className="add_button"
              disabled={disableSubmit}
              onClick={() => handleVerifyAdhar()}
              style={{ marginLeft: 0, width: "80%", height: 40 }}
            >
              <p style={{ marginTop: -8, fontWeight: "500", fontSize: 16 }}>
                {" "}
                Verify Aadhaar
              </p>
            </button>
          </Col>
        </Row>
        {show == true && (
          <>
              <Title>Farmer Details</Title>
            <Row>
              <Col lg={12} sm={6} md={6} className="table_head">
                <Table className="tablelist" responsive>
                  <thead>
                    <tr>
                      <th> Name</th>
                      <th> Father's Name</th>
                      <th> Gender</th>
                      <th> KYC</th>
                      <th>Category</th>
{/*                       <th>Village</th>
 */}                    </tr>
                  </thead>
                  {farmerData.length == 0 && (
                    <tbody>
                      <tr>
                        <td colSpan="12">
                          <p className="no_Data_table">No Data Found</p>
                        </td>
                      </tr>
                    </tbody>
                  )}
                  {farmerData.length != 0 && (
                    <tbody>
                      {farmerData.map((obj, index) => {
                        return (
                          <>
                            <tr key={index}>
                              {/*                                                 <td>{obj.id}</td> */}
                              <td>{obj.name}</td>
                              <td>{obj.father_name}</td>
                              <td>{obj.Gender}</td>
                       <td>{obj.KYC}</td>
                       <td>{obj.CastCetegory}</td>
{/*                        <td>{obj.village}</td> */}
                             
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  )}
                </Table>
              </Col>
            </Row>
        {/*   </>
        )}

        {show == true && (
          <> */}
          <hr/>
          <Container style={{paddingTop:30,paddingBottom:30,boxShadow: '0px 3px 6px #00000029',
          background: '#f0f0f0',borderRadius:5}}>
            <Row>
            <Col lg={2}>
                <label>Component</label>
                <input value={crop} disabled style={{ width: "93%" }}></input>
              </Col>
              <Col lg={2}>
                <label>Type</label>
                <input value={variety} disabled style={{ width: "93%" }}></input>
              </Col>
              <Col lg={2}>
                <label>Available Stock</label>
                <input value={aqty} disabled style={{ width: "93%" }}></input>
              </Col>
              <Col lg={2}>
                <label>Remaining Quota</label>
                <input value={farmersSeedQuota} disabled style={{ width: "93%" }}></input>
              </Col>
              <Col lg={3}>
                <label>Quantity To Be Distributed</label>
                <input
                  name="qunatity"
                  value={qunatity}
                  style={{ background: '#ddffdb',width: "93%" }}
                  onChange={handleInput}
                ></input>
                {qunatityErr != "" && (
                  <p className="alert-message">{qunatityErr}</p>
                )}
              </Col>
             {/*  <Col lg={3}>
                <label>Year</label>
                <input value={year} disabled style={{ width: "93%" }}></input>
              </Col>
              <Col lg={3}>
                <label>Season</label>
                <input value={season} disabled style={{ width: "93%" }}></input>
              </Col> */}
            </Row>

            <Row>
              <Col lg={3}>
                <button
                  className="add_button"
                  disabled={disableSubmit}
                  onClick={() => handleVerifyOTP()}
                  style={{ width: "60%", marginLeft: 20 }}
                >
                  <p className="pcat_title"> Request OTP</p>
                </button>
              </Col>
            </Row>
            {otpShow == true && (
          <>
            <Row>
              <Col lg={4}>
                <div className="inputdiv">
                  <label> Enter OTP</label>
                  <br />
                  <input
                    name="otp"
                    value={otp}
                    onChange={handleInput}
                    style={{ width: "93%" }}
                  ></input>
                  {/*                                 {otpErr != '' && <p className="alert-message">{otpErr}</p>}
                   */}{" "}
                  {minutes === 0 && seconds === 0 ? null : (
                    <h6 style={{ marginLeft: 20 }}>
                      {" "}
                      Time : {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                    </h6>
                  )}
                  {/*  <OtpTimer seconds={60} text="Time:" 
                                ButtonText="Resend" resend={submit} 
                                style={{marginLeft:15}}/> */}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={3}>
                <button
                  className="add_button"
                  disabled={disableSubmit}
                  onClick={() => handleSubmit()}
                  style={{ width: "80%", marginLeft: 20 }}
                >
                  <p className="pcat_title"> Confirm Transaction</p>
                </button>
              </Col>
            </Row>
          </>
        )}
        {adharshow && (
          <>
            <Row>
              <Col lg={4}>
                <div className="inputdiv">
                  <label> Enter Aadhaar No</label>
                  <br />
                  <input
                    name="adhar"
                    value={adhar}
                    onChange={handleInput}
                    placeholder="Aadhaar Number"
                    style={{ width: "93%" }}
                  ></input>
                  {adharErr != "" && (
                    <p className="alert-message">{adharErr}</p>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={3}>
                <button
                  className="add_button"
                  disabled={disableSubmit}
                  onClick={() => handleSubmit()}
                  style={{ width: "80%", marginLeft: 20 }}
                >
                  <p className="pcat_title"> Confirm Transaction</p>
                </button>
              </Col>
            </Row>
          </>
        )}
        </Container>
                  <hr/>
            <Row>
            {seedHistorData.length != 0 && (
              <>
              <Title style={{marginLeft:12}}>Distribution History</Title>
              <Col lg={12} sm={6} md={6} className="table_head">
                <Table className="tablelist">
                  <thead>
                    <tr>
                      <th> Distributor Name</th>
                      <th> Distributed Quantity</th>
                      <th>Distribution Date</th>
                    </tr>
                  </thead>
                    <tbody>
                      {seedHistorData.map((obj, index) => {
                        return (
                          <>
                            <tr key={index}>
                              <td>{obj.distributorName}</td>
                              <td>{formatter.format(obj.distributedQuantity)}</td>
                           <td>{obj.distributionDate}</td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                </Table>
              </Col>
              </>
                  )}
            </Row>
          </>
        )}
      </Container>
      <ToastContainer />
    </Wrapper>
  );
};
export default DistribureSeedAdhar;
