import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper, Label, Input, Switch } from "./DistributorAllotmentByDao.styles"
import { Row, Container, Col, OverlayTrigger, Tooltip } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import Edit from "../../images/edit.png";
import plus from "../../images/white_plus.png"
import { useNavigate, useParams } from "react-router-dom";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle"
import { LoadingSpinner } from "../LoadingSpinner/index";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import Modal from 'react-bootstrap/Modal';

const DistributorAllotmentByDao = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    const [distributorDataByID, setDistributorDataByID] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [distributorData, setDistributorData] = useState([]);
    const [distributorId, setDistributorId] = useState('');
    const [typeChecked, setChecked] = useState(false);

    let { districtID } = useParams();
    const [seasonData, setSeasonData] = useState([])


    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(50);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey, setSearchKey] = useState('n');
    const [searchKeyTable, setSearchKeyTable] = useState('');
    const [year, setYear] = useState(2022);
    const [season, setSeason] = useState("Rabi");
    const [addPurchasetype, setaddPurchasetype] = useState(false);
    const [purchase_type, setpurchase_type] = useState('');
    const [User_det, setUser_det] = useState('');
    const [disable_Button, setbuttonDisable] = useState(false);



    const handleSearch = (e) => {
        let filterValue = e.target.value;
        setCurrentPage(0);
        if (filterValue == '') {
            let offsetsearch = 0;
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setTableData(slice);
            setSearchData(orgtableData);
            setOffSet(offsetsearch);
            setCurrentPage(0);
            setSearchKey('n');
        }
        else {
            let offsetsearch = 0;
            const filteredData = orgtableData.filter(item => {
                return (item ?.FirstName ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                    item ?.LastName ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                        item ?.email ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                            item ?.Purchaser ?.toLowerCase().includes(filterValue.toLowerCase()))
            });

            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            setOffSet(offsetsearch);
            setSearchKeyTable(filteredData);
            setTableData(slice);
            setSearchData(filteredData);
        }
    }

    const handleEditDistributorDetail = (obj) => {
        // navigate("/distributor-details/" + obj.id);
        navigate("/distributor-details/" + obj.id, { state: { distributordata: obj, year: year, season: season } });
        // navigate("/forecast-details-ao/" +obj.ForcastID)
    }
    const handleChangePurshaseStatus = (obj) => {
        setaddPurchasetype(true)
        console.log(obj);
        setUser_det(obj)
    }
    const handleClose = () => {
        setaddPurchasetype(false)
    }
    const handleaddDepartment = async () => {

        setIsLoading(true);
        console.log(purchase_type);
        let result = await API_Auth.updateUserDetail(User_det.FirstName, User_det.LastName, User_det.ContactNo,
            User_det.email, User_det.UserName, User_det.Department, User_det.Designation, User_det.Role, User_det.District,
            User_det.Block, '', User_det.profilePic, User_det.id, purchase_type);
        let res = result[0];
        if (res.Flag == 'Success') {
            setaddPurchasetype(false);
            setIsLoading(false);
            toast.success('Distributor updated successfully');
            setInterval(() => {
                //  window.location.reload();
                navigate(0);
            }, 1000);

        } else {
            setIsLoading(false);
            toast.error('Something went wrong please try again');
        }
    }

    useEffect(() => {
        setIsLoading(true);

        const fetchDistributors = async () => {
            const DistrictID = localStorage.getItem("DistrictID");        
            let result = await API_Auth.getDistributorsWithDistrictID("distributor",DistrictID);
            result.sort((a, b) => a.FirstName.localeCompare(b.FirstName));

            console.log("result", result)
            setDistributorData(result);
            /*   let distributor_result = await API_Auth.getDistributorsByID(10);
  
              console.log("distributor_result",distributor_result) */


            //  setDistributorDataByID(distributor_result);
            setSearchData(result);
            setIsLoading(false);
            //seting pagenation data
            var slice = result.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result.length / perPage));
            setOrgTableData(result);
            setTableData(slice);

            const result_data = await API_Auth.getSeasons();

            setSeasonData(result_data)
        };
        // call the function
        fetchDistributors()
            // make sure to catch any error
            .catch(console.error);
    }, []);
    const handlerowsperPage = (e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(searchKeyTable);
        }
        else {
            const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(orgtableData);
        }
    }
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }
    const handleInput = async (e) => {
        const { name, value } = e.target;

        if (name == "year") setYear(value);
        if (name == "season") setSeason(value);
        if (name == "purchaser") setpurchase_type(value);

        /*  setIsLoading(true);
         let distributor_result = await API_Auth.getDistributorsByID(value);

         console.log("distributor_result",distributor_result)
         setIsLoading(false);

         setDistributorDataByID(distributor_result);
         setSearchData(distributor_result);
         setIsLoading(false);
         //seting pagenation data
         var slice = distributor_result.slice(offset, offset + perPage);
         setPageCount(Math.ceil(distributor_result.length / perPage));
         setOrgTableData(distributor_result);
         setTableData(slice); */

    }

    const loadMoreData = (offsetdata) => {
        const data = orgtableData;
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setOffSet(offsetdata);
            setSearchData(searchKeyTable);
        } else {
            const slice = data.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(data.length / perPage))
            setOffSet(offsetdata);
            setTableData(slice);
            setSearchData(data);
        }
    }
    const handleChange = async (e, index) => {
        const value = e.target.checked;
        console.log(value);
        let list = [...tableData];
        list[index]["typeChecked"] = value;
        let purchaser = '';
        if (value == true) {
            list[index]["Purchaser"] = "Yes";
            purchaser = "Yes";
        }
        else if (value == false) {
            list[index]["Purchaser"] = "No";
            purchaser = "No";
        }
        setTableData(list);
        console.log(list);
        let result = await API_Auth.updateUserPurchaseType(list[index].id,purchaser);
        let res = result[0];
        if (res.Flag == 'Success') {
            setbuttonDisable(true);
            setaddPurchasetype(false);
            setIsLoading(false);
            toast.success('Distributor updated successfully');
            setInterval(() => {
                //  window.location.reload();
                setbuttonDisable(false);
               // navigate(0);

            }, 1000);

        } else {
            setIsLoading(false);
            toast.error('Something went wrong please try again');
        }
        console.log(list);
    };

    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}

            <Container>
                <Row>
                    <Col lg={4} style={{ paddingLeft: 0 }}>
                        <Title>Distributor List</Title>
                    </Col>
                    <Col lg={3}>
                       {/*  <div className="row_div">
                            <div className="input_div" style={{ width: '100%' }}>

                                <select onClick={handleInput}
                                    name="year"
                                    value={year} style={{ margin: 0, background: 'transparent' }}>
                                    <option value="2022">2022</option>
                                    <option value="2023">2023</option>
                                    <option value="2024">2024</option>

                                </select>
                            </div>

                        </div> */}
                    </Col>
                    <Col lg={1} style={{ paddingLeft: 0 }}>
                    </Col>
                   {/*  <Col lg={3}style={{ paddingRight: 0 }}>
                        <div className="row_div"  style={{ paddingRight: 0 }}>
                            <div className="input_div" style={{ width: '100%' }} >
                                <select value={season} onChange={handleInput} name="season"                                 
                                style={{ margin: 0, background: 'transparent' }}>
                                    <option value="">Select Season</option>
                                    {seasonData.length != 0 && <>
                                        {seasonData.map((item, index) => {
                                            return (
                                                <option value={item.Season}>{item.Season}</option>
                                            )
                                        })}
                                    </>}
                                </select>
                            </div>
                        </div>
                    </Col> */}
                 <Col lg={4} style={{ paddingRight: 0}}>
                        <div className="row_div" style={{ paddingRight: 0}}>
                            <div className="input_div" style={{width:'100%'}} >
                                <input type="type" style={{ margin: 0, background: 'transparent',marginRight:0 }}
                                    name="filterValue"
                                    onChange={(e) => handleSearch(e)}
                                    placeholder="Search" />
                                <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                            </div>

                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
                        <Table className="tablelist" responsive>
                            <thead>
                                <tr>
                                    {/*                                     <th>Id</th> */}

                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Purchaser</th>
                                    <th style={{ textAlign: 'right' }}>Action</th>
                                </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {tableData.length != 0 && <tbody>
                                {tableData.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                                {/*                                                 <td>{obj.id}</td> */}
                                                <td>{obj.FirstName} {obj.LastName}</td>
                                                <td>{obj.email}</td>
                                                {obj.Purchaser=="Yes" &&
                                                <td className="green_bold">{obj.Purchaser}</td>
                                                 }
                                                 {obj.Purchaser=="No" &&
                                                <td className="red_bold">{obj.Purchaser}</td>
                                                 }
                                                <td>
                                                    <div className="inputdiv" style={{float:'right'}}>
                                                        <Label>
                                                            <Input checked={obj.Purchaser == "Yes"} type="checkbox"
                                                                disabled={disable_Button}
                                                                style={{width: '5%'}}
                                                                onChange={(e) => handleChange(e, index)} />
                                                            <Switch />
                                                        </Label>
                                                    </div>
                                                </td>
                                                {/*   <td>
                                                <div style={{ float: 'right' }}>
                                                            <>
                                                            {obj.Purchaser=="Yes"&&
                                                                <OverlayTrigger overlay={
                                                                    <Tooltip id={`tooltip-top`}>Allotment Quantity</Tooltip>}>
                                                                      <AddSharpIcon size={24} style={{ marginLeft: 15, cursor: 'pointer', color: "#000044" }}onClick={() => handleEditDistributorDetail(obj)}/>
                                                                </OverlayTrigger>
                                                              }
                                                            </>
                                                            <>
                                                            <OverlayTrigger overlay={
                                                                    <Tooltip id={`tooltip-top`}>Edit</Tooltip>}>
                                                        <img src={Edit} onClick={() => handleChangePurshaseStatus(obj)} style={{ width: 20, height: 20, margin: 5, cursor: 'pointer' }} />
                                                        </OverlayTrigger>
                                                        </>
                                                    </div>
                                                </td> */}
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                            }
                        </Table>
                        {tableData.length != 0 &&
                            <div>
                                <div className="pagination_row_div">
                                    <p className="pagination_title">Showing items per page:</p>
                                    <div className="pagination_input_div" >
                                        <select className="pagination_select"
                                            style={{ margin: 0, background: 'transparent' }}
                                            name="pagination_value"
                                            value={perPage}
                                            onChange={handlerowsperPage} >
                                            <option value={20}>20</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="pagination_row_right_div">
                                    <p className="pagination_total">Showing {offset + 1} to {searchData.length < offset + perPage &&
                                        <span>{searchData.length}</span>}
                                        {searchData.length > offset + perPage &&
                                            <span>{offset + perPage} </span>} of {searchData.length} items</p>
                                    <ReactPaginate
                                        previousLabel={<ArrowBackIosIcon className="pagination_prev_btn" color="#2438a7" />}
                                        nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7" />}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={currentPage} />
                                </div>
                            </div>
                        }                    </Col>
                </Row>
                <Modal show={addPurchasetype} onHide={handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    dialogclassname="modal-content">
                    <Modal.Header closeButton>
                        <Modal.Title> Update Purchaser</Modal.Title>
                    </Modal.Header>

                    <Modal.Body >
                        <div>
                            <label >Distributor Name</label>
                            <br />
                            <div className="input_div" >
                                <input placeholder='Enter Department'
                                    value={User_det.FirstName}
                                    style={{ width: "75%" }}
                                />
                            </div>
                        </div>

                        <div>
                            <label >Select Purchase</label>
                            <br />
                            <div className="input_div" >
                                <select onChange={handleInput} value={purchase_type}
                                    name="purchaser"
                                    style={{ width: "75%" }} >
                                    <option value=''>Select Purchaser </option>
                                    <option value={'yes'}>Yes</option>
                                    <option value={'no'}>No</option>
                                </select>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer className="modal-footer">
                        <div className="row_div">
                            <button className="add_button" onClick={() => handleaddDepartment()} style={{
                                padding: 12,
                                height: 45,
                                margin: 10
                            }}>
                                {/*<img src={plus} style={{ margin: 5 }} /> */}
                                <p className="pcat_title">UPDATE</p></button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </Container>
            <ToastContainer />
        </Wrapper>
    )
}
export default DistributorAllotmentByDao