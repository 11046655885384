import React, { useState, useEffect, useContext, useRef } from "react";
import { UserContext } from "../../context";
import { Wrapper, Label, Input, Switch } from "./styles";
import API_Auth from "../../API_Auth";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingSpinner } from "../LoadingSpinner/index";
import { Container, Row, Col } from "react-bootstrap";
import { base_url } from "../../config";
import Delete from "../../images/delete.png";
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import { Modal, Button } from "react-bootstrap";

const FarmerApplication = () => {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const { isExpanded } = useContext(UserContext);
  const location = useLocation();
  console.log("258963147012",location)
  const navigate = useNavigate();

  /*  */

  const [activityName, setActivityName] = useState("");
  const [activityNameErr, setActivityNameErr] = useState("");
  const [area, setArea] = useState("");
  const [areaErr, setareaErr] = useState("");
  const [plotNo, setPlotNo] = useState("");
  const [plotNoErr, setplotNoErr] = useState("");
  const [areaKhatDocument, setareaKhatDocument] = useState("");
  const [areaKhatDocumentErr, setareaKhatDocumentErr] = useState("");
  const [areaDocument, setAreaDocument] = useState("");
  const [areaDocumentErr, setAreaDocumentErr] = useState("");
  const [applicantType, setApplicantType] = useState("");
  const [applicantTypeErr, setApplicantTypeErr] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankBranch, setBankBranch] = useState("");
  const [branchCode, setBranchCode] = useState("");
  const [branchAccount, setBranchAccount] = useState("");
  const [uploadQualification, setUploadQualification] = useState("");
  const [uploadphotograph, setUploadPhotograph] = useState("");
  const [bankNameErr, setBankNameErr] = useState("");
  const [bankBranchErr, setBankBranchErr] = useState("");
  const [branchCodeErr, setBranchCodeErr] = useState("");
  const [branchAccountErr, setBranchAccountErr] = useState("");
  const [uploadQualificationErr, setUploadQualificationErr] = useState("");
  const [uploadphotographErr, setUploadPhotographErr] = useState("");
  const [qualification, setQualification] = useState("");
  const [qualificationErr, setQualificationErr] = useState("");
  const [FAge, setFAge] = useState("");
  const [FAgeErr, setFAgeErr] = useState("");
  const [activities, setActivities] = useState([]);
  const [applications, setApplications] = useState([]);
  const [declartion, setDeclariton] = useState("");
  const [declartionErr, setDeclartionErr] = useState("");
  const [itemErr, setItemErr] = useState("");

  const [photoFile, setPhotoFile] = useState("");
  const [areaFile, setAreaFile] = useState("");
  const [qualFile, setQualFile] = useState("");
  const [schemes, setSchemes] = useState([]);
  const [schemeErr, setSchemeErr] = useState("");
  const [scheme, setScheme] = useState("");
  const [orgName, setOrgName] = useState("");
  const [orgNameErr, setOrgNameErr] = useState("");
  const [organisation, setOrganisation] = useState(false);
  const [description, setDescription] = useState(false);
  const [orgAddress, setOrgAddress] = useState("");
  const [orgAddressErr, setOrgAddressErr] = useState("");
  const [orgMobile, setOrgMobile] = useState("");
  const [orgMobileErr, setOrgMobileErr] = useState("");
  const [orgPincode, setOrgPincode] = useState("");
  const [orgPincodeErr, setOrgPincodeErr] = useState("");
  const [farmerAadharNo, setfarmerAadharNo] = useState("");
  const [farmerAadharNoErr, setfarmerAadharNoErr] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [ApplicationNo, setApplicationNo] = useState("");
  const [qualificationshow, setQualificationShow] = useState(false);

  const [uploadPhysicalCopy, setUploadPhysicalCopy] = useState("");
  const [uploadPhysicalCopyErr, setUploadPhysicalCopyErr] = useState("");
  const[uploadPhysicalFile,setUploadPhysicalFile]=useState('');
  /*  */

  const [isLoading, setIsLoading] = useState(false);
  const [inputFields, setInputFields] = useState([
    {
      activityName: "",
      scheme: "",
      id: 0,
      error: true,
      errorlabel: "",
    },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      const districts = await API_Auth.getDistricts();

      let activities = await API_Auth.getActivities();
      console.log("activities", activities);
      setActivities(activities);

      let schemes = await API_Auth.getSchemes();
      setSchemes(schemes);

      let applicants = await API_Auth.getApplicants();
      setApplications(applicants);
    };
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, []);
  const handleInput = async (e) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;
    if (name === "uploadphotograph") {
      /*   var imagefile2 = document.querySelector("#file-upload2");
        console.log("file-upload", imagefile2.files[0]);
      //  setUploadPhotograph(URL.createObjectURL(imagefile2.files[0]));
      setUploadPhotograph(imagefile2.files[0].name) */

      var formData = new FormData();
      var imagefile2 = document.querySelector("#file-upload2");
      formData.append("userPic", imagefile2.files[0]);
      console.log(imagefile2.files[0]);
      console.log(formData);
      let upload_res = await API_Auth.uploadImage(formData);
      if (upload_res.data == "File is uploaded") {
        var file_name = "";
        for (var key of formData.entries()) {
          file_name = key[1].name;
        }
        console.log(upload_res);
        console.log(file_name, base_url + "/images?filename=" + file_name);

        var date = new Date();
        var unixTimeStamp = Math.floor(date.getTime() / 1000);
        console.log(unixTimeStamp);
        setUploadPhotograph(base_url + "/images?filename=" + file_name);

        setPhotoFile(file_name);
      }
    }
    if (name === "activityName") {
      setActivityName(value);
    }
    if (name === "scheme") {
      setScheme(value);
    }
    if (name === "applicantType") {
      console.log(value);
      setApplicantType(value);
      setOrgName("");
      setOrgAddress("");
      setOrgMobile("");
      setOrgPincode("");

      if (
        value === "2" ||
        value === "3" ||
        value === "4" ||
        value === "5" ||
        value === "6" ||
        value === "7" ||
        value === "8"
      ) {
        setOrganisation(true);
        setDescription(false);
      } else if (value === "9") {
        setDescription(true);
        setOrganisation(false);
      } else {
        setOrganisation(false);
        setDescription(false);
      }
    }
    if (name === "orgName") {
      setOrgName(value);
    }
    if (name === "orgAddress") {
      setOrgAddress(value);
    }
    if (name === "orgMobile") {
      setOrgMobile(value);
    }

    if (name === "orgPincode") {
      setOrgPincode(value);
    }
    if (name === "FAge") {
      setFAge(value);
    }
    if (name === "area") {
      setArea(value);
    }
    if (name === "plotNo") {
      setPlotNo(value);
    }
    if (name === "areaDocument") {
      /*  var imagefile1 = document.querySelector("#file-upload1");
        console.log("file-upload", imagefile1.files[0]);
        setAreaDocument(URL.createObjectURL(imagefile1.files[0])); */

      var formData = new FormData();
      var imagefile1 = document.querySelector("#file-upload1");
      formData.append("userPic", imagefile1.files[0]);
      console.log(imagefile1.files[0]);
      console.log(formData);
      let upload_res = await API_Auth.uploadImage(formData);
      if (upload_res.data == "File is uploaded") {
        var file_name = "";
        for (var key of formData.entries()) {
          file_name = key[1].name;
        }
        console.log(upload_res);
        console.log(file_name, base_url + "/images?filename=" + file_name);
        setAreaFile(file_name);

        setAreaDocument(base_url + "/images?filename=" + file_name);
      }
    }
    if (name === "qualification") {
      setQualification(value);

      if (value === "Non-Matric") {
        setQualificationShow(false);
      } else {
        setQualificationShow(true);
      }
    }
    if (name === "uploadQualification") {
      /*  var imagefile3 = document.querySelector("#file-upload3");
        console.log("file-upload", imagefile3.files[0]);
        setUploadQualification(URL.createObjectURL(imagefile3.files[0])); */

      var formData = new FormData();
      var imagefile3 = document.querySelector("#file-upload3");
      formData.append("userPic", imagefile3.files[0]);
      console.log(imagefile3.files[0]);
      console.log(formData);
      let upload_res = await API_Auth.uploadImage(formData);
      if (upload_res.data == "File is uploaded") {
        var file_name = "";
        for (var key of formData.entries()) {
          file_name = key[1].name;
        }
        console.log(upload_res);
        console.log(file_name, base_url + "/images?filename=" + file_name);
        setQualFile(file_name);

        setUploadQualification(base_url + "/images?filename=" + file_name);
      }
    }

    if (name === "uploadPhysicalCopy") {
      /*  var imagefile3 = document.querySelector("#file-upload3");
        console.log("file-upload", imagefile3.files[0]);
        setUploadQualification(URL.createObjectURL(imagefile3.files[0])); */

      var formData = new FormData();
      var imagefile4 = document.querySelector("#file-upload4");
      formData.append("userPic", imagefile4.files[0]);
      console.log(imagefile4.files[0]);
      console.log(formData);
      let upload_res = await API_Auth.uploadImage(formData);
      if (upload_res.data == "File is uploaded") {
        var file_name = "";
        for (var key of formData.entries()) {
          file_name = key[1].name;
        }
        console.log(upload_res);
        console.log(file_name, base_url + "/images?filename=" + file_name);
       setUploadPhysicalFile(file_name)
        setUploadPhysicalCopy(base_url + "/images?filename=" + file_name);
      }
    }

    if (name === "bankName") {
      setBankName(value);
    }
    if (name === "bankBranch") {
      setBankBranch(value);
    }
    if (name === "branchCode") {
      setBranchCode(value);
    }
    if (name === "branchAccount") {
      setBranchAccount(value);
    }

    /*  if (name === "areaKhatDocument") {
        var imagefile = document.querySelector("#file-upload");
        console.log("file-upload", imagefile.files[0]);
        setareaKhatDocument(URL.createObjectURL(imagefile.files[0]));
      } */

    if (name === "declartion") {
      if (e.target.checked === true) {
        setDeclariton("Yes");
      } else {
        setDeclariton("No");
      }
    }
  };

  const handleFarmerApplication = async () => {
    /*  if (inputFields.length == 0) {
      setItemErr("Please Add items");
    } else if (inputFields.length != 0) {
      for (var i = 0; i < inputFields.length; i++) {
        console.log("Hello", i);
        if (
          inputFields[i].activityName === "" ||
          inputFields[i].scheme === ""
        ) {
          setItemErr("Please Enter All Fields");
        }
      }
    } */
    if(location?.state?.adharNumber=="" || location.state ?.adharNumber==undefined){
      alert("Adhar Number is not there");
      navigate("/farmer-registration")
    }

    else if (uploadphotograph === "") {
      setUploadPhotographErr("Please Upload Photograph");
    }  else if (activityName === "") {
      setActivityNameErr("Please Select Activity");
      setUploadPhotographErr("");
    } else if (scheme === "") {
      setSchemeErr("Please Select Scheme");
      setActivityNameErr("");
    } else if (applicantType === "") {
      setApplicantTypeErr("Please Select Applicant");
      setSchemeErr("");
    } /* else if (
      (applicantType == "2" ||
        applicantType == "3" ||
        applicantType == "4" ||
        applicantType == "5" ||
        applicantType == "6" ||
        applicantType == "7" ||
        applicantType == "8") &&
      orgName == ""
    ) {
      setApplicantTypeErr("");
      setOrgNameErr("Please Enter Organisation Name");
    } else if (
      (applicantType == "2" ||
        applicantType == "3" ||
        applicantType == "4" ||
        applicantType == "5" ||
        applicantType == "6" ||
        applicantType == "7" ||
        applicantType == "8") &&
      orgAddress === ""
    ) {
      setApplicantTypeErr("");
      setOrgNameErr("");
      setOrgAddressErr("Please Enter Organisation Address");
    } else if (
      (applicantType == "2" ||
        applicantType == "3" ||
        applicantType == "4" ||
        applicantType == "5" ||
        applicantType == "6" ||
        applicantType == "7" ||
        applicantType == "8") &&
      orgMobile === ""
    ) {
      setApplicantTypeErr("");

      setOrgAddressErr("");
      setOrgMobileErr("Please Enter Mobile Number");
    } else if (
      (applicantType == "2" ||
        applicantType == "3" ||
        applicantType == "4" ||
        applicantType == "5" ||
        applicantType == "6" ||
        applicantType == "7" ||
        applicantType == "8") &&
      orgPincode === ""
    ) {
      setApplicantTypeErr("");
      setOrgMobileErr("");
      setOrgPincodeErr("Please Enter Pincode");
    } else if (applicantType == "9" && orgName == "") {
      setApplicantTypeErr("");
      setOrgNameErr("Please Enter Description");
    } */ else if (FAge === "") {
      setOrgNameErr("");
      setOrgAddressErr("");
      setOrgMobileErr("");
      setOrgPincodeErr("");
      setFAgeErr("Please Enter Age");
      setApplicantTypeErr("");
    } else if (area === "") {
      setareaErr("Please Enter Area");
      setFAgeErr("");
    } /* else if (plotNo === "") {
      setplotNoErr("Please Enter Area/Plot No");
      setareaErr("");
    } else if (areaDocument === "") {
      setAreaDocumentErr("Please Upload Area Doc");
      setplotNoErr(""); 
    } */else if (qualification === "") {
      setQualificationErr("Please Select Qualification");
      setAreaDocumentErr("");
      setareaErr("")
    } /*else if (qualificationshow === true && uploadQualification === "") {

      else if (uploadQualification === "") {
      console.log("Hello")
      if (qualificationshow ===true) {
        setUploadQualificationErr("Please Upload Qualification");
        setQualificationErr("");
        setAreaDocumentErr("");
      } else {
        console.log("NO upload")

        setUploadQualificationErr("");
        setQualificationErr("");
        setAreaDocumentErr("");
      } 
      setUploadQualificationErr("Please Upload Qualification");
      setQualificationErr("");
      setAreaDocumentErr("");
    } else if (bankName === "") {
      setBankNameErr("Please Enter Bank Name");
      setAreaDocumentErr("");
      setQualificationErr("");
      setUploadQualificationErr("");
    } else if (bankBranch === "") {
      setBankBranchErr("Please Enter Bank Branch");
      setBankNameErr("");
    } else if (branchCode === "") {
      setBranchCodeErr("Please Enter Bank IFSC Code");
      setBankBranchErr("");
    } else if (branchAccount === "") {
      setBranchAccountErr("Please Enter Bank Account No");
      setBranchCodeErr("");
    }  */
  else if (uploadPhysicalCopy === "") {
    setBranchCodeErr("");
      setQualificationErr("");
      setUploadPhysicalCopyErr("Please Upload File");
    } else if (declartion === "") {
      setDeclartionErr("Please Check the box");
      setUploadPhysicalCopyErr("");
    } else {
      setDeclartionErr("");
      setUploadPhotographErr("");
      setUploadPhotographErr("");
      setActivityNameErr("");
      setSchemeErr("");
      setApplicantTypeErr("");
      setOrgNameErr("");
      setOrgAddressErr("");
      setOrgMobileErr("");
      setOrgPincodeErr("");
      setFAgeErr("");
      setareaErr("");
      setplotNoErr("");
      setAreaDocumentErr("");
      setQualificationErr("");
      setUploadQualificationErr("");
      setBankNameErr("");
      setBankBranchErr("");
      setBranchCodeErr("");
      setBranchAccountErr("");

      var array = [];
      console.log(inputFields);
      for (var i = 0; i < inputFields.length; i++) {
        let var_request =
          inputFields[i]["activityName"] + "_" + inputFields[i]["scheme"];

        array.push(var_request);
      }
      console.log("array", array);
      let text = array.toString();
      console.log("text", text);
      setDisableSubmit(true);
      setIsLoading(true);

      const result = await API_Auth.insert_farmerApplication(
        activityName,
        scheme,
        location?.state?.adharNumber,
        FAge,
        area,
        plotNo,
        //areaDocument,
        areaFile,
        bankName,
        bankBranch,
        branchCode,
        branchAccount,
        qualification,
        //uploadQualification,
        qualFile,
        photoFile,
        //uploadphotograph,
        declartion,
        applicantType,
        orgName,
        orgAddress,
        orgMobile,
        orgPincode,
        uploadPhysicalFile
      );
      console.log("result", result);
      setIsLoading(false);

      if (result[0].Flag == "Success") {
        setUploadPhotograph("");
        setActivityName("");
        setScheme("");
        setApplicantType("");
        setOrgName("");
        setOrgAddress("");
        setOrgMobile("");
        setOrgPincode("");
        setFAge("");
        setArea("");
        setPlotNo("");
        setAreaDocument("");
        setQualification("");
        setUploadQualification("");
        setBankName("");
        setBankBranch("");
        setBranchCode("");
        setBranchAccount("");

        /*  alert(
          "Farmer Application Submitted successfully and application No is" +
            result[0].ApllicationNo
        ); */

        /*  setTimeout(() => {
          navigate("/list-farmer-applications", {
            state: { adharNumber: location.state.adharNumber },
          });
        }, 2000); */
        setShowModal(true);
        setApplicationNo(result[0].ApllicationNo);
        setfarmerAadharNo("");
        setfarmerAadharNoErr("");
        setDescription(false);
        setOrganisation(false);
        setQualificationShow(false);
      } else {
        toast.error("Something Went Wrong ");
        setDisableSubmit(false);
      }
      /*  ;
       */
    }
  };

  const addInputField = () => {
    var data = inputFields;
    if (data.length != 0) {
      for (var i = 0; i < data.length; i++) {
        var feed = {
          activityName: "",
          scheme: "",
          id: i + 1,
          error: true,
          errorlabel: "",
        };
      }
    } else {
      var feed = {
        activityName: "",
        scheme: "",
        id: 0,
        error: true,
        errorlabel: "",
      };
    }
    data.push(feed);
    setInputFields([...data]);
  };

  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    console.log(name, value);
    const list = [...inputFields];
    // list[index][name] = value;

    if (name === "activityName") {
      console.log(value);
      // ✅ with regular expression

      list[index][name] = value;
    }
    if (name === "scheme") {
      console.log(value);
      // ✅ with regular expression

      list[index][name] = value;
    }

    console.log(list);
    setInputFields(list);
  };
  const handleDeleteItem = (data, index) => {
    var setValue = inputFields;
    const dataRemoved = setValue.filter((el, elindex) => {
      return el.id !== data.id;
    });

    setInputFields([...dataRemoved]);
  };

  const initModal = () => {
    setShowModal(false);
    navigate("/list-farmer-applications", {
      state: { adharNumber: location.state.adharNumber },
    });
  };

  return (
    <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
      <Container>
        <Row className="row_title">
          <Col lg={4}>
            <p>Farmer Application</p>
          </Col>
          <Col lg={6}></Col>
        </Row>
      </Container>
      {isLoading && <LoadingSpinner />}
      <hr />
      <Container>
        <Row>
          <Col lg={5} md={5} sm={5}></Col>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Upload Photograph</label>
              <br />

              {uploadphotograph === "" && (
                <label for="file-upload2" class="custom-file-upload"></label>
              )}
              {uploadphotograph !== "" && (
                <label className="custom-file-uploaded">
                  <div>
                    <img
                      src={uploadphotograph}
                      style={{ height: 100, width: 100, margin: 10 }}
                    />
                  </div>
                </label>
              )}
              <input
                id="file-upload2"
                onChange={handleInput}
                name="uploadphotograph"
                type="file"
                style={{ display: "none" }}
              />
              {uploadphotographErr !== "" && (
                <p className="alert-message">{uploadphotographErr}</p>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Activity</label>
              <br />

              <select
                onChange={handleInput}
                name="activityName"
                value={activityName}
                style={{ width: "100%" }}
              >
                <option value="">Select Activity</option>
                {activities.length !== 0 && (
                  <>
                    {activities.map((item, index) => {
                      return (
                        <option value={item.id}>{item.HortiActivity}</option>
                      );
                    })}
                  </>
                )}
              </select>
              {activityNameErr !== "" && (
                <p className="alert-message">{activityNameErr}</p>
              )}
            </div>
          </Col>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Scheme</label>
              <br />

              <select
                onChange={handleInput}
                name="scheme"
                value={scheme}
                style={{ width: "100%" }}
              >
                <option value="">Select Scheme</option>
                {schemes.length !== 0 && (
                  <>
                    {schemes.map((item, index) => {
                      return (
                        <option value={item.id}>{item.TypeOfScheme}</option>
                      );
                    })}
                  </>
                )}
              </select>
              {schemeErr !== "" && <p className="alert-message">{schemeErr}</p>}
            </div>
          </Col>
        </Row>

        {/* <Row>
          <Col lg={4}>
            <label>Activity Name</label>
          </Col>
          <Col lg={4}>
            <label>Scheme Name</label>
          </Col>
          <Col lg={4}>
            <label>Action</label>
          </Col>
        </Row>
        <div>
          {inputFields.map((data, index) => {
            const { activityName, scheme, error, errorlabel } = data;
            return (
              <Row key={index}>
                <Col lg={4}>
                  <div className="inputdiv">
                    <div className="row_div_search">
                      <div className="input_div">
                          <select
                          name="activityName"
                          value={activityName}
                          placeholder="Select Activity"
                          onChange={(evnt) => handleChange(index, evnt)}
                        >
                          <option>Select</option>
                          {activities.length !== 0 && (
                            <>
                              {activities.map((item, index) => {
                                return (
                                  <option value={item.id}>
                                    {item.HortiActivity}
                                  </option>
                                );
                              })}
                            </>
                          )}
                        </select>
                      </div>
                    </div>

                    {error == false && (
                      <p className="alert-message">{errorlabel}</p>
                    )}
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="inputdiv">
                    <div className="row_div_search">
                      <div className="input_div">
                       

                        <select
                          name="scheme"
                          value={scheme}
                          placeholder="Select Activity"
                          onChange={(evnt) => handleChange(index, evnt)}
                        >
                          <option>Select</option>
                          {schemes.length !== 0 && (
                            <>
                              {schemes.map((item, index) => {
                                return (
                                  <option value={item.id}>
                                    {item.TypeOfScheme}
                                  </option>
                                );
                              })}
                            </>
                          )}
                        </select>
                      </div>
                    </div>

                    {error == false && (
                      <p className="alert-message">{errorlabel}</p>
                    )}
                  </div>
                </Col>

                <Col lg={4}>
                  <img
                    src={Delete}
                    onClick={() => handleDeleteItem(data, index)}
                    style={{
                      width: 20,
                      height: 20,
                      marginTop: 20,
                      marginLeft: 15,
                      cursor: "pointer",
                    }}
                  />
                </Col>
              </Row>
            );
          })}
          {itemErr != "" && <p className="alert-message">{itemErr}</p>}
        </div>
 */}
        {/*  <Row>
          <Col lg={9}></Col>
          <Col lg={3}>
            <div
              className="row_table_div "
              onClick={() => addInputField()}
              style={{
                width: 135,
                margin: 20,
                fontWeight: "bold",
                float: "left",
              }}
            >
              <AiOutlinePlus size={20} style={{ margin: 10 }} />
              <p>ADD ACTIVITY</p>
            </div>
          </Col>
        </Row> */}
        <hr />
        <Row>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Any Association</label>
              <br />

              <select
                onChange={handleInput}
                name="applicantType"
                value={applicantType}
                style={{ width: "100%" }}
              >
                <option value="">Select Applicant</option>
                {applications.length !== 0 && (
                  <>
                    {applications.map((item, index) => {
                      return (
                        <option value={item.id}>{item.ApplicantName}</option>
                      );
                    })}
                  </>
                )}
              </select>
              {applicantTypeErr !== "" && (
                <p className="alert-message">{applicantTypeErr}</p>
              )}
            </div>
          </Col>
          {description === true && (
            <>
              <Col lg={4} md={4} sm={4}>
                <div className="inputdiv">
                  <label>Description</label>
                  <br />
                  <input
                    name="orgName"
                    value={orgName}
                    placeholder="Enter Description"
                    onChange={handleInput}
                    style={{ width: "100%" }}
                  />
                  {orgNameErr !== "" && (
                    <p className="alert-message">{orgNameErr}</p>
                  )}
                </div>
              </Col>
            </>
          )}
          {organisation == true && (
            <>
              <Col lg={4} md={4} sm={4}>
                <div className="inputdiv">
                  <label>Organisation Name</label>
                  <br />
                  <input
                    name="orgName"
                    value={orgName}
                    placeholder="Enter Org Name"
                    onChange={handleInput}
                    style={{ width: "100%" }}
                  />
                  {orgNameErr !== "" && (
                    <p className="alert-message">{orgNameErr}</p>
                  )}
                </div>
              </Col>
              <Col lg={4} md={4} sm={4}>
                <div className="inputdiv">
                  <label>Organisation Address</label>
                  <br />
                  <input
                    name="orgAddress"
                    value={orgAddress}
                    placeholder="Enter Org Address"
                    onChange={handleInput}
                    style={{ width: "90%" }}
                  />
                  {orgAddressErr !== "" && (
                    <p className="alert-message">{orgAddressErr}</p>
                  )}
                </div>
              </Col>
              <Col lg={4} md={4} sm={4}>
                <div className="inputdiv">
                  <label>Mobile Number</label>
                  <br />
                  <input
                    name="orgMobile"
                    maxLength={10}
                    value={orgMobile}
                    placeholder="Enter Org Mobile"
                    onChange={handleInput}
                    style={{ width: "100%" }}
                  />
                  {orgMobileErr !== "" && (
                    <p className="alert-message">{orgMobileErr}</p>
                  )}
                </div>
              </Col>
              <Col lg={4} md={4} sm={4}>
                <div className="inputdiv">
                  <label>Pincode</label>
                  <br />
                  <input
                    name="orgPincode"
                    maxLength={6}
                    value={orgPincode}
                    placeholder="Enter PinCode"
                    onChange={handleInput}
                    style={{ width: "100%" }}
                  />
                  {orgPincodeErr !== "" && (
                    <p className="alert-message">{orgPincodeErr}</p>
                  )}
                </div>
              </Col>
            </>
          )}
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Applicant Age</label>
              <br />
              <input
                type="number"
                name="FAge"
                value={FAge}
                placeholder="Enter Age"
                onChange={handleInput}
                style={{ width: "90%" }}
              />
              {FAgeErr !== "" && <p className="alert-message">{FAgeErr}</p>}
            </div>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Area(in Hectare)</label>
              <br />
              <input
                type="number"
                name="area"
                value={area}
                placeholder="Enter Area"
                onChange={handleInput}
                style={{ width: "90%" }}
              />
              {areaErr !== "" && <p className="alert-message">{areaErr}</p>}
            </div>
          </Col>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label> Area Khata No/Plot No:</label>
              <br />
              <input
                type="text"
                name="plotNo"
                value={plotNo}
                placeholder="Enter Plot No"
                onChange={handleInput}
                style={{ width: "90%" }}
              />
              {plotNoErr !== "" && <p className="alert-message">{plotNoErr}</p>}
            </div>
          </Col>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Area Document</label>
              <br />
              {areaDocument === "" && (
                <label for="file-upload1" class="custom-file-upload"></label>
              )}
              {areaDocument !== "" && (
                <label className="custom-file-uploaded">
                  <div>
                    <img
                      src={areaDocument}
                      style={{ height: 100, width: 100 }}
                    />
                  </div>
                </label>
              )}
              <input
                id="file-upload1"
                onChange={handleInput}
                name="areaDocument"
                type="file"
                style={{ display: "none" }}
              />
              {areaDocumentErr !== "" && (
                <p className="alert-message">{areaDocumentErr}</p>
              )}
            </div>
          </Col>
        </Row>
        <hr />
        <label>Qualification</label>

        <Row>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Select Degree</label>
              <br />

              <select
                onChange={handleInput}
                name="qualification"
                value={qualification}
                style={{ width: "90%" }}
              >
                <option value="">Select Qualification</option>
                <option value="SSC">SSC</option>
                <option value="Non-Matric">Non-Matric</option>

                <option value="Inter">Inter</option>
                <option value="B.TECH">B.TECH</option>
                <option value="M.TECH">M.TECH</option>
                <option value="BA">BA</option>
                <option value="B.COM">B.COM</option>
                <option value="B.SC">B.SC</option>

              </select>
              {qualificationErr !== "" && (
                <p className="alert-message">{qualificationErr}</p>
              )}
            </div>
          </Col>
          {qualificationshow == true && (
            <Col lg={4} md={4} sm={4}>
              <div className="inputdiv">
                <label>Upload Qualification</label>
                <br />
                {uploadQualification === "" && (
                  <label for="file-upload3" class="custom-file-upload"></label>
                )}
                {uploadQualification !== "" && (
                  <label className="custom-file-uploaded">
                    <div>
                      <img
                        src={uploadQualification}
                        style={{ height: 100, width: 100 }}
                      />
                    </div>
                  </label>
                )}
                <input
                  id="file-upload3"
                  onChange={handleInput}
                  name="uploadQualification"
                  type="file"
                  style={{ display: "none" }}
                />
                {uploadQualificationErr !== "" && (
                  <p className="alert-message">{uploadQualificationErr}</p>
                )}
              </div>
            </Col>
          )}
        </Row>
        <hr />
        <Row>
          <label>Banking Information</label>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Bank Name</label>
              <br />
              <input
                type="text"
                name="bankName"
                value={bankName}
                placeholder="Enter Bank Name"
                onChange={handleInput}
                style={{ width: "100%" }}
              />
              {bankNameErr !== "" && (
                <p className="alert-message">{bankNameErr}</p>
              )}
            </div>
          </Col>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Branch</label>
              <br />
              <input
                name="bankBranch"
                value={bankBranch}
                placeholder="Enter Bank Branh"
                onChange={handleInput}
                style={{ width: "90%" }}
              />
              {bankBranchErr !== "" && (
                <p className="alert-message">{bankBranchErr}</p>
              )}
            </div>
          </Col>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Branch IFSC Code</label>
              <br />
              <input
                name="branchCode"
                maxLength={11}
                value={branchCode}
                placeholder="Enter Bank IFSC code"
                onChange={handleInput}
                style={{ width: "100%" }}
              />
              {branchCodeErr !== "" && (
                <p className="alert-message">{branchCodeErr}</p>
              )}
            </div>
          </Col>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Bank Account Number</label>
              <br />
              <input
                name="branchAccount"
                value={branchAccount}
                placeholder="Enter Bank Account"
                onChange={handleInput}
                style={{ width: "100%" }}
              />
              {branchAccountErr !== "" && (
                <p className="alert-message">{branchAccountErr}</p>
              )}
            </div>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Upload Physical Copy</label>
              <br />
              {uploadPhysicalCopy === "" && (
                <label for="file-upload4" class="custom-file-upload"></label>
              )}
              {uploadPhysicalCopy !== "" && (
                <label className="custom-file-uploaded">
                  <div>
                    <img
                      src={uploadPhysicalCopy}
                      style={{ height: 100, width: 100 }}
                    />
                  </div>
                </label>
              )}
              <input
                id="file-upload4"
                onChange={handleInput}
                name="uploadPhysicalCopy"
                type="file"
                style={{ display: "none" }}
              />
              {uploadPhysicalCopyErr !== "" && (
                <p className="alert-message">{uploadPhysicalCopyErr}</p>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            lg={10}
            md={10}
            sm={10}
            style={{
              marginTop: 10,
              marginLeft: 30,
            }}
          >
            <Row>
              <Col lg={2} sm={2} md={2}>
                <input
                  type="checkbox"
                  style={{ height: 25 }}
                  onChange={handleInput}
                  value={declartion}
                  name="declartion"
                />
              </Col>
              <Col lg={6} sm={6} md={6}>
                <label
                  style={{
                    marginTop: 15,
                    marginLeft: -35,
                  }}
                >
                  Declartion by Farmer
                </label>
                {declartionErr !== "" && (
                  <p className="alert-message">{declartionErr}</p>
                )}
              </Col>
            </Row>
          </Col>{" "}
        </Row>
      </Container>
      <Container>
        <Row>
          <Col lg={3}></Col>
          <Col lg={4}>
            <div className="row_div">
              <button
                className="add_button"
                disabled={disableSubmit}
                onClick={() => handleFarmerApplication()}
                style={{
                  margin: 12,
                  width: "92%",
                }}
              >
                <p className="pcat_title">Submit</p>
              </button>
            </div>
          </Col>
        </Row>
      </Container>{" "}
      <Modal show={showModal}>
        <Modal.Header closeButton onClick={initModal}>
          <Modal.Title>Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Farmer Application Submitted successfully and application No is{" "}
            <b>{ApplicationNo}</b>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={initModal}>OK</Button>
          {/*   <Button variant="dark" onClick={initModal}>
            Store
          </Button> */}
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </Wrapper>
  );
};
export default FarmerApplication;
