import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Container, Row, Col ,Modal} from "react-bootstrap";
import { Wrapper } from "./styles"
import { LoadingSpinner } from "../LoadingSpinner/index";
import { Title } from "../../GlobalStyle";
import { useNavigate, useParams } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import Delete from "../../images/delete.png"
import { height } from "@mui/system";
import Table from "react-bootstrap/Table";
import { base_url } from "../../config"

const PurchaseInitiate = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [year, setYear] = useState('');
    const [season, setSeason] = useState('');
    
    const [seasonforAPI, setSeasonAPI] = useState('');
    const [yearErr, setYearErr] = useState('');
    const [seasonErr, setSeasonErr] = useState('');
    const navigate = useNavigate();
    const [seedIndex, setSeedIndex] = useState('');
    const [seeds, setSeeds] = useState([]);
    const [seedsSuggestion, setSeedsSuggestion] = useState([]);
    const [seedErr, setSeedErr] = useState('');
    const [seedText, setSeedText] = useState('a');
    const [suppliers, setSuppliers] = useState([]);
    const [supplierId, setSupplierbyId] = useState('');
    const [supplierErr, setSupplierErr] = useState('');

    const [varietyIndex, setVarietyIndex] = useState('');
    const [varietys, setVarietys] = useState([]);
    const [varietySuggestion, setVarietySuggestion] = useState([]);
    const [varietyErr, setVarietyErr] = useState('');
    const [varietyText, setVarietyText] = useState('a');
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [itemErr, setItemErr] = useState('');
    const [chequeno, setChequeno] = useState('');
    const [chequenoErr, setChequenoErr] = useState('');
    const [payment_type, setpayment_type] = useState('');
    const [payment_typeErr, setpayment_typeErr] = useState('');
    const [draftImg, setDraftImg] = useState('');
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [seasonData, setSeasonData] = useState([]);
    const [quantityerr, setquantityerr] = useState();
    const [userPic, setuserPic] = useState('');
    const [userPicShow, setuserPicShow] = useState('');
    const [showpopup, setshowPopup] = useState(false);
    const [errorArray, setErrorArray] = useState([]);
    const [exceedqtyEror, setqtyExceederr] = useState([]);
    
    const [requiredQuantityErr, setRequiredQuantityError] = useState('');

    const [inputFields, setInputFields] = useState([{
        seed: '',
        variety: '',
        quantity: '',
        varieties: [],
        seedid: '',
        varietyid: '',
        issueqtyerror: true,
        issueqtyErr: "",
        issuecolor: '',
        allotedqty: ''
    }]);

            useEffect(() => {
            const fetchSeeds = async () => {
            const user_id = localStorage.getItem("UserID");
            let seedsdata = await API_Auth.getSeeds();
            seedsdata.sort((a, b) => a.CropName.localeCompare(b.CropName));
            console.log("seedsdata", seedsdata);
            setSeeds(seedsdata);
            setVarietys(seedsdata)
            let suppliersdata = await API_Auth.getSuppliers('supplier');
            suppliersdata.sort((a, b) => a.UserName.localeCompare(b.UserName));
            console.log("suppliersdata", suppliersdata)
            setSuppliers(suppliersdata);
            const result = await API_Auth.getSeasons();
            setSeasonData(result);
            console.log(result);
            //const data = result.filter((item) => item.Season == result1[0].Season);
            /* setSeason(result[0].CombYearSeason);
            const myArray = result[0].CombYearSeason.split("-");
            console.log("myArray", myArray)
            setYear(myArray[0]);
            setSeasonAPI(myArray[1]);
            console.log("Season", result[0].id); */

            /* let result1 = await API_Auth.getPurchaseListbByAllotment(user_id,'');
            console.log("result", result1)
            let list = [...result1];
            for(let i=0; i<result1.length; i++){
                const pendingPurchase = parseInt(result1[i]?.Quantity)-parseInt(result1[i]?.PurchaseQuantity);
                list[i]["pendingPurchase"] = pendingPurchase;
                 }
                 setOrgTableData(list);
                 setTableData(list);
                 console.log("list", list) */
        };
        // call the function
        fetchSeeds()
            // make sure to catch any error
            .catch(console.error);
    }, [])



    const handleBackClick = () => {
        navigate(-1);;
    }
    const handleFileupload = async () => {
        var formData = new FormData();
        var imagefile = document.querySelector('#file-upload');
        formData.append("userPic", imagefile.files[0]);
        console.log(imagefile);
        console.log(formData);
        let upload_res = await API_Auth.uploadImage(formData);
        if (upload_res.data == 'File is uploaded') {
            var file_name = '';
            for (var key of formData.entries()) {
                file_name = key[1].name;
            }
            console.log(upload_res);
            setuserPic(file_name);
            setuserPicShow(base_url + "/images?filename=" + file_name);
        }
    }
    const removePic = (type) => {
            setuserPic('');
            setuserPicShow('');
            document.getElementById("file-upload").value = '';
    }

    const handleInput = async(e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
       // if (name === "year") setYear(value);
        if (name == "season") {
            console.log("comb season value",value);
            if(value!=''){
            const myArray = value.split("-");
            console.log(myArray)
            setYear(myArray[0]);
            setSeasonAPI(myArray[1]);
            setSeason(value);
            const user_id = localStorage.getItem("UserID");
            let result1 = await API_Auth.getPurchaseListbByAllotment(user_id,value);
            console.log("result", result1)
            let list = [...result1];
            for(let i=0; i<result1.length; i++){
                const pendingPurchase = parseInt(result1[i]?.Quantity)-parseInt(result1[i]?.PurchaseQuantity);
                list[i]["pendingPurchase"] = pendingPurchase;
                 }
                 setOrgTableData(list);
                 setTableData(list);
                 console.log("list", list)
                }
        }
        if (name === "chequeno") setChequeno(value);
        if (name == "payment_type") setpayment_type(value);
        if (name === "supplier") setSupplierbyId(value);
       // if (name == "season") setSeason(value);
        if (name === "draftimg") {
            /*             const lastSegment = value.split("").pop();
            console.log(lastSegment); // "playlist" */
            console.log("value", value);
            setDraftImg(value)
        };
    }
    
    const handleClose = async (item, index) => {
    setshowPopup(false)
    }
    const handleSeedChange = async (item, index) => {
        let totalArray = await handleItems(item);

        let error = false;
      /*   if (year == '') {
            error = true;
            setYearErr("This Field is required");
        } else {
            error = false
            setYearErr('')
        } */
        if (season == '') {
            error = true;
            setSeasonErr("This Field is required");
        } else {
            error = false
            setSeasonErr('')
        }
        if (!error) {

            if (totalArray) {
                inputFields[seedIndex].seed = item.CropName;
                inputFields[seedIndex].seedid = item.id;

                const varieties = await API_Auth.getVarietyBySeed(item.CropName);
                let userid = localStorage.getItem('UserID')

                const allotedresult = await API_Auth.checkallotement(year, seasonforAPI, userid, varieties[0].id, item.id)
                console.log("allotedresult", allotedresult)
                console.log("varieties", varieties);
                inputFields[seedIndex].allotedqty = allotedresult.length != 0 ? allotedresult[0].AllotedQuantity : 0
                // setVarieties(varieties)
                inputFields[seedIndex].varieties = varieties;
                inputFields[seedIndex].variety = varieties.length != 0 ? varieties[0].Variety : '';
                inputFields[seedIndex].varietyid = varieties.length != 0 ? varieties[0].id : '';




                /*   setItemErr('');
                  setDuplicateAsset(false); */
                // inputFields[seedIndex].error = true;
                // inputFields[seedIndex].errorlabel = "true"

            } else {
                inputFields[seedIndex].seed = item.CropName;

                //setItemErr('Please Select Another Item ..Item is already added');
                // inputFields[seedIndex].error = false;
                //inputFields[seedIndex].errorlabel = "Duplicate Entry"
                //setDuplicateAsset(true);
            }
            console.log("inputFields", inputFields)
            setInputFields(inputFields)

            setSeedText('a')

            // setItemSuggestions([]);
        }

    }
    const addInputField = () => {
        var data = inputFields;
        if (data.length != 0) {
            for (var i = 0; i < data.length; i++) {
                var feed = {
                    seed: '',
                    variety: '',
                    quantity: '',
                    seedid: '',
                    varietyid: '',
                    varieties: [],

                    error: true,
                    errorlabel: ""

                };
            }
        } else {
            var feed = {
                seed: '',
                variety: '',
                quantity: '',
                id: '',
                seedid: '',
                varietyid: '',
                varieties: [],

                error: true,
                errorlabel: ""

            };
        }
        data.push(feed);
        setInputFields([...data]);
        setItemErr("")
    }

    const handleDeleteRow = (data, index) => {
        var setValue = inputFields;
        console.log(data, index, inputFields)
        const dataRemoved = setValue.filter((el, elindex) => {
            return el.seedid !== data.seedid;
        });
        setInputFields([...dataRemoved])
    }

    const handleVarietyChange = async (item, index) => {
        let totalArray = await handleItems(item);

        if (totalArray) {
            inputFields[varietyIndex].variety = item.Variety;
            inputFields[varietyIndex].varietyid = item.id;


            /*   setItemErr('');
              setDuplicateAsset(false); */
            // inputFields[seedIndex].error = true;
            // inputFields[seedIndex].errorlabel = "true"

        } else {
            inputFields[varietyIndex].variety = item.Variety;

            //setItemErr('Please Select Another Item ..Item is already added');
            // inputFields[seedIndex].error = false;
            //inputFields[seedIndex].errorlabel = "Duplicate Entry"
            //setDuplicateAsset(true);
        }
        console.log("inputFields", inputFields)
        setInputFields(inputFields)

        setVarietyText('a')

        // setItemSuggestions([]);

    }



    const handleChange = async (index, evnt) => {

        const { name, value } = evnt.target;
        const list = [...inputFields];

        //setseedIndex(index);
        if (name === "seed") {
            setSeedIndex(index);

            if (value.length == 0) {
                setSeedsSuggestion(seeds);
                list[index][index] = index;
                list[index][name] = value;
            } else {
                const suggestdta = seeds.filter(item => {
                    return item ?.CropName ?.toLowerCase().includes(value.toLowerCase())                    
                });
                list[index][index] = index;
                list[index][name] = value;
                setSeedText('f')

                if (suggestdta.length == 0) {
                    setSeedErr('Component not found');
                } else {
                    setSeedErr('');

                }
                setSeedsSuggestion(suggestdta);
            }
        }
        if (name === "variety") {
            setVarietyIndex(index);
            console.log(index)

            list[index][name] = value;
            const id = list[index]['varieties'].filter((item, index) => item.Variety == value)
            console.log("id", id)

            list[index]['varietyid'] = id[0].id

            let userid = localStorage.getItem('UserID')


            const allotedresult = await API_Auth.checkallotement(year, seasonforAPI, userid, id[0].id, list[index].seedid)
            console.log("allotedresult", allotedresult);
            list[index].allotedqty = allotedresult.length != 0 ? allotedresult[0].AllotedQuantity : 0


            console.log("list", list);
            /*  if (value.length == 0) {
                 setVarietySuggestion(varietys);
                 list[index][index] = index;
                 list[index][name] = value;
             } else {
                 const suggestdta = seeds.filter(item => {
                     return item ?.Variety ?.toLowerCase().includes(value.toLowerCase())                    
                 });
                 list[index][index] = index;
                 list[index][name] = value;
                 setVarietyText('f')
 
                 if (suggestdta.length == 0) {
                     setVarietyErr('Variety not found');
                 } else {
                     setVarietyErr('');
 
                 }
                 setVarietySuggestion(suggestdta);
             } */
        }
        if (name == "quantity") {
            /*   list[index][index] = index;
              list[index][name] = value; */

            if (parseInt(list[index].allotedqty) >= parseInt(value)) {
                console.log("Hello");
                list[index]['issueqtyerror'] = true;
                list[index]['issueqtyErr'] = "";
                list[index][name] = value;
                list[index][index] = index

            } else {
                console.log("Hi")

                list[index][index] = index
                list[index]['issueqtyerror'] = false;
                list[index]['issueqtyErr'] = "Quantity Exceeds"
                list[index]["issuecolor"] = "y"
                list[index][name] = value;

            }
        }

        console.log("list", list)

        setInputFields(list);
    }
    const renderItemSuggestions = () => {
        if (seedsSuggestion.length == 0) {
            return null;
        }
        return (
            <ul className={seedsSuggestion.length != 0 && seedText == 'f' ? "ul_search" : "ul_search_length"} >
                {seedsSuggestion.length != 0 && seedText == 'f' &&
                    <>

                        {seedsSuggestion.map((item, index) => {
                            return (
                                <li className="li_search" onClick={() => handleSeedChange(item, index)}>{item.CropName}</li>
                            )
                        })}
                    </>
                }
            </ul>
        )
    };
    const handlechange = (index, e, obj) => {
        const name = e.currentTarget.name;
        setquantityerr(''); 
        const value = e.currentTarget.value;
        console.log("obj.Quantity", obj.pendingPurchase);
        console.log(name, value);
        const list = [...tableData];
        list[index]["quantityerr"] = "";
        if(parseInt(value)> parseInt(obj.pendingPurchase)){
            //setquantityerr('Quantiy Exceeds');
            list[index]["quantityerr"] = true;
        }
        else{
           // setquantityerr(''); 
           list[index]["quantityerr"] = false;
        };
        if (name === "requiredQuantity") {
          list[index][name] = value;
        };
        const data = list.filter((item) => item.quantityerr);
        console.log(data);
        if(data?.length>0){
            setDisableSubmit(true);
        }
        else{
            setDisableSubmit(false);  
        };
        setTableData(list);
        console.log(tableData);
    };
    const handleCreateData = async () => {
        setRequiredQuantityError('');
        let error = false;
        if (season == '') {
            error = false;
            setSeasonErr("This Field is required");
        } else {
            error = true
            setSeasonErr('')
        }
        if (supplierId == '') {
            error = false;
            setSupplierErr("This Field is required");
        } else {
            error = true
            setSupplierErr('')
        }
        var croparray = [];
        var vararray = [];
        var qtyarray = [];    
        let error_array = [];  
        for (var i = 0; i < tableData.length; i++) {
            console.log(tableData[i]);
            if(tableData[i]["requiredQuantity"]&& !tableData[i][quantityerr]){
               /*  if(parseInt(tableData[i]["requiredQuantity"])>parseInt(tableData[i]["pendingPurchase"])){
                    error_array.push(tableData[i]);
                    console.log("error_array", error_array);
                }
                else{ */
            let var_cropid = tableData[i]["cropID"] + "_" + tableData[i]["verityID"] + "_" + tableData[i]["requiredQuantity"];
            croparray.push(var_cropid);
            vararray.push(tableData[i]["verityID"])
            let qty_Array = tableData[i]["verityID"] + "-" + tableData[i]["requiredQuantity"];
            qtyarray.push(qty_Array);
            console.log("croparray", croparray);
            console.log("vararray", vararray);
            console.log("qtyarray", qtyarray);
                }
            /* } */
            console.log("error_array", error_array);
            setErrorArray(error_array);
        }
        let croptext = croparray.toString();
        let vartext = vararray.toString();
        let qtytext = qtyarray.toString();

        console.log("croptext", croptext);
        console.log("vartext", vartext);
        console.log("qtytext", qtytext);
           if (error&&qtytext) {
            setDisableSubmit(true);
          //  setIsLoading(true);
            let userId = localStorage.getItem("UserID");

            let status = "SentToAO";
            let cheque = "'" + chequeno + "'"

            // let add_forecast = await API_Auth.initiateSinglePurchase(year, season, inputFields[0]["seedid"],inputFields[0]["varietyid"] ,inputFields[0]["quantity"],supplierId, payment_type, chequeno, userPic, userId)
            console.log(year, season, supplierId, payment_type, cheque, userPic, userId, croptext, vartext, qtytext)
             
            let add_forecast = await API_Auth.initiateMultiPurchase(year, seasonforAPI, supplierId, payment_type, cheque, userPic, userId, croptext, vartext, qtytext);
          
            console.log(add_forecast);
            if (add_forecast[0]?.Flag == "Success") {
                setIsLoading(false);

                toast.success('Purchase added successfully');
                setTimeout(() => {
                    navigate("/purchase-list/" + 'purchase');
                }, 2000)
            } else {
                setIsLoading(false);
                setDisableSubmit(false);
                toast.error('Something went wrong');
            }
        } else {
            setIsLoading(false);
            setDisableSubmit(false);
            setRequiredQuantityError('Please Enter Required quantity');
        } 
    }
    const renderVarietySuggestions = () => {
        if (varietySuggestion.length == 0) {
            return null;
        }
        return (
            <ul className={varietySuggestion.length != 0 && varietyText == 'f' ? "ul_search" : "ul_search_length"} >
                {varietySuggestion.length != 0 && varietyText == 'f' &&
                    <>

                        {varietySuggestion.map((item, index) => {
                            return (
                                <li className="li_search" onClick={() => handleVarietyChange(item, index)}>{item.Variety}</li>
                            )
                        })}
                    </>
                }
            </ul>
        )
    }
    const handleItems = async (item) => {
        let totalErrorArray = []


        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].itemid === item.id) {
                return false;
            }
        }
        return true;   // this means not unique

    }
    const formatter = new Intl.NumberFormat('en-IN');
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}
            <Container style={{marginTop:54}}>
                <Row>
                    <Col lg={2}>
                        <div className="back_button" onClick={() => handleBackClick()}>
                            <IoIosArrowBack size={18} />
                            <h6>Back</h6>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Title>Initiate Purchase</Title>
                    </Col>
                </Row>
                <hr />
            </Container>
            <Container>
                <Row>
                   {/*  <Col lg={4}>
                        <div className="inputdiv">
                            <label> Select Year</label>
                            <br />
                            <select
                                name="year"
                                value={year}
                                onChange={handleInput}
                                style={{ width: "93%" }}>
                                <option value=''>Select Year</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                            </select>
                            {yearErr != '' && <p className="alert-message">{yearErr}</p>}
                        </div>
                    </Col> */}
                    <Col lg={4}>
                        <div className="inputdiv">
                            <label>Select Season & Year</label>
                            <br />
                            <select value={season} onChange={handleInput} name="season" >
                                <option value="">Select Season</option>
                                {seasonData.length != 0 && <>
                                    {seasonData.map((item, index) => {
                                        return (
                                            <option value={item.CombYearSeason}>{item.CombYearSeason}</option>
                                        )
                                    })}
                                </>}

                            </select>
                            {seasonErr != '' && <p className="alert-message">{seasonErr}</p>}

                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="inputdiv">
                            <label>Select Supplier</label>
                            <br />
                            <select
                                name="supplier"
                                value={supplierId}
                                onChange={handleInput}
                                style={{ width: "93%" }}>
                                <option value=''>Select Supplier</option>
                                {suppliers.length != 0 && <>
                                    {suppliers.map((item, index) => {
                                        return (
                                            <option value={item.id}>{item.UserName}</option>
                                        )
                                    })}
                                </>}
                            </select>
                            {supplierErr != '' && <p className="alert-message">{supplierErr}</p>}

                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="inputdiv">
                            <label>Payment Type</label>
                            <br />
                            <input type="text"
                                name="payment_type"
                                value={payment_type}
                                placeholder="Enter payment type"
                                onChange={handleInput}
                                style={{ width: "93%" }} />
                            {payment_typeErr != '' && <p className="alert-message">{payment_typeErr}</p>}
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="inputdiv">
                            <label>Cheque / DD No</label>
                            <br />
                            <input type="number"
                                name="chequeno"
                                value={chequeno}
                                placeholder="Enter cheque no"
                                onChange={handleInput}
                                style={{ width: "93%" }} />
                            {chequenoErr != '' && <p className="alert-message">{chequenoErr}</p>}
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="inputdiv">
                            <label>DD File</label><br />
               {/*  {userPicShow != '' &&
        <label className="custom-file-uploaded" >
            <div><img src={userPicShow} />
            </div></label>}  */}
                    <input id="file-upload" onChange={() => handleFileupload()} name="draftimg" type="file" />
                      
                        </div>
                    </Col>
                </Row>
                <hr />
                <Row>
                <Col lg={12} sm={12} md={12} className="table_head">
                <Table className="tablelist" responsive>
                            <thead>
                                <tr>
                                  {/*   <th>Supplier Name </th>
                                    <th>Component</th>
                                    <th>Demand Date</th>
                                    <th>Status</th> */}
                                    <th>Seed</th>
                                    <th>Type Name </th>
                                    <th>Allotted Quantity</th>
                                    <th>Purchase Quantity</th>
                                    <th>Pending Purchase </th>
                                    <th style={{ textAlign: 'center' }}>Required Quantity</th>
                          {/*<th style={{ textAlign: 'right' }}>Action</th>*/}
                            </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {tableData.length != 0 && <tbody>
                                {tableData.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                           <td style={{lineBreak:"anywhere"}}>{obj.SeedName}</td>
                                           <td>{obj.VarietyName}</td>
                                           <td>{formatter.format(obj.Quantity)}</td>
                                           <td>{formatter.format(obj.PurchaseQuantity)}</td>
                                           <td>{formatter.format(obj.pendingPurchase)}</td>
                                           <td style={{textAlign:"-webkit-center"}}><div className="input_div_table" >
                                           <input type={'number'}
                                            style={{ margin: 0, background: 'transparent', fontSize: 18 }}
                                            name="requiredQuantity" className="inputdiv"
                                            value={obj.requiredQuantity}
                                            //disabled={item.receivedBy?"complete":''}
                                            onChange={(evnt) => handlechange(index, evnt, obj)}
                                            placeholder="" />
                                       {obj.quantityerr && <p style={{
                                            color: '#DF8D0F', textAlign: 'center', fontWeight: '700'
                                        }}>{"Quantiy Exceeds"}</p>}
                                             </div></td>                                            
                                              {/*   <td style={{ textAlign: 'right' }}>

                                                    <div>
                                                        <img src={Edit} onClick={() => handleEditForeCast(obj)} style={{ width: 20, height: 20, margin: 5, cursor: 'pointer' }} />
                                                    </div>
                                                </td> */}
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                            }
                        </Table>
                        </Col>
                        </Row>
              {/*    <Row>
                    <Col lg={3}><label> Component</label></Col>
                    <Col lg={3}><label> Variety</label></Col>
                    <Col lg={2}><label>Allotment</label></Col>

                    <Col lg={2}><label>Required Quantity (in kgs)</label></Col>
                    <Col lg={1}><label>Action</label></Col>

                </Row>
                <div>

                    {inputFields.map((data, index) => {
                        const { seed, variety, quantity, varieties, allotedqty, issueqtyerror, issuecolor, issueqtyErr } = data;
                        return (
                            <Row key={index}>
                                <Col lg={3}>
                                    <div className="inputdiv">
                                        <div className="row_div_search">
                                            <div className="input_div" >
                                                <input type="type" style={{ margin: 0, background: 'transparent' }}
                                                    name="seed"
                                                    value={seed}
                                                    onChange={(evnt) => handleChange(index, evnt)}
                                                    placeholder="Component" />
                                                <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                                            </div>

                                        </div>
                                        {index == seedIndex &&
                                            <>
                                                {renderItemSuggestions()}
                                            </>
                                        }
                                    </div>
                                </Col>

                                <Col lg={3}>
                                    

                                    <div className="inputdiv">

                                        <select
                                            name="variety"
                                            value={variety}
                                            onChange={(evnt) => handleChange(index, evnt)}>
                                            {varieties.length != 0 &&
                                                <>
                                                    {varieties.map((item, index) => {
                                                        return (
                                                            <option value={item.Variety}>{item.Variety}</option>
                                                        )
                                                    })}

                                                </>}
                                        </select>


                                    </div>
                                </Col>
                                <Col lg={2}>

                                    <div className="inputdiv">
                                        <input
                                            value={allotedqty}
                                            disabled
                                        />

                                    </div>
                                </Col>
                                <Col lg={2}>

                                    <div className="inputdiv">
                                        <input
                                            name="quantity"
                                            value={quantity}
                                            onChange={(evnt) => handleChange(index, evnt)} />
                                        {issueqtyerror == false && issuecolor == "y" && <p style={{
                                            color: '#DF8D0F', textAlign: 'center', fontWeight: '700'
                                        }}>{issueqtyErr}</p>}
                                    </div>
                                </Col>
                                <Col lg={1}>
                                    <img src={Delete} onClick={() => handleDeleteRow(data, index)} style={{ width: 20, height: 20, marginTop: 40, marginLeft: 15, cursor: 'pointer' }} />
                                </Col>
                            </Row>
                        )
                    })}
                    {itemErr != '' && <p className="alert-message">{itemErr}</p>}

                </div> */}
               {/*  <Row>
                    <Col lg={4}>

                        <div className="row_table_div " onClick={() => addInputField()} style={{ width: 115, margin: 15, fontWeight: 'bold', float: 'left' }}>
                            <AiOutlinePlus size={20} style={{ margin: 10 }} />
                            <p>ADD ROWS</p>
                        </div>
                    </Col>
                </Row>  */}
                  {tableData.length != 0 && 
                <Row>
                    <Col lg={3}>
                    {requiredQuantityErr != '' && <p className="alert-message">{requiredQuantityErr}</p>}
                        <button className="add_button" disabled={disableSubmit} onClick={() => handleCreateData()}>
                        <p className="pcat_title"> Purchase</p></button>
                    </Col>
                </Row>
                   }
                <Modal show={showpopup} onHide={handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    dialogclassname="modal-content">
                    <Modal.Header closeButton>
                        <Modal.Title>{"Required Quantity Exceeds"}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div>
                                                                        <Table responsive>
                                                                            <thead>
                                                                            <th>Seed</th>
                                    <th>Type Name </th>
                                    <th>Allotted Quantity</th>
                                    <th>Purchase Quantity</th>
                                    <th>Pending Purchase </th>
                                                                            </thead>
                                                                            <tbody>
                                                                                {errorArray?.map((obj, index) => {
                                                                                    return (
                                                                                        <tr key={index}>
                                                                                        <td style={{lineBreak:"anywhere"}}>{obj.SeedName}</td>
                                                                                        <td>{obj.VarietyName}</td>
                                                                                        <td>{obj.Quantity}</td>
                                                                                        <td>{obj.PurchaseQuantity}</td>
                                                                                        <td>{obj.pendingPurchase}</td>
                                                                                        </tr>
                                                                                    )
                                                                                })}
                                                                            </tbody>
                                                                        </Table>
                                                                       
                                                                
                        </div>

                    </Modal.Body>
                   {/*  <Modal.Footer className="modal-footer" style={{marginTop:0}}>
                        <div className="row_div">
                            <button className="add_button" onClick={() => handleSubmitt(object_det)} style={{
                                padding: 12,
                                height: 45,
                                margin: 10
                            }}>
                                <p className="pcat_title">Allot Quantity</p></button>
                        </div>
                    </Modal.Footer> */}
                         
                </Modal>
            </Container>
            <ToastContainer />
        </Wrapper>
    )
}
export default PurchaseInitiate