import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Wrapper, } from "./styles";
import { UserContext } from "../../context";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { Row, Col,Container } from "react-bootstrap";

// DONE
const CopyRight = () => {
    const navigate = useNavigate();
    const { setSessionIsLoggedIn} = useContext(UserContext);
    const [passwordType, setPasswordType] = useState("password");
    const [isLoading, setIsLoading] = useState(false);
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password");
    }
    return (
        <Wrapper>
            {isLoading && <LoadingSpinner />}
            <>
            <Row className="footer_sec">
            <div className="text_center" >
          <a href='/privacypolicy'>Privacy Policy</a>
           </div>
              <div className="text_center" style={{marginTop:4}}>
          <p>Copyright © 2022 , Department of Agriculture, Govt. of Jharkhand. All Rights Reserved</p>
          <p>Powered by <a href="https://www.settlemint.com/" target="_blank" style={{color: '#63D669'}}>SettleMint</a></p>
        </div>
            </Row>
            </>
        </Wrapper>
    );
};

export default CopyRight;
