import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Wrapper, } from "./styles";
import { UserContext } from "../../context"
import { FaRegEye, FaEyeSlash } from "react-icons/fa"
import { LoadingSpinner } from "../LoadingSpinner/index";
import { Row, Col,Container } from "react-bootstrap";
import SettleMint_logo from "../../images/SettleMint_logo.svg";
import API_Auth from "../../API_Auth";
import BannerFarmer from "../../images/banner-farmer.svg"
import Paddy from "../../images/paddy.jpeg"
import Carousel from 'react-bootstrap/Carousel';
import Maize from "../../images/maize.jpg";
import Wheat from "../../images/wheat.jpg";
import Mustard from "../../images/mustard.jpg";

import Jowar from "../../images/jowar.jpg";
import Bajra from "../../images/bajra.jpg";
import Ragi from "../../images/ragi.jpg";
import Arhar from "../../images/arhar.jpg";
import Gram from "../../images/gram.jpg";
import Groundnut from "../../images/groundnut.jpg";
import Soyabean from "../../images/soyabean.jpg";
import Sesame from "../../images/sesame.jpg";
import Niger from "../../images/niger.jpg";
import Barley from "../../images/barley.jpg";
import Mungbean from "../../images/moong.jpeg";
import Uradbean from "../../images/uradbean.jpg";

import Horsegram from "../../images/horsegram.jpg";
import Lentil from "../../images/lentil.jpg";
import Pea from "../../images/peas.jpg";
import Berseem from "../../images/berseem.jpg";
import Toria from "../../images/toria.jpg";
import Lineseed from "../../images/lineseed.jpg";
import Safflower from "../../images/safflower.jpg";
import Sunflower from "../../images/sunflower.jpg";
import Rajma from "../../images/rajma.jpeg";
import Wingedbeans from "../../images/wingedbeans.jpg";
import Kankoda from "../../images/kankoda.jpg";
import Chenopadiu from "../../images/chenopodium.jpg";

import CopyRight from "../../elements/CopyRights";
import Table from "react-bootstrap/Table";
// DONE
const FarmersCorner = () => {
    const navigate = useNavigate();
    const { setSessionIsLoggedIn, setSessionLoggedRole, setUserEmail, setSessionLoggedUser, setLoggedUserName } = useContext(UserContext);
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);
    const [seedData, setseedData] = useState([{seedName:"Paddy",
    seedDiscription:"BVD-111, BVD-203, BVS-1, IR-64 (drt-1), TRC-2012-5, 27P 31, PAC-801, Arize Tej, DRR Dhan 50, DRR Dhan 54, BVD-109, BVD-110, Birsamati, Naveen, Abhishekh, Lalat, MTU-1001, IR-64, Sahbhagi, MTU-7029, Rajendra Mansuri, Ranjendra Sweta, Improved Sambha Mansuri (RP Bio 226), DRRH-2, DRRH-3, MTU-1001, CR-Dhan40, PNPH-24, CR Dhan-202, CR Dhan-305, Arize-6444, BIO-799, Bina Dhan-11, SwarnaShakti, CR-Dhan-320",
    seedImage:Paddy},
    {seedName:"Maize",
    seedDiscription:"Vivek Hybrid 27, BVM-2, Vivek Hybrid-45, DHM-117, Malviya Hybrid Makka, PMH-3, Suwan Contribute, Bio-9682, Sweet Corn-Nuzi 260, HQPM-1, Birsa Vikas Makka-1, Birsa Vikas Makka-2, Suwan Composite, D-994©, Vivek-43(H), Vivek-7, HM-9 (H), Malivya Makka-2 (H), HM-11, HQPM-5, NMH-803, NMH-920, NMH-713, Bio-9544, HQPM-7, Yuvraj Gold, DMRH-1301, HQPM-2, Shaktiman-4, DHM-121",
    seedImage:Maize},
    {seedName:"Wheat",
    seedDiscription:"DBW-187, HD-2967, K-1006, HD-3086, K-307, PBW-723, PBW-725,K-1317, HD-3171, HA-1612,H1-1563, DBW-107, Birsa Gehun-4, HD-3118, MACS-6222, DBW-252, HI-1621",
    seedImage:Wheat},
    {seedName:"Indian Mustard",
    seedDiscription:"NRCHB-101, DRMR 150-35, NRCDR-2, Birsa Bhabha Mustard-1, TBM-204, Pusa Mustard-31, Pusa Mustard-32, Shivani , Pusa Mustard-30, Pusa Mustard-25, Shivani , Pusa Agrani, Pusa Bold, PM-26, Pusa Mahak (JD-6), PM-29, PM-28",
    seedImage:Mustard},
    {seedName:"Jowar",
    seedDiscription:"CSV 20, CSV 37, CSH 39, RVJ-1862, HTJH-3208, HTJH-3301, CSV-16-6/20, CSH-16, CSV-15, CSV-17, CSV-23, CSV-27",
    seedImage:Jowar},  
    {seedName:"Bajra",
    seedDiscription:"HHB-299, PB-18-52, PB 17-20, PB 1705, MP 7878, Kaveri Super Boss, KBH 108, ASG-3, PB-106, GHB-558, GHB-526, 7688",
    seedImage:Bajra},    
    {seedName:"Ragi",
    seedDiscription:"A404, BM-2, VL (Marua) 379, GPU-28, GPU-67, GPU-45, VL-149, VL (Marua) 376, Chattishgarh Ragi-2, BG-1, Birsa Marua - 3, JWM-",
    seedImage:Ragi},  
    {seedName:"Arhar",
    seedDiscription:"PA-203, BA-1, Asha (Icpl 87119), JKM 189, UPAS-120, Bahar, Narendra Arhar-1, UPAS-120, Malviya-13, NDA-2, MA 3, NDA - 3, Rajendra Arhar - 1, MA -6, LRG - 41, Biras Arhar - 2",
    seedImage:Arhar},  
    {seedName:"Gram",
    seedDiscription:"GLK 26155 (L555), GNG 1958, GNG 1969, BG 3022, CSJ 515, BG 3043, GNG 2207, Birsa Chana-3, GNG 2144, Pant Gram-5, JG-14, Pusa-372, KAK-2, PG-186, Pusa-256, Haryana Kabuli Chana-2 (HK-94-134), Pusa Kabuli - 1003, HK-4 (HK05-169), JAKI-9218, PUSA-3043",
    seedImage:Gram},    
    {seedName:"Groundnut",
    seedDiscription:"Birsa Bold, Birsa Groundnut-3 (BG-4), Girnar - 3, TG-38, GPBD-3, ICGV 9431114, K-6, TG-51, TG-37A, Dharni (2013), Phule Morna (KDG-123)",
    seedImage:Groundnut},  
    {seedName:"Soyabean",
    seedDiscription:"RSC 10-46, RSC 11-07, Kota Soya - 1 (RKS - 113), NRC SL-1, RSC 10-46, AMS 2014-1, Birsa Soyabean-1, Birsa Safed Soyabean-2, JS-335, JS-9560, JS-9752, Birsa Soyabean-3",
    seedImage:Soyabean},  
    {seedName:"Sesame",
    seedDiscription:"TKG-306, SWB-32-10, Jawahar Til, Kanke Sard, Sekhar, N 25, RT 346, RT-351",
    seedImage:Sesame},    
    {seedName:"Niger",
    seedDiscription:"Niger	Birsa Niger-3, Pooja-1, IGPN 2004 (Phule Karala - 1), Utkal Niger 150, DNS 4, Birsa Niger -1, Birsa Niger - 2, BNS - 10 (Puja)",
    seedImage:Niger},
    {seedName:"Barley",
    seedDiscription:"RD-2552, DWRB-137, BH 946, RD 2899, Lakhan, K 603, K - 508, Haritma, Ratna, NDB-1173, RD 2794, Mahamana (MUB-113), Jyoti",
    seedImage:Barley},  
    {seedName:"Mungbean",
    seedDiscription:"IPM 205-7 (Virat), IPM 02-3, GM - 6, Pusa Vishan, Malviya Jan Chetna (HUW-12), TM 99-37, IPM 99-125, Pusa Moong -3, SML-668, Pant Moong-2, K-851, MH2-15, HUM-12, Malviya Jagriti, Pant Moong 4, Narendra Moong 1, Sunaina , PDM 139, HUM-1, Meha, Pant Moong 5, TMB 37, HUM-16, Surya, Pant Moong-6, MH-1142",
    seedImage:Mungbean},    
    {seedName:"Uradbean",
    seedDiscription:"WBU 109 (Sulata), Mulundra Urd 2(KPU 405), KUG 479, Birsa Urad-1, Pant - U 19, T-9, Sekhar-2, Pant-U-31, Sekhar-3, IPU 94-1(Uttara), Pant U30, WBU 108, KU 92-1 (Azad Urd 1), Birsa Urad - 2",
    seedImage:Uradbean},  
    {seedName:"Horsegram",
    seedDiscription:"Birsa Kulthi-1, VLG-19, VLG-15, AK-21, AK-42, GHG-45, GHG-5, Indra Kulthi - 1, Bilasa, Alakh Kulthi, Sabri Kulthi",
    seedImage:Horsegram},  
    {seedName:"Lentil",
    seedDiscription:"KLS-218, KLS-09-03 (Krish), PL-406, PL-639, Varun (T-59), DPL-62, HUL 57, K-75, V L Masoor -129, Moitree WBL -77, NDL - 1, WBL -58, PL-8, IPL-316, IPL-220, L 4727",
    seedImage:Lentil},    
    {seedName:"Pea",
    seedDiscription:"DDR-23 (Pusa Prabhat), Malviya Mator -15 (HUDP-15), Malviya Mator-2, VL Mator-42, Swarna Tripti, SKNP-04-09, Dantewada Field Pea, IPFD 12-2",
    seedImage:Pea},  
    {seedName:"Berseem",
    seedDiscription:"Maize - African Tall, J - 1006, Oat-RO-19, JHO-99-1, Berseem-BL-42, JBSC-1, Napier-CO (BN)-5, Others",
    seedImage:Berseem},  
    {seedName:"Toria",
    seedDiscription:"Tapeshwari, Bhawani, Pant Toria-508, PT-303, Panchali, PT-2002-25 (Uttara)",
    seedImage:Toria},    
    {seedName:"Lineseed",
    seedDiscription:"Priyam, Divya, Birsa Tisi-1, Sekhar-2, JLS-67, Ruchi",
    seedImage:Lineseed},  
    {seedName:"Safflower",
    seedDiscription:"A-300, NARI-38, Akshagiri, 59-2-1, NARI-57",
    seedImage:Safflower},    
    {seedName:"Sunflower",
    seedDiscription:"DRSF-113, DRSF-108, KBSH-44, KBSH-53, DCS-107, Kaveri Champ",
    seedImage:Sunflower},
    {seedName:"Rajma",
    seedDiscription:"Arun, BL Rajma-125, RBL-6",
    seedImage:Rajma},
    {seedName:"Winged Bean",
    seedDiscription:"RMDWB-1, IWB-1",
    seedImage:Wingedbeans},
    {seedName:"Kankoda",
    seedDiscription:"Indira Kankoda-1, Indira Kankoda-2",
    seedImage:Kankoda},
    {seedName:"Chenopadiu",
    seedDiscription:"EC 507740",
    seedImage:Chenopadiu}
      ]);
    const [passwordErr, setPasswordErr] = useState("");
    const [responseErr, setResponseErr] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [passwordType, setPasswordType] = useState("password");

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password");
    }


    const handleInput = (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        if (name === "email") setUserName(value);
        if (name === "password") setPassword(value);
    };
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };
    return (
        <Wrapper>
            {isLoading && <LoadingSpinner />}
               <Row className="banner_row">
                <Col lg={12}>
                <img src={BannerFarmer} alt="" className="banner_image"/>
                </Col>
                </Row>
                <Container>
                <Row>
                <Col lg={12} className="text_center margin_top_btm" >
                <h1 class="pseudo_border">Some of the key seed varieties for paddy are:</h1>
                </Col>
                </Row>
                <Row className="margin_top_btm">
                <Col lg={12} className="margin_btm_child">
                <h3 >MTU 1001 (Vijetha)</h3>
   <p>MTU 1001, also known as Vijetha,  was developed in 1995 from a cross between MTU5249 Vajram and MTU7014. 
    It can be sown in both Rabi and Kharif seasons and takes around 135-140 days to mature in the Kharif season
     and 120-125 days in the Rabi season. Its potential yield is around 6.0 tonne per hectare for the Kharif season
      and 7.0 tonne per hectare for the Rabi season. It is resistant to Brown Planthopper (BPH), moderately resistant 
      to Gall Midge, White Back Planthopper, Stemborer, Leafroller (Cnaphalocrocis Medinalis), and Blast. </p>
    </Col>
    <Col lg={12} className="margin_btm_child">
    <h3>MTU 1010 (Cotton Dora Sannalu)</h3>
            <p>MTU 1010, also known as Cotton Dora Sannalu, is a high-yielding mega-type of rice grown extensively in India and was developed in 1999 from a cross between MTU ‑ 2077 Krishnaveni and IR 64. It is usually sown in Rabi season and takes around 120-125 days to mature. Its potential yield is around 7.0 tonne per hectare for the Rabi season. It is highly adaptable, Tolerant to BPH and Blast, and is a widely cultivated type with stable yields</p>
    </Col>
    <Col lg={12} className="margin_btm_child">
    <h3>MTU 7029 (Swarna)</h3>
              <p>MTU 7029, also known as Swarna, was developed in 1982 from a cross between Vasista and Mahsuri. It is usually sown in the Kharif season and takes around 140-150 days to mature. Its potential yield is around 6.5 tonnes per hectare for the Rabi season. It Yields better at low nitrogen levels, possesses seed dormancy, is a highly stable type, is highly adaptable, and is BLB tolerant. </p>
        </Col>
    <Col lg={12} className="margin_btm_child">
    <h3>DRRH-3</h3>
<p>DRRH 3 is the first hybrid with a medium slender (MS) grain type. It matures in 130-135 days and gives a grain yield 
    of 6.0 6.5 t/ha under normal irrigated conditions. It has higher milling ({">"}71%), head rice recovery ({">"}60%), 
    L/B ratio (2.61), intermediate amylose content (24%), and gel consistency of 63 mm indicating good cooking quality.
     It can be cultivated in areas with assured irrigation, where fine-grained rice varieties are preferred and widely
      cultivated. It is resistant to neck blast and moderately resistant to leaf blast, rice tungro, 
    brown spot diseases and tolerant to white-backed planthopper. It has strong culm, and thus lodging resistant 
    with significantly superior performance even under lower doses of N (40 kg N/ha) indicating its higher
     nitrogen use efficiency.  </p>
 </Col>
    <Col lg={12} className="margin_btm_child">
    <h3>IR-64</h3>
<p>In 1985, the IRRI-developed indica type IR64 was released in the Philippines.
     In addition to its high yield of around 8 tonnes per hectare, early maturity,
      and disease resistance, it had excellent cooking quality, matching that of the best varieties available. 
      Due to its multiple characteristics desired by farmers and consumers, its rapid spread, and its wide cultivation,
       IR64 became known as a “mega variety”. Other qualities that help IR64 stand out is that it has one of the highest 
       levels of head rice recovery at milling, and was one of the first varieties with multi-pest and disease 
       tolerances to blast and bacterial blight diseases and brown planthopper infestation.   </p>

 </Col>
    <Col lg={12} className="margin_btm_child">
    <h3>Sahbhagi</h3>
<p>Sahbhagi Dhan (IR74371-70-1-1) is a conventionally bred, drought-tolerant rice released for cultivation in India, in 2010. Under normal conditions, Sahbhagi Dhan produces 4-5 tonnes per hectare, whereas other varieties yield about 2.5 tonnes; under severe drought conditions, it produces 1–2 tonnes per hectare, while other high-yielding varieties produce nothing at all. With respect to irrigation, it requires two irrigations compared to the four required by other, traditional varieties.  </p>

<p>Sahbhagi Dhan is an early maturing type that is ready after 105 days, 
    as compared to the usual 120–150 days for other medium and long-duration varieties.
     This allows farmers to plant the next component earlier (usually a rabi component such as wheat), which in turn gives 
     them enough time to plant three crops in a year. Moreover, Sahbhagi Dhan produces a higher straw yield, both in 
     terms of quantity and quality. Since the majority of the farmers living in drought-prone ecologies are 
     socio-economically underprivileged, Sahbhagi Dhan, along with other drought-tolerant varieties, can serve as one of
      the most viable and deliverable 
    technologies for eradicating poverty from these ecologies dependent on rainfed rice.
</p>
 </Col>
     </Row>
            <Row className="margin_top_btm">
                <Col lg={12}>
                <Table bordered responsive>
      <thead>
        <tr>
          <th>Recommended Seed Varieties</th>
          <th style={{ borderWidth: 0}}></th>
          <th style={{ borderLeftWidth: 0}}></th>
        </tr>
      </thead>
      <tbody>
      {seedData?.map((obj, index) => {
                                    return (
                                        <>
      
        <tr key={index}>
          <td style={{    width: '25%'}}><img className="img_thumbnail" src={obj.seedImage}/></td>
          <td>{obj.seedName}</td>
          <td>{obj.seedDiscription}</td>
        </tr>
        </>
);
})}
      </tbody>                         
    </Table>
                </Col>
                </Row>
            </Container>
            <CopyRight/>
        </Wrapper>
    );
};

export default FarmersCorner;
