import {
  AUTH_LOGIN_API_URL,
  AUTH_ROLE_API_URL,
  ADD_NEW_ROLE_API_URL,
  ADD_UPDATE_ROLE_API_URL,
  ADD_DELETE_ROLE_API_URL,
  AUTH_DESIGNATION_API_URL,
  ADD_NEW_DESIGNATION_API_URL,
  ADD_UPDATE_DESIGNATIOn_API_URL,
  ADD_DELETE_DESIGNATION_API_URL,
  AUTH_DEPARTMENT_API_URL,
  ADD_NEW_DEPARTMENT_API_URL,
  ADD_UPDATE_DEPARTMENT_API_URL,
  ADD_DELETE_DEPARTMENT_API_URL,
  AUTH_VARIETY_API_URL,
  ADD_VARIETY_BY_ID_API_URL,
  ADD_VARIETY_API_URL,
  ADD_VARIETY_UPDATE_API_URL,
  ADD_DELETE_VARIETY_API_URL,
  AUTH_USERS_API_URL,
  AUTH_SELECT_DISTRICTS_API_URL,
  ADD_USER_BY_ID_API_URL,
  ADD_USER_API_URL,
  ADD_UPDATE_USER_API_URL,
  ADD_DELETE_USER_API_URL,
  AUTH_DISPATCH_BY_PURCHASEID,
  AUTH_SEEDS_API_URL,
  ADD_SEED_API_URL,
  ADD_SEED_UPDATE_API_URL,
  ADD_DELETE_SEED_API_URL,
  AUTH_SEED_TYPE_API_URL,
  AUTH_VARIETY_BY_SEED_TYPE_API_URL,
  AUTH_FORECAST_API_URL,
  AUTH_FORECAST_DETAILS_API_URL,
  AUTH_ADD_FORECAST_API_URL,
  AUTH_SELECT_SUPPLIERS_API_URL,
  AUTH_INITIATE_PURCHASE_API_URL,
  AUTH_SINGLE_INITIATE_PURCHASE_API_URL,
  AUTH__PURCHASE_LIST_API_URL,
  AUTH__PURCHASE_LIST_DISTRIBUTOR_ALLOTMENT_API_URL,
  AUTH_FORECAST_DISTRICT_WISE_API_URL,
  AUTH_DEMAND_REQUEST_SUPPLIER_LIST,
  AUTH_DISPATCH_TRANSIT_DETAILS_API_URL,
  AUTH__TRANSPORT_DETAILS_API_URL,
  AUTH_DISPATCH_BY_SUPPLIER,
  AUTH__RECEIVED_SEED_QTY_API_URL,
  AUTH__GET_DISTRIBUTORS_BY_URL,
  AUTH__GET_DISTRIBUTORS_BY_DISTRICTID_URL,
  AUTH__GET_DISTRIBUTORS_BY_ID_BY_URL,
  AUTH_SEED_INSERT_ALLOTMENT,
  AUTH_STOCK_REGISTER,
  AUTH__GET_DISTRIBUTOR_USER_BY_ID_BY_URL,
  AUTH_ADD_FARMER,
  AUTH__GET_DISTRIBUTOR_SEED_HISTORY_BY_URL,
  AUTH__GET_DISTRIBUTOR_SEEDS,
  AUTH__GET_FARMER_DETAILS,
  AUTH_INSERT_FARMER_DISTRIBUTION,
  AUTH__GET_DISTRIBUTION_WISE_BY_URL,
  AUTH__GET_DISTRIBUTION_SEED_WISE_BY_URL,
  AUTH__GET_DISTRIBUTION_VARIETY_WISE_BY_URL,
  AUTH__GET_DISTRIBUTION_DISTRICT_WISE_BY_URL,
  AUTH__GET_DASHBOARD_SEED_WISE_BY_URL,
  AUTH__GET_DISTRIBUTION_SEASON_WISE_BY_URL,
  AUTH__GET_DISTRIBUTION_WISE_DISTRICT_ALL,
  AUTH__GET_DISTRIBUTION_WISE_DISTRICT_SEED,
  AUTH__GET_DISTRIBUTION_WISE_DISTRICT_VERITY,
  AUTH__GET_DISTRIBUTOR_DAO_WITH_SAME_DISTRICTS,
  AUTH__GET_SEASONS_API_URL,
  ADD_DELETE_SEASON_API_URL,
  AUTH_INSERT_SEASON_API_URL,
  AUTH_CHECK_ALLOTEMENT_SEED_API_URL,
  AUTH__GET_ALL_SEED_REQUEST_FOR_DAO,
  AUTH__GET_IND_SEED_REQUEST_DAO,
  AUTH__GET_APPROVEOR_REJECT_DAO,
  AUTH__GET_TRANSFER_REQUESTS_BY_DISTRIBUTOR,
  AUTH__ADD_TRANSFER_REQUESTS_BY_DISTRIBUTOR,
  AUTH__GET_RECEIVED_RANSFER_REQUESTS_BY_DISTRIBUTOR,
  AUTH__SEED_RECEIVER_FOR_DISTRIBUTOR,
  AUTH__VERIFY_OTP_WEB,
  UPDATE_USER_PASSWORD_API_URL,
  AUTH__GET_DISTRIBUTION_WISE_DISTRICT_SEASON,
  ADD_UPDATE_PURCHASER_TYPE_API_URL,
  UPLOAD_IMAGE_API_URL,
  AUTH_UPDATE_FORECAST_BY_DAO_API_URL,
  AUTH_SELECT_DISTRICT_WISE_SEED_API_URL,
  AUTH_SELECT_DISTRICT_SEED_WISE_SEED_API_URL,
  AUTH__GET_DASHBOARD_SEED_SEASON_WISE_BY_URL,
  AUTH_SHOW_FORCASTS_AO_ALL_API_URL,
  AUTH_SHOW_DISTRIBUTION_WISE_ALLSEARCH_API_URL,
  AUTH_SHOW_DISTRICT_WISE_ALLSEARCH_API_URL,
  AUTH_SHOW_SELECT_DISTRICT_WISE_FARMER_API_URL,
  AUTH_SHOW_SELECTDISTRICT_WISE_FARMER_DISTRIBUTION_API_URL,
  AUTH__GET_FARMER_WITH_ROP_SEASONDETAILS,
  ADD_SEEDS_BY_DAO,
  GET_SEEDS_BY_DAO,
  UPDATE_FORECAST_SEEDS_BY_DAO,
  GET_AO_SELECT_PENDING,
  GET_AO_SELECT_APPROVE,
  GET_AO_PENDING_VIEW,
  GET_AO_UPDATE_FORECAST_VIEW,
  GET_FORECAST_HISTORY_VIEW,
  GET_SEEDS_BY_APPROVE_AO,
  GET_DISTRICT_WISE_FARMER1,
  GET_ALL_FARMER_DETAIL,
  GET_ALL_DISTRIBUTION_DETAIL,
  GET_AO_APPROVE_QTY_SUPPLIER,
  GET_TRANSACTION_HASH_DETAIL,
  GET_REQUEST_TESTING_URL,
  AUTH_SHOW_DISTRICT_LEVEL_DATA,
  GET_UOM_BASE_URL,
  AUTH_GET_FARMER_REGISTRATION_OTP,
  AUTH_GET_FARMER_REGISTRATION_VERIFY_OTP,
  AUTH_FARMER_NEW_REGISTRATION,
  AUTH_GET_BLOCK_BY_DISTRICT_ID,
  AUTH_CHECK_ADHAR_NUMBER,
  GET_ACTIVITIES,
  GET_SCHEMES,
  GET_APPLICANTS,
  AUTH_FARMER_NEW_APPLICATION,
  LIST_OF_FARMERS_BY_DHO,
  UPDATE_APPLICATION_DAO,
  LIST_OF_FARMERS_BY_AO,
  UPDATE_APPLICATION_AO,
  GET_FARMER_APPLICATIONS_BY_ADHAR,
  GET_VIEW_APPLICATIONS_BY_APPLICATION_NO,
  INSERT_ACTIVITY,
  UPDATE_ACTIVITY,
  DELETE_ACTIVITY,
  INSERT_SCHEME,
  UPDATE_SCHEME,
  DELETE_SCHEME
} from "./config";
import axios from "axios";

const apiSettings = {
  loginUser: async (mode, emailID, password) => {
    const result = await (
      await fetch(
        AUTH_LOGIN_API_URL +
          "?mode=" +
          mode +
          "&emailID=" +
          emailID +
          "&password=" +
          password,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },

  getRoles: async () => {
    const result = await (
      await fetch(AUTH_ROLE_API_URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  addRole: async (roleName) => {
    const result = await (
      await fetch(ADD_NEW_ROLE_API_URL + "&role=" + roleName, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  updateRole: async (roleName, roleId) => {
    const result = await (
      await fetch(
        ADD_UPDATE_ROLE_API_URL + "&role=" + roleName + "&pid=" + roleId,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  deleteRole: async (roleId) => {
    const result = await (
      await fetch(ADD_DELETE_ROLE_API_URL + "&pid=" + roleId, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },

  //Designations

  getDesignations: async () => {
    const result = await (
      await fetch(AUTH_DESIGNATION_API_URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  addDesignation: async (name) => {
    const result = await (
      await fetch(ADD_NEW_DESIGNATION_API_URL + "&userDesignation=" + name, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  updateDesignation: async (name, id) => {
    const result = await (
      await fetch(
        ADD_UPDATE_DESIGNATIOn_API_URL +
          "&userDesignation=" +
          name +
          "&pid=" +
          id,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  deleteDesignation: async (id) => {
    const result = await (
      await fetch(ADD_DELETE_DESIGNATION_API_URL + "&pid=" + id, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },

  //departments

  getDepartments: async () => {
    const result = await (
      await fetch(AUTH_DEPARTMENT_API_URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  addDepartment: async (name) => {
    const result = await (
      await fetch(ADD_NEW_DEPARTMENT_API_URL + "&department=" + name, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  updateDepartment: async (name, id) => {
    const result = await (
      await fetch(
        ADD_UPDATE_DEPARTMENT_API_URL + "&department=" + name + "&pid=" + id,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  deleteDepartment: async (id) => {
    const result = await (
      await fetch(ADD_DELETE_DEPARTMENT_API_URL + "&pid=" + id, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },

  //variety

  getVariety: async () => {
    const result = await (
      await fetch(AUTH_VARIETY_API_URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  getVarietyById: async (id) => {
    const result = await (
      await fetch(ADD_VARIETY_BY_ID_API_URL + "&pid=" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  addVariety: async (
    cropName,
    variety,
    seedType,
    perAcreLimit,
    season,
    uom
  ) => {
    const result = await (
      await fetch(
        ADD_VARIETY_API_URL +
          "&cropName=" +
          cropName +
          "&variety=" +
          variety +
          "&verityType=" +
          seedType +
          "&perAcreLimit=" +
          perAcreLimit +
          "&season=" +
          season +
          "&uom=" +
          uom,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },

  updateVariety: async (
    cropName,
    variety,
    seedType,
    perAcreLimit,
    season,
    id,
    uom
  ) => {
    const result = await (
      await fetch(
        ADD_VARIETY_UPDATE_API_URL +
          "&cropName=" +
          cropName +
          "&variety=" +
          variety +
          "&verityType=" +
          seedType +
          "&perAcreLimit=" +
          perAcreLimit +
          "&season=" +
          season +
          "&pid=" +
          id +
          "&uom=" +
          uom,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },

  deleteVariety: async (id) => {
    const result = await (
      await fetch(ADD_DELETE_VARIETY_API_URL + "&pid=" + id, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  //users
  getUsers: async () => {
    const result = await (
      await fetch(AUTH_USERS_API_URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  getDistricts: async () => {
    const result = await (
      await fetch(AUTH_SELECT_DISTRICTS_API_URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  getSuppliers: async (role) => {
    const result = await (
      await fetch(AUTH_SELECT_SUPPLIERS_API_URL + "&role=" + role, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  getUserById: async (id) => {
    const result = await (
      await fetch(ADD_USER_BY_ID_API_URL + "&pid=" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  addUserDetail: async (
    firstName,
    lastName,
    contactNumber,
    email,
    userName,
    dep,
    design,
    role,
    district,
    block,
    password,
    profilePic,
    purchaser,
    category
  ) => {
    const result = await (
      await fetch(
        ADD_USER_API_URL +
          "&userFname=" +
          firstName +
          "&userLname=" +
          lastName +
          "&userEmailId=" +
          email +
          "&userCno=" +
          contactNumber +
          "&userDesignation=" +
          design +
          "&role=" +
          role +
          "&profilePic=" +
          profilePic +
          "&userID=" +
          userName +
          "&password=" +
          password +
          "&department=" +
          dep +
          "&district=" +
          district +
          "&block=" +
          block +
          "&Purchaser=" +
          purchaser +
          "&category=" +
          category,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  updateUserDetail: async (
    firstName,
    lastName,
    contactNumber,
    email,
    userName,
    dep,
    design,
    role,
    district,
    block,
    password,
    profilePic,
    userid,
    purchaser,
    category
  ) => {
    const result = await (
      await fetch(
        ADD_UPDATE_USER_API_URL +
          "&userFname=" +
          firstName +
          "&userLname=" +
          lastName +
          "&userEmailId=" +
          email +
          "&userCno=" +
          contactNumber +
          "&userDesignation=" +
          design +
          "&role=" +
          role +
          "&profilePic=" +
          profilePic +
          "&userID=" +
          userName +
          "&password=" +
          password +
          "&department=" +
          dep +
          "&pid=" +
          userid +
          "&district=" +
          district +
          "&block=" +
          block +
          "&Purchaser=" +
          purchaser +
          "&category=" +
          category,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },

  deleteUser: async (id) => {
    const result = await (
      await fetch(ADD_DELETE_USER_API_URL + "&pid=" + id, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  //seeds
  getSeeds: async () => {
    const result = await (
      await fetch(AUTH_SEEDS_API_URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  addSeed: async (cropName, seedType, season) => {
    const result = await (
      await fetch(
        ADD_SEED_API_URL +
          "&cropName=" +
          cropName +
          "&variety=" +
          seedType +
          "&season=" +
          season,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  updateSeed: async (cropName, seedType, season, id) => {
    const result = await (
      await fetch(
        ADD_SEED_UPDATE_API_URL +
          "&cropName=" +
          cropName +
          "&variety=" +
          seedType +
          "&season=" +
          season +
          "&pid=" +
          id,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  deleteSeed: async (id) => {
    const result = await (
      await fetch(ADD_DELETE_SEED_API_URL + "&pid=" + id, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },

  getSeedType: async () => {
    const result = await (
      await fetch(AUTH_SEED_TYPE_API_URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  getVarietyBySeed: async (cropName) => {
    const result = await (
      await fetch(AUTH_VARIETY_BY_SEED_TYPE_API_URL + "&cropName=" + cropName, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  //seasons

  getSeasons: async () => {
    const result = await (
      await fetch(AUTH__GET_SEASONS_API_URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  addSeason: async (year, season) => {
    const result = await (
      await fetch(
        AUTH_INSERT_SEASON_API_URL + "&year=" + year + "&season=" + season,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  deleteSeason: async (id) => {
    const result = await (
      await fetch(ADD_DELETE_SEASON_API_URL + "&pid=" + id, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  getForeCastData: async (districtID, season) => {
    const result = await (
      await fetch(
        AUTH_FORECAST_API_URL +
          "&DistrictID=" +
          districtID +
          "&season=" +
          season,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getForeCastDetailsBYID: async (forecastID) => {
    const result = await (
      await fetch(AUTH_FORECAST_DETAILS_API_URL + "&forcastID=" + forecastID, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  addForecast: async (year, season, userId, districtID, array, status) => {
    const result = await (
      await fetch(
        AUTH_ADD_FORECAST_API_URL +
          "&year=" +
          year +
          "&season=" +
          season +
          "&creatorID=" +
          userId +
          "&DistrictID=" +
          districtID +
          "&detailData=" +
          array +
          "&status=" +
          status,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  /*   initiateSinglePurchase:async(year, season, inputFields[0]["seedid"],inputFields[0]["varietyid"] ,inputFields[0]["quantity"],supplierId,
 payment_type, chequeno, draftImg, userId)
  =>{

  }, */

  initiateSinglePurchase: async (
    year,
    season,
    cropId,
    varietyId,
    quantity,
    supplierId,
    payment_type,
    chequeno,
    draftImg,
    userId
  ) => {
    const result = await (
      await fetch(
        AUTH_SINGLE_INITIATE_PURCHASE_API_URL +
          "&year=" +
          year +
          "&season=" +
          season +
          "&cropID=" +
          cropId +
          "&verityID=" +
          varietyId +
          "&demandQuantity=" +
          quantity +
          "&userID=" +
          userId +
          "&status=" +
          "open" +
          "&supplystatus=" +
          "" +
          "&supplierID=" +
          supplierId +
          "&paymentType=" +
          payment_type +
          "&chequeOrDraftno=" +
          chequeno +
          "&draftImg=" +
          draftImg,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  initiateMultiPurchase: async (
    year,
    season,
    supplierId,
    payment_type,
    chequeno,
    draftImg,
    userId,
    cropId,
    varietyId,
    quantity
  ) => {
    const result = await (
      await fetch(
        AUTH_INITIATE_PURCHASE_API_URL +
          "&year=" +
          year +
          "&season=" +
          season +
          "&cropID=" +
          cropId +
          "&verityID=" +
          varietyId +
          "&demandQuantity=" +
          quantity +
          "&userID=" +
          userId +
          "&status=" +
          "open" +
          "&supplystatus=" +
          "" +
          "&supplierID=" +
          supplierId +
          "&paymentType=" +
          payment_type +
          "&chequeOrDraftno=" +
          chequeno +
          "&draftImg=" +
          draftImg,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
    /*  const result = await (
      await fetch(AUTH_INITIATE_PURCHASE_API_URL + '&year=' + year + '&season=' + season + '&cropID=' + cropId ++'&verityID=' +
       varietyId +'&demandQuantity=' + quantity +
        "&userID=" + userId + '&status=' + 'open' + '&supplystatus=' + '' + '&supplierID=' + supplierId
        + '&paymentType=' + payment_type + '&chequeOrDraftno=' + chequeno + '&draftImg=' + draftImg, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          }
        })
    ).json();
    return result */
  },

  checkallotement: async (year, season, userId, varietyId, seedID) => {
    //http://dev.jharkhandseed.com:3022/jseed/purchageProcess?mode=CHECKALLOTMENTQUANTITY&year=2022&season=Rabi&userID=21&verityID=11&cropID=3
    const result = await (
      await fetch(
        AUTH_CHECK_ALLOTEMENT_SEED_API_URL +
          "&year=" +
          year +
          "&season=" +
          season +
          "&userID=" +
          userId +
          "&verityID=" +
          varietyId +
          "&cropID=" +
          seedID,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getPurchaseList: async (userId, season) => {
    const result = await (
      await fetch(
        AUTH__PURCHASE_LIST_API_URL + "&userID=" + userId + "&season=" + season,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getPurchaseListbByAllotment: async (userId, season) => {
    const result = await (
      await fetch(
        AUTH__PURCHASE_LIST_DISTRIBUTOR_ALLOTMENT_API_URL +
          "&distributorID=" +
          userId +
          "&season=" +
          season,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getForcastProcessDistrictWise: async (seedid) => {
    console.log(
      "API",
      AUTH_FORECAST_DISTRICT_WISE_API_URL + "&seedID=" + seedid
    );
    const result = await (
      await fetch(AUTH_FORECAST_DISTRICT_WISE_API_URL + "&seedID=" + seedid, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  getDemandReuestSupplier: async (supplierID, season) => {
    console.log(
      "API",
      AUTH_DEMAND_REQUEST_SUPPLIER_LIST + "&supplierID=" + supplierID
    );
    const result = await (
      await fetch(
        AUTH_DEMAND_REQUEST_SUPPLIER_LIST +
          "&supplierID=" +
          supplierID +
          "&season=" +
          season,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },

  getDispacthByTransitDetails: async (dispatchID) => {
    const result = await (
      await fetch(
        AUTH_DISPATCH_TRANSIT_DETAILS_API_URL + "&dispatchID=" + dispatchID,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getTransactionDetails: async (purchaseID) => {
    const result = await (
      await fetch(AUTH__TRANSPORT_DETAILS_API_URL + "&pid=" + purchaseID, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },

  getDispacthByPurchaseId: async (purchaseID) => {
    const result = await (
      await fetch(AUTH_DISPATCH_BY_PURCHASEID + "&pid=" + purchaseID, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },

  getDispatchBySupplier: async (
    year,
    season,
    cropID,
    supplierID,
    transportDetail,
    purchaseID,
    status,
    supplystatus
  ) => {
    const result = await (
      await fetch(
        AUTH_DISPATCH_BY_SUPPLIER +
          "&year=" +
          year +
          "&season=" +
          season +
          "&cropID=" +
          cropID +
          "&supplierID=" +
          supplierID +
          "&transportDetail=" +
          transportDetail +
          "&pid=" +
          purchaseID +
          "&status=" +
          status +
          "&supplystatus=" +
          supplystatus,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  receiveSeedQuantity: async (
    cropID,
    verityID,
    recevedQuantity,
    receivedBy,
    pid,
    year,
    season,
    status,
    dispatchID
  ) => {
    const result = await (
      await fetch(
        AUTH__RECEIVED_SEED_QTY_API_URL +
          "&cropID=" +
          cropID +
          "&verityID=" +
          verityID +
          "&recevedQuantity=" +
          recevedQuantity +
          "&receivedBy=" +
          receivedBy +
          "&pid=" +
          pid +
          "&status=" +
          status +
          "&supplystatus=" +
          "receive" +
          "&year=" +
          year +
          "&season=" +
          season +
          "&dispatchID=" +
          dispatchID,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },

  getDistributors: async (role) => {
    const result = await (
      await fetch(AUTH__GET_DISTRIBUTORS_BY_URL + "&role=" + role, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  getDistributorsWithDistrictID: async (role, districtID) => {
    const result = await (
      await fetch(
        AUTH__GET_DISTRIBUTORS_BY_DISTRICTID_URL +
          "&role=" +
          role +
          "&district=" +
          districtID,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getDistributorsByID: async (distributorID) => {
    const result = await (
      await fetch(
        AUTH__GET_DISTRIBUTORS_BY_ID_BY_URL + "&distributorID=" + distributorID,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getInsertAllotment: async (
    distributorID,
    cropId,
    varietyId,
    allotedQty,
    daoID,
    year,
    season
  ) => {
    const result = await (
      await fetch(
        AUTH_SEED_INSERT_ALLOTMENT +
          "&distributorID=" +
          distributorID +
          "&cropID=" +
          cropId +
          "&verityID=" +
          varietyId +
          "&allotmetQuantity=" +
          allotedQty +
          "&daoID=" +
          daoID +
          "&year=" +
          year +
          "&season=" +
          season,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getStockRegister: async (id, season) => {
    const result = await (
      await fetch(AUTH_STOCK_REGISTER + "&userID=" + id + "&season=" + season, {
        method: "GEt",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  getDistributorUserByID: async (role, cropID) => {
    const result = await (
      await fetch(
        AUTH__GET_DISTRIBUTOR_USER_BY_ID_BY_URL +
          "&role=" +
          role +
          "&CropID=" +
          cropID,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  addFarmerDetail: async (
    mode,
    name,
    fatherName,
    contactNumber,
    adharNo,
    panchayath,
    village,
    area,
    district,
    block,
    userId,
    caste,
    gender,
    ekyc
  ) => {
    console.log("caste", caste);
    console.log("gender", gender);
    console.log("ekyc", ekyc);

    const result = await (
      await fetch(
        AUTH_ADD_FARMER,

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },

          body: JSON.stringify({
            mode: mode,
            aadharNo: adharNo,
            area: area,
            block: block,
            distributorID: userId,
            districtID: district,
            fatherName: fatherName,
            name: name,
            mobileNo: contactNumber,
            panchayatName: panchayath,
            village: village,
            gender: gender,
            ekyc: ekyc,
            cast: caste,
          }),
        }
      )
    ).json();
    return result;
  },
  getDistributorSeedHistory: async (userid, offset, season) => {
    const result = await (
      await fetch(
        AUTH__GET_DISTRIBUTOR_SEED_HISTORY_BY_URL +
          "&userID=" +
          userid +
          "&offset=" +
          offset +
          "&season=" +
          season,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getSeedDistributor: async (id, season) => {
    let offset = "0,100";
    let data = {
      offset: offset,
      mode: "SEEDLIST",
      userID: id,
      season: season,
    };
    console.log("data", data);
    const result = await (
      await fetch(AUTH__GET_DISTRIBUTOR_SEEDS, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
    ).json();
    return result;
  },
  getFarmerDetail: async (id, mode) => {
    let data = {
      mode: mode,
      aadharNo: id,
    };
    console.log("data", data);
    const result = await (
      await fetch(AUTH__GET_FARMER_DETAILS, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
    ).json();
    return result;
  },
  getInsertDistribution: async (
    mobile_no,
    adhar,
    mode,
    qunatity,
    name,
    seedID,
    verityID,
    gps,
    apkVersion,
    uid,
    year,
    season,
    otp,
    Aadhar
  ) => {
    const result = await (
      await fetch(AUTH_INSERT_FARMER_DISTRIBUTION, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mode: mode,
          quantity: qunatity,
          farmerCno: mobile_no,
          farmerAadhar: adhar,
          distributionPoint: name,
          seedID: seedID,
          verityID: verityID,
          gps: gps,
          apkVersion: apkVersion,
          userID: uid,
          year: year,
          season: season,
          otp: otp,
          Aadhar: Aadhar,
        }),
      })
    ).json();
    return result;
  },
  FetchDeleteDistribution: async (
    veriety,
    seed,
    distribution_email_id,
    quantity,
    id,
    year,
    season
  ) => {
    const result = await (
      await fetch(AUTH_INSERT_FARMER_DISTRIBUTION, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mode: "DELETEDISTRIBUTION",
          quantity: quantity,
          seedID: seed,
          verityID: veriety,
          userID: distribution_email_id,
          year: year,
          season: season,
          dwid: id,
        }),
      })
    ).json();
    return result;
  },
  getDistributionWise: async (districtID) => {
    const result = await (
      await fetch(
        AUTH__GET_DISTRIBUTION_WISE_BY_URL + "&districtID=" + districtID,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getDistributionWiseSeed: async (seedid) => {
    const result = await (
      await fetch(
        AUTH__GET_DISTRIBUTION_SEED_WISE_BY_URL + "&seedID=" + seedid,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getDistributionWiseSeason: async (season) => {
    const result = await (
      await fetch(
        AUTH__GET_DISTRIBUTION_SEASON_WISE_BY_URL + "&season=" + season,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getDistributionWiseVariety: async (id) => {
    const result = await (
      await fetch(
        AUTH__GET_DISTRIBUTION_VARIETY_WISE_BY_URL + "&verityID=" + id,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getDistributionWiseDistricts: async (id) => {
    const result = await (
      await fetch(
        AUTH__GET_DISTRIBUTION_DISTRICT_WISE_BY_URL + "&districtID=" + id,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getDashboardSeedWise: async (id) => {
    const result = await (
      await fetch(AUTH__GET_DASHBOARD_SEED_WISE_BY_URL + "&seedID=" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  getDistrictsWiseDistributoraAll: async () => {
    const result = await (
      await fetch(AUTH__GET_DISTRIBUTION_WISE_DISTRICT_ALL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  getDistrictsWiseDistributoraVariety: async (id) => {
    const result = await (
      await fetch(
        AUTH__GET_DISTRIBUTION_WISE_DISTRICT_VERITY + "&verityID=" + id,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getDistrictsWiseDistributorSeason: async (id) => {
    const result = await (
      await fetch(
        AUTH__GET_DISTRIBUTION_WISE_DISTRICT_SEASON + "&season=" + id,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getDistrictsWiseDistributoraSeed: async (id) => {
    const result = await (
      await fetch(AUTH__GET_DISTRIBUTION_WISE_DISTRICT_SEED + "&seedID=" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  getDistributorsWithSameDistricForDAO: async (
    role,
    cropID,
    districtID,
    varietyId,
    season
  ) => {
    console.log(
      AUTH__GET_DISTRIBUTOR_DAO_WITH_SAME_DISTRICTS +
        "&role=" +
        role +
        "&CropID=" +
        cropID +
        "&district=" +
        districtID +
        season
    );
    const result = await (
      await fetch(
        AUTH__GET_DISTRIBUTOR_DAO_WITH_SAME_DISTRICTS +
          "&role=" +
          role +
          "&CropID=" +
          cropID +
          "&district=" +
          districtID +
          "&verityID=" +
          varietyId +
          "&season=" +
          season,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getAllSeedReqestsForDao: async (approverID) => {
    const result = await (
      await fetch(
        AUTH__GET_ALL_SEED_REQUEST_FOR_DAO + "&approverID=" + approverID,

        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },

  getTransferRequestsByDistributor: async (id, season) => {
    const result = await (
      await fetch(
        AUTH__GET_TRANSFER_REQUESTS_BY_DISTRIBUTOR +
          "&senderUserID=" +
          id +
          "&season=" +
          season,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getINDSeedReqestForDao: async (transferReqid) => {
    const result = await (
      await fetch(
        AUTH__GET_IND_SEED_REQUEST_DAO + "&transferReqNO=" + transferReqid,

        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getAddTransferByDistributor: async (year, season, userId, daoID, array) => {
    const result = await (
      await fetch(
        AUTH__ADD_TRANSFER_REQUESTS_BY_DISTRIBUTOR +
          "&year=" +
          year +
          "&season=" +
          season +
          "&senderUserID=" +
          userId +
          "&approverID=" +
          daoID +
          "&seedID=" +
          array,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getApproveRejectSeedRequestDao: async (transferReqid, status) => {
    const result = await (
      await fetch(
        AUTH__GET_APPROVEOR_REJECT_DAO +
          "&transferReqNO=" +
          transferReqid +
          "&approveStatus=" +
          status,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getReceiverRequestsByDistributor: async (id, season) => {
    const result = await (
      await fetch(
        AUTH__GET_RECEIVED_RANSFER_REQUESTS_BY_DISTRIBUTOR +
          "&receiverUserID=" +
          id +
          "&season=" +
          season,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getSeedReceiveForDistributor: async (
    year,
    season,
    verityID,
    SeedID,
    SenderID,
    RecieverID,
    Quantity,
    status,
    transferReqid
  ) => {
    const result = await (
      await fetch(
        AUTH__SEED_RECEIVER_FOR_DISTRIBUTOR +
          "&year=" +
          year +
          "&season=" +
          season +
          "&transferReqNO=" +
          transferReqid +
          "&verietyID=" +
          verityID +
          "&seedID=" +
          SeedID +
          "&senderUserID=" +
          SenderID +
          "&receiverUserID=" +
          RecieverID +
          "&seedQuantity=" +
          Quantity +
          "&approveStatus=" +
          status,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getVerifyOTP: async (mobile, qty, cropName) => {
    const result = await (
      await fetch(
        AUTH__VERIFY_OTP_WEB +
          "&mobile=" +
          mobile +
          "&quantity=" +
          qty +
          "&cropName=" +
          cropName,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  updateUserPassword: async (id, password) => {
    const result = await (
      await fetch(UPDATE_USER_PASSWORD_API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mode: "UPDATEPASSWORD",
          pid: id,
          password: password,
        }),
      })
    ).json();
    return result;
  },
  updateUserPurchaseType: async (userid, purchaser) => {
    const result = await (
      await fetch(
        ADD_UPDATE_PURCHASER_TYPE_API_URL +
          "&pid=" +
          userid +
          "&Purchaser=" +
          purchaser,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  uploadImage: async (formData) => {
    let result = axios.post(UPLOAD_IMAGE_API_URL, formData, {}).then((res) => {
      return res;
    });
    return result;
  },
  addForecastByAotoDao: async (
    details_det,
    userId,
    forcastID,
    varietyArray,
    status
  ) => {
    const result = await (
      await fetch(
        AUTH_UPDATE_FORECAST_BY_DAO_API_URL +
          "&detailData=" +
          details_det +
          "&status=" +
          status +
          "&AoID=" +
          userId +
          "&forcastID=" +
          forcastID +
          "&seedID=" +
          varietyArray,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getDistrictCropwiseSeedData: async (id, districtId) => {
    const result = await (
      await fetch(
        AUTH_SELECT_DISTRICT_WISE_SEED_API_URL +
          "&seedID=" +
          id +
          "&districtID=" +
          districtId,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getDistrictCropSeasonwiseSeedData: async (id, districtId, season) => {
    const result = await (
      await fetch(
        AUTH_SELECT_DISTRICT_SEED_WISE_SEED_API_URL +
          "&seedID=" +
          id +
          "&districtID=" +
          districtId +
          "&season=" +
          season,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getDashboardSeedSeasonWise: async (id, season) => {
    const result = await (
      await fetch(
        AUTH__GET_DASHBOARD_SEED_SEASON_WISE_BY_URL +
          "&seedID=" +
          id +
          "&season=" +
          season,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getTotalForecastsForAO: async (season) => {
    const result = await (
      await fetch(AUTH_SHOW_FORCASTS_AO_ALL_API_URL + "&season=" + season, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  getDashboardDistrictandDistributionsSearch: async (
    districtID,
    verityID,
    season,
    seedID
  ) => {
    const result = await (
      await fetch(
        AUTH_SHOW_DISTRIBUTION_WISE_ALLSEARCH_API_URL +
          "&districtID=" +
          districtID +
          "&verityID=" +
          verityID +
          "&season=" +
          season +
          "&seedID=" +
          seedID,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getDashboardDistrictLevelData: async (year) => {
    const result = await (
      await fetch(AUTH_SHOW_DISTRICT_LEVEL_DATA + "&year=" + year, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  getDashboardDistrictAllSearch: async (verityID, season, seedID) => {
    const result = await (
      await fetch(
        AUTH_SHOW_DISTRICT_WISE_ALLSEARCH_API_URL +
          "&verityID=" +
          verityID +
          "&season=" +
          season +
          "&seedID=" +
          seedID,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getDistrictWiseFarmers: async (districtID, season) => {
    console.log(districtID);
    const result = await (
      await fetch(
        AUTH_SHOW_SELECT_DISTRICT_WISE_FARMER_API_URL +
          "&district=" +
          districtID +
          "&category=" +
          season,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },

  getDistrictWiseDistributions: async (districtID) => {
    const result = await (
      await fetch(
        AUTH_SHOW_SELECTDISTRICT_WISE_FARMER_DISTRIBUTION_API_URL +
          "&district=" +
          districtID,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getFarmerDetailWithCropSeason: async (
    id,
    mobile_no,
    mode,
    distributorId,
    feedback,
    aadharId,
    verityID,
    seed,
    season,
    year
  ) => {
    let data = {
      mode: mode,
      farmerAadhar: id,
      dwid: distributorId,
      aadhar: aadharId,
      farmerFeedback: feedback,
      farmerCno: mobile_no,
      seedID: seed,
      season: season,
      year: year,
      verityID: verityID,
    };
    console.log("data", data);
    const result = await (
      await fetch(AUTH__GET_FARMER_WITH_ROP_SEASONDETAILS, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
    ).json();
    return result;
  },

  addSeedByDao: async (daoid, cropId, varietyID, year, season) => {
    const result = await (
      await fetch(
        ADD_SEEDS_BY_DAO +
          "&daoID=" +
          daoid +
          "&seedID=" +
          cropId +
          "&varietyID=" +
          varietyID +
          "&year=" +
          year +
          "&season=" +
          season,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getSeedsByDao: async (daoid) => {
    const result = await (
      await fetch(GET_SEEDS_BY_DAO + "&daoID=" + daoid, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  getSeedsByDaoSeason: async (daoid, season) => {
    const result = await (
      await fetch(GET_SEEDS_BY_DAO + "&daoID=" + daoid + "&season=" + season, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },

  updateForecastSeedByDao: async (
    daoid,
    cropId,
    varietyID,
    year,
    season,
    forecastQuantity,
    status,
    district
  ) => {
    const result = await (
      await fetch(
        UPDATE_FORECAST_SEEDS_BY_DAO +
          "&daoID=" +
          daoid +
          "&seedID=" +
          cropId +
          "&varietyID=" +
          varietyID +
          "&year=" +
          year +
          "&season=" +
          season +
          "&forecastQuantity=" +
          forecastQuantity +
          "&status=" +
          status +
          "&districtID=" +
          district,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getAoSelectPending: async () => {
    const result = await (
      await fetch(GET_AO_SELECT_PENDING, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  getAoPendingView: async (districtID) => {
    const result = await (
      await fetch(GET_AO_PENDING_VIEW + "&districtID=" + districtID, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },

  getAoUpdateForeacast: async (
    daoID,
    seedID,
    varietyID,
    year,
    season,
    ForecastID,
    aoID,
    approvedqunatity,
    status
  ) => {
    const result = await (
      await fetch(
        GET_AO_UPDATE_FORECAST_VIEW +
          "&daoID=" +
          daoID +
          "&seedID=" +
          seedID +
          "&varietyID=" +
          varietyID +
          "&year=" +
          year +
          "&season=" +
          season +
          "&pid=" +
          ForecastID +
          "&aoID=" +
          aoID +
          "&approveQuantity=" +
          approvedqunatity +
          "&status=" +
          status,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getAoSelectApprove: async () => {
    const result = await (
      await fetch(GET_AO_SELECT_APPROVE, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  getForecastHistoryByDistrict: async (districtID) => {
    const result = await (
      await fetch(GET_FORECAST_HISTORY_VIEW + "&districtID=" + districtID, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  getSeedsAO: async (districtID) => {
    const result = await (
      await fetch(GET_SEEDS_BY_APPROVE_AO + "&districtID=" + districtID, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  getFarmersListDistrict: async (startDate) => {
    const result = await (
      await fetch(GET_DISTRICT_WISE_FARMER1 + "&startDate=" + startDate, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  getAllFarmerDetails: async (startDate, endDate, districtId) => {
    const result = await (
      await fetch(
        GET_ALL_FARMER_DETAIL +
          "&startDate=" +
          startDate +
          "&endDate=" +
          endDate +
          "&district=" +
          districtId,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getAllDistributionDetails: async (
    startDate,
    endDate,
    season,
    district,
    seedID
  ) => {
    const result = await (
      await fetch(
        GET_ALL_DISTRIBUTION_DETAIL +
          "&startDate=" +
          startDate +
          "&endDate=" +
          endDate +
          "&season=" +
          season +
          "&district=" +
          district +
          "&CropID=" +
          seedID,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getAoApprovedQtySuppliers: async (
    mode,
    supplierID,
    verietyID,
    season,
    district,
    seedID,
    year,
    remainQuantity,
    approvedQuantity,
    approvedBy
  ) => {
    const result = await (
      await fetch(
        GET_AO_APPROVE_QTY_SUPPLIER +
          mode +
          "&supplierID=" +
          supplierID +
          "&verietyID=" +
          verietyID +
          "&year=" +
          year +
          "&remainQuantity=" +
          remainQuantity +
          "&approvedQuantity=" +
          approvedQuantity +
          "&approvedBy=" +
          approvedBy +
          "&season=" +
          season +
          "&districtID=" +
          district +
          "&seedID=" +
          seedID,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getRequestTestingAPI: async (
    mode,
    seedID,
    verietyID,
    year,
    season,
    senderID,
    daoID,
    testingAgencyID,
    status,
    reportFile,
    reportComment,
    daoComment,
    senderComment,
    seedReport,
    pid,
    dispatchID
  ) => {
    const result = await (
      await fetch(
        GET_REQUEST_TESTING_URL +
          mode +
          "&senderID=" +
          senderID +
          "&verietyID=" +
          verietyID +
          "&year=" +
          year +
          "&daoID=" +
          daoID +
          "&testingAgencyID=" +
          testingAgencyID +
          "&daoComment=" +
          daoComment +
          "&season=" +
          season +
          "&senderComment=" +
          senderComment +
          "&seedReport=" +
          seedReport +
          "&dispatchID=" +
          dispatchID +
          "&pid=" +
          pid +
          "&status=" +
          status +
          "&reportFile=" +
          reportFile +
          "&reportComment=" +
          reportComment +
          "&seedID=" +
          seedID,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },

  gettransactionHashDetails: async (txnhash) => {
    const result = await (
      await fetch(GET_TRANSACTION_HASH_DETAIL + txnhash, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  getUOMBase_url: async (mode, uomId, pid) => {
    const result = await (
      await fetch(GET_UOM_BASE_URL + mode + "&uom=" + uomId + "&pid=" + pid, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },

  getFarmerRegisterOTP: async (mobile) => {
    let data = {
      mobile: mobile,
    };
    console.log("data", data);
    const result = await (
      await fetch(AUTH_GET_FARMER_REGISTRATION_OTP + "&mobile=" + mobile, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },

  getFarmerRegisterVerifyOTP: async (mobile, otp) => {
    let data = {
      mobile: mobile,
    };
    console.log("data", data);
    const result = await (
      await fetch(
        AUTH_GET_FARMER_REGISTRATION_VERIFY_OTP +
          "&mobile=" +
          mobile +
          "&otp=" +
          otp,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },

  getFarmerNewRegistration: async (
    FfirstName,
    FLastName,
    FFatherName,
    FGender,
    FCaste,
    farmerCNo,
    farmerAadharNo,
    FVillage,
    FPanchayat,
    FBlock,
    FArea,
    FDistrict,
    Farmerid
  ) => {
    let data = {
      farmerFName: FfirstName,
      farmerLName: FLastName,
      fatherName: FFatherName,
      gender: FGender,
      caste: FCaste,
      farmerCNo: farmerCNo,
      farmerAadharNo: farmerAadharNo,
      village: FVillage,
      panchayat: FPanchayat,
      block: FBlock,
      district: FDistrict,
      area: FArea,
      pid: Farmerid,
    };
    console.log("data", data);

    const result = await (
      await fetch(
        AUTH_FARMER_NEW_REGISTRATION +
          "&farmerFName=" +
          FfirstName +
          "&farmerLName=" +
          FLastName +
          "&fatherName=" +
          FFatherName +
          "&gender=" +
          FGender +
          "&caste=" +
          FCaste +
          "&farmerCNo=" +
          farmerCNo +
          "&farmerAadharNo=" +
          farmerAadharNo +
          "&village=" +
          FVillage +
          "&panchayat=" +
          FPanchayat +
          "&block=" +
          FBlock +
          "&district=" +
          FDistrict +
          "&area=" +
          FArea +
          "&pid=" +
          Farmerid,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getBlockByDistrictId: async (id) => {
    const result = await (
      await fetch(AUTH_GET_BLOCK_BY_DISTRICT_ID + "&district=" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  getCheckAdharNumber: async (farmerAadharNo) => {
    const result = await (
      await fetch(
        AUTH_CHECK_ADHAR_NUMBER + "&farmerAadharNo=" + farmerAadharNo,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },

  getActivities: async () => {
    const result = await (
      await fetch(GET_ACTIVITIES, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  getSchemes: async () => {
    const result = await (
      await fetch(GET_SCHEMES, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  getApplicants: async () => {
    const result = await (
      await fetch(GET_APPLICANTS, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  insert_farmerApplication: async (
    activityName,
    scheme,
    farmerAadharNo,
    FAge,
    area,
    plotNo,
    areaDocument,
    bankName,
    bankBranch,
    branchCode,
    branchAccount,
    qualification,
    uploadQualification,
    uploadphotograph,
    declartion,
    applicantType,
    orgName,
    orgAddress,
    orgMobile,
    orgPincode,
    uploadPhysicalCopy
  ) => {
    const result = await (
      await fetch(
        AUTH_FARMER_NEW_APPLICATION +
          "&ActivityAndScheam=" +
          activityName +
          "&Scheame=" +
          scheme +
          "&aadhar=" +
          farmerAadharNo +
          "&age=" +
          FAge +
          "&area=" +
          area +
          "&plotNo=" +
          plotNo +
          "&areaDoc=" +
          areaDocument +
          "&bankName=" +
          bankName +
          "&branch=" +
          bankBranch +
          "&branchIFSC=" +
          branchCode +
          "&accountNumber=" +
          branchAccount +
          "&qualification=" +
          qualification +
          "&qualificationDoc=" +
          uploadQualification +
          "&beneficaryPhoto=" +
          uploadphotograph +
          "&declaration=" +
          declartion +
          "&applicantType=" +
          applicantType +
          "&nameOFOrg=" +
          orgName +
          "&orgAddress=" +
          orgAddress +
          "&orgContactNo=" +
          orgMobile +
          "&orgPincode=" +
          orgPincode +
          "&PhysicalCopy="+uploadPhysicalCopy,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getListDataFarmersByDHO: async (
    id,
    statusAo,
    applicationStatusDho,
    ActivityAndScheam,
    scheame
  ) => {
    /*   http://143.110.255.191:3022/jseed/hortiApplicationData?mode=SELECTAPPLICATIONDAO&approvedByDho=2822
    &applicationStatusAO=&applicationStatusDho=Approved&ActivityAndScheam&Scheame
   */ const result = await (
      await fetch(
        LIST_OF_FARMERS_BY_DHO +
          "&approvedByDho=" +
          id +
          "&applicationStatusAO=" +
          statusAo +
          "&applicationStatusDho=" +
          applicationStatusDho +
          "&ActivityAndScheam=" +
          ActivityAndScheam +
          "&Scheame=" +
          scheame,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getListDataFarmersByAO: async (id,
    statusAo,
    applicationStatusDho,
    ActivityAndScheam,
    scheame,
    districtName 
 ) => {
    const result = await (
      await fetch(LIST_OF_FARMERS_BY_AO + "&approvedByAO=" + id +
        "&applicationStatusAO=" +
          statusAo +
          "&applicationStatusDho=" +
          applicationStatusDho +
          "&ActivityAndScheam=" +
          ActivityAndScheam +
          "&Scheame=" +
          scheame+"&declaration=" +districtName, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },

  updateApplicationDHO: async (status, quantity, applicationNO,reason) => {
    const result = await (
      await fetch(
        UPDATE_APPLICATION_DAO +
          "&dhoApptovedQuantity=" +
          quantity +
          "&applicationStatusDho=" +
          status +
          "&applicationNO=" +
          applicationNO +
          "&RejectReasonDHO=" +reason,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  updateApplicationAO: async (status, applicationNO,reason) => {
    console.log(
      UPDATE_APPLICATION_AO +
        "&applicationStatusAO=" +
        status +
        "&applicationNO=" +
        applicationNO+
        "&RejectReasonAO=" +reason,
    );
    const result = await (
      await fetch(
        UPDATE_APPLICATION_AO +
          "&applicationStatusAO=" +
          status +
          "&applicationNO=" +
          applicationNO +"&RejectReasonAO=" +reason,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  getFamerApplicationsByAdhar: async (adharNo) => {
    const result = await (
      await fetch(GET_FARMER_APPLICATIONS_BY_ADHAR + "&aadhar=" + adharNo, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  getFarmerDetailsByApplicationNO: async (applicationNO) => {
    const result = await (
      await fetch(
        GET_VIEW_APPLICATIONS_BY_APPLICATION_NO +
          "&applicationNO=" +
          applicationNO,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },

  addActivity: async (activityName) => {
    const result = await (
      await fetch(INSERT_ACTIVITY + "&activityName=" + activityName, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  updateActivity: async (activityName, pid) => {
    const result = await (
      await fetch(
        UPDATE_ACTIVITY + "&activityName=" + activityName + "&pid=" + pid,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  deleteActivity: async (pid) => {
    const result = await (
      await fetch(DELETE_ACTIVITY + "&pid=" + pid, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },

  addSchemeData: async (schemeName) => {
    const result = await (
      await fetch(INSERT_SCHEME + "&schemeName=" + schemeName, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
  updateScheme: async (schemeName, pid) => {
    const result = await (
      await fetch(
        UPDATE_SCHEME + "&schemeName=" + schemeName + "&pid=" + pid,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
    return result;
  },
  deleteScheme: async (pid) => {
    const result = await (
      await fetch(DELETE_SCHEME + "&pid=" + pid, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return result;
  },
};
export default apiSettings;
