import Role from "./images/icons8-role-64.png"
import users from "./images/users.png";
import Department from "./images/icons8-department-64.png";
import Seeds from "./images/icons8-sesame-48.png";
import variety from "./images/icons8-variety-64.png";
import Order from "./images/icons8-purchase-order-48.png"
import season from "./images/icons8-season-64.png"
import measure from "./images/icons8-measure-64.png";
const _nav = [
  {
    title: "Role",
    to: "/roles",
    icon: Role,
    subitems: []
  },
  {
    title: "Designation",
    to: "/designations",
    icon:users,
    subitems: []
  },
  {
    title: "Department",
    to: "/departments",
    icon:Department,
    subitems: []
  },
  {
    title: "Users",
    to: "/admin-users",
    icon:users,
    subitems: []
  },
  {
    title: "Activities",
    to: "/activites",
    icon:Department,
    subitems: []
  },
  {
    title: "Schemes",
    to: "/schemes",
    icon:Department,
    subitems: []
  },
  {
    title: "Type",
    to: "/variety",
    icon:variety,
    subitems: []
  },
  {
    title: "Components",
    to: "/seeds",
    icon:Seeds,
    subitems: []
  },
  
  {
    title: "Season",
    to: "/season",
    icon:season,
    subitems: []
  },
  {
    title: "UOM",
    to: "/uom",
    icon:measure,
    subitems:[]
  },

];

export default _nav;
