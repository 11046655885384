import React, { useState, useEffect, useContext,useRef } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./styles"
import { Row, Container, Col } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import { useNavigate, Link } from "react-router-dom";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle"
import { LoadingSpinner } from "../LoadingSpinner/index";
import moment from "moment";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CanvasJSReact from '../canvasjs.react';
import { BarChart, Bar, XAxis, YAxis, Tooltip,LabelList,ResponsiveContainer, Legend,Cell, CartesianGrid } from 'recharts';
import Modal from 'react-bootstrap/Modal';

 var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart; 

const DashboardAo = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [offset, setOffSet] = useState(0);
    const [orgtableData, setOrgTableData] = useState([]);
    const[start_date,setStartDate]=useState('');
    const[end_date,setEndDate]=useState('');
    const [graph_det, setgraph_data] = useState([]);
    const [seasonData, setSeasonData] = useState([]);
    const[season,setSeason]=useState('');
    const[seedData,setSeedData]=useState([]);
    const [seedId,setSeedId]=useState('');

    const [totalmemberCount, setTotalmemberCount] = useState([]);
    const [totalfarmerBenifited, setTotalfarmerBenifited] = useState([]);
    const [totaltileDistributed, setTotaltileDistributedQuantity] = useState([]);

    const [todaytotalmemberCount, settodayTotalmemberCount] = useState([]);
    const [todaytotalfarmerBenifited, settodayTotalfarmerBenifited] = useState([]);
    const [todaytotaltileDistributed, settodayTotalDistributedQuantity] = useState([]);

    

    let chart = useRef();
    const data = [{name: "0",stock: 100,},{name: "10",stock: 80,},
        {name: "20",stock: 70,}, {name: "30",stock: 95,},
        {name: "40",stock: 50,},{name: "50",stock: 20,}];
        const options = {
        animationEnabled: true,
        exportEnabled: true,
        theme: "light2", //"light1", "dark1", "dark2"
       /*  title:{
            text: "Simple Column Chart with Index Labels"
        }, */
        toolTip: {},
        axisY: {
            includeZero: true
        },
        axisX: {
            interval:1,
            labelMaxWidth: 180,           
            labelAngle: -45, //90,
           // labelFontFamily:"verdana0"
        },
        data: [{
            type: "column", //change type to bar, line, area, pie, etc
            indexLabel: "{y}", //Shows y value on all Data Points
            indexLabelFontColor: "#5A5757",
            indexLabelPlacement: "outside",
            dataPoints: graph_det,
        }]
    };
    console.log(graph_det);

    const handleInput = async (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        if (name == "start_date") {setStartDate(value)};
        if (name == "end_date") {setEndDate(value);}
        if (name == "season") {setSeason(value);}
        if (name == "seedId") {setSeedId(value);}
        
    }

        useEffect(() => {
        setIsLoading(true);       
        const fetchDistributionWise = async () => {

            const seasonresult = await API_Auth.getSeasons();
            setSeasonData(seasonresult)

            let seed_result =await API_Auth.getSeeds();
            setSeedData(seed_result);

            let result_farmers = await API_Auth.getDashboardDistrictAllSearch('','','');
            console.log(result_farmers);

            let result_tiles = await API_Auth.getFarmersListDistrict('');
            console.log(result_tiles);
            for(let i=0; i<result_tiles.length; i++){
                const data=result_farmers.filter((item,index)=>parseInt(item.district)==parseInt(result_tiles[i].districtID));
                for(let j=0; j<result_farmers.length; j++){
                    if(parseInt(result_farmers[j].district)==parseInt(result_tiles[i].districtID)){
                        result_tiles[i]['distributedQuantity'] = result_farmers[j].disrtributedQuantity;
                        result_tiles[i]['farmersBenifitedAdd'] = result_farmers[j].farmersBenifited;
                    }
                }
            } 
            console.log(result_tiles);
            let totalfarmerBenifited = 0;
            let totaltiledisrtributedQuantity = 0;
            let totafarmerRegistered = 0;
            for(let i=0; i<result_tiles.length; i++){
                if(result_tiles[i]?.farmersBenifitedAdd){
                    totalfarmerBenifited = totalfarmerBenifited+parseInt(result_tiles[i]?.farmersBenifitedAdd);
                }
                 if(result_tiles[i]?.distributedQuantity){
                    totaltiledisrtributedQuantity = totaltiledisrtributedQuantity+parseInt(result_tiles[i]?.distributedQuantity);
              }
              if(result_tiles[i]?.memberCount){
                totafarmerRegistered = totafarmerRegistered+parseInt(result_tiles[i]?.memberCount);
              }
            }
            setTotalmemberCount(totafarmerRegistered);
            setTotaltileDistributedQuantity(totaltiledisrtributedQuantity);
            setTotalfarmerBenifited(totalfarmerBenifited);

            const date = new Date();
            const futureDate = date.getDate();
            date.setDate(futureDate);
            const defaultValue = date.toLocaleDateString('en-CA');
            setStartDate(defaultValue);
            setEndDate(defaultValue);
            const startdate=moment(defaultValue).format("MM/DD/YYYY");
            const enddate=moment(defaultValue).format("MM/DD/YYYY");


            let todau_result_distribution = await API_Auth.getDashboardDistrictLevelData(startdate);
            console.log(todau_result_distribution);
            let today_result_farmers = await API_Auth.getFarmersListDistrict(startdate);
            console.log(today_result_farmers);
            for(let i=0; i<todau_result_distribution.length; i++){
                for(let j=0; j<today_result_farmers.length; j++){
                    if(parseInt(today_result_farmers[j]?.districtID)==parseInt(todau_result_distribution[i]?.district)){
                        todau_result_distribution[i]['memberCount'] = today_result_farmers[j]?.memberCount;
                    }
                }
            } 
            console.log(todau_result_distribution);
            let todaytotalfarmerBenifited = 0;
            let todaytotaldisrtributedQuantity = 0;
            let todaytotafarmerRegistered = 0;
            for(let i=0; i<todau_result_distribution.length; i++){
                if(todau_result_distribution[i]?.farmersBenifited){
                    todaytotalfarmerBenifited = todaytotalfarmerBenifited+parseInt(todau_result_distribution[i]?.farmersBenifited);
                }
                 if(todau_result_distribution[i]?.disrtributedQuantity){
                    todaytotaldisrtributedQuantity = todaytotaldisrtributedQuantity+parseInt(todau_result_distribution[i]?.disrtributedQuantity);
              }
              if(todau_result_distribution[i]?.memberCount){
                todaytotafarmerRegistered = todaytotafarmerRegistered+parseInt(todau_result_distribution[i]?.memberCount);
              }
            }
            settodayTotalmemberCount(todaytotafarmerRegistered);
            settodayTotalDistributedQuantity(todaytotaldisrtributedQuantity);
            settodayTotalfarmerBenifited(todaytotalfarmerBenifited);


            let result = await API_Auth.getAllDistributionDetails(startdate,enddate,'','','');
            console.log('graph_result',result);
            setIsLoading(false);
            let totaldisrtributedQuantity = 0;
            let graph_data = [];
            for(let i=0; i<result.length; i++){
                 if(result[i]?.distributionQuantity){
                    totaldisrtributedQuantity = totaldisrtributedQuantity+parseInt(result[i]?.distributionQuantity);
                    var data={
                        districtName: result[i]['districtName'],
                        distributionQuantity:result[i]['distributionQuantity']
                        }
                        graph_data.push(data);
              }
            }
            let totalranchiQty = 0;
            let totalPakurQty = 0;
            let totalsinghbhumQty = 0;

            let totalChatraQty = 0;
            let totalDeogharQty = 0;
            let totalDhanbadQty = 0;

            let totalDumkaQty = 0;
            let totalGarhwaQty = 0;
            let totalGiridihQty = 0;

            let totalGoddaQty = 0;
            let totalGumlaQty = 0;
            let totalBokaroQty = 0;

            let totalHazaribagQty = 0;
            let totalJamshedpurQty = 0;
            let totalJamtaraQty = 0;
            let totalKhuntiQty = 0;

            let totalKodermaQty = 0;
            let totalLateharQty = 0;
            let totalLohardagaQty = 0;
            let totalPalamuQty = 0;

            let totalSimdegaQty = 0;
            let totalSahibganjQty = 0;
            let totalSeraikelaQty = 0;
            let totalRamgarhQty = 0;
            

            for(let i=0; i<graph_data.length; i++){
                if(graph_data[i]?.districtName=="Ranchi"){
                    totalranchiQty = totalranchiQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Pakur"){
                    totalPakurQty = totalPakurQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=='West Singhbhum'){
                    totalsinghbhumQty = totalsinghbhumQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Bokaro"){
                    totalBokaroQty = totalBokaroQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Hazaribag"){
                    totalHazaribagQty = totalHazaribagQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Jamshedpur"){
                    totalJamshedpurQty = totalJamshedpurQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Jamtara"){
                    totalJamtaraQty = totalJamtaraQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Khunti"){
                    totalKhuntiQty = totalKhuntiQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Koderma"){
                    totalKodermaQty = totalKodermaQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Latehar"){
                    totalLateharQty = totalLateharQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Lohardaga"){
                    totalLohardagaQty = totalLohardagaQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Palamu"){
                    totalPalamuQty = totalPalamuQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Ramgarh"){
                    totalRamgarhQty = totalRamgarhQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Chatra"){
                    totalChatraQty = totalChatraQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Sahibganj"){
                    totalSahibganjQty = totalSahibganjQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Seraikela"){
                    totalSeraikelaQty = totalSeraikelaQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Simdega"){
                    totalSimdegaQty = totalSimdegaQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Deoghar"){
                    totalDeogharQty = totalDeogharQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Dhanbad"){
                    totalDhanbadQty = totalDhanbadQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Dumka"){
                    totalDumkaQty = totalDumkaQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Garhwa"){
                    totalGarhwaQty = totalGarhwaQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Giridih"){
                    totalGiridihQty = totalGiridihQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Godda"){
                    totalGoddaQty = totalGoddaQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Gumla"){
                    totalGumlaQty = totalGumlaQty+parseInt(result[i]?.distributionQuantity);
                }
            }
            let graphtotal_data = [
                {label: 'Ranchi',y:totalranchiQty},
                {label: 'Bokaro',y:totalBokaroQty},
                {label: 'Chatra',y:totalChatraQty},
                {label: 'Deoghar',y:totalDeogharQty},
    
                {label: 'Dhanbad',y:totalDhanbadQty},
                {label: 'Dumka',y:totalDumkaQty},
                {label: 'Garhwa',y:totalGarhwaQty},
                {label: 'Giridih',y:totalGiridihQty},
    
                {label: 'Godda',y:totalGoddaQty},
                {label: 'Gumla',y:totalGumlaQty},
                {label: 'Hazaribag',y:totalHazaribagQty},
                {label: 'Jamshedpur',y:totalJamshedpurQty},
                {label: 'Jamtara',y:totalJamtaraQty},
    
                {label: 'Khunti',y:totalKhuntiQty},
                {label: 'Koderma',y:totalKodermaQty},
                {label: 'Latehar',y:totalLateharQty},
                {label: 'Lohardaga',y:totalLohardagaQty},
                {label: 'Pakur',y:totalPakurQty},
    
                {label: 'Palamu',y:totalPalamuQty},
                {label: 'Ramgarh',y:totalRamgarhQty},
                {label: 'Sahibganj',y:totalSahibganjQty},
                {label: 'Seraikela',y:totalSeraikelaQty},
                {label: 'Simdega',y:totalSimdegaQty},
                {label: 'West Singhbhum',y:totalsinghbhumQty},
            ];
            console.log(graphtotal_data);
            setgraph_data(graphtotal_data);
        };
        // call the function
        fetchDistributionWise()
            // make sure to catch any error
            .catch(console.error);
    }, []);

    const handlehitthheAPI = async() => {
        const startdate=moment(start_date).format("MM/DD/YYYY");
        const enddate=moment(end_date).format("MM/DD/YYYY")
        setIsLoading(true);       
        let result = await API_Auth.getAllDistributionDetails(startdate,enddate,season,'',seedId);
        console.log("graph_result",result);
        let totaldisrtributedQuantity = 0;
        let graph_data = [];
			
        for(let i=0; i<result.length; i++){
             if(result[i]?.distributionQuantity){
                totaldisrtributedQuantity = totaldisrtributedQuantity+parseInt(result[i]?.distributionQuantity);
                var data={
                    districtName: result[i]['districtName'],
                    distributionQuantity:result[i]['distributionQuantity']
                    }
                    graph_data.push(data)
          }
        }
        console.log(graph_data);
        let totalranchiQty = 0;
        let totalPakurQty = 0;
        let totalsinghbhumQty = 0;

        let totalChatraQty = 0;
        let totalDeogharQty = 0;
        let totalDhanbadQty = 0;

        let totalDumkaQty = 0;
        let totalGarhwaQty = 0;
        let totalGiridihQty = 0;

        let totalGoddaQty = 0;
        let totalGumlaQty = 0;
        let totalBokaroQty = 0;

        let totalHazaribagQty = 0;
        let totalJamshedpurQty = 0;
        let totalJamtaraQty = 0;
        let totalKhuntiQty = 0;

        let totalKodermaQty = 0;
        let totalLateharQty = 0;
        let totalLohardagaQty = 0;
        let totalPalamuQty = 0;

        let totalSimdegaQty = 0;
        let totalSahibganjQty = 0;
        let totalSeraikelaQty = 0;
        let totalRamgarhQty = 0;
        

        for(let i=0; i<graph_data.length; i++){
            if(graph_data[i]?.districtName=="Ranchi"){
                totalranchiQty = totalranchiQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Pakur"){
                totalPakurQty = totalPakurQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=='West Singhbhum'){
                totalsinghbhumQty = totalsinghbhumQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Bokaro"){
                totalBokaroQty = totalBokaroQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Hazaribag"){
                totalHazaribagQty = totalHazaribagQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Jamshedpur"){
                totalJamshedpurQty = totalJamshedpurQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Jamtara"){
                totalJamtaraQty = totalJamtaraQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Khunti"){
                totalKhuntiQty = totalKhuntiQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Koderma"){
                totalKodermaQty = totalKodermaQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Latehar"){
                totalLateharQty = totalLateharQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Lohardaga"){
                totalLohardagaQty = totalLohardagaQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Palamu"){
                totalPalamuQty = totalPalamuQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Ramgarh"){
                totalRamgarhQty = totalRamgarhQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Chatra"){
                totalChatraQty = totalChatraQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Sahibganj"){
                totalSahibganjQty = totalSahibganjQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Seraikela"){
                totalSeraikelaQty = totalSeraikelaQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Simdega"){
                totalSimdegaQty = totalSimdegaQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Deoghar"){
                totalDeogharQty = totalDeogharQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Dhanbad"){
                totalDhanbadQty = totalDhanbadQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Dumka"){
                totalDumkaQty = totalDumkaQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Garhwa"){
                totalGarhwaQty = totalGarhwaQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Giridih"){
                totalGiridihQty = totalGiridihQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Godda"){
                totalGoddaQty = totalGoddaQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Gumla"){
                totalGumlaQty = totalGumlaQty+parseInt(result[i]?.distributionQuantity);
            }
        }
        let graphtotal_data = [
            {label: 'Ranchi',y:totalranchiQty},
            {label: 'Bokaro',y:totalBokaroQty},
            {label: 'Chatra',y:totalChatraQty},
            {label: 'Deoghar',y:totalDeogharQty},

            {label: 'Dhanbad',y:totalDhanbadQty},
            {label: 'Dumka',y:totalDumkaQty},
            {label: 'Garhwa',y:totalGarhwaQty},
            {label: 'Giridih',y:totalGiridihQty},

            {label: 'Godda',y:totalGoddaQty},
            {label: 'Gumla',y:totalGumlaQty},
            {label: 'Hazaribag',y:totalHazaribagQty},
            {label: 'Jamshedpur',y:totalJamshedpurQty},
            {label: 'Jamtara',y:totalJamtaraQty},

            {label: 'Khunti',y:totalKhuntiQty},
            {label: 'Koderma',y:totalKodermaQty},
            {label: 'Latehar',y:totalLateharQty},
            {label: 'Lohardaga',y:totalLohardagaQty},
            {label: 'Pakur',y:totalPakurQty},

            {label: 'Palamu',y:totalPalamuQty},
            {label: 'Ramgarh',y:totalRamgarhQty},
            {label: 'Sahibganj',y:totalSahibganjQty},
            {label: 'Seraikela',y:totalSeraikelaQty},
            {label: 'Simdega',y:totalSimdegaQty},
            {label: 'West Singhbhum',y:totalsinghbhumQty},
        ];
        console.log(graphtotal_data);
        setgraph_data(graphtotal_data);
        setIsLoading(false);
    }
    const formatter = new Intl.NumberFormat('en-IN')
    const colors = ["#457e96", "#64b3a5", "#3b78b8","#8b629c", "#964554", "#b3b157",
    "#6bc788", "#9e4190", "#d4b74e","#61717a", "#ba6f3d", "#084d29",
    "#964554","#ba6f3d",  "#b3b157","#64b3a5",  "#6bc788",
    "#457e96", "#084d29","#3b78b8","#8b629c","#d4b74e","#61717a",
    "#9e4190",  ]
    const DataFormater = (number) => {
        if(number > 1000000000){
          return (number/1000000000).toString() + 'B';
        }else if(number > 1000000){
          return (number/1000000).toString() + 'M';
        }else if(number > 1000){
          return (number/1000).toString() + 'K';
        }else{
          return number.toString();
        }
      }
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}

            <Container>
                    <Row>
                    <Col lg={3} style={{marginTop:25}}>
                        <Title>Dashboard</Title>
                    </Col>
                    </Row>
                    <Row>
                    <Col className="conotent_text" lg={4}>
                    <Card  style={{backgroundColor: '#ff8080',borderRadius: 10,textAlign:'center'}} >
        <CardContent style={{paddingBottom:16}}>
          <h6 gutterBottom variant="h5" component="div">
           Farmers Registered
          </h6>
          <p>
          {formatter.format(totalmemberCount)}
          </p>
        </CardContent>
    </Card>                   
                    </Col>

                <Col className="conotent_text" lg={4}>
                    <Card  style={{backgroundColor: '#c2bdbd',borderRadius: 10,textAlign:'center'}} >
                    <CardContent style={{paddingBottom:16}}>
          <h6 gutterBottom variant="h5" component="div">
         Seed Distributed (kg)
          </h6>
          <p>
          {formatter.format(totaltileDistributed)}
          </p>
        </CardContent>
    </Card>                   
                    </Col>
                    
                 <Col className="conotent_text" lg={4}>
                    <Card  style={{backgroundColor: '#8080ff',borderRadius: 10,textAlign:'center'}} >
                    <CardContent style={{paddingBottom:16}}>
          <h6 gutterBottom variant="h5" component="div">
           Farmers Benefited
          </h6>
          <p>
          {formatter.format(totalfarmerBenifited)}
          </p>
        </CardContent>
    </Card>                   
     </Col>
         <Col lg={12}>
                        <Title>Today's Report</Title>
                    </Col>
                    <Col className="conotent_text" lg={4}>
                    <Card  style={{backgroundColor: '#c985df',borderRadius: 10,textAlign:'center'}} >
        <CardContent style={{paddingBottom:16}}>
          <h6 gutterBottom variant="h5" component="div">
           Farmers Registered
          </h6>
          <p>
          {formatter.format(todaytotalmemberCount)}
          </p>
        </CardContent>
    </Card>                   
                    </Col>

                <Col className="conotent_text" lg={4}>
                    <Card  style={{backgroundColor: '#a9e396',borderRadius: 10,textAlign:'center'}} >
                    <CardContent style={{paddingBottom:16}}>
          <h6 gutterBottom variant="h5" component="div">
         Seed Distributed (kg)
          </h6>
          <p>
          {formatter.format(todaytotaltileDistributed)}
          </p>
        </CardContent>
    </Card>                   
                    </Col>
                    
                 <Col className="conotent_text" lg={4}>
                    <Card  style={{backgroundColor: '#e1a294',borderRadius: 10,textAlign:'center'}} >
                    <CardContent style={{paddingBottom:16}}>
          <h6 gutterBottom variant="h5" component="div">
           Farmers Benefited
           </h6>
          <p>
          {formatter.format(todaytotalfarmerBenifited)}
          </p>
        </CardContent>
    </Card>                   
     </Col>
     <Col lg={3} style={{paddingLeft:0,paddingRight:0}}>
                    <label style={{marginTop:15,}}>Season</label>
                        <div className="row_div">
                            <div className="input_div" style={{ marginLeft: 0}}>
                                <select value={season} onChange={handleInput} name="season"
                                    style={{ margin: 0, background: 'transparent' }}>
                                    <option value="">All</option>
                                    {seasonData.length != 0 && <>
                                        {seasonData.map((item, index) => {
                                            return (
                                         <option value={item.CombYearSeason}>{item.CombYearSeason}</option>
                                            )
                                        })}
                                    </>}
                                </select>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} style={{paddingLeft:0,paddingRight:0}}>
                    <label style={{marginTop:15,}}>Component</label>

                        <div className="row_div">
                        <div className="input_div" >
                              
                            <select
                                name="seedId"
                                value={seedId}
                                onChange={handleInput}
                                style={{ margin: 0, background: 'transparent' }}>
                            <option value="">All</option>
                            {seedData.length!=0 &&
                                 <>
                                {seedData.map((item,index)=>{
                                    return (
                                        <option value={item.id}>{item.CropName}</option>
                                    )
                                })}
                            </>}
                              
                            </select>
                           
                            </div>

                        </div>
                    </Col>
                   <Col lg={2}style={{paddingLeft:0}}>
                        <div className="inputdiv">
                            <label style={{marginTop:15,}}>Start Date</label>
                            <br />
                            <input type="date"
                                name="start_date"
                                value={start_date}
                                placeholder="dd/mm/yyyy"
                                onChange={handleInput} />
                        </div>
                    </Col>
                    <Col lg={2} >
                        <div className="inputdiv">
                            <label style={{marginTop:15,}}>End Date</label>
                            <br />
                            <input type="date"  style={{width:"100%",marginLeft:0}}
                                name="end_date"
                                value={end_date}
                                placeholder="dd/mm/yyyy"
                                onChange={handleInput} />
                        </div>
                    </Col>
                    <Col lg={2} style={{ paddingRight: 0,marginTop:40 }}>
                          <button
                            className="add_button"
                            onClick={handlehitthheAPI}>
                            <p className="pcat_title">Show Curve</p>
                        </button>
                    </Col>
                    </Row>
            </Container>
            <Container style={{marginTop:6}}>                   
               {!isLoading && 
                <div style={{backgroundColor: 'white'}}>
                    {graph_det.length>0&&
                    <>
		    {/* <CanvasJSChart options={options}/>   */}
          {/*   <BarChart width={600} height={300} data={data}>
    <XAxis dataKey="label" tick={renderCustomAxisTick} />
    <YAxis />
    <Bar dataKey="uv" barSize={30} fill="#8884d8"
      label={renderCustomBarLabel}/>
  </BarChart> */}
  <ResponsiveContainer width={'100%'} height={500}>
  <BarChart isAnimationActive={false}   data={graph_det}  margin={{ top: 20, right: 20, left: 20, bottom: 20 }}  >
    <XAxis dataKey="label"  textAnchor= "end" 
     interval={0} angle= "-40" style={{marginBottom:12}} height={60} 
     tick={{ fontSize: '12px', width: '50px', wordWrap: 'break-word' }}/>
    <YAxis/>
    {/* <Tooltip wrapperStyle={{ width: 100,}} /> */}
    {/* <Legend width={100} 
    wrapperStyle={{ top: 40, right: 20, backgroundColor: '#f5f5f5', 
    borderRadius: 3, lineHeight: '40px' }} /> */}
    {/* <CartesianGrid stroke="#ccc" strokeDasharray="1 1" /> */}
    {/*  <Bar dataKey="y"  interval='0' fill="#00a0fc"
  margin={{
      top: 0,
      right: 0,
      left: 0,
      bottom: 120
    }}/>  */}
      <Bar dataKey="y">
    {
      graph_det.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={colors[index]} />
        ))
    }
        <LabelList dataKey="y" 
 position="top" />
  </Bar> 
  </BarChart>
  </ResponsiveContainer> 
            </>
                    }
                    {graph_det.length == 0&&
                   <p className="no_Data_table">No Data Found</p>
                     }
	         	</div>
                }
            </Container>
           
            <ToastContainer />
        </Wrapper>
    )
}
export default DashboardAo