import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./styles";
import {
  Row,
  Container,
  Col,
 
} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle";
import { LoadingSpinner } from "../LoadingSpinner/index";
import ReactPaginate from "react-paginate";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import View from "../../images/view.svg";


const FarmerApplicationDetailsByDHO = () => {
  const { isExpanded } = useContext(UserContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [offset, setOffSet] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [orgtableData, setOrgTableData] = useState([]);
  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [searchKey, setSearchKey] = useState("n");
  const [searchKeyTable, setSearchKeyTable] = useState("");
  const [role, setRole] = useState("");
  const [activities, setActivities] = useState([]);
  const [schemes, setSchemes] = useState([]);
  const [activityName, setActivityName] = useState("");
  const [schemeName, setSchemeName] = useState("");
  const [dhostatus, setDhoStatus] = useState("");
  const [aoStatus, setAOStatus] = useState("");
  const [districts, setDistricts] = useState([]);
  const [districtName, setDistrictName] = useState([]);
  const [userId, setUserId] = useState("");

 
  const handleInput = async (e) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;

    if (name == "activityName") {
      setActivityName(value);
      if (role.toLowerCase() === "dao" || role.toLowerCase() === "dho") {
        setIsLoading(true);

        const result = await API_Auth.getListDataFarmersByDHO(
          userId,
          aoStatus,
          dhostatus,
          value,
          schemeName
        );

        setIsLoading(false);
        setTableData(result);
        setOrgTableData(result);
        setIsLoading(false);
        setSearchData(result);
      } else if (role.toLowerCase() === "ao") {
        setIsLoading(true);

        const result = await API_Auth.getListDataFarmersByAO(
          userId,
          aoStatus,
          dhostatus,
          value,
          schemeName,
          districtName
        );
        console.log("result", result);
        setIsLoading(false);
        setTableData(result);
        setOrgTableData(result);
        setIsLoading(false);
        setSearchData(result);
      }
    }
    if (name == "schemeName") {
      setSchemeName(value);
      if (role.toLowerCase() === "dao" || role.toLowerCase() === "dho") {
        setIsLoading(true);
        const result = await API_Auth.getListDataFarmersByDHO(
          userId,
          aoStatus,
          dhostatus,
          activityName,
          value
        );

        setIsLoading(false);
        setTableData(result);
        setOrgTableData(result);
        setIsLoading(false);
        setSearchData(result);
      } else if (role.toLowerCase() === "ao") {
        setIsLoading(true);

        const result = await API_Auth.getListDataFarmersByAO(
          userId,
          aoStatus,
          dhostatus,
          activityName,
          value,
          districtName
        );
        console.log("result", result);
        setIsLoading(false);
        setTableData(result);
        setOrgTableData(result);
        setIsLoading(false);
        setSearchData(result);
      }
    }
    if (name == "districtName") {
      setDistrictName(value);
      setIsLoading(true);

      const result = await API_Auth.getListDataFarmersByAO(
        userId,
        aoStatus,
        dhostatus,
        activityName,
        schemeName,
        value
      );
      console.log("result", result);
      setIsLoading(false);
      setTableData(result);
      setOrgTableData(result);
      setIsLoading(false);
      setSearchData(result);
    }
    if (name == "dhostatus") {
      setDhoStatus(value);
      setIsLoading(true);

      if (role.toLowerCase() === "dao" || role.toLowerCase() === "dho") {
        const result = await API_Auth.getListDataFarmersByDHO(
          userId,
          aoStatus,
          value,
          activityName,
          schemeName
        );

        setIsLoading(false);
        setTableData(result);
        setOrgTableData(result);
        setIsLoading(false);
        setSearchData(result);
      } else if (role.toLowerCase() === "ao") {
        setIsLoading(true);

        const result = await API_Auth.getListDataFarmersByAO(
          userId,
          aoStatus,
          value,
          activityName,
          schemeName,
          districtName
        );
        console.log("result", result);
        setIsLoading(false);
        setTableData(result);
        setOrgTableData(result);
        setIsLoading(false);
        setSearchData(result);
      }
    }
    if (name === "aoStatus") {
      setAOStatus(value);
      setIsLoading(true);

      if (role.toLowerCase() === "dao" || role.toLowerCase() === "dho") {
        const result = await API_Auth.getListDataFarmersByDHO(
          userId,
          value,
          dhostatus,
          activityName,
          schemeName
        );

        setIsLoading(false);
        setTableData(result);
        setOrgTableData(result);
        setIsLoading(false);
        setSearchData(result);
      } else if (role.toLowerCase() === "ao") {
        setIsLoading(true);

        const result = await API_Auth.getListDataFarmersByAO(
          userId,
          value,
          dhostatus,
          activityName,
          schemeName,
          districtName
        );
        console.log("result", result);
        setIsLoading(false);
        setTableData(result);
        setOrgTableData(result);
        setIsLoading(false);
        setSearchData(result);
      }
    }
    
  };
  const handleSearch = (e) => {
    let filterValue = e.target.value;
    setCurrentPage(0);
    if (filterValue == "") {
      let offsetsearch = 0;
      var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
      setPageCount(Math.ceil(orgtableData.length / perPage));
      setOrgTableData(orgtableData);
      setTableData(slice);
      setSearchData(orgtableData);
      setOffSet(offsetsearch);
      setCurrentPage(0);
      setSearchKey("n");
    } else {
      let offsetsearch = 0;
      const filteredData = orgtableData.filter((item) => {
        return (
          item?.blockName?.toLowerCase().includes(filterValue.toLowerCase()) ||
          item?.memberCount
            ?.toString()
            .toLowerCase()
            .includes(filterValue.toLowerCase())
        );
      });

      const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
      setPageCount(Math.ceil(filteredData.length / perPage));
      setSearchKey("y");
      setOffSet(offsetsearch);
      setSearchKeyTable(filteredData);
      setTableData(slice);
      setSearchData(filteredData);
      setOrgTableData(filteredData);
    }
  };
  useEffect(() => {
    const id = localStorage.getItem("UserID");
    console.log("id", id);
    setUserId(id);
    const role = localStorage.getItem("loggedRole");
    console.log("role", role);
    setRole(role);

    setIsLoading(true);

    const getListData = async () => {
      let activities = await API_Auth.getActivities();
      console.log("activities", activities);
      setActivities(activities);
      let schemes = await API_Auth.getSchemes();
      setSchemes(schemes);
      const districts = await API_Auth.getDistricts();
      setDistricts(districts);

      if (role === "DHO" || role === "DAO") {
        const result = await API_Auth.getListDataFarmersByDHO(
          id,
          aoStatus,
          dhostatus,
          activityName,
          schemeName
        );
        console.log("result", result);
        setIsLoading(false);
        setTableData(result);
        setOrgTableData(result);
        setIsLoading(false);
        setSearchData(result);
      } else if (role.toLowerCase() === "ao") {
        const result = await API_Auth.getListDataFarmersByAO(
          id,
          aoStatus,
          dhostatus,
          activityName,
          schemeName,
          districtName
        );
        console.log("result", result);
        setIsLoading(false);
        setTableData(result);
        setOrgTableData(result);
        setIsLoading(false);
        setSearchData(result);
      }
    };
    getListData()
      // make sure to catch any error
      .catch(console.error);
  }, [role]);

  
  const handlerowsperPage = (e) => {
    let showperpage = e.currentTarget.value;
    setPerPage(parseInt(showperpage));
    setCurrentPage(0);
    if (searchKey == "y") {
      const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
      setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value));
      setTableData(slice);
      setOffSet(0);
      setSearchData(searchKeyTable);
    } else {
      const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
      setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value));
      setTableData(slice);
      setOffSet(0);
      setSearchData(orgtableData);
    }
  };
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offsetdata = selectedPage * perPage;
    setCurrentPage(selectedPage);
    setOffSet(offsetdata);
    loadMoreData(offsetdata);
  };

  const loadMoreData = (offsetdata) => {
    const data = orgtableData;
    if (searchKey == "y") {
      const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
      setPageCount(Math.ceil(searchKeyTable.length / perPage));
      setTableData(slice);
      setOffSet(offsetdata);
      setSearchData(searchKeyTable);
    } else {
      const slice = data.slice(offsetdata, offsetdata + perPage);
      setPageCount(Math.ceil(data.length / perPage));
      setOffSet(offsetdata);
      setTableData(slice);
      setSearchData(data);
    }
  };
  
  const handleViewDetails = (obj) => {
    navigate("/farmer-application-details", { state: { famerdetails: obj } });
  };

  const handdleClearFilter = async () => {
    setActivityName("");
    setSchemeName("");
    setAOStatus("");
    setDhoStatus("");
    setDistrictName("");
    if (role.toLowerCase() == "dao" || role.toLowerCase() == "dho") {
      setIsLoading(true);

      const result = await API_Auth.getListDataFarmersByDHO(
        userId,
        '',
        '',
        '',
        ''
      );
      console.log("result", result);
      setIsLoading(false);
      setTableData(result);
      setOrgTableData(result);
      setIsLoading(false);
      setSearchData(result);
    } else if (role.toLowerCase == "ao") {
      setIsLoading(true);

      const result = await API_Auth.getListDataFarmersByAO(
        userId,
        '',
        '',
        '',
        '',
        ''
      );
      console.log("result", result);
      setIsLoading(false);
      setTableData(result);
      setOrgTableData(result);
      setIsLoading(false);
      setSearchData(result);
    }
  };
  const handleAddFarmerApplication = () => {
    navigate("/farmer-application-ao")
  };

  return (
    <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
      {isLoading && <LoadingSpinner />}

      <Container>
        <Row>
          <Col lg={3} style={{ paddingLeft: 0, marginTop: 24 }}>
            <Title>Farmer Application Details</Title>
          </Col>
          <Col lg={3}></Col>
          {role.toLowerCase()=="ao" && <Col lg={3} style={{ marginTop: 25 }}>
            <div>
              <button
                className="add_button"
                onClick={() => handleAddFarmerApplication()}
              >
                <p className="pcat_title">CREATE NEW APPLICTION</p>
              </button>
            </div>
          </Col>
}
         <Col lg={2} style={{ marginTop: 25 }}>
            <button className="add_button" onClick={() => handdleClearFilter()}>
              <p className="pcat_title">Clear All Filters</p>
            </button>
          </Col>

        </Row>
        <Row>
          <Col lg={2} style={{ paddingLeft: 0, marginTop: 24 }}>
            <label>Acitivity </label>
            <div className="row_div">
              <div className="input_div">
                <select
                  onChange={handleInput}
                  name="activityName"
                  value={activityName}
                  style={{ margin: 0, background: "transparent" }}
                >
                  <option value="">Select Activity</option>
                  {activities.length !== 0 && (
                    <>
                      {activities.map((item, index) => {
                        return (
                          <option value={item.id}>{item.HortiActivity}</option>
                        );
                      })}
                    </>
                  )}
                </select>
              </div>
            </div>
          </Col>
          <Col lg={2} style={{ paddingLeft: 0, marginTop: 24 }}>
            <label>Scheme </label>
            <div className="row_div">
              <div className="input_div">
                <select
                  onChange={handleInput}
                  name="schemeName"
                  value={schemeName}
                  style={{ margin: 0, background: "transparent" }}
                >
                  <option value="">Select Scheme</option>
                  {schemes.length !== 0 && (
                    <>
                      {schemes.map((item, index) => {
                        return (
                          <option value={item.id}>{item.TypeOfScheme}</option>
                        );
                      })}
                    </>
                  )}
                </select>
              </div>
            </div>
          </Col>
          {role.toLowerCase() === "ao" && (
            <Col lg={2} style={{ paddingLeft: 0, marginTop: 24 }}>
              <label>District </label>
              <div className="row_div">
                <div className="input_div">
                  <select
                    onChange={handleInput}
                    name="districtName"
                    value={districtName}
                    style={{ margin: 0, background: "transparent" }}
                  >
                    <option value="">Select District</option>
                    {districts.length != 0 && (
                      <>
                        {districts.map((item, index) => {
                          return (
                            <option value={item.id}>
                              {item.v_districtName}
                            </option>
                          );
                        })}
                      </>
                    )}
                  </select>
                </div>
              </div>
            </Col>
          )}
          <Col lg={3} style={{ paddingLeft: 0, marginTop: 24 }}>
            <label>DHO Status </label>
            <div className="row_div">
              <div className="input_div">
                <select
                  onChange={handleInput}
                  name="dhostatus"
                  value={dhostatus}
                  style={{ margin: 0, background: "transparent" }}
                >
                  <option value="">Select DHO Status</option>

                  <option value={"Pending"}>Pending</option>
                  <option value={"Approved"}>Approved</option>
                  <option value={"Reject"}>Reject</option>
                </select>
              </div>
            </div>
          </Col>
          <Col lg={3} style={{ paddingLeft: 0, marginTop: 24 }}>
            <label>AO Status </label>
            <div className="row_div">
              <div className="input_div">
                <select
                  onChange={handleInput}
                  name="aoStatus"
                  value={aoStatus}
                  style={{ margin: 0, background: "transparent" }}
                >
                  <option value="">Select AO Status</option>

                  <option value={"Pending"}>Pending</option>
                  <option value={"Approved"}>Approved</option>
                  <option value={"Reject"}>Reject</option>
                </select>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container style={{ marginTop: 6 }}>
        <Row>
          <Col lg={12} sm={12} md={12} className="table_head">
                     <Table className="tablelist" responsive>
              <thead>
                <tr className="tablehead_small">
                  <th> Application No</th>
                  <th> Farmer Name</th>
                  <th> Mobile</th>
                  {role.toLowerCase() === "ao" && <th>District</th>}

                  <th> Age</th>
                  <th> Area in(Hectare)</th>
                  <th>Plot No </th>
                  <th>Activity Name</th>
                  <th>Scheme Name</th>

                  <th>Application Status DHO</th>
                  <th>Reject Reason DHO</th>
                  <th>Application Status AO</th>
                  <th>Reject Reason AO</th>


                  <th style={{ textAlign: "right" }}>Action</th>
                </tr>
              </thead>
              {tableData.length == 0 && (
                <tbody>
                  <tr>
                    <td colSpan="12">
                      <p className="no_Data_table">No Data Found</p>
                    </td>
                  </tr>
                </tbody>
              )}
              {tableData.length != 0 && (
                <tbody>
                  {tableData.map((obj, index) => {
                    return (
                      <>
                        <tr key={index} className="tabletd_small">
                          <td>{obj.ApllicationNo}</td>
                          <td>{obj.farmerName}</td>
                          <td>{obj.farmerCno}</td>
                          {role.toLowerCase() === "ao" && (
                            <td>{obj.districtName}</td>
                          )}

                          <td>{obj.Age}</td>
                          <td>{obj.Area}</td>
                          <td>{obj.PlotNo}</td>
                          <td>{obj.activityName}</td>
                          <td>{obj.scheameName}</td>
                          <td>{obj.ApplicationStatusDho}</td>
                          <td>{obj.RejectReasonDHO}</td>

                          <td>{obj.ApplicationStatusAO}</td>
                          <td>{obj.RejectReasonAO}</td>


                          <td style={{ textAlign: "right" }}>
                            <div
                              className="row_table_edit"
                              style={{ background: "antiquewhite" }}
                              onClick={() =>
                                //handleViewRequestDetails(obj, "view");
                                handleViewDetails(obj)
                              }
                            >
                              <img src={View} />
                              <p>VIEW</p>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              )}
            </Table>
            {tableData.length != 0 && (
              <div>
                <div className="pagination_row_div">
                  <p className="pagination_title">Showing items per page:</p>
                  <div className="pagination_input_div">
                    <select
                      className="pagination_select"
                      style={{ margin: 0, background: "transparent" }}
                      name="pagination_value"
                      value={perPage}
                      onChange={handlerowsperPage}
                    >
                      {/*                                             <option value={4}>4</option> */}
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                  </div>
                </div>
                <div className="pagination_row_right_div">
                  <p className="pagination_total">
                    Showing {offset + 1} to{" "}
                    {searchData.length < offset + perPage && (
                      <span>{searchData.length}</span>
                    )}
                    {searchData.length > offset + perPage && (
                      <span>{offset + perPage} </span>
                    )}{" "}
                    of {searchData.length} items
                  </p>
                  <ReactPaginate
                    previousLabel={
                      <ArrowBackIosIcon
                        className="pagination_prev_btn"
                        color="#2438a7"
                      />
                    }
                    nextLabel={
                      <ArrowForwardIosIcon
                        className="pagination_next_btn"
                        color="#2438a7"
                      />
                    }
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    forcePage={currentPage}
                  />
                </div>
              </div>
            )}{" "}
          </Col>
        </Row>
      </Container>
           <ToastContainer />
    </Wrapper>
  );
};
export default FarmerApplicationDetailsByDHO;
