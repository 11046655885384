import styled from "styled-components";

export const Wrapper = styled.div`
background: #fff;
margin-top: 48px;
.pseudo_border {
  position: relative;
  display: inline-block;
  font-weight: 600;
  line-height: 1.2;
  font-size: 48px;
}
.margin_top_btm{
  margin-bottom: 3rem!important;
  margin-top: 3rem!important;
}
.card_body{
  flex: 1 1 auto;
    padding: 1.3rem 1.3rem;
    height: 100%;
}
.roundedImage{
  width: 150px;
  margin-bottom: 1.5rem!important;
  border-radius: 50%!important;
  vertical-align: middle;
  border-style: none;
}
.pseudo_border:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  margin: 10px auto;
  width: 90px;
  height: 3px;
  background: #247158;
}
.text_center{
  text-align: center!important;
}
`