import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./NiceSeeds.styles";
import API_Auth from "../../API_Auth";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingSpinner } from "../LoadingSpinner/index";
import { Container, Row, Col } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Table from "react-bootstrap/Table";
import { Title } from "../../GlobalStyle";

const NiceSeeds = () => {
  const navigate = useNavigate();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const { isExpanded } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);

  const [cropName, setCropName] = useState("");
  const [cropNameErr, setCropNameErr] = useState("");

  const [variety, setVariety] = useState("");
  const [varietyErr, setVarietyErr] = useState("");

  const [seasons, setSeasons] = useState([]);
  const [year, setYear] = useState("");
  const [yearErr, setYearErr] = useState("");
  const [seedData, setSeedData] = useState([]);

  const [seedText, setSeedText] = useState("a");
  const [seedsSuggestion, setSeedsSuggestion] = useState([]);

  const [seedErr, setSeedErr] = useState("");
  const [cropId, setCropId] = useState("");
  const [varieties, setVarieties] = useState("");
  const [varietyID, setVarietyID] = useState("");
  const [offset, setOffSet] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [orgtableData, setOrgTableData] = useState([]);
  const [perPage, setPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [searchKey, setSearchKey] = useState("n");
  const [searchKeyTable, setSearchKeyTable] = useState("");
  const [searchData, setSearchData] = useState([]);

  const [daoSeedData, setDaoSeedData] = useState("");
  const handleBackClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchSeeds = async () => {
      const seeds = await API_Auth.getSeeds();
      console.log("seeds", seeds);
      setSeedData(seeds);

      const seasondata = await API_Auth.getSeasons();
      console.log("result", seasondata);
      setSeasons(seasondata);

      let daoid = localStorage.getItem("UserID");

      const result = await API_Auth.getSeedsByDao(daoid);
      console.log("resu", result);
      setSearchData(result);
      setIsLoading(false);
      //seting pagenation data
      var slice = result.slice(offset, offset + perPage);
      setPageCount(Math.ceil(result.length / perPage));
      setOrgTableData(result);
      setTableData(slice);

      console.log(result);
      setDaoSeedData(result);
    };
    // call the function
    fetchSeeds()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  const handlerowsperPage = (e) => {
    let showperpage = e.currentTarget.value;
    setPerPage(parseInt(showperpage));
    setCurrentPage(0);
    if (searchKey == "y") {
      const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
      setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value));
      setTableData(slice);
      setOffSet(0);
      setSearchData(searchKeyTable);
    } else {
      const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
      setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value));
      setTableData(slice);
      setOffSet(0);
      setSearchData(orgtableData);
    }
  };
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offsetdata = selectedPage * perPage;
    setCurrentPage(selectedPage);
    setOffSet(offsetdata);
    loadMoreData(offsetdata);
  };

  const loadMoreData = (offsetdata) => {
    const data = orgtableData;
    if (searchKey == "y") {
      const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
      setPageCount(Math.ceil(searchKeyTable.length / perPage));
      setTableData(slice);
      setOffSet(offsetdata);
      setSearchData(searchKeyTable);
    } else {
      const slice = data.slice(offsetdata, offsetdata + perPage);
      setPageCount(Math.ceil(data.length / perPage));
      setOffSet(offsetdata);
      setTableData(slice);
      setSearchData(data);
    }
  };

  const handleLoadPage = async () => {
    setCropName("");
    setCropId("");
    setVariety("");
    setVarietyID("");
    setYear("");
    setDisableSubmit(false);

    let daoid = localStorage.getItem("UserID");

    const result = await API_Auth.getSeedsByDao(daoid);
    console.log("resu", result);
    setSearchData(result);
    setIsLoading(false);
    //seting pagenation data
    var slice = result.slice(offset, offset + perPage);
    setPageCount(Math.ceil(result.length / perPage));
    setOrgTableData(result);
    setTableData(slice);
  };
  const handleAddSeed = async () => {
    let error = false;

    if (cropName == "") {
      setCropNameErr("This field is required");
      error = true;
    } else {
      setCropNameErr("  ");
    }
    if (variety == "") {
      setVarietyErr("This field is required");
      error = true;
    } else {
      setVarietyErr("  ");
    }
    if (year == "") {
      setYearErr("This field is required");
      error = true;
    } else {
      setYearErr("  ");
    }
    if (!error) {
      setDisableSubmit(true);
      console.log(year);
      const myArray = year.split("-");
      console.log("myArray", myArray);
      let year_split = myArray[0];
      let year_season = myArray[1];

      console.log(daoSeedData);
      console.log(cropId,varietyID,year_split,year_season);

      let duplicateerror=false
      /* for(var i=0;i<daoSeedData.length;i++){
        if(parseInt(daoSeedData[i].seedID)==cropId && parseInt(daoSeedData[i].varietyID)==varietyID && daoSeedData[i].Year==year_split &&  daoSeedData[i].Season == year_season){
            duplicateerror=true;
            console.log("duplicateerror",duplicateerror)
             }
      } */
      console.log("duplicateerror",duplicateerror)

      if(!duplicateerror){
         setIsLoading(true);
        setDisableSubmit(true);
      let daoid = localStorage.getItem("UserID");
      let result = await API_Auth.addSeedByDao(
        daoid,
        cropId,
        varietyID,
        year_split,
        year_season
      );
      let res = result[0];
      if (res.Flag == "Success") {
        setIsLoading(false);
        toast.success("Seed added successfully");
        handleLoadPage();
      }
      else if(res.Flag == "Fail") {
        setIsLoading(false);
        toast.error("Duplicate Entry");
        setTimeout(() => {
          setDisableSubmit(false);
        }, 6000)
      }  
      else {
        setIsLoading(false);
        setDisableSubmit(false);
        toast.error("Something went wrong please try again");
      }  
    }
    else{
        toast.error("Duplicate Entry");
        setTimeout(() => {
          setDisableSubmit(false);
        }, 6000)
    }
}
  };
  const handleSeedChange = async (item) => {
    console.log("item", item);
    setCropId(item.id);
    const varities = await API_Auth.getVarietyBySeed(item.CropName);
    console.log(varities);
    setVarieties(varities);
    setCropName(item.CropName);
    setSeedText("a");
  };
  const handleInput = async (e) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;
    if (name === "cropName") {
      if (value.length == 0) {
        setSeedsSuggestion(seedData);
        setCropName(value);
      } else {
        const suggestdta = seedData.filter((item) => {
          return item?.CropName?.toLowerCase().includes(value.toLowerCase());
        });
        setCropName(value);
        setSeedText("f");

        if (suggestdta.length == 0) {
          setSeedErr("Seed not found");
        } else {
          setSeedErr("");
        }
        setSeedsSuggestion(suggestdta);
      }
    }
    if (name === "variety") {
      setVariety(value);
      const data = varieties.filter((data) => data.Variety == value);
      console.log(data);

      setVarietyID(data.length != 0 ? data[0].id : "");
    }

    if (name === "year") setYear(value);
  };
  const renderItemSuggestions = () => {
    if (seedsSuggestion.length == 0) {
      return null;
    }
    return (
      <ul
        className={
          seedsSuggestion.length != 0 && seedText == "f"
            ? "ul_search"
            : "ul_search_length"
        }
      >
        {seedsSuggestion.length != 0 && seedText == "f" && (
          <>
            {seedsSuggestion.map((item, index) => {
              return (
                <li
                  className="li_search"
                  onClick={() => handleSeedChange(item)}
                >
                  {item.CropName}
                </li>
              );
            })}
          </>
        )}
      </ul>
    );
  };
  return (
    <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
      <Container>
        <Row className="row_title">
          <Col lg={4}>
            <p>My Seeds</p>
          </Col>
          <Col lg={6}></Col>
          <Col lg={2}>
            <div className="back_button" onClick={() => handleBackClick()}>
              <IoIosArrowBack size={18} />
              <h6>Back</h6>
            </div>
          </Col>
        </Row>
      </Container>
      {isLoading && <LoadingSpinner />}

      <hr />
      <Container>
        <Row>
          <Col lg={6} md={6} sm={6}>
            <div className="inputdiv">
              <label>Component Name</label>
              <br />
              <div className="row_div_search">
                <div className="input_div">
                  <input
                    type="type"
                    style={{
                      margin: 0,
                      background: "transparent",
                      width: "75%",
                    }}
                    name="cropName"
                    value={cropName}
                    onChange={handleInput}
                    placeholder="Component"
                  />
                  <AiOutlineSearch
                    size={24}
                    style={{ margin: 10, cursor: "pointer" }}
                    color="lightgray"
                  />
                </div>
              </div>
              <>{renderItemSuggestions()}</>

              {cropNameErr != "" && (
                <p className="alert-message">{cropNameErr}</p>
              )}
            </div>
          </Col>
          <Col lg={6} md={6} sm={6}>
            <div className="inputdiv">
              <label>Type</label>
              <br />
              {/*   <input
                type="text"
                name="variety"
                value={variety}
                placeholder="Enter variety"
                onChange={handleInput}
                style={{ width: "75%" }}
              /> */}
              <select
                value={variety}
                name="variety"
                onChange={handleInput}
                style={{ width: "75%" }}
              >
                <option value="">Select Type</option>

                {varieties.length != 0 && (
                  <>
                    {varieties.map((item, index) => {
                      return (
                        <option value={item.Variety}>{item.Variety}</option>
                      );
                    })}
                  </>
                )}
              </select>

              {varietyErr != "" && (
                <p className="alert-message">{varietyErr}</p>
              )}
            </div>
          </Col>
          <Col lg={6} md={6} sm={6}>
            <div className="inputdiv">
              <label>Year & Season</label>
              <br />

              <select
                value={year}
                name="year"
                onChange={handleInput}
                style={{ width: "75%" }}
              >
                <option value="">Select Year and Season</option>

                {seasons.length != 0 && (
                  <>
                    {seasons.map((item, index) => {
                      return (
                        <option value={item.CombYearSeason}>
                          {item.CombYearSeason}
                        </option>
                      );
                    })}
                  </>
                )}
              </select>

              {yearErr != "" && <p className="alert-message">{yearErr}</p>}
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={5}>
            <div className="row_div">
              <button
                className="add_button"
                disabled={disableSubmit}
                onClick={() => handleAddSeed()}
                style={{
                  margin: 12,
                  width: "92%",
                }}
              >
                <p className="pcat_title">ADD MORE</p>
              </button>
            </div>
          </Col>
        </Row>
      </Container>

      <Container>
        <Title>Seed Information</Title>
        <Row>
          <Col lg={12} sm={12} md={12} className="table_head">
            <Table className="tablelist" responsive>
              <thead>
                <tr>
                  <th>Component</th>
                  <th>Type</th>
                  <th>Year</th>
                  <th>Season</th>
                </tr>
              </thead>
              {tableData.length == 0 && (
                <tbody>
                  <tr>
                    <td colSpan="12">
                      <p className="no_Data_table">No Data Found</p>
                    </td>
                  </tr>
                </tbody>
              )}
              {tableData.length != 0 && (
                <tbody>
                  {tableData.map((obj, index) => {
                    return (
                      <>
                        <tr key={index}>
                          {/*                                                 <td>{obj.id}</td> */}
                          <td>{obj.SeedName}</td>
                          <td>{obj.varietyName}</td>

                          <td>{obj.Year}</td>
                          {/*                                                 <td>{obj.Season}</td> */}
                          <td>{obj.Season}</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              )}
            </Table>
            {tableData.length != 0 && (
              <div>
                <div className="pagination_row_div">
                  <p className="pagination_title">Showing items per page:</p>
                  <div className="pagination_input_div">
                    <select
                      className="pagination_select"
                      style={{ margin: 0, background: "transparent" }}
                      name="pagination_value"
                      value={perPage}
                      onChange={handlerowsperPage}
                    >
                      <option value={4}>4</option>
                      <option value={8}>8</option>
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                    </select>
                  </div>
                </div>
                <div className="pagination_row_right_div">
                  <p className="pagination_total">
                    Showing {offset + 1} to{" "}
                    {searchData.length < offset + perPage && (
                      <span>{searchData.length}</span>
                    )}
                    {searchData.length > offset + perPage && (
                      <span>{offset + perPage} </span>
                    )}{" "}
                    of {searchData.length} items
                  </p>
                  <ReactPaginate
                    previousLabel={
                      <ArrowBackIosIcon
                        className="pagination_prev_btn"
                        color="#2438a7"
                      />
                    }
                    nextLabel={
                      <ArrowForwardIosIcon
                        className="pagination_next_btn"
                        color="#2438a7"
                      />
                    }
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    forcePage={currentPage}
                  />
                </div>
              </div>
            )}{" "}
          </Col>
        </Row>
      </Container>

      <ToastContainer />
    </Wrapper>
  );
};
export default NiceSeeds;
