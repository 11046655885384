import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./Users.styles"
import { Row, Container, Col } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import Edit from "../../images/edit.png";
import plus from "../../images/white_plus.png"
import { useNavigate, Link } from "react-router-dom";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle"
import { LoadingSpinner } from "../LoadingSpinner/index";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Delete from "../../images/delete.png"
import * as FileSaver from 'file-saver';

import * as XLSX from 'xlsx';


const Users = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);


    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey, setSearchKey] = useState('n');
    const [searchKeyTable, setSearchKeyTable] = useState('');

    const handleSearch = (e) => {
        let filterValue = e.target.value;
        setCurrentPage(0);
        if (filterValue == '') {
            let offsetsearch = 0;
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setTableData(slice);
            setSearchData(orgtableData);
            setOffSet(offsetsearch);
            setCurrentPage(0);
            setSearchKey('n');
        }
        else {
            let offsetsearch = 0;
            const filteredData = orgtableData.filter(item => {
                return (item ?.FirstName ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                    item ?.LastName ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                        item ?.email ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                            item ?.Designation ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                                item ?.Role ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                                    item ?.UserName ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                                        item ?.UserName ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                                            item ?.Block ?.toLowerCase().includes(filterValue.toLowerCase()) ||



                                                item ?.District ?.toLowerCase().includes(filterValue.toLowerCase()))
            });

            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            setOffSet(offsetsearch);
            setSearchKeyTable(filteredData);
            setTableData(slice);
            setSearchData(filteredData);
        }
    }

    const handleEditUser = (obj) => {
        navigate("/add-user-detail/" + obj.id);
    }
    const handleAddUser = () => {
        navigate("/add-user-detail/add");

    }

    const handleDeleteUser = (obj) => {

        console.log(obj)
        if (window.confirm('Are you sure you want to delete the User ?')) {
            // Save it!
            const fetchDeleteUser = async () => {
                setIsLoading(true);

                let result = await API_Auth.deleteUser(obj.id);
                let res = result[0];
                if (res.Flag == 'Success') {
                    setIsLoading(false);

                    toast.success('User Deleted successfully');
                    const fetchAsset = async () => {
                        let result = await API_Auth.getUsers();

                        setUsers(result);
                        setIsLoading(false);
                        var slice = result.slice(offset, offset + perPage);
                        setPageCount(Math.ceil(result.length / perPage));
                        setOrgTableData(result);
                        setTableData(slice);
                        setSearchData(result);
                    };
                    // call the function
                    fetchAsset()
                        // make sure to catch any error
                        .catch(console.error);

                } else {
                    toast.error('Something went wrong please try again');
                }

            };
            // call the function
            fetchDeleteUser()
                // make sure to catch any error
                .catch(console.error);
        } else {
            // Do nothing!
        }



    }
    useEffect(() => {
        setIsLoading(true);

        const fetchAsset = async () => {
            let result = await API_Auth.getUsers();
            setUsers(result);
            setSearchData(result);
            setIsLoading(false);
            //seting pagenation data
            var slice = result.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result.length / perPage));
            setOrgTableData(result);
            setTableData(slice);
        };
        // call the function
        fetchAsset()
            // make sure to catch any error
            .catch(console.error);
    }, []);
    const exportToCSV = (users_list) => {
        users_list.forEach(function(obj) {
            delete obj?.UserName
            delete obj?.DistrictID
        });
        console.log(users_list);
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        const fileExtension = '.xlsx';
        const fileName = 'UserDetails'

        const ws = XLSX.utils.json_to_sheet(users_list);

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        const data = new Blob([excelBuffer], { type: fileType });

        FileSaver.saveAs(data, fileName + fileExtension); 
    }
    const handlerowsperPage = (e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(searchKeyTable);
        }
        else {
            const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(orgtableData);
        }
    }
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        const data = orgtableData;
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setOffSet(offsetdata);
            setSearchData(searchKeyTable);
        } else {
            const slice = data.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(data.length / perPage))
            setOffSet(offsetdata);
            setTableData(slice);
            setSearchData(data);
        }
    }

    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}

            <Container style={{ marginTop: 42 }}>
                <Row>
                    <Col lg={4} style={{ paddingLeft: 0 }}>
                        <Title>Users</Title>
                    </Col>
                    <Col lg={5}>
                        <div className="row_div">
                            {/*                             <p className="title">Filter By:</p> */}
                            <div className="input_div" >
                                <input type="type" style={{ margin: 0, background: 'transparent' }}
                                    name="filterValue"
                                    //    value={filterValue}
                                    onChange={(e) => handleSearch(e)}
                                    placeholder="Search" />
                                <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                            </div>

                        </div>
                    </Col>
                    <Col lg={3} style={{ paddingRight: 0 }}>
                        <div>
                            <button className="add_button" onClick={() => handleAddUser()}>
                                {/*                                 <img src={plus} style={{ margin: 5 }} /> */}
                                <p className="pcat_title"> ADD A NEW USER</p></button>
                        </div></Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
                    {tableData.length >0 &&
                           <Row>
                      <Col lg={10}></Col>
                     <Col lg={2} >
                        <button
                            className="export_button"
                            onClick={(e) => exportToCSV(users)}>
                            <p className="pcat_title">Export CSV</p>
                        </button>
                    </Col>
                   </Row>
                        }
                        <Table responsive>
                            <thead>
                                <tr className="table_wrap">
{/*                                     <th>Id</th> */}
                                    <th>First Name </th>
                                    <th>Last Name</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    <th>Role</th>
                                    <th>Designation</th>
                                    <th>Category</th>
                                    <th>Department</th>
                                    <th>District</th>
                                    <th>Block</th>
                                    <th>Purchaser</th>
                                    <th style={{ textAlign: 'right' }}>Action</th>
                                </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {tableData.length != 0 && <tbody>
                                {tableData.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index} className="table_wrap">
{/*                                                 <td>{obj.id}</td> */}
                                                <td>{obj.FirstName}</td>
                                                <td>{obj.LastName}</td>

                                                <td>{obj.email}</td>
                                                <td>{obj.ContactNo}</td>
                                                <td>{obj.Role}</td>
                                                <td>{obj.Designation}</td>
                                                <td>{obj.category}</td>
                                                <td>{obj.Department}</td>
                                                <td>{obj.District}</td>
                                                <td>{obj.Block}</td>
                                                <td>{obj.Purchaser}</td>


                                                <td style={{ textAlign: 'right' }}>
                                                    {/*  <div  onClick={() => handleEditUser(obj)}>
                                                        <img src={Edit}  />
                                                      
                                                    </div>
                                                    <div  onClick={() => handleDeleteUser(obj)} style={{ marginRight: 10 }}>
                                                        <img src={Delete} />
                                                        {/* <p>DELETE</p> 
                                                    </div> */}
                                                    <div>
                                                        <img src={Edit} onClick={() => handleEditUser(obj)} style={{ width: 20, height: 20, margin: 5, cursor: 'pointer' }} />
                                                        <img src={Delete} onClick={() => handleDeleteUser(obj)} style={{ width: 20, height: 20, margin: 5, cursor: 'pointer' }} />
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                            }
                        </Table>
                        {tableData.length != 0 &&
                            <div>
                                <div className="pagination_row_div">
                                    <p className="pagination_title">Showing items per page:</p>
                                    <div className="pagination_input_div" >
                                        <select className="pagination_select"
                                            style={{ margin: 0, background: 'transparent' }}
                                            name="pagination_value"
                                            value={perPage}
                                            onChange={handlerowsperPage} >
                                            <option value={4}>4</option>
                                            <option value={8}>8</option>
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="pagination_row_right_div">
                                    <p className="pagination_total">Showing {offset + 1} to {searchData.length < offset + perPage &&
                                        <span>{searchData.length}</span>}
                                        {searchData.length > offset + perPage &&
                                            <span>{offset + perPage} </span>} of {searchData.length} items</p>
                                    <ReactPaginate
                                        previousLabel={<ArrowBackIosIcon className="pagination_prev_btn" color="#2438a7" />}
                                        nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7" />}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={currentPage} />
                                </div>
                            </div>
                        }                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </Wrapper>
    )
}
export default Users