import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Wrapper, } from "./Login.styles";
import { UserContext } from "../../context"
import { FaRegEye, FaEyeSlash } from "react-icons/fa"
import { LoadingSpinner } from "../LoadingSpinner/index";
import { Row, Col } from "react-bootstrap";
import SettleMint_logo from "../../images/SettleMint_logo.svg";
import API_Auth from "../../API_Auth";

// DONE
const Login = () => {
    const navigate = useNavigate();
    const { setSessionIsLoggedIn, setSessionLoggedRole, setUserEmail, setSessionLoggedUser, setLoggedUserName } = useContext(UserContext);
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);
    const [userErr, setUserErr] = useState("");
    const [passwordErr, setPasswordErr] = useState("");
    const [responseErr, setResponseErr] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [passwordType, setPasswordType] = useState("password");

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }


    const handleInput = (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        if (name === "email") setUserName(value);
        if (name === "password") setPassword(value);
    };

    const handleSubmit = async (e) => {
        console.log("e")
        e.preventDefault();
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        if (name === "email") setUserName(value);
        if (name === "password") setPassword(value);

        try {
            // clear localStorage for clearing prev user context and access token
            localStorage.clear();
            let error = false;

            if (userName == '') {
                setUserErr('This field is required');
                error = true;
            }
            if (password == '') {
                setPasswordErr('Password is required.');
                error = true;
            } else {
                setPasswordErr('');
            }
            // login this user
            if (!error) {
                setIsLoading(true);
                let result = await API_Auth.loginUser('LOGINCHECK', userName, password);
                let _user = result[0];
                console.log("user", _user);

                if (_user.Flag == "Fail") {
                    setIsLoading(false);
                    setError(true);
                    setResponseErr(_user.description)


                } else {
                    setIsLoading(false);
                    setSessionIsLoggedIn('yes');
                    setSessionLoggedRole(_user.Role);
                    setSessionLoggedUser(result);
                    localStorage.setItem("isLogged", 'yes');
                    localStorage.setItem("loggedRole", _user.Role);
                    localStorage.setItem("DistrictID", _user.DistrictID);
                    localStorage.setItem("UserID", _user.id);

                    let y = _user.FirstName + ' ' + _user.LastName;
                    setLoggedUserName(y);
                  


                    localStorage.setItem("loggedUserName", _user.FirstName + ' ' + _user.LastName);
                    localStorage.setItem("user", JSON.stringify(result));
                    if (_user.Role.toLowerCase() == "admin") {
                        navigate("/roles");
                    } else if (_user.Role.toLowerCase() == "dao"||_user.Role.toLowerCase() == "dho") {
                       navigate("/distributor-allotment")
                    }
                    else if (_user.Role.toLowerCase() == "mis") {
                        navigate("/dashboard-ao")
                     }
                     else if (_user.Role.toLowerCase() == "ao_mis") {
                        navigate("/dashboard-ao")
                     }
                    else if (_user.Role.toLowerCase() == "distributor") {
                     navigate("/purchase-list/" +'purchase');
                    }
                    else if (_user.Role.toLowerCase() == "testing") {
                        navigate("/test-agency-requests");
                       }
                    else if (_user.Role.toLowerCase() == "ao") {
                      //navigate("/dashboard-ao");
                      navigate("/forecast-ao-update-new");
                    } else if (_user.Role.toLowerCase() == "supplier")
                        navigate("/supplier-dashboard")
                    }



            }

        } catch (error) {
            console.error(error);
            setError(true);
        }
    };

    return (
        <Wrapper>
            {isLoading && <LoadingSpinner />}
            <Row>
                <Col lg={6} md={6} sm={6} style={{ paddingRight: 0 }}>
                    <div className="logoimg">
                        {/*  <Row>
                            <Col lg={3}>
                                <img src={Logo} style={{ width: 120, height: 120, margin: 20 }} />
                            </Col>
                        </Row>
                        <div className="copyright">
                            <p> Blockchain based Medical Assets Tracking
                                   <br />
                                <p className="logo_sub_text"> Department of Medical Education, Uttarakhand</p>
                            </p>
                            <br />
                        </div>
 */}                    </div>
                </Col>
                <Col lg={6} md={6} sm={6} style={{ backgroundColor: 'white', paddingLeft: 0 }}>
                    <div className="text-sign-align">

                        <h2 className="ffat-font-size-40">
                            Sign In</h2>
                        {error && <div className="error">{responseErr}</div>}

                        <form onSubmit={(e) => handleSubmit(e)}>
                            {/*  <div className="formfield mt-4" id="firstnamefield">
                                    <input type="text" name="email" id="email" required className="inputdiv"
                                        value={userName}
                                        onChange={handleInput} />
                                    <label alt="email" for="email" >Email or Phone</label>
                                </div>
                                <div className="formfield mt-3 mb-30" id="firstnamefield">
                                    <input type="password" name="password" id="password" required
                                        value={password}
                                        onChange={handleInput} />
                                    <label alt="password" for="password" >Password</label>
                                </div> */}

                            <div style={{ marginTop: 30 }}>
                                <label alt="email" for="email" >Email or Phone</label>

                                <div className="row_div">
                                    <div className="input_div" >
                                        <input style={{ margin: 0, background: 'transparent', fontSize: 18 }}
                                            type="text" name="email" className="inputdiv"
                                            value={userName}
                                            onChange={handleInput}
                                            placeholder="Email" />
                                    </div>

                                </div>
                                {userErr != '' && <p className="error">{userErr}</p>}

                            </div>
                            <div style={{ marginTop: 5 }}>
                                <label alt="password" for="password" >Password</label>

                                <div className="row_div">
                                    <div className="input_div" >
                                        <input type={passwordType} style={{ margin: 0, background: 'transparent', fontSize: 18 }}
                                            name="password" className="inputdiv"
                                            value={password}
                                            onChange={handleInput}
                                            placeholder="Password" />

                                        <div>
                                            <div onClick={togglePassword}>
                                                {passwordType === "password" ? <FaRegEye style={{ marginRight: 10, marginTop: 10 ,cursor:'pointer'}} size={22} /> : <FaEyeSlash style={{ marginRight: 10, marginTop: 10 }} size={22} />}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {passwordErr != '' && <p className="error">{passwordErr}</p>}

                            </div>
                            <div className="reset_section">
                                <p className="reset_title"> Reset your password?</p>
                            </div>
                            <div>
                                <button className="add_button" type="submit">
                                    <p className="pcat_title"> LOGIN</p></button>
                            </div>
                            <div className="copyright_powered">
                                <p className="logo_side_text"> Powered by<img src={SettleMint_logo} style={{ width: 120, margin: 20 }} /></p>
                                <br />
                            </div>

                        </form>

                        <Row>
                            <Col>

                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Wrapper>
    );
};

export default Login;
