import styled from "styled-components";

export const Wrapper = styled.div`
background: #fff;
margin-top: 92px;
p {
  font-family: 'Poppins', sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #404040;
}
.banner_row{
  margin-right:0;
  margin-left:0;
}
.banner_col{
  padding-right:0;
  padding-left:0;
}
.banner_image{
  background-size: cover;
  padding: 0px;
  width: 100%;
  display: flex;
  align-items: flex-end;
}
.margin_btm_child{
 p{
    margin-bottom:16px;
  }
  h3{
    margin-bottom:12px;
  }
}
.img_thumbnail {
  vertical-align: middle;
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
.pseudo_border {
  position: relative;
  display: inline-block;
  font-weight: 600;
  line-height: 1.2;
  font-size: 48px;
}
.rounded {
  width: 100%;
  max-width: 100%;
  height: auto!important;
  border-radius: 1rem !important;
  img {
    vertical-align: middle;
    border-style: none;
}
}
.pseudo_border:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  margin: 10px auto;
  width: 90px;
  height: 3px;
  background: #247158;
}

.text_center{
  text-align: center!important;
}
.margin_top_btm{
  margin-bottom: 3rem!important;
  margin-top: 3rem!important;
}
.play-sec {
  padding: 44px 0;
  margin-bottom: 3rem!important;
  margin-top: 3rem!important;
   h1 {
    font-size: 60px;
    font-weight: 600;
    line-height: 80px;
}
p {
  font-size: 28px;
  line-height: 42px;
  color: #000;
  margin: 59px 0 18px 0;
}
h2 {
  font-family: 'Poppins', sans-serif;
}
}
.theme-color {
  color: #63D669;
}
.form-sec, .form-group {
  margin-bottom: 2rem;
}
.form-sec button {
  background: #32ae3d;
  border-color: #32ae3d;
  padding: 16px 35px;
  border-radius: 10px;
  fill: #fff;
}
.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  font-size: 1rem;
  line-height: 1.5;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.footer_sec{background-color: #fff;
    height: auto;
    padding: 41px 0;
    border-top: 3px solid #247158;
p {
  margin-bottom: 0px;
  font-size: 14px;
  color: #000000;
  font-family: sans-serif;
  font-weight: 300;
  line-height: 1.7em;
  margin-top: 0;
}
a{
  text-decoration: none;
  background-color: transparent;
  :hover {
    text-decoration: underline;
  }
}
}
`