import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./styles"
import { Row, Container, Col } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import Edit from "../../images/edit.png";
import plus from "../../images/white_plus.png"
import { useNavigate, useParams } from "react-router-dom";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle"
import { LoadingSpinner } from "../LoadingSpinner/index";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from "moment";
import View from "../../images/view.svg";
import Modal from 'react-bootstrap/Modal';

const TransferRequestsForDAO = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    const [requestsList, setRequestsList] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(50);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey, setSearchKey] = useState('n');
    const [searchKeyTable, setSearchKeyTable] = useState('');
    const [expandTable, setexpandTable] = useState(false);
    const [object_det, setObjectData] = useState([]);
    const [xlfile, setXlfile] = useState('');

    const handleSearch = (e) => {
        let filterValue = e.target.value;
        setCurrentPage(0);
        if (filterValue == '') {
            let offsetsearch = 0;
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setTableData(slice);
            setSearchData(orgtableData);
            setOffSet(offsetsearch);
            setCurrentPage(0);
            setSearchKey('n');
        }
        else {
            let offsetsearch = 0;
            const filteredData = orgtableData.filter(item => {
                return (item ?.SenderName ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                item ?.SeedName ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                item ?.VarietyName ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                    item ?.ReceiverName ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                        item ?.Status ?.toLowerCase().includes(filterValue.toLowerCase()))
            });

            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            setOffSet(offsetsearch);
            setSearchKeyTable(filteredData);
            setTableData(slice);
            setSearchData(filteredData);
        }
    }



    useEffect(() => {
        setIsLoading(true);
        const user_id = localStorage.getItem("UserID");
        const fetchForecast = async () => {
            let result = await API_Auth.getAllSeedReqestsForDao(user_id);
            console.log("result", result)
            setRequestsList(result);
            setSearchData(result);
            setIsLoading(false);
            //seting pagenation data
            var slice = result.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result.length / perPage));
            setOrgTableData(result);
            setTableData(slice);
        };
        // call the function
        fetchForecast()
            // make sure to catch any error
            .catch(console.error);
    }, []);
    const handleAddPurchase = () => {
        navigate("/purchase-initiate");
    }
    const handlerowsperPage = (e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(searchKeyTable);
        }
        else {
            const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(orgtableData);
        }
    }
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        const data = orgtableData;
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setOffSet(offsetdata);
            setSearchData(searchKeyTable);
        } else {
            const slice = data.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(data.length / perPage))
            setOffSet(offsetdata);
            setTableData(slice);
            setSearchData(data);
        }
    }
    const handleViewRequestDetails = async (obj) => {
        let result = await API_Auth.getINDSeedReqestForDao(obj.RequestNo);
        console.log("resu;te", result)
        setexpandTable(true);
        setObjectData(result);
    }
    const handleSubmitt = (obj, status) => {
        obj.map(async function (app, key) {
            const receive_seed = await API_Auth.getApproveRejectSeedRequestDao(app.RequestNo, status);
        });
        setTimeout(() => {
            navigate(0);
        }, 2000);
    };
    const handleClose = () => {
        setexpandTable(false);
    };
    const handleInput = async (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        var file = e.target.files[0];
        console.log(file);
        setXlfile({ file });
    
        console.log(xlfile);
    
    };
    const handlereadFile = async () => {
    };
    const formatter = new Intl.NumberFormat('en-IN');

    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}

            <Container style={{ marginTop: 42 }}>
                <Row>
                    <Col lg={4} style={{ paddingLeft: 0 }}>
                        <Title>TRANSFER REQUESTS</Title>
                    </Col>
                    <Col lg={4} style={{ paddingRight: 0 }}>

                    </Col>
                    <Col lg={4} style={{ paddingRight: 0 }}>
                        <div className="row_div"  style={{ paddingRight: 0 }}>
                            {/*                             <p className="title">Filter By:</p> */}
                            <div className="input_div" >
                                <input type="type" style={{ margin: 0, background: 'transparent' }}
                                    name="filterValue"
                                    //    value={filterValue}
                                    onChange={(e) => handleSearch(e)}
                                    placeholder="Search" />
                                <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                            </div>

                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
                        <Table className="tablelist" responsive>
                            <thead>
                                <tr>
                                    <th>Component</th>
                                    <th>Type</th>

                                    <th>Sender Name </th>
                                    <th>Receiver Names</th>
                                    <th>Season</th>
                                    <th>Quantity</th>
                                    <th>Status</th>
                                    <th>Date</th>

                                    
                                    <th style={{ textAlign: 'right' }}>Action</th>
                                </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {tableData.length != 0 && <tbody>
                                {tableData.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                            <td>{obj.SeedName}</td>
                                            <td>{obj.VarietyName}</td>


                                                <td>{obj.SenderName}</td>
                                                <td>{obj.ReceiverNames}</td>
                                                <td>{obj.year}-{obj.season}</td>
                                                <td>{formatter.format(obj.Quantity)}</td>
                                                <td>{obj.Status}</td>
                                                <td>{moment.utc(obj.ReqDate).format("DD MMM YYYY  h:mm A")}</td>


                                                <td style={{ textAlign: 'right' }}>
                                                    <div className="row_table_edit" style={{background: 'antiquewhite'}} onClick={() => handleViewRequestDetails(obj, "view")}>
                                                        <img src={View} />
                                                        <p>VIEW</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                            }
                        </Table>
                        {tableData.length != 0 &&
                            <div>
                                <div className="pagination_row_div">
                                    <p className="pagination_title">Showing items per page:</p>
                                    <div className="pagination_input_div" >
                                        <select className="pagination_select"
                                            style={{ margin: 0, background: 'transparent' }}
                                            name="pagination_value"
                                            value={perPage}
                                            onChange={handlerowsperPage} >
                                          <option value={20}>20</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="pagination_row_right_div">
                                    <p className="pagination_total">Showing {offset + 1} to {searchData.length < offset + perPage &&
                                        <span>{searchData.length}</span>}
                                        {searchData.length > offset + perPage &&
                                            <span>{offset + perPage} </span>} of {searchData.length} items</p>
                                    <ReactPaginate
                                        previousLabel={<ArrowBackIosIcon className="pagination_prev_btn" color="#2438a7" />}
                                        nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7" />}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={currentPage} />
                                </div>
                            </div>
                        }                    </Col>
                </Row>
                <Modal show={expandTable} onHide={handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    dialogclassname="modal-content">
                    <Modal.Header closeButton>
                        <Modal.Title>Individual Request</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                    <div className="row_div">
                    <Row>
                        <Row>
                            <Col lg={4}>
                                <p className="pcat_title">Sender Name :</p>
                            </Col>
                            <Col>
                            <p className="pcat_text">{object_det[0]?.SenderName}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <p className="pcat_title">Request No :</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{object_det[0]?.RequestNo}</p>
                            </Col>
                        </Row>
                        </Row>
                        </div>
                        <div>
                            {object_det &&
                                (object_det && object_det.length == 0) && <>
                                    <Table responsive>
                                        <tr>
                                            <td colspan="12" className="hiddenRow">
                                                <p style={{
                                                    textAlign: "center",
                                                    fontWeight: "bold",
                                                    margin: 10,
                                                    color: 'black'
                                                }}>No Data Found</p>

                                            </td>
                                        </tr>
                                    </Table>
                                </>
                            }
                            {
                                (object_det && object_det.length != 0) && <>
                                    <Table responsive>
                                        <thead>
                                            <th>Approver Name</th>
                                            <th>Receiver Name</th>
                                            <th>Quantity</th>
                                            <th>Status</th>
                                        </thead>
                                        <tbody>
                                            {object_det ?.map((item, index) => {
                                                return (
                                                    <tr key={index} >
                                                        <td>{item.ApproverName}</td>
                                                        <td>{item.ReceiverName}</td>
                                                        <td>{item.Quantity}</td>
                                                        <td>{item.Status}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </>
                            }
                        </div>

                    </Modal.Body>
                    {object_det[0]?.Status=="Open"&&
                    <Modal.Footer className="modal-footer" style={{marginTop:0}}>
                        <div className="row_div">
                            <button className="add_button" onClick={() => handleSubmitt(object_det,"approve")} style={{
                                padding: 12,
                                height: 45,
                                margin: 10
                            }}>
                                <p>APPROVE</p></button>
                        </div>
                        <div className="row_div">
                            <button className="add_button" onClick={() => handleSubmitt(object_det,"reject")} style={{
                                padding: 12,
                                height: 45,
                                margin: 10
                            }}>
                                <p>REJECT</p></button>
                        </div>
                    </Modal.Footer>
                    }
                </Modal>
            </Container>
            <ToastContainer />
        </Wrapper>
    )
}
export default TransferRequestsForDAO