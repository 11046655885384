import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./styles"
import { Row, Container, Col } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import Edit from "../../images/edit.png";
import plus from "../../images/white_plus.png"
import { useNavigate, useParams } from "react-router-dom";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle"
import { LoadingSpinner } from "../LoadingSpinner/index";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from "moment";


const TransitDataByDispatch = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    const [transportIds, setTransportIds] = useState([]);
    
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);

    let { DispatchID } = useParams();


    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(50);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey, setSearchKey] = useState('n');
    const [searchKeyTable, setSearchKeyTable] = useState('');

    const handleSearch = (e) => {
        let filterValue = e.target.value;
        setCurrentPage(0);
        if (filterValue == '') {
            let offsetsearch = 0;
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setTableData(slice);
            setSearchData(orgtableData);
            setOffSet(offsetsearch);
            setCurrentPage(0);
            setSearchKey('n');
        }
        else {
            let offsetsearch = 0;
            const filteredData = orgtableData.filter(item => {
                return (item ?.year ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                item ?.season ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                    item ?.cropID ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                        item ?.verityID ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                            item ?.parchageID ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                            moment(item ?.dispatchDate).format('DD-MM-YYYY') ?.includes(filterValue.toLowerCase()) ||
                            item ?.supplierID ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                            item ?.transitQuantity ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                            item ?.recevedQuantity ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                            item ?.receivedBy ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                            moment(item ?.receivedDate).format('DD-MM-YYYY').includes(filterValue.toLowerCase()))
            });

            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            setOffSet(offsetsearch);
            setSearchKeyTable(filteredData);
            setTableData(slice);
            setSearchData(filteredData);
        }
    }

    const handleAddPurchase = () => {
       // navigate("/purchase-initiate/"+userID);
    }

    useEffect(() => {
        setIsLoading(true);

        const fetchForecast = async () => {
            let result = await API_Auth.getDispacthByTransitDetails(DispatchID);
            console.log("result", result);
            let transitDetails = result.transitDetail;
            let transportIds = result.transport;
            setTransportIds(transportIds);
            setSearchData(transitDetails);
            setIsLoading(false);
            //seting pagenation data
            var slice = transitDetails.slice(offset, offset + perPage);
            setPageCount(Math.ceil(transitDetails.length / perPage));
            setOrgTableData(transitDetails);
            setTableData(slice);
        };
        // call the function
        fetchForecast()
            // make sure to catch any error
            .catch(console.error);
    }, []);
    const handlerowsperPage = (e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(searchKeyTable);
        }
        else {
            const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(orgtableData);
        }
    }
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        const data = orgtableData;
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setOffSet(offsetdata);
            setSearchData(searchKeyTable);
        } else {
            const slice = data.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(data.length / perPage))
            setOffSet(offsetdata);
            setTableData(slice);
            setSearchData(data);
        }
    }
    const handleViewDispatch = (obj) => {
        navigate("/transaction-details/"+obj.id);
    }
    const formatter = new Intl.NumberFormat('en-IN');
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}
                    <Container>
                <Row>
                    <Col lg={4} style={{ paddingLeft: 0 }}>
                        <Title>TRANSPORT DETAILS</Title>
                    </Col>
                       </Row>
                       </Container>
              <Container>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
                        <Table className="tablelist" responsive>
                            <thead>
                                <tr>
{/*                                     <th>Id</th> */}
                                    <th>Transport ID</th>
                                    <th>Action</th>
                                    </tr>
                            </thead>
                            {transportIds.length != 0 && <tbody>
                                {transportIds.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
{/*                                                 <td>{index}</td> */}
                                                <td>{obj.id}</td>
                                                <td>
<div className="row_table_view " onClick={() => handleViewDispatch(obj)} style={{ marginRight: 10 }}>
    <p>View</p>
</div>
</td> 
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                            }
                        </Table>
                        </Col>
                        </Row>
                        </Container>
            <Container>
                <Row>
                    <Col lg={4} style={{ paddingLeft: 0 }}>
                        <Title>TRANSIT DETAILS</Title>
                    </Col>
                    <Col lg={3}>

                    </Col>
                    <Col lg={5} style={{ paddingRight: 0 }}>
                        <div className="row_div">
                            {/*                             <p className="title">Filter By:</p> */}
                            <div className="input_div" >
                                <input type="type" style={{ margin: 0, background: 'transparent' }}
                                    name="filterValue"
                                    //    value={filterValue}
                                    onChange={(e) => handleSearch(e)}
                                    placeholder="Search" />
                                <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                            </div>

                        </div>
                    </Col>
                    {/* <Col lg={3} style={{ paddingRight: 0 }}>
                        <div>
                            <button className="add_button" onClick={() => handleAddPurchase()}>
                                {/*                                 <img src={plus} style={{ margin: 5 }} /> 
                                <p className="pcat_title">INITIATE PURCHASE</p></button>
                        </div></Col> */}
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
                        <Table className="tablelist" responsive>
                            <thead>
                                <tr>
                                    <th>Year</th>
                                    <th>Season</th>
                                    <th>Component</th>
                                    <th>Type</th>
                                    <th>Purchase ID </th>
{/*                                     <th>Supplier ID</th>
 */}                                    <th>Transit Quantity </th>
                                    <th>Dispatch Date </th>
                                    <th>Receive Quantity </th>
                                    <th>Received By </th>
                                    <th>Received Date </th>
                          {/*<th style={{ textAlign: 'right' }}>Action</th>*/}
                            </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {tableData.length != 0 && <tbody>
                                {tableData.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                            <td>{obj.year}</td>
                                                <td>{obj.season}</td>
                                                <td>{obj.SeedName}</td>
                                                <td>{obj.VarietyName}</td>
                                                <td>{obj.parchageID}</td>
{/*                                                 <td>{obj.supplierID}</td>
 */}                                                <td>{formatter.format(obj.transitQuantity)}</td>
                                                <td>{obj.dispatchDate}</td>
                                                <td>{formatter.format(obj.recevedQuantity)}</td>
                                                <td>{obj.receivedBy}</td>
                                                <td>{ moment.utc(obj.receivedDate).format("DD MMM YYYY  h:mm A")}</td>
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                            }
                        </Table>
                        {tableData.length != 0 &&
                            <div>
                                <div className="pagination_row_div">
                                    <p className="pagination_title">Showing items per page:</p>
                                    <div className="pagination_input_div" >
                                        <select className="pagination_select"
                                            style={{ margin: 0, background: 'transparent' }}
                                            name="pagination_value"
                                            value={perPage}
                                            onChange={handlerowsperPage} >
                                          <option value={20}>20</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="pagination_row_right_div">
                                    <p className="pagination_total">Showing {offset + 1} to {searchData.length < offset + perPage &&
                                        <span>{searchData.length}</span>}
                                        {searchData.length > offset + perPage &&
                                            <span>{offset + perPage} </span>} of {searchData.length} items</p>
                                    <ReactPaginate
                                        previousLabel={<ArrowBackIosIcon className="pagination_prev_btn" color="#2438a7" />}
                                        nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7" />}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={currentPage} />
                                </div>
                            </div>
                        }                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </Wrapper>
    )
}
export default TransitDataByDispatch