import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./AddUser.styles"
import { Title } from "../../GlobalStyle.js"
import API_Auth from "../../API_Auth";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingSpinner } from "../LoadingSpinner/index";
import { Container, Row, Col } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";
import { FaRegEye, FaEyeSlash } from "react-icons/fa"





const AddUser = () => {
    let { userID } = useParams();
    const navigate = useNavigate();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const {
        isExpanded,
    } = useContext(UserContext);

    const [formTitle, setFormTitle] = useState('Add New User');

    const [isLoading, setIsLoading] = useState(false);

    const [firstName, setfirstName] = useState('');
    const [firstNameErr, setfirstNameErr] = useState('');

    const [lastName, setlastName] = useState('');
    const [lastNameErr, setlastNameErr] = useState('');

    const [email, setemail] = useState('');
    const [emailerr, setemailErr] = useState('');

    const [contactNumber, setcontactNumber] = useState('');
    const [contactNumberErr, setcontactNumberErr] = useState('');

    const [userName, setuserName] = useState('');
    const [userNameErr, setuserNameErr] = useState('');

    const [category, setcategory] = useState('');
    const [categoryErr, setcategoryErr] = useState('');

    const [roles, setRoles] = useState([]);
    const [role, setRole] = useState('');
    const [districts, setDistricts] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [designations, setDesignations] = useState([]);
    const [dep, setDep] = useState('');
    const [design, setDesign] = useState('');
    const [roleErr, setRoleErr] = useState('');
    const [designErr, setDesignErr] = useState('');
    const [deptErr, setDeptErr] = useState('')
    const [district, setdistrict] = useState('');
    const [districtErr, setdistrictErr] = useState('');
    const [block, setblock] = useState('');
    const [blockErr, setblockErr] = useState('');
    const [purchaser, setPurchaser] = useState('');
    const [purchaserErr, setPurchaserErr] = useState('');

    const [formButtonValue, setFormButtonValue] = useState('ADD USER');
    const [password, setPassword] = useState('');
    const [passwordErr, setPasswordErr] = useState('');
    const [profilePic, setProfilePic] = useState('');
    const [passwordType, setPasswordType] = useState("password");

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }



    const handleBackClick = () => {
        navigate("/admin-users");
        navigate(0);

    }

    useEffect(() => {
        const fetchData = async () => {

            const roles = await API_Auth.getRoles();
            setRoles(roles)
            const designations = await API_Auth.getDesignations();
            setDesignations(designations)
            const departments = await API_Auth.getDepartments();
            setDepartments(departments)
            const districts = await API_Auth.getDistricts();
            setDistricts(districts)

            if (userID != 'add') {
                let res = await API_Auth.getUserById(userID);
                let user_det = res[0];
                console.log("res", res[0])
                setfirstName(res.length != 0 ? user_det.FirstName : '');
                setlastName(res.length != 0 ? user_det.LastName : '');
                setemail(res.length != 0 ? user_det.email : '');
                setcontactNumber(res.length != 0 ? user_det.ContactNo : '');
                setuserName(res.length != 0 ? user_det.UserName : '');
                setcategory(res.length != 0 ? user_det.category : '');
                setRole(res.length != 0 ? user_det.Role : '');
                setDep(res.length != 0 ? user_det.Department : '')
                setDesign(res.length != 0 ? user_det.Designation : '')
                setblock(res.length != 0 ? user_det.Block : '')
                setdistrict(res.length != 0 ? user_det.District : '')
                setPassword(res.length != 0 ? user_det.Password : '')
                setProfilePic(res.length != 0 ? user_det.Profilepic : '')
                setPurchaser(res.length!=0?user_det.Purchaser:'')
                setFormTitle('Edit User');
                setFormButtonValue('UPDATE USER');
            }
        };
        // call the function
        fetchData()

            // make sure to catch any error
            .catch(console.error);
    }, []);

    const handleAddUser = async () => {
        let error = false;
        if (firstName == '') {
            setfirstNameErr('This field is required');
            error = true;
        }
        else {
            setfirstNameErr('  ');
        }
        if (lastName == '') {
            setlastNameErr('This field is required');
            error = true;
        }
        else {
            setlastNameErr('  ');
        }
        if (contactNumber == '') {
            setcontactNumberErr('This field is required');
            error = true;
        }
        else if(contactNumber != ''){
            var pattern = new RegExp(/^[0-9\b]+$/);

            if (!pattern.test(contactNumber)) {
                    
                error = true;
                setcontactNumberErr('Please enter only number.');                    

            }else if(contactNumber.length != 10){
                error = true;
                setcontactNumberErr('Please enter valid phone number.');   
            } else{
                setcontactNumberErr(''); 
            }    
        }
        else {
            setcontactNumberErr('  ');
        }
       /*  if (email == '') {
            setemailErr('This field is required');
            error = true;
        }
        else {
            setemailErr('  ');
        } */
        if (email != '') {
            let pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
            if (!pattern.test(email)) {
                setemailErr('Please enter valid email.');
                error = true;
            } else {
                setemailErr('');
            }
        } else if (email == '') {
            setemailErr('Email is required.');
            error = true;
        }
        else {
            setemailErr('');
        }
       /*  if (userName == '') {
            setuserNameErr('This field is required');
            error = true;
        }
        else {
            setuserNameErr('');
        } */
       /*  if (category == '') {
            setcategoryErr('This field is required');
            error = true;
        }
        else {
            setcategoryErr('  ');
        } */
        if (role == '') {
            setRoleErr('This field is required');
            error = true;
        }
        else {
            setRoleErr('  ');
        }
        if (role == "Distributor") {
            if (purchaser == '') {
                setPurchaserErr('This Field is required');
                error = true;

            } else {
                setPurchaserErr('')
            }
        }

        if (dep == '') {
            setDeptErr('This field is required');
            error = true;
        }
        else {
            setDeptErr('  ');
        }
       /*  if (design == '') {
            setDesignErr('This field is required');
            error = true;
        }
        else {
            setDesignErr('  ');
        }
        if (block == '') {
            setblockErr('This field is required');
            error = true;
        }
        else {
            setblockErr('  ');
        } */
        if (district == '') {
            setdistrictErr('This field is required');
            error = true;
        }
        else {
            setdistrictErr('  ');
        }
        if (password == '') {
            setPasswordErr('This field is required');
            error = true;
        }
        else {
            setPasswordErr('  ');
        }
        if (!error) {
            setIsLoading(true)
            setDisableSubmit(true);
            if (userID == 'add') {
                var p= purchaser

                if(role!="Distributor"){
                    p="No"
                }
                let result = await API_Auth.addUserDetail(firstName, lastName, contactNumber, email, userName, dep, design, role, district, block, password, profilePic,p,category);
                if(result.code=="ER_DUP_ENTRY"){
                    toast.error('Email  already exists');
                    setIsLoading(false);
                    setDisableSubmit(false);
                }
                else{
                    let res = result[0];
                console.log(result);
                console.log(res);
                 if (res.Flag == 'Success') {
                    setIsLoading(false);
                    toast.success('User added successfully');
                    setTimeout(() => {
                        navigate("/admin-users");
                    }, 2000);

                } else {
                    setIsLoading(false);

                    setDisableSubmit(false);
                    toast.error('Something went wrong please try again');
                } 
            }
            } else {
                var p= purchaser

                if(role!="Distributor"){
                    p="No"
                }
               console.log(firstName, lastName, contactNumber, email, userName, dep, design, role, district, block, password, profilePic, userID, p)
                let result = await API_Auth.updateUserDetail(firstName, lastName, contactNumber, email, userName, dep, design, role, district, block, password, profilePic, userID, p,category);
                if(result.code=="ER_DUP_ENTRY"){
                    toast.error('Email  already exists');
                    setIsLoading(false);
                    setDisableSubmit(false);
                }
                else{
                    
                let res = result[0];
                 if (res?.Flag == 'Success') {
                    setIsLoading(false);
                    toast.success('User updated successfully');
                    setTimeout(() => {
                        navigate("/admin-users");
                    }, 2000);
                } else {
                    setIsLoading(false);
                    setDisableSubmit(false);
                    toast.error('Something went wrong please try again');
                } 
            }
            }
        }


    }
    const handleInput = async (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        if (name === "firstName") setfirstName(value);
        if (name === "lastName") setlastName(value);
        if (name === "email") setemail(value);
        if (name === "contactNumber") setcontactNumber(value);
        if (name === "userName") setuserName(value);
        if (name === "category") setcategory(value);
        if (name === "role") setRole(value);
        if (name === "department") setDep(value);
        if (name === "designation") setDesign(value);
        if (name === "block") setblock(value);
        if (name === "district") setdistrict(value);
        if (name === "password") setPassword(value);
        if (name == "purchaser") setPurchaser(value);
        if (name === "profile") {
            /*             const lastSegment = value.split("").pop();
            
            console.log(lastSegment); // "playlist" */

            console.log("value", value);
            setProfilePic(value)

        };





    }
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            <Container>
                <Row className="row_title">
                    <Col lg={4}><p>{formTitle}</p></Col>
                    <Col lg={6}></Col>
                    <Col lg={2}>
                        <div className="back_button" onClick={() => handleBackClick()}>
                            <IoIosArrowBack size={18} />
                            <h6>Back</h6>
                        </div>
                    </Col>
                </Row>
            </Container>
            {isLoading && <LoadingSpinner />}

            <hr />
            <Container>
                <Row>
                    <Col lg={6} md={6} sm={6}>
                        <div className="inputdiv">
                            <label>First Name</label>
                            <br />
                            <input type="text"
                                name="firstName"
                                value={firstName}
                                placeholder="Enter First Name"
                                onChange={handleInput}
                                style={{ width: "75%" }} />
                            {firstNameErr != '' && <p className="alert-message">{firstNameErr}</p>}
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={6}>
                        <div className="inputdiv">
                            <label>Last Name</label>
                            <br />
                            <input type="text"
                                name="lastName"
                                value={lastName}
                                placeholder="Enter lastName"
                                onChange={handleInput}
                                style={{ width: "75%" }} />
                            {lastNameErr != '' && <p className="alert-message">{lastNameErr}</p>}
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={6}>
                        <div className="inputdiv">
                            <label>Email</label>
                            <br />
                            <input type="text"
                                name="email"
                                value={email}
                                placeholder="Enter Email"
                                onChange={handleInput}
                                style={{ width: "75%" }} />
                            {emailerr != '' && <p className="alert-message">{emailerr}</p>}
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={6}>
                        <div className="inputdiv">
                            <label>Mobile Number</label>
                            <br />
                            <input type="number"
                                name="contactNumber"
                                value={contactNumber}
                                placeholder="Enter Mobile Number"
                                onChange={handleInput}
                                style={{ width: "75%" }} />
                            {contactNumberErr != '' && <p className="alert-message">{contactNumberErr}</p>}
                        </div>
                    </Col>
                   {/*  <Col lg={6} md={6} sm={6}>
                        <div className="inputdiv">
                            <label>User Name</label>
                            <br />
                            <input type="text"
                                name="userName"
                                value={userName}
                                placeholder="Enter userName"
                                onChange={handleInput}
                                style={{ width: "75%" }} />
                            {userNameErr != '' && <p className="alert-message">{userNameErr}</p>}
                        </div>
                    </Col> */}
                    <Col lg={6} md={6} sm={6}>
                        <div className="inputdiv">
                            <label>Role</label>
                            <br />

                            <select onChange={handleInput} name="role"
                                value={role}
                                style={{ width: "75%" }} >
                                <option value=''>Select Role</option>
                                {roles.length != 0 && <>
                                    {roles.map((item, index) => {
                                        return (
                                            <option value={item.Role}>{item.Role}</option>
                                        )
                                    })}
                                </>}

                            </select>

                            {roleErr != '' && <p className="alert-message">{roleErr}</p>}
                        </div>
                    </Col>
                    {role == "Distributor" && <Col lg={6} md={6} sm={6}>
                        <div className="inputdiv">
                            <label>Purchaser</label>
                            <br />

                            <select onChange={handleInput}
                                value={purchaser}

                                name="purchaser"
                                style={{ width: "75%" }} >
                                <option value=''>Select Purchaser</option>
                                <option value="Yes">YES</option>
                                <option value="No">NO</option>


                            </select>

                            {purchaserErr != '' && <p className="alert-message">{purchaserErr}</p>}
                        </div>
                    </Col>
                    }

                    <Col lg={6} md={6} sm={6}>
                        <div className="inputdiv">
                            <label>Designation</label>
                            <br />

                            <select onChange={handleInput}
                                value={design}

                                name="designation"
                                style={{ width: "75%" }} >
                                <option value=''>Select Designation</option>
                                {designations.length != 0 && <>
                                    {designations.map((item, index) => {
                                        return (
                                            <option value={item.Designation}>{item.Designation}</option>
                                        )
                                    })}
                                </>}

                            </select>

                            {designErr != '' && <p className="alert-message">{designErr}</p>}
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={6}>
                        <div className="inputdiv">
                            <label>Category</label>
                            <br />
                            <input type="text"
                                name="category"
                                value={category}
                                placeholder="Enter category"
                                onChange={handleInput}
                                style={{ width: "75%" }} />
                            {categoryErr != '' && <p className="alert-message">{categoryErr}</p>}
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={6}>
                        <div className="inputdiv">
                            <label>Department</label>
                            <br />

                            <select onChange={handleInput}
                                value={dep}

                                name="department"
                                style={{ width: "75%" }} >
                                <option value=''>Select Department</option>
                                {departments.length != 0 && <>
                                    {departments.map((item, index) => {
                                        return (
                                            <option value={item.DepartmentName}>{item.DepartmentName}</option>
                                        )
                                    })}
                                </>}

                            </select>

                            {deptErr != '' && <p className="alert-message">{deptErr}</p>}
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={6}>
                        <div className="inputdiv">
                            <label>Block</label>
                            <br />
                            <input type="text"
                                name="block"
                                value={block}
                                placeholder="Enter block"
                                onChange={handleInput}
                                style={{ width: "75%" }} />
                            {blockErr != '' && <p className="alert-message">{blockErr}</p>}
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={6}>
                        {/* <div className="inputdiv">
                            <label>District</label>
                            <br />
                            <input type="text"
                                name="district"
                                value={district}
                                placeholder="Enter districtName"
                                onChange={handleInput}
                                style={{ width: "75%" }} />
                            {districtErr != '' && <p className="alert-message">{districtErr}</p>}
                        </div> */}
                        <div className="inputdiv">
                            <label>District</label>
                            <br />
                            <select onChange={handleInput} value={district}
                                name="district"
                                style={{ width: "75%" }} >
                                <option value=''>Select District</option>
                                {districts.length != 0 && <>
                                    {districts.map((item, index) => {
                                        return (
                                            <option value={item.id}>{item.v_districtName}</option>
                                        )
                                    })}
                                </>}
                            </select>
                            {districtErr != '' && <p className="alert-message">{districtErr}</p>}
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={6}>
                    <div className="row_div">
                                    <div className="input_div" >

                            <label>Password</label>
                            <br />
                            <input type={passwordType}
                                name="password"
                                value={password}
                                placeholder="Enter password"
                                onChange={handleInput}
                                style={{ width: "75%" }}/>
                         {passwordType === "password" ? <FaRegEye onClick={togglePassword} style={{ marginLeft: -45,cursor:'pointer'}} size={22} /> : <FaEyeSlash onClick={togglePassword} style={{ marginLeft: -45,cursor:'pointer' }} size={22} />}
                         {passwordErr != '' && <p className="alert-message">{passwordErr}</p>}
                        </div>
                        </div>
                        </Col>
                    <Col lg={6} md={6} sm={6}>


                        <div className="inputdiv">
                            <label>Profile Pic</label>
                            <br />
                            {profilePic == '' && <label for="file-upload" class="custom-file-upload">
                                {/*   <div className="edit_photo_div">
            <p>Edit Photo</p>
            <img src={Edit} />
        </div> */}
                            </label>}
                            {profilePic != '' &&
                                <label className="custom-file-uploaded" >
                                    <div>
                                        <img src={profilePic} />
                                        {/* <a onClick={() => removePic('userpic')}> Remove</a> */}
                                    </div>
                                </label>}
                            <input id="file-upload" onChange={handleInput} name="profile" type="file" style={{ display: "none" }} />
                        </div>

                    </Col>
                </Row>
                <Row>
                    <Col lg={5}>
                        <div className="row_div">
                            <button className="add_button" disabled={disableSubmit} onClick={() => handleAddUser()}
                                style={{
                                    margin: 12,
                                    width: "92%"
                                }}
                            >
                                <p className="pcat_title">{formButtonValue}</p></button>
                        </div>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </Wrapper>
    )

}
export default AddUser