import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./DistributorSeedHistory.styles"
import { Row, Container, Col,OverlayTrigger, Tooltip, } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import Edit from "../../images/edit.png";
import plus from "../../images/white_plus.png"
import { useNavigate, useParams } from "react-router-dom";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle"
import { LoadingSpinner } from "../LoadingSpinner/index";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from "moment";
import Modal from 'react-bootstrap/Modal';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';


const DistributorSeedHistory = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    const [distributorSeedHistory, setDistributorSeedHistory] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);

    let { distribution_type } = useParams();


    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(50);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey, setSearchKey] = useState('n');
    const [searchKeyTable, setSearchKeyTable] = useState('');
    const[seasonData,setSeasonData]=useState([]);
    const[season,setSeason]=useState('');
    const[showDetailsModal,setshowDetailModel]=useState(false);
    const[hashDetails,sethashDetail]=useState(false);

    const handleSearch = (e) => {
        let filterValue = e.target.value;
        setCurrentPage(0);
        if (filterValue == '') {
            let offsetsearch = 0;
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setTableData(slice);
            setSearchData(orgtableData);
            setOffSet(offsetsearch);
            setCurrentPage(0);
            setSearchKey('n');
        }
        else {
            let offsetsearch = 0;
            const filteredData = orgtableData.filter(item => {
                return (item ?.v_farmerAadhar ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                    item ?.farmerContactNumber ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                        item ?.distributionPointName ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                        item ?.SeedName ?.toLowerCase().includes(filterValue.toLowerCase()) ||

                        item ?.VarietyName ?.toLowerCase().includes(filterValue.toLowerCase()) ||

                            item ?.quantity ?.toLowerCase().includes(filterValue.toLowerCase()) )
            });

            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            setOffSet(offsetsearch);
            setSearchKeyTable(filteredData);
            setTableData(slice);
            setSearchData(filteredData);
        }
    }
    const handleInput=async(e)=>{
        const {name,value}=e.target;
           if(name=="season"){
            setSeason(value);
            setIsLoading(true)
            const user_id = localStorage.getItem("UserID");
            let offsetdata='0,'+perPage;
            let token='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NjA2MzEwMDMsImV4cCI6MTY2MDcxNzQzM30.uanmIGh3gvnLGVn6_W9mj5_F1am2Xf6yojULHAYzHZ8'    
            let data = await API_Auth.getDistributorSeedHistory(user_id,offsetdata,value);
            const result=data.data;
            console.log("result", result)
            setDistributorSeedHistory(result);
            setSearchData(result);
            setIsLoading(false);
            //seting pagenation data
            let offset = 0;
            var slice = result.slice(offset, offset + perPage);
            console.log("Slice",slice)
            setPageCount(Math.ceil(data.countandoffset[0].total_count / perPage));
            setOrgTableData(result);
            setTableData(result);
            setCurrentPage(0); 
            }
        }

    useEffect(() => {
        setIsLoading(true);
        const user_id = localStorage.getItem("UserID");
       // const user_id=4;
       
        let offsetdata='0,'+perPage;
        let token='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NjA2MzEwMDMsImV4cCI6MTY2MDcxNzQzM30.uanmIGh3gvnLGVn6_W9mj5_F1am2Xf6yojULHAYzHZ8'
        const fetchForecast = async () => {
            const seasonsresult=await API_Auth.getSeasons();
            setSeasonData(seasonsresult);
            setSeason('');
            let data = await API_Auth.getDistributorSeedHistory(user_id,offsetdata,'');
            const result=data.data;
            console.log("result", result)
            setDistributorSeedHistory(result);
            setSearchData(result);
            setIsLoading(false);
            console.log(offset,perPage)
            //seting pagenation data
            var slice = result.slice(offset, offset + perPage);
            console.log("Slice",slice);
            setPageCount(Math.ceil(data.countandoffset[0].total_count / perPage));
            setOrgTableData(result);
            setTableData(slice);
        };
        // call the function
        fetchForecast()
            // make sure to catch any error
            .catch(console.error);
    }, []);
  /*   const handleAddPurchase = () => {
        navigate("/purchase-initiate");
    } */
    const handlerowsperPage = async(e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        let offsetAPI = 0+','+parseInt(showperpage)
        // loadMoreData(offsetdata);
         const user_id = localStorage.getItem("UserID");
         let token='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NjA2MzEwMDMsImV4cCI6MTY2MDcxNzQzM30.uanmIGh3gvnLGVn6_W9mj5_F1am2Xf6yojULHAYzHZ8'
         let data = await API_Auth.getDistributorSeedHistory(user_id,offsetAPI,season);
         const result=data.data;
         console.log("result", result);
         setDistributorSeedHistory(result);
         setSearchData(result);
         setIsLoading(false);
         console.log(offset,perPage);
         //seting pagenation data
         const page_count = Math.ceil(data.countandoffset[0].total_count / parseInt(showperpage));
         console.log(page_count);
         setPageCount(page_count);
         setOrgTableData(result);
         setTableData(result);
        setCurrentPage(0);
       /*  if (searchKey == "y") {
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(searchKeyTable);
        }
        else {
            const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(orgtableData);
        } */
    }
    const exportToCSV = (csvData) => {

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        const fileExtension = '.xlsx';
        const fileName = 'DistributionHistory'

        const ws = XLSX.utils.json_to_sheet(csvData);

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        const data = new Blob([excelBuffer], { type: fileType });

        FileSaver.saveAs(data, fileName + fileExtension);
    }
    const handlePageClick = async(e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        let offsetAPI = offsetdata+','+perPage
       // loadMoreData(offsetdata);
        const user_id = localStorage.getItem("UserID");
        let token='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NjA2MzEwMDMsImV4cCI6MTY2MDcxNzQzM30.uanmIGh3gvnLGVn6_W9mj5_F1am2Xf6yojULHAYzHZ8'
        let data = await API_Auth.getDistributorSeedHistory(user_id,offsetAPI,season);
        setPageCount(Math.ceil(data.countandoffset[0].total_count / perPage));
        console.log(Math.ceil(data.countandoffset[0].total_count / perPage));
        const result=data.data;
        console.log("result", result)
        setDistributorSeedHistory(result);
        setSearchData(result);
        setIsLoading(false);
        console.log(offset,perPage)
        //seting pagenation data
        setOrgTableData(result);
        setTableData(result);
    }

    const loadMoreData = (offsetdata) => {
        const data = orgtableData;
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setOffSet(offsetdata);
            setSearchData(searchKeyTable);
        } else {
            const slice = data.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(data.length / perPage))
            setOffSet(offsetdata);
            setTableData(slice);
            setSearchData(data);
        }
    }
    const handleShowDetails = async(obj) => {
        let data = await API_Auth.gettransactionHashDetails(obj.txhash);
        console.log(data);
        sethashDetail(data);
        setshowDetailModel(true);
    };
    const handleClose =() => {
        setshowDetailModel(false);
    }
    const formatter = new Intl.NumberFormat('en-IN');
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}

            <Container>
                <Row>
                    <Col lg={4} style={{ paddingLeft: 0,marginTop:25 }}>
                        <Title>Component Distribution History</Title>
                    </Col>
                    <Col lg={4} style={{ paddingLeft: 0, }}>
                    <label>Season</label>

                        <div className="row_div">
                            <div className="input_div" >
                                <select value={season} onChange={handleInput} name="season"
                                    style={{ margin: 0, background: 'transparent' }}
                                >

                                    <option value="">All</option>
                                    {seasonData.length != 0 && <>
                                        {seasonData.map((item, index) => {
                                            return (
                                                <option value={item.CombYearSeason}>{item.CombYearSeason}</option>
                                            )
                                        })}
                                    </>}

                                </select>
                            </div>
                        </div>
                    </Col>
                    <Col lg={1} style={{ marginTop:25}}>
                        </Col>
                    <Col lg={3} style={{ marginTop:25}}>
                        <button
                            className="export_button"
                            onClick={(e) => exportToCSV(orgtableData)}>
                            <p className="pcat_title">Export CSV</p>
                        </button>
                    </Col>
                  {/*   <Col lg={4} style={{ paddingRight: 0,marginTop:25 }}>
                        <div className="row_div" style={{ paddingRight: 0 }}>
                            <div className="input_div" >
                                <input type="type" style={{ margin: 0, background: 'transparent' }}
                                    name="filterValue"
                                    //    value={filterValue}
                                    onChange={(e) => handleSearch(e)}
                                    placeholder="Search" />
                                <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                            </div>

                        </div>
                    </Col> */}
                   
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
                        <Table className="tablelist" responsive>
                            <thead>
                                <tr className="table_wrap">
{/*                                     <th>Purchase Id</th> */}
                                    <th>Aadhaar No </th>
                                    <th>Contact No</th>
                                    <th>Component</th>
                                    <th>Type</th>
                                    <th>Season</th>
                                    <th>Name</th>
                                    <th>Quantity</th>
                                    <th>UOM</th>
                                     <th>Date&Time</th>
                                     <th>CID</th>
                                     <th>txhash</th>
                                     

                          {/*<th style={{ textAlign: 'right' }}>Action</th>*/}
                            </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {tableData.length != 0 && <tbody>
                                {tableData.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index}className="table_wrap">
{/*                                                 <td>{obj.PurchaseID}</td> */}
                                                <td>{obj.v_farmerAadhar}</td>
                                                <td>{obj.farmerContactNumber}</td>
                                                <td>{obj.SeedName}</td>
                                                <td>{obj.VarietyName}</td>
                                                <td>{obj.year}-{obj.season}</td>
                                                <td>{obj.FarmerName}</td>
                                                <td>{formatter.format(obj.quantity)}</td>
                                                <td>{obj.Uom}</td>
                                                <td>{moment.utc(obj.created_at).format("DD MMM YYYY  h:mm A")}</td>
                                                <td>
                                                <OverlayTrigger
                                                        key={obj.CID}
                                                        overlay={<Tooltip id={`tooltip-top`}>
                                                                {obj.CID}</Tooltip>}>
                                                 <span className="table_text_overflow">{obj.CID}</span>
                                                    </OverlayTrigger>
                                                    </td>
                                                <td>
                                                <OverlayTrigger
                                                        key={obj.txhash}
                                                        overlay={<Tooltip id={`tooltip-top`}>
                                                                {obj.txhash}</Tooltip>}>
                                                 <span className="table_text_overflow" style={{textDecoration: 'underline',color:'blue'}} 
                                                 onClick={()=>handleShowDetails(obj)}>{obj.txhash}</span>
                                                    </OverlayTrigger>
                                                </td>
                                              {/*   <td style={{ textAlign: 'right' }}>
                                                    <div>
                                                        <img src={Edit} onClick={() => handleEditForeCast(obj)} style={{ width: 20, height: 20, margin: 5, cursor: 'pointer' }} />
                                                    </div>
                                                </td> */}
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                            }
                        </Table>
                        {tableData.length != 0 &&
                            <div>
                                <div className="pagination_row_div">
                                    <p className="pagination_title">Showing items per page:</p>
                                    <div className="pagination_input_div" >
                                        <select className="pagination_select"
                                            style={{ margin: 0, background: 'transparent' }}
                                            name="pagination_value"
                                            value={perPage}
                                            onChange={handlerowsperPage} >
                                            <option value={20}>20</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="pagination_row_right_div">
                                    <p className="pagination_total">Showing {offset + 1} to {searchData.length < offset + perPage &&
                                        <span>{searchData.length}</span>}
                                        {searchData.length > offset + perPage &&
                                            <span>{offset + perPage} </span>} of {searchData.length} items</p>
                                    <ReactPaginate
                                        previousLabel={<ArrowBackIosIcon className="pagination_prev_btn" color="#2438a7" />}
                                        nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7" />}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={currentPage} />
                                </div>
                            </div>
                        }                    </Col>
                </Row>
            </Container>
            <Modal show={showDetailsModal} onHide={handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    dialogclassname="modal-content">
                    <Modal.Header closeButton>
                        <Modal.Title> Transaction Details</Modal.Title>
                    </Modal.Header>

                    <Modal.Body >
                    <Row>
                            <Col lg={3}>
                                <p className="pcat_title">BlockHash:</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.blockHash}</p>
                            </Col>
                            </Row>
                            <Row>
                            <Col lg={3}>
                                <p className="pcat_title">BlockNumber :</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.blockNumber}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <p className="pcat_title">ChainId:</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.chainId}</p>
                            </Col>
                            </Row>
                            <Row>
                            <Col lg={3}>
                                <p className="pcat_title">From :</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.from}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <p className="pcat_title">Gas:</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.gas}</p>
                            </Col>
                            </Row>
                            <Row>
                            <Col lg={3}>
                                <p className="pcat_title">GasPrice :</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.gasPrice}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <p className="pcat_title">Hash:</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.hash}</p>
                            </Col>
                            </Row>
                            <Row>
                            <Col lg={3}>
                                <p className="pcat_title">Input :</p>
                            </Col>
                            <Col>
                            <OverlayTrigger
                                                        key={hashDetails.input}
                                                        overlay={<Tooltip id={`tooltip-top`}>
                                                                {hashDetails.input}</Tooltip>}>
                                                                <span className="pcat_title_overFlow">{hashDetails.input}</span>
                                                    </OverlayTrigger>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <p className="pcat_title">Nonce:</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.nonce}</p>
                            </Col>
                            </Row>
                            <Row>
                            <Col lg={3}>
                                <p className="pcat_title">To :</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.to}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <p className="pcat_title">V :</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.v}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <p className="pcat_title">R :</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.r}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <p className="pcat_title">S :</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.s}</p>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            <ToastContainer />
        </Wrapper>
    )
}
export default DistributorSeedHistory