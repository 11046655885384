import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./ForecastNew.styles";
import { Row, Container, Col, Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Edit from "../../images/edit.png";
import plus from "../../images/white_plus.png";
import { useNavigate, useParams } from "react-router-dom";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle";
import { LoadingSpinner } from "../LoadingSpinner/index";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Modal from "react-bootstrap/Modal";


const ForecastNew = () => {
  const { isExpanded } = useContext(UserContext);
  const [foreCastData, setForeCastData] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);

  let { districtID, user_type } = useParams();
  const [disableSubmit, setDisableSubmit] = useState(false);


  const [offset, setOffSet] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [orgtableData, setOrgTableData] = useState([]);
  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [searchKey, setSearchKey] = useState("n");
  const [searchKeyTable, setSearchKeyTable] = useState("");
  const [seasonData, setSeasonData] = useState([]);
  const [season, setSeason] = useState("");
  const [forecastQunatity, setForecastQunatity] = useState("");
  const [editable, setnotEditable] = useState([]);
  const [selectedSeed, setSelectedSeed] = useState([]);
  const [showSubmitModal, setShowsubmittModal] = useState(false);
  const [submitObjectDet, setsubmittobjdet] = useState(false);
  const [userRole, setUserRole] = useState();
  const [expandTable, setexpandTable] = useState(false);
  const [totalAllotQuantity, setTotalQuantity] = useState([]);
  const [totalQuantityErr, setTotalQuantityErr] = useState([]);
  const [showAddQuantity, setShowAddQuantity] = useState(false);
  const [AddedAllotment, setAddedAllotment] = useState();
  const [existingItem, setExcistingItem] = useState();
  const [AddedAllotErr, setAddedAllotErr] = useState();
  const [remainingTotalQuantity, setremainingTotalQuantity] = useState();
  const [itemErr, setItemErr] = useState("");
  const[keyindex,setkeyindex]=useState("");
  const [districtData, setDistrictData] = useState([]);
  const [districtId, setDistrictId] = useState('');

  const [object_det, setObjectData] = useState([]);
  const handleAddMoreQuantity = (item) => {
    setShowAddQuantity(true);
    setExcistingItem(item);
    let remainingtotal = 0;
    remainingtotal = parseInt(forecastQunatity) - parseInt(totalAllotQuantity);
    setremainingTotalQuantity(remainingtotal);
  };

  const handleClose = () => {
    setexpandTable(false);
    setTotalQuantityErr("");
  };
  const handleSubmitt = (obj) => {
    setShowsubmittModal(true);
    setsubmittobjdet(obj);
    /*  setTimeout(() => {
            navigate(0);
        }, 1000); */
  };

  const handleSearch = (e) => {
    let filterValue = e.target.value;
    setCurrentPage(0);
    if (filterValue == "") {
      let offsetsearch = 0;
      var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
      setPageCount(Math.ceil(orgtableData.length / perPage));
      setOrgTableData(orgtableData);
      setTableData(slice);
      setSearchData(orgtableData);
      setOffSet(offsetsearch);
      setCurrentPage(0);
      setSearchKey("n");
    } else {
      let offsetsearch = 0;
      const filteredData = orgtableData.filter((item) => {
        return (
          item?.varietyName
            ?.toLowerCase()
            .includes(filterValue.toLowerCase()) ||
          item?.SeedName?.toLowerCase().includes(filterValue.toLowerCase()) ||
          item?.Season?.toLowerCase().includes(filterValue.toLowerCase()) ||
          item?.Year?.toLowerCase().includes(filterValue.toLowerCase())
        );
      });

      const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
      setPageCount(Math.ceil(filteredData.length / perPage));
      setSearchKey("y");
      setOffSet(offsetsearch);
      setSearchKeyTable(filteredData);
      setTableData(slice);
      setSearchData(filteredData);
    }
  };

 
  const handleInput = async (e) => {
    const { name, value } = e.target;
    if (name == "season") {
      setSeason(value);
      setIsLoading(true);
      let daoid = localStorage.getItem("UserID");

      let result = await API_Auth.getSeedsByDaoSeason(daoid, value);
      console.log("result", result);
      setForeCastData(result);
      setSearchData(result);
      setIsLoading(false);
      //seting pagenation data
      let offset = 0;
      var slice = result.slice(offset, offset + perPage);
      setPageCount(Math.ceil(result.length / perPage));
      setOrgTableData(result);
      setTableData(slice);
      setOffSet(offset);
      setCurrentPage(0);
    }
    if (name == "districtId") {
      setDistrictId(value);
      setIsLoading(true);
      let result = await API_Auth.getSeedsByDaoSeason(value,season);
      console.log("result", result);
      setForeCastData(result);
      setSearchData(result);
      setIsLoading(false);
      //seting pagenation data
      let offset = 0;
      var slice = result.slice(offset, offset + perPage);
      setPageCount(Math.ceil(result.length / perPage));
      setOrgTableData(result);
      setTableData(slice);
      setOffSet(offset);
      setCurrentPage(0);    }
  };
  useEffect(() => {
    setIsLoading(true);
    const user_role = localStorage.getItem("loggedRole");
    setUserRole(user_role);

    const fetchForecast = async () => {
      const seasonsresult = await API_Auth.getSeasons();
      setSeasonData(seasonsresult);
      let daoid = localStorage.getItem("UserID");
      let districts_result = await API_Auth.getDistricts();
      console.log("districts_result", districts_result)
      setDistrictData(districts_result);

      let result = await API_Auth.getSeedsByDao(user_role?.toLowerCase() == "dao"?daoid:1);
      console.log("result", result);

      result.forEach((v) => {
        v.Fqty = v.forecastedQuantity;
      });

      setForeCastData(result);
      setSearchData(result);
      setIsLoading(false);
      //seting pagenation data
      var slice = result.slice(offset, offset + perPage);
      setPageCount(Math.ceil(result.length / perPage));
      setOrgTableData(result);
      setTableData(slice);
    };
    // call the function
    fetchForecast()
      // make sure to catch any error
      .catch(console.error);
  }, []);
  const handlerowsperPage = (e) => {
    let showperpage = e.currentTarget.value;
    setPerPage(parseInt(showperpage));
    setCurrentPage(0);
    if (searchKey == "y") {
      const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
      setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value));
      setTableData(slice);
      setOffSet(0);
      setSearchData(searchKeyTable);
    } else {
      const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
      setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value));
      setTableData(slice);
      setOffSet(0);
      setSearchData(orgtableData);
    }
  };
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offsetdata = selectedPage * perPage;
    setCurrentPage(selectedPage);
    setOffSet(offsetdata);
    loadMoreData(offsetdata);
  };

  const loadMoreData = (offsetdata) => {
    const data = orgtableData;
    if (searchKey == "y") {
      const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
      setPageCount(Math.ceil(searchKeyTable.length / perPage));
      setTableData(slice);
      setOffSet(offsetdata);
      setSearchData(searchKeyTable);
    } else {
      const slice = data.slice(offsetdata, offsetdata + perPage);
      setPageCount(Math.ceil(data.length / perPage));
      setOffSet(offsetdata);
      setTableData(slice);
      setSearchData(data);
    }
  };
  const handleUpdateForecast = async (obj,index) => {
    console.log(obj);
    setkeyindex(index);
    let daoid = localStorage.getItem("UserID");
    let status = "PENDING";
    let district = localStorage.getItem("DistrictID");
    console.log("district", district);
    console.log("obj.Fqty ", obj.Fqty, obj.forecastedUpdatedQty);
    let error = false;
    console.log(obj.forecastedUpdatedQty);
    if (!obj.forecastedUpdatedQty||obj.forecastedUpdatedQty==''||obj.forecastedUpdatedQty==null) {
      error = true;
      setItemErr("Please Enter Quantity");
    } 
   else if (parseInt(obj.Fqty) >= parseInt(obj.forecastedUpdatedQty)) {
      error = true;
      setItemErr("Quantity will not less than " + obj.Fqty);
    } 
    else {
        setItemErr("");
      console.log("hi");
    }
    console.log(error);
    if (!error) {
      setDisableSubmit(true);
      setIsLoading(true);

       let result = await API_Auth.updateForecastSeedByDao(
        daoid,
        obj.seedID,
        obj.varietyID,
        obj.Year,
        obj.Season,
        obj.forecastedUpdatedQty,
        status,
        district
      );
      console.log(result);
      let res = result[0];
      if (res.Flag == "Success") {
        setIsLoading(false);

        toast.success("Forecast Updated successfully");
      } else {
        setIsLoading(false);
        setDisableSubmit(false);


        toast.error("Something went wrong please try again");
      }

      setInterval(() => {
        navigate(0);
      }, 2000); 
    }
  };
  const handlechange = (index, e, obj) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;
    console.log(obj, index);
    const list = [...tableData];
    if (name === "forecast") {
      list[index]["forecastedUpdatedQty"] = value;
    }

    console.log(list);
    setTableData(list);
  };

  const handleEditAllotment = async (obj, action) => {
    console.log(obj);
    setForecastQunatity(obj.AoApproveQuantity);
    console.log(foreCastData);
    let districtID = localStorage.getItem("DistrictID");

    setnotEditable(false);
    console.log(obj);
    setSelectedSeed(obj);
    let result = await API_Auth.getDistributorsWithSameDistricForDAO(
      "distributor",
      obj.seedID,
      districtID,
      obj.varietyID,obj.Year+'-'+obj.Season,

    );
    setexpandTable(true);
    setTotalQuantityErr("");
    console.log(result);
    let totalQuantity = 0;
    for (let i = 0; i < result.length; i++) {
      if (result[i]?.AllotmentQuantity) {
        totalQuantity = totalQuantity + parseInt(result[i]?.AllotmentQuantity);
      }
    }
    const list = [...result];
    for (let i = 0; i < list.length; i++) {
      if (list[i].AllotmentQuantity) {
        list[i]["AllotAddedQuantity"] = list[i].AllotmentQuantity;
      }
    }
    setTotalQuantity(totalQuantity);
    setObjectData(list);
    console.log(totalQuantity);
    setTotalQuantity(totalQuantity);
  };
  const handleChange = (index, e, obj) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;
    console.log(obj, index);
    const list = [...object_det];
    const list1 = [...tableData];
    if (name === "AllotmentQuantity") {
      list[index]["AllotAddedQuantity"] = value;
    }
    if (name === "AddedAllotment") {
      setAddedAllotment(value);
    }
    if (name === "QuantityAo") {
      list1[index][name] = value;
    }
    setTableData(list1);
    let totalQuantity = 0;
    for (let i = 0; i < list.length; i++) {
      if (list[i]?.AllotAddedQuantity) {
        totalQuantity = totalQuantity + parseInt(list[i]?.AllotAddedQuantity);
      }
    }
    setTotalQuantity(totalQuantity);
    setObjectData(list);
    console.log(object_det);
  };
  const handleClosesubmitt = () => {
    setShowsubmittModal(false);
    setShowAddQuantity(false);
  };
  const handleFinalSubmitt = () => {
    setShowsubmittModal(false);
    setTotalQuantityErr("");
    let daoID = localStorage.getItem("UserID");
    console.log(selectedSeed);
    console.log(submitObjectDet);

    if (totalAllotQuantity <= selectedSeed.AoApproveQuantity) {
      console.log(submitObjectDet, foreCastData);
        submitObjectDet.map(async function (app, key) {
        if (!app.AllotmentQuantity) {
          if (app.AllotAddedQuantity && parseInt(app.AllotAddedQuantity) > 0) {
            console.log(app.id, app.AllotAddedQuantity);
            const receive_seed = await API_Auth.getInsertAllotment(
              app.id,
              selectedSeed.seedID,
              selectedSeed.varietyID,
              app.AllotAddedQuantity,
              daoID,
              selectedSeed.Year,
              selectedSeed.Season
            );
            console.log(receive_seed);
            setexpandTable(false);
            setTimeout(() => {
              setexpandTable(false);
              navigate(0);
            }, 2000);
          }
        }
      }); 

      if (!expandTable) {
        toast.success("Allotted successfully");
      }
    } else {
      setTotalQuantityErr("Allotted Quantity is more than approved quantity ");
    }
  };
  const handleAddedAllottment = async () => {
    const list = [...object_det];
    console.log(list);
    console.log(existingItem);
    console.log(AddedAllotment);

    if (AddedAllotment) {
      const list = [...object_det];
      console.log(list);
      console.log(existingItem);
      if (existingItem.AllotmentQuantity >= AddedAllotment) {
        setAddedAllotErr("Added quantity is more than the excisting quantity ");
      } else {
        let tobeAddedQuantity = 0;
        tobeAddedQuantity =
          parseInt(AddedAllotment) - parseInt(existingItem.AllotmentQuantity);
        console.log(tobeAddedQuantity, remainingTotalQuantity);
        if (tobeAddedQuantity > remainingTotalQuantity) {
          console.log("error");
          setAddedAllotErr("Quantity Exceeds");
        } else {
          let daoID = localStorage.getItem("UserID");
          const receive_seed = await API_Auth.getInsertAllotment(
            existingItem.id,
            selectedSeed.seedID,
            selectedSeed.varietyID,
            tobeAddedQuantity,
            daoID,
            selectedSeed.Year,
            selectedSeed.Season
          );
          console.log(receive_seed);
          setAddedAllotErr("");
          setAddedAllotment("");
          setShowAddQuantity(false);
          let totalAllotment =
            parseInt(existingItem.AllotmentQuantity) +
            parseInt(tobeAddedQuantity);
          let totalQuantity = 0;
          console.log(totalAllotment);
          for (let i = 0; i < list.length; i++) {
            if (list[i]?.AllotAddedQuantity) {
              if (list[i]?.id == existingItem.id) {
                list[i].AllotAddedQuantity = totalAllotment;
              }
              totalQuantity =
                totalQuantity + parseInt(list[i]?.AllotAddedQuantity);
            }
          }
          setTotalQuantity(totalQuantity);
          setObjectData(list);
          setTimeout(() => {
            navigate(0);
          }, 2000);
        }
      }
    } else {
      setAddedAllotErr("Pleas Enter Valid quantiy");
    }
  };
  return (
    <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
      {isLoading && <LoadingSpinner />}

      <Container>
        <Row>
          <Col lg={3} style={{ paddingLeft: 0, marginTop: 25 }}>
            <Title>Demand Forecast</Title>
          </Col>
          
          <Col lg={3} style={{ paddingLeft: 0 }}>
            <label>Season</label>

            <div className="row_div">
              <div className="input_div">
                <select
                  value={season}
                  onChange={handleInput}
                  name="season"
                  style={{ margin: 0, background: "transparent" }}
                >
                  <option value="">All</option>
                  {seasonData.length != 0 && (
                    <>
                      {seasonData.map((item, index) => {
                        return (
                          <option value={item.CombYearSeason}>
                            {item.CombYearSeason}
                          </option>
                        );
                      })}
                    </>
                  )}
                </select>
              </div>
            </div>
          </Col>
            {userRole?.toLowerCase() == "dao"&&
                      <Col lg={3} style={{ marginTop: 25 }}>
                      </Col>
           }
                       {userRole?.toLowerCase() == "ao"&&
                      <Col lg={3}>
                           <label>District</label>
                        <div className="row_div" style={{ paddingRight: 0}}>
                            <div className="input_div" >
                                <select
                                    name="districtId"
                                    value={districtId}
                                    onChange={handleInput}
                                    style={{ margin: 0, background: 'transparent' }}>
                                    <option value="">All</option>
                                    {districtData.length != 0 &&
                                        <>
                                            {districtData.map((item, index) => {
                                                return (
                                                    <option value={item.id}>{item.v_districtName}</option>
                                                )
                                            })}
                                        </>}
                                </select>
                            </div>
                        </div>
                      </Col>
           }
          <Col lg={3} style={{ marginTop: 25 }}>
            <div className="row_div" style={{ paddingRight: 0 }}>
              {/*                             <p className="title">Filter By:</p> */}
              <div className="input_div" style={{ marginRight: 0 }}>
                <input
                  type="type"
                  style={{ margin: 0, background: "transparent" }}
                  name="filterValue"
                  //    value={filterValue}
                  onChange={(e) => handleSearch(e)}
                  placeholder="Search"
                />
                <AiOutlineSearch
                  size={24}
                  style={{ margin: 10, cursor: "pointer" }}
                  color="lightgray"
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col lg={12} sm={12} md={12} className="table_head">
            <Table className="tablelist" responsive>
              <thead>
                <tr className="table_wrap">
                  <th>Component</th>
                  <th>Type </th>
                  <th>Year</th>
                  <th>Season</th>
                  <th>Current Forecast</th>
                  <th>Approved Quantity</th>
                  <th>Update Value</th>
                  <th>Status</th>

                  <th style={{ textAlign: "center" }}>Action</th>
                </tr>
              </thead>
              {tableData.length == 0 && (
                <tbody>
                  <tr>
                    <td colSpan="12">
                      <p className="no_Data_table">No Data Found</p>
                    </td>
                  </tr>
                </tbody>
              )}
              {tableData.length != 0 && (
                <tbody>
                  {tableData.map((obj, index) => {
                    return (
                      <>
                        <tr key={index} className="table_wrap">
                          <td>{obj.SeedName}</td>

                          <td>{obj.varietyName}</td>

                          <td>{obj.Year}</td>
                          <td>{obj.Season}</td>

                          <td>{obj.forecastedQuantity}
                          {/*   <input
                              type={"number"}
                              style={{
                                margin: 0,
                                background: "transparent",
                                height: 32,
                                fontSize: 18,
                                width: "90%",
                              }}
                              name="forecast"
                              className="inputdiv"
                              disabled
                              value={obj.forecastedQuantity}
                              onChange={(evnt) =>
                                handlechange(index, evnt, obj)
                              }
                              placeholder=""
                            /> */}
                            {/* {itemErr != "" && (keyindex==index) && (
                              <p style={{ color: "red", margin: 5 }}>
                                {itemErr}
                              </p>
                            )} */}
                          </td>
                          <td>{obj.AoApproveQuantity}
                          {/*   <input
                              type={"number"}
                              style={{
                                margin: 0,
                                background: "transparent",
                                height: 32,
                                fontSize: 18,
                                width: "90%",
                              }}
                              name="approved"
                              className="inputdiv"
                              value={obj.AoApproveQuantity}
                              disabled
                              placeholder=""
                            /> */}
                          </td>
                          <td>
                            <input
                              type={"number"}
                              style={{
                                margin: 0,
                                background: "transparent",
                                height: 32,
                                fontSize: 18,
                                width: "90%",background: '#ddffdb'
                              }}
                              name="forecast"
                              className="inputdiv"
                              value={obj.forecastedUpdatedQty}
                              onChange={(evnt) =>
                                handlechange(index, evnt, obj)
                              }
                              placeholder=""
                            />
                            {itemErr != "" && (keyindex==index) && (
                              <p style={{ color: "red", margin: 5 }}>
                                {itemErr}
                              </p>
                            )}
                          </td>
                          
                          <td>{obj.status}</td>

                          <td style={{ textAlign: "right" }}>
                            <div style={{ display: "flex" }}>
                            <div>
                              <button
                 className="row_table_edit"
                 disabled={disableSubmit}
                 style={{ background: "antiquewhite" ,border:'none'}}
                 onClick={() => handleUpdateForecast(obj,index)}>
                              <p>UPDATE </p>
                              </button>
                            </div>
                            {obj.AoApproveQuantity != 0 && (
                              <div
                                className="row_table_edit"
                                onClick={() => handleEditAllotment(obj, "view")}
                                style={{marginLeft: 2,background: "#c1edc1"}}>
                                <p>ALLOTMENT</p>
                              </div>
                            )}
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              )}
            </Table>
            <Modal
              show={expandTable}
              onHide={handleClose}
              aria-labelledby="contained-modal-title-vcenter"
              dialogclassname="modal-content"
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  {editable ? "Current Allotment" : "Distributor Allotment"}
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div>
                  <p className="pcat_title">
                    AO Approved Quantity : {forecastQunatity}
                  </p>
                  {object_det && object_det && object_det.length == 0 && (
                    <>
                      <Table responsive>
                        <tr>
                          <td colspan="12" className="hiddenRow">
                            <p
                              style={{
                                textAlign: "center",
                                fontWeight: "bold",
                                margin: 10,
                                color: "black",
                              }}
                            >
                              No Data Found
                            </p>
                          </td>
                        </tr>
                      </Table>
                    </>
                  )}
                  {object_det && object_det.length != 0 && (
                    <>
                      <Table responsive>
                        <thead>
                          <th>First Name </th>
                          <th>Last Name</th>
                          <th>Email</th>
                          <th>Allotted Quantity</th>
                        </thead>
                        <tbody>
                          {object_det?.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.FirstName}</td>
                                <td>{item.LastName}</td>
                                <td>{item.email}</td>
                                <td>
                                  <div className="input_div_table">
                                    <input
                                      type={"number"}
                                      style={{
                                        margin: 0,
                                        background: "transparent",
                                        height: 32,
                                        width: "50%",
                                        fontSize: 18,
                                      }}
                                      name="AllotmentQuantity"
                                      className="inputdiv"
                                      value={item.AllotAddedQuantity}
                                      disabled={item.AllotmentQuantity}
                                      onChange={(evnt) =>
                                        handleChange(index, evnt, object_det)
                                      }
                                      placeholder=""
                                    />
                                    {item.AllotmentQuantity > 0 && (
                                      <Button
                                        variant="primary"
                                        onClick={() =>
                                          handleAddMoreQuantity(item)
                                        }
                                      >
                                        Update
                                      </Button>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                      {!editable && (
                        <Row>
                          <Col lg={4}></Col>
                          <Col lg={4}></Col>
                          <Col lg={4} style={{ marginBottom: 12 }}>
                            <div>
                              <p className="pcat_title">
                                Total: {totalAllotQuantity}
                              </p>
                              {totalQuantityErr && (
                                <p className="alert-message">
                                  {" "}
                                  {totalQuantityErr}
                                </p>
                              )}
                            </div>
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                </div>
              </Modal.Body>
              {!editable && (
                <Modal.Footer className="modal-footer" style={{ marginTop: 0 }}>
                  <div className="row_div">
                    <button
                      className="add_button"
                      onClick={() => handleSubmitt(object_det)}
                      style={{
                        padding: 12,
                        height: 45,
                        margin: 10,
                      }}
                    >
                      {/*<img src={plus} style={{ margin: 5 }} /> */}
                      <p className="pcat_title">Allot Quantity</p>
                    </button>
                  </div>
                </Modal.Footer>
              )}
            </Modal>
            <Modal
              aria-labelledby="example-modal-sizes-title-sm"
              size="sm"
              show={showSubmitModal}
              onHide={handleClosesubmitt}
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-sm">
                  Allot Seed
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>Are you sure to allot seed ?</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClosesubmitt}>
                  NO
                </Button>
                <Button variant="primary" onClick={handleFinalSubmitt}>
                  YES
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal
              aria-labelledby="example-modal-sizes-title-sm"
              size="sm"
              show={showAddQuantity}
              onHide={handleClosesubmitt}
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-sm">
                  {" "}
                  Update Seed Quantity (Increase){" "}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <p className="pcat_title">
                    Previous Allotment to the Distributor:{" "}
                    {existingItem?.AllotmentQuantity}
                  </p>
                  <p className="pcat_title">
                    Remaining Limit as per AO Approval: {remainingTotalQuantity}
                  </p>
                </div>
                <input
                  type={"number"}
                  style={{
                    margin: 0,
                    background: "transparent",
                    height: 32,
                    width: "50%",
                    fontSize: 18,
                  }}
                  name="AddedAllotment"
                  className="inputdiv"
                  value={AddedAllotment}
                  onChange={(evnt) => handleChange("", evnt, "")}
                  placeholder="Enter Revised Quantity"
                />
                <p className="alert-message">{AddedAllotErr}</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handleAddedAllottment}>
                  Update
                </Button>
              </Modal.Footer>
            </Modal>
            {tableData.length != 0 && (
              <div>
                <div className="pagination_row_div">
                  <p className="pagination_title">Showing items per page:</p>
                  <div className="pagination_input_div">
                    <select
                      className="pagination_select"
                      style={{ margin: 0, background: "transparent" }}
                      name="pagination_value"
                      value={perPage}
                      onChange={handlerowsperPage}
                    >
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                  </div>
                </div>
                <div className="pagination_row_right_div">
                  <p className="pagination_total">
                    Showing {offset + 1} to{" "}
                    {searchData.length < offset + perPage && (
                      <span>{searchData.length}</span>
                    )}
                    {searchData.length > offset + perPage && (
                      <span>{offset + perPage} </span>
                    )}{" "}
                    of {searchData.length} items
                  </p>
                  <ReactPaginate
                    previousLabel={
                      <ArrowBackIosIcon
                        className="pagination_prev_btn"
                        color="#2438a7"
                      />
                    }
                    nextLabel={
                      <ArrowForwardIosIcon
                        className="pagination_next_btn"
                        color="#2438a7"
                      />
                    }
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    forcePage={currentPage}
                  />
                </div>
              </div>
            )}{" "}
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </Wrapper>
  );
};
export default ForecastNew;
