import React, { Component } from 'react';
import { Outlet } from "react-router-dom";
import Header1 from './Header1';
import Sidebar from './Sidebar';


function Layout2({props}){
    return(
        <>
        <Header1 />
        <Sidebar/>
        <Outlet/>
        </>
    )
}

export default Layout2;