import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper} from "./styles";
import API_Auth from "../../API_Auth";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingSpinner } from "../LoadingSpinner/index";
import { Container, Row, Col } from "react-bootstrap";
import { base_url } from "../../config";
import { Modal, Button } from "react-bootstrap";

const FarmerApplicationDetails = () => {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const { isExpanded } = useContext(UserContext);
  const location = useLocation();

  const [approvedArea, setApprovedArea] = useState(
    location?.state?.famerdetails?.Area
  );
  const [approvedAreaErr, setApprovedAreaErr] = useState("");
  const [applicantType, setApplicantType] = useState("");
  const [applications, setApplications] = useState([]);
  const [activities, setActivities] = useState([]);
  const [schemes, setSchemes] = useState([]);
  const [activityName, setActivityName] = useState("");
  const [schemeName, setSchemeName] = useState("");
  const [showModal, setShowModal] = useState(false);

  /*  */

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [role, setRole] = useState("");

  const [organisation, setOrganisation] = useState(false);
  const [description, setDescription] = useState(false);
  const [applicationData, setApplicationData] = useState("");
  const [activityData, setActivityData] = useState([]);
  const [rejectReason, setrejectReason] = useState("");
  const [reasonModal, setReasonModal] = useState(false);
  const [rejectReasonErr, setrejectReasonErr] = useState("");
  const[pcshow,setPcShow]=useState(false);
  const[areaDocShow,setAreaDocShow]=useState(false);
  const[qualShow,setqualShow]=useState(false)
  const[physicalcopyShow,setphysicalcopyShow]=useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const role = localStorage.getItem("loggedRole");
      setRole(role);

      let applicants = await API_Auth.getApplicants();
      setApplications(applicants);

      setIsLoading(true);
      const result = await API_Auth.getFarmerDetailsByApplicationNO(
        location?.state.famerdetails.ApllicationNo
      );
      console.log("finalresult", result);
      setApplicationData(result.applicationData?.[0]);
      setActivityData(result.activityData);
      setIsLoading(false);

      var tpc = result.applicationData ?.[0].pc;
      if (tpc.indexOf("http://") == 0 || tpc.indexOf("https://") == 0) {
          // do something here
         setPcShow(true)
      }else{
        setPcShow(false)
      }
      var tarea = result.applicationData ?.[0].Areadoc;
      if (tarea.indexOf("http://") == 0 || tarea.indexOf("https://") == 0) {
          // do something here
         setAreaDocShow(true)
      }else{
        setAreaDocShow(false)
      }
  
      var tqual = result.applicationData ?.[0].QualificationDoc;
      if (tqual.indexOf("http://") == 0 || tqual.indexOf("https://") == 0) {
          // do something here
         setqualShow(true)
      }else{
        setqualShow(false)
      }
  
      var tpccopy = result.applicationData ?.[0].phisicalcopy;
      if (tpccopy.indexOf("http://") == 0 || tpccopy.indexOf("https://") == 0) {
          // do something here
         setphysicalcopyShow(true)
      }else{
        setphysicalcopyShow(false)
      }

      var newArray = applicants.filter(function (el) {
        return el.id == location.state.famerdetails.ApplicantType;
      });
      setApplicantType(newArray[0].ApplicantName);

      let activities = await API_Auth.getActivities();
      console.log("activities", activities);
      setActivities(activities);

      var newArray1 = activities.filter(function (el) {
        return el.id == result.applicationData?.[0].activityID;
      });
      setActivityName(newArray1[0].HortiActivity);

      let schemes = await API_Auth.getSchemes();
      setSchemes(schemes);

      var newArray2 = schemes.filter(function (el) {
        return el.id == result.applicationData?.[0].ScheameID;
      });
      setSchemeName(newArray2[0].TypeOfScheme);

      if (
        newArray[0].id.toString() === "2" ||
        newArray[0].id.toString() === "3" ||
        newArray[0].id.toString() === "4" ||
        newArray[0].id.toString() === "5" ||
        newArray[0].id.toString() === "6" ||
        newArray[0].id.toString() === "7" ||
        newArray[0].id.toString() === "8"
      ) {
        setOrganisation(true);
        setDescription(false);
      } else if (newArray[0].id.toString() === "9") {
        setDescription(true);
        setOrganisation(false);
      } else {
        setOrganisation(false);
        setDescription(false);
      }
    };
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, []);
  const handleInput = async (e) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;
    console.log(value);
    if (name === "approvedArea") {
      setApprovedArea(value);
    }
    if (name === "rejectReason") {
      setrejectReason(value);
    }
  };

  const initModal = () => {
    setShowModal(false);
    navigate("/farmer-application-details-by-dho");
  };
  const handleButton = async (obj) => {
    console.log(obj);
    if (role.toLowerCase() === "dho" || role.toLowerCase() === "dao") {
      if (approvedArea === "") {
        setApprovedAreaErr("Please Enter Quantity");
      } else {
        setIsLoading(true);
        if (obj === "Approved") {
          setDisableSubmit(true);

          const result = await API_Auth.updateApplicationDHO(
            obj,
            approvedArea,
            location.state.famerdetails.ApllicationNo,
            rejectReason
          );
          setIsLoading(false);
          setDisableSubmit(false);

          if (result[0].Flag === "Success") {
            setShowModal(true);
          }
        } else {
          setReasonModal(true);
        }
      }
    } else if (role.toLowerCase() === "ao") {
      setIsLoading(true);
      if (obj === "Approved") {
        setDisableSubmit(true);

        const result = await API_Auth.updateApplicationAO(
          obj,
          location.state.famerdetails.ApllicationNo,
          rejectReason
        );
        setIsLoading(false);
        setDisableSubmit(false);

        if (result[0].Flag === "Success") {
          setShowModal(true);

         
        }
      } else {
        setReasonModal(true);
      }
    }
  };

  const handleClose = () => {
    setReasonModal(false);
  };

  const handleReject = async () => {
    if (role.toLowerCase() === "dho" || role.toLowerCase() === "dao") {
      if (rejectReason === "") {
        setrejectReasonErr("Please Enter Reason");
      } else {
        setrejectReasonErr("");
        setIsLoading(true);
        setDisableSubmit(true);
        const result = await API_Auth.updateApplicationDHO(
          "Reject",
          approvedArea,
          location.state.famerdetails.ApllicationNo,
          rejectReason
        );
        console.log(result);
        setIsLoading(false);
        setDisableSubmit(false);

        if (result[0].Flag === "Success") {
          setShowModal(true);
          setReasonModal(false);
        }
      }
    } else if (role.toLowerCase() == "ao") {
      if (rejectReason === "") {
        setrejectReasonErr("Please Enter Reason");
      } else {
        setrejectReasonErr("");
        setIsLoading(true);
        setDisableSubmit(true);

        const result = await API_Auth.updateApplicationAO(
          "Reject",
          location.state.famerdetails.ApllicationNo,
          rejectReason
        );
        console.log(result);
        setIsLoading(false);
        setDisableSubmit(false);

        if (result[0].Flag === "Success") {
          setShowModal(true);
          setReasonModal(false);
        }
      }
    }
  };

  return (
    <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
      <Container>
        <Row className="row_title">
          <Col lg={4}>
            <p>Farmer Application Details</p>
          </Col>
          <Col lg={6}></Col>
        </Row>
      </Container>
      {isLoading && <LoadingSpinner />}

      <hr />

      <Container>
        <Row>
          <Col lg={5} md={5} sm={5}></Col>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label></label>
              <br />

              <label className="custom-file-uploaded">
                <div>
                  <img
                    src={pcshow==true  ? applicationData.pc:base_url + "/images?filename=" + applicationData.pc}
                    style={{ height: 100, width: 100, margin: 10 }}
                  />
                </div>
              </label>
            </div>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Farmer Name</label>
              <br />
              <input
                disabled
                value={applicationData.farmerName}
                style={{ width: "100%" }}
              />
            </div>
          </Col>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Adhar No</label>
              <br />
              <input
                disabled
                value={applicationData?.aadhar}
                style={{ width: "100%" }}
              />
            </div>
          </Col>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Mobile Number</label>
              <br />
              <input
                disabled
                value={applicationData?.farmerCno}
                style={{ width: "100%" }}
              />
            </div>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Activity Name</label>
              <br />
              <input disabled value={activityName} style={{ width: "100%" }} />
            </div>
          </Col>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Scheme</label>
              <br />
              <input disabled value={schemeName} style={{ width: "100%" }} />
            </div>
          </Col>
        </Row>
        {/*  <Row>
          <Col lg={12} sm={12} md={12} className="table_head">
            <Table className="tablelist" >
              <thead>
                <tr>
                
                  <th>Activity Name</th>
                  <th>Scheme Name </th>
                 </tr>
              </thead>
              {activityData.length == 0 && (
                <tbody>
                  <tr>
                    <td colSpan="12">
                      <p className="no_Data_table">No Data Found</p>
                    </td>
                  </tr>
                </tbody>
              )}
              {activityData.length != 0 && (
                <tbody>
                  {activityData.map((obj, index) => {
                    return (
                      <>
                        <tr key={index}>
                            <td>{obj.activityName}</td>
                            <td>{obj.scheameName}</td>
                            


                          </tr>
                      </>
                    );
                  })}
                </tbody>
              )}
            </Table>
        
        </Col>
        </Row>
        */}{" "}
        <hr />
        <Row>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Any Association</label>
              <br />
              <input disabled value={applicantType} style={{ width: "90%" }} />
            </div>
          </Col>
          {description === true && (
            <>
              <Col lg={4} md={4} sm={4}>
                <div className="inputdiv">
                  <label>Description</label>
                  <br />
                  <input
                    value={applicationData?.nameOfOrg}
                    disabled
                    style={{ width: "100%" }}
                  />
                </div>
              </Col>
            </>
          )}
          {organisation == true && (
            <>
              <Col lg={4} md={4} sm={4}>
                <div className="inputdiv">
                  <label>Organisation Name</label>
                  <br />
                  <input
                    value={applicationData?.nameOfOrg}
                    disabled
                    style={{ width: "100%" }}
                  />
                </div>
              </Col>
              <Col lg={4} md={4} sm={4}>
                <div className="inputdiv">
                  <label>Organisation Address</label>
                  <br />
                  <input
                    value={applicationData?.OrgAdd}
                    disabled
                    style={{ width: "100%" }}
                  />
                </div>
              </Col>
              <Col lg={4} md={4} sm={4}>
                <div className="inputdiv">
                  <label>Mobile Number</label>
                  <br />
                  <input
                    value={applicationData?.Orgcontact}
                    disabled
                    style={{ width: "90%" }}
                  />
                </div>
              </Col>
              <Col lg={4} md={4} sm={4}>
                <div className="inputdiv">
                  <label>Pincode</label>
                  <br />
                  <input
                    value={applicationData?.pincode}
                    disabled
                    style={{ width: "100%" }}
                  />
                </div>
              </Col>
            </>
          )}

          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Age</label>
              <br />
              <input
                disabled
                value={applicationData?.Age}
                style={{ width: "90%" }}
              />
            </div>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Area</label>
              <br />
              <input
                value={applicationData?.Area}
                disabled
                style={{ width: "100%" }}
              />
            </div>
          </Col>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Plot No</label>
              <br />
              <input
                value={applicationData?.PlotNo}
                disabled
                style={{ width: "100%" }}
              />
            </div>
          </Col>

          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Area Doc</label>
              <br />
              <label className="custom-file-uploaded">
                <div className="inputdiv">
                  <img
                     src={areaDocShow==true ? applicationData?.Areadoc:
                      base_url +
                      "/images?filename=" +
                      applicationData?.Areadoc
                    }
                    style={{
                      height: 100,
                      width: 100,
                      margin: 10,
                      border: "2px solid rgb(223, 223, 223)",
                      borderRadius: 6,
                    }}
                  />
                </div>
              </label>
            </div>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Qualification</label>
              <br />
              <input
                disabled
                value={applicationData?.Qualification}
                style={{ width: "100%" }}
              />
            </div>
          </Col>
          {applicationData?.Qualification != "Non-Metric" && (
            <Col lg={4} md={4} sm={4}>
              <div className="inputdiv">
                <label>Qualification Doc</label>
                <br />
                <label className="custom-file-uploaded">
                  <div className="inputdiv">
                    <img
                       src={qualShow==true ?  applicationData?.QualificationDoc :
                        base_url +
                        "/images?filename=" +
                        applicationData?.QualificationDoc
                      }
                      style={{
                        height: 100,
                        width: 100,
                        margin: 10,
                        border: "2px solid rgb(223, 223, 223)",
                        borderRadius: 6,
                      }}
                    />
                  </div>
                </label>
              </div>
            </Col>
          )}
        </Row>
        <hr />
        <Row>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Bank Name</label>
              <br />
              <input
                value={applicationData?.BankName}
                disabled
                style={{ width: "100%" }}
              />
            </div>
          </Col>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Bank Branch</label>
              <br />
              <input
                value={applicationData?.Branch}
                disabled
                style={{ width: "90%" }}
              />
            </div>
          </Col>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Bank IFSC Code</label>
              <br />
              <input
                value={applicationData?.BranchIfsc}
                disabled
                style={{ width: "90%" }}
              />
            </div>
          </Col>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Bank Account No</label>
              <br />
              <input
                value={applicationData?.AccountNo}
                disabled
                style={{ width: "100%" }}
              />
            </div>
          </Col>
        </Row>
        <hr />
        <hr />
        <Row>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Physical Copy</label>
              <br />
              <label className="custom-file-uploaded">
                <div className="inputdiv">
                  <img
                    src={physicalcopyShow ==true ?applicationData?.phisicalcopy:
                      base_url +
                      "/images?filename=" +
                      applicationData?.phisicalcopy
                    }
                    style={{
                      height: 100,
                      width: 100,
                      margin: 10,
                      border: "2px solid rgb(223, 223, 223)",
                      borderRadius: 6,
                    }}
                  />
                </div>
              </label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Approved by DHO (Area)</label>
              <br />
              <input
                name="approvedArea"
                disabled={
                  role.toLowerCase() === "ao" ||
                  location?.state?.famerdetails.ApplicationStatusDho !==
                    "Pending"
                    ? true
                    : false
                }
                value={
                  location?.state?.famerdetails.ApplicationStatusDho ===
                  "Pending"
                    ? approvedArea
                    : location?.state?.famerdetails.ApprovedArea
                }
                onChange={handleInput}
                style={{ width: "90%" }}
              />
              {approvedAreaErr !== "" && (
                <p className="alert-message">{approvedAreaErr}</p>
              )}
            </div>
          </Col>
        </Row>
      </Container>

      {(role.toLowerCase() === "dho" || role.toLowerCase() === "dao") &&
        applicationData.ApplicationStatusDho === "Reject" && (
          <Container>
            <Row>
              <Col lg={4} md={4} sm={4}>
                <div className="inputdiv">
                  <label>Reason</label>
                  <br />
                  <input
                    value={applicationData?.RejectReasonDHO}
                    disabled
                    style={{ width: "100%" }}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        )}
        {(role.toLowerCase() === "ao") &&
        applicationData.ApplicationStatusAO === "Reject" && (
          <Container>
            <Row>
              <Col lg={4} md={4} sm={4}>
                <div className="inputdiv">
                  <label>Reason</label>
                  <br />
                  <input
                    value={applicationData?.RejectReasonAO}
                    disabled
                    style={{ width: "100%" }}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        )}
      {(role.toLowerCase() === "dho" || role.toLowerCase() === "dao") &&
        applicationData.ApplicationStatusDho == "Pending" && (
          <Container>
            <Row>
              <Col lg={2}></Col>
              <Col lg={3}>
                <div className="row_div">
                  <button
                    className="add_button"
                    disabled={disableSubmit}
                    onClick={() => handleButton("Approved")}
                    style={{
                      margin: 12,
                      width: "92%",
                    }}
                  >
                    <p className="pcat_title">Approve</p>
                  </button>
                </div>
              </Col>
              <Col lg={3}>
                <div className="row_div">
                  <button
                    className="add_button"
                    disabled={disableSubmit}
                    onClick={() => handleButton("Reject")}
                    style={{
                      margin: 12,
                      width: "92%",
                    }}
                  >
                    <p className="pcat_title">Reject</p>
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        )}

      {role.toLowerCase() === "ao" &&
        location?.state?.famerdetails.ApplicationStatusAO == "Pending" && (
          <Container>
            <Row>
              <Col lg={2}></Col>
              <Col lg={3}>
                <div className="row_div">
                  <button
                    className="add_button"
                    disabled={disableSubmit}
                    onClick={() => handleButton("Approved")}
                    style={{
                      margin: 12,
                      width: "92%",
                    }}
                  >
                    <p className="pcat_title">Approve</p>
                  </button>
                </div>
              </Col>
              <Col lg={3}>
                <div className="row_div">
                  <button
                    className="add_button"
                    disabled={disableSubmit}
                    onClick={() => handleButton("Reject")}
                    style={{
                      margin: 12,
                      width: "92%",
                    }}
                  >
                    <p className="pcat_title">Reject</p>
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      <Modal show={showModal}>
        <Modal.Header closeButton onClick={initModal}>
          <Modal.Title>Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {role.toLowerCase() === "ao" && (
            <p>Application Status AO Changed Successfully</p>
          )}
          {(role.toLowerCase() === "dao" || role.toLowerCase() === "dho") && (
            <p>Application Status DHO Changed Successfully</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={initModal}>OK</Button>
          {/*   <Button variant="dark" onClick={initModal}>
            Store
          </Button> */}
        </Modal.Footer>
      </Modal>

      <Modal
        show={reasonModal}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        dialogclassname="modal-content"
      >
        <Modal.Header closeButton>
          <Modal.Title> Reason for Rejection</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <label>Reason</label>
            <br />
            <div className="input_div">
              <input
                placeholder="Enter Reason"
                name="rejectReason"
                value={rejectReason}
                onChange={handleInput}
                style={{ width: "85%" }}
              />
            </div>
            {rejectReasonErr != "" && (
              <p className="alert-message">{rejectReasonErr}</p>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <div className="row_div">
            <button
              className="add_button"
              disabled={disableSubmit}
              onClick={() => handleReject()}
              style={{
                padding: 12,
                height: 45,
                margin: 10,
              }}
            >
              <p className="pcat_title">Submit</p>
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </Wrapper>
  );
};
export default FarmerApplicationDetails;
