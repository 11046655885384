import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Container, Row, Col } from "react-bootstrap";
import { Wrapper } from "./AddTransferByDistributor.js"
import { LoadingSpinner } from "../LoadingSpinner/index";
import { Title } from "../../GlobalStyle";
import { useNavigate, useParams } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import Delete from "../../images/delete.png"
import { height } from "@mui/system";


const AddTransferByDistributor = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [year, setYear] = useState('');
    const [season, setSeason] = useState('');
    const [yearErr, setYearErr] = useState('');
    const [seasonErr, setSeasonErr] = useState('');
    const navigate = useNavigate();
    const [seedIndex, setSeedIndex] = useState('');
    const [seeds, setSeeds] = useState([]);
    const [seedsSuggestion, setSeedsSuggestion] = useState([]);
    const [seedErr, setSeedErr] = useState('');
    const [seedText, setSeedText] = useState('a');
    const [suppliers, setSuppliers] = useState([]);
    const [supplierId, setSupplierbyId] = useState('');
    const [supplierErr, setSupplierErr] = useState('');

    const [varietyIndex, setVarietyIndex] = useState('');
    const [varietys, setVarietys] = useState([]);
    const [varietySuggestion, setVarietySuggestion] = useState([]);
    const [varietyErr, setVarietyErr] = useState('');
    const [varietyText, setVarietyText] = useState('a');
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [itemErr, setItemErr] = useState('');
    const [chequeno, setChequeno] = useState('');
    const [chequenoErr, setChequenoErr] = useState('');
    const [payment_type, setpayment_type] = useState('');
    const [payment_typeErr, setpayment_typeErr] = useState('');
    const [draftImg, setDraftImg] = useState('');

    const[daoData,setDaoData]=useState([]);
    const[daoID,setDaoID]=useState('');
    const[distributorData,setDistributorData]=useState([]);
    const[approverErr,setApproverErr]=useState('')
    

    const [seasonData, setSeasonData] = useState([]);

    const [inputFields, setInputFields] = useState([{
        seed: '',
        variety: '',
        quantity: '',
        varieties: [],
        seedid: '',
        varietyid: '',
        issueqtyerror: true,
        issueqtyErr: "",
        issuecolor: '',
        allotedqty: '',
        receiverID:'',
        StockQuantity:''
    }]);

    useEffect(() => {
        const fetchSeeds = async () => {

            let seedsdata = await API_Auth.getSeeds();
            seedsdata.sort((a, b) => a.CropName.localeCompare(b.CropName));
            console.log("seedsdata", seedsdata)
            setSeeds(seedsdata);

            let DistrictID =localStorage.getItem("DistrictID");
            console.log("DistrictID",DistrictID)

          
            let daoresult = await API_Auth.getDistributors("DAO");
            const fitered_dao_result=daoresult.filter((item,index)=>{
                return parseInt(item.District)==parseInt(DistrictID)
            })
            console.log("DAO",fitered_dao_result);
            setDaoData(fitered_dao_result);

            let distributorresult = await API_Auth.getDistributors("distributor");

            const userid=localStorage.getItem("UserID");

               console.log(distributorresult);
               /* const filteredData = orgtableData.filter(item => {
                return (item ?.Allotment ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                    item ?.DistributorName ?.toLowerCase().includes(filterValue.toLowerCase())); */
            const fitered_distributor_result = distributorresult.filter(item => {
                return (
                    parseInt(item.id)!=parseInt(userid)&&
                    parseInt(item.District)==parseInt(DistrictID)
                    )
            });
            console.log("distributorresult",fitered_distributor_result);
            console.log("DistrictID",DistrictID);
            setDistributorData(fitered_distributor_result)

            const result = await API_Auth.getSeasons();
            setSeasonData(result);
        };
        // call the function
        fetchSeeds()
            // make sure to catch any error
            .catch(console.error);
    }, [])



    const handleBackClick = () => {
        navigate(-1);;

    }
    const handleInput = (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        if (name == "season"){
            setSeason(value);
            setInputFields([{
                seed: '',
                variety: '',
                quantity: '',
                varieties: [],
                seedid: '',
                varietyid: '',
                issueqtyerror: true,
                issueqtyErr: "",
                issuecolor: '',
                allotedqty: '',
                receiverID:'',
                StockQuantity:''}]);
        } 
        if (name === "approver") setDaoID(value);
           }
    const handleSeedChange = async (item, index) => {

        let error = false;
      
        if (season == '') {
            error = true;

            setSeasonErr("This Field is required");
        } else {
            error = false

            setSeasonErr('')
        }
        if (!error) {

        let totalArray = await handleItems(item);

      
            if (totalArray) {
                inputFields[seedIndex].seed = item.CropName;
                inputFields[seedIndex].seedid = item.id;

                const varieties = await API_Auth.getVarietyBySeed(item.CropName);
                let userid = localStorage.getItem('UserID');

                console.log(season)
                const myArray = season.split("-");
                console.log("myArray",myArray)
                let year_split=myArray[0];
                let year_season=myArray[1]

                const allotedresult = await API_Auth.checkallotement(year_split, year_season, userid, varieties[0].id, item.id)
                console.log("allotedresult", allotedresult)
                console.log("varieties", varieties);
                inputFields[seedIndex].StockQuantity = allotedresult.length != 0 ? allotedresult[0].StockQuantity : 0 
                // setVarieties(varieties)
                inputFields[seedIndex].varieties = varieties;
                inputFields[seedIndex].variety = varieties.length != 0 ? varieties[0].Variety : '';
                inputFields[seedIndex].varietyid = varieties.length != 0 ? varieties[0].id : '';




                /*   setItemErr('');
                  setDuplicateAsset(false); */
                // inputFields[seedIndex].error = true;
                // inputFields[seedIndex].errorlabel = "true"

            } else {
                inputFields[seedIndex].seed = item.CropName;

                //setItemErr('Please Select Another Item ..Item is already added');
                // inputFields[seedIndex].error = false;
                //inputFields[seedIndex].errorlabel = "Duplicate Entry"
                //setDuplicateAsset(true);
            }
            console.log("inputFields", inputFields)
            setInputFields(inputFields)

            setSeedText('a')

            // setItemSuggestions([]);
        }
        

    }
    const addInputField = () => {
        var data = inputFields;
        if (data.length != 0) {
            for (var i = 0; i < data.length; i++) {
                var feed = {
                    seed: '',
                    variety: '',
                    quantity: '',
                    seedid: '',
                    varietyid: '',
                    varieties: [],

                    error: true,
                    errorlabel: "",
                    receiverID:"",
                    StockQuantity:""

                };
            }
        } else {
            var feed = {
                seed: '',
                variety: '',
                quantity: '',
                id: '',
                seedid: '',
                varietyid: '',
                varieties: [],

                error: true,
                errorlabel: "",
                receiverID:"",
                StockQuantity:""

            };
        }
        data.push(feed);
        setInputFields([...data]);
        setItemErr("")
    }

    const handleDeleteRow = (data, index) => {
        var setValue = inputFields;
        console.log(data, index, inputFields)
        const dataRemoved = setValue.filter((el, elindex) => {
            return elindex !== index;
        });
        setInputFields([...dataRemoved]) 
    }

    const handleVarietyChange = async (item, index) => {
        let totalArray = await handleItems(item);

        if (totalArray) {
            inputFields[varietyIndex].variety = item.Variety;
            inputFields[varietyIndex].varietyid = item.id;


            /*   setItemErr('');
              setDuplicateAsset(false); */
            // inputFields[seedIndex].error = true;
            // inputFields[seedIndex].errorlabel = "true"

        } else {
            inputFields[varietyIndex].variety = item.Variety;

            //setItemErr('Please Select Another Item ..Item is already added');
            // inputFields[seedIndex].error = false;
            //inputFields[seedIndex].errorlabel = "Duplicate Entry"
            //setDuplicateAsset(true);
        }
        console.log("inputFields", inputFields)
        setInputFields(inputFields)

        setVarietyText('a')

        // setItemSuggestions([]);

    }



    const handleChange = async (index, evnt) => {

        const { name, value } = evnt.target;
        const list = [...inputFields];

        //setseedIndex(index);
        if (name === "seed") {
            setSeedIndex(index);

            if (value.length == 0) {
                setSeedsSuggestion(seeds);
                list[index][index] = index;
                list[index][name] = value;
            } else {
                const suggestdta = seeds.filter(item => {
                    return item ?.CropName ?.toLowerCase().includes(value.toLowerCase())                    
                });
                list[index][index] = index;
                list[index][name] = value;
                setSeedText('f')

                if (suggestdta.length == 0) {
                    setSeedErr('Seed not found');
                } else {
                    setSeedErr('');

                }
                setSeedsSuggestion(suggestdta);
            }
        }
        if (name === "variety") {
            setVarietyIndex(index);
            console.log(index)

            list[index][name] = value;
            const id = list[index]['varieties'].filter((item, index) => item.Variety == value)
            console.log("id", id)

            list[index]['varietyid'] = id[0].id;
            console.log(season)
            const myArray = season.split("-");
            console.log("myArray",myArray)
            let year_split=myArray[0];
            let year_season=myArray[1]

             let userid = localStorage.getItem('UserID')

             list[index]['quantity']='';
             list[index]['issueqtyerror'] = true;
             list[index]['issueqtyErr'] = "";             


            const allotedresult = await API_Auth.checkallotement(year_split, year_season, userid, id[0].id, list[index].seedid)
            console.log("allotedresult", allotedresult);
            list[index].StockQuantity = allotedresult.length != 0 ? allotedresult[0].StockQuantity : 0 


            console.log("list", list);
            /*  if (value.length == 0) {
                 setVarietySuggestion(varietys);
                 list[index][index] = index;
                 list[index][name] = value;
             } else {
                 const suggestdta = seeds.filter(item => {
                     return item ?.Variety ?.toLowerCase().includes(value.toLowerCase())                    
                 });
                 list[index][index] = index;
                 list[index][name] = value;
                 setVarietyText('f')
 
                 if (suggestdta.length == 0) {
                     setVarietyErr('Variety not found');
                 } else {
                     setVarietyErr('');
 
                 }
                 setVarietySuggestion(suggestdta);
             } */
        }
        if (name == "quantity") {
              list[index][index] = index;
              list[index][name] = value;
              console.log("value",value);
              console.log("value",value);
              list[index]['issueqtyErr'] = ""
           if (parseInt(list[index].StockQuantity) >= parseInt(value)) {
                console.log("Hello");
                list[index]['issueqtyerror'] = true;
                list[index]['issueqtyErr'] = "";
                list[index][name] = value;
                list[index][index] = index

            }
            else if (value == "") {
                console.log("Hello");
                list[index]['issueqtyerror'] = true;
                list[index]['issueqtyErr'] = " ";
                list[index]["issuecolor"] = "y"
                list[index][name] = value;
                list[index][index] = index
            }
             else {
                console.log("Hi")
                console.log("value");
                list[index][index] = index
                list[index]['issueqtyerror'] = false;
                list[index]['issueqtyErr'] = "Quantity Exceeds"
                list[index]["issuecolor"] = "y"
                list[index][name] = value;
            } 
        }
        if (name == "receiverID") {
            list[index][name] = value;
        }

        console.log("list", list)
        const data = list.filter((item) => !item.issueqtyerror);
        console.log(data);
        if(data?.length>0){
            setDisableSubmit(true);
        }
        else{
            setDisableSubmit(false);  
        };
        setInputFields(list);
    }
    const renderItemSuggestions = () => {
        if (seedsSuggestion.length == 0) {
            return null;
        }
        return (
            <ul className={seedsSuggestion.length != 0 && seedText == 'f' ? "ul_search" : "ul_search_length"} >
                {seedsSuggestion.length != 0 && seedText == 'f' &&
                    <>

                        {seedsSuggestion.map((item, index) => {
                            return (
                                <li className="li_search" onClick={() => handleSeedChange(item, index)}>{item.CropName}</li>
                            )
                        })}
                    </>
                }
            </ul>
        )
    }
    const handleCreateData = async () => {
        let error = false;
      
        if (season == '') {
            error = true;

            setSeasonErr("This Field is required");
        } else {
            error = false

            setSeasonErr('')
        }
        if (daoID == '') {
            error = true;

            setApproverErr("This Field is required");
        } 
        if (inputFields.length == 0) {
            error = true;
            setItemErr("Please Add Rows")
        } else {
            error = false;
            setItemErr("")
        }
        if (inputFields.length != 0) {

            for (var i = 0; i < inputFields.length; i++) {

                if (inputFields[i].seed === "" || inputFields[i].variety === "" || inputFields[i].quantity === "" || inputFields[i].receiverID=="" ) {
                    setItemErr("Please Enter All Fields");
                    error = true;
                } else if (
                    inputFields[i].issueqtyerror == false) {
                    error = true;
                }
                else {
                    error = false;
                    setItemErr("");

                }
            }
        }
        var croparray = [];
        var vararray = [];
        var qtyarray = [];
        for (var i = 0; i < inputFields.length; i++) {


           // seedID_verityID_quantity_reciverID

            let var_cropid = inputFields[i]["seedid"] + "_" + inputFields[i]["varietyid"] + "_" + inputFields[i]["quantity"] + "_" + inputFields[i]["receiverID"];
            croparray.push(var_cropid);
         /*    vararray.push(inputFields[i]["varietyid"])

            let qty_Array = inputFields[i]["varietyid"] + "-" + inputFields[i]["quantity"];
            qtyarray.push(qty_Array);
 */
            console.log("croparray", croparray);
           /*  console.log("vararray", vararray);
            console.log("qtyarray", qtyarray); */


        }
        let croptext = croparray.toString();
      /*   let vartext = vararray.toString();
        let qtytext = qtyarray.toString(); */

        console.log("croptext", croptext);
/*         console.log("vartext", vartext);
        console.log("qtytext", qtytext); */

        console.log(season)
        const myArray = season.split("-");
        console.log("myArray",myArray)
        let year_split=myArray[0];
        let year_season=myArray[1]

        if (!error) {
            setDisableSubmit(true);
            let userId = localStorage.getItem("UserID");
            console.log(year_season,year_season,userId,daoID,croptext)

            let add_transfer_request=await API_Auth.getAddTransferByDistributor(year_split,year_season,userId,daoID,croptext)
            setIsLoading(true);
            if (add_transfer_request[0].Flag == "Success") {
                setIsLoading(false);

                toast.success('Transfer added successfully ');
                setTimeout(() => {
                    navigate("/transferrequests-bydistributor");
                }, 2000)
            } else {
                setIsLoading(false);
                setDisableSubmit(false);
                toast.error('Something went wrong');
            } 
        } else {
            setIsLoading(false);
            setDisableSubmit(false);
        }
    }
    const renderVarietySuggestions = () => {
        if (varietySuggestion.length == 0) {
            return null;
        }
        return (
            <ul className={varietySuggestion.length != 0 && varietyText == 'f' ? "ul_search" : "ul_search_length"} >
                {varietySuggestion.length != 0 && varietyText == 'f' &&
                    <>

                        {varietySuggestion.map((item, index) => {
                            return (
                                <li className="li_search" onClick={() => handleVarietyChange(item, index)}>{item.Variety}</li>
                            )
                        })}
                    </>
                }
            </ul>
        )
    }
    const handleItems = async (item) => {
        let totalErrorArray = []


        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].itemid === item.id) {
                return false;
            }
        }
        return true;   // this means not unique

    }
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}
            <Container>
                <Row>
                    <Col lg={2}>
                        <div className="back_button" onClick={() => handleBackClick()}>
                            <IoIosArrowBack size={18} />
                            <h6>Back</h6>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Title>Add Transfer</Title>
                    </Col>
                </Row>
                <hr />
            </Container>
            <Container>
                <Row>
                  
                    <Col lg={5}>
                        <div className="inputdiv">
                            <label>Select Season</label>
                            <br />
                           
                            <select value={season} onChange={handleInput} name="season" >

                                <option value="">Select Season</option>
                                {seasonData.length != 0 && <>
                                    {seasonData.map((item, index) => {
                                        return (
                                            <option value={item.CombYearSeason}>{item.CombYearSeason}</option>
                                        )
                                    })}
                                </>}

                            </select>
                            {seasonErr != '' && <p className="alert-message">{seasonErr}</p>}

                        </div>
                    </Col>
                    <Col lg={5}>
                        <div className="inputdiv">
                            <label>Select Approver</label>
                            <br />
                            <select
                                name="approver"
                                value={daoID}
                                onChange={handleInput}
                                style={{ width: "93%" }}>
                                <option value=''>Select Approver</option>
                                {daoData.length != 0 && <>
                                    {daoData.map((item, index) => {
                                        return (
                                            <option value={item.id}>{item.FirstName}{item.LastName}</option>
                                        )
                                    })}
                                </>}
                            </select>
                            {approverErr != '' && <p className="alert-message">{approverErr}</p>}

                        </div>
                    </Col>
                                   </Row>
                <hr />
                <Row>
                    <Col lg={3}><label> Seed</label></Col>
                    <Col lg={2}><label> Type</label></Col>

                    <Col lg={2}><label> Receivers </label></Col>
                    <Col lg={2}><label>Stock Quantity</label></Col>
                    <Col lg={2}><label> Quantity </label></Col>

                    <Col lg={1}><label>Action</label></Col>

                </Row>
                <div>

                    {inputFields.map((data, index) => {
                        const { seed, variety, quantity, varieties, receiverID, issueqtyerror, issuecolor, issueqtyErr,StockQuantity } = data;
                        return (
                            <Row key={index}>
                                <Col lg={3}>
                                    <div className="inputdiv">
                                        <div className="row_div_search">
                                            <div className="input_div" >
                                                <input type="type" style={{ margin: 0, background: 'transparent' }}
                                                    name="seed"
                                                    value={seed}
                                                    onChange={(evnt) => handleChange(index, evnt)}
                                                    placeholder="Seed" />
                                                <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                                            </div>

                                        </div>
                                        {index == seedIndex &&
                                            <>
                                                {renderItemSuggestions()}
                                            </>
                                        }
                                        {/*  {error == false && <p className="alert-message">{errorlabel}</p>} */}
                                    </div>
                                </Col>

                                <Col lg={2}>
                                   
                                    <div className="inputdiv">

                                        <select
                                            name="variety"
                                            value={variety}
                                            onChange={(evnt) => handleChange(index, evnt)}>
                                            {varieties.length != 0 &&
                                                <>
                                                    {varieties.map((item, index) => {
                                                        return (
                                                            <option value={item.Variety}>{item.Variety}</option>
                                                        )
                                                    })}

                                                </>}
                                        </select>


                                    </div>
                                </Col>

                              <Col lg={2}>
                                   
                                   <div className="inputdiv">

                                       <select
                                           name="receiverID"
                                           value={receiverID}
                                           onChange={(evnt) => handleChange(index, evnt)}>
                                           {distributorData.length != 0 &&
                                               <>
                                                <option style={{fontSize:18}} value=''>Select Receiver</option>

                                                   {distributorData.map((item, index) => {
                                                       return (
                                                           <option value={item.id}>{item.FirstName}{item.LastName}</option>
                                                       )
                                                   })}

                                               </>}
                                       </select>


                                   </div>
                               </Col>
                               <Col lg={2}>

                                    <div className="inputdiv">
                                        <input
                                            value={StockQuantity}
                                            disabled
                                        />

                                    </div>
                                </Col>
                              
                                
                                <Col lg={2}>

                                    <div className="inputdiv">
                                        <input
                                            name="quantity"
                                            value={quantity}
                                            onChange={(evnt) => handleChange(index, evnt)} />
                                        {issueqtyerror == false && issuecolor == "y" && <p style={{
                                            color: '#DF8D0F', textAlign: 'center', fontWeight: '700'
                                        }}>{issueqtyErr}</p>}
                                    </div>
                                </Col>
                                <Col lg={1}>
                                    <img src={Delete} onClick={() => handleDeleteRow(data, index)} style={{ width: 20, height: 20, marginTop: 40, marginLeft: 15, cursor: 'pointer' }} />
                                </Col>


                            </Row>
                        )

                    })}
                    {itemErr != '' && <p className="alert-message">{itemErr}</p>}

                </div>
                <Row>
                    <Col lg={4}>

                        <div className="row_table_div " onClick={() => addInputField()} style={{ width: 115, margin: 15, fontWeight: 'bold', float: 'left' }}>
                            <AiOutlinePlus size={20} style={{ margin: 10 }} />
                            <p>ADD ROWS</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={3}>

                        <button className="add_button" disabled={disableSubmit} onClick={() => handleCreateData()}>
                            <p className="pcat_title"> Submit Transfer Request</p></button>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </Wrapper>
    )
}
export default AddTransferByDistributor