import React, { useState, useContext, useEffect } from "react";
// @ts-ignore
import Logo from "../../images/logo.png";
// Styles
import { Wrapper, LCLogoImg } from "./Header1.styles";
// Routing
import { Link, useNavigate } from "react-router-dom";
// Context
import { UserContext } from "../../context";
import PhoneIcon from "@mui/icons-material/Phone";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

const Header1 = ({ showImage }) => {
  console.log(" ************************** Header ");

  // token & user for app: logged in entity
  let token, user;

  // get token and user from context
  const {
    sessionToken,
    setSessionToken,
    sessionUser,
    setSessionUser,
    setIdentity,
  } = useContext(UserContext);
  token = sessionToken;
  user = sessionUser;
  const [bank, setBank] = useState("issuing");
  const [networkType, setNetworkType] = useState("");

  // get token and user from localStorage
  const token_ls = localStorage.getItem("token");
  const user_ls = JSON.parse(localStorage.getItem("user"));

  const [lang, setLang] = useState("");

  // if token missing in context, get from localStorage
  if (!token || !user) {
    token = token_ls;
    user = user_ls;
  }

  // useEffect
  const navigate = useNavigate();
  /* useEffect(() => {
    const selectedBank = localStorage.getItem('identity');
    setBank(selectedBank);
    console.log("user",user)
    if(user){
      if(user.organisation=="sbi" || user.organisation=="icici"|| user.organisation=="axis"|| user.organisation=="yesbank"){
        setNetworkType("DLT_1")
      }else if(user.organisation=="hdfc" || user.organisation=="indusind"){
        setNetworkType("DLT_2")

      }
    }
    // if token and user missing in context
    if (!token && !user) {
      console.log('token/user not found in context!');
      // set for future context,
      setSessionToken(token_ls);
      setSessionUser(user_ls);
      // if token/user not found in localStorage, redirect to login page
      if (!token_ls && !user_ls) {
        console.log('Go to login!');
        navigate('/login-user');
      }
    }
  }, []);
 */
  const handleNavChange = (e) => {
    let value = e.currentTarget.value;
    let name = e.currentTarget.name;

    if (name == "lang") {
      setLang(value);
    }
  };
  const handleLogin = () => {
    console.log("login");
    navigate("/login");
  };
  const handleFarmerLogin = () => {
    console.log("farmer login");
    //navigate("/farmer-login");
    navigate("/farmer-registration");
  };

  return (
    <>
      {/* <Wrapper>
      <div className="sidebar-headers">
        <Link to="/">
          <LCLogoImg
            src={Logo}
            alt="lc-header-logo"
          />
        </Link>
        <div style={{marginTop:6}}>
          <ul>
            <li><Link to="/about">About Project</Link></li>
            <li><Link to="/farmers-corner">Farmers Corner</Link></li>
            <li><Link to="/department">Department</Link></li>
            <li><Link to="/contactus">Contact Us</Link></li>
          </ul>
        </div>
      </div>
        <div className="header-rights">
        <div className="helpline_cont" style={{ marginRight: 0}}>
             <PhoneIcon  style={{ marginTop: -4,color:"#ffff"}}/>
             </div>
             <div className="helpline_cont" style={{marginLeft: 0, marginRight: 0}}>
          <h6>TOLL FREE -</h6>
      </div>
      <div className="helpline_cont" style={{ marginLeft: 0}}>
      <h6 >1800-123-1136</h6>
      </div>
            <div>
              <select
                value={lang}
                name="lang"
                className="select-module"
                onChange={(e) => handleNavChange(e)}
              >
                <option value="English">English</option>
                <option value="Hindi">Hindi</option>
              </select>
            </div>
            <div className="section-two" style={{marginRight:10}}>
            <div >
              <button  className="logout" style={{width: '100%'}} onClick={()=>handleFarmerLogin()}>
              <p style={{marginLeft:0,padding: '0px 6px'}}>Farmer</p></button>
            </div>
          </div>

          <div className="section-two">
            <div >
              <button  className="logout" onClick={()=>handleLogin()}>
              <p>Login</p></button>
            </div>
          </div>
        </div>
    </Wrapper> */}
      <Navbar
        style={{ background: "#247158", whiteSpace: "nowrap", padding: 0 }}
        collapseOnSelect
        expand="lg"
        fixed="top"
      >
        <Navbar.Brand
          style={{
            fontWeight: 600,
            fontFamily: "Roboto",
            fontSize: 24,
            color: "#ffff",
            marginLeft: 14,
          }}
        >
          <Link to="/">
            <LCLogoImg src={Logo} alt="lc-header-logo" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {/* <Navbar.Collapse className="justify-content-end" style={{marginRight: 38}} id="navbarScroll"> */}
            {/*  <div style={{marginTop:6}}>
          <ul className='link_bg'>
            <li className='link_bg'><Link to="/about">About Project</Link></li>
            <li><Link to="/farmers-corner">Farmers Corner</Link></li>
            <li><Link to="/department">Department</Link></li>
            <li><Link to="/contactus">Contact Us</Link></li>
          </ul>
        </div> */}
            <Navbar.Text style={{ marginTop: 25 }}>
              <li>
                <Link
                  style={{
                    textAlign: "center",
                    fontFamily: "Roboto",
                    letterSpacing: 0.5,
                    padding: 16,
                    fontSize: 16,
                    color: "#ffff",
                    textDecoration: "none",
                  }}
                  to="/about"
                >
                  About Project
                </Link>
              </li>
            </Navbar.Text>
            <Navbar.Text style={{ marginTop: 25 }}>
              <li>
                <Link
                  to="/farmers-corner"
                  style={{
                    textAlign: "center",
                    fontFamily: "Roboto",
                    letterSpacing: 0.5,
                    padding: 16,
                    fontSize: 16,
                    color: "#ffff",
                    textDecoration: "none",
                  }}
                >
                  Farmers Corner
                </Link>
              </li>
            </Navbar.Text>
            <Navbar.Text style={{ marginTop: 25 }}>
              <li>
                <Link
                  to="/department"
                  style={{
                    textAlign: "center",
                    fontFamily: "Roboto",
                    letterSpacing: 0.5,
                    padding: 16,
                    fontSize: 16,
                    color: "#ffff",
                    textDecoration: "none",
                  }}
                >
                  Department
                </Link>
              </li>
            </Navbar.Text>
            <Navbar.Text style={{ marginTop: 25 }}>
              <li>
                <Link
                  to="/contactus"
                  style={{
                    textAlign: "center",
                    fontFamily: "Roboto",
                    letterSpacing: 0.5,
                    padding: 16,
                    fontSize: 16,
                    color: "#ffff",
                    textDecoration: "none",
                  }}
                >
                  Contact Us
                </Link>
              </li>
            </Navbar.Text>
            <Navbar.Text style={{ marginLeft: 40 }}>
              <PhoneIcon style={{ marginTop: 23, color: "#ffff" }} />
            </Navbar.Text>
            <Navbar.Text
              style={{
                fontWeight: 700,
                fontFamily: "Roboto",
                fontSize: 16,
                marginTop: 25,
                color: "#ffff",
              }}
            >
              TOLL FREE -
            </Navbar.Text>
            <Navbar.Text
              style={{
                fontWeight: 700,
                fontFamily: "Roboto",
                fontSize: 16,
                marginTop: 25,
                color: "#ffff",
                marginLeft: 12,
              }}
            >
              1800-123-1136
            </Navbar.Text>
            <Navbar.Text
              style={{
                fontWeight: 700,
                fontFamily: "Roboto",
                fontSize: 18,
                marginLeft: 22,
                color: "#ffff",
                cursor: "pointer",
              }}
            >
              <select
                style={{ width: 180, height: 42 }}
                value={lang}
                name="lang"
                className="select-module"
                onChange={(e) => handleNavChange(e)}
              >
                <option value="English">English</option>
                <option value="Hindi">Hindi</option>
              </select>{" "}
            </Navbar.Text>
            {/* <Navbar.Text onClick={(e) => handleFarmerLogin(e)}
          style={{ fontWeight:700,fontFamily:'Roboto',fontSize:18,color:"#ffff",paddingLeft: 12,
          paddingRight: 12,
          height: 40,
          marginTop: 25,
          borderRadius: 4,
          background: '#fd7e14',
          cursor:'pointer',marginLeft:10}}>
          Farmer</Navbar.Text>
          <Navbar.Text onClick={(e) => handleLogin(e)}
          style={{ fontWeight:700,fontFamily:'Roboto',fontSize:18,color:"#ffff",cursor:'pointer',marginLeft:8,
          paddingLeft: 12,
          paddingRight: 12,
          height: 40,
          marginTop: 25,
          borderRadius: 4,
          marginLeft: 15,
          background: '#fd7e14',}}>
          Login</Navbar.Text> */}
          </Nav>
        </Navbar.Collapse>
        <Navbar.Text
          onClick={(e) => handleFarmerLogin(e)}
          style={{
            fontWeight: 700,
            fontFamily: "Roboto",
            fontSize: 18,
            color: "#ffff",
            paddingLeft: 12,
            paddingRight: 12,
            height: 40,
            marginTop: 5,
            borderRadius: 4,
            background: "#fd7e14",
            cursor: "pointer",
          }}
        >
          Farmer
        </Navbar.Text>
        <Navbar.Text
          onClick={(e) => handleLogin(e)}
          style={{
            fontWeight: 700,
            fontFamily: "Roboto",
            fontSize: 18,
            color: "#ffff",
            cursor: "pointer",
            marginLeft: 8,
            paddingLeft: 12,
            paddingRight: 12,
            height: 40,
            marginTop: 5,
            borderRadius: 4,
            marginLeft: 15,
            marginRight: 12,
            background: "#fd7e14",
          }}
        >
          Login
        </Navbar.Text>
        {/*</Navbar.Collapse>*/}
      </Navbar>
    </>
  );
};

export default Header1;
