import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./styles"
import { Row, Container, Col,Button } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import Edit from "../../images/edit.png";
import plus from "../../images/white_plus.png"
import { useNavigate, useParams ,useLocation} from "react-router-dom";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle"
import { LoadingSpinner } from "../LoadingSpinner/index";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from "moment";
import Modal from 'react-bootstrap/Modal';

const ReceivedQuantity = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [status_type, setStatus_type] = useState(location?.state?.status);

    let { PurchaseID } = useParams();


    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey, setSearchKey] = useState('n');
    const [searchKeyTable, setSearchKeyTable] = useState('');
    const [showSubmitModal, setShowSubmitModal] = useState(false);
    const [receive_seed_qty, setReceiveseed_Qty] = useState('');

    const handleSearch = (e) => {
        let filterValue = e.target.value;
        setCurrentPage(0);
        if (filterValue == '') {
            let offsetsearch = 0;
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setTableData(slice);
            setSearchData(orgtableData);
            setOffSet(offsetsearch);
            setCurrentPage(0);
            setSearchKey('n');
        }
        else {
            let offsetsearch = 0;
            const filteredData = orgtableData.filter(item => {
                return (item ?.Year ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                    item ?.Season ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                        item ?.CropID ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                            item ?.VerietyID ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                                item ?.DemandQuantity ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                                item ?.DraftNo ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                                item ?.SupplierID ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                                item ?.transitQuantity ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                                item ?.receiveQuantity ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                                moment(item ?.DemandDate).format('DD-MM-YYYY').includes(filterValue.toLowerCase()))
            });

            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            setOffSet(offsetsearch);
            setSearchKeyTable(filteredData);
            setTableData(slice);
            setSearchData(filteredData);
        }
    }

    const handleAddPurchase = () => {
       // navigate("/purchase-initiate/"+userID);
    }

    useEffect(() => {
        setIsLoading(true);

        const fetchForecast = async () => {
            let result = await API_Auth.getDispacthByPurchaseId(PurchaseID);
            console.log("result", result)
            let demandDetails = result.demandDetails;
            let list_det = demandDetails;
            for(let i=0; demandDetails.length>i; i++){
                list_det[i]['receiveQuantity'] = list_det[i]['receiveQuantity'] =="" ? demandDetails[i]['transitQuantity']: list_det[i]['receiveQuantity'];
            }
            console.log(list_det);
            setSearchData(list_det);
            setIsLoading(false);
            //seting pagenation data
            var slice = list_det.slice(offset, offset + perPage);
            setPageCount(Math.ceil(list_det.length / perPage));
            setOrgTableData(list_det);
            setTableData(slice);
        };
        // call the function
        fetchForecast()
            // make sure to catch any error
            .catch(console.error);
    }, []);
    const handlerowsperPage = (e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(searchKeyTable);
        }
        else {
            const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(orgtableData);
        }
    }
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        const data = orgtableData;
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setOffSet(offsetdata);
            setSearchData(searchKeyTable);
        } else {
            const slice = data.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(data.length / perPage))
            setOffSet(offsetdata);
            setTableData(slice);
            setSearchData(data);
        }
    }
    const handleSubmitt = (obj) => {
        setShowSubmitModal(true);
        setReceiveseed_Qty(obj);
    };
    const handleInput = (index,e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        const list = [...tableData];
        if (name === "receiveQuantity"){
            list[index][name] = value;
         };
         setTableData(list);
    };
    const handleReceiveSubmitt = async() => {
        setShowSubmitModal(false);
/*         let receive_seed = await API_Auth.receiveSeedQuantity(receive_seed_qty.CropID,receive_seed_qty.VerietyID,
             receive_seed_qty.receive_qty,receive_seed_qty.receive_qty,PurchaseID); */

             tableData.map(async function (app, key) {
                const receive_seed = await API_Auth.receiveSeedQuantity(app.CropID,app.VerietyID,
                    app.transitQuantity,app.receiveQuantity,PurchaseID);
            });
            toast.success('receive seed addesuccessfully ');
            navigate("/receive-list")
         
        /* if (receive_seed[0].Flag == "Success") {
            setIsLoading(false);
            toast.success('receive seed addesuccessfully ');
            setTimeout(() => {
            navigate(0);
            }, 2000)
        } else {
            toast.error('Something went wrong');
        }  */
            };
            const handleClose = () => {
                setShowSubmitModal(false);
            };
            const formatter = new Intl.NumberFormat('en-IN');
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}
            <Container>
                <Row>
                    <Col lg={4} style={{ paddingLeft: 0 }}>
                        <Title>DEMAND DETAILS</Title>
                    </Col>
                    <Col lg={4} style={{ paddingLeft: 0 }}>
                    </Col>
                    <Col lg={4} style={{ paddingRight: 0 }}>
                        <div className="row_div" style={{ paddingRight: 0 }}>
                            {/*                             <p className="title">Filter By:</p> */}
                            <div className="input_div" >
                                <input type="type" style={{ margin: 0, background: 'transparent' }}
                                    name="filterValue"
                                    //    value={filterValue}
                                    onChange={(e) => handleSearch(e)}
                                    placeholder="Search" />
                                <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                            </div>

                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
                        <Table className="tablelist" responsive>
                            <thead>
                                <tr>
                                    <th>Year</th>
                                    <th>Season</th>
                                    <th>Component</th>
                                    <th>Type</th>
                                    <th>Demand Quantity</th>
                                    <th>Demand Date</th>
                                    <th>Supplier</th>
                                    <th>DraftNo No</th>
                                    <th>Transit Quantity </th>
                                    <th>Receive Quantity </th>
{/*                          <th style={{ textAlign: 'center' }}>Action</th> */}
                            </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {tableData.length != 0 && <tbody>
                                {tableData.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                            <td>{obj.Year}</td>
                                                <td>{obj.Season}</td>
                                                <td>{obj.SeedName}</td>
                                                <td>{obj.VarietyName}</td>
                                                <td>{formatter.format(obj.DemandQuantity)}</td>
                                                <td>{moment.utc(obj.DemandDate).format("DD MMM YYYY  h:mm A")}</td>
                                                <td>{obj.SupplierName}</td>
                                                <td>{obj.DraftNo}</td>
                                                <td>{formatter.format(obj.transitQuantity)}</td>
                                                <td><div className="input_div_table" >
                                        <input type={'number'}
                                         style={{ margin: 0, background: 'transparent',textAlign:"-webkit-center", fontSize: 18 }}
                                            name="receiveQuantity" className="inputdiv"
                                            value={obj.receiveQuantity}
                                            disabled={status_type=="complete"}
                                            onChange={(evnt) =>handleInput(index, evnt)}
                                            placeholder="" />
                                        </div></td>
                                               {/*  <td style={{ textAlign: 'right' }}>
                                                    <div className="row_table_edit " onClick={() => handleSubmitt(obj)}>
                                                        <p>Receive</p>
                                                    </div>
                                                    </td> */}
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                            }
                        </Table>
                     {/*    {tableData.length != 0 &&
                            <div>
                                <div className="pagination_row_div">
                                    <p className="pagination_title">Showing items per page:</p>
                                    <div className="pagination_input_div" >
                                        <select className="pagination_select"
                                            style={{ margin: 0, background: 'transparent' }}
                                            name="pagination_value"
                                            value={perPage}
                                            onChange={handlerowsperPage} >
                                            <option value={4}>4</option>
                                            <option value={8}>8</option>
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="pagination_row_right_div">
                                    <p className="pagination_total">Showing {offset + 1} to {searchData.length < offset + perPage &&
                                        <span>{searchData.length}</span>}
                                        {searchData.length > offset + perPage &&
                                            <span>{offset + perPage} </span>} of {searchData.length} items</p>
                                    <ReactPaginate
                                        previousLabel={<ArrowBackIosIcon className="pagination_prev_btn" color="#2438a7" />}
                                        nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7" />}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={currentPage} />
                                </div>
                            </div>
                        }    */}
                        {status_type != "complete"&&
                        <Row>
                        <Col lg={4}>
                        </Col>
                        <Col lg={5}>
                        </Col>
                        <Col lg={3} style={{marginTop:12,marginBottom:12}}>
                         <div>
                         <button className="add_button" onClick={() => handleSubmitt()}>
                         {/*<img src={plus} style={{ margin: 5 }} /> */}
                         <p className="pcat_title">RECEIVE ALL</p></button>
                         </div>
                        </Col>
                        </Row>
                       }
                        </Col>

                </Row>
            </Container>
            <Modal aria-labelledby="example-modal-sizes-title-sm" size="sm" show={showSubmitModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">Receive Seed</Modal.Title>
                    </Modal.Header>
        <Modal.Body>Are you sure you want to Receive seed!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            NO
          </Button>
          <Button variant="primary" 
           onClick={handleReceiveSubmitt}>
            YES
          </Button>
        </Modal.Footer>
                </Modal>
            <ToastContainer />
        </Wrapper>
    )
}
export default ReceivedQuantity