import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation, } from "react-router-dom";
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Collapse from "@mui/material/Collapse";
import { hasChildren } from "../../utils";
import { AiOutlineMenu } from "react-icons/ai";
import Order from "../../images/icons8-purchase-order-48.png"
import forecast_district from "../../images/forecast_district.png"
import district_wise from "../../images/district_wise.png"
import Farmer from "../../images/icons8-farmer-48.png"
import dashboard_distri from "../../images/dashboard_distri.png";
import icons_reports_block from "../../images/icons_reports_block.png";
import icons_farmerslist from "../../images/icons_farmerslist.png";
import blockchain_data from "../../images/blockchain_data.png";

import Distribution from "../../images/icons8-distribution-64.png"
import Seedwise from "../../images/icons8-seed-64.png"
import Team from "../../images/icons8-team-64.png"
import Foreacast from "../../images/icons8-forecast-65.png"
import Transfer from "../../images/icons8-transfer-64.png"
import Purchaser from "../../images/icons8-purchaser-64.png"
import Logo from "../../images/logo_menu.png"
import icon_dashboard from "../../images/icon_dashboard.png"
import distributor_distri from "../../images/distributor_distri.png"
import distributor_history from "../../images/distributor_history.png"
import received_distributor from "../../images/received_distributor.png"
import stock_register from "../../images/stock_register.png"
import request_crops from "../../images/request_crops.png"
import Seeds from "../../images/icons8-sesame-48.png";

// Styles
import { Wrapper } from "./styles";

// Context
import adminSidedata from "../../_nav";

import { UserContext } from "../../context";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Users from "../Users";


const Sidebar = () => {
  let logged_role, logged_user;
  const location = useLocation();
  const [sidebar, setSidebar] = useState([]);
  const [totalArray, setTotalArray] = useState('');
  const [userRole, setUserRole] = useState('');
  const [itemSelect, setItemSelect] = useState('');
  const [show, setShown] = useState(false);
  const [id, setId] = useState('n');
  const [sideMenuData, setSideMenuData] = useState([])
  // get token and user from context
  const navigate = useNavigate();
  const [storeDet, setStoreDet] = useState([]);
  const [storeName, setStoreName] = useState('');
  const [keyLabel, setKeyLabel] = useState('');

  const { isExpanded, sessionLoggedUser, sessionLoggedRole, setTitle, setMainStoreId, setIsExpanded } = useContext(UserContext);

  logged_role = sessionLoggedRole;
  logged_user = sessionLoggedUser;
  const logged_role_ls = localStorage.getItem("loggedRole");
  const logged_user_ls = localStorage.getItem("user");
  const approver_id = localStorage.getItem("ApproverD");
  if (!logged_role_ls) {
    logged_role = logged_role_ls;
    logged_user = logged_user_ls
  }
  // useEffect
  useEffect(() => {
    const logged_user_ls = localStorage.getItem("user");
    const user_role = localStorage.getItem("loggedRole");
    console.log("user_role",user_role)
    setUserRole(user_role)
    const user_id = localStorage.getItem("UserID");
    const DistrictID = localStorage.getItem("DistrictID");

    console.log("DistrictID", DistrictID);

    const FKEY=localStorage.getItem("FarmerKey");
    console.log("FKEY",FKEY);
    if(FKEY==="yes"){
      let data = [

        {
          title: "Farmer Applications ",
          to: "/farmer-application",
          icon: Purchaser,
          items: []
        },
      ]
      setSidebar(data)
    }


    if(user_role!=null){
      localStorage.removeItem("FarmerKey")

    if (user_role.toLowerCase() == 'admin') {
      setSidebar(adminSidedata);
    }
    else if (user_role.toLowerCase() == 'distributor') {
      let distributorData = [

        {
          title: "Seed Purchasing ",
          to: "/purchase-list/" + 'purchase',
          icon: Purchaser,
          items: []
        },
        {
          title: "Received Seeds ",
          to: "/receive-list",
          icon: received_distributor,
          items: []
        },
        {
          title: "Distribute Seeds",
          to: "/distributor-seeds",
          icon: distributor_distri,
          items: []
        },
        {
          title: "Distribution Log",
          to: "/distributor-seed-history",
          icon: distributor_history,
          items: []
        },
        {
          title: "Stock Register ",
          to: "/stock-register",
          icon: stock_register,
          items: []
        },
        {
          title: "Farmer Registration ",
          to: "/add-farmer",
          icon: Farmer,
          items: []
        },
       /*  {
          title: "Farmer Application ",
          to: "/farmer-application",
          icon: Farmer,
          items: []
        }, */

        {
          title: "Transfer Requests ",
          to: "/transferrequests-bydistributor",
          icon: Transfer,
          items: []
        },
        {
          title: "Seed Testing ",
          to: "/seed-testing",
          icon: Purchaser,
          items: []
        },
      /*   {
          title: "Receiver Requests ",
          to: "/receiverequests-bydistributor",
          icon: request_crops,
          items: []
        }, */
      ]
      setSidebar(distributorData);
    }
    else if (user_role.toLowerCase() == 'testing') {
      let testingSideData = [
        {
          title: "Test Requests",
          to: "test-agency-requests",
          icon: Order,
          items: []
        },
       /*  {
          title: "My Team",
          to: "distributor-allotment",
          icon: Team,
          items: []
        }, */
      ]
      setSidebar(testingSideData);
    }
    else if (user_role.toLowerCase() == 'dao'||user_role.toLowerCase() == 'dho') {
      let distributor_items = [
        {
          title: "Distributor Allotment",
          to: "distributor-allotment",
          icon: Order,
          items: []
        },
      ]
      let daoSideData = [


        {
          title: "My Seeds",
          to: "my-seeds",
          icon: Seeds,
          items: []
        },
        {
          title: "My Team",
          to: "distributor-allotment",
          icon: Team,
          items: []
        },
        
        /* {
          title: "Forecast & Allotment ",
          to: "/forecast-dao/" + DistrictID+"/dao",
          icon: Foreacast,
          items: []
        }, */

        {
          title: "Forecast & Allotment New",
          to: "/forecast-dao-new/" + DistrictID+"/dao",
          icon: Foreacast,
          items: []
        },
        {
          title: "Distributor Dashboard ",
          to: "/dashboard-distributionwise",
          icon: Distribution,
          subitems: []
        }, 
         {
          title: "Block level Data",
          to: "/district-wise-farmer-registrations",
          icon: blockchain_data,
          subitems: []
        },
        {
          title:"Distribution Report",
          to: "/distribution-details-list",
          icon: icons_reports_block,
          subitems: []
        },
        {
          title:"Farmer Application Details",
          to: "/farmer-application-details-by-dho",
          icon: icons_reports_block,
          subitems: []
        },
       /*  {
          title: "Block Level Data",
          to: "/district-wise-farmer-distributions",
          icon: Farmer,
          subitems: []
        }, */
        /* {
          title: "CROP WISE ",
          to: "/dashboard-seedwise",
          icon: Seedwise,
          subitems: []
        }, 
        {
          title: "DISTRICT WISE ",
          to: "/dashbord-districwise",
          icon: Order,
          items: []
        },*/
        {
          title: "Transfer Requests",
          to: "/transfer-requests-dao",
          icon: Transfer,
          items: []
        },
        {
          title: "Test Requests",
          to: "test-requests",
          icon: Order,
          items: []
        },
      ]
      setSidebar(daoSideData);
    } 
    else if (user_role.toLowerCase() == 'mis') {
      let misSideData = [
        {
          title: "Dashboard",
          to: "/dashboard-ao",
          icon: icon_dashboard,
          subitems: []
        }, 
      {
          title: "District Wise ",
          to: "/dashbord-districtwise",
          icon: district_wise,
          items: []
        },
        /* {
          title: "Component Wise1 ",
          to: "/dashboard-seedwise",
          icon: Seedwise,
          subitems: []
        }, */
        {
          title: "Component Wise",
          to: "/dashboard-seedwise2",
          icon: Seedwise,
          subitems: []
        },
        {
          title: "Distributor Wise ",
          to: "/dashboard-distributionwise",
          icon: Distribution,
          subitems: []
        }, 
        {
          title:"Registration Report",
          to: "/farmer-details-list",
          icon: icons_farmerslist,
          subitems: []
        },
        {
          title:"Distribution Report",
          to: "/distribution-details-list",
          icon: icons_reports_block,
          subitems: []
        },
        /* {
          title:"District Level Data",
          to: "/farmer-list-district-wise",
          icon: dashboard_distri,
          subitems: []
        }, */
        {
          title: "Block Level Data",
          to: "/district-wise-farmer-registrations",
          icon: blockchain_data,
          subitems: []
        },
        {
          title: "Find Farmer ",
          to: "/find-farmer",
          icon: Farmer,
          items: []
        },
      ]
      setSidebar(misSideData);
    }
    else if (user_role.toLowerCase() == 'ao_mis') {
      let ao_misData = [
        {
          title: "Dashboard",
          to: "/dashboard-ao",
          icon: icon_dashboard,
          subitems: []
        }, 
      {
        title: "District Wise ",
        to: "/dashbord-districtwise",
        icon: district_wise,
        items: []
      },
      {
        title: "Component Wise1 ",
        to: "/dashboard-seedwise",
        icon: Seedwise,
        subitems: []
      },
      {
        title: "Component Wise2 ",
        to: "/dashboard-seedwise2",
        icon: Seedwise,
        subitems: []
      },
      {
        title: "Distributor Wise ",
        to: "/dashboard-distributionwise",
        icon: Distribution,
        subitems: []
      }, 
      {
        title:"Registration Report",
        to: "/farmer-details-list",
        icon: icons_farmerslist,
        subitems: []
      },
      {
        title:"Distribution Report",
        to: "/distribution-details-list",
        icon: icons_reports_block,
        subitems: []
      },
      {
        title:"District Level Data",
        to: "/farmer-list-district-wise",
        icon: dashboard_distri,
        subitems: []
      },
      {
        title: "Block Level Data",
        to: "/district-wise-farmer-registrations",
        icon: blockchain_data,
        subitems: []
      },
      {
        title: "Find Farmer ",
        to: "/find-farmer",
        icon: Farmer,
        items: []
      },
    ]
      setSidebar(ao_misData);
    }
    else if (user_role.toLowerCase() == 'ao') {
      let aoSideData = [
        /* {
          title: "Allotment Module",
          to: "/forecast-ao",
          icon: Distribution,
          subitems: []
        }, */ 
      /*   {
          title: "Allotment Module New",
          to: "/forecast-ao-new",
          icon: Distribution,
          subitems: []
        },  */
        /* {
          title: "Dashboard",
          to: "/dashboard-ao",
          icon: icon_dashboard,
          subitems: []
        },  */
        {
          title: "District Allotment",
          to: "/forecast-ao-update-new",
          icon: Distribution,
          subitems: []
        }, 
        
        {
          title: "Supplier Allotment",
          to: "/supplier-limit-allotment",
          icon: Distribution,
          subitems: []
        }, 
       /*  {
          title: "Allotment Module New One",
          to: "/forecast-dao-new/" + DistrictID+"/dao",
          icon: Foreacast,
          items: []
        }, */
        {
          title: "District Wise ",
          to: "/dashbord-districtwise",
          icon: district_wise,
          items: []
        },
        {
          title: "Distributor Wise ",
          to: "/dashboard-distributionwise",
          icon: Distribution,
          subitems: []
        }, 
        {
          title: "Component Wise1 ",
          to: "/dashboard-seedwise",
          icon: Seedwise,
          subitems: []
        },
        {
          title: "Component Wise2 ",
          to: "/dashboard-seedwise2",
          icon: Seedwise,
          subitems: []
        },
       
        {
          title: "Demand Forecast ",
          to: "/district-wise-forecast",
          icon: forecast_district,
          subitems: []
        },
        {
          title:"Registration Report",
          to: "/farmer-details-list",
          icon: icons_farmerslist,
          subitems: []
        },
        {
          title:"Distribution Report",
          to: "/distribution-details-list",
          icon: icons_reports_block,
          subitems: []
        },
        {
          title:"District Level Data",
          to: "/farmer-list-district-wise",
          icon: dashboard_distri,
          subitems: []
        },
        {
          title: "Block Level Data",
          to: "/district-wise-farmer-registrations",
          icon: blockchain_data,
          subitems: []
        },
        /* {
          title: "Block Level Data",
          to: "/district-wise-farmer-distributions",
          icon: Farmer,
          subitems: []
        }, */
        {
          title:"Farmer Application Details",
          to: "/farmer-application-details-by-dho",
          icon: icons_reports_block,
          subitems: []
        },
        {
          title: "Farmer Registration",
          to: "/add-farmer",
          icon: Farmer,
          items: []
        },
        {
          title: "Find Farmer ",
          to: "/find-farmer",
          icon: Farmer,
          items: []
        },
       
     
      ]
      setSidebar(aoSideData);
    }
    else if (user_role.toLowerCase() == 'supplier') {

      let suppliserSidedata = [
        
        {
          title: "Supplier Dashboard",
          to: "/supplier-dashboard",
          icon: Order,
          items: []
        },
        {
          title: "Supply Requests",
          to: "/demand-request-supplier",
          icon: Order,
          items: []
        },



      ]
      setSidebar(suppliserSidedata);
    }
  }

  }, [])

  const handleMove = (obj) => {
    if (obj.to != '')
      navigate(obj.to);
    //e.preventDefault();

    //  navigate(0);
  };
  const handleToggler = () => {
    if (isExpanded) {
      setIsExpanded(false);
      return;
    }
    setIsExpanded(true);
  };
  const MenuItem = ({ item }) => {
    const Component = hasChildren(item) ? MultiLevel : SingleLevel;
    return <Component item={item} />;
  };
  
  const SingleLevel = ({ item }) => {
    return (
      <ListItem onClick={() => handleMove(item)} primary={item.title} button className={location.pathname == item.to ? "menu-open" : ""}>
        <ListItemIcon>{item.icon != '' && <img src={item.icon} className="sidebar-icon" />}</ListItemIcon>
        <ListItemText sx={{fontSize:15}}>{item.title} </ListItemText>
      </ListItem>
    );
  };
  const MultiLevel = ({ item }) => {
    const { items: children } = item;
    const [open, setOpen] = useState(true);


    let open_menu = children.filter(function (value, key) {
      if (value.items.length > 0) {
        let found_menu = false;
        let citems = value.items;

        for (let i = 0; i < citems.length; i++) {
          let result = false;
          if (citems[i].to != '') {
            result = location.pathname.includes(citems[i].to);
          }
          if (citems[i].to == location.pathname || result) {
            found_menu = true;
          }
          if (citems[i].items && citems[i].items.length > 0) {
            let ccitems = citems[i].items;
            for (let i = 0; i < ccitems.length; i++) {
              let to = ccitems[i].to.split(' ').join('%20');
              let result = to.includes(location.pathname);
              if (to == location.pathname || result) {
                found_menu = true;
              }
            }
          }
        }
        return found_menu;
      }
      return value.to == location.pathname;
    });

    return ( 
      <React.Fragment>
        <ListItem button >
          <ListItemIcon>{item.icon != '' && <img src={item.icon} className="icon sidebar-icon" />}</ListItemIcon>
          <ListItemText primary={item.title} >
          </ListItemText>
        </ListItem>
        <Collapse in={open || open_menu.length > 0} timeout="auto" unmountOnExit>
          <hr />
          <List component="div" className="sub-item" disablePadding>
            {children.map((child, key) => (
              <MenuItem key={key} item={child} />
            ))}
          </List>
        </Collapse>
      </React.Fragment>
    );
  };


  /*  var approver_id =localStorage.getItem("ApproverD", )
   console.log("approver_id",approver_id) */


  const handleShow = () => {
    setShown(true);
  }
  return (
    <Wrapper>


      <div className={isExpanded ? "sidebar" : "sidebar collapsed"}>
        <div onClick={handleToggler} className={isExpanded ? "menu_toogler" : "menu_toogler collapsed"}>
          <AiOutlineMenu size={24} />
        </div>

          {isExpanded && <img src={Logo} alt="img" style={{ width: '100%',height: 'auto',padding:15,margin: "5px auto"}} />}
        {!isExpanded && <img src={Logo} alt="img" style={{ width: '100%',height: 'auto',padding:15,margin: "5px auto" }} />}
 


        {sidebar.map((item, key) => <MenuItem key={key} item={item} />)}
      </div>

    </Wrapper >
  );
};

export default Sidebar;
