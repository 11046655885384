import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Container, Row, Col } from "react-bootstrap";
import { Wrapper } from "./SupplyOrder.styles"
import { LoadingSpinner } from "../LoadingSpinner/index";
import { Title } from "../../GlobalStyle";
import { useNavigate, useParams } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import Delete from "../../images/delete.png"


const SupplyOrder = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [year, setYear] = useState('');
    const [season, setSeason] = useState('');
    const [seasonforAPI, setSeasonAPI] = useState('');
    const [yearErr, setYearErr] = useState('');
    const [seasonErr, setSeasonErr] = useState('');
    const navigate = useNavigate();
    const [seedIndex, setSeedIndex] = useState('');
    const [seeds, setSeeds] = useState([]);
    const [seedsSuggestion, setSeedsSuggestion] = useState([]);
    const [seedErr, setSeedErr] = useState('');
    const [seedText, setSeedText] = useState('a');

    const [varietyIndex, setVarietyIndex] = useState('');
    const [varietys, setVarietys] = useState([]);
    const [varietySuggestion, setVarietySuggestion] = useState([]);
    const [varietyErr, setVarietyErr] = useState('');
    const [varietyText, setVarietyText] = useState('a');
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [itemErr, setItemErr] = useState('')

    let { districtID } = useParams();

    const [seasonData,setSeasonData]=useState('');
    const [inputFields, setInputFields] = useState([{
        seed: '',
        variety: '',
        quantity: '',
        varieties: [],
        seedid:'',
        varietyid:''
    }]);

    useEffect(() => {
        const fetchSeeds = async () => {

            let seedsdata = await API_Auth.getSeeds();
            seedsdata.sort((a, b) => a.CropName.localeCompare(b.CropName));
            setSeeds(seedsdata);
            setVarietys(seedsdata)

            let result=await API_Auth.getSeasons();
            setSeasonData(result)
        };
        // call the function
        fetchSeeds()
            // make sure to catch any error
            .catch(console.error);
    }, [])



    const handleBackClick = () => {
        navigate(-1);;

    }
    const handleInput = (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
       // if (name === "year") setYear(value);
        if (name == "season") {
            console.log("comb season value",value);
            const myArray = value.split("-");
            console.log(myArray)
            setYear(myArray[0]);
            setSeasonAPI(myArray[1]);
            setSeason(value);
        }

    }
    const handleSeedChange = async (item, index) => {
        let totalArray = await handleItems(item);

        if (totalArray) {
            inputFields[seedIndex].seed = item.CropName;
            inputFields[seedIndex].seedid = item.id;

            const varieties = await API_Auth.getVarietyBySeed(item.CropName);
            console.log("varieties", varieties);
            // setVarieties(varieties)
            inputFields[seedIndex].varieties = varieties;
            inputFields[seedIndex].variety = varieties.length != 0 ? varieties[0].Variety : '';
            inputFields[seedIndex].varietyid = varieties.length != 0 ? varieties[0].id : '';




            /*   setItemErr('');
              setDuplicateAsset(false); */
            // inputFields[seedIndex].error = true;
            // inputFields[seedIndex].errorlabel = "true"

        } else {
            inputFields[seedIndex].seed = item.CropName;

            //setItemErr('Please Select Another Item ..Item is already added');
            // inputFields[seedIndex].error = false;
            //inputFields[seedIndex].errorlabel = "Duplicate Entry"
            //setDuplicateAsset(true);
        }
        console.log("inputFields", inputFields)
        setInputFields(inputFields)

        setSeedText('a')

        // setItemSuggestions([]);

    }
    const addInputField = () => {
        var data = inputFields;
        if (data.length != 0) {
            for (var i = 0; i < data.length; i++) {
                var feed = {
                    seed: '',
                    variety: '',
                    quantity: '',
                    seedid: '',
                    varietyid:'',
                    varieties: [],

                    error: true,
                    errorlabel: ""

                };
            }
        } else {
            var feed = {
                seed: '',
                variety: '',
                quantity: '',
                id: '',
                seedid: '',
                varietyid:'',
                varieties: [],

                error: true,
                errorlabel: ""

            };
        }
        data.push(feed);
        setInputFields([...data]);
        setItemErr("")
    }

    const handleDeleteRow = (data, index) => {
        var setValue = inputFields;
        const dataRemoved = setValue.filter((el, elindex) => {
            return el.id !== data.id;
        });

        setInputFields([...dataRemoved])


    }

    const handleVarietyChange = async (item, index) => {
        let totalArray = await handleItems(item);

        if (totalArray) {
            inputFields[varietyIndex].variety = item.Variety;
            inputFields[varietyIndex].varietyid = item.id;
            /*   setItemErr('');
              setDuplicateAsset(false); */
            // inputFields[seedIndex].error = true;
            // inputFields[seedIndex].errorlabel = "true"

        } else {
            inputFields[varietyIndex].variety = item.Variety;

            //setItemErr('Please Select Another Item ..Item is already added');
            // inputFields[seedIndex].error = false;
            //inputFields[seedIndex].errorlabel = "Duplicate Entry"
            //setDuplicateAsset(true);
        }
        console.log("inputFields", inputFields)
        setInputFields(inputFields)

        setVarietyText('a')

        // setItemSuggestions([]);

    }



    const handleChange = (index, evnt) => {

        const { name, value } = evnt.target;
        const list = [...inputFields];

        //setseedIndex(index);
        if (name === "seed") {
            setSeedIndex(index);

            if (value.length == 0) {
                setSeedsSuggestion(seeds);
                list[index][index] = index;
                list[index][name] = value;
            } else {
                const suggestdta = seeds.filter(item => {
                    return item ?.CropName ?.toLowerCase().includes(value.toLowerCase())                    
                });
                list[index][index] = index;
                list[index][name] = value;
                setSeedText('f')

                if (suggestdta.length == 0) {
                    setSeedErr('Component not found');
                } else {
                    setSeedErr('');

                }
                setSeedsSuggestion(suggestdta);
            }
        }
        if (name === "variety") {
            setVarietyIndex(index);
            console.log(index)
            
            list[index][name] = value;
            const id=list[index]['varieties'].filter((item,index)=> item.Variety==value)
            console.log("id",id)

            list[index]['varietyid']=id[0].id


            console.log("list", list);
            /*  if (value.length == 0) {
                 setVarietySuggestion(varietys);
                 list[index][index] = index;
                 list[index][name] = value;
             } else {
                 const suggestdta = seeds.filter(item => {
                     return item ?.Variety ?.toLowerCase().includes(value.toLowerCase())                    
                 });
                 list[index][index] = index;
                 list[index][name] = value;
                 setVarietyText('f')
 
                 if (suggestdta.length == 0) {
                     setVarietyErr('Variety not found');
                 } else {
                     setVarietyErr('');
 
                 }
                 setVarietySuggestion(suggestdta);
             } */
        }
        if (name == "quantity") {
            list[index][index] = index;
            list[index][name] = value;
        }


        setInputFields(list);
    }
    const renderItemSuggestions = () => {
        if (seedsSuggestion.length == 0) {
            return null;
        }
        return (
            <ul className={seedsSuggestion.length != 0 && seedText == 'f' ? "ul_search" : "ul_search_length"} >
                {seedsSuggestion.length != 0 && seedText == 'f' &&
                    <>

                        {seedsSuggestion.map((item, index) => {
                            return (
                                <li className="li_search" onClick={() => handleSeedChange(item, index)}>{item.CropName}</li>
                            )
                        })}
                    </>
                }
            </ul>
        )
    }
    const handleCreateData = async () => {
        let error = false;
       /*  if (year == '') {
            error = true;
            setYearErr("This Field is required");
        } else {
            error = false
            setYearErr('')
        } */
        if (season == '') {
            error = true;
            setSeasonErr("This Field is required");
        } else {
            error = false

            setSeasonErr('')
        }
        if (inputFields.length == 0) {
            error = true;
            setItemErr("Please Add Rows")
        } else {
            error = false;
            setItemErr("")
        }
        if (inputFields.length != 0) {

            for (var i = 0; i < inputFields.length; i++) {

                if (inputFields[i].seed === "" || inputFields[i].variety === "" || inputFields[i].quantity === "") {
                    setItemErr("Please Enter All Fields");
                    error = true;
                } else {
                    error = false;
                    setItemErr("");

                }
            }
        }
        var array=[];
        for (var i = 0; i < inputFields.length; i++) {

             let var_request = inputFields[i]["seedid"] + "_" + inputFields[i]["varietyid"] + "_" + inputFields[i]["quantity"] ;
            array.push(var_request);
            console.log("array",array);
        }
        if (!error) {
            setIsLoading(true);
            let userId = localStorage.getItem("UserID");
            let status="SentToAO";
            setDisableSubmit(true);

          //  let add_forecast 
            console.log(year, seasonforAPI, userId, districtID, array,status);
           let add_forecast = await API_Auth.addForecast(year, seasonforAPI, userId, districtID, array,status);
            if (add_forecast[0].Flag == "Success") {
                setIsLoading(false);

                toast.success('Forecast added successfully');
                setTimeout(() => {
                    navigate("/forecast-dao/" + districtID+"/dao");
                }, 2000)
            } else {
                setDisableSubmit(false);
                toast.error('Something went wrong');
            } 
        } else {
            setDisableSubmit(false);

        }
    }
    const renderVarietySuggestions = () => {
        if (varietySuggestion.length == 0) {
            return null;
        }
        return (
            <ul className={varietySuggestion.length != 0 && varietyText == 'f' ? "ul_search" : "ul_search_length"} >
                {varietySuggestion.length != 0 && varietyText == 'f' &&
                    <>

                        {varietySuggestion.map((item, index) => {
                            return (
                                <li className="li_search" onClick={() => handleVarietyChange(item, index)}>{item.Variety}</li>
                            )
                        })}
                    </>
                }
            </ul>
        )
    }
    const handleItems = async (item) => {
        let totalErrorArray = []


        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].itemid === item.id) {
                return false;
            }
        }
        return true;   // this means not unique

    }
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}
            <Container>
                <Row>
                    <Col lg={2}>
                        <div className="back_button" onClick={() => handleBackClick()}>
                            <IoIosArrowBack size={18} />
                            <h6>Back</h6>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Title>Send Supply Order to Component Supplier</Title>
                    </Col>
                </Row>
                <hr />
            </Container>
            <Container>
                <Row>
                   {/*  <Col lg={5}>
                        <div className="inputdiv">
                            <label> Select Year</label>
                            <br />
                            <select
                                name="year"
                                value={year}
                                onChange={handleInput} >
                                <option value=''>Select Year</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                            </select>
                            {yearErr != '' && <p className="alert-message">{yearErr}</p>}
                        </div>
                    </Col> */}
                    <Col lg={5}>
                        <div className="inputdiv">
                            <label>Select Year & Season</label>
                            <br />
                            <select value={season} onChange={handleInput} name="season" >

                                        <option value="">Select Season</option>
                                        {seasonData.length != 0 && <>
                                            {seasonData.map((item, index) => {
                                                return (
                                                    <option value={item.CombYearSeason}>{item.CombYearSeason}</option>
                                                )
                                            })}
                                        </>}

                                    </select>
                            {seasonErr != '' && <p className="alert-message">{seasonErr}</p>}

                        </div>
                    </Col>
                </Row>

                <hr />
                <Row>
                    <Col lg={4}><label>Select Component</label></Col>
                    <Col lg={4}><label>Select Component Type</label></Col>

                    <Col lg={3}><label>Required Quantity (in kgs)</label></Col>
                    <Col lg={1}><label>Action</label></Col>

                </Row>
                <div>

                    {inputFields.map((data, index) => {
                        const { seed, variety, quantity, varieties } = data;
                        return (
                            <Row key={index}>
                                <Col lg={4}>
                                    <div className="inputdiv">
                                        <div className="row_div_search">
                                            <div className="input_div" >
                                                <input type="type" style={{ margin: 0, background: 'transparent' }}
                                                    name="seed"
                                                    value={seed}
                                                    onChange={(evnt) => handleChange(index, evnt)}
                                                    placeholder="Component" />
                                                <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                                            </div>

                                        </div>
                                        {index == seedIndex &&
                                            <>
                                                {renderItemSuggestions()}
                                            </>
                                        }
                                        {/*  {error == false && <p className="alert-message">{errorlabel}</p>} */}
                                    </div>
                                </Col>

                                <Col lg={4}>
                                    {/*  <div className="inputdiv">
                                        <div className="row_div_search">
                                            <div className="input_div" >
                                                <input type="type" style={{ margin: 0, background: 'transparent' }}
                                                    name="variety"
                                                    value={variety}
                                                    onChange={(evnt) => handleChange(index, evnt)}
                                                    placeholder="Seed" />
                                                <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                                            </div>

                                        </div>
                                        {index == varietyIndex &&
                                            <>
                                                {renderVarietySuggestions()}
                                            </>
                                        }
                                       
                                    </div>
 */}

                                    <div className="inputdiv">

                                        <select
                                            name="variety"
                                            value={variety}
                                            onChange={(evnt) => handleChange(index, evnt)}>
                                            {varieties.length != 0 &&
                                                <>
                                                    {varieties.map((item, index) => {
                                                        return (
                                                            <option value={item.Variety}>{item.Variety}</option>
                                                        )
                                                    })}

                                                </>}
                                        </select>


                                    </div>
                                </Col>
                                <Col lg={3}>

                                    <div className="inputdiv">
                                        <input
                                            name="quantity"
                                            value={quantity}
                                            onChange={(evnt) => handleChange(index, evnt)} />

                                    </div>
                                </Col>
                                <Col lg={1}>
                                    <img src={Delete} onClick={() => handleDeleteRow(data, index)} style={{ width: 20, height: 20, marginTop: 40, marginLeft: 15, cursor: 'pointer' }} />
                                </Col>


                            </Row>
                        )

                    })}
                    {itemErr != '' && <p className="alert-message">{itemErr}</p>}

                </div>
                <Row>
                    <Col lg={4}>

                        <div className="row_table_div " onClick={() => addInputField()} style={{ width: 115, margin: 15, fontWeight: 'bold', float: 'left' }}>
                            <AiOutlinePlus size={20} style={{ margin: 10 }} />
                            <p>ADD ROWS</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={3}>

                        <button className="add_button" disabled={disableSubmit} onClick={() => handleCreateData()}>
                            <p className="pcat_title"> CREATE</p></button>
                    </Col>
                </Row>
            </Container>
            <ToastContainer/>
        </Wrapper>
    )
}
export default SupplyOrder