import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./styles"
import { Row, Container, Col } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import Edit from "../../images/edit.png";
import plus from "../../images/white_plus.png"
import { useNavigate, useParams } from "react-router-dom";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle"
import { LoadingSpinner } from "../LoadingSpinner/index";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from "moment";
import { AiOutlinePlusSquare, AiOutlineSearch } from "react-icons/ai";
import { base_url } from "../../config"
import { IoIosArrowBack } from "react-icons/io";
import Modal from "react-bootstrap/Modal";

const DispatchIndividual = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    const [purchaseDetails, setPurchaseDetails] = useState([]);
    const [dispatchDetails, setDispatchDetails] = useState([]);

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);

    let { PurchaseID, type } = useParams();


    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey, setSearchKey] = useState('n');
    const [searchKeyTable, setSearchKeyTable] = useState('');
    const [shownDrawerIndex, setshownDrawerIndex] = useState(null);
    const[itemErr,setItemErr]=useState('');
    const[userRole,setUserRole]=useState('');
    const[receiveDisabled,setreceiveDisabled]=useState(false);
    const[dispatchID,setDispatchID]=useState(false);
    /* const[receive_file,setreceive_file]=useState(false);
    const[receive_fileErr,setreceive_fileErr]=useState(false);
    const[receive_fileShow,setreceiveFileShow]=useState(false);*/
    const [disableSubmit, setDisableSubmit] = useState(false); 
    const [commentsModal, setcommentsModal] = useState(false); 
    const [testing_det, settesting_det] = useState(''); 
    const [testing_coments, settesting_coments] = useState(''); 
    const [commentsErr, setcommentsErr] = useState(''); 
    const showDrawyer = async (e, index, DispatchID) => {
        console.log(index, shownDrawerIndex, DispatchID);
        setDispatchID(DispatchID);
        if (shownDrawerIndex == index) {
            let all_dispatchs = [...dispatchDetails];
            //all_seds[index].items = undefined;
            let result = await API_Auth.getDispacthByTransitDetails(DispatchID);
            let items = result.transitDetail;
            all_dispatchs[index].items = items;
            all_dispatchs[index].transportdetails = result.transport;

            let details = all_dispatchs[index].items
            if (details.length != 0) {
                for (var i = 0; i < details.length; i++) {
                    if (details[i].receiveQuantity == "" || details[i].receiveQuantity == null) {
                        details[i].receiveQuantity = details[i].transitQuantity
                    }
                }
            }
            details.forEach(v => {
                v.issueqtyerror = false;
                v.issueqtyErr = '';
                v.issuecolor = '';
            });
            all_dispatchs[index].items = details
            console.log(details);

            // setSeedDemandDetail(details);

            console.log("all_dispatchs", all_dispatchs)

            setDispatchDetails(all_dispatchs);
            console.log("show", all_dispatchs);
            setshownDrawerIndex(null);
        }
        else if (shownDrawerIndex) {
            let all_dispatchs = [...dispatchDetails];
            //all_seds[index].items = undefined;
            let result = await API_Auth.getDispacthByTransitDetails(DispatchID);
            let items = result.transitDetail;
            all_dispatchs[index].transportdetails = result.transport;

            all_dispatchs[index].items = items;
            let details = all_dispatchs[index].items
            if (details.length != 0) {
                for (var i = 0; i < details.length; i++) {
                    if (details[i].receiveQuantity == "" || details[i].receiveQuantity == null) {
                        details[i].receiveQuantity = details[i].transitQuantity
                    }
                }
            }
            details.forEach(v => {
                v.issueqtyerror = false;
                v.issueqtyErr = '';
                v.issuecolor = '';
            });
            all_dispatchs[index].items = details
            console.log(details);

            // setSeedDemandDetail(details);

            console.log("all_dispatchs", all_dispatchs)


            setDispatchDetails(all_dispatchs);
            console.log("show", all_dispatchs);
        }
        else {
            e.preventDefault();
            let all_dispatchs = [...dispatchDetails];
            let result = await API_Auth.getDispacthByTransitDetails(DispatchID);
            console.log(result);
            console.log("not show", result);
            let items = result.transitDetail;

            all_dispatchs[index].items = items;
            all_dispatchs[index].transportdetails = result.transport;
            let details = all_dispatchs[index].items
            if (details.length != 0) {
                if(type="receive"){
                for (var i = 0; i < details.length; i++) {
                    if (details[i].recevedQuantity == "" || details[i].recevedQuantity == null) {
                        details[i].recevedQuantity = details[i].transitQuantity
                    }
                }
            }
            }
            details.forEach(v => {
                v.issueqtyerror = true;
                v.issueqtyErr = '';
                v.issuecolor = '';
            });
            all_dispatchs[index].items = details
            console.log(details);

            // setSeedDemandDetail(details);

            console.log("all_dispatchs", all_dispatchs)



            setDispatchDetails(all_dispatchs);
            setshownDrawerIndex(index);
        }
    }

    const handleSearch = (e) => {
        let filterValue = e.target.value;
        setCurrentPage(0);
        if (filterValue == '') {
            let offsetsearch = 0;
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setTableData(slice);
            setSearchData(orgtableData);
            setOffSet(offsetsearch);
            setCurrentPage(0);
            setSearchKey('n');
        }
        else {
            let offsetsearch = 0;
            const filteredData = orgtableData.filter(item => {
                return (item ?.SeedName ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                            item ?.VerietyID ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                                item ?.DemandQuantity ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                                            item ?.transitQuantity ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                                                item ?.receiveQuantity ?.toLowerCase().includes(filterValue.toLowerCase()))
            });

            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            setOffSet(offsetsearch);
            setSearchKeyTable(filteredData);
            setTableData(slice);
            setSearchData(filteredData);
        }
    }

    const handleGotoDispatchScreen = (obj) => {
        console.log(obj[0].SupplierID);
        navigate("/dispatch-supplier/" +PurchaseID+"/"+ obj[0].SupplierID)
    }

    useEffect(() => {
        setIsLoading(true);
        const user_role = localStorage.getItem("loggedRole");
        setUserRole(user_role);
        const fetchForecast = async () => {
            let result = await API_Auth.getDispacthByPurchaseId(PurchaseID);
            console.log("result", result)
            let demandDetails = result.demandDetails;
            let dispatchDetails = result.dispatchDetails;
            let purchaseStatus = result?.purchaseStatus ?.[0];
            let list = [...demandDetails];
            for(let i=0; i<demandDetails.length; i++){
                let TobeReceiveQuantity = parseInt(demandDetails[i]?.DemandQuantity)-parseInt(demandDetails[i]?.receiveTotalQuantity);
                if(parseInt(TobeReceiveQuantity)>parseInt(demandDetails[i]?.transitQuantity)){
                list[i]["ReceivedStatus"] = 'partialReceived';
                }else if(parseInt(TobeReceiveQuantity)==parseInt(demandDetails[i]?.transitQuantity)){
                    list[i]["ReceivedStatus"] = 'complete';
                    }
                   /*  else if(parseInt(TobeReceiveQuantity)<parseInt(demandDetails[i]?.transitQuantity)){
                        list[i]["ReceivedStatus"] = 'complete';
                        } */
                 }
            setSearchData(list);
            console.log(list);
            setDispatchDetails(dispatchDetails);
            setPurchaseDetails(purchaseStatus);
            setSearchData(demandDetails);
            setIsLoading(false);
            //seting pagenation data
            var slice = demandDetails.slice(offset, offset + perPage);
            setPageCount(Math.ceil(demandDetails.length / perPage));
            setOrgTableData(demandDetails);
            setTableData(slice);
        };
        // call the function
        fetchForecast()
            // make sure to catch any error
            .catch(console.error);
    }, []);
    const handlerowsperPage = (e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(searchKeyTable);
        }
        else {
            const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(orgtableData);
        }
    }
    /* const handleFileupload = async () => {
        var formData = new FormData();
        var imagefile = document.querySelector('#file-upload');
        formData.append("receive_file", imagefile.files[0]);
        console.log(imagefile.files[0]);
        console.log(formData);
        let upload_res = await API_Auth.uploadImage(formData);
        if (upload_res.data == 'File is uploaded') {
            var file_name = '';
            for (var key of formData.entries()) {
                file_name = key[1].name;
            }
            console.log(upload_res);
            console.log(file_name);
            setreceive_file(file_name);
            setreceiveFileShow(base_url + "/images?filename=" + file_name);
        }
    } */
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        const data = orgtableData;
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setOffSet(offsetdata);
            setSearchData(searchKeyTable);
        } else {
            const slice = data.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(data.length / perPage))
            setOffSet(offsetdata);
            setTableData(slice);
            setSearchData(data);
        }
    }
    const handleViewDispatch = (obj) => {
        console.log("obj", obj.id)
        //navigate("/transit-data-by-dispatch/" + obj.DispatchIDs);
        navigate("/transaction-details/" + obj.id);

    }
    
    const handleChange = (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        if (name === "testing_coments") {
            settesting_coments(value);
        }
    }
    const handleInput = (index, e, obj, inde) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        console.log("index", index);
        console.log("inde", inde)
        console.log(name, value);
        const list = [...dispatchDetails];
                if (name === "recevedQuantity") {
            console.log(" list[inde].items[index]", list[index].items[inde])
            //list[index].items[inde][name] = value;
            if (parseInt(list[index].items[inde].transitQuantity) >= parseInt(value)) {
                console.log("Hello");
                list[index].items[inde]['issueqtyerror'] = true;
                list[index].items[inde]['issueqtyErr'] = "";
                list[index].items[inde][name] = value;
            } 
            else if (value == "") {
                console.log("Hello");
                list[index].items[inde]['issueqtyerror'] = true;
                list[index].items[inde]['issueqtyErr'] = "";
                list[index].items[inde][name] = value
            }

            else {
                console.log("Hi")
                list[index].items[inde]['issueqtyerror'] = false;
                list[index].items[inde]['issueqtyErr'] = "Quantity Exceeds"
                list[index].items[inde]["issuecolor"] = "y"
                list[index].items[inde][name] = value
            }
        };
        console.log("list", list)
        setDispatchDetails(list);
        // all_seds[index].items = items;
        console.log(tableData);
    };
    const handleReceiveSeed = (obj) => {
        setreceiveDisabled(true);
        let error=false
        console.log(obj);
        if(obj.length!=0){
            for(var i=0;i<obj.length;i++){
                if (obj[i].issueqtyerror == false) {
                    error = true;
                    setItemErr("Something Went Wrong");
                    setreceiveDisabled(false);
                } else {
                    setItemErr("")
                    error = false
                }
            }
        }
        console.log("error",error);
         if(!error){
            setIsLoading(true)
            const data=tableData.filter((item)=>item.ReceivedStatus=='partialReceived');
             console.log(data);
             let status = ''
             if(data != ''){
              status = 'PartialComplete'
             }
             else{
                status = 'complete'
               }
               console.log(status);
               console.log(obj);
               if(status == 'complete'){
               const data1=obj.filter((item)=>parseInt(item.transitQuantity)>parseInt(item.recevedQuantity)); 
               console.log(data1);
               if(data1 != ''){
                status = 'PartialComplete'
               }
               else{
                  status = 'complete'
                 }
                }
                 console.log(status);
                 setIsLoading(false);

            obj.map(async function (app, key) {
            let user_id = localStorage.getItem("UserID");
            const receive_seed = await API_Auth.receiveSeedQuantity(app.cropID, app.verityID,
                app.recevedQuantity, user_id, PurchaseID,tableData[0].Year,tableData[0].Season,status,dispatchID); 
        }); 
        setIsLoading(false);
         toast.success('receive seed added successfully');
          setTimeout(() => {
        navigate(0) ;
        }, 2000)   
    } 
    }
    const handleClose = (obj) => {
        setcommentsModal(false);
    }
    const handleRequestReceive = async(obj) => {
        console.log(obj);
        setcommentsModal(true);
        settesting_det(obj);
     }
    const handleaddTesting = async() => {
        if(testing_coments != ''){
            console.log(testing_det);
           setcommentsModal(false);
           setDisableSubmit(true);
             const result = await API_Auth.getRequestTestingAPI('INSERTTEST',testing_det.CropID, testing_det.VerietyID, 
             testing_det.Year, testing_det.Season,testing_det.UserID, testing_det.daoID,
               '','Initiated', '', '', '', testing_coments, '', '', testing_det.purchageID);  
               let res = result[0];
                  if (res.Flag == "Success") {
                   setIsLoading(false);
                   toast.success("Request testing successfully");
                 } else {
                   setIsLoading(false);
                   setDisableSubmit(false);
                   toast.error("Something went wrong please try again");
                 }
                 setInterval(() => {
                   navigate(0);
                 }, 2000);  
        }else{
            setcommentsErr("please send any comments")
        }

        
     }

    const handleBackClick = () => {
        if(type == 'receive'){
            navigate("/receive-list");
        }
        else if(type == 'seed_testing'){
            navigate("/seed-testing");
        }
        else{
        navigate("/demand-request-supplier");
        }
    }
    const formatter = new Intl.NumberFormat('en-IN')
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}
            <Container style={{marginTop:54}}>
        <Row className="row_title">
          <Col lg={4}>
{/*             <p>{"DEMAND DETAILS"}</p> */}
          </Col>
          <Col lg={6}></Col>
          <Col lg={2}>
            <div className="back_button" onClick={() => handleBackClick()}>
              <IoIosArrowBack size={18} />
              <h6>Back</h6>
            </div>
          </Col>
        </Row>
      </Container>
      {isLoading && <LoadingSpinner />}

      <hr />
            {/* <Container>
                <Row>
                <Col lg={4} style={{ paddingLeft: 0 }}>
                        <Title>PURCHASE STATUS</Title>
                    </Col>
                    </Row>
                    <div className="row_div">
                    <Row>
                        <Row>
                            <Col lg={5}>
                                <p className="pcat_title">Status:</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{purchaseDetails.Status}</p>
                            </Col>
                            </Row>
                            <Row>
                            <Col lg={5}>
                                <p className="pcat_title">Supply Status:</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{purchaseDetails.SupplyStatus}</p>
                            </Col>
                            </Row>
                            </Row>
                            </div>
                            
                    </Container>
             */}        <Container>
                <Row>
                    <Col lg={4} style={{ paddingLeft: 0 }}>
                        <Title>DEMAND DETAILS</Title>
                    </Col>
                    <Col lg={4} style={{ paddingRight: 0 }}>
                   {/*  <div>
                            <button className="add_button" onClick={() => handleGotoDispatchScreen()}>
                                <p className="pcat_title">Dispatch</p></button>
                        </div> */}
                    </Col>
                     <Col lg={4} style={{ paddingLeft: 0 }}>
                            <div className="row_div" style={{ paddingRight: 0 }}>
                            <div className="input_div" >
                                <input type="type" style={{ margin: 0, background: 'transparent' }}
                                    name="filterValue"
                                    //    value={filterValue}
                                    onChange={(e) => handleSearch(e)}
                                    placeholder="Search" />
                                <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                            </div>

                        </div> 
                    </Col> 
                </Row>
            </Container>
            <Container>
                <Row className="table_head" style={{ marginTop: 10, marginBottom: 30 }}>
                    <Col lg={6} style={{ flexDirection: 'row', display: 'flex', marginTop: 10,alignItems: 'center' }}>
                        <label> Demand Date : </label>{tableData.length != 0 && 
                        <p className="plabel" style={{margin: 0,marginLeft:4}}>{tableData[0].DemandDate}</p>}
                    </Col>
                    <Col lg={6} style={{ flexDirection: 'row',display:'flex',marginTop:10,alignItems:'center'}}>
                        <label> Draft Image : </label>
                        {tableData[0]?.DraftImage&&
                         <label className="custom-file-uploaded" >
                         <div>
                             <img src={base_url + "/images?filename=" + tableData[0].DraftImage} />
                         </div>
                       </label>
                        }
                        {/*{!tableData[0].DraftImage&&
                             /*  <p className="plabel">
                        {tableData[0].DraftImage}</p> 
                        } */}
                    </Col>
                    <Col lg={6} style={{ flexDirection: 'row', display: 'flex',marginBottom:14,marginTop: 8 }}>
                        <label> Draft No : </label>{tableData.length != 0 && 
                        <p className="plabel">{tableData[0].DraftNo}</p>}
                    </Col>
                </Row>
                <Row>

                    <Col lg={12} sm={12} md={12} className="table_head">
                        <Table className="tablelist" responsive>
                            <thead>
                                <tr>
                                    {/*   <th>Year</th>
                                    <th>Season</th> */}
                                    <th>Component</th>
                                    <th>Type</th>
                                    <th>Demand (in kg)</th>
                                    <th>Total Received</th>
                                    <th>In Transit  </th>
{/*                                     <th>Received  </th> */}
                                    {/*<th style={{ textAlign: 'right' }}>Action</th>*/}
                                    {userRole.toLowerCase()== 'distributor'&&  
                                         <th style={{ textAlign: "center" }}>Action</th> }
                                </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {tableData.length != 0 && <tbody>
                                {tableData.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                                {/*   <td>{obj.Year}</td>
                                                <td>{obj.Season}</td> */}
                                                <td>{obj.SeedName}</td>
                                                <td>{obj.VarietyName}</td>
                                                <td>{formatter.format(obj.DemandQuantity)}</td>
                                                {/*    <td>{obj.DemandDate}</td>
                                               <td>{obj.SupplierName}</td>
                                             <td>{obj.DraftNo}</td>
                                             <td>{obj.DraftImage}</td> */}
                                                <td>{formatter.format(obj.receiveTotalQuantity)}</td>
                                                <td>{formatter.format(obj.transitQuantity)}</td>
                                                {userRole.toLowerCase()== 'distributor'&&  
                       <>
                       {obj.receiveTotalQuantity > 0 && 
                       <>
                     {obj.testingStatus&&
                        <td style={{ textAlign: "center" }}className="pcat_title">
                  {obj.testingStatus}     
                        </td>
                         }
                         </>
                     }
                   </>
                   }
                    {userRole.toLowerCase()== 'distributor'&&  
                       <>
                       {obj.receiveTotalQuantity > 0 && 
                       <>
                       {!obj.testingStatus&&
                        <td style={{ textAlign: "right" }}>
                            <div>
                              <button
                                className="row_table_edit"
                                style={{
                                  background: "antiquewhite",
                                  border: "none",
                                  width:'100%'
                                }}
                                disabled={disableSubmit}
                                onClick={() => handleRequestReceive(obj, index)}>
                                <p>Request Testing</p>
                              </button>
                            </div>
                          </td> 
                                }
                                </>
                            }
                          </>
                          }
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>


                            }
                        </Table>
                        {userRole.toLowerCase()== 'supplier'&&purchaseDetails.Status != "complete"&&purchaseDetails.Status != "inprocess"&&
                        <>
                        <Col lg={2} style={{ paddingRight: 0 }}>
                             <div>
                            <button className="add_button" onClick={() => handleGotoDispatchScreen(tableData)}>
                                <p className="pcat_title">Dispatch</p></button>
                        </div>
                    </Col> 
                    </>
                        }
                    </Col>
                    
                </Row>
            </Container>
            {type !='seed_testing' &&
             <>
            <Container>
                <Row>
                    <Col lg={4} style={{ paddingLeft: 0 }}>
                    {userRole.toLowerCase()== 'supplier'&& <Title>DISPATCH DETAILS</Title> }
                    {userRole.toLowerCase()== 'distributor'&& <Title>RECEIVE LOT DETAILS</Title> }
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
                        <Table className="tablelist" responsive>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Dispatch ID</th>
                                    <th>Dispatch Date</th>
                                </tr>
                            </thead>
                            {dispatchDetails.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {dispatchDetails.length != 0 && <tbody>
                                {dispatchDetails.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                                <td class="pointer" onClick={(e) => showDrawyer(e, index, obj.DispatchIDs)} >
                                                    <AiOutlinePlusSquare size="24" style={{ marginLeft: 10, cursor: "pointer" }} />
                                                </td>
                                                <td>{obj.DispatchIDs}</td>
                                                <td>{moment.utc(obj.dispatchDate).format("DD MMM YYYY  h:mm A")}</td>

                                            </tr>

                                            {obj.transportdetails && obj.transportdetails.length != 0 && shownDrawerIndex == index && <tr>
                                                <td colspan="12" className="hiddenRow">
                                                    <p style={{ fontWeight: "bold", padding: 4, margin: 10, fontSize: 16 }}>Transport Details</p>
                                                </td>
                                            </tr>
                                            }
                                            {obj.transportdetails &&
                                                <tr>
                                                    <td colspan="12" className="hiddenRow">
                                                        {
                                                            (obj.transportdetails && obj.transportdetails.length == 0) && <>
                                                                <Table responsive>
                                                                    <tr>
                                                                        <td colspan="12" className="hiddenRow">
                                                                            <p style={{
                                                                                textAlign: "center",
                                                                                fontWeight: "bold",
                                                                                margin: 10,
                                                                                color: 'black'
                                                                            }}>No Data Found</p>

                                                                        </td>
                                                                    </tr>
                                                                </Table>
                                                            </>
                                                        }
                                                        {
                                                            (obj.transportdetails && obj.transportdetails.length != 0) && <>

                                                                <Table responsive style={{ display: shownDrawerIndex == index ? '' : 'none' }}>
                                                                    <thead>
                                                                        <tr>

                                                                            <th>Truck No </th>
                                                                            <th>Driver Name</th>
                                                                            <th>Driver Contact</th>
                                                                            <th>Quantity In Truck</th>
                                                                            <th>Loading Date</th>
                                                                            {/*    <th>Truck Detail</th>
                                    <th>Purchase Id</th> */}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {obj.transportdetails.map((items, index) => {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td>{items.truckNo}</td>
                                                                                    <td>{items.DriverName}</td>
                                                                                    <td>{items.driverCno}</td>
                                                                                    <td>{formatter.format(items.QuantityInTruck)}</td>
                                                                                    <td> {moment.utc(items.loadingDate).format("DD MMM YYYY  h:mm A")}</td>
                                                                                    {/*    <td>{items.truckDetail}</td>
                                                                                   <td>{items.parchaseID}</td> */}

                                                                                </tr>
                                                                            )
                                                                        })}

                                                                    </tbody>

                                                                </Table>
                                                               {/*  <Row style={{ display: shownDrawerIndex == index ? '' : 'none' }}>
                                                                    <Col lg={4}>
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                    </Col>
                                                                    <Col lg={2} style={{ marginBottom: 12 }}>

                                                                    </Col>
                                                                </Row> */}
                                                            </>
                                                        }
                                                    </td>
                                                </tr>
                                            }
                                            {obj.items && obj.items.length != 0 && shownDrawerIndex == index && <tr>
                                                <td colspan="12" className="hiddenRow">
                                                {userRole.toLowerCase()== 'supplier'&&  <p style={{ fontWeight: "bold", padding: 4, margin: 10, fontSize: 16 }}>Dispatch lot {index + 1}</p> }
                    {userRole.toLowerCase()== 'distributor'&&  <p style={{ fontWeight: "bold", padding: 4, margin: 10, fontSize: 16 }}>Receive lot {index + 1}</p> }

                                                   

                                                    {/*  <p style={{fontWeight:"bold",padding:4,margin:10,fontSize:16}}>Transit Details</p> */}
                                                </td>
                                            </tr>
                                            }

                                            {obj.items &&
                                                <tr>
                                                    <td colspan="12" className="hiddenRow">
                                                        {
                                                            (obj.items && obj.items.length == 0) && <>
                                                                <Table responsive>
                                                                    <tr>
                                                                        <td colspan="12" className="hiddenRow">
                                                                            <p style={{
                                                                                textAlign: "center",
                                                                                fontWeight: "bold",
                                                                                margin: 10,
                                                                                color: 'black'
                                                                            }}>No Data Found</p>

                                                                        </td>
                                                                    </tr>
                                                                </Table>
                                                            </>
                                                        }
                                                        {
                                                            (obj.items && obj.items.length != 0) && <>

                                                                <Table responsive style={{ display: shownDrawerIndex == index ? '' : 'none' }}>
                                                                    <thead>
                                                                        {/*   <th>Year</th>
                                                                        <th>Season</th>
                                                                        <th>Purchase ID </th> */}
                                                                        <th>Component</th>
                                                                        <th>Type</th>
                                                                        {/*<th>Supplier ID</th>*/}<th>Transit Quantity </th>
                                                                        <th>Received Quantity </th>
                                                                      <th>Received By </th> 
                                                                        <th>Received Date </th>
                                                                          </thead>
                                                                    <tbody>
                                                                        {obj.items.map((item, indexkey) => {
                                                                            return (
                                                                                <tr key={index} >
                                                                                    {/*    <td>{item.year}</td>
                                                                                    <td>{item.season}</td>
                                                                                    <td>{item.parchageID}</td> */}

                                                                                    <td>{item.SeedName}</td>
                                                                                    <td>{item.VarietyName}</td>
                                                                                    {/*<td>{obj.supplierID}</td>*/}<td>{formatter.format(item.transitQuantity)}</td>
                                                                                    {/*  <td>{item.recevedQuantity}</td> */}
                                                                                    {type == "receive" && <td><div className="input_div_table" >
                                                                                        <input type={'number'}
                                                                                            style={{ margin: 0,height:32, background: 'transparent', fontSize: 18 }}
                                                                                            name="recevedQuantity" className="inputdiv"
                                                                                            value={item.recevedQuantity}
                                                                                             disabled={item.receivedBy?"complete":''}
                                                                                            onChange={(evnt) => handleInput(index, evnt, obj, indexkey)}
                                                                                            placeholder="" />
                                                                                        {item.issueqtyerror == false && item.issuecolor == "y" && <p style={{
                                                                                            color: '#DF8D0F', textAlign: 'center', fontWeight: '700'
                                                                                        }}>{item.issueqtyErr}</p>}
                                                                                    </div></td>}
                                                                                    {type == "dispatch" && <td>{formatter.format(item.recevedQuantity)} </td>}

                                                                                 <td>{item.ReceiverName}</td> 
                                                                                    <td>{item.receivedDate}</td></tr>
                          )
                          })}

                                                                        {obj.items && obj.items.length != 0 && shownDrawerIndex == index && type == "receive"&& !obj.items[0].receivedBy && 
                                                                        <tr>
                                                                            <td className="hiddenRow">
                                                                                <>
                                                                          {/*   <div className="inputdiv_file" style={{marginTop: 12}}>
                            <label style={{marginLeft: 0}}>Receive File</label>
    {receive_file != '' &&
        <label className="custom-file-uploaded" >
            <div>
                <p>{receive_file} </p>
            </div>
        </label>}
        {receive_file == '' &&
        <>
                     <input id="file-upload" style={{marginTop: 0,marginLeft: 0}}
                     onChange={() => handleFileupload()} name="receive_file" type="file" />
                            {receive_fileErr != '' && <p className="alert-message">{receive_fileErr}</p>}
              </>
                }
                        </div> */}
                                                                                <button className="add_button" disabled={receiveDisabled}onClick={() => handleReceiveSeed(obj.items)}>
                                                                                    <p className="pcat_title">RECEIVE</p></button>
                                                                                {itemErr!='' && <p className="alert-message">{itemErr}</p>}
                                                                                </>
                                                                            </td>
                                                                        </tr>
                                                                        }
                                                                    </tbody>

                                                                </Table>
                                                               {/*  <Row style={{ display: shownDrawerIndex == index ? '' : 'none' }}>
                                                                    <Col lg={4}>
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                    </Col>
                                                                    <Col lg={2} style={{ marginBottom: 12 }}>

                                                                    </Col>
                                                                </Row> */}
                                                            </>
                                                        }
                                                    </td>
                                                </tr>
                                            }
                                           


                                        </>
                                    );
                                })}
                            </tbody>
                            }
                        </Table>
                    </Col>
                </Row>
            </Container>
           </>
           }
            <Modal
        show={commentsModal}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Comments</Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <div>
                            <label >Comments</label>
                            <br />
                            <div className="input_div" >
                                 <textarea
                                        value={testing_coments}
                                        onChange={handleChange}
                                        style={{ width: "85%",height: 100 }}
                                        name="testing_coments" placeholder="Type Something"
                                        rows={5}
                                        cols={5}
                                    />
                            </div>
                             {commentsErr != '' && <p className="alert-message">{commentsErr}</p>}
                        </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
                        <div className="row_div">
                            <button className="add_button" onClick={() => handleaddTesting()} style={{
                                padding: 12,
                                height: 45,
                                margin: 10
                            }}>
                                <p className="pcat_title">Submit Testing</p></button>
                        </div>
                    </Modal.Footer>
      </Modal>
            <ToastContainer />
        </Wrapper>
    )
}
export default DispatchIndividual