import React, { useState, useEffect, useContext, useRef } from "react";
import { UserContext } from "../../context";
import { Wrapper, Label, Input, Switch } from "./styles";
import { Title } from "../../GlobalStyle.js";
import API_Auth from "../../API_Auth";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingSpinner } from "../LoadingSpinner/index";
import { Container, Row, Col, Table } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";
import * as XLSX from "xlsx";
import Modal from "react-bootstrap/Modal";

const AddFarmer = () => {
  let { userID } = useParams();
  const fileUploader = useRef(null);

  const navigate = useNavigate();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const { isExpanded } = useContext(UserContext);

  const [formTitle, setFormTitle] = useState("Add New Farmer");

  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState("");
  const [NameErr, setNameErr] = useState("");

  const [fatherName, setFatherName] = useState("");
  const [fatherNameErr, setFatherNameErr] = useState("");

  const [contactNumber, setcontactNumber] = useState("");
  const [contactNumberErr, setcontactNumberErr] = useState("");

  const [adharNo, setAdharNo] = useState("");
  const [adharNoErr, setAdharNoErr] = useState("");

  const [distributor, setdistributor] = useState("");
  const [distributorErr, setdistributorErr] = useState("");

  const [district, setdistrict] = useState("");
  const [districtErr, setdistrictErr] = useState("");

  const [caste, setcaste] = useState("");
  const [casteErr, setcasteErr] = useState("");

  const [area, setArea] = useState("");
  const [areaErr, setaAreaErr] = useState("");

  const [formKey, setFormKey] = useState(true);
  
  const [uploadarray_length, setuploadarrayLength] = useState('');
  const [buttonShow, setButtonShow] = useState(false);

  const [districts, setDistricts] = useState([]);
  const [distributors, setDistributorData] = useState([]);
  const [castList, setCastList] = useState([]);
  const [err, setErr] = useState("");
  
  const [success_array, setSucsess_array] = useState(0);
  const [error_array, setError_array] = useState(0);
  const [jsonInvalid_Array, setjsonInvalid_Array] = useState(0);

  const [panchayath, setPanchayath] = useState("");
  const [panchayathErr, setPanchayathErr] = useState("");

  const [village, setVillage] = useState("");
  const [villagErr, setVillagErr] = useState("");

  const [block, setblock] = useState("");
  const [blockErr, setblockErr] = useState("");

  const [formButtonValue, setFormButtonValue] = useState("ADD FARMER");
  const [jsonIncorrectArrray, setjsonIncorrectArrray] = useState([]);
  const [show, setShow] = useState(false);
  const [file, setFile] = useState("");
  const [event, setEvent] = useState("");
  const [radioValue, setRadioValue] = useState("");
  const [radioErr, setRadioErr] = useState("");
  const [typeChecked, setChecked] = useState(false);
  const [role, setRole] = useState("");
  const [json_det, setJson_det] = useState([]);
  var success_count = 0 ;
  var error_count = 0 ;
  var jsonInvalid_count = 0 ;
  var json_incorrectaray = [];

  const handleSubmit = () => {
    setIsLoading(true);
    const reader = new FileReader();
    let e = event;
      reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);
      console.log(json.length);
      var error_Array = [];
      var sucsess_Array = [];
      for (var i = 0; i < json.length; i++) {
        json[i].status = 'unprocessed';
      }
      setJson_det(json);

/*       for (var i = 0; i < json_det.length; i++) { */
        if (typeof json[0].Aadhar == "number") {
          var adharstring = json[0]?.Aadhar?.toString();
        }
        if (typeof json[0].District == "string") {
          const data=districts?.filter((item)=>item.v_districtName?.toLowerCase()==json[0].District?.toLowerCase());
          json[0].DistrictID = data[0]?.id;
          setIsLoading(false);
        }
        var mobilestring = json[0]?.Mobile?.toString();
        console.log(json[0]?.Farmer);
        if (
          json[0]?.Farmer == "" ||
          json[0]?.Farmer == undefined ||
          json[0]?.Father == "" ||
          json[0]?.Father == undefined ||
          adharstring == undefined ||
          mobilestring == undefined ||
          adharstring?.length !== 12 ||
          mobilestring?.length !== 10 ||
          json[0]?.DistrictID == "" ||
          json[0]?.DistrictID == undefined ||
          json[0]?.Gender == "" ||
          json[0]?.Gender == undefined ||
          json[0]?.Caste == "" ||
          json[0]?.Caste == undefined ||
          json[0]?.eKYC == "" ||
          json[0]?.eKYC == undefined 
        ) {
          json_incorrectaray.push(json[0]);
          jsonInvalid_count = jsonInvalid_count +1;
          setjsonInvalid_Array(jsonInvalid_count);
          setErr("Data Invalid");
          setIsLoading(false);
          const data=json.filter((item,index)=> index!= 0);
          console.log(data);
            setJson_det(data);
            handlehittheAPI(data);
         // setjsonIncorrectArrray(json_incorrectaray);
        } 
         else {
          if(json[0].Gender.toLowerCase() != "female" ||
          json[0].Gender.toLowerCase() != "male" ||
          json[0].Gender.toLowerCase() != "na" ||
          json[0].Caste.toLowerCase() != "sc" ||
          json[0].Caste.toLowerCase() != "st" ||
          json[0].Caste.toLowerCase() != "obc" ||
          json[0].Caste.toLowerCase() != "general" ||
          json[0].Caste.toLowerCase() != "other" ||
          json[0].Caste.toLowerCase() != "na" ||
          json[0].eKYC.toLowerCase() != "yes" ||
          json[0].eKYC.toLowerCase() != "no"||
          json[0].eKYC.toLowerCase() != "na" ){
          console.log('apihit');
            setIsLoading(false);
            const userId = localStorage.getItem("UserID");
            const fetchData = async () => {
             setIsLoading(true);
            console.log("json[0]", json[0]);
            let result = await API_Auth.addFarmerDetail(
              "REGISTER",
              json[0].Farmer,
              json[0].Father,
              json[0].Mobile,
              json[0].Aadhar,
              json[0].Panchayat,
              json[0].Village,
              json[0].Area,
              json[0].DistrictID,
              json[0].Block,
              userId,
              json[0].Caste,
              json[0].Gender,
              json[0].eKYC
            );
            let res = result.errordata;
            if (res[0].message == "success") {
              setErr("");
              json[0].status = 'processed';
              const data=json.filter((item,index)=>index!=0);
              console.log(data);
              success_count = success_count +1;
              setSucsess_array(success_count);
              console.log('success_array1',success_array );
              handlehittheAPI(data);
              setJson_det(data);
              setTimeout(() => {
                setIsLoading(false);
              }, 4000);
            } else {
              error_count = error_count +1;
              setError_array(error_count);
              console.log('error_count1',error_count );
              json[0].status = 'processed';
              setIsLoading(false);
              const data=json.filter((item,index)=>index!=0);
              console.log(data);
                handlehittheAPI(data);
                setJson_det(data);
            }
          };
          // call the function
          fetchData()
            // make sure to catch any error
            .catch(console.error);
    }
  }
      /* } */
      setSucsess_array(success_count);
      setError_array(error_count);
      setjsonInvalid_Array(jsonInvalid_count);  
      console.log(json_incorrectaray);
     // setjsonIncorrectArrray(json_incorrectaray);
      console.log(
        "json",
        json_incorrectaray,
        json_incorrectaray.length,
        json.length
      );
      console.log("eror", error_Array);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };
  const handleSubmitttoServer = async(json_det) => {
    console.log(success_array);
    console.log(error_array);
    console.log(jsonInvalid_Array)

    if(json_det.length>0){
    var error_Array = [];
    var sucsess_Array = [];
    if (typeof json_det[0].Aadhar == "number") {
      var adharstring = json_det[0]?.Aadhar?.toString();
    }
    if (typeof json_det[0].District == "string") {
      const data=districts.filter((item)=>item.v_districtName?.toLowerCase()==json_det[0].District?.toLowerCase());
      json_det[0].DistrictID = data[0]?.id;
      setIsLoading(false);
    }
    var mobilestring = json_det[0]?.Mobile?.toString();
    if (
      json_det[0]?.Farmer == "" ||
      json_det[0]?.Farmer == undefined ||
      json_det[0]?.Father == "" ||
      json_det[0]?.Father == undefined ||
      adharstring == undefined ||
      mobilestring == undefined ||
      adharstring?.length !== 12 ||
      mobilestring?.length !== 10 ||
      json_det[0]?.DistrictID === "" ||
      json_det[0]?.DistrictID === undefined ||
      json_det[0]?.Gender === "" ||
      json_det[0]?.Gender === undefined ||
      json_det[0]?.Caste === "" ||
      json_det[0]?.Caste === undefined ||
      json_det[0]?.eKYC === "" ||
      json_det[0]?.eKYC === undefined   
    ) {
      json_incorrectaray.push(json_det[0]);
      jsonInvalid_count = jsonInvalid_count +1;
      setjsonInvalid_Array(jsonInvalid_count);
      setErr("Data Invalid");
      setIsLoading(false);
      const data=json_det.filter((item,index)=>index!=0);
      console.log(data);
        handlehittheAPI(data);
        setJson_det(data);
      //setjsonIncorrectArrray(json_incorrectaray);
    } 
     else {
      if(json_det[0].Gender.toLowerCase() != "female" ||
      json_det[0].Gender.toLowerCase() != "male" ||
      json_det[0].Gender.toLowerCase() != "na" ||
      json_det[0].Caste.toLowerCase() != "sc" ||
      json_det[0].Caste.toLowerCase() != "st" ||
      json_det[0].Caste.toLowerCase() != "obc" ||
      json_det[0].Caste.toLowerCase() != "general" ||
      json_det[0].Caste.toLowerCase() != "other" ||
      json_det[0].Caste.toLowerCase() != "na" ||
      json_det[0].eKYC.toLowerCase() != "yes" ||
      json_det[0].eKYC.toLowerCase() != "no"||
      json_det[0].eKYC.toLowerCase() != "na" ){
        setIsLoading(false);
        const userId = localStorage.getItem("UserID");

        const fetchData = async () => {
         setIsLoading(true);
        console.log("json_det[0]", json_det[0]);
        let result = await API_Auth.addFarmerDetail(
          "REGISTER",
          json_det[0].Farmer,
          json_det[0].Father,
          json_det[0].Mobile,
          json_det[0].Aadhar,
          json_det[0].Panchayat,
          json_det[0].Village,
          json_det[0].Area,
          json_det[0].DistrictID,
          json_det[0].Block,
          userId,
          json_det[0].Caste,
          json_det[0].Gender,
          json_det[0].eKYC
        );
        let res = result.errordata;
        if (res[0].message == "success") {
          setErr("");

          json_det[0].status = 'processed';
          const data=json_det.filter((item,index)=>index!=0);
          console.log(data);
            setJson_det(data);
            handlehittheAPI(data);
          success_count = success_count +1;
          setSucsess_array(success_count);
          console.log('success_array2',success_array );
/*           setTimeout(() => {
          }, 4000); */
        } else {
          error_count = error_count +1;
          setError_array(error_count);
          console.log('error_count2',error_count );
          json_det[0].status = 'processed';
          const data=json_det.filter((item,index)=>index!=0);
          console.log(data);
          setJson_det(data);
          handlehittheAPI(data);
        }
      };
      // call the function
      fetchData()
        // make sure to catch any error
        .catch(console.error);
}}
    }
    else{
      setSucsess_array(success_count);
      setError_array(error_count);    
      setjsonInvalid_Array(jsonInvalid_count);
      setIsLoading(false);
      console.log(json_incorrectaray);
     setjsonIncorrectArrray(json_incorrectaray);
      }
  }
  const handlehittheAPI = async(json_det) => {
    console.log(success_array);
    console.log(error_array);
    console.log(jsonInvalid_Array)

    console.log(json_det);
    if(json_det.length>0){
    var error_Array = [];
    var sucsess_Array = [];
    if (typeof json_det[0].Aadhar == "number") {
      var adharstring = json_det[0]?.Aadhar?.toString();
    }
    if (typeof json_det[0].District == "string") {
      const data=districts?.filter((item)=>item.v_districtName?.toLowerCase()==json_det[0].District?.toLowerCase());
      json_det[0].DistrictID = data[0]?.id;
      setIsLoading(false);
    }
    var mobilestring = json_det[0]?.Mobile?.toString();
    if (
      json_det[0]?.Farmer == "" ||
      json_det[0]?.Farmer == undefined ||
      json_det[0]?.Father == "" ||
      json_det[0]?.Father == undefined ||
      adharstring == undefined ||
      mobilestring == undefined ||
  adharstring?.length !== 12 ||
      mobilestring?.length !== 10 ||
      json_det[0]?.DistrictID === "" ||
      json_det[0]?.DistrictID === undefined ||
      json_det[0]?.Gender === "" ||
      json_det[0]?.Gender === undefined ||
      json_det[0]?.Caste === "" ||
      json_det[0]?.Caste === undefined ||
      json_det[0]?.eKYC === "" ||
      json_det[0]?.eKYC === undefined
    ) {
      json_incorrectaray.push(json_det[0]);
      jsonInvalid_count = jsonInvalid_count +1;
      setjsonInvalid_Array(jsonInvalid_count);
      setErr("Data Invalid");
      setIsLoading(false);
      const data=json_det.filter((item,index)=>index!=0);
        handleSubmitttoServer(data);
        setJson_det(data);
        console.log(json_incorrectaray);
       // setjsonIncorrectArrray(json_incorrectaray); 
    } 
     else {
      if(json_det[0].Gender.toLowerCase() != "female" ||
      json_det[0].Gender.toLowerCase() != "male" ||
      json_det[0].Gender.toLowerCase() != "na" ||
      json_det[0].Caste.toLowerCase() != "sc" ||
      json_det[0].Caste.toLowerCase() != "st" ||
      json_det[0].Caste.toLowerCase() != "obc" ||
      json_det[0].Caste.toLowerCase() != "general" ||
      json_det[0].Caste.toLowerCase() != "other" ||
      json_det[0].Caste.toLowerCase() != "na" ||
      json_det[0].eKYC.toLowerCase() != "yes" ||
      json_det[0].eKYC.toLowerCase() != "no"||
      json_det[0].eKYC.toLowerCase() != "na" ){
        setIsLoading(false);
        const userId = localStorage.getItem("UserID");

        const fetchData = async () => {
         setIsLoading(true);
        console.log("json_det[0]", json_det[0]);
        let result = await API_Auth.addFarmerDetail(
          "REGISTER",
          json_det[0].Farmer,
          json_det[0].Father,
          json_det[0].Mobile,
          json_det[0].Aadhar,
          json_det[0].Panchayat,
          json_det[0].Village,
          json_det[0].Area,
          json_det[0].DistrictID,
          json_det[0].Block,
          userId,
          json_det[0].Caste,
          json_det[0].Gender,
          json_det[0].eKYC
        );
        let res = result.errordata;
        if (res[0].message == "success") {
          setErr("");
          json_det[0].status = 'processed';
          const data=json_det.filter((item,index)=>index!=0);
          console.log(data);
            setJson_det(data);
            success_count = success_count +1;
            setSucsess_array(success_count);
            console.log('success_array3',success_array );
            handleSubmitttoServer(data);
        } else {
          error_count = error_count +1;
          setError_array(error_count);
          console.log('error_count3',error_count );
          json_det[0].status = 'processed';
          const data=json_det.filter((item,index)=>index!=0);
            setJson_det(data);
            handleSubmitttoServer(data);
        }
      };
      // call the function
      fetchData()
        // make sure to catch any error
        .catch(console.error);
}
     }
    }
    else{
      setSucsess_array(success_count);
      setError_array(error_count);
      console.log(jsonInvalid_count);
      setjsonInvalid_Array(jsonInvalid_count); 
      setIsLoading(false);
      console.log(json_incorrectaray);
      setjsonIncorrectArrray(json_incorrectaray);
    }
  };
  const handleBackClick = () => {
    setFormKey(true);
    setButtonShow(false);
  };

  const filePathset = async (e) => {
    e.preventDefault();

    e.preventDefault();
    if (e.currentTarget.files) {
      setEvent(e);
      console.log("-------");
      setFile(e.currentTarget.files);
      /*             const reader = new FileReader();
                        reader.onload = (e) => {
                            const data = e.target.result;
                            const workbook = XLSX.read(data, { type: "array" });
                            const sheetName = workbook.SheetNames[0];
                            const worksheet = workbook.Sheets[sheetName];
                            const json_det = XLSX.utils.sheet_to_json(worksheet);
                            console.log(json_det);
                            var json_incorrectaray = [];
                            var error_Array=[]
            
                            for (var i = 0; i < json_det.length; i++) {
                                if (typeof (json_det[i].Aadhar) == "number") {
                                    var adharstring = json_det[i].Aadhar.toString()
            
                                }
                                var mobilestring = json_det[i].Mobile.toString()
            
                                if (json_det[i].farmer == "" || json_det[i].Father == "" || adharstring.length != 12 || mobilestring.length != 10) {
            
                                    json_incorrectaray.push(json_det[i]);
            
                                    setjsonIncorrectArrray(json_incorrectaray)
                                } else {
            
                                    const userId = localStorage.getItem("UserID");
            
                                    const fetchData = async () => {
                                        setIsLoading(true);
                                        let result = await API_Auth.addFarmerDetail(json_det[i].farmer, json_det[i].fatherName, json_det[i].contactNumber, json_det[i].Aadhar, json_det[i].Panchayat,
                                            json_det[i].Village, json_det[i].Area, json_det[i].District, json_det[i].Block, userId);
                                            let res = result.errordata;
                                            setIsLoading(false);
            
                                            if (res[0].message == "success") {
                                                    console.log("success");
                                            }else{
            
                                                error_Array.push("failure");
                                                console.log(error_Array.length);
                                                setErr(error_Array.length + " " + "Duplicate Entries ")
                                            }
            
                                    };
                                    // call the function
                                    fetchData()
            
                                        // make sure to catch any error
                                        .catch(console.error);
            
                                }
             
            
                            }
            
                            console.log("json_det", json_incorrectaray, json_incorrectaray.length, json_det.length);
                            console.log("eror",error_Array);
            
                        };
                        reader.readAsArrayBuffer(e.target.files[0]);
                    }*/
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      let result = await API_Auth.getDistributors("distributor");
      console.log("result", result);
      setDistributorData(result);
      const districts = await API_Auth.getDistricts();
      setDistricts(districts);
      let castList = await API_Auth.addFarmerDetail("GETCAST");
      console.log(castList.errordata);
      setCastList(castList.errordata);
      let role = localStorage.getItem("loggedRole");
      console.log("role", role);
      setRole(role);
      if(role.toLowerCase() == 'distributor'){
        var districtid = localStorage.getItem("DistrictID");
      const data=districts?.filter((item)=>item.id==districtid);
      console.log(data,districtid);
      setdistrict(data[0].id);
      }
    };
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  const handleAddUser = async () => {
    setAdharNoErr("  ");
    let error = false;
    const digitsRegExp = /(?=.*?[0-9])/;
    const minLengthRegForAdhar = /^.{12,12}$/;
    const minLengthRegForMobileNo = /^.{10,10}$/;
    const digitsAdhar = digitsRegExp.test(adharNo);
    const minLengthAdhar = minLengthRegForAdhar.test(adharNo);
    const minLengthMobile = minLengthRegForMobileNo.test(contactNumber);
    const digitsMobile = digitsRegExp.test(contactNumber);
    if (name == "") {
      setNameErr("This field is required");
      error = true;
    } else {
      setNameErr("  ");
    }
    if (fatherName == "") {
      setFatherNameErr("This field is required");
      error = true;
    } 
    else if (fatherName != "") {
      let pattern = /^[A-Za-z]+$/;
      if (!pattern.test(fatherName)) {
        setFatherNameErr('Please enter text only.');
          error = true;
      } else {
        setFatherNameErr('');
      }
    } 
    else {
      setFatherNameErr("  ");
    }
    if (contactNumber == "") {
      setcontactNumberErr("This field is required");
      error = true;
    }
  else if (!digitsMobile) {
      setcontactNumberErr("Enter nos only");
      error = true;
      console.log('true');
    }
    else if (!minLengthMobile) {
      setcontactNumberErr("Enter valid mobile number");
      error = true;
      console.log('true');
    } else {
      setcontactNumberErr("");
    }
    if (adharNo == "") {
      setAdharNoErr("This field is required");
      error = true;
    }
    else if (!digitsAdhar) {
      setAdharNoErr("Enter nos only");
      error = true;
    }
    else if (!minLengthAdhar) {
      setAdharNoErr("Enter valid adhar no");
      error = true;
    } else {
      setAdharNoErr("  ");
    }
    if (area == "") {
      setaAreaErr("This field is required");
      error = true;
    } else {
      setaAreaErr("  ");
    }

    if (panchayath == "") {
      setPanchayathErr("This field is required");
      error = true;
    } else {
      setPanchayathErr("  ");
    }
    if (village == "") {
      setVillagErr("This field is required");
      error = true;
    } 
    else if (village != "") {
      let pattern = /^[A-Za-z]+$/;
      if (!pattern.test(village)) {
        setVillagErr('Please enter text only.');
          error = true;
      } else {
        setVillagErr('');
      }
    } 
    else {
      setVillagErr("  ");
    }
    if (block == "") {
      setblockErr("This field is required");
      error = true;
    } else {
      setblockErr("  ");
    }
   /*  if (distributor == "") {
      setdistributorErr("This field is required");
      error = true;
    } else {
      setdistributorErr("  ");
    } */
    if (district == "") {
      setdistrictErr("This field is required");
      error = true;
    } else {
      setdistrictErr("  ");
    }
    if (caste == "") {
      setcasteErr("This field is required");
      error = true;
    } else {
      setcasteErr("  ");
    }
   console.log(error);
    if (!error) {
      let ekyc = "No";
      if (typeChecked) {
        ekyc = "Yes";
      }
      console.log(ekyc);
      setIsLoading(true);
      setDisableSubmit(true);
       const userId = localStorage.getItem("UserID");
      let result = await API_Auth.addFarmerDetail(
        "REGISTER",
        name,
        fatherName,
        contactNumber,
        adharNo,
        panchayath,
        village,
        area,
        district,
        block,
        userId,
        caste,
        radioValue,
        ekyc
      );
      console.log(result.errordata);
      let res = result.errordata;
      if (res[0].message == "success") {
        setIsLoading(false);
        toast.success("Farmer added successfully");
        setTimeout(() => {
          navigate(0);
        }, 2000);
      } else if (res[0].message == "Farmer already exists") {
        setIsLoading(false);

        setDisableSubmit(false);
        toast.error("Farmer already exists");
      } else {
        setIsLoading(false);

        setDisableSubmit(false);
        toast.error("Something went wrong please try again");
      } 
    }
  };
  const handleDisplay = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  const handleChange = (e) => {
    setChecked(e.target.checked);
    console.log(e.target.checked);
  };
  const handleInput = async (e) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;
    if (name === "name") setName(value);
    if (name === "fatherName") setFatherName(value);
    if (name === "contactNumber") setcontactNumber(value);
    if (name === "adharNo") setAdharNo(value);
    if (name === "area") setArea(value);
    if (name === "panchayath") setPanchayath(value);
    if (name === "village") setVillage(value);
    if (name === "block") setblock(value);
    if (name === "district") setdistrict(value);
    if (name === "distributor") setdistributor(value);
    if (name === "caste") setcaste(value);
    if (name === "radio") {
      setRadioValue(value);
      console.log(value);
    }
  };
  const handleBulkUpload = () => {
    setButtonShow(true);
    setFormKey(false);
  };
  return (
    <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
      <Container>
        <Row className="row_title">
          <Col lg={4}>
            <p>{formTitle}</p>
          </Col>
          <Col lg={6}></Col>
          <Col lg={2}>
            <div className="back_button" onClick={() => handleBackClick()}>
              <IoIosArrowBack size={18} />
              <h6>Back</h6>
            </div>
          </Col>
        </Row>
      </Container>
      {isLoading && <LoadingSpinner />}

      <hr />

      <Container>
        <Row>
          <Col lg={9}></Col>
          {role.toLowerCase() == "ao" && (
            <Col lg={3}>
              <div className="row_div">
                <button
                  className="add_button"
                  /* disabled={disableSubmit} */ onClick={() =>
                    handleBulkUpload()
                  }
                  style={{
                    margin: 12,
                    width: "92%",
                  }}
                >
                  <p className="pcat_title">Bulk Upload</p>
                </button>
              </div>
            </Col>
          )}
        </Row>
      </Container>

      {buttonShow == true && (
        <Container>
          <Row>
            <input
              type="file"
              id="file"
              onChange={filePathset}
              style={{ width: "90%" }}
            />
            {jsonInvalid_Array>0 &&
              <>
                <p className="alert-message">
                   {jsonInvalid_Array +" Invalid Data Entries"}{""}
                  </p>
                  <div className="row_div">
                  <button
                  className="add_button"
                  onClick={() => handleDisplay()}
                  style={{
                    margin: 12,
                    width: "14%",
                    background:'#d7978e'
                  }}
                >
                  <p className="pcat_title">Show Errors</p>
                </button>
                </div>
                {/*<p className="alert-message">{jsonIncorrectArrray.length}</p>*/}{" "}
              </>
            }
             {error_array>0 && 
              <>
                <p className="alert-message">
                  {error_array+"  Duplicate Entries Updated"}
                  </p>
                  <div className="row_div">
                </div>
              </>
            }
            {success_array>0&&
                  <p className="sucess-message">
                  {success_array +" Sucessful Data Entries"}{""}
            </p>
              }
          </Row>

          <Row>
            <Col lg={2}>
              <div className="row_div">
                <button
                  className="add_button"
                  /* disabled={disableSubmit} */ onClick={() => handleSubmit()}
                  style={{
                    margin: 12,
                    width: "92%",
                  }}
                >
                  <p className="pcat_title">Upload</p>
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      )}
      {formKey == true && (
        <Container>
          <Row>
            <Col lg={6} md={6} sm={6}>
              <div className="inputdiv">
                <label>Name</label>
                <br />
                <input
                  type="text"
                  name="name"
                  value={name}
                  placeholder="Enter Farmer Name"
                  onChange={handleInput}
                  style={{ width: "75%" }}
                />
                {NameErr != "" && <p className="alert-message">{NameErr}</p>}
              </div>
            </Col>
            <Col lg={6} md={6} sm={6}>
              <div className="inputdiv">
                <label>Father Name</label>
                <br />
                <input
                  type="text"
                  name="fatherName"
                  value={fatherName}
                  placeholder="Enter Father Name"
                  onChange={handleInput}
                  style={{ width: "75%" }}
                />
                {fatherNameErr != "" && (
                  <p className="alert-message">{fatherNameErr}</p>
                )}
              </div>
            </Col>
            <Col lg={6} md={6} sm={6}>
              <div className="inputdiv">
                <label>Mobile Number</label>
                <br />
                <input
                  type="number"
                  name="contactNumber"
                  value={contactNumber}
                  placeholder="Enter Mobile Number"
                  onChange={handleInput}
                  style={{ width: "75%" }}
                />
                {contactNumberErr != "" && (
                  <p className="alert-message">{contactNumberErr}</p>
                )}
              </div>
            </Col>
            <Col lg={6} md={6} sm={6}>
              <div className="inputdiv">
                <label>Aadhaar No</label>
                <br />
                <input
                  type="text"
                  name="adharNo"
                  value={adharNo}
                  placeholder="Enter Aadhaar No"
                  onChange={handleInput}
                  style={{ width: "75%" }}
                />
                {adharNoErr != "" && (
                  <p className="alert-message">{adharNoErr}</p>
                )}
              </div>
            </Col>
          {/*   <Col lg={6} md={6} sm={6}>
              <div className="inputdiv">
                <label>Distributor</label>
                <br />
                <select
                  onChange={handleInput}
                  value={distributor}
                  name="distributor"
                  style={{ width: "75%" }}
                >
                  <option value="">Select Distributor</option>
                  {distributors.length != 0 && (
                    <>
                      {distributors.map((item, index) => {
                        return <option value={item.id}>{item.UserName}</option>;
                      })}
                    </>
                  )}
                </select>
                {distributorErr != "" && (
                  <p className="alert-message">{distributorErr}</p>
                )}
              </div>
            </Col> */}
            <Col lg={6} md={6} sm={6}>
              {/* <div className="inputdiv">
                            <label>District</label>
                            <br />
                            <input type="text"
                                name="district"
                                value={district}
                                placeholder="Enter districtName"
                                onChange={handleInput}
                                style={{ width: "75%" }} />
                            {districtErr != '' && <p className="alert-message">{districtErr}</p>}
                        </div> */}
              <div className="inputdiv">
                <label>District</label>
                <br />
                <select
                  onChange={handleInput}
                  value={district}
                 // disabled={role.toLowerCase() == 'distributor'}
                  name="district"
                  style={{ width: "75%" }}
                >
                  <option value="">Select District</option>
                  {districts?.length > 0 && (
                    <>
                      {districts?.map((item, index) => {
                        return (
                          <option value={item.id}>{item.v_districtName}</option>
                        );
                      })}
                    </>
                  )}
                </select>
                {districtErr != "" && (
                  <p className="alert-message">{districtErr}</p>
                )}
              </div>
            </Col>
            <Col lg={6} md={6} sm={6}>
              <div className="inputdiv">
                <label>Area</label>
                <br />
                <input
                  type="text"
                  name="area"
                  value={area}
                  placeholder="Enter Area"
                  onChange={handleInput}
                  style={{ width: "75%" }}
                />
                {areaErr != "" && <p className="alert-message">{areaErr}</p>}
              </div>
            </Col>
            <Col lg={6} md={6} sm={6}>
              <div className="inputdiv">
                <label>Block</label>
                <br />
                <input
                  type="text"
                  name="block"
                  value={block}
                  placeholder="Enter block"
                  onChange={handleInput}
                  style={{ width: "75%" }}
                />
                {blockErr != "" && <p className="alert-message">{blockErr}</p>}
              </div>
            </Col>
            <Col lg={6} md={6} sm={6}>
              <div className="inputdiv">
                <label>Village</label>
                <br />
                <input
                  type="text"
                  name="village"
                  value={village}
                  placeholder="Enter Village Name"
                  onChange={handleInput}
                  style={{ width: "75%" }}
                />
                {villagErr != "" && (
                  <p className="alert-message">{villagErr}</p>
                )}
              </div>
            </Col>
            <Col lg={6} md={6} sm={6}>
              <div className="inputdiv">
                <label>Panchayat</label>
                <br />
                <input
                  type="text"
                  name="panchayath"
                  value={panchayath}
                  placeholder="Enter Panchayat"
                  onChange={handleInput}
                  style={{ width: "75%" }}
                />
                {panchayathErr != "" && (
                  <p className="alert-message">{panchayathErr}</p>
                )}
              </div>
            </Col>
            <Col lg={6} md={6} sm={6}>
              <div className="inputdiv">
                <label>Caste</label>
                <br />
                <select
                  onChange={handleInput}
                  value={caste}
                  name="caste"
                  style={{ width: "75%" }}
                >
                  <option value="">Select Caste</option>
                  {castList?.length != 0 && (
                    <>
                      {castList?.map((item, index) => {
                        return (
                          <option value={item.castCategoryName}>
                            {item.castCategoryName}
                          </option>
                        );
                      })}
                    </>
                  )}
                </select>
                {casteErr != "" && <p className="alert-message">{casteErr}</p>}
              </div>
            </Col>
            <Col lg={6}>
              <div className="inputdiv">
                <label>EKYC</label>
                <br />
                <Label style={{ marginTop: 24,width:'18%' }}>
                  <span>{typeChecked ? "Yes" : "No"}</span>
                  <Input
                    checked={typeChecked}
                    type="checkbox"
                    style={{width:'5%',cursor:'pointer'}}

                    onChange={(e) => handleChange(e)}
                  />
                  <Switch />
                </Label>
              </div>
            </Col>
            <Col lg={6}>
              <div className="inputdiv">
                <label>Gender</label>
                <br />
                <div style={{ flexDirection: "row", marginTop: 14 }}>
                  <input
                    type="radio"
                    id="male"
                    name="radio"
                    value="male"
                    style={{ width: 25, height: 15, margin: 20 }}
                    onChange={handleInput}
                  />
                  <label for="male" className="radiolabel">
                    MALE
                  </label>
                  <input
                    type="radio"
                    id="female"
                    name="radio"
                    value="female"
                    style={{ width: 25, height: 15, margin: 20 }}
                    onChange={handleInput}
                  />
                  <label for="female" className="radiolabel">
                    FEMALE
                  </label>
                </div>
                {radioErr != "" && <p className="alert-message">{radioErr}</p>}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={5}>
              <div className="row_div">
                <button
                  className="add_button"
                  disabled={disableSubmit}
                  onClick={() => handleAddUser()}
                  style={{
                    margin: 12,
                    width: "92%",
                  }}
                >
                  <p className="pcat_title">{formButtonValue}</p>
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      )}
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        dialogclassname="modal-content"
      >
        <Modal.Header closeButton>
          <Modal.Title>Data</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row_div"></div>
          <div>
            {jsonIncorrectArrray &&
              jsonIncorrectArrray &&
              jsonIncorrectArrray.length == 0 && (
                <>
                  <Table responsive>
                    <tr>
                      <td colspan="12" className="hiddenRow">
                        <p
                          style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            margin: 10,
                            color: "black",
                          }}
                        >
                          No Data Found
                        </p>
                      </td>
                    </tr>
                  </Table>
                </>
              )}
            {jsonIncorrectArrray && jsonIncorrectArrray.length != 0 && (
              <>
                <Table responsive>
                  <thead>
                    <th> Name</th>
                    <th>Father Name</th>
                    <th>Aadhaar No</th>
                    <th>Mobile No</th>
                    <th>Gender</th>
                    <th>Caste</th>
                    <th>EKYC</th>
                  </thead>
                  <tbody>
                    {jsonIncorrectArrray?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.Farmer}</td>
                          <td>{item.Father}</td>
                          <td>{item.Aadhar}</td>
                          <td>{item.Mobile}</td>
                          <td>{item.Gender}</td>
                          <td>{item.Caste}</td>
                          <td>{item.eKYC}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </Wrapper>
  );
};
export default AddFarmer;
