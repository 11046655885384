import React, { useState, useEffect, useContext,useRef } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./styles"
import { Row, Container, Col ,OverlayTrigger,Tooltip,Button} from "react-bootstrap"
import Table from "react-bootstrap/Table";
import { useNavigate, Link } from "react-router-dom";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle"
import { LoadingSpinner } from "../LoadingSpinner/index";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from "moment"
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Season from "../Season";
import CanvasJSReact from '../canvasjs.react';
 /*import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Legend
} from "recharts"; */
import Modal from 'react-bootstrap/Modal';

 var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart; 

const DistributionDetailsList = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(50);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey, setSearchKey] = useState('n');
    const [searchKeyTable, setSearchKeyTable] = useState('');
    const[start_date,setStartDate]=useState('');
    const[end_date,setEndDate]=useState('');
    const[distributionsData,setdistributionsData]=useState('');
    const[districtId,setDistrictId]=useState('');
    const[season,setSeason]=useState('');
    const[role,setRole]=useState('');
    const[showDetailsModal,setshowDetailModel]=useState(false);
    const[hashDetails,sethashDetail]=useState(false);

    const[showRevertModal,setshowRevertModal]=useState(false);
    const[revertobj,setrevertobj]=useState('');


    const [totalDistributedQuantity, setTotalDistributedQuantity] = useState([]);
    const [graph_det, setgraph_data] = useState([{x: 'Ranchi', y: '15'},
    {x: 'Bokaro', y: '1'},
    {x: 'Bokaro', y: '9'} ,
    {x: 'Ranchi', y: '10'},
    {x: 'Ranchi', y: '75'},
    {x: 'Ranchi', y: '10'},
    {x: 'Ranchi', y: '50'},
    {x: 'Ranchi', y: '50'},
    {x: 'Pakur', y: '25'},
    {x: 'Pakur', y: '15'},
    {x: 'Pakur', y: '30'},
    {x: 'Bokaro', y: '4'},
    {x: 'W. Singhbhum', y: '30'},
    {x: 'Bokaro', y: '100'}
    ]);
    const [districtData, setDistrictData] = useState([]);
    const [seasonData, setSeasonData] = useState([]);

    let chart = useRef();
    const data = [{name: "0",stock: 100,},{name: "10",stock: 80,},
        {name: "20",stock: 70,}, {name: "30",stock: 95,},
        {name: "40",stock: 50,},{name: "50",stock: 20,}];
        const options = {
        animationEnabled: true,
        exportEnabled: true,
        theme: "light2", //"light1", "dark1", "dark2"
       /*  title:{
            text: "Simple Column Chart with Index Labels"
        }, */
        axisY: {
            includeZero: true
        },
        axisX: {
            interval:1,
            labelMaxWidth: 180,           
            labelAngle: -45, //90,
           // labelFontFamily:"verdana0"
        },
        data: [{
            type: "column", //change type to bar, line, area, pie, etc
            indexLabel: "{y}", //Shows y value on all Data Points
            indexLabelFontColor: "#5A5757",
            indexLabelPlacement: "outside",
            dataPoints: graph_det,
        }]
    };
    console.log(graph_det);

    const handleInput = async (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        if (name == "season") {
            setSeason(value);
           /*  const startdate=moment(start_date).format("MM/DD/YYYY");
            const enddate=moment(end_date).format("MM/DD/YYYY")
            let result = await API_Auth.getAllDistributionDetails(startdate,enddate,value,districtId);
            console.log(result);
            setSearchData(result);
            setdistributionsData(result);
            setIsLoading(false);
            //seting pagenation data
            var slice = result.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result.length / perPage));
            setOrgTableData(result);
            setTableData(slice); */
        }
        if (name == "districtId") {
            setDistrictId(value);
            /* const startdate=moment(start_date).format("MM/DD/YYYY");
            const enddate=moment(end_date).format("MM/DD/YYYY")
            let result = await API_Auth.getAllDistributionDetails(startdate,enddate,season,value);
            console.log(result);
            setSearchData(result);
            setdistributionsData(result);
            setIsLoading(false);
            //seting pagenation data
            var slice = result.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result.length / perPage));
            setOrgTableData(result);
            setTableData(slice); */
        }
        if (name == "start_date") {setStartDate(value)
            /* const startdate=moment(value).format("MM/DD/YYYY");
            const enddate=moment(end_date).format("MM/DD/YYYY")
            setIsLoading(true);
            let result = await API_Auth.getAllDistributionDetails(startdate,enddate,season,districtId);
            console.log(result);
            setSearchData(result);
            setdistributionsData(result);
            setIsLoading(false);
            //seting pagenation data
            var slice = result.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result.length / perPage));
            setOrgTableData(result);
            setTableData(slice); */
        };
        if (name == "end_date") {
            
            setEndDate(value);
            /* const startdate=moment(start_date).format("MM/DD/YYYY");
            const enddate=moment(value).format("MM/DD/YYYY")
            setIsLoading(true);
            let result = await API_Auth.getAllDistributionDetails(startdate,enddate,season,districtId);
            console.log(result);
            setSearchData(result);
            setdistributionsData(result);
            setIsLoading(false);
            //seting pagenation data
            var slice = result.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result.length / perPage));
            setOrgTableData(result);
            setTableData(slice); */
        }
    }
    const handleSearch = (e) => {
        let filterValue = e.target.value;
        setCurrentPage(0);
        if (filterValue == '') {
            let offsetsearch = 0;
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setTableData(slice);
            setSearchData(orgtableData);
            setOffSet(offsetsearch);
            setCurrentPage(0);
            setSearchKey('n');
        }
        else {
            let offsetsearch = 0;
            const filteredData = orgtableData.filter(item => {
                return (item ?.blockName ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                item ?.memberCount?.toString().toLowerCase().includes(filterValue.toLowerCase()))
            });

            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            setOffSet(offsetsearch);
            setSearchKeyTable(filteredData);
            setTableData(slice);
            setSearchData(filteredData);
            setOrgTableData(filteredData);
        }
    }
        useEffect(() => {
        setIsLoading(true);       
        const fetchDistributionWise = async () => {

            let districts_result = await API_Auth.getDistricts();
            setDistrictData(districts_result)

            const seasonresult = await API_Auth.getSeasons();
            setSeasonData(seasonresult)
            const role=localStorage.getItem('loggedRole')
            console.log("role",role)
            setRole(role)
            const DistrictID = localStorage.getItem("DistrictID");
            console.log("result", DistrictID);
           
           /*  const start_date = new Date();
            const end_date = new Date();
            start_date.setDate(start_date.getDate() - 6);
            const defaultValue = start_date.toLocaleDateString('en-CA');
            console.log(defaultValue);
            const default_endDate = end_date.toLocaleDateString('en-CA'); 
            setStartDate(defaultValue);
            setEndDate(default_endDate);*/
            const date = new Date();
            date.setDate(date.getDate());
            const defaultValue = date.toLocaleDateString('en-CA');
            console.log(defaultValue);
 
            setStartDate(defaultValue);
            setEndDate(defaultValue);
            const startdate=moment(defaultValue).format("MM/DD/YYYY");
            const enddate=moment(defaultValue).format("MM/DD/YYYY")
           
            let district = ''
            if(role.toLocaleLowerCase()=="mis"||role.toLocaleLowerCase()=="dao"){
                setDistrictId(DistrictID);
                district=DistrictID;
             }
             else{
                setDistrictId('');
                district='';
             }
            let result = await API_Auth.getAllDistributionDetails(startdate,enddate,'',district,'');
            console.log(result);
            setSearchData(result);
            setdistributionsData(result);
            setIsLoading(false);
            let totaldisrtributedQuantity = 0;
            let graph_data = [];
            for(let i=0; i<result.length; i++){
                 if(result[i]?.distributionQuantity){
                    totaldisrtributedQuantity = totaldisrtributedQuantity+parseInt(result[i]?.distributionQuantity);
                    var data={
                        districtName: result[i]['districtName'],
                        distributionQuantity:result[i]['distributionQuantity']
                        }
                        graph_data.push(data);
              }
            }
            let totalranchiQty = 0;
            let totalPakurQty = 0;
            let totalsinghbhumQty = 0;

            let totalChatraQty = 0;
            let totalDeogharQty = 0;
            let totalDhanbadQty = 0;

            let totalDumkaQty = 0;
            let totalGarhwaQty = 0;
            let totalGiridihQty = 0;

            let totalGoddaQty = 0;
            let totalGumlaQty = 0;
            let totalBokaroQty = 0;

            let totalHazaribagQty = 0;
            let totalJamshedpurQty = 0;
            let totalJamtaraQty = 0;
            let totalKhuntiQty = 0;

            let totalKodermaQty = 0;
            let totalLateharQty = 0;
            let totalLohardagaQty = 0;
            let totalPalamuQty = 0;

            let totalSimdegaQty = 0;
            let totalSahibganjQty = 0;
            let totalSeraikelaQty = 0;
            let totalRamgarhQty = 0;
            

            for(let i=0; i<graph_data.length; i++){
                if(graph_data[i]?.districtName=="Ranchi"){
                    totalranchiQty = totalranchiQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Pakur"){
                    totalPakurQty = totalPakurQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=='W. Singhbhum'){
                    totalsinghbhumQty = totalsinghbhumQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Bokaro"){
                    totalBokaroQty = totalBokaroQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Hazaribag"){
                    totalHazaribagQty = totalHazaribagQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Jamshedpur"){
                    totalJamshedpurQty = totalJamshedpurQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Jamtara"){
                    totalJamtaraQty = totalJamtaraQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Khunti"){
                    totalKhuntiQty = totalKhuntiQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Koderma"){
                    totalKodermaQty = totalKodermaQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Latehar"){
                    totalLateharQty = totalLateharQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Lohardaga"){
                    totalLohardagaQty = totalLohardagaQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Palamu"){
                    totalPalamuQty = totalPalamuQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Ramgarh"){
                    totalRamgarhQty = totalRamgarhQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Chatra"){
                    totalChatraQty = totalChatraQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Sahibganj"){
                    totalSahibganjQty = totalSahibganjQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Seraikela"){
                    totalSeraikelaQty = totalSeraikelaQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Simdega"){
                    totalSimdegaQty = totalSimdegaQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Deoghar"){
                    totalDeogharQty = totalDeogharQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Dhanbad"){
                    totalDhanbadQty = totalDhanbadQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Dumka"){
                    totalDumkaQty = totalDumkaQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Garhwa"){
                    totalGarhwaQty = totalGarhwaQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Giridih"){
                    totalGiridihQty = totalGiridihQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Godda"){
                    totalGoddaQty = totalGoddaQty+parseInt(result[i]?.distributionQuantity);
                }
                if(graph_data[i]?.districtName=="Gumla"){
                    totalGumlaQty = totalGumlaQty+parseInt(result[i]?.distributionQuantity);
                }
            }
            let graphtotal_data = [
                {label: 'Ra',y:totalranchiQty},
                {label: 'Bo',y:totalBokaroQty},
                {label: 'Ch',y:totalChatraQty},
                {label: 'De',y:totalDeogharQty},

                {label: 'Dh',y:totalDhanbadQty},
                {label: 'Du',y:totalDumkaQty},
                {label: 'Ga',y:totalGarhwaQty},
                {label: 'Gi',y:totalGiridihQty},

                {label: 'Go',y:totalGoddaQty},
                {label: 'Gu',y:totalGumlaQty},
                {label: 'Ha',y:totalHazaribagQty},
                {label: 'Ja',y:totalJamshedpurQty},
                {label: 'Jam',y:totalJamtaraQty},

                {label: 'Kh',y:totalKhuntiQty},
                {label: 'Ko',y:totalKodermaQty},
                {label: 'La',y:totalLateharQty},
                {label: 'Lo',y:totalLohardagaQty},
                {label: 'Pa',y:totalPakurQty},

                {label: 'Pa',y:totalPalamuQty},
                {label: 'Ra',y:totalRamgarhQty},
                {label: 'Sa',y:totalSahibganjQty},
                {label: 'Se',y:totalSeraikelaQty},
                {label: 'Si',y:totalSimdegaQty},
                {label: 'Sin',y:totalsinghbhumQty},
            ];
            console.log(graphtotal_data);
         //  setgraph_data(graph_data);
            setTotalDistributedQuantity(totaldisrtributedQuantity);
            //seting pagenation data
            var slice = result.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result.length / perPage));
            setOrgTableData(result);
            setTableData(slice);
        };
        // call the function
        fetchDistributionWise()
            // make sure to catch any error
            .catch(console.error);
    }, []);

    const exportToCSV = (csvData) => {

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        const fileExtension = '.xlsx';
        const fileName = 'DistributionDetails'

        const ws = XLSX.utils.json_to_sheet(csvData);

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        const data = new Blob([excelBuffer], { type: fileType });

        FileSaver.saveAs(data, fileName + fileExtension);
    }
    const handlerowsperPage = (e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(searchKeyTable);
        }
        else {
            const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(orgtableData);
        }
    }
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        const data = orgtableData;
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setOffSet(offsetdata);
            setSearchData(searchKeyTable);
        } else {
            const slice = data.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(data.length / perPage))
            setOffSet(offsetdata);
            setTableData(slice);
            setSearchData(data);
        }
    }
    const handleDelete = async(obj) => {
        setshowRevertModal(true);
        setrevertobj(obj);
   }
   const handleFinalSubmitt = async () => {
       setshowRevertModal(false);
       const result = await API_Auth.FetchDeleteDistribution(
           revertobj.verietyName, revertobj.SeedName, revertobj.userEmail,
            revertobj.distributionQuantity, revertobj.ID,revertobj.year,revertobj.season
          );
          console.log(result);
          if (result.errordata[0].message == "success") {
            setIsLoading(false);
    
            toast.success("Quantity is reverted successfully");
            setTimeout(() => {
              navigate(0);
            }, 2000);
          } else {
            setIsLoading(false);
            //setDisableSubmit(false);
            toast.error("Something went wrong please try again");
          } 
   }    
   
   const handlehitthheAPI = async() => {
        const startdate=moment(start_date).format("MM/DD/YYYY");
        const enddate=moment(end_date).format("MM/DD/YYYY")
        setIsLoading(true);
        let result = await API_Auth.getAllDistributionDetails(startdate,enddate,season,districtId,'');
        console.log(result);
        setSearchData(result);
        setdistributionsData(result);
        setIsLoading(false);
        let totaldisrtributedQuantity = 0;
        let graph_data = [];
			
        for(let i=0; i<result.length; i++){
             if(result[i]?.distributionQuantity){
                totaldisrtributedQuantity = totaldisrtributedQuantity+parseInt(result[i]?.distributionQuantity);
              /*   graph_data.push(result[i].districtName);
                graph_data.push(result[i].distributionQuantity); */
                var data={
                    districtName: result[i]['districtName'],
                    distributionQuantity:result[i]['distributionQuantity']
                    }
                    graph_data.push(data)
                /* graph_data[i]['x'] = result[i]['districtName'];
                graph_data[i]['y'] = result[i]['distributionQuantity']; */
          }
        }
        console.log(graph_data);
        let totalranchiQty = 0;
        let totalPakurQty = 0;
        let totalsinghbhumQty = 0;

        let totalChatraQty = 0;
        let totalDeogharQty = 0;
        let totalDhanbadQty = 0;

        let totalDumkaQty = 0;
        let totalGarhwaQty = 0;
        let totalGiridihQty = 0;

        let totalGoddaQty = 0;
        let totalGumlaQty = 0;
        let totalBokaroQty = 0;

        let totalHazaribagQty = 0;
        let totalJamshedpurQty = 0;
        let totalJamtaraQty = 0;
        let totalKhuntiQty = 0;

        let totalKodermaQty = 0;
        let totalLateharQty = 0;
        let totalLohardagaQty = 0;
        let totalPalamuQty = 0;

        let totalSimdegaQty = 0;
        let totalSahibganjQty = 0;
        let totalSeraikelaQty = 0;
        let totalRamgarhQty = 0;
        

        for(let i=0; i<graph_data.length; i++){
            if(graph_data[i]?.districtName=="Ranchi"){
                totalranchiQty = totalranchiQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Pakur"){
                totalPakurQty = totalPakurQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=='W. Singhbhum'){
                totalsinghbhumQty = totalsinghbhumQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Bokaro"){
                totalBokaroQty = totalBokaroQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Hazaribag"){
                totalHazaribagQty = totalHazaribagQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Jamshedpur"){
                totalJamshedpurQty = totalJamshedpurQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Jamtara"){
                totalJamtaraQty = totalJamtaraQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Khunti"){
                totalKhuntiQty = totalKhuntiQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Koderma"){
                totalKodermaQty = totalKodermaQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Latehar"){
                totalLateharQty = totalLateharQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Lohardaga"){
                totalLohardagaQty = totalLohardagaQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Palamu"){
                totalPalamuQty = totalPalamuQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Ramgarh"){
                totalRamgarhQty = totalRamgarhQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Chatra"){
                totalChatraQty = totalChatraQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Sahibganj"){
                totalSahibganjQty = totalSahibganjQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Seraikela"){
                totalSeraikelaQty = totalSeraikelaQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Simdega"){
                totalSimdegaQty = totalSimdegaQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Deoghar"){
                totalDeogharQty = totalDeogharQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Dhanbad"){
                totalDhanbadQty = totalDhanbadQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Dumka"){
                totalDumkaQty = totalDumkaQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Garhwa"){
                totalGarhwaQty = totalGarhwaQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Giridih"){
                totalGiridihQty = totalGiridihQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Godda"){
                totalGoddaQty = totalGoddaQty+parseInt(result[i]?.distributionQuantity);
            }
            if(graph_data[i]?.districtName=="Gumla"){
                totalGumlaQty = totalGumlaQty+parseInt(result[i]?.distributionQuantity);
            }
        }
        let graphtotal_data = [
            {label: 'Ra',y:totalranchiQty},
            {label: 'Bo',y:totalBokaroQty},
            {label: 'Ch',y:totalChatraQty},
            {label: 'De',y:totalDeogharQty},

            {label: 'Dh',y:totalDhanbadQty},
            {label: 'Du',y:totalDumkaQty},
            {label: 'Ga',y:totalGarhwaQty},
            {label: 'Gi',y:totalGiridihQty},

            {label: 'Go',y:totalGoddaQty},
            {label: 'Gu',y:totalGumlaQty},
            {label: 'Ha',y:totalHazaribagQty},
            {label: 'Ja',y:totalJamshedpurQty},
            {label: 'Jam',y:totalJamtaraQty},

            {label: 'Kh',y:totalKhuntiQty},
            {label: 'Ko',y:totalKodermaQty},
            {label: 'La',y:totalLateharQty},
            {label: 'Lo',y:totalLohardagaQty},
            {label: 'Pa',y:totalPakurQty},

            {label: 'Pa',y:totalPalamuQty},
            {label: 'Ra',y:totalRamgarhQty},
            {label: 'Sa',y:totalSahibganjQty},
            {label: 'Se',y:totalSeraikelaQty},
            {label: 'Si',y:totalSimdegaQty},
            {label: 'Sin',y:totalsinghbhumQty},
        ];
        console.log(graphtotal_data);
        setgraph_data(graphtotal_data);
        setTotalDistributedQuantity(totaldisrtributedQuantity);
        //seting pagenation data
        let offset = 0;
        var slice = result.slice(offset, offset + perPage);
        setPageCount(Math.ceil(result.length / perPage));
        setOrgTableData(result);
        setTableData(slice);
        setOffSet(offset);
        setCurrentPage(0);
        
    }
    const handleShowDetails = async(obj) => {
        let data = await API_Auth.gettransactionHashDetails(obj.txhash);
        console.log(data);
        sethashDetail(data);
        setshowDetailModel(true);
    };
    const handleClose =() => {
        setshowDetailModel(false);
        setshowRevertModal(false);
    }
    const formatter = new Intl.NumberFormat('en-IN')

    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}

            <Container>
                <Row>
                    <Col lg={3} style={{ paddingLeft: 0,marginTop:24}}>
                        <Title>Distribution Details</Title>
                    </Col>
                    </Row>
                    <Row>
                    <Col lg={role.toLocaleLowerCase()=="mis"?3:2} style={{ paddingLeft: 0}}>
                    <label style={{marginTop:17,}}>Season</label>
                        <div className="row_div" style={{ paddingLeft: 0}}>
                            <div className="input_div" style={{ marginLeft: 0}}>
                                <select value={season} onChange={handleInput} name="season"
                                    style={{ margin: 0, background: 'transparent' }}>
                                    <option value="">All</option>
                                    {seasonData.length != 0 && <>
                                        {seasonData.map((item, index) => {
                                            return (
                                         <option value={item.CombYearSeason}>{item.CombYearSeason}</option>
                                            )
                                        })}
                                    </>}
                                </select>
                            </div>
                        </div>
                    </Col>
{/*                     {role.toLocaleLowerCase()=="mis" &&
                    <Col lg={3}>
                        </Col>
                    } */}
                     {role.toLocaleLowerCase()=="ao_mis" &&
                    <Col lg={2}style={{ paddingRight: 0}}>
                    <label style={{marginTop:17,}}>District</label>
                        <div className="row_div" style={{ paddingRight: 0}}>
                            <div className="input_div" >
                                <select
                                    name="districtId"
                                    value={districtId}
                                    onChange={handleInput}
                                    style={{ margin: 0, background: 'transparent' }}>
                                    <option value="">All</option>
                                    {districtData.length != 0 &&
                                        <>
                                            {districtData.map((item, index) => {
                                                return (
                                            <option value={item.id}>{item.v_districtName}</option>
                                                )
                                            })}
                                        </>}
                                </select>
                            </div>
                        </div> 
                    </Col> 
                       }
                    {role.toLocaleLowerCase()=="ao" &&
                    <Col lg={2}style={{ paddingRight: 0}}>
                    <label style={{marginTop:17,}}>District</label>
                        <div className="row_div" style={{ paddingRight: 0}}>
                            <div className="input_div" >
                                <select
                                    name="districtId"
                                    value={districtId}
                                    onChange={handleInput}
                                    style={{ margin: 0, background: 'transparent' }}>
                                    <option value="">All</option>
                                    {districtData.length != 0 &&
                                        <>
                                            {districtData.map((item, index) => {
                                                return (
                                            <option value={item.id}>{item.v_districtName}</option>
                                                )
                                            })}
                                        </>}
                                </select>
                            </div>
                        </div> 
                    </Col> 
                       }
                       <Col lg={3} style={{ paddingLeft: 0}}>
                        <div className="inputdiv">
                            <label style={{marginTop:15,}}>Start Date</label>
                            <br />
                            <input type="date"
                                name="start_date"
                               // disabled
                                value={start_date}
                                placeholder="dd/mm/yyyy"
                                onChange={handleInput} />
                        </div>
                    </Col>
                    <Col lg={3} style={{ paddingRight: 0}}>
                        <div className="inputdiv">
                            <label style={{marginTop:15,}}>End Date</label>
                            <br />
                            <input type="date"  style={{width:"100%",marginLeft:0}}
                                name="end_date"
                               // disabled
                                value={end_date}
                                placeholder="dd/mm/yyyy"
                                onChange={handleInput} />
                        </div>
                    </Col>
                    <Col lg={role.toLocaleLowerCase()=="mis"?3:2} style={{ paddingRight: 0,marginTop:40 }}>
                          <button
                            className="add_button"
                            onClick={handlehitthheAPI}>
                            <p className="pcat_title">Fetch Data</p>
                        </button>
                    </Col>
                    </Row>
            </Container>
            <Container style={{marginTop:6}}>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
{/*                     {role.toLocaleLowerCase() != "ao_mis" &&
                           <> */}
                           {tableData.length >0 &&
                           <Row>
                      <Col lg={10}></Col>
                     <Col lg={2} >
                        <button
                            className="export_button"
                            onClick={(e) => exportToCSV(distributionsData)}>
                            <p className="pcat_title">Export CSV</p>
                        </button>
                    </Col>
                   </Row>
                        }
                        {/* </>
                    } */}
                        <Table  className="tablelist" responsive >
                            <thead>
                            <tr className="tablehead_small">
                            <th> Authentication</th>
                            <th> On-Spot</th>
                            <th> District</th>
                            <th> Season</th>
                      <th>Seed</th>
                      <th> Type</th>
                      <th> Quantity</th>
{/*                       <th> Farmer ContactNO</th> */}
                      <th style={{textAlign:'center'}}> Date</th>

{/*                       <th> Distributor</th> */}
                      <th> Distributor Name</th>
                      <th> Distributor Email</th>
                      <th> Farmer</th>
                      <th> Father</th>
                      <th> Mobile</th>
                      <th> Panchayat</th>
                      <th> Block</th>
                      <th> Village</th>
                      <th>CID</th>
                      <th style={{textAlign:'center'}}>txhash</th>
                      <th style={{textAlign:'center'}}>Action</th>

                                </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {tableData.length != 0 &&
                             <tbody>
                                {tableData.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index} className="tabletd_small">
                                            {obj.isOTPverified == "Yes" &&
                        <td style={{fontWeight:600,color:'green'}}>OTP</td>
                         }
                          {obj.isOTPverified == "No" &&
                        <td style={{fontWeight:600,color:'red'}}>Aadhaar</td>
                         } <td style={{fontWeight:600,color:obj.OnspotReg=='Yes'?'red':'green'}}>{obj.OnspotReg}</td>
                                             <td>{obj.districtName}</td>
                                            <td>{obj.year}-{obj.season}</td>
                                            <td>{obj.SeedName}</td>
                              <td>{obj.verietyName}</td>
                              <td>{obj.distributionQuantity}</td>
                          {/*<td>{obj.farmerContactNO}</td> */}
                        <td colSpan={1}>{obj.distributionDate}</td>
                        
                   <td>{obj.distributorFName}{' '}{obj.distributorLName}</td> 
                        <td>{obj.userEmail}</td>
                       <td>{obj.farmerName}</td>
                       <td>{obj.fatherName}</td>
                       <td>{obj.farmerMobileNO}</td>
                      <td>{obj.panchayat}</td>
                      <td>{obj.Block}</td>
                      <td>{obj.Village}</td>
                      <td>
                                                <OverlayTrigger
                                                        key={obj.CID}
                                                        overlay={<Tooltip id={`tooltip-top`}>
                                                                {obj.CID}</Tooltip>}>
                                                 <span className="table_text_overflow">{obj.CID}</span>
                                                    </OverlayTrigger>
                                                    </td>
                                                <td>
                                                <OverlayTrigger
                                                        key={obj.txhash}
                                                        overlay={<Tooltip id={`tooltip-top`}>
                                                                {obj.txhash}</Tooltip>}>
                                                 <span className="table_text_overflow" style={{textDecoration: 'underline',color:'blue'}}
                                                 onClick={()=>handleShowDetails(obj)}>
                                                    {obj.txhash}</span>
                                                    </OverlayTrigger>
                                                </td>
                                                <td style={{ textAlign: 'right' }}>
                                                 <div className="row_table_edit" style={{background: '#e99f9f',width:100}} onClick={() => handleDelete(obj)}>
                                                        <p>Revert Quantity</p>
                                                    </div> 
                                                   
                                                </td>
                    </tr>
                                        </>
                                    );
                                })} 
                                         <tr>
                                 {/*<td>{obj.id}</td> */}
                            <td className="ptotal_title" colSpan={2}>Total Quantity:</td>
                            <td className="ptotal_title"></td>
                            <td className="ptotal_title"></td>
                            <td className="ptotal_title"></td>
                            <td className="ptotal_title"></td>
                            <td className="ptotal_title" colSpan={9}>{formatter.format(totalDistributedQuantity)}</td>
                                            </tr>  
                            </tbody>
                            }
                        </Table>
                        {tableData.length != 0 &&
                            <div>
                                <div className="pagination_row_div">
                                    <p className="pagination_title">Showing items per page:</p>
                                    <div className="pagination_input_div" >
                                        <select className="pagination_select"
                                            style={{ margin: 0, background: 'transparent' }}
                                            name="pagination_value"
                                            value={perPage}
                                            onChange={handlerowsperPage} >
{/*                                             <option value={4}>4</option> */}
                                            <option value={20}>20</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="pagination_row_right_div">
                                    <p className="pagination_total">Showing {offset + 1} to {searchData.length < offset + perPage &&
                                        <span>{searchData.length}</span>}
                                        {searchData.length > offset + perPage &&
                                            <span>{offset + perPage} </span>} of {searchData.length} items</p>
                                    <ReactPaginate
                                        previousLabel={<ArrowBackIosIcon className="pagination_prev_btn" color="#2438a7" />}
                                        nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7" />}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={currentPage} />
                                </div>
                            </div>
                        }                    </Col>
                </Row>
               
            </Container>
            <Modal aria-labelledby="example-modal-sizes-title-sm" size="sm" show={showRevertModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">Allot Seed</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure to Revert The Quantity ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        NO
          </Button>
                    <Button variant="primary"
                        onClick={handleFinalSubmitt}>
                        YES
          </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showDetailsModal} onHide={handleClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    dialogclassname="modal-content">
                    <Modal.Header closeButton>
                        <Modal.Title> Transaction Details</Modal.Title>
                    </Modal.Header>

                    <Modal.Body >
                    <Row>
                            <Col lg={3}>
                                <p className="pcat_title" style={{marginBottom:6}}>BlockHash:</p>
                            </Col>
                            <Col>
                                <p className="pcat_text" style={{marginBottom:6}}>{hashDetails.blockHash}</p>
                            </Col>
                            </Row>
                            <Row>
                            <Col lg={3}>
                                <p className="pcat_title" style={{marginBottom:6}}>BlockNumber :</p>
                            </Col>
                            <Col>
                                <p className="pcat_text" style={{marginBottom:6}}>{hashDetails.blockNumber}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <p className="pcat_title" style={{marginBottom:6}}>ChainId:</p>
                            </Col>
                            <Col>
                                <p className="pcat_text" style={{marginBottom:6}}>{hashDetails.chainId}</p>
                            </Col>
                            </Row>
                            <Row>
                            <Col lg={3}>
                                <p className="pcat_title" style={{marginBottom:6}}>From :</p>
                            </Col>
                            <Col>
                                <p className="pcat_text" style={{marginBottom:6}}>{hashDetails.from}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <p className="pcat_title" style={{marginBottom:6}}>Gas:</p>
                            </Col>
                            <Col>
                                <p className="pcat_text" style={{marginBottom:6}}>{hashDetails.gas}</p>
                            </Col>
                            </Row>
                            <Row>
                            <Col lg={3}>
                                <p className="pcat_title" style={{marginBottom:6}}>GasPrice :</p>
                            </Col>
                            <Col>
                                <p className="pcat_text" style={{marginBottom:6}}>{hashDetails.gasPrice}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <p className="pcat_title" style={{marginBottom:6}}>Hash:</p>
                            </Col>
                            <Col>
                                <p className="pcat_text" style={{marginBottom:6}}>{hashDetails.hash}</p>
                            </Col>
                            </Row>
                            <Row>
                            <Col lg={3}>
                                <p className="pcat_title" style={{marginBottom:6}}>Input :</p>
                            </Col>
                            <Col>
                            <OverlayTrigger
                                                        key={hashDetails.input}
                                                        overlay={<Tooltip id={`tooltip-top`}>
                                                                {hashDetails.input}</Tooltip>}>
                                                <span className="pcat_title_overFlow" style={{marginBottom:6}}>{hashDetails.input}</span>
                                                    </OverlayTrigger>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <p className="pcat_title" style={{marginBottom:6}}>Nonce:</p>
                            </Col>
                            <Col>
                                <p className="pcat_text" style={{marginBottom:6}}>{hashDetails.nonce}</p>
                            </Col>
                            </Row>
                            <Row>
                            <Col lg={3}>
                                <p className="pcat_title"style={{marginBottom:6}}>To :</p>
                            </Col>
                            <Col>
                                <p className="pcat_text" style={{marginBottom:6}}>{hashDetails.to}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <p className="pcat_title" style={{marginBottom:6}}>V :</p>
                            </Col>
                            <Col>
                                <p className="pcat_text" style={{marginBottom:6}}>{hashDetails.v}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <p className="pcat_title" style={{marginBottom:6}}>R :</p>
                            </Col>
                            <Col>
                                <p className="pcat_text" style={{marginBottom:6}}>{hashDetails.r}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <p className="pcat_title" style={{marginBottom:6}}>S :</p>
                            </Col>
                            <Col>
                                <p className="pcat_text" style={{marginBottom:6}}>{hashDetails.s}</p>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            <ToastContainer />
        </Wrapper>
    )
}
export default DistributionDetailsList