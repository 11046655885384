import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./ForecastHistory.styles";
import API_Auth from "../../API_Auth";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingSpinner } from "../LoadingSpinner/index";
import { Container, Row, Col } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Table from "react-bootstrap/Table";
import { Title } from "../../GlobalStyle";
import moment from "moment";


const ForecastHistory = () => {
  const navigate = useNavigate();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const { isExpanded } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);

  const [cropName, setCropName] = useState("");
  const [cropNameErr, setCropNameErr] = useState("");

  const [variety, setVariety] = useState("");
  const [varietyErr, setVarietyErr] = useState("");

  const [seasons, setSeasons] = useState([]);
  const [year, setYear] = useState("");
  const [yearErr, setYearErr] = useState("");
  const [seedData, setSeedData] = useState([]);

  const [seedText, setSeedText] = useState("a");
  const [seedsSuggestion, setSeedsSuggestion] = useState([]);

  const [seedErr, setSeedErr] = useState("");
  const [cropId, setCropId] = useState("");
  const [varieties, setVarieties] = useState("");
  const [varietyID, setVarietyID] = useState("");
  const [offset, setOffSet] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [orgtableData, setOrgTableData] = useState([]);
  const [perPage, setPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [searchKey, setSearchKey] = useState("n");
  const [searchKeyTable, setSearchKeyTable] = useState("");
  const [searchData, setSearchData] = useState([]);

  const [daoSeedData, setDaoSeedData] = useState("");
  const location = useLocation();

  const [districtID, setDistrictID] = useState(
    location.state?.obj == undefined ? "" : location.state?.obj
  );

  console.log("state", location.state);
  const handleBackClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchSeeds = async () => {
      const seeds = await API_Auth.getSeeds();
      console.log("seeds", seeds);
      setSeedData(seeds);

      const seasondata = await API_Auth.getSeasons();
      console.log("result", seasondata);
      setSeasons(seasondata);

      console.log("districtID", districtID);

      const result = await API_Auth.getForecastHistoryByDistrict(districtID);
      console.log("resu", result);
      setSearchData(result);
      setIsLoading(false);
      //seting pagenation data
      var slice = result.slice(offset, offset + perPage);
      setPageCount(Math.ceil(result.length / perPage));
      setOrgTableData(result);
      setTableData(slice);

      console.log(result);
      setDaoSeedData(result);
    };
    // call the function
    fetchSeeds()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  const handlerowsperPage = (e) => {
    let showperpage = e.currentTarget.value;
    setPerPage(parseInt(showperpage));
    setCurrentPage(0);
    if (searchKey == "y") {
      const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
      setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value));
      setTableData(slice);
      setOffSet(0);
      setSearchData(searchKeyTable);
    } else {
      const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
      setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value));
      setTableData(slice);
      setOffSet(0);
      setSearchData(orgtableData);
    }
  };
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offsetdata = selectedPage * perPage;
    setCurrentPage(selectedPage);
    setOffSet(offsetdata);
    loadMoreData(offsetdata);
  };

  const loadMoreData = (offsetdata) => {
    const data = orgtableData;
    if (searchKey == "y") {
      const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
      setPageCount(Math.ceil(searchKeyTable.length / perPage));
      setTableData(slice);
      setOffSet(offsetdata);
      setSearchData(searchKeyTable);
    } else {
      const slice = data.slice(offsetdata, offsetdata + perPage);
      setPageCount(Math.ceil(data.length / perPage));
      setOffSet(offsetdata);
      setTableData(slice);
      setSearchData(data);
    }
  };

  return (
    <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
      <Container>
        <Row className="row_title">
          <Col lg={4}>
            <p>History</p>
          </Col>
          <Col lg={6}></Col>
          <Col lg={2}>
            <div className="back_button" onClick={() => handleBackClick()}>
              <IoIosArrowBack size={18} />
              <h6>Back</h6>
            </div>
          </Col>
        </Row>
      </Container>
      {isLoading && <LoadingSpinner />}

      <hr />

      <Container>
        <Row>
          <Col lg={12} sm={12} md={12} className="table_head">
            <Table className="tablelist" responsive>
              <thead>
                <tr>
                  <th>Component</th>
                  <th>Type</th>
                  <th>Year</th>
                  <th>Season</th>
                  <th>Forecast Quantity</th>
                  <th>Forecast Date</th>
                  <th>Approve Quantity</th>
                  <th>Approve Date</th>

                  
                  <th>Status</th>

                </tr>
              </thead>
              {tableData.length == 0 && (
                <tbody>
                  <tr>
                    <td colSpan="12">
                      <p className="no_Data_table">No Data Found</p>
                    </td>
                  </tr>
                </tbody>
              )}
              {tableData.length != 0 && (
                <tbody>
                  {tableData.map((obj, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td>{obj.seedName}</td>
                          <td>{obj.varietyName}</td>

                          <td>{obj.year}</td>
                          <td>{obj.season}</td>
                          <td>{obj.forecastQuantity}</td>
                          <td>{moment.utc(obj.forecastDate).format("DD MMM YYYY  h:mm A")}</td>

                          <td>{obj.approveQuantity}</td>
                          {obj.approvedate!='' &&  <td>{moment.utc(obj.approvedate).format("DD MMM YYYY  h:mm A")}</td>}
                          {obj.approvedate=='' &&  <td></td>}


                          <td>{obj.status}</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              )}
            </Table>
            {tableData.length != 0 && (
              <div>
                <div className="pagination_row_div">
                  <p className="pagination_title">Showing items per page:</p>
                  <div className="pagination_input_div">
                    <select
                      className="pagination_select"
                      style={{ margin: 0, background: "transparent" }}
                      name="pagination_value"
                      value={perPage}
                      onChange={handlerowsperPage}
                    >
                      <option value={4}>4</option>
                      <option value={8}>8</option>
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                    </select>
                  </div>
                </div>
                <div className="pagination_row_right_div">
                  <p className="pagination_total">
                    Showing {offset + 1} to{" "}
                    {searchData.length < offset + perPage && (
                      <span>{searchData.length}</span>
                    )}
                    {searchData.length > offset + perPage && (
                      <span>{offset + perPage} </span>
                    )}{" "}
                    of {searchData.length} items
                  </p>
                  <ReactPaginate
                    previousLabel={
                      <ArrowBackIosIcon
                        className="pagination_prev_btn"
                        color="#2438a7"
                      />
                    }
                    nextLabel={
                      <ArrowForwardIosIcon
                        className="pagination_next_btn"
                        color="#2438a7"
                      />
                    }
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    forcePage={currentPage}
                  />
                </div>
              </div>
            )}{" "}
          </Col>
        </Row>
      </Container>

      <ToastContainer />
    </Wrapper>
  );
};
export default ForecastHistory;
