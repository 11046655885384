import styled from "styled-components";

export const Wrapper = styled.div`
background:white;
margin:20px;
border-radius:6px;
.alert-message{
  color: red;
  margin-left: 15px;
}
.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  width:120px;
  height:120px;
  border:2px solid  #6EDC8F;  
  background:#ACACAC;
  border-radius:5%;
  margin:15px;
}   
.sigImg{
width: 28%;
  margin: 10px;
}
.custom-file-uploaded {
  text-align: center;
  background: transparent;
  img{
    width: 100px;
    height: 100px;
    border:2px solid  #6EDC8F;  
    border-radius: 5%;
  }
  a{
  cursor: pointer;
  }

}
.input_div {
    color:var(--black);
    font-family: 'ARIAL';
    height:45px;
    width:100%;
    margin-top:8px;
    border-radius:6px;
    background:white;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    :focus {
      border: 0px solid #DFDFDF !important; 
    }
    input,select{
           border: 2px solid #DFDFDF;
       :focus {
         outline: none !important;
         border-color:none;  
       }
      }
     
  }
.row_div_search{
    display:flex;
    flex-direction:row;
    padding: 8px;
  }
  .inputdiv {
    margin:10px 10px 5px;
}
.ul_search{
  
    list-style-type:none;
    text-align:left;
    margin-left:10px;
    padding:0;
    overflow: scroll;
    height: 150px;
    border :1px solid lightgrey;
    &:before {
      content:"";
    }
  }
  .ul_search_length{
    list-style-type:none;
    text-align:left;
    margin:0;
    padding:0;
   
    border :none;
    &:before {
      content:"";
    }
  }

 .li_search {
    padding :10px 15px;
    margin-left:5px;
    font-family:'Roboto';
    font-bold:400;
    cursor:pointer;
  } 


`