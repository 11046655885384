import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Wrapper, } from "./styles";
import { UserContext } from "../../context"
import { FaRegEye, FaEyeSlash } from "react-icons/fa"
import { LoadingSpinner } from "../LoadingSpinner/index";
import { Row, Col,Container } from "react-bootstrap";
import SettleMint_logo from "../../images/SettleMint_logo.svg";
import API_Auth from "../../API_Auth";
import playApp from "../../images/play-app.png"
import GooglePlay from "../../images/play-btn.png"
import Carousel from 'react-bootstrap/Carousel';
import About_Project from "../../images/about-project.png";
import WhyImage from "../../images/why.png";
import About from "../../images/about.png";
import CopyRight from "../../elements/CopyRights";

// DONE
const AboutUs = () => {
    const navigate = useNavigate();
    const { setSessionIsLoggedIn, setSessionLoggedRole, setUserEmail, setSessionLoggedUser, setLoggedUserName } = useContext(UserContext);
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);
    const [userErr, setUserErr] = useState("");
    const [passwordErr, setPasswordErr] = useState("");
    const [responseErr, setResponseErr] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [passwordType, setPasswordType] = useState("password");

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password");
    }


    const handleInput = (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        if (name === "email") setUserName(value);
        if (name === "password") setPassword(value);
    };
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };
    return (
        <Wrapper>
            {isLoading && <LoadingSpinner />}
     
                <Container>
                <Row>
                <Col lg={12} className="text_center margin_top_btm" >
                <h1 class="pseudo_border">About Project</h1>
                </Col>
                </Row>
                <Row className="margin_top_btm">
                <Col lg={7}>

                <p>Jharkhand, meaning 'the land of forests' is a state in eastern India. The total geographical
area of Jharkhand state is 79.72 lakh hectares, out of that, around 38 lakh hectares are
classified as cultivable agricultural land. Jharkhand state falls under the agro-climatic
zone VII (Eastern Plateau and Hilly region), which has been further divided into three
subzones. The state receives an annual rainfall of 1200 – 1600 mm and the climate
ranges from dry semi-humid to humid semi-arid types. About 82% of the annual rainfall
occurs within the monsoon season, which lasts from mid-June to September. Available
moisture over the entire monsoon period determines the opportunity for the various
cropping systems practiced by the farmers. Agriculture is the primary income-generating
activity for 80% of the rural population of the state. This agricultural economy of the
Jharkhand state is characterized by dependence on nature, low investment, low
productivity, mono-cropping with paddy as the dominant component, inadequate irrigation
facilities, and small and marginal holdings.</p>
                </Col>
                 <Col  lg={5}>
                <img src={About} alt="" className="rounded"/>
                </Col>
            </Row>
            <Row className="margin_top_btm">
            <Col  lg={5}>
                <img src={WhyImage} alt="" className="rounded"/>
                </Col>
                <Col lg={7}>

                <p>One of the main strategies for the development of Jharkhand State is to ensure
agricultural and rural development on a sustainable basis. The main crops of Jharkhand
state are paddy, maize, wheat, pulses, oilseeds, potato, and vegetables. Components seed is one
of the most crucial elements in the livelihoods of farming communities. The potential
benefits to farmers from the usage of high-quality seeds for a diverse range of component
varieties are widely acknowledged. What is also critical is that the seeds are distributed
within a defined time frame.</p>

			  <p>However, at times there are instances when spurious seeds find their way to farmers
through various means, sometimes, there is also a delay in the seed distribution, which
affects the sowing cycle and eventually the yield for the farmer. In absence of a robust
monitoring system which offers real time visibility in the demand generation, procurement,
warehousing, and distribution of seeds, it is difficult to assess the effectiveness and
efficiency of the seed distribution system.
</p>
                </Col>
            </Row>
            <Row className="margin_top_btm">
                <Col lg={12}>
                <p>To tackle these challenges, and to avoid the introduction of spurious certified seeds in the
distribution supply chain by creating a robust tamper-proof tracking, monitoring, and
reporting mechanism, the department of agriculture, the government of Jharkhand, has
decided to implement a blockchain-based “Seed Traceability” platform which shall ensure
that there is no tampering of data and there will be an absolute transparency regarding
the movement of seeds, right from demand creation, to delivery, testing, and eventually,
distribution.</p>
                </Col>
                </Row>
                <Row className="margin_top_btm">
            <Col  lg={12}>
                <img src={About_Project} alt="" className="rounded"/>
                </Col>
                </Row>
                <Row className="margin_top_btm">
            <Col  lg={12}>
            <p>Blockchain is a system of recording information in a way that makes it difficult or
impossible to change, hack, or cheat the system. It is essentially a digital ledger of
transactions that is duplicated and distributed across the entire network of computer
systems on the blockchain. Each block in the chain contains a number of transactions,
and every time a new transaction occurs on the blockchain, a record of that transaction is
added to every participant’s ledger. This decentralized database managed by multiple
participants is known as Distributed Ledger Technology (DLT). Blockchain is a type of
DLT in which transactions are recorded with an immutable cryptographic signature called
a hash.</p>

				  <p>For implementing this cutting-edge technology for the benefit of the farmers and citizens
of the state, the government of Jharkhand invited techno-commercial bids from leading
industry players in April of 2021 and after a careful evaluation, selected European
Blockchain Leader SettleMint for delivering this project. The deployed solution runs on an
enterprise-class private Blockchain, utilizes hyper-scalable cloud platforms and
orchestration tools such as Docker and Kubernetes as the supporting infrastructure
layers, and is driven by smart contracts based on highly secure and encrypted
transactions and record-keeping algorithms.</p>


				  <p>This solution connects various stakeholders such as District Agricultural Officers (DAOs),
State Agricultural Officers, Suppliers, Distributors, Seed Quality Testing Agencies,
Logistics Providers, and most importantly Farmers. The movement of seeds through the
entire supply and distribution chain is captured and recorded on Blockchain, there is a
Mobile application to facilitate easy access for various stakeholders and farmers, and an
aadhar card and secret code based authentication mechanism is used for the
establishment of the identity and for confirmation of the receipt of seeds by the farmer, at
every stage detailed reports are generated which provide real-time visibility on the
execution of the seed distribution program. The data generated by this blockchain
platform will be used to identify bottlenecks and process efficiency improvement areas
which will help in the continuous improvement of the Seed Distribution Program. By
providing more rapid access to relevant and reliable information that helps determine the
source and location of implicated products, this effective traceability system significantly
reduces the response times when an outbreak occurs.
</p>

<p>The government is committed to the upliftment of the farmers of the state and with this
project, Jharkhand has become the first state in the country to deploy such cutting-edge
technological intervention using Blockchain. The government is thankful to all the
stakeholders who have shown immense enthusiasm, dedication, and sincerity to adopt
this new solution quickly and have come together to empower the farmers of the state.
This project will connect 24 DAOs, XYZ+ Suppliers, and XYZ+ Seed Distributors who will
handle XYZXYZ quintals of seed distribution per year and will touch the lives of XYZXYZ
farmers.
</p>
                </Col>
                </Row>
            </Container>
            {/* <Container>
                <Row className="footer_sec">
              <div className="text_center" >
          <p>Copyright © 2022 , Department of Agriculture, Govt. of Jharkhand. All Rights Reserved</p>
          <p>Powered by <a href="https://www.settlemint.com/" target="_blank" style={{color: '#63D669'}}>SettleMint</a></p>
        </div>
            </Row>
            </Container> */}
            <CopyRight/>
        </Wrapper>
    );
};

export default AboutUs;
