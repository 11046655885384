import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./ForecastAODetailsNew.styles";
import { Row, Container, Col } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Edit from "../../images/edit.png";
import plus from "../../images/white_plus.png";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle";
import { LoadingSpinner } from "../LoadingSpinner/index";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const ForecastAODetailsNew = () => {
  const { isExpanded } = useContext(UserContext);
  const [foreCastData, setForeCastData] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);

  let { forecastID } = useParams();
  const [buttonSubmit, setButtonSubmit] = useState(false);


  const [offset, setOffSet] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [orgtableData, setOrgTableData] = useState([]);
  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [searchKey, setSearchKey] = useState("n");
  const [searchKeyTable, setSearchKeyTable] = useState("");
  const [err, setErr] = useState("");
  const location = useLocation();

  const [districtID, setDistrictID] = useState(
    location.state?.forecastseeddata == undefined
      ? ""
      : location.state?.forecastseeddata.districtID
  );
  const [daoID, setDaoID] = useState(
    location.state?.forecastseeddata == undefined
      ? ""
      : location.state?.forecastseeddata.DaoID
  );

  const [inputFields, setInputFields] = useState([
    {
      SeedName: "",
      VarietyName: "",
      Quantity: "",
      approvedQuantity: "",
      suppliers: [],
    },
  ]);

  useEffect(() => {
    setIsLoading(true);

    const fetchForecast = async () => {
      let result = await API_Auth.getAoPendingView(districtID);
      console.log("result", result);
      setForeCastData(result);
      setSearchData(result);
      var seeddata = result.SeedData;
      result.forEach((v) => {
        v.approvedqunatity = v.forecastQuantity;
        v.issueqtyerror = true;
        v.issueqtyErr = '';
        v.issuecolor = '';
      });

      setIsLoading(false);
      console.log("seedata", seeddata);
      setInputFields(seeddata);

      //sesetSearchDatating pagenation data
    };
    // call the function
    fetchForecast()
      // make sure to catch any error
      .catch(console.error);
  }, []);
  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    console.log(name, value, index);
    const list = [...foreCastData];
    if (name == "approvedqunatity") {

      if (parseInt(list[index].forecastQuantity) >= parseInt(value)) {
        console.log("Hello");
        list[index]['issueqtyerror'] = true;
        list[index]['issueqtyErr'] = "";
        list[index][name] = value;
    } 
    else if (value == "") {
        console.log("Hello");
        list[index]['issueqtyerror'] = false;
        list[index]['issueqtyErr'] = "Please Enter Quantity";
        list[index][name] = value
    }

    else {
        console.log("Hi")
        list[index]['issueqtyerror'] = false;
        list[index]['issueqtyErr'] = "Quantity Exceeds"
        list[index]["issuecolor"] = "y"
        list[index][name] = value
    }

    }
    console.log(list);

    setForeCastData(list);
  };
  const handleSubmit = async () => {
    console.log(foreCastData);
    let error = false;
    for (var i = 0; i < foreCastData.length; i++) {
      if (foreCastData[i].approvedqunatity == "") {
        error = true;
        setErr("Please Enter All Fields");
      }
      if (foreCastData[i].issueqtyerror == false) {
        error = true;
        setErr("Please Enter Valid Quantity");
    } else {
        error = false
        setErr("")
    }
    }
    console.log(error);
     if (!error) {
      setErr("");
      
      setIsLoading(true);
      setButtonSubmit(true);

      console.log("disable",buttonSubmit)

   
      const aoID = localStorage.getItem("UserID");
      let status = "APPROVE";

      foreCastData.map(async function (app, key) {
        console.log(daoID,
            app.seedID,
            app.varietyID,
            app.year,
            app.season,
            app.ForecastID,
            aoID,
            app.approvedqunatity,
            status);
           
        const forecast_Data = await API_Auth.getAoUpdateForeacast(
          daoID,
          app.seedID,
          app.varietyID,
          app.year,
          app.season,
          app.ForecastID,
          aoID,
          app.approvedqunatity,
          status
        );
      });
      setIsLoading(false);
      toast.success("Approved Quantity added successfully");
      console.log("disable",buttonSubmit)

       setTimeout(() => {
        //navigate("/forecast-ao-new");
      }, 2000);
    } else {
      console.log("button")
      setButtonSubmit(false)

    } 
  };
  const handleHistory=()=>{
    console.log("datahell",districtID);
    navigate("/forecast-history", { state: { obj: districtID } });



  }
  return (
    <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
      {isLoading && <LoadingSpinner />}

      <Container>
        {/*   <Row>
                    <Col lg={4} style={{ paddingLeft: 0 }}>
                        <Title>ForeCast Details</Title>
                    </Col>
                </Row> */}
      </Container>
      <Container></Container>
      <Container>
        <Row>
          <Col lg={4} style={{ paddingLeft: 0 }}>
            <Title>Seed Details</Title>
          </Col>
          <Col lg={6} style={{ paddingLeft: 0 }}>
          </Col>
          <Col lg={2} style={{ paddingLeft: 0 }}>
          <div className="back_button" onClick={() => handleHistory()}>
              <h6>History</h6>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col lg={12} sm={12} md={12} className="table_head">
            <Table className="tablelist" responsive>
              <thead>
                <tr className="table_wrap">
                  <th>SeedName </th>
                  <th>Type Name</th>
                  <th>Year</th>
                  <th>Season</th>
                  <th>Forecast Quantity</th>

                  <th>Approved Quantity</th>
                </tr>
              </thead>
              {foreCastData.length == 0 && (
                <tbody>
                  <tr>
                    <td colSpan="12">
                      <p className="no_Data_table">No Data Found</p>
                    </td>
                  </tr>
                </tbody>
              )}
              {foreCastData.length != 0 && (
                <tbody>
                  {foreCastData.map((data, index) => {
                    const {
                      seedName,
                      varietyName,
                      year,
                      season,
                      approvedqunatity,
                      forecastQuantity,
                      issueqtyerror,
                      issuecolor,
                      issueqtyErr
                    } = data;
                    return (
                      <>
                        <tr key={index}className="table_wrap">
                          <td>{seedName}</td>
                          <td>{varietyName}</td>
                          <td>{year}</td>
                          <td>{season}</td>
                          <td>{forecastQuantity}</td>


                          <td>
                            <div>
                              <input
                                type="number"
                                style={{ width: "50%" }}
                                name="approvedqunatity"
                                value={approvedqunatity}
                                onChange={(evnt) => handleChange(index, evnt)}

                            
                              />
                                  {issueqtyerror == false && issuecolor == "y" && <p style={{
                                    color: '#DF8D0F', textAlign: 'center', fontWeight: '700'
                                }}>{issueqtyErr}</p>}
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              )}
            </Table>
            {err != "" && <p style={{ color: "red", margin: 10 }}>{err}</p>}
            <Row>
              <Col
                lg={3}
                className="send_button"
                disable={buttonSubmit}
                 onClick={() => handleSubmit()}
              >
                <p>Approve</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </Wrapper>
  );
};
export default ForecastAODetailsNew;
