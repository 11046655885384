import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Wrapper, } from "./styles";
import { UserContext } from "../../context"
import { LoadingSpinner } from "../LoadingSpinner/index";
import { Row, Col,Container } from "react-bootstrap";
import BadalImage from "../../images/badal.png"
import NishaImage from "../../images/nisha.png"
import SiddiqiImage from "../../images/siddiqi.png";
import CopyRight from "../../elements/CopyRights";
import Card from 'react-bootstrap/Card';
// DONE
const DepartmentStaticScreen = () => {
    const navigate = useNavigate();
    const { setSessionIsLoggedIn, setSessionLoggedRole, setUserEmail, setSessionLoggedUser, setLoggedUserName } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [passwordType, setPasswordType] = useState("password");

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password");
    }
    return (
        <Wrapper>
            {isLoading && <LoadingSpinner />}
            <Container>
                <Row>
                <Col lg={12} className="text_center margin_top_btm" >
                <h1 class="pseudo_border">Department of Agriculture, Animal<br></br> Husbandry & Co-operative</h1>
                </Col>
                </Row>
                <Row className="margin_top_btm">
                <Col lg={4}> 
                <Card className="card_body">
        <Card.Img variant="top" className="roundedImage" src={BadalImage} />
        <Card.Body>
          <Card.Title style={{fontSize:19}}>Shri Badal Patralekh</Card.Title>
          <p>Hon'ble Minister-in-Charge</p>
          <Card.Text style={{color: '#666666'}}>
          Department of Agriculture, Animal Husbandry & Co-operative.
          </Card.Text>
        </Card.Body>
      </Card>             
                </Col>
                 <Col  lg={4}>
                 <Card className="card_body">
        <Card.Img variant="top" className="roundedImage" src={SiddiqiImage} />
        <Card.Body>
          <Card.Title style={{fontSize:19}}>Shri Aboobacker Siddique P, IAS</Card.Title>
          <p>Secretary</p>
          <Card.Text style={{color: '#666666'}}>
Department of Agriculture, Animal Husbandry & Co-operative.
          </Card.Text>
        </Card.Body>
      </Card>
                </Col>
                <Col  lg={4}>
                 <Card className="card_body">
        <Card.Img variant="top" className="roundedImage" src={NishaImage} />
        <Card.Body>
        <Card.Title style={{fontSize:19}}>Smt Nisha Oraon Singhmarr, IRS</Card.Title>
          <p>Director</p>
          <Card.Text style={{color: '#666666'}}>
          Department of Agriculture, Animal Husbandry & Co-operative (Directorate of Agriculture).
          </Card.Text>
        </Card.Body>
      </Card>
                </Col>
            </Row>
            </Container>
            <CopyRight/>
        </Wrapper>
    );
};

export default DepartmentStaticScreen;
