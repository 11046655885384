import styled from "styled-components";

export const Wrapper = styled.div`
background: #fff;
.footer_sec{
    background-color: #fff;
    height: auto;
    padding: 41px 0;
    margin: 0 auto;
    border-top: 3px solid #247158;
p {
  margin-bottom: 0px;
  font-size: 14px;
  color: #000000;
  font-family: sans-serif;
  font-weight: 300;
  line-height: 1.7em;
  margin-top: 0;
}
a{
  text-decoration: none;
  color: rgb(52 181 58) !important;
  background-color: transparent;
  cursor:pointer;
  :hover {
    text-decoration: underline;
  }
}
}
`