import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./styles"
import { Row, Container, Col } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import Edit from "../../images/edit.png";
import plus from "../../images/white_plus.png"
import { useNavigate, Link } from "react-router-dom";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle"
import { LoadingSpinner } from "../LoadingSpinner/index";
import { AiOutlineSearch } from "react-icons/ai";

import * as FileSaver from 'file-saver';

import * as XLSX from 'xlsx';


const DashboardSeedWise2 = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    const [distributionWise, setDistributionWise] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);


    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey, setSearchKey] = useState('n');
    const [searchKeyTable, setSearchKeyTable] = useState('');

    const [seedId, setSeedId] = useState('');    
    const [seedName, setSeedName] = useState('');
    const [key, setKey] = useState('d');
    const [varietyId, setVarietyId] = useState('');
    const [varietyName, setVarietyName] = useState('');
    const [districtName, setDistrictName] = useState('');
    const [districtId, setDistrictId] = useState('');
    
    const [selectdistrictData, setDistrictsSelectData] = useState('');
    const [seedData, setSeedData] = useState([]);
    const [varietyData, setVarietyData] = useState([]);

    const [districtData, setDistrictData] = useState([]);
    const [seasonData, setSeasonData] = useState([]);

    const [season, setSeason] = useState('')
    const[role,setRole]=useState('');

    const [varieties, setVarieties] = useState([])
    const [dashbaordSeedWisedata, setDashbaordSeedWiseData] = useState([])
    const[exceldata,setExceldata]=useState([])
    const [totalQuantity, setTotalQuantity] = useState([]);
    const [totalAllotQuantity, setTotalAllotQuantity] = useState(0);
    const [totalpurchaseDemandQuantity, setTotalpurchaseQuantity] = useState(0);
    const [totaldisrtributedQuantity, setTotaldisrtributedQuantity] = useState(0);
    const [totalfarmersBenifited, setTotalfarmersBenifited] = useState(0);
    const [totalreceivedQuntity, setTotalReceiveQuantity] = useState(0);

    const colorGen = () => {
        var generateColor = Math.floor(Math.random() * 256);
        return generateColor;
    }

    const exportToCSV=(csvData)=>{

        
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        const fileExtension = '.xlsx';
        const fileName = 'Seed Wise Dashboard'

        const ws = XLSX.utils.json_to_sheet(csvData);

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        const data = new Blob([excelBuffer], { type: fileType });

        FileSaver.saveAs(data, fileName + fileExtension);
    }
    const handleInput = async (e) => {
        const { name, value } = e.target;
        if (name == "season") {
            setSeason(value);
            console.log(value);
            const data = seedData.filter((item) => item.id == seedId);
            if(value != ''){
            const result = await API_Auth.getDistrictCropSeasonwiseSeedData(data[0].CropName,districtId,value);
            console.log("resultseedata", result);
            var totalarrayexcel=[]
            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    console.log("result[key]",result[key].length)
                    console.log(key);
                    for(var i=0;i<result[key].length; i++){
                    let data = {
                        District:result[key][i][0].DistrictName,
                        Variety: key,
                        Quantity:result[key][i][0].Quantity,
                        Alloted:result[key][i][0].AllocatedQuantity,
                        Purchased:result[key][i][0].purchaseDemandQuantity,
                        Distributed:result[key][i][0].v_disrtributedQuantity,
                        Farmers:result[key][i][0].farmersBenifited,
                        ReceivedQuantity:result[key][i][0].ReceivedQuantity
                    }
                    totalarrayexcel.push(data) 
                }
                }
            }
            console.log("totalarrayexcel", totalarrayexcel)
            setExceldata(totalarrayexcel);             
            var totalarray = []
            setIsLoading(false);
            var varietiesarray = []
            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    console.log("key", key);
                    let data = {
                        vname: key,
                        color: 'rgb(' + colorGen() + ',' + colorGen() + ',' + colorGen() + ')'
                    }
                    varietiesarray.push(data)
                }
            }
            console.log("varietiesarray", varietiesarray)
            setVarieties(varietiesarray);
            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    let data = {
                        vname: key,
                        vdata: result[key]
                    }
                    totalarray.push(data)
                }
            }
            console.log("totalArray", totalarray);
            setDashbaordSeedWiseData(totalarray);
            let totalAllotQuantity = 0;
            let totalReceiverQuantity = 0;
            let totalpurchaseQuantity = 0;
            let totalfarmersbenefited = 0;
            let totaldistributedquantity = 0;
            let totalQuantity = 0;

            for(let i=0; i<totalarray.length; i++){
                console.log("totalarray[i]?.vdata[0][0]?.AllocatedQuantity", totalarray[i]?.vdata[0][0]?.AllocatedQuantity);
                if(totalarray[i]?.vdata[0][0]?.AllocatedQuantity){
                 totalAllotQuantity = totalAllotQuantity+parseInt(totalarray[i]?.vdata[0][0]?.AllocatedQuantity);
                }
              if(totalarray[i]?.vdata[0][0]?.AllocatedQuantity){
                 totalReceiverQuantity = totalReceiverQuantity+parseInt(totalarray[i]?.vdata[0][0]?.AllocatedQuantity);
              }
              if(totalarray[i]?.vdata[0][0]?.purchaseDemandQuantity){
                 totalpurchaseQuantity = totalpurchaseQuantity+parseInt(totalarray[i]?.vdata[0][0]?.purchaseDemandQuantity);
              }
              if(totalarray[i]?.vdata[0][0]?.farmersBenifited){
                 totalfarmersbenefited = totalfarmersbenefited+parseInt(totalarray[i]?.vdata[0][0]?.farmersBenifited);
              }
              if(totalarray[i]?.vdata[0][0]?.Quantity){
                 totalQuantity = totalQuantity+parseInt(totalarray[i]?.vdata[0][0]?.Quantity);
              }
              if(totalarray[i]?.vdata[0][0]?.v_disrtributedQuantity){
                 totaldistributedquantity = totaldistributedquantity+parseInt(totalarray[i]?.vdata[0][0]?.v_disrtributedQuantity);
              }
             }
             setTotalAllotQuantity(totalAllotQuantity);
             setTotalQuantity(totalQuantity); 
             setTotalReceiveQuantity(totalReceiverQuantity); 
             setTotaldisrtributedQuantity(totaldistributedquantity); 
             setTotalpurchaseQuantity(totalpurchaseQuantity);
             setTotalfarmersBenifited(totalfarmersbenefited); 
        }
        else{
            const result = await API_Auth.getDistrictCropwiseSeedData(data[0].CropName,districtId);
            console.log("resultseedata", result);
            setIsLoading(false);
            var totalarrayexcel=[]
            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    console.log("result[key]",result[key].length)
                    console.log(key);
                    for(var i=0;i<result[key].length; i++){
                    let data = {
                        District:result[key][i][0].DistrictName,
                        Variety: key,
                        Quantity:result[key][i][0].Quantity,
                        Alloted:result[key][i][0].AllocatedQuantity,
                        Purchased:result[key][i][0].purchaseDemandQuantity,
                        Distributed:result[key][i][0].v_disrtributedQuantity,
                        Farmers:result[key][i][0].farmersBenifited,
                        ReceivedQuantity:result[key][i][0].ReceivedQuantity
                    }
                    totalarrayexcel.push(data) 
                }
                }
            }
            console.log("totalarrayexcel", totalarrayexcel)
            setExceldata(totalarrayexcel);   
            var totalarray = []
            var varietiesarray = []
            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    console.log("key", key);
                    let data = {
                        vname: key,
                        color: 'rgb(' + colorGen() + ',' + colorGen() + ',' + colorGen() + ')'
                    }
                    varietiesarray.push(data)
                }
            }
            console.log("varietiesarray", varietiesarray)
            setVarieties(varietiesarray);
            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    let data = {
                        vname: key,
                        vdata: result[key]
                    }
                    totalarray.push(data)
                }
            }
            console.log("totalArray", totalarray);
            setDashbaordSeedWiseData(totalarray);
            let totalAllotQuantity = 0;
            let totalReceiverQuantity = 0;
            let totalpurchaseQuantity = 0;
            let totalfarmersbenefited = 0;
            let totaldistributedquantity = 0;
            let totalQuantity = 0;

            for(let i=0; i<totalarray.length; i++){
                console.log("totalarray[i]?.vdata[0][0]?.AllocatedQuantity", totalarray[i]?.vdata[0][0]?.AllocatedQuantity);
                if(totalarray[i]?.vdata[0][0]?.AllocatedQuantity){
                 totalAllotQuantity = totalAllotQuantity+parseInt(totalarray[i]?.vdata[0][0]?.AllocatedQuantity);
                }
              if(totalarray[i]?.vdata[0][0]?.AllocatedQuantity){
                 totalReceiverQuantity = totalReceiverQuantity+parseInt(totalarray[i]?.vdata[0][0]?.AllocatedQuantity);
              }
              if(totalarray[i]?.vdata[0][0]?.purchaseDemandQuantity){
                 totalpurchaseQuantity = totalpurchaseQuantity+parseInt(totalarray[i]?.vdata[0][0]?.purchaseDemandQuantity);
              }
              if(totalarray[i]?.vdata[0][0]?.farmersBenifited){
                 totalfarmersbenefited = totalfarmersbenefited+parseInt(totalarray[i]?.vdata[0][0]?.farmersBenifited);
              }
              if(totalarray[i]?.vdata[0][0]?.Quantity){
                 totalQuantity = totalQuantity+parseInt(totalarray[i]?.vdata[0][0]?.Quantity);
              }
              if(totalarray[i]?.vdata[0][0]?.v_disrtributedQuantity){
                 totaldistributedquantity = totaldistributedquantity+parseInt(totalarray[i]?.vdata[0][0]?.v_disrtributedQuantity);
              }
             }
             setTotalAllotQuantity(totalAllotQuantity);
             setTotalQuantity(totalQuantity); 
             setTotalReceiveQuantity(totalReceiverQuantity); 
             setTotaldisrtributedQuantity(totaldistributedquantity); 
             setTotalpurchaseQuantity(totalpurchaseQuantity);
             setTotalfarmersBenifited(totalfarmersbenefited); 
        }
        }
        if (name == "districtId") {
            setDistrictId(value);
            const data = seedData.filter((item) => item.id == seedId);
            setIsLoading(true);
            if(season==''){
            const result = await API_Auth.getDistrictCropwiseSeedData(data[0].CropName,value);
            console.log("resultseedata", result);
            setIsLoading(false);
            var totalarrayexcel=[]
            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    console.log("result[key]",result[key].length)
                    console.log(key);
                    for(var i=0;i<result[key].length; i++){
                    let data = {
                        District:result[key][i][0].DistrictName,
                        Variety: key,
                        Quantity:result[key][i][0].Quantity,
                        Alloted:result[key][i][0].AllocatedQuantity,
                        Purchased:result[key][i][0].purchaseDemandQuantity,
                        Distributed:result[key][i][0].v_disrtributedQuantity,
                        Farmers:result[key][i][0].farmersBenifited,
                        ReceivedQuantity:result[key][i][0].ReceivedQuantity
                    }
                    totalarrayexcel.push(data) 
                }
                }
            }
            console.log("totalarrayexcel", totalarrayexcel)
            setExceldata(totalarrayexcel);   
            var totalarray = []
            var varietiesarray = []
            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    console.log("key", key);
                    let data = {
                        vname: key,
                        color: 'rgb(' + colorGen() + ',' + colorGen() + ',' + colorGen() + ')'
                    }
                    varietiesarray.push(data)
                }
            }
            console.log("varietiesarray", varietiesarray)
            setVarieties(varietiesarray);
            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    let data = {
                        vname: key,
                        vdata: result[key]
                    }
                    totalarray.push(data)
                }
            }
            console.log("totalArray", totalarray);
            setDashbaordSeedWiseData(totalarray);
            let totalAllotQuantity = 0;
            let totalReceiverQuantity = 0;
            let totalpurchaseQuantity = 0;
            let totalfarmersbenefited = 0;
            let totaldistributedquantity = 0;
            let totalQuantity = 0;
            for(let i=0; i<totalarray.length; i++){
                console.log("totalarray[i]?.vdata[0][0]?.AllocatedQuantity", totalarray[i]?.vdata[0][0]?.AllocatedQuantity);
                if(totalarray[i]?.vdata[0][0]?.AllocatedQuantity){
                 totalAllotQuantity = totalAllotQuantity+parseInt(totalarray[i]?.vdata[0][0]?.AllocatedQuantity);
                }
              if(totalarray[i]?.vdata[0][0]?.AllocatedQuantity){
                 totalReceiverQuantity = totalReceiverQuantity+parseInt(totalarray[i]?.vdata[0][0]?.AllocatedQuantity);
              }
              if(totalarray[i]?.vdata[0][0]?.purchaseDemandQuantity){
                 totalpurchaseQuantity = totalpurchaseQuantity+parseInt(totalarray[i]?.vdata[0][0]?.purchaseDemandQuantity);
              }
              if(totalarray[i]?.vdata[0][0]?.farmersBenifited){
                 totalfarmersbenefited = totalfarmersbenefited+parseInt(totalarray[i]?.vdata[0][0]?.farmersBenifited);
              }
              if(totalarray[i]?.vdata[0][0]?.Quantity){
                 totalQuantity = totalQuantity+parseInt(totalarray[i]?.vdata[0][0]?.Quantity);
              }
              if(totalarray[i]?.vdata[0][0]?.v_disrtributedQuantity){
                 totaldistributedquantity = totaldistributedquantity+parseInt(totalarray[i]?.vdata[0][0]?.v_disrtributedQuantity);
              }
             }
             setTotalAllotQuantity(totalAllotQuantity);
             setTotalQuantity(totalQuantity); 
             setTotalReceiveQuantity(totalReceiverQuantity); 
             setTotaldisrtributedQuantity(totaldistributedquantity); 
             setTotalpurchaseQuantity(totalpurchaseQuantity);
             setTotalfarmersBenifited(totalfarmersbenefited); 
            }
            else{
                const result = await API_Auth.getDistrictCropSeasonwiseSeedData(data[0].CropName,value,season);
                console.log("resultseedata", result);    
            var totalarrayexcel=[]
            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    console.log("result[key]",result[key].length)
                    console.log(key);
                    for(var i=0;i<result[key].length; i++){
                    let data = {
                        District:result[key][i][0].DistrictName,
                        Variety: key,
                        Quantity:result[key][i][0].Quantity,
                        Alloted:result[key][i][0].AllocatedQuantity,
                        Purchased:result[key][i][0].purchaseDemandQuantity,
                        Distributed:result[key][i][0].v_disrtributedQuantity,
                        Farmers:result[key][i][0].farmersBenifited,
                        ReceivedQuantity:result[key][i][0].ReceivedQuantity
                    }
                    totalarrayexcel.push(data) 
                }
                }
            }
            console.log("totalarrayexcel", totalarrayexcel)
            setExceldata(totalarrayexcel);             
            var totalarray = []
            setIsLoading(false);
            var varietiesarray = []
            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    console.log("key", key);
                    let data = {
                        vname: key,
                        color: 'rgb(' + colorGen() + ',' + colorGen() + ',' + colorGen() + ')'
                    }
                    varietiesarray.push(data)
                }
            }
            console.log("varietiesarray", varietiesarray)
            setVarieties(varietiesarray);
            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    let data = {
                        vname: key,
                        vdata: result[key]
                    }
                    totalarray.push(data)
                }
            }
            console.log("totalArray", totalarray);
            setDashbaordSeedWiseData(totalarray);
            let totalAllotQuantity = 0;
            let totalReceiverQuantity = 0;
            let totalpurchaseQuantity = 0;
            let totalfarmersbenefited = 0;
            let totaldistributedquantity = 0;
            let totalQuantity = 0;

            for(let i=0; i<totalarray.length; i++){
                console.log("totalarray[i]?.vdata[0][0]?.AllocatedQuantity", totalarray[i]?.vdata[0][0]?.AllocatedQuantity);
                if(totalarray[i]?.vdata[0][0]?.AllocatedQuantity){
                 totalAllotQuantity = totalAllotQuantity+parseInt(totalarray[i]?.vdata[0][0]?.AllocatedQuantity);
                }
              if(totalarray[i]?.vdata[0][0]?.AllocatedQuantity){
                 totalReceiverQuantity = totalReceiverQuantity+parseInt(totalarray[i]?.vdata[0][0]?.AllocatedQuantity);
              }
              if(totalarray[i]?.vdata[0][0]?.purchaseDemandQuantity){
                 totalpurchaseQuantity = totalpurchaseQuantity+parseInt(totalarray[i]?.vdata[0][0]?.purchaseDemandQuantity);
              }
              if(totalarray[i]?.vdata[0][0]?.farmersBenifited){
                 totalfarmersbenefited = totalfarmersbenefited+parseInt(totalarray[i]?.vdata[0][0]?.farmersBenifited);
              }
              if(totalarray[i]?.vdata[0][0]?.Quantity){
                 totalQuantity = totalQuantity+parseInt(totalarray[i]?.vdata[0][0]?.Quantity);
              }
              if(totalarray[i]?.vdata[0][0]?.v_disrtributedQuantity){
                 totaldistributedquantity = totaldistributedquantity+parseInt(totalarray[i]?.vdata[0][0]?.v_disrtributedQuantity);
              }
             }
             console.log(totalAllotQuantity);
             console.log(totalQuantity);
             console.log(totalReceiverQuantity);
             console.log(totaldistributedquantity);
             console.log(totalpurchaseQuantity);
             console.log(totalfarmersbenefited);

             setTotalAllotQuantity(totalAllotQuantity);
             setTotalQuantity(totalQuantity); 
             setTotalReceiveQuantity(totalReceiverQuantity); 
             setTotaldisrtributedQuantity(totaldistributedquantity); 
             setTotalpurchaseQuantity(totalpurchaseQuantity);
             setTotalfarmersBenifited(totalfarmersbenefited); 
            }
            }
        if (name == "seedId") {
            setSeedId(value);
            const data = seedData.filter((item) => item.id == value);
            setIsLoading(true);
            if(season==''){
            const result = await API_Auth.getDistrictCropwiseSeedData(data[0].CropName,districtId);
            setIsLoading(false);
            var totalarrayexcel=[]
            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    console.log("result[key]",result[key].length)
                    console.log(key);
                    for(var i=0;i<result[key].length; i++){
                    let data = {
                        District:result[key][i][0].DistrictName,
                        Variety: key,
                        Quantity:result[key][i][0].Quantity,
                        Alloted:result[key][i][0].AllocatedQuantity,
                        Purchased:result[key][i][0].purchaseDemandQuantity,
                        Distributed:result[key][i][0].v_disrtributedQuantity,
                        Farmers:result[key][i][0].farmersBenifited,
                        ReceivedQuantity:result[key][i][0].ReceivedQuantity
                    }
                    totalarrayexcel.push(data) 
                }
                }
            }
            console.log("totalarrayexcel", totalarrayexcel)
            setExceldata(totalarrayexcel);   
            var totalarray = []
            var varietiesarray = []
            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    console.log("key", key);
                    let data = {
                        vname: key,
                        color: 'rgb(' + colorGen() + ',' + colorGen() + ',' + colorGen() + ')'
                    }
                    varietiesarray.push(data)
                }
            }
            console.log("varietiesarray", varietiesarray)
            setVarieties(varietiesarray);
            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    let data = {
                        vname: key,
                        vdata: result[key]
                    }
                    totalarray.push(data)
                }
            }
            console.log("totalArray", totalarray);
            setDashbaordSeedWiseData(totalarray);
            let totalAllotQuantity = 0;
            let totalReceiverQuantity = 0;
            let totalpurchaseQuantity = 0;
            let totalfarmersbenefited = 0;
            let totaldistributedquantity = 0;
            let totalQuantity = 0;

            for(let i=0; i<totalarray.length; i++){
                console.log("totalarray[i]?.vdata[0][0]?.AllocatedQuantity", totalarray[i]?.vdata[0][0]?.AllocatedQuantity);
                if(totalarray[i]?.vdata[0][0]?.AllocatedQuantity){
                 totalAllotQuantity = totalAllotQuantity+parseInt(totalarray[i]?.vdata[0][0]?.AllocatedQuantity);
                }
              if(totalarray[i]?.vdata[0][0]?.AllocatedQuantity){
                 totalReceiverQuantity = totalReceiverQuantity+parseInt(totalarray[i]?.vdata[0][0]?.AllocatedQuantity);
              }
              if(totalarray[i]?.vdata[0][0]?.purchaseDemandQuantity){
                 totalpurchaseQuantity = totalpurchaseQuantity+parseInt(totalarray[i]?.vdata[0][0]?.purchaseDemandQuantity);
              }
              if(totalarray[i]?.vdata[0][0]?.farmersBenifited){
                 totalfarmersbenefited = totalfarmersbenefited+parseInt(totalarray[i]?.vdata[0][0]?.farmersBenifited);
              }
              if(totalarray[i]?.vdata[0][0]?.Quantity){
                 totalQuantity = totalQuantity+parseInt(totalarray[i]?.vdata[0][0]?.Quantity);
              }
              if(totalarray[i]?.vdata[0][0]?.v_disrtributedQuantity){
                 totaldistributedquantity = totaldistributedquantity+parseInt(totalarray[i]?.vdata[0][0]?.v_disrtributedQuantity);
              }
             }
             setTotalAllotQuantity(totalAllotQuantity);
             setTotalQuantity(totalQuantity); 
             setTotalReceiveQuantity(totalReceiverQuantity); 
             setTotaldisrtributedQuantity(totaldistributedquantity); 
             setTotalpurchaseQuantity(totalpurchaseQuantity);
             setTotalfarmersBenifited(totalfarmersbenefited); 
        }
        else{
            const result = await API_Auth.getDistrictCropSeasonwiseSeedData(data[0].CropName,districtId,season);
            console.log("resultseedata", result);    
            setIsLoading(false);
            var totalarrayexcel=[]
            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    console.log("result[key]",result[key].length)
                    console.log(key);
                    for(var i=0;i<result[key].length; i++){
                    let data = {
                        District:result[key][i][0].DistrictName,
                        Variety: key,
                        Quantity:result[key][i][0].Quantity,
                        Alloted:result[key][i][0].AllocatedQuantity,
                        Purchased:result[key][i][0].purchaseDemandQuantity,
                        Distributed:result[key][i][0].v_disrtributedQuantity,
                        Farmers:result[key][i][0].farmersBenifited,
                        ReceivedQuantity:result[key][i][0].ReceivedQuantity
                    }
                    totalarrayexcel.push(data) 
                }
                }
            }
            console.log("totalarrayexcel", totalarrayexcel)
            setExceldata(totalarrayexcel);   
            var totalarray = []
            var varietiesarray = []
            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    console.log("key", key);
                    let data = {
                        vname: key,
                        color: 'rgb(' + colorGen() + ',' + colorGen() + ',' + colorGen() + ')'
                    }
                    varietiesarray.push(data)
                }
            }
            console.log("varietiesarray", varietiesarray)
            setVarieties(varietiesarray);
            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    let data = {
                        vname: key,
                        vdata: result[key]
                    }
                    totalarray.push(data)
                }
            }
            console.log("totalArray", totalarray);
            setDashbaordSeedWiseData(totalarray);
            let totalAllotQuantity = 0;
            let totalReceiverQuantity = 0;
            let totalpurchaseQuantity = 0;
            let totalfarmersbenefited = 0;
            let totaldistributedquantity = 0;
            let totalQuantity = 0;

            for(let i=0; i<totalarray.length; i++){
                console.log("totalarray[i]?.vdata[0][0]?.AllocatedQuantity", totalarray[i]?.vdata[0][0]?.AllocatedQuantity);
                if(totalarray[i]?.vdata[0][0]?.AllocatedQuantity){
                 totalAllotQuantity = totalAllotQuantity+parseInt(totalarray[i]?.vdata[0][0]?.AllocatedQuantity);
                }
              if(totalarray[i]?.vdata[0][0]?.AllocatedQuantity){
                 totalReceiverQuantity = totalReceiverQuantity+parseInt(totalarray[i]?.vdata[0][0]?.AllocatedQuantity);
              }
              if(totalarray[i]?.vdata[0][0]?.purchaseDemandQuantity){
                 totalpurchaseQuantity = totalpurchaseQuantity+parseInt(totalarray[i]?.vdata[0][0]?.purchaseDemandQuantity);
              }
              if(totalarray[i]?.vdata[0][0]?.farmersBenifited){
                 totalfarmersbenefited = totalfarmersbenefited+parseInt(totalarray[i]?.vdata[0][0]?.farmersBenifited);
              }
              if(totalarray[i]?.vdata[0][0]?.Quantity){
                 totalQuantity = totalQuantity+parseInt(totalarray[i]?.vdata[0][0]?.Quantity);
              }
              if(totalarray[i]?.vdata[0][0]?.v_disrtributedQuantity){
                 totaldistributedquantity = totaldistributedquantity+parseInt(totalarray[i]?.vdata[0][0]?.v_disrtributedQuantity);
              }
             }
             setTotalAllotQuantity(totalAllotQuantity);
             setTotalQuantity(totalQuantity); 
             setTotalReceiveQuantity(totalReceiverQuantity); 
             setTotaldisrtributedQuantity(totaldistributedquantity); 
             setTotalpurchaseQuantity(totalpurchaseQuantity);
             setTotalfarmersBenifited(totalfarmersbenefited); 
        }
        }

    }

    const handleSearch = (e) => {
        let filterValue = e.target.value;
        setCurrentPage(0);
        if (filterValue == '') {
            let offsetsearch = 0;
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setTableData(slice);
            setSearchData(orgtableData);
            setOffSet(offsetsearch);
            setCurrentPage(0);
            setSearchKey('n');
        }
        else {
            let offsetsearch = 0;
            const filteredData = orgtableData.filter(item => {
                return (item ?.districtname ?.toLowerCase().includes(filterValue.toLowerCase()))
            });

            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            setOffSet(offsetsearch);
            setSearchKeyTable(filteredData);
            setTableData(slice);
            setSearchData(filteredData);
        }
    }
    useEffect(() => {
        setIsLoading(true);
        const role=localStorage.getItem('loggedRole')
        console.log("role",role)
        setRole(role)

        const fetchSeedWise = async () => {
            let resultdata = await API_Auth.getSeeds();
            setSeedId(resultdata[0].id);
            setSeedData(resultdata);
            let districtdata = await API_Auth.getDistricts();
            console.log(districtdata);
            var districtID = '';
            const DistrictID = localStorage.getItem("DistrictID");
            if(role.toLocaleLowerCase()=="mis"){
               districtID = DistrictID;
               setDistrictId(DistrictID);
            }
            else{
                districtID = districtdata[0].id;
                setDistrictId(districtdata[0].id);
            }
            console.log(districtID);
            const seasonresult = await API_Auth.getSeasons();
            setSeasonData(seasonresult)
            setDistrictsSelectData(districtdata);
            const result = await API_Auth.getDistrictCropwiseSeedData(resultdata[0].CropName,districtID);
            console.log("resultseedata", result);
            var totalarrayexcel=[]
            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    console.log("result[key]",result[key].length)
                    console.log(key);
                    for(var i=0;i<result[key].length; i++){
                    let data = {
                        District:result[key][i][0].DistrictName,
                        Variety: key,
                        Quantity:result[key][i][0].Quantity,
                        Alloted:result[key][i][0].AllocatedQuantity,
                        Purchased:result[key][i][0].purchaseDemandQuantity,
                        Distributed:result[key][i][0].v_disrtributedQuantity,
                        Farmers:result[key][i][0].farmersBenifited,
                        ReceivedQuantity:result[key][i][0].ReceivedQuantity
                    }
                    totalarrayexcel.push(data) 
                }
                }
            }
            console.log("totalarrayexcel", totalarrayexcel)
            setExceldata(totalarrayexcel); 
            var totalarray = []
            setIsLoading(false);
            var varietiesarray = []
            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    console.log("key", key);
                    let data = {
                        vname: key,
                        color: 'rgb(' + colorGen() + ',' + colorGen() + ',' + colorGen() + ')'
                    }
                    varietiesarray.push(data)
                }
            }
            console.log("varietiesarray", varietiesarray)
            setVarieties(varietiesarray);
            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    let data = {
                        vname: key,
                        vdata: result[key]
                    }
                    totalarray.push(data)
                }
            }
            console.log("totalArray", totalarray);
            setDashbaordSeedWiseData(totalarray);
            let totalAllotQuantity = 0;
            let totalReceiverQuantity = 0;
            let totalpurchaseQuantity = 0;
            let totalfarmersbenefited = 0;
            let totaldistributedquantity = 0;
            let totalQuantity = 0;

            for(let i=0; i<totalarray.length; i++){
                console.log("totalarray[i]?.vdata[0][0]?.AllocatedQuantity", totalarray[i]?.vdata[0][0]?.AllocatedQuantity);
                if(totalarray[i]?.vdata[0][0]?.AllocatedQuantity){
                 totalAllotQuantity = totalAllotQuantity+parseInt(totalarray[i]?.vdata[0][0]?.AllocatedQuantity);
                }
              if(totalarray[i]?.vdata[0][0]?.AllocatedQuantity){
                 totalReceiverQuantity = totalReceiverQuantity+parseInt(totalarray[i]?.vdata[0][0]?.AllocatedQuantity);
              }
              if(totalarray[i]?.vdata[0][0]?.purchaseDemandQuantity){
                 totalpurchaseQuantity = totalpurchaseQuantity+parseInt(totalarray[i]?.vdata[0][0]?.purchaseDemandQuantity);
              }
              if(totalarray[i]?.vdata[0][0]?.farmersBenifited){
                 totalfarmersbenefited = totalfarmersbenefited+parseInt(totalarray[i]?.vdata[0][0]?.farmersBenifited);
              }
              if(totalarray[i]?.vdata[0][0]?.Quantity){
                 totalQuantity = totalQuantity+parseInt(totalarray[i]?.vdata[0][0]?.Quantity);
              }
              if(totalarray[i]?.vdata[0][0]?.v_disrtributedQuantity){
                 totaldistributedquantity = totaldistributedquantity+parseInt(totalarray[i]?.vdata[0][0]?.v_disrtributedQuantity);
              }
             }
             setTotalAllotQuantity(totalAllotQuantity);
             setTotalQuantity(totalQuantity); 
             setTotalReceiveQuantity(totalReceiverQuantity); 
             setTotaldisrtributedQuantity(totaldistributedquantity); 
             setTotalpurchaseQuantity(totalpurchaseQuantity);
             setTotalfarmersBenifited(totalfarmersbenefited); 
        };
        
        // call the function
        fetchSeedWise()
            // make sure to catch any error
            .catch(console.error);
    }, []);
    const handlerowsperPage = (e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(searchKeyTable);
        }
        else {
            const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(orgtableData);
        }
    }
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        const data = orgtableData;
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setOffSet(offsetdata);
            setSearchData(searchKeyTable);
        } else {
            const slice = data.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(data.length / perPage))
            setOffSet(offsetdata);
            setTableData(slice);
            setSearchData(data);
        }
    }
    const formatter = new Intl.NumberFormat('en-IN')


    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}

            <Container>
                <Row>
                    <Col lg={2} style={{ paddingLeft: 0,marginTop:25 }}>
                        <Title>Component Wise</Title>
                    </Col>
                    <Col lg={1} style={{ paddingLeft: 0 ,marginTop:25}}>
                        <p style={{ marginTop: 23, fontWeight: 'bold' }}> Filter By</p>
                    </Col>
                    <Col lg={2}>
                    <label>Season</label>

                        <div className="row_div">
                            <div className="input_div" >
                                <select value={season} onChange={handleInput} name="season"
                                    style={{ margin: 0, background: 'transparent' }}>
                                    <option value="">All</option>
                                    {seasonData.length != 0 && <>
                                        {seasonData.map((item, index) => {
                                            return (
                                                <option value={item.CombYearSeason}>{item.CombYearSeason}</option>
                                            )
                                        })}
                                    </>}

                                </select>
                            </div>
                        </div>
                    </Col>
                    <Col lg={2}>
                    <label>Component</label>
                        <div className="row_div">
                            <div className="input_div" >

                                <select
                                    name="seedId"
                                    value={seedId}
                                    onChange={handleInput}
                                    style={{ margin: 0, background: 'transparent' }}
                                    >
                                    {seedData.length != 0 &&
                                        <>
                                            {seedData.map((item, index) => {
                                             return (
                                              <option value={item.id}>{item.CropName}</option>
                                                )
                                            })}
                                        </>}
                                </select>

                            </div>

                        </div>
                    </Col>
                    {role.toLocaleLowerCase()=="mis" &&
                    <Col lg={3}>
                        </Col>
                    }
                                        {role.toLocaleLowerCase()=="ao_mis" &&
                                        <>
                                        <Col lg={1}>
                                        </Col>
                
                    <Col lg={2}>
                    <label>District</label>
                    <div className="row_div">
                            <div className="input_div" >
                                <select
                                    name="districtId"
                                    value={districtId}
                                    onChange={handleInput}
                                    style={{ margin: 0, background: 'transparent' }}
                                    >
                                    {selectdistrictData.length != 0 &&
                                           <>
                                            {selectdistrictData.map((item, index) => {
                                             return (
                                              <option value={item.id}>{item.v_districtName}</option>
                                                )
                                            })}
                                        </>}
                                </select>
                            </div>
                        </div>
                    </Col>
                    </>
                 }

                    {role.toLocaleLowerCase()=="ao" &&
                    <>
                                        <Col lg={1}>
                                        </Col>
                
                    <Col lg={2}>
                    <label>District</label>
                    <div className="row_div">
                            <div className="input_div" >
                                <select
                                    name="districtId"
                                    value={districtId}
                                    onChange={handleInput}
                                    style={{ margin: 0, background: 'transparent' }}
                                    >
                                    {selectdistrictData.length != 0 &&
                                           <>
                                            {selectdistrictData.map((item, index) => {
                                             return (
                                              <option value={item.id}>{item.v_districtName}</option>
                                                )
                                            })}
                                        </>}
                                </select>
                            </div>
                        </div>
                    </Col>
                    </>
                 }
                    <Col lg={2} style={{marginTop:25}}>
                        <button
                            className="export_button"
                            onClick={(e) => exportToCSV(exceldata)}>
                            <p className="pcat_title">Export Excel</p>
                        </button>
                    </Col>
                </Row>
            </Container>
            <Container>
            <Row>
               <Col lg={12} sm={6} md={6} className="table_head" style={{background:'none'}}>
                <Table bordered responsive>
                    <thead>
                        <tr>
                              <th style={{
                                borderLeft: "2px solid #084298",
                                borderTop: "2px solid #084298",
                                textAlign:'center'
                                             }}>Type</th> 
                                                <th style={{
                                             borderTop: "2px solid #084298",
                                             borderLeft: "2px solid #084298",
                                                  textAlign:'center',
                                                }}>Allotted</th>
                                               <th style={{
                                                   borderTop: "2px solid #084298",
                                                  textAlign:'center',
                                                }}>Purchased</th>
                                                <th style={{
                                              borderTop: "2px solid #084298",
                                                  textAlign:'center',
                                                }}>Received</th>
                                               <th style={{textAlign:'center',
                                                   borderTop: "2px solid #084298",
                                                }}>Available Stock</th>
                                                <th style={{
                                               borderTop: "2px solid #084298",
                                                  textAlign:'center',
                                                }}>Distributed</th>
                                                <th style={{textAlign:'center',
                                                   borderTop: "2px solid #084298",
                                                    borderRight: "2px solid #084298"
                                                }}>Farmers</th>
                        </tr>
                    </thead>
                    {dashbaordSeedWisedata.length == 0 && <tbody>
                                <tr>
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                    {dashbaordSeedWisedata.length != 0 && <tbody>
                                {dashbaordSeedWisedata.map((item, index) => {
                                    return (
                                        <>
                                     <tr key={index}>
                                       <td style={{
                                borderLeft: "2px solid #084298",
                                borderRight: "2px solid #084298",
                                background:'#dee9ed'
                            }}>{item.vname}</td>
                                        <td>{item.vdata[0] != undefined ? formatter.format(item.vdata[0][0].AllocatedQuantity) : ''}</td>
                                        <td>{item.vdata[0] != undefined ? formatter.format(item.vdata[0][0].purchaseDemandQuantity) : ''}</td>
                                        <td>{item.vdata[0] != undefined ? formatter.format(item.vdata[0][0].ReceivedQuantity) : ''}</td>
                                        <td>{item.vdata[0] != undefined ? formatter.format(item.vdata[0][0].Quantity) : ''}</td>
                                        <td>{item.vdata[0] != undefined ? formatter.format(item.vdata[0][0].v_disrtributedQuantity) : ''}</td>
                                        <td style={{textAlign:'center',borderRight: "2px solid #084298"}}>
                                        {item.vdata[0] != undefined ? formatter.format(item.vdata[0][0].farmersBenifited) : ''}</td>
                        </tr>
                    </>
                    );
                })}
                 <tr style={{border:'none'}}>
                                                <td className="ptotal_title" style={{border:'none'}}>Total:</td>
                                                <td className="ptotal_title" style={{border:'none'}}>{formatter.format(totalAllotQuantity)}</td>
                                                <td className="ptotal_title" style={{border:'none'}}>{formatter.format(totalpurchaseDemandQuantity)}</td>
                                                <td className="ptotal_title" style={{border:'none'}}>{formatter.format(totalreceivedQuntity)}</td>
                                                <td className="ptotal_title" style={{border:'none'}}>{formatter.format(totalQuantity)}</td>
                                                <td className="ptotal_title" style={{border:'none'}}>{formatter.format(totaldisrtributedQuantity)}</td>
                                                <td className="ptotal_title" style={{border:'none'}}>{formatter.format(totalfarmersBenifited)}</td>
                                            </tr>
            </tbody>
            }
                </Table>
                 </Col>
                </Row>
            </Container >


            {/*   <Container>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
                       {tableData.length != 0 &&
                            <div>
                                <div className="pagination_row_div">
                                    <p className="pagination_title">Showing items per page:</p>
                                    <div className="pagination_input_div" >
                                        <select className="pagination_select"
                                            style={{ margin: 0, background: 'transparent' }}
                                            name="pagination_value"
                                            value={perPage}
                                            onChange={handlerowsperPage} >
                                            <option value={4}>4</option>
                                            <option value={8}>8</option>
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="pagination_row_right_div">
                                    <p className="pagination_total">Showing {offset + 1} to {searchData.length < offset + perPage &&
                                        <span>{searchData.length}</span>}
                                        {searchData.length > offset + perPage &&
                                            <span>{offset + perPage} </span>} of {searchData.length} items</p>
                                    <ReactPaginate
                                        previousLabel={<ArrowBackIosIcon className="pagination_prev_btn" color="#2438a7" />}
                                        nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7" />}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={currentPage} />
                                </div>
                            </div>
                        }</Col>
                </Row>
            </Container>
        */}     <ToastContainer />
        </Wrapper >
    )
}
export default DashboardSeedWise2