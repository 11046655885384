import React, { useState, useEffect, useContext, useRef } from "react";
import { UserContext } from "../../context";
import { Wrapper, Label, Input, Switch } from "./styles";
import API_Auth from "../../API_Auth";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingSpinner } from "../LoadingSpinner/index";
import { Container, Row, Col } from "react-bootstrap";
import { base_url } from "../../config";

const FarmerApplicatioView = () => {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const { isExpanded } = useContext(UserContext);
  const location = useLocation();
  const [FarmerDetails, setFarmerDetails] = useState(
    location?.state?.farmerDetails
  );
  const [approvedArea, setApprovedArea] = useState(
    location?.state?.famerdetails?.Area
  );
  const [approvedAreaErr, setApprovedAreaErr] = useState("");
  const [applicantType, setApplicantType] = useState("");
  const [applications, setApplications] = useState([]);
  const [declartion, setDeclariton] = useState("");
  const [farmerName, setFarmerName] = useState("");
  const [organisation, setOrganisation] = useState(false);
  const [description, setDescription] = useState(false);
  const [applicationData, setApplicationData] = useState("");
  const [activityData, setActivityData] = useState([]);
  const[activities,setActivities]=useState([]);
  const[schemes,setSchemes]=useState([]);
  const[activityName,setActivityName]=useState('');
  const[schemeName,setSchemeName]=useState('');
  const[qualificationshow,setQualificationShow]=useState(false);
  const[pcshow,setPcShow]=useState(false);
  const[areaDocShow,setAreaDocShow]=useState(false);
  const[qualShow,setqualShow]=useState(false)
  const[physicalcopyShow,setphysicalcopyShow]=useState(false)
 const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [role, setRole] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const role = localStorage.getItem("loggedRole");
      setRole(role);

      let applicants = await API_Auth.getApplicants();
      setApplications(applicants);

      setIsLoading(true);

      const result = await API_Auth.getFarmerDetailsByApplicationNO(
        location?.state.famerdetails.ApllicationNo
      );
      console.log("finalresult", result);
      setApplicationData(result.applicationData?.[0]);
      setActivityData(result.activityData);

      var tpc = result.applicationData ?.[0].pc;
      if (tpc.indexOf("http://") == 0 || tpc.indexOf("https://") == 0) {
          // do something here
         setPcShow(true)
      }else{
        setPcShow(false)
      }
      var tarea = result.applicationData ?.[0].Areadoc;
      if (tarea.indexOf("http://") == 0 || tarea.indexOf("https://") == 0) {
          // do something here
         setAreaDocShow(true)
      }else{
        setAreaDocShow(false)
      }
  
      var tqual = result.applicationData ?.[0].QualificationDoc;
      if (tqual.indexOf("http://") == 0 || tqual.indexOf("https://") == 0) {
          // do something here
         setqualShow(true)
      }else{
        setqualShow(false)
      }
      var tpccopy = result.applicationData ?.[0].phisicalcopy;
      if (tpccopy.indexOf("http://") == 0 || tpccopy.indexOf("https://") == 0) {
          // do something here
         setphysicalcopyShow(true)
      }else{
        setphysicalcopyShow(false)
      }
  

      setIsLoading(false);

      var newArray = applicants.filter(function (el) {
        return el.id == result.applicationData?.[0].ApplicantType;
      });
      setApplicantType(newArray[0].ApplicantName);
      let activities = await API_Auth.getActivities();
      setActivities(activities);

      var newArray1 = activities.filter(function (el) {
        return el.id == result.applicationData?.[0].activityID;
      });
      setActivityName(newArray1[0].HortiActivity);
      let schemes = await API_Auth.getSchemes();
      setSchemes(schemes);

      var newArray2 = schemes.filter(function (el) {
        return el.id == result.applicationData?.[0].ScheameID;
      });
      setSchemeName(newArray2[0].TypeOfScheme);
      if (
        newArray[0].id.toString() === "2" ||
        newArray[0].id.toString() === "3" ||
        newArray[0].id.toString() === "4" ||
        newArray[0].id.toString() === "5" ||
        newArray[0].id.toString() === "6" ||
        newArray[0].id.toString() === "7" ||
        newArray[0].id.toString() === "8"

      ) {
        setOrganisation(true);
        setDescription(false);
      } else if (newArray[0].id.toString() === "9") {
        setDescription(true);
        setOrganisation(false);
      } else {
        setOrganisation(false);
        setDescription(false);
      }
    };
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, [location?.state.famerdetails]);
  const handleInput = async (e) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;
    if (name === "approvedArea") {
      setApprovedArea(value);
    }
  };

  return (
    <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
      <Container>
        <Row className="row_title">
          <Col lg={4}>
            <p>Farmer Application Details</p>
          </Col>
          <Col lg={6}></Col>
        </Row>
      </Container>
      {isLoading && <LoadingSpinner />}

      <hr />

      <Container>
        <Row>
          <Col lg={5} md={5} sm={5}></Col>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label></label>
              <br />

              <label className="custom-file-uploaded">
                <div>
                  <img
                    src={pcshow==true  ? applicationData.pc:base_url + "/images?filename=" + applicationData.pc}
                    style={{ height: 100, width: 100, margin: 10 }}
                  />
                </div>
              </label>
            </div>
          </Col>
        </Row>

        <hr />
        <Row>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Farmer Name</label>
              <br />
              <input
                disabled
                value={applicationData.farmerName}
                style={{ width: "100%" }}
              />
            </div>
          </Col>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Adhar No</label>
              <br />
              <input
                disabled
                value={applicationData?.aadhar}
                style={{ width: "100%" }}
              />
            </div>
          </Col>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Mobile Number</label>
              <br />
              <input
                disabled
                value={applicationData?.farmerCno}
                style={{ width: "100%" }}
              />
            </div>
          </Col>
        </Row>
        <hr/>
        <Row>
        <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Activity Name</label>
              <br />
              <input
                disabled
                value={activityName }
                style={{ width: "100%" }}
              />
            </div>
          </Col>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Scheme</label>
              <br />
              <input
                disabled
                value={schemeName}
                style={{ width: "100%" }}
              />
            </div>
          </Col>
        </Row>
        {/* <Row>
          <Col lg={12} sm={12} md={12} className="table_head">
            <Table className="tablelist" >
              <thead>
                <tr>
                
                  <th>Activity Name</th>
                  <th>Scheme Name </th>
                 </tr>
              </thead>
              {activityData.length == 0 && (
                <tbody>
                  <tr>
                    <td colSpan="12">
                      <p className="no_Data_table">No Data Found</p>
                    </td>
                  </tr>
                </tbody>
              )}
              {activityData.length != 0 && (
                <tbody>
                  {activityData.map((obj, index) => {
                    return (
                      <>
                        <tr key={index}>
                            <td>{obj.activityName}</td>
                            <td>{obj.scheameName}</td>
                            


                          </tr>
                      </>
                    );
                  })}
                </tbody>
              )}
            </Table>
        
        </Col>
        </Row>
       */}  <hr/>
         <Row>
        <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Applicant</label>
              <br />
              <input disabled value={applicantType} style={{ width: "90%" }} />
            </div>
          </Col>
          {description === true && (
            <>
              <Col lg={4} md={4} sm={4}>
                <div className="inputdiv">
                  <label>Description</label>
                  <br />
                  <input
                    value={applicationData?.nameOfOrg}
                    disabled
                    style={{ width: "100%" }}
                  />
                </div>
              </Col>
            </>
          )}
          {organisation == true && (
            <>
              <Col lg={4} md={4} sm={4}>
                <div className="inputdiv">
                  <label>Organisation Name</label>
                  <br />
                  <input
                    value={applicationData?.nameOfOrg}
                    disabled
                    style={{ width: "100%" }}
                  />
                </div>
              </Col>
              <Col lg={4} md={4} sm={4}>
                <div className="inputdiv">
                  <label>Organisation Address</label>
                  <br />
                  <input
                    value={applicationData?.OrgAdd}
                    disabled
                    style={{ width: "100%" }}
                  />
                </div>
              </Col>
              <Col lg={4} md={4} sm={4}>
                <div className="inputdiv">
                  <label>Mobile Number</label>
                  <br />
                  <input
                    value={applicationData?.Orgcontact}
                    disabled
                    style={{ width: "90%" }}
                  />
                </div>
              </Col>
              <Col lg={4} md={4} sm={4}>
                <div className="inputdiv">
                  <label>Pincode</label>
                  <br />
                  <input
                    value={applicationData?.pincode}
                    disabled
                    style={{ width: "100%" }}
                  />
                </div>
              </Col>
            </>
          )}


          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Age</label>
              <br />
              <input
                disabled
                value={applicationData?.Age}
                style={{ width: "90%" }}
              />
            </div>
          </Col>
          </Row>
          <hr/>
          <Row>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Area</label>
              <br />
              <input
                value={applicationData?.Area}
                disabled
                style={{ width: "100%" }}
              />
            </div>
          </Col>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Plot No</label>
              <br />
              <input
                value={applicationData?.PlotNo}
                disabled
                style={{ width: "100%" }}
              />
            </div>
          </Col>

          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Area Doc</label>
              <br />
              <label className="custom-file-uploaded">
                <div className="inputdiv">
                  <img
                    src={areaDocShow==true ? applicationData?.Areadoc:
                      base_url +
                      "/images?filename=" +
                      applicationData?.Areadoc
                    }
                    style={{
                      height: 100,
                      width: 100,
                      margin: 10,
                      border: "2px solid rgb(223, 223, 223)",
                      borderRadius: 6,
                    }}
                  />
                </div>
              </label>
            </div>
          </Col>

          
            </Row>
            <hr/>

        <Row>
          
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Qualification</label>
              <br />
              <input
                disabled
                value={applicationData?.Qualification}
                style={{ width: "100%" }}
              />
            </div>
          </Col>
          {applicationData?.Qualification !="Non-Matric" &&<Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Qualification Doc</label>
              <br />
              <label className="custom-file-uploaded">
                <div className="inputdiv">
                  <img
                    src={qualShow==true ?  applicationData?.QualificationDoc :
                      base_url +
                      "/images?filename=" +
                      applicationData?.QualificationDoc
                    }
                    style={{
                      height: 100,
                      width: 100,
                      margin: 10,
                      border: "2px solid rgb(223, 223, 223)",
                      borderRadius: 6,
                    }}
                  />
                </div>
              </label>
            </div>
          </Col>
}
          </Row>
          <hr/>
          <Row>
         
          
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Bank Name</label>
              <br />
              <input
                value={applicationData?.BankName}
                disabled
                style={{ width: "100%" }}
              />
            </div>
          </Col>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Bank Branch</label>
              <br />
              <input
                value={applicationData?.Branch}
                disabled
                style={{ width: "90%" }}
              />
            </div>
          </Col>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Bank IFSC Code</label>
              <br />
              <input
                value={applicationData?.BranchIfsc}
                disabled
                style={{ width: "90%" }}
              />
            </div>
          </Col>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Bank Account No</label>
              <br />
              <input
                value={applicationData?.AccountNo}
                disabled
                style={{ width: "100%" }}
              />
            </div>
          </Col>
          </Row>
          <Row>
          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Physical Copy</label>
              <br />
              <label className="custom-file-uploaded">
                <div className="inputdiv">
                  <img
                    src={physicalcopyShow ==true ?applicationData?.phisicalcopy:
                      base_url +
                      "/images?filename=" +
                      applicationData?.phisicalcopy
                    }
                    style={{
                      height: 100,
                      width: 100,
                      margin: 10,
                      border: "2px solid rgb(223, 223, 223)",
                      borderRadius: 6,
                    }}
                  />
                </div>
              </label>
            </div>
          </Col>
            </Row>
            <hr/>

          <Row>

          <Col lg={4} md={4} sm={4}>
            <div className="inputdiv">
              <label>Approved by DHO (Area)</label>
              <br />
              <input
                name="approvedArea"
                disabled
                value={applicationData?.ApprovedArea}
                onChange={handleInput}
                style={{ width: "90%" }}
              />
              
            </div>
          </Col>
        </Row>
      </Container>

      <ToastContainer />
    </Wrapper>
  );
};
export default FarmerApplicatioView;
