import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./DemandRequestSupplier.styles"
import { Row, Container, Col } from "react-bootstrap"
import API_Auth from "../../API_Auth";
import { LoadingSpinner } from "../LoadingSpinner/index";
import { AiOutlineSearch } from "react-icons/ai";
import { Title } from "../../GlobalStyle";
import Edit from "../../images/edit.png";
import Delete from "../../images/delete.png"
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const DemandRequestSupplier = () => {
    const {
        isExpanded,
    } = useContext(UserContext);

    const [isLoading, setIsLoading] = useState(false)
    const [roles, setRoles] = useState([]);
    const navigate = useNavigate();


    const [searchData, setSearchData] = useState([]);
    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(50);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey, setSearchKey] = useState('n');
    const [searchKeyTable, setSearchKeyTable] = useState('');
    const [suppliers, setSuppliers] = useState([]);
    const [supplierID, setSuppliersID] = useState([]);
    const [suppliersList, setSuppliersList] = useState([]);
    const[seasonData,setSeasonData]=useState([]);
    const[season,setSeason]=useState('')





    useEffect(() => {
        setIsLoading(true);
        const fetchSuppliers = async () => {
            const seasonsresult=await API_Auth.getSeasons();
            setSeasonData(seasonsresult);
            const data = await API_Auth.getSuppliers('supplier');
            setIsLoading(false);
            console.log("Data",data);
            let id=localStorage.getItem("UserID");
            console.log("id",id)


            setSuppliers(data);
            if (data.length != 0) {
                const result = await API_Auth.getDemandReuestSupplier(id,'');
                console.log(result);
                setSuppliersList(result)
                setSearchData(result);
                //seting pagenation data
                var slice = result?.slice(offset, offset + perPage);
                setPageCount(Math.ceil(result.length / perPage));
                setOrgTableData(result);
                setTableData(slice);

            }


        };
        fetchSuppliers()
            // make sure to catch any error
            .catch(console.error);
    }, []);

    const handleInput = async (e) => {
        const { name, value } = e.target;
/*      setSuppliersID(value);
        setIsLoading(true)
        const result = await API_Auth.getDemandReuestSupplier(value);
        console.log(result);
        setIsLoading(false)
        setSuppliersList(result)
        setSearchData(result);
        var slice = result.slice(offset, offset + perPage);
        setPageCount(Math.ceil(result.length / perPage));
        setOrgTableData(result);
        setTableData(slice);*/
        if(name=="season"){
            setSeason(value);
            setIsLoading(true)
            const user_id = localStorage.getItem("UserID");
            let result = await API_Auth.getDemandReuestSupplier(user_id,value);
            console.log(result);
            setSuppliersList(result);
            setSearchData(result);
            setIsLoading(false);
            //seting pagenation data
            let offset = 0;
            var slice = result.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result.length / perPage));
            setOrgTableData(result);
            setTableData(slice);
            setOffSet(offset);
            setCurrentPage(0); 
        }
    }
    const handlerowsperPage = (e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(searchKeyTable);
        }
        else {
            const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(orgtableData);
        }
    }
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        const data = orgtableData;
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setOffSet(offsetdata);
            setSearchData(searchKeyTable);
        } else {
            const slice = data.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(data.length / perPage))
            setOffSet(offsetdata);
            setTableData(slice);
            setSearchData(data);
        }
    }

    const handleSearch = (e) => {
        let filterValue = e.target.value;
        setCurrentPage(0);
        if (filterValue == '') {
            let offsetsearch = 0;
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setTableData(slice);
            setSearchData(orgtableData);
            setOffSet(offsetsearch);
            setCurrentPage(0);
            setSearchKey('n');
        }
        else {
            let offsetsearch = 0;
            const filteredData = orgtableData.filter(item => {
                return (item ?.SeedName ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                            item ?.DistrictName ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                                item ?.UserName ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                                    item ?.Status ?.toLowerCase().includes(filterValue.toLowerCase()))
            });

            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            setOffSet(offsetsearch);
            setSearchKeyTable(filteredData);
            setTableData(slice);
            setSearchData(filteredData);
        }
    }
    const exportToCSV = (csvData) => {

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        const fileExtension = '.xlsx';
        const fileName = 'Supplier Requests'

        const ws = XLSX.utils.json_to_sheet(csvData);

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        const data = new Blob([excelBuffer], { type: fileType });

        FileSaver.saveAs(data, fileName + fileExtension);
    }
    const handleDispatch = (obj) => {
        navigate("/dispatch-supplier/" + obj.PurchaseID + "/" + obj.SupplierID)
    }
    const handleView = (obj) => {
        navigate("/dispatch-individual/"+obj.PurchaseID +"/"+"dispatch");
    }

    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}
            <Container>
                <Row>
                    <Col lg={4} style={{ paddingLeft: 0,marginTop:25 }}>
                        <Title>Demand Request Supplier</Title>
                    </Col>
                    <Col lg={3} style={{ paddingLeft: 0, }}>
                    <label>Season</label>

                        <div className="row_div">
                            <div className="input_div" >
                                <select value={season} onChange={handleInput} name="season"
                                    style={{ margin: 0, background: 'transparent' }}
                                >

                                    <option value="">All</option>
                                    {seasonData.length != 0 && <>
                                        {seasonData.map((item, index) => {
                                            return (
                                                <option value={item.CombYearSeason}>{item.CombYearSeason}</option>
                                            )
                                        })}
                                    </>}

                                </select>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} style={{ paddingRight: 0,marginTop:25 }}>
                        <div className="row_div" style={{ paddingRight: 0 }}>
                            {/*                             <p className="title">Filter By:</p> */}
                            <div className="input_div" >
                                <input type="type" style={{ margin: 0, background: 'transparent' }}
                                    name="filterValue"
                                    //    value={filterValue}
                                    onChange={(e) => handleSearch(e)}
                                    placeholder="Search" />
                                <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                            </div>

                        </div>
                    </Col>
                    <Col lg={2} style={{ paddingRight: 0,marginTop:25 }}>
                        <button
                            className="export_button"
                            onClick={(e) => exportToCSV(orgtableData)}>
                            <p className="pcat_title">Export CSV</p>
                        </button>
                    </Col>
                    {/* <Col lg={3} style={{ paddingRight: 0 }}>
                        <div className="row_div">
                            <div className="input_div" >
                                <select style={{ margin: 0, background: 'transparent' }}
                                    onChange={(e) => handleInput(e)}
                                    placeholder="Search">
                                    <>
                                        {suppliers.length != 0 && <>
                                            {suppliers.map((item, index) => {
                                                return (
                                                    <option value={item.id}>{item.UserName}</option>
                                                )
                                            })}

                                        </>}
                                    </>
                                </select>
                            </div>

                        </div>
                    </Col>
 */}

                </Row>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">

                        <Table className="tablelist" responsive>
                            <thead>

                                <tr className="table_wrap">
{/*                                     <th>ID</th> */}
                                    <th>Component </th>
                                    <th>Season</th>
                                    <th >District </th>
                                    <th>Buyer</th>
                                    <th>Demand Date</th>
                                    <th>Status</th>
                                    <th style={{ textAlign: 'center' }}>Action</th>


                                </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {tableData.length != 0 && <tbody>
                                {tableData.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index} className="table_wrap">
{/*                                                 <td>{obj.PurchaseID}</td>*/}
                                        <td style={{lineBreak:"anywhere"}}>{obj.SeedName}</td>
                                        <td>{obj.year}-{obj.Season}</td>
                                                <td>{obj.DistrictName}</td>
                                                <td>{obj.UserName}</td>
                                                <td>{moment.utc(obj.Demanddate).format("DD MMM YYYY  h:mm A")}</td>
                                                {obj.Status=='open' &&  <td style={{color:'red'}}>{obj.Status}</td> }
                                    {obj.Status=='complete' &&  <td style={{color:'blue'}}>{obj.Status}</td> }
                                    {obj.Status=='inprocess' &&  <td style={{color:'green'}}>{obj.Status}</td> }
                                    {obj.Status=='PartialComplete' &&  <td style={{color:'#c5c518'}}>{obj.Status}</td> }
                                                <td style={{ textAlign: 'right' }}>
                                                    <div  style={{ display: 'flex'}}>
                                                {obj.Status=='open'&&
                                                    <div className="row_table_edit " style={{background: '#c1edc1'}} onClick={() => handleDispatch(obj)}>
                                                        <p>Dispatch</p>
                                                    </div>
                                                     }
                                                      {obj.Status=='PartialComplete'&&
                                                    <div className="row_table_edit " style={{background: '#c1edc1',marginLeft:2}} onClick={() => handleDispatch(obj)}>
                                                        <p>Dispatch</p>
                                                    </div>
                                                     }
                                                    <div className="row_table_edit " onClick={() => handleView(obj)} style={{ marginLeft:2,background: 'antiquewhite' }}>
                                                        <p>View</p>
                                                    </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                            }
                        </Table>
                        {tableData.length != 0 &&
                            <div>
                                <div className="pagination_row_div">
                                    <p className="pagination_title">Showing items per page:</p>
                                    <div className="pagination_input_div" >
                                        <select className="pagination_select"
                                            style={{ margin: 0, background: 'transparent' }}
                                            name="pagination_value"
                                            value={perPage}
                                            onChange={handlerowsperPage} >
                                            <option value={20}>20</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="pagination_row_right_div">
                                    <p className="pagination_total">Showing {offset + 1} to {searchData.length < offset + perPage &&
                                        <span>{searchData.length}</span>}
                                        {searchData.length > offset + perPage &&
                                            <span>{offset + perPage} </span>} of {searchData.length} items</p>
                                    <ReactPaginate
                                        previousLabel={<ArrowBackIosIcon className="pagination_prev_btn" color="#2438a7" />}
                                        nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7" />}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={currentPage} />
                                </div>
                            </div>
                        }

                    </Col>


                </Row>

            </Container>
            <ToastContainer />

        </Wrapper>
    )
}
export default DemandRequestSupplier