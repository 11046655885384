import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
//Styles
import "bootstrap/dist/css/bootstrap.min.css";
import UserProvider from "./context";

import "./App.css";
import { GlobalStyle } from "./GlobalStyle";
import Layout from "./components/Layout";
import Layout1 from "./components/Layout1";

import Role from "./components/Role";
import Designation from "./components/Designation";
import Department from "./components/Department";
import AddVarietyDetail from "./components/AddVarietyDetail";
import Users from "./components/Users";
import AddUser from "./components/AddUser";
import SupplyOrder from "./components/SupplyOrder";
import Variety from "./components/Variety";
import Seed from "./components/Seed";
import Forecast from "./components/Forecast";
import ForecastDetails from "./components/ForecastDetails";
import ForecastAODetails from "./components/ForecastAODetails";
import PurchaseInitiate from "./components/PurchaseInitiate";
import PurchaseList from "./components/PurchaseList";
import DistrictWiseForecast from "./components/DistrictWiseForecast";
import DemandRequestSupplier from "./components/DemandRequestSupplier";
import DispatchSupplier from "./components/DispatchSupplier";
import DispatchIndividual from "./components/DispatchIndividual";
import SeedTesting from "./components/SeedTesting";
import TestRequests from "./components/TestRequests";
import TestAgencyRequests from "./components/TestAgencyRequests";
import TransitDataByDispatch from "./components/TransitDataByDispatch";
import TransportDetailsView from "./components/TransportDetailsView";
import ReceivedList from "./components/ReceivedList";
import ReceivedQuantity from "./components/ReceivedQuantity";
import DistributorAllotmentByDao from "./components/DistributorAllotmentByDao";
import DistributorDetails from "./components/DistributorDetails";
import StockRegister from "./components/StockRegister";
import DistributorSeedHistory from "./components/DistributorSeedHistory";
import DistributionSeeds from "./components/DistributionSeeds";
import DistribureSeedAdhar from "./components/DistribureSeedAdhar";
import AddFarmer from "./components/AddFarmer";
import DashboardDistributionWise from "./components/DashboardDistributionWise";
import DashboardSeedWise from "./components/DashboardSeedWise";
import DashboardSeedWise2 from "./components/DashboardSeedWise2";
import DashboardDistrictWise from "./components/DashboardDistrictWise";
import HomeScreen from "./components/HomeScreen";
import ContactUs from "./components/ContactUs";
import Season from "./components/Season";
import PrivacyPolicy from "./components/PrivacyPolicy";
import AboutUs from "./components/AboutUs";
import DepartmentStaticScreen from "./components/DepartmentStaticScreen";
import FarmersCorner from "./components/FarmersCorner";
import TransferRequestsForDAO from "./components/TransferRequestsForDAO";
import TransferRequestsByDistributor from "./components/TransferRequestsByDistributor";
import AddTransferByDistributor from "./components/AddTransferByDistributor";
import ReceiverRequestsByDistributor from "./components/ReceiverRequestsByDistributor";
import FindFarmer from "./components/FindFarmer";
import ForecastAO from "./components/ForecastAO";
import DistrictWiseFarmerDistributions from "./components/DistrictWiseFarmerDistributions";
import DistrictWiseFarmerRegistrations from "./components/DistrictWiseFarmerRegistrations";
import NiceSeeds from "./components/NiceSeeds";
import ForecastNew from "./components/ForecastNew";
import ForecastAONew from "./components/ForecastAONew";
import ForecastAODetailsNew from "./components/ForecastAODetailsNew";
import ForeCastHistory from "./components/ForecastHistory";
import ForecastNewAO from "./components/ForecastNewAO";
import SupplierLimitAllotment from "./components/SupplierLimitAllotment";
import SupplierDashboard from "./components/SupplierDashboard";
import FarmersListDistrictWise from "./components/FarmersListDistrictWise";
import FarmersDetailsListAll from "./components/FarmersDetailsListAll";
import DistributionDetailsList from "./components/DistributionDetailsList";
import FarmerCheckLogin from "./components/FarmerCheckLogin";
import DashboardAo from "./components/DashboardAo";
import UOM from "./components/UOM";
import FarmerRegistration from "./components/FarmerRegistration";
import FarmerApplication from "./components/FarmerApplication";
import FarmerApplicationDetailsByDHO from "./components/FarmerApplicationDetailsByDHO";
import FarmerApplicationDetails from "./components/FarmerApplicationDetails";
import Layout2 from "./components/Layout2";
import ListFarmerApplication from "./components/ListFarmerApplication";
import FarmerApplicatioView from "./components/FarmerApplicationView";
import FarmerApplicationAO from "./components/FarmerApplicationAO";
import Activities from "./components/Activities";
import Schemes from "./components/Schemes";

const App = () => {
  return (
    <Router>
      <UserProvider>
        <Routes>
          {/*            <Route path="" exact={true} element={<Login />} />  */}
          <Route path="/login" element={<Login />} />
          <Route element={<Layout2/>}>
          <Route path="/farmer-application" element={<FarmerApplication/>}/>
          <Route path="/list-farmer-applications" element={<ListFarmerApplication/>}/>
    <Route path="/farmer-application-view" element={<FarmerApplicatioView/>}/>
            </Route>
            

          <Route element={<Layout1 />}>
            <Route path="" element={<HomeScreen />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />

            <Route path="/department" element={<DepartmentStaticScreen />} />
            <Route path="/ContactUs" element={<ContactUs />} />
            <Route path="/farmers-corner" element={<FarmersCorner />} />
            <Route path="/farmer-login" element={<FarmerCheckLogin />} />

            <Route
              path="/farmer-registration"
              element={<FarmerRegistration />}
            />
          </Route>
          <Route element={<Layout />}>
            <Route path="/roles" element={<Role />} />
            <Route path="/activites" element={<Activities />} />
            <Route path="/schemes" element={<Schemes />} />


            <Route path="/designations" element={<Designation />} />
            <Route path="/departments" element={<Department />} />
            <Route path="/variety" element={<Variety />} />
            <Route path="/season" element={<Season />} />
            <Route
              path="/add-variety-detail/:varietyID"
              element={<AddVarietyDetail />}
            />
            <Route path="/admin-users" element={<Users />} />
            <Route path="/add-user-detail/:userID" element={<AddUser />} />
            <Route path="/supply-order/:districtID" element={<SupplyOrder />} />
            <Route path="/seeds" element={<Seed />} />
            <Route
              path="/forecast-dao/:districtID/:user_type"
              element={<Forecast />}
            />
            <Route
              path="/forecast-dao-new/:districtID/:user_type"
              element={<ForecastNew />}
            />

            <Route path="/forecast-ao-update-new" element={<ForecastNewAO />} />
            <Route
              path="/supplier-limit-allotment"
              element={<SupplierLimitAllotment />}
            />
            <Route path="/supplier-dashboard" element={<SupplierDashboard />} />

            <Route path="/purchase-initiate" element={<PurchaseInitiate />} />
            <Route
              path="/purchase-list/:distribution_type"
              element={<PurchaseList />}
            />
            <Route path="/receive-list" element={<ReceivedList />} />
            <Route
              path="/received-quantity/:PurchaseID"
              element={<ReceivedQuantity />}
            />

            <Route path="/add-farmer" element={<AddFarmer />} />
{/*                         <Route path="/farmer-application" element={<FarmerApplication/>}/>
 */}             
            <Route
              path="/forecast-details/:forecastID"
              element={<ForecastDetails />}
            />
            <Route
              path="/forecast-details-ao/:forecastID"
              element={<ForecastAODetails />}
            />
            <Route
              path="/district-wise-forecast"
              element={<DistrictWiseForecast />}
            />
            <Route
              path="/demand-request-supplier"
              element={<DemandRequestSupplier />}
            />
            <Route path="/dispatch-supplier" element={<DispatchSupplier />} />
            <Route
              path="/dispatch-individual/:PurchaseID/:type"
              element={<DispatchIndividual />}
            />
            <Route path="/seed-testing" element={<SeedTesting />} />
            <Route path="/test-requests" element={<TestRequests />} />
            <Route
              path="/test-agency-requests"
              element={<TestAgencyRequests />}
            />
            <Route path="/uom" element={<UOM />} />
            <Route
              path="/transit-data-by-dispatch/:DispatchID"
              element={<TransitDataByDispatch />}
            />
            <Route
              path="/transaction-details/:PurchaseID"
              element={<TransportDetailsView />}
            />
            <Route
              path="/dispatch-supplier/:purchaseID/:supplierID"
              element={<DispatchSupplier />}
            />
            <Route
              path="/distributor-allotment"
              element={<DistributorAllotmentByDao />}
            />

            <Route
              path="/distributor-details/:distributorID"
              element={<DistributorDetails />}
            />
            <Route
              path="/distributor-seed-history"
              element={<DistributorSeedHistory />}
            />
            <Route path="/distributor-seeds" element={<DistributionSeeds />} />
            <Route
              path="/distribute-seed-adhar"
              element={<DistribureSeedAdhar />}
            />

            <Route path="/stock-register" element={<StockRegister />} />
            <Route
              path="/dashboard-distributionwise"
              element={<DashboardDistributionWise />}
            />
            <Route path="/dashboard-seedwise" element={<DashboardSeedWise />} />
            <Route
              path="/dashboard-seedwise2"
              element={<DashboardSeedWise2 />}
            />
            <Route
              path="/dashbord-districtwise"
              element={<DashboardDistrictWise />}
            />
            <Route
              path="/transfer-requests-dao"
              element={<TransferRequestsForDAO />}
            />

            <Route
              path="/transferrequests-bydistributor"
              element={<TransferRequestsByDistributor />}
            />
            <Route
              path="/addTransfer-bydistributor"
              element={<AddTransferByDistributor />}
            />
            <Route
              path="/receiverequests-bydistributor"
              element={<ReceiverRequestsByDistributor />}
            />
            <Route path="/find-farmer" element={<FindFarmer />} />

            <Route path="/forecast-ao" element={<ForecastAO />} />
            <Route path="/forecast-ao-new" element={<ForecastAONew />} />

            <Route
              path="/district-wise-farmer-registrations"
              element={<DistrictWiseFarmerRegistrations />}
            />
            <Route
              path="/farmer-details-list"
              element={<FarmersDetailsListAll />}
            />
            <Route
              path="/farmer-list-district-wise"
              element={<FarmersListDistrictWise />}
            />
            <Route
              path="/distribution-details-list"
              element={<DistributionDetailsList />}
            />
            <Route
              path="/farmer-application-details-by-dho"
              element={<FarmerApplicationDetailsByDHO />}
            />
            <Route path="/farmer-application-ao"
            element={<FarmerApplicationAO/>}
            />

            <Route
              path="/farmer-application-details"
              element={<FarmerApplicationDetails />}
            />
            <Route path="/dashboard-ao" element={<DashboardAo />} />

            <Route
              path="/district-wise-farmer-distributions"
              element={<DistrictWiseFarmerDistributions />}
            />
            <Route path="/my-seeds" element={<NiceSeeds />} />
            <Route path="/forecast-history" element={<ForeCastHistory />} />
            <Route
              path="/forecast-ao-details-new"
              element={<ForecastAODetailsNew />}
            />
          </Route>
        </Routes>
        <GlobalStyle />
      </UserProvider>
    </Router>
  );
};

export default App;
