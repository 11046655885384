import React from "react";
// Styles
import {
  Wrapper,

} from "./styles";
// Routing
export const LoadingSpinner = () => {  

  
  return (
    <Wrapper>
     <div className="spinner-container">
       <div className="loading-spinner"></div> 
    </div>
    
    </Wrapper>
  );
};

