import styled from "styled-components";

export const Wrapper = styled.div`
.tablelist {
  margin-top:15px;
  margin-right:5px;;
}
.alert-message{
  color: red;
  margin-left: 15px;
}
 .plabel {
   font-size:15px;
   margin-left:3px;
   margin-top:-2x;
 }
thead{
  color:black;
  background:#c0d3ef;
  border:1px solid #c0d3ef;
  border-radius:10px;
}
td{
  color:black;
  font-weight:400;
  font-size:14px;
  height:30px;
  padding:1px 14px !important;
  vertical-align: middle;
  font-family:Roboto;
  text-transform: capitalize;
}
th{
  font-size:16px;
  height:40px;
  padding:0px 14px;
  vertical-align: middle;
  font-weight:500;
  font-family:Roboto;
}
.custom-file-uploaded {
  text-align: center;
  background: transparent;
  img{
    width: 100px;
    height: 100px;
   // border:2px solid  #6EDC8F;  
    border-radius: 5%;
  }
  a{
  cursor: pointer;
  }
}
.table-head-content {
  padding: 13px 13px 0 13px;
  background: white;
  border-bottom: 1px solid #E2E2E2;   
}  
.row_div {
  display:flex;
  flex-direction:row;
  padding: 8px;
}
.pcat_text{
  margin-left: 10px;
  font-family: Roboto;
  font-size: 14px;
}
.pcat_title{
  margin-left:10px;
  font-family:Roboto;
  font-weight:500;
  font-size:16px;
}
.input_div_table{
  width: 150px;
  color:var(--black);
  font-family: 'ARIAL';
  height:30px;
  border-radius:6px;
  margin:5px;
  background:white;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  input,select{
         border: 2px solid #DFDFDF;
         height:30px;
     :focus {
       outline: none !important;
       border-color:none;  
     }
    }  
}
.input_div {
  width: 100%;
  color:var(--black);
  font-family: 'ARIAL';
  height:45px;
  border-radius:6px;
  margin:5px;
  background:white;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  :focus {
    border: 0px solid #DFDFDF !important; 
  }
  input,select{
         border: 2px solid #DFDFDF;
     :focus {
       outline: none !important;
       border-color:none;  
     }
    }
   
}
.title{
  font-family:Roboto;
  font-size:16px;
  font-weight:600;
  margin-left:10px;
  margin-bottom:0 !important;
  margin-top:15px;

}

.adddiv {
  background:white;
  height:95%;
  width:80%;
  margin-top:15px;
  margin-left:20px;
  border-radius:5px;
  
  input,select{
    width:85%;
    height: 40px;
    border: 1px solid #00001F24;
    border-radius: 6px;
    margin: 15px ;
    padding: 10px; 
    :focus {
      outline: none !important;
      border-color:none;  
    }
   }
  
}
.add_cat_button {
  background:#000044;
  color:white;
  border:#4EACFB;
  padding:10px;
  height:40px;
  width:85%;
  margin:100px 25px 0px;
  font-weight:bold;
  border-radius:6px;
}

.row_table_view {
  width: 80px;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  background:#ECECEC;
  color:#000044;
  display:flex;
  flex-direction:row;
  border-radius:4px;
  cursor:pointer; 
 
  @media screen and (max-width: 1365px) {
    width:70%;

  }
  @media screen and (max-width: 1199px) {
    width: 75%;

  }
  @media screen and (max-width: 991px) {
    width: 85%;

  }
  @media screen and (max-width: 768px) {
    width: 90%;
  }
  img{
    width: 15px;
    height: 14px;
    margin: 0px 4px;
  }
  p{
    margin: 0px 4px;
    font-size: 13px;
    margin-top: 3px;
    font-weight: bold;      }
}

`