import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./TransferRequestsByDistributor.styles"
import { Row, Container, Col } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import Edit from "../../images/edit.png";
import plus from "../../images/white_plus.png"
import { useNavigate, useParams } from "react-router-dom";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle"
import { LoadingSpinner } from "../LoadingSpinner/index";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from "moment";
import View from "../../images/view.svg";
import Modal from 'react-bootstrap/Modal';



const TransferRequestsByDistributor = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    const [transferRequests, setTransferRequests] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);



    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(50);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey, setSearchKey] = useState('n');
    const [searchKeyTable, setSearchKeyTable] = useState('');

    const [expandTable, setexpandTable] = useState(false);
    const [object_det, setObjectData] = useState([]);
    const[seasonData,setSeasonData]=useState([]);
    const[season,setSeason]=useState('')


    const handleViewRequestDetails = async (obj) => {
        let result = await API_Auth.getINDSeedReqestForDao(obj.RequestNo);
        console.log("resu;te", result)
        setexpandTable(true);
        setObjectData(result);
    }
    const handleClose = () => {
        setexpandTable(false);
    }

    const handleSearch = (e) => {
        let filterValue = e.target.value;
        setCurrentPage(0);
        if (filterValue == '') {
            let offsetsearch = 0;
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setTableData(slice);
            setSearchData(orgtableData);
            setOffSet(offsetsearch);
            setCurrentPage(0);
            setSearchKey('n');
        }
        else {
            let offsetsearch = 0;
            const filteredData = orgtableData.filter(item => {
                return (item ?.RequestNo ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                    item ?.ApproverName ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                    item ?.Status ?.toLowerCase().includes(filterValue.toLowerCase()) ||

                        item ?.ReceiverNames ?.toLowerCase().includes(filterValue.toLowerCase()))
            });

            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            setOffSet(offsetsearch);
            setSearchKeyTable(filteredData);
            setTableData(slice);
            setSearchData(filteredData);
        }
    }

    const handleInput=async(e)=>{
        const {name,value}=e.target;
        if(name=="season"){
            setSeason(value);
            setIsLoading(true)
            const user_id = localStorage.getItem("UserID");
            let result = await API_Auth.getTransferRequestsByDistributor(user_id,value);
            console.log("result", result)
            setTransferRequests(result);
            setSearchData(result);
            setIsLoading(false);
            //seting pagenation data
            let offset = 0;
            var slice = result.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result.length / perPage));
            setOrgTableData(result);
            setTableData(slice);
            setOffSet(offset);
            setCurrentPage(0); 
        }
    }


    useEffect(() => {
        setIsLoading(true);
        const user_id = localStorage.getItem("UserID");
        const fetchForecast = async () => {
            const seasonsresult=await API_Auth.getSeasons();
            setSeasonData(seasonsresult);

            let result = await API_Auth.getTransferRequestsByDistributor(user_id,'');
            console.log("result", result)
            setTransferRequests(result);
            setSearchData(result);
            setIsLoading(false);
            //seting pagenation data
            var slice = result.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result.length / perPage));
            setOrgTableData(result);
            setTableData(slice);
        };
        // call the function
        fetchForecast()
            // make sure to catch any error
            .catch(console.error);
    }, []);
    const handleAddTransfer = () => {
        navigate("/addTransfer-bydistributor");
    }
    const handlerowsperPage = (e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(searchKeyTable);
        }
        else {
            const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(orgtableData);
        }
    }
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        const data = orgtableData;
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setOffSet(offsetdata);
            setSearchData(searchKeyTable);
        } else {
            const slice = data.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(data.length / perPage))
            setOffSet(offsetdata);
            setTableData(slice);
            setSearchData(data);
        }
    }
    const formatter = new Intl.NumberFormat('en-IN');
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}

            <Container>
                <Row>
                    <Col lg={3} style={{ paddingLeft: 0 ,marginTop:25}}>
                        <Title>Transfer Requests</Title>
                    </Col>
                    <Col lg={3} style={{ paddingLeft: 0, }}>
                    <label>Season</label>

                        <div className="row_div">
                            <div className="input_div" >
                                <select value={season} onChange={handleInput} name="season"
                                    style={{ margin: 0, background: 'transparent' }}
                                >

                                    <option value="">All</option>
                                    {seasonData.length != 0 && <>
                                        {seasonData.map((item, index) => {
                                            return (
                                                <option value={item.CombYearSeason}>{item.CombYearSeason}</option>
                                            )
                                        })}
                                    </>}

                                </select>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4}style={{marginTop:25}}>
                        <div className="row_div">
                            {/*                             <p className="title">Filter By:</p> */}
                            <div className="input_div" >
                                <input type="type" style={{ margin: 0, background: 'transparent' }}
                                    name="filterValue"
                                    //    value={filterValue}
                                    onChange={(e) => handleSearch(e)}
                                    placeholder="Search" />
                                <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                            </div>

                        </div>
                    </Col>
                   {/*  {purchaserRole=='Yes' &&  */} <Col lg={2} style={{ paddingRight: 0,marginTop:25 }}>
                        <div>
                            <button className="add_button" onClick={() => handleAddTransfer()}>
                                <p className="pcat_title">ADD TRANSFER</p></button>
                        </div></Col>
                        
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
                        <Table className="tablelist" responsive>
                            <thead>
                                <tr>
                                <th>Season</th>
                                    <th>RequestNo </th> 
                                    <th>Approver </th>
                                    <th>Receiver's</th>
                                    <th>Quantity</th>
                                    <th>Status</th>
                          <th style={{ textAlign: 'right' }}>Action</th>
                            </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {tableData.length != 0 && <tbody>
                                {tableData.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                            <td>{obj.year}-{obj.season}</td>
                                                 <td>{obj.RequestNo}</td>
                                                <td>{obj.ApproverName}</td>
                                                <td>{obj.ReceiverNames}</td>
                                                <td>{formatter.format(obj.Quantity)}</td>
                                                <td>{obj.Status}</td>

                                            <td style={{ textAlign: 'right' }}>
                                                    <div className="row_table_edit" onClick={() => handleViewRequestDetails(obj, "view")}>
                                                        <img src={View} />
                                                        <p>VIEW</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                            }
                        </Table>
                        {tableData.length != 0 &&
                            <div>
                                <div className="pagination_row_div">
                                    <p className="pagination_title">Showing items per page:</p>
                                    <div className="pagination_input_div" >
                                        <select className="pagination_select"
                                            style={{ margin: 0, background: 'transparent' }}
                                            name="pagination_value"
                                            value={perPage}
                                            onChange={handlerowsperPage} >
                                          <option value={20}>20</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="pagination_row_right_div">
                                    <p className="pagination_total">Showing {offset + 1} to {searchData.length < offset + perPage &&
                                        <span>{searchData.length}</span>}
                                        {searchData.length > offset + perPage &&
                                            <span>{offset + perPage} </span>} of {searchData.length} items</p>
                                    <ReactPaginate
                                        previousLabel={<ArrowBackIosIcon className="pagination_prev_btn" color="#2438a7" />}
                                        nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7" />}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={currentPage} />
                                </div>
                            </div>
                        }                    </Col>
                </Row>
                <Modal show={expandTable} onHide={handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    dialogclassname="modal-content">
                    <Modal.Header closeButton>
                        <Modal.Title>Individual Request</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="row_div">
                            <Row>
                                <Row>
                                    <Col lg={3}>
                                        <p className="pcat_title">Sender Name :</p>
                                    </Col>
                                    <Col>
                                        {object_det.length != 0 && <p className="pcat_text">{object_det[0].SenderName}</p>}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={3}>
                                        <p className="pcat_title">Request No :</p>
                                    </Col>
                                    <Col>
                                        {object_det.length != 0 && <p className="pcat_text">{object_det[0].RequestNo}</p>}
                                    </Col>
                                </Row>
                            </Row>
                        </div>
                        <div>
                            {object_det &&
                                (object_det && object_det.length == 0) && <>
                                    <Table responsive>
                                        <tr>
                                            <td colspan="12" className="hiddenRow">
                                                <p style={{
                                                    textAlign: "center",
                                                    fontWeight: "bold",
                                                    margin: 10,
                                                    color: 'black'
                                                }}>No Data Found</p>

                                            </td>
                                        </tr>
                                    </Table>
                                </>
                            }
                            {
                                (object_det && object_det.length != 0) && <>
                                    <Table responsive>
                                        <thead>
                                            <th>Approver Name</th>
                                            <th>Receiver Name</th>
                                            <th>Quantity</th>
                                            <th>Status</th>
                                        </thead>
                                        <tbody>
                                            {object_det ?.map((item, index) => {
                                                return (
                                                    <tr key={index} >
                                                        <td>{item.ApproverName}</td>
                                                        <td>{item.ReceiverName}</td>
                                                        <td>{item.Quantity}</td>
                                                        <td>{item.Status}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </>
                            }
                        </div>

                    </Modal.Body>
                   
                </Modal>
            </Container>
            <ToastContainer />
        </Wrapper>
    )
}
export default TransferRequestsByDistributor