
let base_url_dev_Txhash = "http://143.110.255.191:4000";
let base_url = "http://143.110.255.191:3022/jseed";


/*  let base_url = "https://jharkhandseed.com:3022/jseed";
let base_url_dev_Txhash = "https://jharkhandseed.com:4000";  */



//login
const AUTH_LOGIN_API_URL = base_url+"/regCheck";

//role 

const AUTH_ROLE_API_URL=base_url+"/userDetail?mode=SELECTROLE";
const ADD_NEW_ROLE_API_URL=base_url+"/userDetail?mode=INSERTROLE";
const ADD_UPDATE_ROLE_API_URL=base_url+"/userDetail/?mode=UPDATEROLE"
const ADD_DELETE_ROLE_API_URL=base_url+"/userDetail?mode=DELETEROLE"

//designations

const AUTH_DESIGNATION_API_URL=base_url+"/userDetail?mode=SELECTDESIGNATIONS";
const ADD_NEW_DESIGNATION_API_URL=base_url+"/userDetail?mode=INSERTDESIGNATION";
const ADD_UPDATE_DESIGNATIOn_API_URL=base_url+"/userDetail/?mode=UPDATEDESIGNATION"
const ADD_DELETE_DESIGNATION_API_URL=base_url+"/userDetail?mode=DELETEDESIGNATIONS"

// departments

const AUTH_DEPARTMENT_API_URL=base_url+"/userDetail?mode=SELECTDEPARTMENT";
const ADD_NEW_DEPARTMENT_API_URL=base_url+"/userDetail?mode=INSERTDEPARTMENT";
const ADD_UPDATE_DEPARTMENT_API_URL=base_url+"/userDetail/?mode=UPDATEDEPARTMENT"
const ADD_DELETE_DEPARTMENT_API_URL=base_url+"/userDetail?mode=DELETEDEPARTMENT"

//variety

const AUTH_VARIETY_API_URL=base_url+"/seedDetail?mode=SELECTSEEDDETAILS";
const ADD_VARIETY_BY_ID_API_URL=base_url+"/seedDetail?mode=SELECTSEEDDETAILIND";
const ADD_VARIETY_API_URL=base_url+"/seedDetail?mode=INSERTSEEDDETAIL";
const ADD_VARIETY_UPDATE_API_URL=base_url+"/seedDetail?mode=UPDATESEEDDETAIL";
const ADD_DELETE_VARIETY_API_URL=base_url+"/seedDetail?mode=DELETESEEDDETAIL";


//seeds


const AUTH_SEEDS_API_URL=base_url+"/seedDetail?mode=SELECTSEEDS";
const ADD_DELETE_SEED_API_URL=base_url+"/seedDetail?mode=DELETESEED";
const ADD_SEED_API_URL=base_url+"/seedDetail?mode=INSERTSEED";
const ADD_SEED_UPDATE_API_URL=base_url+"/seedDetail?mode=UPDATESEED";

//seed type

const AUTH_SEED_TYPE_API_URL=base_url+"/seedDetail?mode=SELECTSEEDTYPE"

//users

const AUTH_USERS_API_URL=base_url+"/userDetail?mode=SELECTUSERS";
const ADD_USER_BY_ID_API_URL=base_url+"/userDetail?mode=SELECTUSERIND";
const ADD_USER_API_URL=base_url+"/userDetail?mode=INSERTUSER";
const ADD_UPDATE_USER_API_URL=base_url+"/userDetail?mode=UPDATEUSER";
const ADD_DELETE_USER_API_URL=base_url+"/userDetail?mode=DELETEUSER";
const UPDATE_USER_PASSWORD_API_URL=base_url+"/resetpasswordWeb";
const ADD_UPDATE_PURCHASER_TYPE_API_URL=base_url+"/userDetail?mode=UPDATEUSERPURCHASER";


//seasons   

const AUTH__GET_SEASONS_API_URL=base_url+"/season?mode=SELECTSEASON"
const ADD_DELETE_SEASON_API_URL=base_url+"/season?mode=DELETESEASON"
const AUTH_INSERT_SEASON_API_URL=base_url+"/season?mode=INSERTSEASON"
//variety by seed

const AUTH_VARIETY_BY_SEED_TYPE_API_URL=base_url+"/seedDetail?mode=SELECTSEEDVARIETY"
//Foreacast (dao)
const AUTH_FORECAST_API_URL=base_url+"/forcastProcess?mode=SHOWFORCASTS";
const AUTH_FORECAST_DETAILS_API_URL=base_url+"/forcastProcessInd?mode=SHOWFORCASTIND";

const AUTH_ADD_FORECAST_API_URL=base_url+"/forcastProcess?mode=INSERTFORCAST"
//Districts select (dao)
const AUTH_SELECT_DISTRICTS_API_URL=base_url+"/userDetail?mode=SELECTDISTRICT"

//Suppliers 
const AUTH_SELECT_SUPPLIERS_API_URL=base_url+"/userDetail?mode=SELECTSUPPLIERS"
const AUTH_DEMAND_REQUEST_SUPPLIER_LIST=base_url+"/purchageProcess?mode=SELECTDEMANDSUPPLIER"
const AUTH_DISPATCH_BY_PURCHASEID=base_url +"/purchageProcessIND?mode=SELECTDEMANDIND"


const AUTH_DISPATCH_BY_SUPPLIER=base_url+"/purchageProcess?mode=INSERTTRANSITMULTI"

//Distributor select
const AUTH_INITIATE_PURCHASE_API_URL=base_url+"/purchageProcess?mode=INSERTDEMANDMULTI"

const AUTH_SINGLE_INITIATE_PURCHASE_API_URL=base_url+"/purchageProcess?mode=INSERTDEMAND"

const AUTH_CHECK_ALLOTEMENT_SEED_API_URL=base_url+"/purchageProcess?mode=CHECKALLOTMENTQUANTITY"
const AUTH__PURCHASE_LIST_API_URL=base_url+"/purchageProcess?mode=SELECTDEMANDDISTRICT"
const AUTH__PURCHASE_LIST_DISTRIBUTOR_ALLOTMENT_API_URL=base_url+"/seedAllotment?mode=SHOWDISTRIBUTORALLOTMENT"
//forecast district wise
const AUTH_FORECAST_DISTRICT_WISE_API_URL=base_url+"/forcastProcessDistrictWise?mode=AOFORECAST"

//transit wise
const AUTH_DISPATCH_TRANSIT_DETAILS_API_URL=base_url+"/purchageProcessDispatch?mode=SELECTDISPATCH"
const AUTH__TRANSPORT_DETAILS_API_URL=base_url+"/purchageProcess?mode=SELECTTRANSPORT"
//receive seed
const AUTH__RECEIVED_SEED_QTY_API_URL=base_url+"/purchageProcess?mode=UPDATEDEMANDRECEIVER"

//get distributors
const AUTH__GET_DISTRIBUTORS_BY_URL=base_url+"/userDetail?mode=SELECTUSERROLEWISE"
const AUTH__GET_DISTRIBUTORS_BY_DISTRICTID_URL=base_url+"/userDetail?mode=SELECTUSERROLEWISEDISTRICTWISE"


const AUTH__GET_DISTRIBUTORS_BY_ID_BY_URL=base_url+"/seedAllotment?mode=SHOWDISTRIBUTORDITAIL"
const AUTH__GET_DISTRIBUTOR_USER_BY_ID_BY_URL=base_url+"/userDetail?mode=SELECTUSERROLEWISE"
const AUTH__GET_DISTRIBUTOR_DAO_WITH_SAME_DISTRICTS=base_url+"/userDetail?mode=SELECTUSERROLEWISEPURCHASER"

//seed insert allotment

const AUTH_SEED_INSERT_ALLOTMENT=base_url+"/seedAllotment?mode=INSERTALLOTMENT"

// stock register
const AUTH_STOCK_REGISTER=base_url+"/stock?mode=STOCK";

// add farmer
const AUTH_ADD_FARMER=base_url+"/insertAndGetFarmerWeb";

//distributor seed history

const AUTH__GET_DISTRIBUTOR_SEED_HISTORY_BY_URL=base_url+"/getFarmerDistributionWeb?mode=DISTRIBUTIONSEEDHISTORY"

//const AUTH_INSERT_FARMER_DISTRIBUTION=base_url+"/insertFarmerDistributionWeb"

const AUTH_INSERT_FARMER_DISTRIBUTION=base_url+"/insertFarmerDistributionnewWeb"


const AUTH__GET_DISTRIBUTOR_SEEDS=base_url+"/seedDataWeb"

//get Farmer details

const AUTH__GET_FARMER_DETAILS=base_url+"/insertAndGetFarmerWeb"

//get DISTRIBUTION WISE DISTRICT ALL 
const AUTH__GET_DISTRIBUTION_WISE_DISTRICT_ALL=base_url+"/dashboard?mode=DISTRIBUTIONWISEDISTRICTALL";
const AUTH__GET_DISTRIBUTION_WISE_DISTRICT_SEED=base_url+"/dashboard?mode=DISTRIBUTIONWISEDISTRICTSEED";
const AUTH__GET_DISTRIBUTION_WISE_DISTRICT_VERITY=base_url+"/dashboard?mode=DISTRIBUTIONWISEDISTRICTVERITY";
const AUTH__GET_DISTRIBUTION_WISE_DISTRICT_SEASON=base_url+"/dashboard?mode=DISTRIBUTIONWISEDISTRICTALLSEASON"


// distribution wise 


const AUTH__GET_DISTRIBUTION_WISE_BY_URL=base_url+"/dashboard?mode=DISTRIBUTIONWISEALLDISTRICT"


// distribution seed wise


const AUTH__GET_DISTRIBUTION_SEED_WISE_BY_URL=base_url+"/dashboard?mode=DISTRIBUTIONWISESEED"

// distribution variety wise

const AUTH__GET_DISTRIBUTION_VARIETY_WISE_BY_URL=base_url+"/dashboard?mode=DISTRIBUTIONWISEVERITY"

const AUTH__GET_DISTRIBUTION_DISTRICT_WISE_BY_URL=base_url+"/dashboard?mode=DISTRIBUTIONWISEDISTRICT"


//distribution season wise


const AUTH__GET_DISTRIBUTION_SEASON_WISE_BY_URL=base_url+"/dashboard?mode=DISTRIBUTIONWISESEASON"

// dashabord seed wise

const AUTH__GET_DASHBOARD_SEED_WISE_BY_URL=base_url+"/dashboardSeedDistrict?mode=SELECTDISTRICTSEED"

const AUTH__GET_DASHBOARD_SEED_SEASON_WISE_BY_URL=base_url+"/dashboardSeedDistrictSeason?mode=SELECTDISTRICTSEED"

// seed transfer wise
const AUTH__GET_ALL_SEED_REQUEST_FOR_DAO=base_url+"/seedTransfer?mode=SELECTREQAPPROVER"
const AUTH__GET_IND_SEED_REQUEST_DAO=base_url+"/seedTransfer?mode=SELECTINDREQ"
const AUTH__GET_APPROVEOR_REJECT_DAO=base_url+"/seedTransfer?mode=APPROVEORREJECT"

//TRANSFER REQUESTS BY DISTRIBUTOR

const AUTH__GET_TRANSFER_REQUESTS_BY_DISTRIBUTOR=base_url+"/seedTransfer?mode=SELECTREQSENDER"


const AUTH__ADD_TRANSFER_REQUESTS_BY_DISTRIBUTOR=base_url+"/seedTransfer?mode=INSERTTRASFERREQ"


const AUTH__GET_RECEIVED_RANSFER_REQUESTS_BY_DISTRIBUTOR=base_url+"/seedTransfer?mode=SELECTALLAPPROVEREQFORRECIVER"


const AUTH__SEED_RECEIVER_FOR_DISTRIBUTOR=base_url+"/seedTransfer?mode=SEEDRECEIVE"

//VERIFY OTP
const AUTH__VERIFY_OTP_WEB=base_url+"/sendVerifyOTPWeb?mode=SEND"
// upload API
const UPLOAD_IMAGE_API_URL = base_url+"/uploadApi";

const AUTH_UPDATE_FORECAST_BY_DAO_API_URL = base_url+"/forcastProcess?mode=UPDATEFORECASTBYAO"

const AUTH_SELECT_DISTRICT_WISE_SEED_API_URL = base_url+"/dashboardSeedDistrict1?mode=SELECTDISTRICTSEED"
const AUTH_SELECT_DISTRICT_SEED_WISE_SEED_API_URL = base_url+"/dashboardSeedDistrict1Season?mode=SELECTDISTRICTSEED"

const AUTH_SHOW_FORCASTS_AO_ALL_API_URL = base_url+"/forcastProcess?mode=SHOWFORCASTSALL"

const AUTH_SHOW_DISTRIBUTION_WISE_ALLSEARCH_API_URL = base_url+"/dashboard?mode=DISTRIBUTIONWISEALLSEARCH"
const AUTH_SHOW_DISTRICT_WISE_ALLSEARCH_API_URL = base_url+"/dashboard?mode=DISTRIBUTIONWISEDISTRICTALLSEARCH"
const AUTH_SHOW_DISTRICT_LEVEL_DATA = base_url+"/dashboard?mode=DISTRIBUTIONWISEDISTRICTALLSEARCHDATE"
const AUTH_SHOW_SELECT_DISTRICT_WISE_FARMER_API_URL = base_url+"/userDetail?mode=SELECTDISTRICTWISEFARMER"
const AUTH_SHOW_SELECTDISTRICT_WISE_FARMER_DISTRIBUTION_API_URL = base_url+"/userDetail?mode=SELECTDISTRICTWISEFARMERDISTRIBUTION"
const AUTH__GET_FARMER_WITH_ROP_SEASONDETAILS=base_url+"/insertAndGetFarmerWebwithdistributionWeb"



const ADD_SEEDS_BY_DAO=base_url+"/daoSeeds?mode=INSERTSEED"

const GET_SEEDS_BY_DAO=base_url+"/daoSeeds?mode=SELECTSEED"

const UPDATE_FORECAST_SEEDS_BY_DAO=base_url +"/daoSeedsForecastNew?mode=INSERTFORECAST"

const GET_AO_SELECT_PENDING=base_url+"/daoSeedsForecastNew?mode=AOSELECTPENDING"
const GET_AO_SELECT_APPROVE=base_url+"/daoSeedsForecastNew?mode=AOSELECTAPPROVE"

const GET_AO_PENDING_VIEW=base_url+"/daoSeedsForecastNew?mode=AOSELECTEND"

const GET_AO_UPDATE_FORECAST_VIEW=base_url+"/daoSeedsForecastNew?mode=AOUPDATEFORECAST"

const GET_FORECAST_HISTORY_VIEW=base_url+"/daoSeedsForecastNew?mode=AOSELECTENDHISTORY"


const GET_SEEDS_BY_APPROVE_AO=base_url+"/daoSeedsForecastNew?mode=SELECTSEED";
const GET_DISTRICT_WISE_FARMER1=base_url+"/userDetail?mode=SELECTDISTRICTWISEFARMER1";
const GET_ALL_FARMER_DETAIL=base_url+"/userDetail?mode=GETALLFARMERDETAIL";
const GET_ALL_DISTRIBUTION_DETAIL=base_url+"/userDetail?mode=GETALLDISTRIBUTIONDETAIL";


const GET_AO_APPROVE_QTY_SUPPLIER=base_url+"/aoApprovedQtySupplier?mode=";

const GET_TRANSACTION_HASH_DETAIL= base_url_dev_Txhash+"/seedBCApi/transactiondetail?tx=";
const GET_REQUEST_TESTING_URL=base_url+"/testing?mode=";
const GET_UOM_BASE_URL=base_url+"/uom?mode=";


const AUTH_GET_FARMER_REGISTRATION_OTP=base_url+"/sendVerifyOTPWebRegistration?mode=SEND";

const AUTH_GET_FARMER_REGISTRATION_VERIFY_OTP=base_url+"/sendVerifyOTPWebRegistration?mode=VERIFY"


const AUTH_FARMER_NEW_REGISTRATION=base_url+"/newFarmerReg?mode=REGISTER"
const AUTH_GET_BLOCK_BY_DISTRICT_ID=base_url+"/userDetail?mode=SELECTBLOCK"
const AUTH_CHECK_ADHAR_NUMBER=base_url+"/newFarmerReg?mode=AADHARCHECK"
const GET_SCHEMES=base_url+"/hortiTypeOFScheame?mode=SELECTTYPEOFSCHEME";
const GET_APPLICANTS=base_url+"/hortiApplicantype?mode=SELECTAPP"

const AUTH_FARMER_NEW_APPLICATION=base_url+"/hortiApplicationData?mode=INSERTAPPLICATION" 

const LIST_OF_FARMERS_BY_DHO=base_url+"/hortiApplicationData?mode=SELECTAPPLICATIONDAO"

const LIST_OF_FARMERS_BY_AO=base_url+"/hortiApplicationData?mode=SELECTAPPLICATIONAO"

const UPDATE_APPLICATION_DAO=base_url+"/hortiApplicationData?mode=UPDATEAPPLICATIONDHO"
const UPDATE_APPLICATION_AO=base_url+"/hortiApplicationData?mode=UPDATEAPPLICATIONAO"
const GET_FARMER_APPLICATIONS_BY_ADHAR=base_url+"/hortiApplicationData?mode=SELECTAPPLICATIONFARMER"
const GET_VIEW_APPLICATIONS_BY_APPLICATION_NO=base_url+"/hortiApplicationDataWithAct?mode=SELECTAPPLICATIONIND"

// activities
const GET_ACTIVITIES=base_url+"/hortiActivities?mode=SELECTHORTIACT"
const INSERT_ACTIVITY=base_url+"/hortiActivities?mode=INSERTHORTIACT";
const UPDATE_ACTIVITY=base_url+"/hortiActivities?mode=UPDATEHORTIACT";

const DELETE_ACTIVITY=base_url+"/hortiActivities?mode=DELETEHORTIACT";
//schmes

const INSERT_SCHEME=base_url+"/hortiTypeOFScheame?mode=INSERTTYPEOFSCHEME"
const UPDATE_SCHEME=base_url+"/hortiTypeOFScheame?mode=UPDATETYPEOFSCHEME"
const DELETE_SCHEME=base_url+"/hortiTypeOFScheame?mode=DELETETYPEOFSCHEME"

export {
   
    AUTH_LOGIN_API_URL,
    AUTH_SELECT_DISTRICTS_API_URL,
    AUTH_ROLE_API_URL,
    ADD_NEW_ROLE_API_URL,
    ADD_UPDATE_ROLE_API_URL,
    ADD_DELETE_ROLE_API_URL,

    AUTH_DESIGNATION_API_URL,
    ADD_NEW_DESIGNATION_API_URL,
    ADD_UPDATE_DESIGNATIOn_API_URL,
    ADD_DELETE_DESIGNATION_API_URL,


    AUTH_DEPARTMENT_API_URL,
    ADD_NEW_DEPARTMENT_API_URL,
    ADD_UPDATE_DEPARTMENT_API_URL,
    ADD_DELETE_DEPARTMENT_API_URL,

    AUTH_VARIETY_API_URL,
    ADD_VARIETY_BY_ID_API_URL,
    ADD_VARIETY_API_URL,
    ADD_VARIETY_UPDATE_API_URL,
    ADD_DELETE_VARIETY_API_URL,

    AUTH_USERS_API_URL,
    ADD_USER_BY_ID_API_URL,
    ADD_USER_API_URL,
    ADD_UPDATE_USER_API_URL,
    ADD_DELETE_USER_API_URL,

    AUTH__GET_SEASONS_API_URL,
    ADD_DELETE_SEASON_API_URL,
    AUTH_INSERT_SEASON_API_URL,


    AUTH_SEEDS_API_URL,
    ADD_SEED_API_URL,
    ADD_SEED_UPDATE_API_URL,
    ADD_DELETE_SEED_API_URL,

    AUTH_SEED_TYPE_API_URL,

    AUTH_VARIETY_BY_SEED_TYPE_API_URL,

    AUTH_FORECAST_API_URL,
    AUTH_FORECAST_DETAILS_API_URL,
    AUTH_ADD_FORECAST_API_URL,
    AUTH_FORECAST_DISTRICT_WISE_API_URL,

    AUTH__TRANSPORT_DETAILS_API_URL,
    AUTH_DISPATCH_TRANSIT_DETAILS_API_URL,
    AUTH__PURCHASE_LIST_API_URL,
    AUTH_INITIATE_PURCHASE_API_URL,
    AUTH_SINGLE_INITIATE_PURCHASE_API_URL,
    AUTH_CHECK_ALLOTEMENT_SEED_API_URL,
    AUTH_DISPATCH_BY_PURCHASEID,
    AUTH_SELECT_SUPPLIERS_API_URL,
    AUTH_DEMAND_REQUEST_SUPPLIER_LIST,
    AUTH_DISPATCH_BY_SUPPLIER,
    AUTH__RECEIVED_SEED_QTY_API_URL,
    AUTH__GET_DISTRIBUTORS_BY_URL,
    AUTH__GET_DISTRIBUTORS_BY_ID_BY_URL,
    AUTH__GET_DISTRIBUTOR_USER_BY_ID_BY_URL,
    AUTH_SEED_INSERT_ALLOTMENT,
    AUTH_STOCK_REGISTER,
    AUTH__GET_DISTRIBUTOR_SEED_HISTORY_BY_URL,
    AUTH__GET_DISTRIBUTOR_SEEDS,
    AUTH__GET_FARMER_DETAILS,
    AUTH_ADD_FARMER,
    AUTH_INSERT_FARMER_DISTRIBUTION,
    AUTH__GET_DISTRIBUTION_WISE_DISTRICT_ALL,
    AUTH__GET_DISTRIBUTION_WISE_DISTRICT_SEED,
    AUTH__GET_DISTRIBUTION_WISE_DISTRICT_VERITY,    
    AUTH__GET_DISTRIBUTION_WISE_BY_URL,
    AUTH__GET_DISTRIBUTION_SEED_WISE_BY_URL,
    AUTH__GET_DISTRIBUTION_VARIETY_WISE_BY_URL,
    AUTH__GET_DISTRIBUTION_SEASON_WISE_BY_URL,
    AUTH__GET_DISTRIBUTION_DISTRICT_WISE_BY_URL,
    AUTH__GET_DASHBOARD_SEED_WISE_BY_URL,
    AUTH__GET_DISTRIBUTION_WISE_DISTRICT_SEASON,
    AUTH__GET_DISTRIBUTOR_DAO_WITH_SAME_DISTRICTS,
    AUTH__GET_ALL_SEED_REQUEST_FOR_DAO,
    AUTH__GET_IND_SEED_REQUEST_DAO,
    AUTH__GET_APPROVEOR_REJECT_DAO,

    AUTH__GET_TRANSFER_REQUESTS_BY_DISTRIBUTOR,
    AUTH__ADD_TRANSFER_REQUESTS_BY_DISTRIBUTOR,
    AUTH__GET_RECEIVED_RANSFER_REQUESTS_BY_DISTRIBUTOR,
    AUTH__SEED_RECEIVER_FOR_DISTRIBUTOR,

    AUTH__VERIFY_OTP_WEB,
    UPDATE_USER_PASSWORD_API_URL,
    AUTH__GET_DISTRIBUTORS_BY_DISTRICTID_URL,
    AUTH__PURCHASE_LIST_DISTRIBUTOR_ALLOTMENT_API_URL,
    ADD_UPDATE_PURCHASER_TYPE_API_URL,
    UPLOAD_IMAGE_API_URL,
    base_url,
    AUTH_UPDATE_FORECAST_BY_DAO_API_URL,
    AUTH_SELECT_DISTRICT_WISE_SEED_API_URL,
    AUTH_SELECT_DISTRICT_SEED_WISE_SEED_API_URL,
    AUTH__GET_DASHBOARD_SEED_SEASON_WISE_BY_URL,
    AUTH_SHOW_FORCASTS_AO_ALL_API_URL,
    AUTH_SHOW_DISTRIBUTION_WISE_ALLSEARCH_API_URL,
    AUTH_SHOW_DISTRICT_WISE_ALLSEARCH_API_URL,
    AUTH_SHOW_SELECT_DISTRICT_WISE_FARMER_API_URL,
    AUTH_SHOW_SELECTDISTRICT_WISE_FARMER_DISTRIBUTION_API_URL,
    AUTH__GET_FARMER_WITH_ROP_SEASONDETAILS,


    ADD_SEEDS_BY_DAO,
    GET_SEEDS_BY_DAO,
    UPDATE_FORECAST_SEEDS_BY_DAO,
    GET_AO_SELECT_PENDING,
    GET_AO_SELECT_APPROVE,
    GET_AO_PENDING_VIEW,
    GET_AO_UPDATE_FORECAST_VIEW,
    GET_FORECAST_HISTORY_VIEW,
    GET_SEEDS_BY_APPROVE_AO,
    GET_DISTRICT_WISE_FARMER1,
    GET_ALL_FARMER_DETAIL,
    GET_ALL_DISTRIBUTION_DETAIL,
    GET_AO_APPROVE_QTY_SUPPLIER,
    GET_TRANSACTION_HASH_DETAIL,
    GET_REQUEST_TESTING_URL,
    AUTH_SHOW_DISTRICT_LEVEL_DATA,
    GET_UOM_BASE_URL,


    AUTH_GET_FARMER_REGISTRATION_OTP,
    AUTH_GET_FARMER_REGISTRATION_VERIFY_OTP,
    AUTH_FARMER_NEW_REGISTRATION,
    AUTH_GET_BLOCK_BY_DISTRICT_ID,
    AUTH_CHECK_ADHAR_NUMBER,
    GET_ACTIVITIES,
    GET_SCHEMES,
    GET_APPLICANTS,
    AUTH_FARMER_NEW_APPLICATION,
    LIST_OF_FARMERS_BY_DHO,
    UPDATE_APPLICATION_DAO,
    LIST_OF_FARMERS_BY_AO,
    UPDATE_APPLICATION_AO,
    GET_FARMER_APPLICATIONS_BY_ADHAR,
    GET_VIEW_APPLICATIONS_BY_APPLICATION_NO,
    INSERT_ACTIVITY,
    UPDATE_ACTIVITY,
    DELETE_ACTIVITY,
    INSERT_SCHEME,
    UPDATE_SCHEME,
    DELETE_SCHEME
}