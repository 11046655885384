import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./Role.styles"
import { Row, Container, Col } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import API_Auth from "../../API_Auth";
import { LoadingSpinner } from "../LoadingSpinner/index";
import { AiOutlineSearch } from "react-icons/ai";
import { Title } from "../../GlobalStyle";
import Edit from "../../images/edit.png";
import Delete from "../../images/delete.png"
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Role = () => {
    const {
        isExpanded,
    } = useContext(UserContext);

    const [isLoading, setIsLoading] = useState(false)
    const [roles, setRoles] = useState([]);

    const [formTitle, setFormTitle] = useState('ADD ROLE');
    const [formButtonValue, setFormButtonValue] = useState('ADD');
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey, setSearchKey] = useState('n');
    const [searchKeyTable, setSearchKeyTable] = useState('');

    const [addRole, setAddRole] = useState(false);
    const [roleName, setRoleName] = useState('');
    const [roleId, setRoleId] = useState('');
    const [roleNameErr, setRoleNameErr] = useState('')


    useEffect(() => {
        setIsLoading(true);
        const fetchRoles = async () => {
            let result = await API_Auth.getRoles();
            result.sort((a, b) => a.Role.localeCompare(b.Role))

            setRoles(result);
            setIsLoading(false);
            var slice = result.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result.length / perPage));
            setOrgTableData(result);
            setTableData(slice);
            setSearchData(result);
        };
        fetchRoles()
            // make sure to catch any error
            .catch(console.error);
    }, []);

    const handleSearch = (e) => {
        let filterValue = e.target.value;
        setCurrentPage(0);
        if (filterValue == '') {
            let offsetsearch = 0;
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setCurrentPage(0);
            setOffSet(offsetsearch);
            setTableData(slice);
            setSearchData(orgtableData);
            setSearchKey('n');
        }
        else {
            let offsetsearch = 0;
            const filteredData = orgtableData.filter(item => {
                return item ?.Role ?.toLowerCase().includes(filterValue.toLowerCase())
            });
            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            setOffSet(offsetsearch);
            setSearchKeyTable(filteredData);
            setTableData(slice);
            setSearchData(filteredData);
            // setTableData(filteredData);
        }
    }
    const handleRole = () => {
        setAddRole(true)
        setRoleName('');
        setRoleNameErr('')
        setFormButtonValue('ADD');

    }
    const handleEditRole = (obj) => {
        setAddRole(true)
        // setFormTitle('Edit Role');
        setFormButtonValue('UPDATE');
        setRoleNameErr('');
        setRoleName(obj.Role);
        setRoleId(obj.id);

    }
    const handleClose = () => {
        setAddRole(false)
    }

    const handelRoleName = (e) => {
        setRoleName(e.currentTarget.value)
    }

    const handleDeleteRole = (obj) => {
        if (window.confirm('Are you sure you want to delete the Role ?')) {
            // Save it!
            const fetchDeleteRole = async () => {
                setIsLoading(true);

                let result = await API_Auth.deleteRole(obj.id);
                let res = result[0];
                if (res.Flag == 'Success') {
                    setIsLoading(false);

                    toast.success('Role Deleted successfully');
                    const fetchAsset = async () => {
                        let result = await API_Auth.getRoles();

                        setRoles(result);
                        setIsLoading(false);
                        var slice = result.slice(offset, offset + perPage);
                        setPageCount(Math.ceil(result.length / perPage));
                        setOrgTableData(result);
                        setTableData(slice);
                        setSearchData(result);
                    };
                    // call the function
                    fetchAsset()
                        // make sure to catch any error
                        .catch(console.error);

                } else {
                    toast.error('Something went wrong please try again');
                }

            };
            // call the function
            fetchDeleteRole()
                // make sure to catch any error
                .catch(console.error);
        } else {
            // Do nothing!
        }


    }

    const handlerowsperPage = (e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        if(searchKey=="y"){
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value));
            setTableData(slice);  
            setOffSet(0);  
            setSearchData(searchKeyTable);
        }
        else{
        const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
        setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value));
        setTableData(slice);
        setOffSet(0);
        setSearchData(orgtableData);
        }
    }
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        const data = orgtableData;
        if(searchKey=="y"){
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setOffSet(offsetdata);
            setSearchData(searchKeyTable);
        }else{
        const slice = data.slice(offsetdata, offsetdata + perPage);
        setPageCount(Math.ceil(data.length / perPage));
        setTableData(slice);
        setOffSet(offsetdata);
        setSearchData(data);
        }

    }

    const handleAddRole = async () => {
        setDisableSubmit(true);
        let error = false;
        if (roleName == '') {
            setRoleNameErr('this field is required.');
            error = true;
        }
        else {
            setRoleNameErr('');
        }
        if (!error) {
            setIsLoading(true);

            if (formButtonValue == 'ADD') {
                let result = await API_Auth.addRole(roleName);
                let res = result[0];
                if (res.Flag == 'Success') {
                    setAddRole(false);
                    setIsLoading(false);

                    toast.success('Role added successfully');
                    setInterval(() => {
                        window.location.reload();
                    }, 2000);

                } else {
                    setIsLoading(false);
                    setDisableSubmit(false);
                    toast.error('Something went wrong please try again');
                }
            } else if (formButtonValue == 'UPDATE') {

                let result = await API_Auth.updateRole(roleName, roleId);
                let res = result[0];
                if (res.Flag == 'Success') {
                    setIsLoading(false);

                    setAddRole(false);
                    toast.success('Role updated successfully');
                    setInterval(() => {
                        window.location.reload();
                    }, 2000);

                } else {
                    setIsLoading(false);
                    setDisableSubmit(false);
                    toast.error('Something went wrong please try again');
                }
            }
        } else {
            setDisableSubmit(false);
        }
    }
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}
            <Container style={{ marginTop: 42 }}>
                <Row>
                    <Col lg={4} style={{ paddingLeft: 0 }}>
                        <Title>Roles</Title>
                    </Col>
                    <Col lg={5} style={{ paddingRight: 0 }}>
                        <div className="row_div">
                            <div className="input_div" >
                                <input type="type" style={{ margin: 0, background: 'transparent' }}
                                    name="filterValue"
                                    onChange={(e) => handleSearch(e)}
                                    placeholder="Search" />
                                <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                            </div>

                        </div>
                    </Col>
                    <Col>
                        <button className="add_button" onClick={() => handleRole()}>
                            <p className="pcat_title"> {formTitle}</p></button>
                    </Col>

                </Row>
                <Row>

                    <Col lg={12} sm={6} md={6} className="table_head">
                        <Table className="tablelist" responsive>
                            <thead>
                                <tr>
                                    <th>Role Name</th>
                                    <th style={{ textAlign: 'right' }}>Action</th>
                                </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr>
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {tableData.length != 0 && <tbody>
                                {tableData.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                                <td>{obj.Role}</td>
                                                <td style={{ textAlign: 'right' }}>
                                                    <div className="row_table_edit " onClick={() => handleEditRole(obj)}>
                                                        <img src={Edit} />
                                                        <p>EDIT</p>
                                                    </div>
                                                    <div className="row_table_edit " onClick={() => handleDeleteRole(obj)} style={{ marginRight: 10 }}>
                                                        <img src={Delete} />
                                                        <p>DELETE</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                            }

                        </Table>
                        <div>
                        <div className="pagination_row_div">
                            <p className="pagination_title">Showing items per page:</p>
                            <div className="pagination_input_div" >
                                <select className="pagination_select"
                                 style={{ margin: 0, background: 'transparent' }}
                                    name="pagination_value"
                                    value={perPage}
                                    onChange={handlerowsperPage} >
                                    <option value={4}>4</option>
                                    <option value={8}>8</option>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                </select>
                                </div>
                        </div>
                        <div className="pagination_row_right_div">
                        <p className="pagination_total">Showing {offset+1} to {searchData.length<offset+perPage&&
                        <span>{searchData.length}</span> }
                        {searchData.length>offset+perPage&&
                        <span>{offset+perPage }</span>} of {searchData.length} items</p>
                         <ReactPaginate
                            previousLabel={<ArrowBackIosIcon  className="pagination_prev_btn" color="#2438a7"/>}
                            nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7"/>}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"} 
                            forcePage={currentPage} />
                    </div>
                    </div>
                    
                    </Col>
                </Row>
                <Modal show={addRole} onHide={handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    dialogclassname="modal-content">
                    <Modal.Header closeButton>
                        <Modal.Title> Role</Modal.Title>
                    </Modal.Header>

                    <Modal.Body >
                        <div>
                            <label >Role Name</label>
                            <br />
                            <div className="input_div" >
                                <input placeholder='Enter Role Name'
                                    value={roleName}
                                    onChange={handelRoleName}
                                    style={{ width: "85%" }}
                                />
                            </div>
                            {roleNameErr != '' && <p className="alert-message">{roleNameErr}</p>}
                        </div>

                    </Modal.Body>
                    <Modal.Footer className="modal-footer">
                        <div className="row_div">
                            <button className="add_button" onClick={() => handleAddRole()} style={{
                                padding: 12,
                                height: 45,
                                margin: 10
                            }}>
                                <p className="pcat_title">{formButtonValue}</p></button>
                        </div>
                    </Modal.Footer>
                </Modal>
                </Container>
            <ToastContainer />

        </Wrapper>
    )
}
export default Role