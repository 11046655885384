import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Wrapper, } from "./styles";
import { UserContext } from "../../context"
import { FaRegEye, FaEyeSlash } from "react-icons/fa"
import { LoadingSpinner } from "../LoadingSpinner/index";
import { Row, Col,Container } from "react-bootstrap";
import SettleMint_logo from "../../images/SettleMint_logo.svg";
import API_Auth from "../../API_Auth";
import playApp from "../../images/play-app.png"
import GooglePlay from "../../images/play-btn.png"
import Carousel from 'react-bootstrap/Carousel';
import About_Project from "../../images/about-project.png";
import WhyImage from "../../images/why.png";
import About from "../../images/about.png";
import CopyRight from "../../elements/CopyRights";

// DONE
const PrivacyPolicy = () => {
    const navigate = useNavigate();

    const [email, setUserEmail] = useState("");
    const [feedback_msg, setfeedback_msg] = useState("");

    const [emailErr, setemailErr] = useState("");
    const [feedback_msgErr, setfeedback_msgErr] = useState("");

    const [isLoading, setIsLoading] = useState(false);



    const handleInput = (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        if (name === "email") setUserEmail(value);
        if (name === "feedback_msg") setfeedback_msg(value);
    };

    const handlesubmitFeedback = async() => {
        console.log(email);
        console.log(feedback_msg);
        let error = false;
            setemailErr('');
        if (email != '') {
            let pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
            if (!pattern.test(email)) {
                setemailErr('Please enter valid email.');
                error = true;
            } else {
                setemailErr('');
            }
        } else if (email == '') {
            setemailErr('Email is required.');
            error = true;
        }
         if (feedback_msg == '') {
             error = true;
             setfeedback_msgErr("This Field is required");
         } else {
             error = false
             setfeedback_msgErr('')
         }
         if (!error) {
          
        }
    };
    return (
        <Wrapper>
            {isLoading && <LoadingSpinner />}
     
                <Container>
                <Row>
                <Col lg={12} className="text_center margin_top_btm" >
                <h1 class="pseudo_border">Privacy Policy</h1>
                </Col>
                </Row>
                <Row className="margin_top_btm">
            <Col  lg={12}>
            <p>This app is developed and designed by designed and developed by Settlemint India Services Pvt Ltd on 
                behalf of Department of Agriculture, state of Jharkhand (hereinafter referred to as "Settlemint India"),
                 an exclusive owner of the said web & mobile application, with an aim to provide facilitation between the
                  "Distributor" and the "Farmer" who are bound by the various policies incorporated by reference in this
                   Agreement.</p>

				  <p>For the purpose of these Terms of Use, wherever the context so requires 'You' or 'User' shall mean any natural or 
                    legal person who agrees to become a User by using the Jharkhand Agriculture Allied App by providing true, accurate, current and 
                    complete Registration Data while
                     registering on the App as Registered User. The term 'We', 'Us', 'Our' shall mean Settlemint India.</p>


				  <p>By merely registering under the Jharkhand Agriculture Allied App for using the services being offered through the App,
                     you shall be deemed to have contracted with Settlemint India and have agreed to be bound by the Terms of Use herein 
                     contained including the various policies incorporated by reference in this User Agreement. 
                     As long as you comply with these Terms of Use, we shall grant you a personal, non-exclusive, non-transferable, 
                    limited privilege to enter and use the App. You access and use the Service at your own risk.
                  </p>

<p>Settlemint India reserves the right, at its sole discretion, to change, modify, add or remove portions of these 
    Terms of Use, at any point of time without prior notice. If Settlemint India makes a change in the Terms of Use,
     it will post the revised Terms of Use on its website namely “ website” and thus, it is recommended that you can
      review the Terms of Use on a regular basis at Settlemint India website and you understand and agree that your 
      continued use of the Service after the changed Terms of Use constitutes your acceptance of the revised Terms of Use. Without limiting the foregoing, if Settlemint India makes a change to the Terms of Use that materially 
    impacts your use of the Service, Settlemint India may post notice of any such change on its website.
</p>
<p className="strong_text"><strong>YOUR ACCOUNT AND REGISTRATION OBLIGATION</strong></p>

<p>The terms of the Use shall be binding from the date when you open an account with the Jharkhand Agriculture Allied App.
</p>
<p>You are solely and entirely responsible for maintaining the confidentiality and security of your Account,
     Display Name and password and for restricting access to your mobile device. You agree that if You provide any
      information that is untrue, inaccurate, not current or incomplete or we have reasonable grounds to suspect that 
      such information is untrue, inaccurate, not current or incomplete, or not in accordance with the this Terms of Use,
       we shall have the right to indefinitely suspend or terminate or block your access to the App. You agree to
        accept responsibility for all activities that occur under your account or password. We shall not be liable to
         any person for any loss or damage which may arise as a result of any failure by you to protect your password
     or account and the loss or damage arising there from.
</p>
<p>If you know or suspect that someone unauthorized knows your password or any other security breach, then you should
     notify us by contacting us immediately in no event later than 24 hours through the address provided in the 
     'Contact Us' section of the website. If Settlemint India has reason to believe that there is likely to be a breach 
     of security or misuse of the App, we may require you to change your password or we may suspend your account without
      any liability to Settlemint India and without any prior notice to you. Without prejudice to the above, Settlemint
       India reserves the right to recover from you any and all damages, losses or costs that are levied on or suffered 
       by Settlemint India due to misuse of your account or password whether due to reasons attributable to you or not.
</p>
<p className="strong_text"><strong>Platform for Transaction</strong></p>

<p>The Jharkhand Agriculture Allied App is a platform which is made available to be used by Registered Merchant who manages all
     his ledgers. However, Settlemint India is not and cannot be a party 
    to or control in any manner in any transactions </p>
<p> The Service, or any feature or part thereof, may not be available in all languages or in all countries and Settlemint
     India makes no representation that the Service, or any feature or part thereof, is appropriate or available for use 
     in any particular location. To the extent you choose to access and use the Service, you do so at your own initiative
      and are responsible for compliance with any Applicable laws, including, but not limited to, any Applicable local 
      laws.
</p>
<p> Settlemint India does not at any point of time during any transaction between Merchant(s) and User 
    on the App come into or take possession of any of the products or services offered by Merchants nor does it
     at any point gain title to or have any rights or claims over the products or services offered by 
     Merchants to Users.</p>
     <p className="strong_text"><strong>Refund Policy</strong></p>

<p>All Services on the Jharkhand Agriculture Allied platform are on the prepaid basis with no refund or exchange permitted in
     any case.
</p>
<p className="strong_text"><strong>Cancellation Policy</strong></p>

<p>Settlemint India holds the power to cancel the subscription of any user. In case if the user wants to cancel
     the subscription, He or She will have to contact Settlemint India.
</p>
<p className="strong_text"><strong>Content</strong></p>

<p>"Content" shall mean any information related to transactions are contained on Jharkhand Agriculture Allied app by the Merchant(s). 
    You understand that all Content whether publicly posted or privately transmitted through the Service is
     the sole responsibility of the Merchant who posted such content on Jharkhand Agriculture Allied App. 
     This means that the Merchant, and not Settlemint India, are solely responsible for any Content that is uploaded,
      downloaded, posted, emailed, transmitted, stored or otherwise made available through your use of the Service. 
      Settlemint India does not control the data transmitted via App, nor does it guarantee the accuracy, integrity 
      or accuracy of such Content.
     You understand and agree that your use of the Service and any Content is solely at your own risk. </p>
<p>
You hereby agree to grant Settlemint India a non-exclusive, royalty-free, sub licensable, non-transferable and
 irrevocable permission to use, display, copy,
 distribute, publish, perform, transmit and broadcast any Content that is submitted through the Service </p>

<p>User acknowledges that "Settlemint India"/ its employees/ partners shall not be treated as publisher or 
    speaker of any information provided by 
    the Merchant and displayed on "Jharkhand Agriculture Allied" and witnessed by the Users. </p>
    <p className="strong_text"><strong>Typographical Errors</strong></p>

<p> The material in this site could include technical inaccuracies or typographical errors.
     Settlemint India may make changes or improvements at any time.</p>
     <p className="strong_text"><strong>Use of the Jharkhand Agriculture Allied App</strong></p>

<p>You agree, undertake and confirm that your use of Jharkhand Agriculture Allied App shall be strictly governed by 
    the following binding principles:
</p>
<p>
You agree not to use any device, software or routine to interfere or attempt to interfere with the proper
 working of the App or any transaction being conducted on the App, or with any other person's use of the App;
</p>
<p>
You may not use the App or any content for any purpose that is unlawful or prohibited by these Terms of Use, 
or to solicit the performance of any illegal activity or other activity which infringes the rights of Settlemint India  
</p>
<p className="strong_text"><strong>Privacy</strong></p>
<p>
We view protection of your privacy as a very important principle. We understand clearly that You and Your 
Personal Information is one of our most important assets. We store and process Your Information including any sensitive 
financial information collected, if any,
 on computers that may be protected by physical as well as reasonable technological security measures and procedures.   
</p>
<p>
We may disclose personal information if required to do so by law or in the good faith belief that such disclosure is
 reasonably necessary to respond to subpoenas, 
court orders, or other legal process.   
</p>
<p className="strong_text"><strong>Disclaimer and Warranty Information</strong></p>
<p>
Settlemint India assumes no risk or responsibility for your use of any service. Settlemint India service is designed
 to provide general guidance. It is the user's and merchantâ€™s responsibility to comply with all applicable laws and 
 insurance requirements. 
Settlemint India makes no warranties to those defined as consumers in the Consumer Protection Act.   
</p>
<p className="strong_text"><strong>Trademark, Copyright and Restriction</strong></p>
<p>
All material on the App, including images, illustrations, audio clips, and video clips, are protected by copyrights,
 trademarks, and other intellectual property rights.   
</p>
<p>
All content included on this App, such as text, graphics, logos, button icons, images, audio clips, digital downloads,
 data compilations, and software, is the property of Settlemint India or its Sellers, unless owned by any third party and
  protected by applicable copyright laws. All other such content not owned by Settlemint India that appear on this site 
  are the property of their respective owners. All software used on this App is the property of Settlemint India and is 
  protected by applicable copyright laws.
 Any unauthorized copying or reproduction of the above identified material is strictly prohibited by law.   
</p>
<p>
Material on App is solely for your personal, non-commercial use. You must not copy, reproduce, republish, upload, post,
 transmit or distribute such material in any way, including by email or other electronic means and whether directly or 
 indirectly and you must not assist any other person to do so. Without the prior written consent of the owner,
  modification of the materials, use of the materials on any other website or networked computer environment or use of
   the materials for any purpose other than personal, non-commercial use is a violation of the copyrights, trademarks and
    other proprietary rights, and is prohibited. Any use for
 which you receive any remuneration, whether in money or otherwise, is a commercial use for the purposes of this clause.
</p>
<p className="strong_text"><strong>Indemnification</strong></p>
<p> You agree to indemnify, defend, and hold harmless Settlemint India, its officers, directors, employees, agents,
     licensors and suppliers (collectively the Provider) from and against all losses, expenses, damages and costs,
      including reasonable attorneys fees, resulting from any violation of these Terms of Use or any activity related to 
      your Internet account (including negligent or wrongful conduct),
     by you or any other person accessing the Site using your Internet account. </p>
<p>You agree to indemnify, hold harmless, and defend Settlemint India, its officers, members, employees, agents, 
    and directors for any and all claims, demands, causes of action, debt, liabilities, damages, costs, or expenses, 
    including costs and reasonable attorney fees arising out of or in relation to your use of the Service, your violation
     of a term or provision of this Agreement or your violation of the rights of a third party. You agree that your
      obligation to hold harmless, defend, 
    and indemnify Settlemint India will survive the termination or failure of this Agreement and your use of the Service.  </p>

<p> Settlemint India PROVIDES THE SERVICE ON AN AS-IS BASIS AND YOU USE THE SERVICE AT YOUR OWN RISK. Settlemint India
     PROVIDES THE SERVICE WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING BUT NOT LIMITED TO 
     WARRANTIES OF MERCHANTABILITY, WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE, WARRANTIES OF NON-INFRINGEMENT, 
     WARRANTIES OF TITLE OR WARRANTIES ARISING FROM COURSE OF DEALING OR COURSE OF PERFORMANCE. ANY ATTEMPTS 
    BY Settlemint India TO MODIFY THE SERVICE WILL NOT BE DEEMED TO BE A WAIVER OF THIS LIMITATION OF LIABILITY. </p>
<p>Applicable Law Terms of Use shall be governed by and interpreted and construed in accordance with the laws of India.
     You agree that jurisdiction over and venue in any legal proceeding directly or indirectly arising out of or relating
      to this Site (including but not limited to the purchase of Settlemint India products) shall be in the courts of
       Delhi. Neither the course of conduct between the parties nor trade practice shall act to modify any of these terms
        and conditions. Settlemint India may assign its rights and duties under this Agreement to any party at any time 
        without notice to you. Settlemint India makes no representation that materials in the Site are appropriate or 
        available for use in other locations, and access to them from territories where their content is illegal is 
        prohibited. Those who choose to access this Site from locations outside of Delhi do so, on their own initiative 
        and are responsible for compliance with Applicable local laws.  </p>
<p> Limitation of Liability In no event Settlemint India shall be liable for any special, incidental, indirect or
     consequential damages of any kind in connection with these terms of use, even if user has
      been informed in advance of the possibility of such damages. </p>
      <p className="strong_text"><strong>Termination</strong></p>
<p> These Terms of Use are Applicable to you upon your accessing this Service. The service is effective until terminated 
    by you or us. Your rights under this agreement will terminate automatically without notice from us if you fail to
     comply with any term(s) of this agreement. Upon termination of the agreement, you shall cease all use of the Service.
      These Terms of Use, or any of them, may be modified or terminated by Settlemint India without notice at any time
       for any reason. The provisions relating to Copyrights and Trademarks, Disclaimer, Claims,
     Limitation of Liability, Indemnification, Applicable Laws, Arbitration and General, shall survive any termination. </p>
<p> PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING. By impliedly or expressly accepting these Terms of Use,
     You also accept and agree to be bound by Settlemint India Policies as amended from time to time.
</p>
<p className="strong_text"><strong>Contact Us</strong></p>
<p>Please contact us for any questions or comments (including all inquiries unrelated to copyright infringement) 
    regarding this App.
</p>
                </Col>
                </Row>
            </Container>
            <div class="jumbotron form-sec mt-5 mb-3">
            <div class="row h-100 justify-content-center mr-0 align-items-center">
                <div class="col-12 col-md-8 col-lg-5 " style={{paddingTop:44,paddingBottom:44}}>
                    <h1 class="text-center mb-4">Share your feedback</h1>
                     {/*  <div class="form-group">
                        <label for="email">E-mail</label>
                        <input type="email" class="form-control" id="email"
                               placeholder="Eg: abc@gmail.com" required />
                        <div class="valid-feedback">
                          Looks good!
                        </div>
                        <div class="invalid-feedback">
                         <img src="https://seedtraceability.settlemint.dev/images/homepage/images/error.svg"/> Please enter a valid email address.
                        </div>
                      </div> */}
                       {/*  <div class="form-group">
                            <label for="exampleFormControlTextarea1">Enter your message</label>
                            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" 
                            placeholder="Please enter your message here"></textarea>
                          </div>
                         */}  
                         <label >E-mail</label>
                            <br />
                            <div className="input_div" >
                            <input
                                    style={{ width: "85%",marginTop: 8}}
                                    placeholder="Eg: abc@gmail.com" 
                                    name="email"
                                    type="email"
                                    value={email}
                                    onChange={handleInput} />
                            </div>
                            {emailErr != '' && <p className="alert-message">{emailErr}</p>} 
                          <div style={{marginTop: 22}}>
                            <label>Enter your message</label>
                            <div className="input_div" >
                                 <textarea
                                        value={feedback_msg}
                                        onChange={handleInput}
                                        style={{ width: "85%",height: 130,marginTop: 8}}
                                        name="feedback_msg" 
                                        placeholder="Please enter your message here"
                                        rows={5}
                                        cols={5}
                                    />
                            </div>
                         {feedback_msgErr != '' && <p className="alert-message">{'Please enter your message'}</p>} 
                        </div> 
                        <button onClick={handlesubmitFeedback} style={{marginLeft: 20,marginTop: 22}} class="btn btn-primary btn-customized">
                        <svg id="sent" xmlns="http://www.w3.org/2000/svg" width="24.224" height="24.371" viewBox="0 0 24.224 24.371">
  <g id="Group_49629" data-name="Group 49629">
    <path id="Path_653" data-name="Path 653" d="M77.934,71.248l3.788-3.843a.629.629,0,1,1,.9.883l-3.788,3.843,3.818,9.828,9.64-24.371L68.063,67.565Z" transform="translate(-68.063 -57.588)" />
  </g>
</svg> Send Message</button>            
                    </div> 
                    </div>
                    </div>
            <CopyRight/>
        </Wrapper>
    );
};

export default PrivacyPolicy;
