import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./styles"
import { Row, Container, Col } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import { useNavigate, Link } from "react-router-dom";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle"
import { LoadingSpinner } from "../LoadingSpinner/index";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import sort_ascending from "../../images/sort_ascending.png";
import sort_default from "../../images/sort_default.png";
import sort_descending from "../../images/sort_descending.png";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import moment from "moment"

const FarmersListDistrictWise = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    const [distributionWise, setDistributionWise] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);


    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(50);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey, setSearchKey] = useState('n');
    const [searchKeyTable, setSearchKeyTable] = useState('');
    const [districtId, setDistrictId] = useState('');
    const [districtData, setDistrictData] = useState([]);
    const[role,setRole]=useState('');
    const [totalDistributedQuantity, setTotalDistributedQuantity] = useState([]);
    const [totalmemberCount, setTotalmemberCount] = useState([]);
    const [totalfarmerBenifited, setTotalfarmerBenifited] = useState([]);
    const[seasonData,setSeasonData]=useState([]);
    const[season,setSeason]=useState('');
    const[start_date,setStartDate]=useState('');
    const [currentSort, setcurrentSort] = useState('default');

    const handleInput = async (e) => {
        const { name, value } = e.target;
        console.log(name,value);
        if (name == "start_date") {
            setStartDate(value)
        };
    }
    const handleSearch = (e) => {
        let filterValue = e.target.value;
        setCurrentPage(0);
        if (filterValue == '') {
            let offsetsearch = 0;
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setTableData(slice);
            setSearchData(orgtableData);
            setOffSet(offsetsearch);
            setCurrentPage(0);
            setSearchKey('n');
        }
        else {
            let offsetsearch = 0;
            const filteredData = orgtableData.filter(item => {
                return (item ?.blockName ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                item ?.memberCount?.toString().toLowerCase().includes(filterValue.toLowerCase()))
            });

            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            setOffSet(offsetsearch);
            setSearchKeyTable(filteredData);
            setTableData(slice);
            setSearchData(filteredData);
            setOrgTableData(filteredData);
        }
    }
    const onSortChange = (sort_value) => {
        if (currentSort === 'down'){
            console.log(sort_value);
            if(sort_value == 'memberCount'){
            tableData.sort((a, b) => a.memberCount - b.memberCount)
            }
           else if(sort_value == 'farmersBenifitedAdd'){
                tableData.sort((a, b) => a.farmersBenifited - b.farmersBenifited)
                }
                else if(sort_value == 'distributedQuantity'){
                    tableData.sort((a, b) => a.disrtributedQuantity - b.disrtributedQuantity)
                    }
              
            setcurrentSort('up');
            setTableData(tableData);
            console.log('tableData down',tableData);
        }
        else if (currentSort === 'up'){
            let offset = 0;
            console.log(orgtableData);
            var slice = orgtableData.slice(offset, offset + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setTableData(slice);
            setOffSet(offset);
            setCurrentPage(0);
            setcurrentSort('default');
        }
        else if (currentSort === 'default') {
            if(sort_value == 'memberCount'){
                tableData.sort((a, b) => b.memberCount - a.memberCount)
                }
               else if(sort_value == 'farmersBenifitedAdd'){
                    tableData.sort((a, b) => b.farmersBenifited - a.farmersBenifited)
                    }
                    else if(sort_value == 'distributedQuantity'){
                        tableData.sort((a, b) => b.disrtributedQuantity - a.disrtributedQuantity)
                        }
                           
            setTableData(tableData);
            setcurrentSort('down');
            console.log('tableData up',tableData);
        };
    };
    const handlehitthheAPI = async(api_type) => { 
        const startdate=moment(start_date).format("MM/DD/YYYY");
        setIsLoading(true);
           if(api_type == 'all'){
            const start_date = new Date();
            const defaultValue = start_date.toLocaleDateString('en-CA');
            console.log(defaultValue);
            setStartDate(defaultValue);
            let result = await API_Auth.getDashboardDistrictLevelData('');
           console.log('result',result);
            let result_farmers = await API_Auth.getFarmersListDistrict('');
            console.log('result_farmers',result_farmers);

            for(let i=0; i<result.length; i++){
                for(let j=0; j<result_farmers.length; j++){
                    if(parseInt(result_farmers[j]?.districtID)==parseInt(result[i]?.district)){
                        if(result_farmers[j]?.memberCount){
                        result[i]['memberCount'] = result_farmers[j]?.memberCount;
                        }
                        else{
                            result[i]['memberCount'] = 0; 
                        }
                    }
                }
            } 
        console.log(result);
        let totalfarmerBenifited = 0;
        let totaldisrtributedQuantity = 0;
        let totafarmerRegistered = 0;
        for(let i=0; i<result.length; i++){
            if(result[i]?.farmersBenifited){
                totalfarmerBenifited = totalfarmerBenifited+parseInt(result[i]?.farmersBenifited);
            }
             if(result[i]?.disrtributedQuantity){
                totaldisrtributedQuantity = totaldisrtributedQuantity+parseInt(result[i]?.disrtributedQuantity);
          }
          if(result[i]?.memberCount){
            totafarmerRegistered = totafarmerRegistered+parseInt(result[i]?.memberCount);
          }
        }
        setTotalmemberCount(totafarmerRegistered);
        setTotalDistributedQuantity(totaldisrtributedQuantity);
        setTotalfarmerBenifited(totalfarmerBenifited);

        
        let result_final = [];
            for(let i=0; i<result.length; i++){
               if(result[i].DistrictName?.toLowerCase()  != 'demo'){
                if(result[i].DistrictName?.toLowerCase() != null&&result[i]?.DistrictName  != ''){
                result_final.push(result[i]);
                }
               }
            }
            
            console.log("result", result_final);
            setDistributionWise(result_final);
            setSearchData(result_final);
            setIsLoading(false);
            //seting pagenation data
            var slice = result_final.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result_final.length / perPage));
            setOrgTableData(result_final);
            setTableData(slice);
           }
           else{
            let result = await API_Auth.getDashboardDistrictLevelData(startdate);
            console.log(result);
            let result_farmers = await API_Auth.getFarmersListDistrict(startdate);
            console.log(result_farmers);

            for(let i=0; i<result.length; i++){
                for(let j=0; j<result_farmers.length; j++){
                    if(parseInt(result_farmers[j]?.districtID)==parseInt(result[i]?.district)){
                        if(result_farmers[j]?.memberCount){
                            result[i]['memberCount'] = result_farmers[j]?.memberCount;
                            }
                            else{
                                result[i]['memberCount'] = 0; 
                            }
                        }
                    
                }
            } 
            console.log(result);
            let totalfarmerBenifited = 0;
            let totaldisrtributedQuantity = 0;
            let totafarmerRegistered = 0;
            for(let i=0; i<result.length; i++){
                if(result[i]?.farmersBenifited){
                    totalfarmerBenifited = totalfarmerBenifited+parseInt(result[i]?.farmersBenifited);
                }
                 if(result[i]?.disrtributedQuantity){
                    totaldisrtributedQuantity = totaldisrtributedQuantity+parseInt(result[i]?.disrtributedQuantity);
              }
              if(result[i]?.memberCount){
                totafarmerRegistered = totafarmerRegistered+parseInt(result[i]?.memberCount);
              }
            }
            setTotalmemberCount(totafarmerRegistered);
            setTotalDistributedQuantity(totaldisrtributedQuantity);
            setTotalfarmerBenifited(totalfarmerBenifited);
            let result_final = [];
            for(let i=0; i<result.length; i++){
               if(result[i].DistrictName?.toLowerCase()  != 'demo'){
                if(result[i].DistrictName?.toLowerCase() != null&&result[i]?.DistrictName  != ''){
                result_final.push(result[i]);
                }
               }
            }
            
            console.log("result", result_final);
            setDistributionWise(result_final);
            setSearchData(result_final);
            setIsLoading(false);
            //seting pagenation data
            var slice = result_final.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result_final.length / perPage));
            setOrgTableData(result_final);
            setTableData(slice);
           }
        
    }
        useEffect(() => {
        setIsLoading(true);
        
        const DistrictID = localStorage.getItem("DistrictID");
        console.log("DistrictID",DistrictID)
        setDistrictId(DistrictID);
        const role=localStorage.getItem('loggedRole')
        console.log("role",role)
        setRole(role)
        if(role.toLocaleLowerCase()=="dao"){
            setDistrictId(DistrictID);
            }
            else{
                setDistrictId(1);
            }
            const start_date = new Date();
            const defaultValue = start_date.toLocaleDateString('en-CA');
            console.log(defaultValue);
            setStartDate(defaultValue);
            const startdate=moment(defaultValue).format("MM/DD/YYYY");
        const fetchDistributionWise = async () => {
            console.log("role",role)

            const seasonsresult=await API_Auth.getSeasons();
            setSeasonData(seasonsresult);
            let districts_result = await API_Auth.getDistricts();
            console.log("districts_result", districts_result)
            setDistrictData(districts_result);
         
            let result = await API_Auth.getDashboardDistrictLevelData(startdate);
            console.log(result);
            let result_farmers = await API_Auth.getFarmersListDistrict(startdate);
            console.log(result_farmers);

            for(let i=0; i<result.length; i++){
                for(let j=0; j<result_farmers.length; j++){
                    if(parseInt(result_farmers[j]?.districtID)==parseInt(result[i]?.district)){
                        result[i]['memberCount'] = result_farmers[j]?.memberCount;
                    }
                }
            } 
            console.log(result);
            let totalfarmerBenifited = 0;
            let totaldisrtributedQuantity = 0;
            let totafarmerRegistered = 0;
            for(let i=0; i<result.length; i++){
                if(result[i]?.farmersBenifited){
                    totalfarmerBenifited = totalfarmerBenifited+parseInt(result[i]?.farmersBenifited);
                }
                 if(result[i]?.disrtributedQuantity){
                    totaldisrtributedQuantity = totaldisrtributedQuantity+parseInt(result[i]?.disrtributedQuantity);
              }
              if(result[i]?.memberCount){
                totafarmerRegistered = totafarmerRegistered+parseInt(result[i]?.memberCount);
              }
            }
            setTotalmemberCount(totafarmerRegistered);
            setTotalDistributedQuantity(totaldisrtributedQuantity);
            setTotalfarmerBenifited(totalfarmerBenifited);

            let result_final = [];
            for(let i=0; i<result.length; i++){
               if(result[i].DistrictName?.toLowerCase()  != 'demo'){
                if(result[i].DistrictName?.toLowerCase() != null&&result[i]?.DistrictName  != ''){
                result_final.push(result[i]);
                }
               }
            }
            
            console.log("result", result_final);
            setDistributionWise(result_final);
            setSearchData(result_final);
            setIsLoading(false);
            //seting pagenation data
            var slice = result_final.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result_final.length / perPage));
            setOrgTableData(result_final);
            setTableData(slice);
        };
        // call the function
        fetchDistributionWise()
            // make sure to catch any error
            .catch(console.error);
    }, []);
    const handlerowsperPage = (e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(searchKeyTable);
        }
        else {
            const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(orgtableData);
        }
    }
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        const data = orgtableData;
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setOffSet(offsetdata);
            setSearchData(searchKeyTable);
        } else {
            const slice = data.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(data.length / perPage))
            setOffSet(offsetdata);
            setTableData(slice);
            setSearchData(data);
        }
    }
    const exportToCSV = (csvData) => {

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        const fileExtension = '.xlsx';
        const fileName = 'DistrictLevelData'

        const ws = XLSX.utils.json_to_sheet(csvData);

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        const data = new Blob([excelBuffer], { type: fileType });

        FileSaver.saveAs(data, fileName + fileExtension);
    }
    const formatter = new Intl.NumberFormat('en-IN')

    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}
            <Container>
                <Row>
                    <Col lg={3} style={{ paddingLeft: 0,marginTop:25 }}>
                        <Title>District Level Data</Title>
                    </Col>
                    <Col lg={3} style={{ paddingLeft: 0}}>
                        <div className="inputdiv">
                            <label style={{marginTop:15,}}>Date</label>
                            <br />
                            <input type="date"
                                name="start_date"
                                value={start_date}
                                placeholder="dd/mm/yyyy"
                                onChange={handleInput} />
                        </div>
                    </Col>
                    <Col lg={2} style={{ paddingRight: 0,marginTop:40 }}>
                          <button
                            className="add_button"
                            onClick={()=>handlehitthheAPI('date')}>
                            <p className="pcat_title">Fetch Data</p>
                        </button>
                    </Col>
                    <Col lg={2} style={{marginTop:40 }}>
                          <button
                            className="add_button"
                            style={{background: 'antiquewhite'}}
                            onClick={()=>handlehitthheAPI('all')}>
                            <p className="pcat_title">Clear Filter</p>
                        </button>
                    </Col>
                    <Col lg={2} style={{ paddingRight: 0,marginTop:40 }}>
                        <button
                            className="export_button"
                            onClick={(e) => exportToCSV(orgtableData)}>
                            <p className="pcat_title">Export CSV</p>
                        </button>
                    </Col>
                    </Row>
            </Container>
            <Container style={{marginTop:6}} >
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
                        <Table className="tablelist" responsive>
                            <thead>
                                <tr className="tablelhdr_img">
                                  <th>District</th>
                                    <th>Farmers Registered
                                    {currentSort=='up' && <img  onClick={()=>onSortChange('memberCount')} src={sort_default} />}
                                    {currentSort=='default' && <img  onClick={()=>onSortChange('memberCount')} src={sort_descending} />}
                                    {currentSort=='down' && <img  onClick={()=>onSortChange('memberCount')} src={sort_ascending} />}
                                    </th>
                                    <th>Farmers Benefited
                                    {currentSort=='up' && <img  onClick={()=>onSortChange('farmersBenifitedAdd')} src={sort_default} />}
                                    {currentSort=='default' && <img  onClick={()=>onSortChange('farmersBenifitedAdd')} src={sort_descending} />}
                                    {currentSort=='down' && <img  onClick={()=>onSortChange('farmersBenifitedAdd')} src={sort_ascending} />}
                                    </th>
                                    <th>Total Distributed
                                    {currentSort=='up' && <img  onClick={()=>onSortChange('distributedQuantity')} src={sort_default} />}
                                    {currentSort=='default' && <img  onClick={()=>onSortChange('distributedQuantity')} src={sort_descending} />}
                                    {currentSort=='down' && <img  onClick={()=>onSortChange('distributedQuantity')} src={sort_ascending} />}
                                    </th>
                                </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {tableData.length != 0 &&
                             <tbody>
                                {tableData.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                            
                                                <td>{obj.DistrictName}</td>
                                              {/*   {obj?.memberCount && */}
                                                <td>{formatter.format(obj?.memberCount?obj?.memberCount:0)}</td>
{/*                                                  }
                                                  {obj?.farmersBenifited && */}
                                                <td>{formatter.format(obj?.farmersBenifited?obj?.farmersBenifited:0)}</td>
                                                 {/*  }
                                                {obj?.disrtributedQuantity && */}
                                                <td>{formatter.format(obj?.disrtributedQuantity?obj?.disrtributedQuantity:0)}</td>
{/*                                                } */}
                                            </tr>
                                        </>
                                    );
                                })} 
                                <tr>
                                                {/*<td>{obj.id}</td> */}
                                                <td className="ptotal_title">Total:</td>
                                                <td className="ptotal_title">{formatter.format(totalmemberCount)}</td>
                                                <td className="ptotal_title">{formatter.format(totalfarmerBenifited)}</td>
                                                <td className="ptotal_title">{formatter.format(totalDistributedQuantity)}</td>
                                            </tr>                                           
                            </tbody>
                            }
                        </Table>
                        {tableData.length != 0 &&
                            <div>
                                <div className="pagination_row_div">
                                    <p className="pagination_title">Showing items per page:</p>
                                    <div className="pagination_input_div" >
                                        <select className="pagination_select"
                                            style={{ margin: 0, background: 'transparent' }}
                                            name="pagination_value"
                                            value={perPage}
                                            onChange={handlerowsperPage} >
{/*                                             <option value={4}>4</option> */}
                                            <option value={20}>20</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="pagination_row_right_div">
                                    <p className="pagination_total">Showing {offset + 1} to {searchData.length < offset + perPage &&
                                        <span>{searchData.length}</span>}
                                        {searchData.length > offset + perPage &&
                                            <span>{offset + perPage} </span>} of {searchData.length} items</p>
                                    <ReactPaginate
                                        previousLabel={<ArrowBackIosIcon className="pagination_prev_btn" color="#2438a7" />}
                                        nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7" />}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={currentPage} />
                                </div>
                            </div>
                        }                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </Wrapper>
    )
}
export default FarmersListDistrictWise