import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Container, Row, Col,OverlayTrigger,Tooltip } from "react-bootstrap";
import { Wrapper } from "./FindFarmer.styles";
import { LoadingSpinner } from "../LoadingSpinner/index";
import { Title } from "../../GlobalStyle";
import { useNavigate, useLocation } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import Delete from "../../images/delete.png";
import OtpTimer from "otp-timer";
import Table from "react-bootstrap/Table";
import Modal from 'react-bootstrap/Modal';

const FindFarmer = () => {
  const { isExpanded } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [disableSubmit, setDisableSubmit] = useState(false);

  const [adharErr, setAdharErr] = useState("");
  const [adhar, setAdhar] = useState("");
  const [finalErr, setFinalErr] = useState("");
  const [farmerData, setFarmerData] = useState("");
  const [show, setShow] = useState(false);
  const [qunatity, setQunatity] = useState("");
  const [qunatityErr, setqunatityErr] = useState("");
  const [username, setUserName] = useState("");
  const [userErr, setUserErr] = useState("");
  const [otpShow, setOtpShow] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpErr, setOtpErr] = useState("");
  const location = useLocation();
  const [adharshow, setAdharShow] = useState(false);
  const[tableData,setTableData]=useState([])

  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");

  const[showDetailsModal,setshowDetailModel]=useState(false);
  const[hashDetails,sethashDetail]=useState(false);

  const handleBackClick = () => {
    navigate(-1);
  };

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(60);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function(position) {
      setLat(position.coords.latitude);
      setLong(position.coords.longitude);
    });
    if (otpShow) {
      let myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(myInterval);
            setAdharShow(true);
            setOtpShow(false);
            console.log("otpadhar", "otpadhar");
          } else {
            setMinutes(minutes - 1);
            setSeconds(59);
          }
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  }, [seconds, otpShow]);
  const handleInput = e => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;
    if (name === "adhar") {
      console.log(value, value.length);
      if (value == "") {
        setAdhar("");
      } else {
        let cc = value.split("-").join("");

        cc = cc.match(new RegExp(".{1,4}$|.{1,4}", "g")).join("-");
        console.log(cc);
        setAdhar(cc);
      }
    }
    if (name == "qunatity") setQunatity(value);
    if (name == "otp") setOtp(value);
  };

  const handleVerifyAdhar = async () => {
    let error = false;
    let a = adhar.split("-").join("");
    console.log(a);

    if (adhar == "") {
      error = true;
      setAdharErr("Please Enter Aadhaar No");
    }
    else if (adhar.length<14) {
      error = true;
      setAdharErr("Please Enter Valid Aadhaar No");
      setFinalErr(" ");
    } 
    else {
      error = false;
      setAdharErr("");
    }
    if (!error) {
      setIsLoading(true);
      //setDisableSubmit(true);
      setFinalErr(" ");
      let result = await API_Auth.getFarmerDetail(a,"GETFARMER");
      console.log(result);
      setIsLoading(false);
      if (result.data.length != 0) {
        setFarmerData(result.data[0]);
        setTableData(result.data);
        const list = [...result.data];
        let districtdata = await API_Auth.getDistricts();
        for(let i=0; i<result.data.length; i++){
          const data = districtdata.filter((item) => item.id == result.data[i].district);
          console.log(data);
          list[i]["districtName"] = data[i]?.v_districtName;
           }
           console.log(list);
           setShow(true);
           const DistrictID = localStorage.getItem("DistrictID");
           const role=localStorage.getItem('loggedRole')
           if(role.toLocaleLowerCase()=="mis"){
          if (DistrictID.toString() == result.data[0].district) {
          setFinalErr("");
          setShow(true);
          setUserName(result.data[0].name);
        } else {
          setFinalErr("Districts are not same");
          setShow(false);
        } 
      }
      } else {
        setShow(false);
        setFinalErr("Farmer Not exists");
      }
    }
  };
  const handleShowDetails = async(obj) => {
    let data = await API_Auth.gettransactionHashDetails(obj.txhash);
    console.log(data);
    sethashDetail(data);
    setshowDetailModel(true);
};
const handleClose =() => {
    setshowDetailModel(false);
}
  return (
    <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
      {isLoading && <LoadingSpinner />}
      <div></div>
      <Container>
        <Row>
          <Col lg={2} style={{marginTop:32}}>
            <div className="back_button" onClick={() => handleBackClick()}>
              <IoIosArrowBack size={18} />
              <h6>Back</h6>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Title>Find Farmer</Title>
          </Col>
        </Row>
        <hr />
      </Container>
      <Container>
        <Row>
          <Col lg={3}></Col>
          <Col lg={4}>
            <div className="inputdiv">
              <label> Enter Aadhaar No</label>
              <br />
              <input
                name="adhar"
                data-type="adhaar-number"
                value={adhar}
                maxLength={14}
                placeholder="AAAA-BBBB-CCCC"
                id="adharnumber"
                onChange={handleInput}
                style={{ width: "93%" }}
              ></input>
              {adharErr != "" && <p className="alert-message">{adharErr}</p>}
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={4}></Col>
          <Col lg={3}>
            {finalErr != "" && <p className="alert-message">{finalErr}</p>}
          </Col>
        </Row>
        <Row>
          <Col lg={4}></Col>
          <Col lg={3}>
            <button
              className="add_button"
              disabled={disableSubmit}
              onClick={() => handleVerifyAdhar()}
              style={{ marginLeft: 0, width: "40%", height: 40 }}
            >
              <p style={{ marginTop: -8, fontWeight: "500", fontSize: 16 }}>
                {" "}
                Submit
              </p>
            </button>
          </Col>
        </Row>
        {show == true && (
          <>
            <Row>
              <Col lg={12} sm={6} md={6} className="table_head">
                <Table className="tablelist" responsive>
                  <thead>
                    <tr>
                      <th> Name</th>
                      <th> Mobile</th>
                      <th> Father</th>
                      <th> Aadhaar No</th>
                      <th> Gender</th>
                      <th> KYC</th>
                      <th> Category</th>
                      <th> District</th>
                      <th> Village</th>
                      <th>CID</th>
                      <th>Txhash</th>
                    </tr>
                  </thead>
                  {tableData.length == 0 && (
                    <tbody>
                      <tr>
                        <td colSpan="12">
                          <p className="no_Data_table">No Data Found</p>
                        </td>
                      </tr>
                    </tbody>
                  )}
                  {tableData.length != 0 && (
                    <tbody>
                      {tableData.map((obj, index) => {
                        return (
                          <>
                            <tr key={index}>
                              {/*                                                 <td>{obj.id}</td> */}
                              <td>{obj.name}</td>
                              <td>{obj.mobile_no}</td>
                              <td>{obj.father_name}</td>
                              <td>{obj.aadhar_no}</td>
                              <td>{obj.Gender}</td>
                       <td>{obj.KYC}</td>
                       <td>{obj.CastCetegory}</td>
                       <td>{obj.district}</td>
                       <td>{obj.village}</td>
                       <td>
                                                <OverlayTrigger
                                                        key={obj.CID}
                                                        overlay={<Tooltip id={`tooltip-top`}>
                                                                {obj.CID}</Tooltip>}>
                                                 <span className="table_text_overflow">{obj.CID}</span>
                                                    </OverlayTrigger>
                                                    </td>
                                                <td>
                                                <OverlayTrigger
                                                        key={obj.txhash}
                                                        overlay={<Tooltip id={`tooltip-top`}>
                                                                {obj.txhash}</Tooltip>}>
                                                 <span className="table_text_overflow" style={{textDecoration: 'underline',color:'blue'}}
                                                 onClick={()=>handleShowDetails(obj)}>{obj.txhash}</span>
                                                    </OverlayTrigger>
                                                </td> 
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  )}
                </Table>
              </Col>
            </Row>
          </>
        )}
      </Container>
      <Modal show={showDetailsModal} onHide={handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    dialogclassname="modal-content">
                    <Modal.Header closeButton>
                        <Modal.Title> Transaction Details</Modal.Title>
                    </Modal.Header>

                    <Modal.Body >
                    <Row>
                            <Col lg={3}>
                                <p className="pcat_title">BlockHash:</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.blockHash}</p>
                            </Col>
                            </Row>
                            <Row>
                            <Col lg={3}>
                                <p className="pcat_title">BlockNumber :</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.blockNumber}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <p className="pcat_title">ChainId:</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.chainId}</p>
                            </Col>
                            </Row>
                            <Row>
                            <Col lg={3}>
                                <p className="pcat_title">From :</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.from}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <p className="pcat_title">Gas:</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.gas}</p>
                            </Col>
                            </Row>
                            <Row>
                            <Col lg={3}>
                                <p className="pcat_title">GasPrice :</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.gasPrice}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <p className="pcat_title">Hash:</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.hash}</p>
                            </Col>
                            </Row>
                            <Row>
                            <Col lg={3}>
                                <p className="pcat_title">Input :</p>
                            </Col>
                            <Col>
                            <OverlayTrigger
                                                        key={hashDetails.input}
                                                        overlay={<Tooltip id={`tooltip-top`}>
                                                                {hashDetails.input}</Tooltip>}>
                                                                <span className="pcat_title_overFlow">{hashDetails.input}</span>
                                                    </OverlayTrigger>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <p className="pcat_title">Nonce:</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.nonce}</p>
                            </Col>
                            </Row>
                            <Row>
                            <Col lg={3}>
                                <p className="pcat_title">To :</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.to}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <p className="pcat_title">V :</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.v}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <p className="pcat_title">R :</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.r}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <p className="pcat_title">S :</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.s}</p>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
      <ToastContainer />
    </Wrapper>
  );
};
export default FindFarmer;
