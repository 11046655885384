import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./styles"
import { Row, Container, Col,Button } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import Edit from "../../images/edit.png";
import plus from "../../images/white_plus.png"
import { useNavigate, useParams } from "react-router-dom";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle"
import { LoadingSpinner } from "../LoadingSpinner/index";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { AiOutlinePlusSquare, AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import Modal from 'react-bootstrap/Modal';
import View from "../../images/view.svg";

const ForecastDetails = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    const [foreCastData, setForeCastData] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [seedData, setSeedData] = useState([]);
    let { forecastID } = useParams();
    
    const [userRole, setUserRole] = useState();
    const [shownDrawerIndex, setshownDrawerIndex] = useState(null)
    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(50);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey, setSearchKey] = useState('n');
    const [searchKeyTable, setSearchKeyTable] = useState('');
    const [expandTable, setexpandTable] = useState(false);
    const [object_det, setObjectData] = useState([]);
    const [selectedSeed, setSelectedSeed] = useState([]);
    const [totalAllotQuantity, setTotalQuantity] = useState([]);
    const [totalQuantityErr, setTotalQuantityErr] = useState([]);
    const [editable, setnotEditable] = useState([]);
    const[forecastQunatity,setForecastQunatity]=useState('');
    const[showSubmitModal,setShowsubmittModal]=useState(false);
    const[submitObjectDet,setsubmittobjdet]=useState(false);
    const[showAddQuantity,setShowAddQuantity]=useState(false);
    const[AddedAllotment,setAddedAllotment]=useState();
    const[existingItem,setExcistingItem]=useState();
    const[AddedAllotErr,setAddedAllotErr]=useState();
    const[remainingTotalQuantity,setremainingTotalQuantity]=useState();

    const handleSearch = (e) => {
        let filterValue = e.target.value;
        setCurrentPage(0);
        if (filterValue == '') {
            let offsetsearch = 0;
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setTableData(slice);
            setSearchData(orgtableData);
            setOffSet(offsetsearch);
            setCurrentPage(0);
            setSearchKey('n');
        }
        else {
            let offsetsearch = 0;
            const filteredData = orgtableData.filter(item => {
                return (item ?.SeedName ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                            item ?.VarietyName ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                                    item ?.Quantity ?.toLowerCase().includes(filterValue.toLowerCase()))
            });

            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            setOffSet(offsetsearch);
            setSearchKeyTable(filteredData);
            setTableData(slice);
            setSearchData(filteredData);
        }
    }

    const handleEditForeCast = (obj) => {
        navigate("/add-user-detail/" + obj.id);
    }
    const handleAddForeCast = () => {
        navigate("/supply-order/" + forecastID);

    }

    useEffect(() => {
        setIsLoading(true);
        const user_role = localStorage.getItem("loggedRole");
        setUserRole(user_role);
        const fetchForecast = async () => {
            let result = await API_Auth.getForeCastDetailsBYID(forecastID);
            console.log("result", result);
            setForeCastData(result.forcastData[0]);
            setSearchData(result.SeedData);
            setSeedData(result.SeedData);
            setIsLoading(false);
            const list = [...result.SeedData];
            if(user_role?.toLowerCase() == "ao"&&result.forcastData[0].Status=="SentToAO"){
            for(let i=0; i<result.SeedData.length; i++){
                    list[i]["QuantityAo"] = result.SeedData[i].Quantity;
             }
            }
             console.log(list);
            //seting pagenation data
            var slice = list.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result.SeedData.length / perPage));
            setOrgTableData(result.SeedData);
            setTableData(slice);
        };
        // call the function
        fetchForecast()
            // make sure to catch any error
            .catch(console.error);
    }, []);
    const handlerowsperPage = (e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(searchKeyTable);
        }
        else {
            const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(orgtableData);
        }
    }
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        const data = orgtableData;
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setOffSet(offsetdata);
            setSearchData(searchKeyTable);
        } else {
            const slice = data.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(data.length / perPage))
            setOffSet(offsetdata);
            setTableData(slice);
            setSearchData(data);
        }
    }
    const showDrawyer = async (e, index, seedId) => {
        console.log(index,shownDrawerIndex);
        let districtID = localStorage.getItem("DistrictID");

         if (shownDrawerIndex == index) {
            let all_seds = [...seedData];
            //all_seds[index].items = undefined;
            let result = await API_Auth.getDistributorsWithSameDistricForDAO("distributor",seedId,districtID);
            let items = result;
            all_seds[index].items = items; 
            setSeedData(all_seds);
            console.log("show",all_seds);
            setshownDrawerIndex(null);
        }
       /*  else if(shownDrawerIndex){
            let all_seds = [...seedData];
            //all_seds[index].items = undefined;
            let result = await API_Auth.getDistributorUserByID("distributor",seedId);
            let items = result;
            all_seds[index].items = items; 
            setSeedData(all_seds);
            console.log("show",all_seds);
        }  */
        else {
             e.preventDefault();
            let all_seds = seedData;
            let result = await API_Auth.getDistributorsWithSameDistricForDAO("distributor",seedId,districtID);
            console.log(result);
            console.log("not show");
             let items = result;
             all_seds[index].items = items;
             setSeedData(all_seds);  
            setshownDrawerIndex(index);
            console.log(seedData);
        } 
    }
    const handleInput = (index,e,obj) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        console.log(obj,index);
        const list = [...object_det];
        const list1 = [...tableData];
        if (name === "AllotmentQuantity"){
            list[index]["AllotAddedQuantity"] = value;
         };
         if (name === "AddedAllotment"){
            setAddedAllotment(value);

         };
         if (name === "QuantityAo"){
            list1[index][name] = value;
         };
         setTableData(list1);
         let totalQuantity = 0;
          for(let i=0; i<list.length; i++){
          if(list[i]?.AllotAddedQuantity){
            totalQuantity = totalQuantity+parseInt(list[i]?.AllotAddedQuantity);
         }
         }
         setTotalQuantity(totalQuantity); 
         setObjectData(list);
         console.log(object_det);
    };
    const handleSubmitt = (obj) => {
        setShowsubmittModal(true);
        setsubmittobjdet(obj);
       /*  setTimeout(() => {
            navigate(0);
        }, 1000); */
    };
    const handleAddMoreQuantity = (item) => {
        setShowAddQuantity(true);
        setExcistingItem(item);
        let remainingtotal = 0 ;
        remainingtotal =  parseInt(forecastQunatity)-parseInt(totalAllotQuantity)
        setremainingTotalQuantity(remainingtotal);
    };
    const handleAddedAllottment = async() => {
        const list = [...object_det];
        console.log(list);
        console.log(existingItem);
        console.log(AddedAllotment);

    if(AddedAllotment){
        const list = [...object_det];
        console.log(list);
        console.log(existingItem);
        if(existingItem.AllotmentQuantity>=AddedAllotment){
            setAddedAllotErr('Added quantity is more than the excisting quantity ');
        }
        else{
            let tobeAddedQuantity = 0;
            tobeAddedQuantity = parseInt(AddedAllotment)-parseInt(existingItem.AllotmentQuantity);
            console.log(tobeAddedQuantity,remainingTotalQuantity);
            if(tobeAddedQuantity>remainingTotalQuantity){
                console.log('error');
                setAddedAllotErr('Quantity Exceeds');
            }
            else{
                let daoID = localStorage.getItem('UserID');
                const receive_seed = await API_Auth.getInsertAllotment(existingItem.id,selectedSeed.SeedID, 
                    selectedSeed.SeedVariety, tobeAddedQuantity, daoID,foreCastData.year,foreCastData.Season); 
                    console.log(receive_seed);
                    setAddedAllotErr('');
                    setAddedAllotment('');
                    setShowAddQuantity(false);
                    let  totalAllotment = parseInt(existingItem.AllotmentQuantity) +parseInt(tobeAddedQuantity);
                    let totalQuantity = 0;
                    console.log(totalAllotment);
                    for(let i=0; i<list.length; i++){
            if(list[i]?.AllotAddedQuantity){
            if(list[i]?.id==existingItem.id){
             list[i].AllotAddedQuantity = totalAllotment;
          }
          totalQuantity = totalQuantity+parseInt(list[i]?.AllotAddedQuantity);
          }
         } 
        setTotalQuantity(totalQuantity); 
        setObjectData(list);
        setTimeout(() => {
            navigate(0);
        }, 2000)
                       }
        }
    /*   let  totalAllotment = parseInt(existingItem.AllotmentQuantity) +parseInt(AddedAllotment);
      let totalQuantity = 0;
      console.log(totalAllotment);
        for(let i=0; i<list.length; i++){
            if(list[i]?.AllotmentQuantity){
            if(list[i]?.id==existingItem.id){
             list[i].AllotmentQuantity = totalAllotment;
          }
          totalQuantity = totalQuantity+parseInt(list[i]?.AllotmentQuantity);
          }
         } 
        setTotalQuantity(totalQuantity); 
        setObjectData(list);
     */   // setShowAddQuantity(false);
        //setAddedAllotErr('');
      //  setAddedAllotment('');
    }
    else{
        setAddedAllotErr("Pleas add quantiy");
    }
    };
    const handleClose = () => {
        setexpandTable(false);
        setTotalQuantityErr('');
    }
    const handleClosesubmitt = () => {
        setShowsubmittModal(false);
        setShowAddQuantity(false);
    }
    const handleFinalSubmitt = () => {
        setShowsubmittModal(false);
        setTotalQuantityErr('');
        let daoID = localStorage.getItem('UserID');
        console.log(selectedSeed);
        console.log(submitObjectDet);

        if(totalAllotQuantity <= selectedSeed.QuantityAo){
            console.log(submitObjectDet,foreCastData);
           submitObjectDet.map(async function (app, key) {
               if(!app.AllotmentQuantity){
             if(app.AllotAddedQuantity&&parseInt(app.AllotAddedQuantity)>0){
              console.log(app.id,app.AllotAddedQuantity)
               const receive_seed = await API_Auth.getInsertAllotment(app.id,selectedSeed.SeedID, 
              selectedSeed.SeedVariety, app.AllotAddedQuantity, daoID,foreCastData.year,foreCastData.Season); 
              console.log(receive_seed);
              setexpandTable(false);
              setTimeout(() => {
                  setexpandTable(false);
                  navigate(0);
              }, 2000)
            }
               }
        }); 
         if(!expandTable){
         toast.success('Allotted successfully');
         }
     }
     else{
  setTotalQuantityErr("Allotted Quantity is more than approved quantity ");
     }    }
    const handleSubmittToDao = async() => {
        var quantiyarray=[];
        var varietyyarray=[];
        console.log("tableData",tableData);
        for (var i = 0; i < tableData.length; i++) {
            let variety_ids = tableData[i]["SeedVariety"];
            let approvedquantity = tableData[i]["SeedVariety"] + "-" + tableData[i]["QuantityAo"]  ;
            varietyyarray.push(variety_ids);
            quantiyarray.push(approvedquantity);
            console.log("approvedquantity",varietyyarray);
            console.log("variety_ids",quantiyarray);
       };
       let districtID = localStorage.getItem("DistrictID");
       let userID = localStorage.getItem("UserID");

      let addForecastByAo = await API_Auth.addForecastByAotoDao(quantiyarray,userID,forecastID,varietyyarray,"assignedToDAO");
      
      if (addForecastByAo[0].Flag == "Success") {
          setIsLoading(false);
          toast.success('Forecast added successfully');
          setTimeout(() => {
          navigate("/forecast-ao");
          }, 2000)
          }else {
          toast.error('Something went wrong');
            } 
    }; 
    
    const handleEditAllotment = async(obj,action) => {
        console.log(obj);
        setForecastQunatity(obj.QuantityAo)
        console.log(foreCastData)
        let districtID = localStorage.getItem("DistrictID");
        if(action=="view"){
         setnotEditable(true);
        }else{
            setnotEditable(false);
        };
        setSelectedSeed(obj);
        let result = await API_Auth.getDistributorsWithSameDistricForDAO("distributor",obj.SeedID,districtID,obj.SeedVariety);
        setexpandTable(true);
        setTotalQuantityErr('');
        console.log(result);
        let totalQuantity = 0;
          for(let i=0; i<result.length; i++){
            if(result[i]?.AllotmentQuantity){
             totalQuantity = totalQuantity+parseInt(result[i]?.AllotmentQuantity);
          }
         }
         const list = [...result];
         for(let i=0; i<list.length; i++){
            if(list[i].AllotmentQuantity){
                 list[i]["AllotAddedQuantity"] = list[i].AllotmentQuantity;
            }
          }
           setTotalQuantity(totalQuantity); 
          setObjectData(list);
         console.log(totalQuantity);
         setTotalQuantity(totalQuantity); 
    }
    const formatter = new Intl.NumberFormat('en-IN')
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}
            <Container style={{background: 'white',borderRadius: 8}}>
                <Row>
                    <Col lg={4} style={{ paddingLeft: 0 }}>
                        <Title style={{ marginLeft: 12 }}>Forecast Details</Title>
                    </Col>
                   {/*  <Col lg={5}>
                        <div className="row_div">
                            <div className="input_div" >
                                <input type="type" style={{ margin: 0, background: 'transparent' }}
                                    name="filterValue"
                                    //    value={filterValue}
                                    onChange={(e) => handleSearch(e)}
                                    placeholder="Search" />
                                <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                            </div>

                        </div>
                    </Col>
                    <Col lg={3} style={{ paddingRight: 0 }}>
                        <div>
                            <button className="add_button" onClick={() => handleAddForeCast()}>
                                <p className="pcat_title"> ADD A FORECAST</p></button>
                        </div></Col> */}
                </Row>
                <div className="row_div">
                    <Row>
                    <Row>
                     <Col lg={3}>
                     <p className="pcat_title">Name:</p>
                     </Col>
                      <Col>
                      <p className="pcat_text">{foreCastData.Name}</p>
                      </Col>
                        {/*  </Row> 
                        <Row> */}
                     <Col lg={3}>
                      <p className="pcat_title">Season :</p>
                      </Col>
                      <Col>
                     <p className="pcat_text">{foreCastData.Season}</p>
                      </Col>
                         </Row>
                         <Row>
                     <Col lg={3}>
                      <p className="pcat_title">Status :</p>
                      </Col>
                      <Col>
                      <p className="pcat_text">{foreCastData.Status}</p>
                      </Col>
                         {/* </Row>
                         <Row> */}
                     <Col lg={3}>
                      <p className="pcat_title">year :</p>
                      </Col>
                      <Col>
                    <p className="pcat_text">{foreCastData.year}</p>
                     </Col>
                         </Row>
                         </Row>
                         </div>
            </Container>
            <Container>
                <Row>
                    <Col lg={4} style={{ paddingLeft: 0 }}>
                        <Title>Seed Details</Title>
                    </Col>
                   {/*  <Col lg={5}>
                        <div className="row_div">
                            <div className="input_div" >
                                <input type="type" style={{ margin: 0, background: 'transparent' }}
                                    name="filterValue"
                                    //    value={filterValue}
                                    onChange={(e) => handleSearch(e)}
                                    placeholder="Search" />
                                <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                            </div>

                        </div>
                    </Col>
                    <Col lg={3} style={{ paddingRight: 0 }}>
                        <div>
                            <button className="add_button" onClick={() => handleAddForeCast()}>
                                <p className="pcat_title"> ADD A FORECAST</p></button>
                        </div></Col> */}
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
                        <Table className="tablelist" responsive>
                            <thead>
                                <tr>
                                    <th>Component Name </th>
                                    <th>Component Type</th>
                                    <th>Forecast Quantity</th>
                                    {userRole?.toLowerCase() == "ao"&&
                                    <th>Approved Quantity</th>
                                     }
                                    {userRole?.toLowerCase() == "dao"&&
                                    <>
                                    <th>AO Approved</th>
                                    <th>Allotted Quantity</th>

                                   <th style={{ textAlign: 'right' }}>Action</th> 
                                   </>
                                    }
                                </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                                {tableData?.map((obj, inde) => {
                                    return (
                                        <>
                                             <tbody>
                                            <tr key={inde}>
                                           {/*  <td class="pointer" onClick={(e) => showDrawyer(e, inde, obj.SeedID)} >
                                            <AiOutlinePlusSquare size="24" style={{ marginLeft: 10 }} />
                                                </td> */}
                                                <td>{obj.SeedName}</td>
                                                <td>{obj.VarietyName}</td>
                                                <td>{formatter.format(obj.Quantity)}</td>
                                                {userRole?.toLowerCase() == "dao"&&
                                                <>
                                                <td>{formatter.format(obj.QuantityAo)}</td>
                                                <td>{formatter.format(obj.distributedQuantity)}</td> 
                                                </>                                                     
                                                      }
                                                      {userRole?.toLowerCase() == "ao"&&
                                                <td><div className="input_div_table" style={{border: 'none'}} >
                                                <input type={'number'}
                                                 style={{ margin: 0, background: 'transparent',height: 32,fontSize: 18 }}
                                                    name="QuantityAo" className="inputdiv"
                                                    value={obj.QuantityAo}
                                                    disabled={foreCastData.Status=="assignedToDAO"}
                                                    onChange={(evnt) =>handleInput(inde,evnt,obj)}
                                                    placeholder="" />
                                                </div></td>
                                                      }
                                                {userRole?.toLowerCase() == "dao"&&
                                                <td style={{ textAlign: 'right' }}>
                                                    <div className="row_table_edit" style={{background: 'antiquewhite'}} onClick={() => handleEditAllotment(obj,"view")}>
                                                    <img src={View}/>
                                                    <p>VIEW</p>
                                                    </div>
                                                    {foreCastData.Status.toLowerCase() == "assignedtodao"&&
                                                    <div className="row_table_edit" onClick={() => handleEditAllotment(obj,"edit")} style={{ marginRight: 10,background: '#c1edc1',width:"40%" }}>
                                                        <img src={Edit} />
                                                        <p>ALLOTMENT</p>
                                                    </div> 
                                                     }
                                                </td>
                                            }
                                            </tr>
                                            </tbody>
                                            {obj.items&&
                                            <tr>
                                            {/* <td colspan="12" className="hiddenRow">
                                                {
                                                    (obj.items && obj.items.length == 0) && <>
                                                        <Table>
                                                            <tr>
                                                                <td colspan="12" className="hiddenRow">
                                                                <p style={{
                                                                    textAlign:"center",
                                                                    fontWeight:"bold",
                                                                    margin:10,
                                                                    color:'black'
                                                                }}>No Data Found</p>

                                                                </td>
                                                                </tr>
                                                        </Table>
                                                        </>
                                                                } 
                                            {
                                                                   (obj?.items && obj?.items?.length != 0) && <>

                                                                        <Table style={{ display: shownDrawerIndex == inde ? '' : 'none' }}>
                                                                            <thead>
                                                                                <th>First Name </th>
                                                                                <th>Last Name</th>
                                                                                <th>Email</th>
                                                                                <th>Allotted Quantity</th>
                                                                            </thead>
                                                                            <tbody>
                                                                                {obj?.items.map((item, index) => {
                                                                                    return (
                                                                                        <tr key={index} >
                                                                                            <td>{item.FirstName}</td>
                                                                                            <td>{item.LastName}</td>
                                                                                            <td>{item.email}</td>
                                                                                            <td><div className="input_div_table" >
                                        <input type={'number'}
                                         style={{ margin: 0, background: 'transparent', fontSize: 18 }}
                                            name="AllotmentQuantity" className="inputdiv"
                                            value={item.AllotmentQuantity}
                                           // disabled={status_type=="complete"}
                                            onChange={(evnt) =>handleInput(index,evnt,obj,inde)}
                                            placeholder="" />
                                        </div></td>
                                                                                        </tr>
                                                                                    )
                                                                                })}

                                                                            </tbody>
                                                                      
                                                                        </Table>
                                                                        <Row style={{ display: shownDrawerIndex == inde ? '' : 'none' }}>
                        <Col lg={4}>
                        </Col>
                        <Col lg={6}>
                        </Col>
                        <Col lg={2} style={{marginBottom:12}}>
                         <div>
                         <button className="add_button" style={{marginTop:0}} onClick={() => handleSubmitt(obj)}>
                         {/*<img src={plus} style={{ margin: 5 }} /> 
                         <p className="pcat_title">SUBMIT ALL</p></button>
                         </div>
                        </Col>
                        </Row>
                                                                    </>
                                                                }
                                            </td> */}
                                        </tr>
                                }
                             
                                        </>
                                    );
                                })}
                                 {userRole?.toLowerCase() == "ao"&&foreCastData.Status=="SentToAO"&&
                                <Row>
                               <div className="row_div">
                               <button className="add_button" onClick={() => handleSubmittToDao()} style={{
                                padding: 12,
                                height: 45,
                                margin: 10
                                }}>
                                {/*<img src={plus} style={{ margin: 5 }} /> */}
                                <p className="pcat_title">Approve Quantity</p></button>
                                </div>
                                </Row>
                                }
                        </Table>
                        {tableData.length != 0 &&
                            <div>
                                <div className="pagination_row_div">
                                    <p className="pagination_title">Showing items per page:</p>
                                    <div className="pagination_input_div" >
                                        <select className="pagination_select"
                                            style={{ margin: 0, background: 'transparent' }}
                                            name="pagination_value"
                                            value={perPage}
                                            onChange={handlerowsperPage} >
                                            <option value={20}>20</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="pagination_row_right_div">
                                    <p className="pagination_total">Showing {offset + 1} to {searchData.length < offset + perPage &&
                                        <span>{searchData.length}</span>}
                                        {searchData.length > offset + perPage &&
                                            <span>{offset + perPage} </span>} of {searchData.length} items</p>
                                    <ReactPaginate
                                        previousLabel={<ArrowBackIosIcon className="pagination_prev_btn" color="#2438a7" />}
                                        nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7" />}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={currentPage} />
                                </div>
                            </div>
                        }                    </Col>
                </Row>
                <Modal show={expandTable} onHide={handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    dialogclassname="modal-content">
                    <Modal.Header closeButton>
                        <Modal.Title>{editable?'Current Allotment':"Distributor Allotment"}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div>
                            <p className="pcat_title">AO Approved Quantity : {forecastQunatity}</p>
                            {object_det&&
                                                (object_det && object_det.length == 0) && <>
                                                        <Table responsive>
                                                            <tr>
                                                                <td colspan="12" className="hiddenRow">
                                                                <p style={{
                                                                    textAlign:"center",
                                                                    fontWeight:"bold",
                                                                    margin:10,
                                                                    color:'black'
                                                                }}>No Data Found</p>

                                                                </td>
                                                                </tr>
                                                        </Table>
                                                        </>
                                                                } 
                                            {
                                                                   (object_det && object_det.length != 0) && <>

                                                                        <Table responsive>
                                                                            <thead>
                                                                                <th>First Name </th>
                                                                                <th>Last Name</th>
                                                                                <th>Email</th>
                                                                                <th>Allotted Quantity</th>
                                                                            </thead>
                                                                            <tbody>
                                                                                {object_det?.map((item, index) => {
                                                                                    return (
                                                                                        <tr key={index} >
                                                                                            <td>{item.FirstName}</td>
                                                                                            <td>{item.LastName}</td>
                                                                                            <td>{item.email}</td>
                                                                                            <td><div className="input_div_table" >
                                        <input type={'number'}
                                         style={{ margin: 0, background: 'transparent',height: 32,width:'50%',fontSize: 18 }}
                                            name="AllotmentQuantity" className="inputdiv"
                                            value={item.AllotAddedQuantity}
                                           disabled={item.AllotmentQuantity}
                                            onChange={(evnt) =>handleInput(index,evnt,object_det)}
                                            placeholder="" />
                                            {item.AllotmentQuantity>0&&
                                                            <Button variant="primary"
                                                            onClick={()=>handleAddMoreQuantity(item)}>
                                                            Update
                                              </Button>
                                        }
                                        </div></td>
                                                                                        </tr>
                                                                                    )
                                                                                })}
                                                                            </tbody>
                                                                        </Table>
                                                                       {!editable&& <Row>
                        <Col lg={4}>
                        </Col>
                        <Col lg={4}>
                        </Col>
                        <Col lg={4} style={{marginBottom:12}}>
                         <div>
                         <p className="pcat_title">Total: {totalAllotQuantity}</p>
                         {totalQuantityErr&&
                         <p className="alert-message"> {totalQuantityErr}</p>}
                         </div>
                        </Col>
                        </Row> }
                                                                    </>
                                                                }
                        </div>

                    </Modal.Body>
                    {!editable&&
                    <Modal.Footer className="modal-footer" style={{marginTop:0}}>
                        <div className="row_div">
                            <button className="add_button" onClick={() => handleSubmitt(object_det)} style={{
                                padding: 12,
                                height: 45,
                                margin: 10
                            }}>
                                {/*<img src={plus} style={{ margin: 5 }} /> */}
                                <p className="pcat_title">Allot Quantity</p></button>
                        </div>
                    </Modal.Footer>
                         }
                </Modal>
                <Modal aria-labelledby="example-modal-sizes-title-sm" size="sm" show={showAddQuantity} onHide={handleClosesubmitt}>
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">      Update Seed Quantity (Increase) </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                         <div>
                         <p className="pcat_title">Previous Allotment to the Distributor: {existingItem?.AllotmentQuantity}</p>
                         <p className="pcat_title">Remaining Limit as per AO Approval: {remainingTotalQuantity}</p>
                         </div>
                    <input type={'number'}
                                         style={{ margin: 0, background: 'transparent',height: 32,width:'50%',fontSize: 18 }}
                                            name="AddedAllotment" className="inputdiv"
                                            value={AddedAllotment}
                                            onChange={(evnt) =>handleInput('',evnt,'')}
                                            placeholder="Enter Revised Quantity" />
                                            <p className="alert-message">{AddedAllotErr}</p>
                                            </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary"
                        onClick={handleAddedAllottment}>
                        Update
                   </Button>
                </Modal.Footer>
            </Modal>
           
           
           
            <Modal aria-labelledby="example-modal-sizes-title-sm" size="sm" show={showSubmitModal} onHide={handleClosesubmitt}>
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">Allot Seed</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure to allot seed ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosesubmitt}>
                        NO
          </Button>
                    <Button variant="primary"
                        onClick={handleFinalSubmitt}>
                        YES
          </Button>
                </Modal.Footer>
            </Modal>
            </Container>
            <ToastContainer />
        </Wrapper>
    )
}
export default ForecastDetails
