import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./styles"
import { Row, Container, Col, OverlayTrigger,Tooltip } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import { useNavigate, Link } from "react-router-dom";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle"
import { LoadingSpinner } from "../LoadingSpinner/index";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from "moment"
import * as FileSaver from 'file-saver';

import * as XLSX from 'xlsx';
import Modal from 'react-bootstrap/Modal';

const FarmersDetailsListAll = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(50);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey, setSearchKey] = useState('n');
    const [searchKeyTable, setSearchKeyTable] = useState('');
    const[start_date,setStartDate]=useState('');
    const[end_date,setEndDate]=useState('');
    const[farmerTotalData,setTotalfarmerdata]=useState('');
    const[role,setRole]=useState('');
    const [districtData, setDistrictData] = useState([]);
    const[districtId,setDistrictId]=useState('');
    const[showDetailsModal,setshowDetailModel]=useState(false);
    const[hashDetails,sethashDetail]=useState(false);

    const handleInput = async (e) => {

        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        if (name == "districtId") {
            setDistrictId(value);
        }
        if (name == "start_date") {
            setStartDate(value)
           /*  const startdate=moment(value).format("MM/DD/YYYY");
            const enddate=moment(end_date).format("MM/DD/YYYY")
            setIsLoading(true);
            const DistrictID = localStorage.getItem("DistrictID");
            let districtID = '';
            if(role.toLocaleLowerCase()=="ao"||role.toLocaleLowerCase()=="ao_mis"){
                districtID = '';
            }
            else{
                districtID = DistrictID;
            }
            let result = await API_Auth.getAllFarmerDetails(startdate,enddate,districtID);
            console.log(result);
            setSearchData(result);
            setTotalfarmerdata(result);
            setIsLoading(false);
            //seting pagenation data
            var slice = result.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result.length / perPage));
            setOrgTableData(result);
            setTableData(slice); */
        };
        if (name == "end_date") {
            
            setEndDate(value);
           /*  setIsLoading(true);
            const DistrictID = localStorage.getItem("DistrictID");
            let districtID = '';
            if(role.toLocaleLowerCase()=="ao"||role.toLocaleLowerCase()=="ao_mis"){
                districtID = '';
            }
            else{
                districtID = DistrictID;
            }
            let result = await API_Auth.getAllFarmerDetails(startdate,enddate,districtID);
            console.log(result);
            setSearchData(result);
            setTotalfarmerdata(result);
            setIsLoading(false);
            //seting pagenation data
            var slice = result.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result.length / perPage));
            setOrgTableData(result);
            setTableData(slice); */
        }
    }
    const handleSearch = (e) => {
        let filterValue = e.target.value;
        setCurrentPage(0);
        if (filterValue == '') {
            let offsetsearch = 0;
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setTableData(slice);
            setSearchData(orgtableData);
            setOffSet(offsetsearch);
            setCurrentPage(0);
            setSearchKey('n');
        }
        else {
            let offsetsearch = 0;
            const filteredData = orgtableData.filter(item => {
                return (item ?.blockName ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                item ?.memberCount?.toString().toLowerCase().includes(filterValue.toLowerCase()))
            });

            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            setOffSet(offsetsearch);
            setSearchKeyTable(filteredData);
            setTableData(slice);
            setSearchData(filteredData);
            setOrgTableData(filteredData);
        }
    }
        useEffect(() => {
        setIsLoading(true);   
        const role=localStorage.getItem('loggedRole')
        setRole(role)    
        const fetchDistributionWise = async () => {
            let districts_result = await API_Auth.getDistricts();
            setDistrictData(districts_result)
         const start_date = new Date();
            const end_date = new Date();
            start_date.setDate(start_date.getDate());
            const defaultValue = start_date.toLocaleDateString('en-CA');
            console.log(defaultValue);
            const default_endDate = end_date.toLocaleDateString('en-CA');
            setStartDate(defaultValue);
            setEndDate(default_endDate); 

           /*  const date = new Date();
            date.setDate(date.getDate());
            const defaultValue = date.toLocaleDateString('en-CA');
            console.log(defaultValue);
 
            setStartDate(defaultValue);
            setEndDate(defaultValue); */

            const startdate=moment(defaultValue).format("MM/DD/YYYY");
            const enddate=moment(end_date).format("MM/DD/YYYY")
            const DistrictID = localStorage.getItem("DistrictID");
            let districtID = '';
            if(role.toLocaleLowerCase()=="ao"||role.toLocaleLowerCase()=="ao_mis"){
                districtID = '';
                setDistrictId('');
            }
            else{
                districtID = DistrictID;
                setDistrictId(DistrictID);
            }
            let result = await API_Auth.getAllFarmerDetails(startdate,enddate,districtID);
            console.log(result);
            setSearchData(result);
            setTotalfarmerdata(result);
            setIsLoading(false);
            //seting pagenation data
            var slice = result.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result.length / perPage));
            setOrgTableData(result);
            setTableData(slice);
        };
        // call the function
        fetchDistributionWise()
            // make sure to catch any error
            .catch(console.error);
    }, []);
    
    const handlehitthheAPI = async() => { 
        const startdate=moment(start_date).format("MM/DD/YYYY");
        const enddate=moment(end_date).format("MM/DD/YYYY")
        setIsLoading(true);
        let result = await API_Auth.getAllFarmerDetails(startdate,enddate,districtId);
        console.log(result);
        setSearchData(result);
        setTotalfarmerdata(result);
        setIsLoading(false);
        //seting pagenation data
        let offset = 0;
        var slice = result.slice(offset, offset + perPage);
        setPageCount(Math.ceil(result.length / perPage));
        setOrgTableData(result);
        setTableData(slice);
        setOffSet(offset);
        setCurrentPage(0);
    }
    const exportToCSV = (csvData) => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const fileName = 'FarmerDetails'
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }
    const handlerowsperPage = (e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(searchKeyTable);
            }
        else {
            const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(orgtableData);
        }
    }
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        const data = orgtableData;
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setOffSet(offsetdata);
            setSearchData(searchKeyTable);
        } else {
            const slice = data.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(data.length / perPage))
            setOffSet(offsetdata);
            setTableData(slice);
            setSearchData(data);
        }
    }
    const handleShowDetails = async(obj) => {
        let data = await API_Auth.gettransactionHashDetails(obj.txhash);
        console.log(data);
        sethashDetail(data);
        setshowDetailModel(true);
    };
    const handleClose =() => {
        setshowDetailModel(false);
    }
    const formatter = new Intl.NumberFormat('en-IN')

    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}

            <Container>
            <Row>
                    <Col lg={4} style={{ paddingLeft: 0,marginTop:25 }}>
                        <Title>Farmer Registration Data</Title>
                    </Col>
                    </Row>
                <Row>
                {role.toLocaleLowerCase()=="ao" &&
                <Col lg={3}style={{ paddingRight: 0}}>
                    <label style={{marginTop:17,}}>District</label>
                        <div className="row_div" style={{ paddingRight: 0}}>
                            <div className="input_div" >
                                <select
                                    name="districtId"
                                    value={districtId}
                                    onChange={handleInput}
                                    style={{ margin: 0, background: 'transparent' }}>
                                    <option value="">All</option>
                                    {districtData.length != 0 &&
                                        <>
                                            {districtData.map((item, index) => {
                                                return (
                                            <option value={item.id}>{item.v_districtName}</option>
                                                )
                                            })}
                                        </>}
                                </select>
                            </div>
                        </div> 
                    </Col> 
                    }
                {role.toLocaleLowerCase()=="ao_mis" &&
                <Col lg={3}style={{ paddingRight: 0}}>
                    <label style={{marginTop:17,}}>District</label>
                        <div className="row_div" style={{ paddingRight: 0}}>
                            <div className="input_div" >
                                <select
                                    name="districtId"
                                    value={districtId}
                                    onChange={handleInput}
                                    style={{ margin: 0, background: 'transparent' }}>
                                    <option value="">All</option>
                                    {districtData.length != 0 &&
                                        <>
                                            {districtData.map((item, index) => {
                                                return (
                                            <option value={item.id}>{item.v_districtName}</option>
                                                )
                                            })}
                                        </>}
                                </select>
                            </div>
                        </div> 
                    </Col> 
                    }
                       <Col lg={3} style={{ paddingLeft: 0}}>
                        <div className="inputdiv">
                            <label style={{marginTop:15,}}>Start Date</label>
                            <br />
                            <input type="date"
                                name="start_date"
                                value={start_date}
                                placeholder="dd/mm/yyyy"
                                onChange={handleInput} />
                        </div>
                    </Col>
                    <Col lg={3} style={{ paddingRight: 0}}>
                        <div className="inputdiv">
                            <label style={{marginTop:15,}}>End Date</label>
                            <br />
                            <input type="date"  style={{width:"100%",marginLeft:0}}
                                name="end_date"
                                value={end_date}
                                placeholder="dd/mm/yyyy"
                                onChange={handleInput} />
                        </div>
                    </Col>
                    <Col lg={3} style={{ paddingRight: 0,marginTop:40 }}>
                          <button
                            className="add_button"
                            onClick={handlehitthheAPI}>
                            <p className="pcat_title">Fetch Data</p>
                        </button>
                    </Col>

                    </Row>
            </Container>
            <Container style={{marginTop:6}}>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
                    {role.toLocaleLowerCase()=="ao" &&
                           <>
                           {tableData.length >0 &&
                           <Row>
                      <Col lg={10}></Col>
                     <Col lg={2} >
                        <button
                            className="export_button"
                            onClick={(e) => exportToCSV(farmerTotalData)}>
                            <p className="pcat_title">Export CSV</p>
                        </button>
                    </Col>
                   </Row>
                        }
                        </>
                     }
                        <Table className="tablelist" responsive>
                            <thead>
                                <tr className="tablehead_small">
                                <th> Name</th>
{/*                       <th> Mobile</th> */}
                      <th> Father</th>
                      <th> Gender</th>
                      <th> KYC</th>
                      <th> Category</th>
                      <th> Date</th>
                      <th> District</th>
                      <th> Block</th>
                      <th> Area</th>
                      <th> Panchayat</th>
                      <th> Village</th>
                      <th>CID</th>
                      <th>Txhash</th>
                                </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {tableData.length != 0 &&
                             <tbody>
                                {tableData.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index} className="tabletd_small">
                                            <td>{obj.FarmarName}</td>
{/*                               <td>{obj.Mobile}</td> */}
                              <td>{obj.fatherName}</td>
                              <td>{obj.gender}</td>
                       <td>{obj.Ekyc}</td>
                       <td>{obj.category}</td>
                       <td>{obj.RegistrationDate}</td>
                       <td>{obj.DistrictNmae}</td>
                       <td>{obj.Block}</td>
                       <td>{obj.Area}</td>
                       <td>{obj.panchayatName}</td>
                       <td>{obj.village}</td>
                       <td>
                                                <OverlayTrigger
                                                        key={obj.CID}
                                                        overlay={<Tooltip id={`tooltip-top`}>
                                                                {obj.CID}</Tooltip>}>
                                                 <span className="table_text_overflow">{obj.CID}</span>
                                                    </OverlayTrigger>
                                                    </td>
                                                <td>
                                                <OverlayTrigger
                                                        key={obj.txhash}
                                                        overlay={<Tooltip id={`tooltip-top`}>
                                                                {obj.txhash}</Tooltip>}>
                                                 <span className="table_text_overflow" style={{textDecoration: 'underline',color:'blue'}}
                                                 onClick={()=>handleShowDetails(obj)}>{obj.txhash}</span>
                                                    </OverlayTrigger>
                                                </td>
                             
                                            </tr>
                                        </>
                                    );
                                })} 
                            </tbody>
                            }
                        </Table>
                        {tableData.length != 0 &&
                            <div>
                                <div className="pagination_row_div">
                                    <p className="pagination_title">Showing items per page:</p>
                                    <div className="pagination_input_div" >
                                        <select className="pagination_select"
                                            style={{ margin: 0, background: 'transparent' }}
                                            name="pagination_value"
                                            value={perPage}
                                            onChange={handlerowsperPage} >
{/*                                             <option value={4}>4</option> */}
                                            <option value={20}>20</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="pagination_row_right_div">
                                    <p className="pagination_total">Showing {offset + 1} to {searchData.length < offset + perPage &&
                                        <span>{searchData.length}</span>}
                                        {searchData.length > offset + perPage &&
                                            <span>{offset + perPage} </span>} of {searchData.length} items</p>
                                    <ReactPaginate
                                        previousLabel={<ArrowBackIosIcon className="pagination_prev_btn" color="#2438a7" />}
                                        nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7" />}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={currentPage} />
                                </div>
                            </div>
                        }                    </Col>
                </Row>
            </Container>
            <Modal show={showDetailsModal} onHide={handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    dialogclassname="modal-content">
                    <Modal.Header closeButton>
                        <Modal.Title> Transaction Details</Modal.Title>
                    </Modal.Header>

                    <Modal.Body >
                    <Row>
                            <Col lg={3}>
                                <p className="pcat_title">BlockHash:</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.blockHash}</p>
                            </Col>
                            </Row>
                            <Row>
                            <Col lg={3}>
                                <p className="pcat_title">BlockNumber :</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.blockNumber}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <p className="pcat_title">ChainId:</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.chainId}</p>
                            </Col>
                            </Row>
                            <Row>
                            <Col lg={3}>
                                <p className="pcat_title">From :</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.from}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <p className="pcat_title">Gas:</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.gas}</p>
                            </Col>
                            </Row>
                            <Row>
                            <Col lg={3}>
                                <p className="pcat_title">GasPrice :</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.gasPrice}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <p className="pcat_title">Hash:</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.hash}</p>
                            </Col>
                            </Row>
                            <Row>
                            <Col lg={3}>
                                <p className="pcat_title">Input :</p>
                            </Col>
                            <Col>
                            <OverlayTrigger
                                                        key={hashDetails.input}
                                                        overlay={<Tooltip id={`tooltip-top`}>
                                                                {hashDetails.input}</Tooltip>}>
                                                                <span className="pcat_title_overFlow">{hashDetails.input}</span>
                                                    </OverlayTrigger>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <p className="pcat_title">Nonce:</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.nonce}</p>
                            </Col>
                            </Row>
                            <Row>
                            <Col lg={3}>
                                <p className="pcat_title">To :</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.to}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <p className="pcat_title">V :</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.v}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <p className="pcat_title">R :</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.r}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <p className="pcat_title">S :</p>
                            </Col>
                            <Col>
                                <p className="pcat_text">{hashDetails.s}</p>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            <ToastContainer />
        </Wrapper>
    )
}
export default FarmersDetailsListAll